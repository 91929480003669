import { getCurrentBusinessId } from './auth';

export const TOPICS = {
  USER_LOGGED_IN_TOPIC: 'USER_LOGGED_IN_TOPIC'
};

export const subscribeToLogIns = (listener: (x: any) => void) => {
  hermes.on(TOPICS.USER_LOGGED_IN_TOPIC, listener);
};

export const sendUserLoggedIn = async () => {
  const userId = await getCurrentBusinessId();
  hermes.send(TOPICS.USER_LOGGED_IN_TOPIC, { userId });
};
