import React, { FC } from 'react';
import Modal from 'react-modal';
import OpportunityPreview from 'components/OpportunityPreview';
import { CreateOpportunityType } from '../../validationSchema';
import {
  Container,
  contentStyles,
  StyledCloseButton,
  StyledCloseRoundIcon
} from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  values: CreateOpportunityType;
}

const OpportunityPreviewModal: FC<Props> = ({ isOpen, onClose, values }) => (
  <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{ content: contentStyles }}
  >
    <StyledCloseButton onClick={onClose} type="button">
      <StyledCloseRoundIcon />
    </StyledCloseButton>
    <Container>
      <OpportunityPreview showDescription values={values} />
    </Container>
  </Modal>
);

export default OpportunityPreviewModal;
