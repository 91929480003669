import React, { FC } from 'react';
import IconProps from './IconProps';

const Envelope: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79756 1.93388C3.52679 1.93388 3.26974 1.99369 3.03667 2.10002L9.06546 6.11397C9.51445 6.41303 10.1759 6.86161 10.7757 7.26699C11.0945 7.48297 11.3858 7.68234 11.66 7.86509C11.7011 7.88835 11.7388 7.91494 11.78 7.94484C13.3531 8.99817 14.2545 9.5265 14.9983 9.5265C15.742 9.5265 16.6434 8.99817 18.2166 7.94484C18.2543 7.91494 18.2954 7.88835 18.3366 7.86509C18.6108 7.68234 18.9055 7.48297 19.2208 7.26699C19.8206 6.86161 20.4821 6.41303 20.9311 6.11397L26.9599 2.10002C26.7268 1.99369 26.4698 1.93388 26.199 1.93388H3.79756ZM1.99474 16.3183C1.99474 16.5177 2.02902 16.7071 2.09414 16.8865C3.82155 15.0623 5.04513 13.7631 5.85742 12.9025C6.44693 12.2745 7.75963 10.8822 9.66183 8.85861L9.64127 8.84532C9.22312 8.55956 8.75357 8.24389 7.94813 7.7056L1.99474 3.74481V16.3183ZM26.199 18.0694C26.2401 18.0694 26.2778 18.0694 26.319 18.0661C25.4107 17.1058 24.1734 15.79 22.6757 14.205C20.7529 12.1648 19.4676 10.8025 18.6862 9.96843C17.1507 10.9653 16.1362 11.4637 14.9983 11.4637C13.8604 11.4637 12.8493 10.9653 11.3104 9.96843C10.4844 10.8457 9.182 12.2279 7.31749 14.205C5.80944 15.8033 4.58243 17.1092 3.67417 18.0661C3.7153 18.0694 3.753 18.0694 3.79413 18.0694H26.199ZM28.0053 16.3183V3.74481L22.0553 7.7056C21.2464 8.24389 20.7803 8.55956 20.3622 8.84532L20.3416 8.85861C22.3192 10.9653 23.6593 12.3941 24.1426 12.9025C24.9652 13.7764 26.1716 15.0557 27.9059 16.8865C27.971 16.7071 28.0053 16.5177 28.0053 16.3183ZM30 3.68167V16.3183C30 17.3318 29.5716 18.2522 28.8861 18.9201C28.1972 19.588 27.2478 20 26.2024 20H3.79756C2.7522 20 1.80281 19.5846 1.1139 18.9201C0.428425 18.2522 0 17.3318 0 16.3183V3.68167C0 2.66822 0.428425 1.7478 1.1139 1.07991C1.80281 0.415351 2.7522 0 3.79756 0H26.199C27.2444 0 28.1938 0.415351 28.8827 1.07991C29.5716 1.7478 30 2.66822 30 3.68167Z"
        fill="#17AACF"
      />
    </svg>
  );
};

export default Envelope;
