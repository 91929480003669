import React, { FC } from 'react';
import { Formik } from 'formik';
import PasswordField from 'components/PasswordField';
import { FullScreenLoader, FieldFooter } from 'uikit';
import validationSchema, { ResetPasswordFormType } from './validationSchema';
import PasswordPageHeading from 'components/PasswordPageHeading';
import {
  InputGroup,
  SubmitButton
} from './styles';
import { resetPassword } from 'services/auth';

interface Props {
  code: string;
  email: string;
  onSuccess: () => void;
}

const ResetPasswordForm: FC<Props> = ({ email, code, onSuccess }) => {
  const initialValues: ResetPasswordFormType = {
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = async (
    { password }: ResetPasswordFormType,
    { setFieldError }: any
  ) => {
    const result = await resetPassword(email, code, password);

    if (result?.message) {
      switch(result?.code) {
        case "ExpiredCodeException": 
          setFieldError('password', "The reset password link has already been used or the link is invalid.");
          break;
        default: 
          setFieldError('password', result.message);
          break;
      }
      
    } else {
      onSuccess();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          {isSubmitting && <FullScreenLoader />}
          <PasswordPageHeading title="Reset your Password"></PasswordPageHeading>
          <InputGroup>
            <PasswordField
              name="password"
              onChange={handleChange}
              showPasswordStrength={false}
              value={values.password}
              placeholder="New Password"
            />
            <FieldFooter error={errors.password} />
          </InputGroup>
          <InputGroup>
            <PasswordField
              name="confirmPassword"
              onChange={handleChange}
              showPasswordStrength={false}
              value={values.confirmPassword}
              placeholder="Confirm new Password"
            />
            <FieldFooter error={errors.confirmPassword} />
          </InputGroup>
          <SubmitButton type="submit" disabled={isSubmitting}>
            Submit
          </SubmitButton>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
