import React, { FC } from 'react';
import authMethods from 'constants/authMethods';
import {
  AuthButton,
  StyledEnvelope,
  StyledGoogleIcon,
  StyledShopifyIcon
} from './styles';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  authProcedureName: string;
  className?: string;
  onAuthByEmail: () => void;
  onAuthByGoogle: () => void;
  onAuthByShopify: () => void;
}

const AuthMethods: FC<Props> = ({
  authProcedureName,
  className,
  onAuthByEmail,
  onAuthByGoogle,
  onAuthByShopify
}) => (
  <div className={className}>
    <AuthButton
      type="button"
      onClick={onAuthByShopify}
      id={trackedElementsIds.logInWithShopifyButton}
    >
      <StyledShopifyIcon />
      {`${authProcedureName} with ${authMethods.SHOPIFY}`}
    </AuthButton>
    <AuthButton type="button" onClick={onAuthByGoogle}>
      <StyledGoogleIcon />
      {`${authProcedureName} with ${authMethods.GOOGLE}`}
    </AuthButton>
    <AuthButton type="button" onClick={onAuthByEmail}>
      <StyledEnvelope />
      {`${authProcedureName} with ${authMethods.EMAIL}`}
    </AuthButton>
  </div>
);

export default AuthMethods;
