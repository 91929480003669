import styled from 'styled-components';
import { Body2, ErrorTextMixin } from 'uikit';

export const Disclaimer = styled(Body2)`
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const ErrorMessage = styled.div`
  ${ErrorTextMixin};
`;
