export const CATEGORIES_MAX_SELECTION = 5;
export const PROFILE_REQUIRED_FIELD_KEYS = [
  'businessName',
  'logo.length',
  'category.length',
  'firstName',
  'lastName'
];
export const TOOLTIPS = {
  businessName:
    'This business name will be displayed on the opportunity that both the referrer and customer will see. Example: ABC Shoes. You do not need to add a URL extension such as .com or .net in your business name.',
  category:
    'Within the Drum mobile marketplace app, referrers can search for business name, category, tags, and keywords in your title. To make your opportunities easily discoverable by referrers please choose up to 5 categories that reflect your business offering.',
  logo:
    'Please provide a logo that will appear on your opportunity. Please do not include a tag line in opportunity as it will be too small to read. We suggest a minimum logo size of 300 x 300 pixels. Any image other than a square will be cropped to fit. Supported file types: .jpeg, .gif, .png.',
  mainContactPerson:
    'Please provide the main contact person information for this account. This information will NOT be displayed in your opportunity. We will only use it for customer support purposes. We will not sell your information or call your phone with pesky auto dialers.'
};
