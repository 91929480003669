import React, { Fragment, ReactNode } from 'react';
import {
  Title as UITitle,
  OptionalContainer,
  OptionalText,
  LargeLine,
  TinyLine,
  Text
} from './styled';
import { Tooltip } from '../index';

interface Props {
  text?: string;
  tooltipText?: string;
  hasLine?: boolean;
  optionalText?: string;
  className?: string;
  children?: ReactNode;
}

const FieldLabel: React.FC<Props> = ({
  text,
  tooltipText,
  hasLine,
  optionalText,
  className,
  children
}) => {
  return (
    <UITitle className={className}>
      {children ? 
        (children)
        :
        <>
          <Text>{text}</Text>
          {tooltipText && <Tooltip text={tooltipText} />}
          {hasLine && (
            <OptionalContainer>
              <LargeLine />
              {!!optionalText && (
                <Fragment>
                  <OptionalText>{optionalText}</OptionalText>
                  <TinyLine />
                </Fragment>
              )}
            </OptionalContainer>
          )}
        </>
      }
    </UITitle>
  );
};

export default FieldLabel;
