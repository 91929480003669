export const getUrlErrorMessage = (str: string) => {
  const words = str.split('+');
  return words.reduce((acc = '', curr, idx) => {
    return acc + (idx !== 0 && curr[0] && curr[0].toUpperCase() === curr[0] ? `. ${curr}` : curr);
  })
}

export function extractHostname(url: string) {
  var hostname;
  if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
  }
  else {
      hostname = url.split('/')[0];
  }
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  return hostname;
}