import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { PageFormType } from 'components/ReferralReward/validationSchema';
import { fonts } from '../../constants';
import { SetField } from '../../types';
import CustomSelect from 'components/CustomSelect';
import MenuList from 'components/MenuList';
import IImageUpload from 'types/ImageUpload';
import { getEmailCampaignImageUploadURL } from 'helpers';
import { ImageUpload, FieldFooter, RadioButton } from 'uikit';
import { getLogoURL } from 'helpers/referralWebpage';
import { ColorBlock, Color, ColorLabel, Corners } from '../../styles';
import { Container, Title, SelectContainer, RadioContainer, ImageContainer, BgContainer } from './styles';
import { ReferralPageStateConfigPageStyleBackgroundType } from '../../../../graphql';

interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  onColorPick: (
    field: string,
    setField: SetField,
    values: PageFormType,
    label: string
  ) => void;
  onCornersPick: (
    field: string,
    setField: SetField,
    values: PageFormType,
    label: string
  ) => void;
  onAddImage: (setFieldValue: any) => (imageData: IImageUpload) => void;
}

const PageStyle: FC<Props> = ({
  onColorPick,
  onCornersPick,
  onAddImage
}) => {
  const { values, setFieldValue, errors } = useFormikContext<
    PageFormType
  >();

  return (
    <Container>
      {/* Button style */}
      <Title>Button Style</Title>
      <div>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'pageStyle.buttonColor',
              setFieldValue,
              values,
              'Button Color'
            )
          }
        >
          <Color
            style={{
              backgroundColor: values.pageStyle.buttonColor
            }}
          />
          <ColorLabel>Button</ColorLabel>
        </ColorBlock>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'pageStyle.textColor',
              setFieldValue,
              values,
              'Button Text Color'
            )
          }
        >
          <Color
            style={{ backgroundColor: values.pageStyle.textColor }}
          />
          <ColorLabel>Text</ColorLabel>
        </ColorBlock>
        <ColorBlock
          onClick={() =>
            onCornersPick(
              'pageStyle.cornersRadius',
              setFieldValue,
              values,
              'Button Corner Radius'
            )
          }
        >
          <Corners />
          <ColorLabel>Corners</ColorLabel>
        </ColorBlock>
      </div>

      {/* Font */}
      <Title>Font</Title>
      <div>
        <SelectContainer>
          <CustomSelect<IOption>
            options={fonts}
            components={{ MenuList }}
            onChange={(option) => {
              setFieldValue('pageStyle.fontName', (option as IOption)?.value);
            }}
            value={{
              label: values.pageStyle.fontName,
              value: values.pageStyle.fontName
            }}
          />
        </SelectContainer>
      </div>

      {/* Background */}
      <Title>Background</Title>

      <RadioContainer>
        <RadioButton
          id={ReferralPageStateConfigPageStyleBackgroundType.Image}
          name="pageStyle.background.backgroundType"
          label="Image"
          labelClass={'emphasized-label'}
        />
        <RadioButton
          id={ReferralPageStateConfigPageStyleBackgroundType.Color}
          name="pageStyle.background.backgroundType"
          label="Color"
          labelClass={'emphasized-label'}
        />
      </RadioContainer>

      {values.pageStyle.background.backgroundType === ReferralPageStateConfigPageStyleBackgroundType.Image && 
        <ImageContainer>
          <ImageUpload
            allowMultipleUpload={false}
            onSuccess={onAddImage && onAddImage(setFieldValue)}
            previewURL={getLogoURL(values.pageStyle.background.backgroundImage)}
            getImageUploadUrl={getEmailCampaignImageUploadURL}
          />
        </ImageContainer>
      }
      {values.pageStyle.background.backgroundType === ReferralPageStateConfigPageStyleBackgroundType.Color && 
        <BgContainer>
          <ColorBlock
            className="wide"
            onClick={() =>
              onColorPick(
                'pageStyle.background.backgroundColor',
                setFieldValue,
                values,
                'Button Text Color'
              )
            }
          >
            <Color
              style={{ backgroundColor: values.pageStyle.background.backgroundColor || '#ededed' }}
            />
            <ColorLabel>Background</ColorLabel>
          </ColorBlock>
        </BgContainer>
      }
      <FieldFooter error={errors?.pageStyle?.background?.backgroundImage} />
    </Container>
  );
};

export default PageStyle;
