import React, { FC } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { InputBase, FieldFooter, TextareaBase } from 'uikit';
import { getOfferImageUploadUrl } from 'helpers';
import { sendDestinationUrlFillTrackingEvent } from 'helpers/segment';
import {
  FIELD_MAX_LENGTH,
  TEXTAREA_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import { CreateOpportunityType } from '../../validationSchema';
import { InputGroup, StyledLabel, Attention } from './styles';
import ImageList from 'components/ImageList';

const helpTexts = {
  OPPORTUNITY_TITLE: {
    label: 'What title would you like to give your opportunity?',
    tooltip:
      'Use this title to capture the attention of potential customers as well as referrers.'
  },
  DESCRIPTION: {
    label:
      'How would you describe your product, service, or special discount offer?',
    tooltip:
      'Use this section to explain your product or service to your customers and provide further details to help the customer understand what your business is offering and why they should become a customer.'
  },
  IMAGES: {
    label: 'What images would you like shown with your opportunity?',
    tooltip:
      'You may include up to 8 images to showcase your product or service. We recommend an image of at least 200 x 200 pixels so that we can show accurate previews to referrers and customers.'
  },
  DESTINATION_URL: {
    label: 'What link on your website would you like to send customers to?',
    tooltip:
      "The destination URL is the webpage where you want the customer to land after they click the 'Open' or 'Go to' links on this page. This is the starting point the customer would make before completing a conversion."
  }
};

const OpportunityDetails: FC = () => {
  const { handleChange, values, errors } = useFormikContext<
    CreateOpportunityType
  >();

  const handleDestinationBlur = (url: string) => {
    if(!url) return;
    sendDestinationUrlFillTrackingEvent(url);
  }

  return (
    <div>
      <InputGroup>
        <StyledLabel
          title={helpTexts.OPPORTUNITY_TITLE.label}
          tooltip={helpTexts.OPPORTUNITY_TITLE.tooltip}
        />
        <InputBase
          name="opportunityDetails.title"
          onChange={handleChange}
          value={values.opportunityDetails.title}
          placeholder="Example: Get 30% your entire purchase!"
          maxLength={FIELD_MAX_LENGTH}
        />
        <FieldFooter
          error={errors?.opportunityDetails?.title}
          limit={FIELD_MAX_LENGTH}
          fieldValueLength={values.opportunityDetails.title.length}
        />
      </InputGroup>
      <InputGroup>
        <StyledLabel
          title={helpTexts.DESCRIPTION.label}
          tooltip={helpTexts.DESCRIPTION.tooltip}
        />
        <TextareaBase
          name="opportunityDetails.description"
          rows={5}
          onChange={handleChange}
          value={values.opportunityDetails.description}
          maxLength={TEXTAREA_MAX_LENGTH}
        />
        <FieldFooter
          error={errors?.opportunityDetails?.description}
          limit={TEXTAREA_MAX_LENGTH}
          fieldValueLength={values.opportunityDetails.description.length}
        />
      </InputGroup>
      <InputGroup>
        <StyledLabel
          title={helpTexts.IMAGES.label}
          tooltip={helpTexts.IMAGES.tooltip}
        />
        <FieldArray
          name="opportunityDetails.photos"
          render={({ push, remove }) => (
            <ImageList
              getImageUploadUrl={getOfferImageUploadUrl}
              images={values.opportunityDetails.photos}
              onRemoveImage={remove}
              onAddImage={push}
            />
          )}
        />
        <FieldFooter error={errors?.opportunityDetails?.photos as string}/>
      </InputGroup>
      <InputGroup>
        <StyledLabel
          title={helpTexts.DESTINATION_URL.label}
          tooltip={helpTexts.DESTINATION_URL.tooltip}
        />
        <InputBase
          name="opportunityDetails.urlDestination"
          onChange={handleChange}
          onBlur={() => handleDestinationBlur(values.opportunityDetails.urlDestination)}
          value={values.opportunityDetails.urlDestination}
          placeholder="URL"
        />
        <FieldFooter error={errors?.opportunityDetails?.urlDestination} />
        <Attention>
          <span>Attention:  </span>
          <span>If you’re offering a discount to customers, please make sure that the right discount code is applied when using this link.</span>
        </Attention>  
      </InputGroup>
    </div>
  );
};

export default OpportunityDetails;
