import styled from 'styled-components';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  width: 260px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular}
  line-height: 16px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;

  span {
    flex: 1 1 auto;
    border-top: 1px solid ${({ theme }) => theme.colors.white};
    opacity: 0.56;
  }
`;

export const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 18px;
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.accentSecondaryColor};
`;