import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { Body2, Label, Select } from 'uikit';
import CustomSelect from 'components/CustomSelect';
import ReferralRewardInput from '../ReferralRewardInput';

export const FormGroup = styled.div`
  :not(:last-child) {
    ${breakpoint('md')`
      margin-bottom: 40px;
    `}

    ${breakpoint('xs', 'md')`
      margin-bottom: 20px;
    `}
  }
`;

export const SectionTitle = styled(Label)`
  margin-bottom: 10px;
`;

export const RewardTriggerEventSelect = styled(Select)`
  ${breakpoint('md')`
    width: 400px;
  `}
`;

export const CommissionFields = styled.div`
  ${breakpoint('md')`
    display: flex;
  `}
`;

export const CommissionTypeSection = styled.div`
  ${breakpoint('md')`
    width: 400px;
    margin-right: 25px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
  `}
`;

export const SectionDescription = styled(Body2)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const StyledSelect = styled(CustomSelect)`
  width: 300px;
`;

export const StyledRewardInput = styled(ReferralRewardInput)`
  width: 150px;
`;

export const MargedRewardInput = styled(StyledRewardInput)`
  margin-top: 25px;
`;
