import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import routes from 'constants/routes';
import { Modal, FullScreenLoader } from 'uikit';
import CreationProgress from './components/CreationProgress';
import DefineAndSend from './components/DefineAndSend';
import { MAX_OPPORTUNITIES_IN_EMAIL } from './components/EmailCampaignTemplates/constants';
import { getTemplate } from './helpers';
import { sendSelectedEmailTemplateTrackingEvent } from 'helpers/segment';
import { emailType } from './types';
import {
  Container,
  Content,
  CreateCampaingContainer,
  CreateCampaingTextContainer,
  CreateTitle,
  CreateText,
  TemplateListContainer,
  TemplateList,
  TemplateListMobile,
  TemplateItem,
  TemplateTitle,
  TemplateSubtitle,
  TemplateContent,
  TemplatePreviewButton,
  HeaderBlock,
  HeaderTitle,
  HeaderLink
} from './styles';
import DashboardPageLayout from 'layouts/DashboardPageLayout';
import {
  OfferStatus,
  useListOpportunitiesQuery,
  useGetCustomersQuery,
  Offer,
  TemplateStyles
} from '../../graphql';
import { ChevronLeft, ChevronRight } from 'assets/svg';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import './CreateEmailCampaignPage.scss';
import {
  EMAIL_STEP_NAME_PARAM,
  EMAIL_STEP_NAME_DEFINE_AND_SEND,
  EMAIL_STEP_TEMPLATE_STYLE_PARAM_NAME,
  EMAIL_STEP_ID_PARAM_NAME
} from 'constants/emailCampaignQueryParams';
import {
  CUSTOMER_TAB_NAME_PARAM,
  CUSTOMER_TAB_NAME_EMAILS_LIST
} from 'constants/customersQueryParams';

const CreateEmailCampaign: FC<RouteComponentProps> = (props) => {
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  const currentStepName = params[EMAIL_STEP_NAME_PARAM];
  const [currentStep, setCurrentStep] = useState<number>(
    currentStepName === EMAIL_STEP_NAME_DEFINE_AND_SEND ? 2 : 1
  );
  const [isNoOffersModalOpen, setIsNoOffersModalOpen] = useState<boolean>(
    false
  );
  const [isNoCustomersModalOpen, setIsNoCustomersModalOpen] = useState<boolean>(
    false
  );
  const [swiper, setSwiper] = useState<any>(null);

  const {
    data: opportunitiesList,
    loading: opportunitiesListLoading
  } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: MAX_OPPORTUNITIES_IN_EMAIL
        }
      }
    }
  });
  const {
    data: customersData,
    loading: customersDataLoading
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        page: {
          offset: 0,
          limit: 1
        }
      }
    }
  });

  const styleSet = [
    TemplateStyles.TemplateStyle1,
    TemplateStyles.TemplateStyle2,
    TemplateStyles.TemplateStyle3,
    TemplateStyles.TemplateStyle4
  ];

  const onPreview = (style: TemplateStyles, index: number) => {
    sendSelectedEmailTemplateTrackingEvent(index + 1);
    props.history.push({
      pathname: props.location.pathname,
      search: `${EMAIL_STEP_NAME_PARAM}=${EMAIL_STEP_NAME_DEFINE_AND_SEND}&${EMAIL_STEP_TEMPLATE_STYLE_PARAM_NAME}=${style}`
    });
  };

  useEffect(() => {
    if (
      opportunitiesList?.listOffers?.items &&
      opportunitiesList?.listOffers?.items.length
    ) {
      if (swiper?.update) {
        swiper.update();
      } else {
        setSwiper(
          new Swiper('.email-templates-swiper-container', {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [opportunitiesList]);

  useEffect(() => {
    if (!opportunitiesList?.listOffers || !customersData?.findCustomers) return;
    const hasOpportunity = !!opportunitiesList?.listOffers?.items?.length;
    const hasCustomers = !!customersData.findCustomers?.total;
    if (!hasOpportunity) {
      setIsNoOffersModalOpen(true);
    } else if (!hasCustomers) {
      setIsNoCustomersModalOpen(true);
    }
  }, [opportunitiesList, customersData]);

  useEffect(() => {
    setCurrentStep(currentStepName === EMAIL_STEP_NAME_DEFINE_AND_SEND ? 2 : 1);
  }, [currentStepName]);

  const renderTemplate = (style: TemplateStyles, key: number) => {
    return (
      <TemplateItem>
        <TemplateTitle>Email Template {key + 1}</TemplateTitle>
        <TemplateSubtitle>Example</TemplateSubtitle>
        <TemplateContent onClick={() => onPreview(style, key)}>
          <>
            {getTemplate(
              emailType.invitationEmail,
              style,
              opportunitiesList?.listOffers?.items as Offer[]
            )}
          </>
        </TemplateContent>
        <TemplatePreviewButton onClick={() => onPreview(style, key)}>
          Select
        </TemplatePreviewButton>
      </TemplateItem>
    );
  };

  const handleProgressStepClick = (index: number) => {
    if (!params[EMAIL_STEP_ID_PARAM_NAME] && index === 1) {
      props.history.push(routes.CREATE_EMAIL_CAMPAIGN);
    }
  };

  return (
    <DashboardPageLayout>
      <Container>
        <HeaderBlock>
          <HeaderTitle>Referral Email Program</HeaderTitle>
          <HeaderLink
            to={`${routes.CUSTOMERS_MANAGEMENT}?${CUSTOMER_TAB_NAME_PARAM}=${CUSTOMER_TAB_NAME_EMAILS_LIST}`}
          >
            {'<'}Back to Email Management
          </HeaderLink>
        </HeaderBlock>
        <CreationProgress
          currentStep={currentStep}
          onStepClick={handleProgressStepClick}
        />
        <Content>
          {currentStep === 1 && (
            <CreateCampaingContainer>
              <CreateCampaingTextContainer>
                <CreateTitle>Create Email Campaign</CreateTitle>
                <CreateText>
                  Creating an automated email campaign to invite your customers
                  and remind them of your opportunities is easy. Simply pick
                  your desired email style template, edit the content and style
                  if you wish and finally, publish your campaign.{' '}
                </CreateText>
              </CreateCampaingTextContainer>

              <TemplateListContainer>
                <CreateTitle>Please choose your email template:</CreateTitle>
                <TemplateList>
                  {styleSet.map((item: TemplateStyles, key) =>
                    renderTemplate(item, key)
                  )}
                </TemplateList>

                <TemplateListMobile>
                  <div className="swiper-container email-templates-swiper-container">
                    <div className="swiper-wrapper">
                      {styleSet.map((item, key) => (
                        <div className="swiper-slide">
                          {renderTemplate(item, key)}
                        </div>
                      ))}
                    </div>
                    <div className="swiper-button-nav swiper-button-prev">
                      <ChevronLeft />
                    </div>
                    <div className="swiper-button-nav swiper-button-next">
                      <ChevronRight />
                    </div>
                  </div>
                </TemplateListMobile>
              </TemplateListContainer>
            </CreateCampaingContainer>
          )}
          {currentStep === 2 && (
            <div>
              <DefineAndSend />
            </div>
          )}
        </Content>

        {(opportunitiesListLoading || customersDataLoading) && (
          <FullScreenLoader />
        )}

        <Modal
          isOpen={isNoOffersModalOpen}
          canClose={false}
          childrenBeforeButtons={true}
          title="Before we do that..."
          message="Please create and publish an opportunity to invite your customers to promote."
          mainButton={{
            text: 'Create Opportunity',
            onClick: () => {
              history.push(routes.CREATE_OPPORTUNITY);
            }
          }}
        />

        <Modal
          isOpen={isNoCustomersModalOpen}
          canClose={false}
          childrenBeforeButtons={true}
          title="Before we do that..."
          message="Please import customers to your customers’ list."
          mainButton={{
            text: 'Go to Customer List',
            onClick: () => {
              history.push(routes.CUSTOMERS_MANAGEMENT);
            }
          }}
        />
      </Container>
    </DashboardPageLayout>
  );
};

export default CreateEmailCampaign;
