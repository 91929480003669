import React, { FC } from 'react';
import IconProps from './IconProps';

const BoldCloseIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      fill="white"
      stroke="#17AACF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5355 7.8787C13.9261 7.48818 13.9261 6.85501 13.5355 6.46449C13.145 6.07396 12.5118 6.07396 12.1213 6.46449L10 8.58581L7.87868 6.46449C7.48816 6.07396 6.85499 6.07396 6.46447 6.46449C6.07394 6.85501 6.07394 7.48818 6.46447 7.8787L8.58579 10L6.46447 12.1213C6.07394 12.5119 6.07394 13.145 6.46447 13.5356C6.85499 13.9261 7.48816 13.9261 7.87868 13.5356L10 11.4142L12.1213 13.5356C12.5118 13.9261 13.145 13.9261 13.5355 13.5356C13.9261 13.145 13.9261 12.5119 13.5355 12.1213L11.4142 10L13.5355 7.8787Z"
      fill="#17AACF"
    />
  </svg>
);

export default BoldCloseIcon;
