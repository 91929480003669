import React, { FC } from 'react';
import { Formik, FormikErrors } from 'formik';
import { InputBase, FieldFooter } from 'uikit';
import { SendTestFormType, sendTestSchema } from '../../../../validationSchema';
import {
  Container,
  InputTitle,
  InputContainer,
  ButtonContainer,
  SendButton,
  SubText1,
  SubText2,
  Row
} from './styles';

interface Props {
  onSend: (chunks: string[]) => void;
}

const SendTestEmail: FC<Props> = ({ onSend }) => {
  // const { handleChange, values, errors } = useFormikContext<
  //   EmailCampaignFormType
  // >();

  const handleSend = (
    validateForm: (
      values?: SendTestFormType
    ) => Promise<FormikErrors<SendTestFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: SendTestFormType
  ) => {
    setSubmitting(true);
    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;
      const chunks = values.sendTest.split(',');
      const trimmedChunks = chunks.map((item: string) => item.trim());
      
      setSubmitting(false);
      
      if(!hasErrors) {
        onSend && onSend(trimmedChunks);
      };
    });
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{ sendTest: '' }}
        validationSchema={sendTestSchema}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={() => {}}
      >
        {({ values, errors, handleChange, validateForm, setSubmitting }) => (
          <>
            <InputTitle>Send preview test email to:</InputTitle>
            <Row>
              <InputContainer>
                <InputBase
                  name={'sendTest'}
                  onChange={handleChange}
                  value={values.sendTest}
                />
              </InputContainer>
              <ButtonContainer>
                <SendButton
                  onClick={() => handleSend(validateForm, setSubmitting, values)}
                >
                  Send test emails
                </SendButton>
              </ButtonContainer>
            </Row>
            <FieldFooter error={errors.sendTest} />

            <SubText1>Separate multiple emails with commas.</SubText1>
            <SubText2>
              You will receive each of the 3 emails. Invitation, Reminder, and
              New Opportunity.
            </SubText2>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default SendTestEmail;
