import React, { FC } from 'react';
import {Support} from 'assets/svg';
import { SCHEDULE_CALL_LINK } from 'constants/outerLinks';
import {
  Container,
  CallIconContainer,
  CallTextContainer,
  CallTitle,
  CallLink
} from './styles';

interface Props {
  className?: string;
  onScheduleCall: () => void;
}

const ScheduleCall: FC<Props> = ({ className, onScheduleCall }) => (
  <Container className={className}>
    <CallIconContainer>
      <Support />
    </CallIconContainer>
    <CallTextContainer>
      <CallTitle>Need help with set up?</CallTitle>
      <CallLink href={SCHEDULE_CALL_LINK}>
        Schedule a free onboarding call.
      </CallLink>
    </CallTextContainer>
  </Container>
);

export default ScheduleCall;
