export const getSource = (source: string) => {
  switch(source) {
    case 'csvUpload':
      return 'CSV Upload';
    case 'manualAdd':
      return 'Manual Add';
    case 'shopify':
      return 'Shopify';
    case 'referralPage':
      return 'Referral Webpage'
    default: return source;
  }
}
export const getStatus = (status: string = '') => {
  switch((status || '').toLowerCase()) {
    case 'notinvited':
      return 'Not Invited'
    default: return (status?.charAt(0).toUpperCase() + status?.slice(1)) || '';
  }
}