import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Container,
  Dot,
  MenuButton,
  MenuContainer,
  StyledButton
} from './styles';

interface Props {
  className?: string;
  options: { name: string; onClick: () => void }[];
}

const OpportunityMenu: FC<Props> = ({ className, options }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleToggle = () => setOpen(!isOpen);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <Container>
        <MenuButton className={className} onClick={handleToggle} type="button">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Dot key={index} />
            ))}
        </MenuButton>
        {isOpen && (
          <MenuContainer>
            <ul>
              {options.map(({ name, onClick }) => (
                <StyledButton key={name} onClick={onClick} type="button">
                  {name}
                </StyledButton>
              ))}
            </ul>
          </MenuContainer>
        )}
      </Container>
    </OutsideClickHandler>
  );
};

export default OpportunityMenu;
