import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px 0px;

  svg {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.25);
  }
`;
