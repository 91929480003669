import React, { FC } from 'react';
import {
  DRUM_HOME_PAGE
} from 'constants/landingPageLinks';
import FALLBACK_IMAGE_URL from 'constants/fallbackImageURL';
import { GetInvitationLandingDataQuery, ImageStyleName } from '../../graphql';
import {
  Container,
  CenteredText,
  GetAppButton,
  ImageCaption,
  Invitation,
  Logo,
  OfferDetails,
  OfferLink,
  Title
} from './styles';

interface Props {
  className?: string;
  appLink?: string;
  businessData: GetInvitationLandingDataQuery | undefined;
}

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

const InviteDrummerHeader: FC<Props> = ({ businessData, className, appLink }) => {
  const businessName = businessData?.getCurrentBusiness?.businessName;
  const logoStyle = businessData?.getCurrentBusiness?.coverImage?.styles?.find(
    (style) => style?.name === ImageStyleName.Square
  );

  const imagePath = logoStyle?.path
    ? `${S3_DOMAIN}/${logoStyle?.path}`
    : FALLBACK_IMAGE_URL;

  return (
    <Container className={className}>
      <Logo src={imagePath} alt="logo" />
      <ImageCaption>Opportunity on Drum</ImageCaption>
      <Invitation>
        <Title>Hello</Title>
        <OfferDetails>
          If you love {businessName}, please spread the word. {businessName} has
          a referral opportunity on{' '}
          <OfferLink
            href={DRUM_HOME_PAGE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Drum
          </OfferLink>
          . Drum allows you to earn money referring businesses you care about.
          Download Drum and share the {businessName} promotions to earn money.
        </OfferDetails>
      </Invitation>
      <CenteredText>
        <GetAppButton
          href={appLink}
          //href={APPS_FLYER_DRUMMER_APP_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Get the App
        </GetAppButton>
      </CenteredText>
    </Container>
  );
};

export default InviteDrummerHeader;
