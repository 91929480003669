import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import {
  useListOpportunitiesQuery,
  ListOffersInput,
  ListOpportunitiesQuery
} from '../../graphql';
import Pagination from 'components/Pagination';
import debounce from 'lodash.debounce';
import { sendOpportunityListLoadedTrackingEvent } from 'helpers/segment';
import { Loader, Modal } from 'uikit';
import { OpportunitiesListTable } from './components';
import { INITIAL_PAGE } from './constants';
import {
  Container,
  EmptyListContainer,
  PaginationContainer,
  StyledSearchInput
} from './styles';

const ListOpportunities: FC = () => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const [searchValue, setSearchValue] = useState('');
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [data, setData] = useState<ListOpportunitiesQuery | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  //We're using this imperative query because Apollo caches lazy queries and it can't be fixed
  const { refetch } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    skip: true
  });
  const imperativeQuery = useCallback(
    (input: ListOffersInput) => {
      return refetch({ input });
    },
    [refetch]
  );

  //const paginationLimit = DEFAULT_PAGINATION_LIMIT;
  const paginationLimit = 9999;

  const getList = useCallback(
    debounce(async (searchValue?: string, page?: number) => {
      setLoading(true);
      let _data = await imperativeQuery({
        pagination: { limit: paginationLimit, page: page || 1 },
        filter: {
          title: { eq: searchValue || '' },
          status: {
            ne: 'DELETED'
          }
        }
      });
      setLoading(false);
      if (_data?.data) {
        sendOpportunityListLoadedTrackingEvent(
          (_data.data?.listOffers?.items || []).map((item) => item.id),
          searchValue || ''
        );
        setData(_data.data);
      } else {
        setError(true);
      }
    }, 400),
    []
  );

  useEffect(() => {
    getList(searchValue, page);
    // eslint-disable-next-line
  }, [page, searchValue]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      setErrorModalOpen(true);
    }
  }, [error]);

  const handleSearchByTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setPage(INITIAL_PAGE);
    setSearchValue(value);
  };

  const getTotalPages = () => {
    const totalOpportunities = data?.listOffers?.meta?.total;

    if (!totalOpportunities) {
      return 0;
    }

    return Math.ceil(totalOpportunities / paginationLimit);
  };

  const handleOfferStatusChange = () => {
    getList();
  };

  const totalPages = getTotalPages();

  return (
    <>
      <Container>
        <StyledSearchInput
          // TODO: fix it
          // @ts-ignore
          name="search-opportunities"
          value={searchValue}
          onChange={handleSearchByTitle}
        />

        {loading ? (
          <EmptyListContainer>
            <Loader />
          </EmptyListContainer>
        ) : (
          <OpportunitiesListTable
            data={data}
            onOfferStatusChange={handleOfferStatusChange}
          />
        )}

        {totalPages > INITIAL_PAGE && (
          <PaginationContainer>
            <Pagination
              onPageChange={(page) => setPage(page)}
              pageCount={totalPages}
            />
          </PaginationContainer>
        )}
      </Container>

      <Modal
        type="warning"
        isOpen={isErrorModalOpen}
        title="Failed to load the opportunities page. Please try again."
        mainButton={{
          text: 'Ok',
          onClick: () => setErrorModalOpen(false)
        }}
        onClose={() => setErrorModalOpen(false)}
      />
    </>
  );
};

export default ListOpportunities;
