import React, { FC, useState } from 'react';
import { GlassPanel } from 'uikit';
import PreviewFooter from '../PreviewFooter';
import { Props as PreviewProps } from '../../types';
import RewardsApp from './components/RewardsApp';
import Email from './components/Email';
import CopyLink from './components/CopyLink';
import Social from './components/Social';
import {
  Container,
  Header,
  BodyParagraph,
  StyledTabs,
  StyledTabList,
  StyledTab,
  StyledTabPanel,
  TopContent
} from './styles';
import { GetBusinessDetailsQuery } from '../../../../graphql';
import { getDefaultEmailValues, socialMedias } from '../../constants';
import { SendEmailFormType } from '../../validationSchema';

export interface Props extends PreviewProps {
  business: GetBusinessDetailsQuery;
  interactable?: boolean;
  isEdit?: boolean;

  //Rewards App tab props
  onRewardsAppButtonClick?: () => void;

  //Email tab props
  emailInitialValues?: SendEmailFormType;
  onEmailButtonClick?: () => void;
  //Copy Link props
  onCopyLinkButtonClick?: () => void;
  //Social props
  onSocialButtonClick?: (socialMedia: socialMedias) => void;
}

const ReferralPreviewShare: FC<Props> = ({
  config,
  offer,
  business,
  interactable = true,
  onRewardsAppButtonClick,
  emailInitialValues,
  onEmailButtonClick,
  onCopyLinkButtonClick,
  onSocialButtonClick
}) => {
  const [tabIndex, setTabIndex] = useState<number>(3);

  const setTab = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      <TopContent>
        <Header>{config.states.share.config.title}</Header>
        <BodyParagraph>{config.states.share.config.body}</BodyParagraph>
      </TopContent>

      <StyledTabs selectedIndex={tabIndex} onSelect={setTab}>
        <StyledTabList>
          <StyledTab>Rewards App</StyledTab>
          <StyledTab>Email</StyledTab>
          <StyledTab>Link</StyledTab>
          <StyledTab>Social</StyledTab>
        </StyledTabList>
        <StyledTabPanel>
          {/* Rewards App */}
          <RewardsApp
            config={config}
            business={business}
            offer={offer}
            onButtonClick={onRewardsAppButtonClick}
            interactable={interactable}
          />
          {!interactable && <GlassPanel className="no-cursor"/>}
        </StyledTabPanel>
        <StyledTabPanel>
          {/* Email */}
          <Email
            config={config}
            initialValues={
              emailInitialValues ||
              getDefaultEmailValues(
                business?.getCurrentBusiness?.businessName || '',
                offer?.title || '',
              )
            }
            onButtonClick={onEmailButtonClick}
          />
          {!interactable && <GlassPanel className="no-cursor"/>}
        </StyledTabPanel>
        <StyledTabPanel>
          {/* Link */}
          <CopyLink
            config={config}
            onButtonClick={onCopyLinkButtonClick}
          ></CopyLink>
          {!interactable && <GlassPanel className="no-cursor"/>}
        </StyledTabPanel>
        <StyledTabPanel>
          {/* Social */}
          <Social
            config={config}
            business={business}
            offer={offer}
            onButtonClick={onSocialButtonClick}
          >
          </Social>  
          {!interactable && <GlassPanel className="no-cursor" />}
        </StyledTabPanel>
      </StyledTabs>
      <PreviewFooter />
    </Container>
  );
};

export default ReferralPreviewShare;
