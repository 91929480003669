import React from 'react';
import omitDeep from 'omit-deep-lodash';
import { pageType } from 'components/ReferralReward/types';
import { PageFormType } from 'components/ReferralReward/validationSchema';
import ReferralPreviewJoin from 'components/ReferralReward/components/ReferralPreviewJoin';
import ReferralPreviewShare from 'components/ReferralReward/components/ReferralPreviewShare';
import ReferralPreviewThankYou from 'components/ReferralReward/components/ReferralPreviewThankYou';
import { ReferralPage, Offer, GetBusinessDetailsQuery } from '../graphql';

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

export const getLogoURL = (logoField: PageFormType['pageStyle']['background']['backgroundImage']) => {
  if(S3_DOMAIN && logoField?.indexOf(S3_DOMAIN) === 0) {
    return logoField || null;
  } else {
    return logoField ? `${S3_DOMAIN}/${logoField}` : null;
  }
  
};

export const getTypeLabel = (type: pageType, capitalize?: boolean) => {
  let result = '';
  switch (type) {
    case pageType.join:
      result = 'join program';
      break;
    case pageType.share:
      result = 'share opportunity';
      break;
    case pageType.thankYou:
      result = 'thank you messaging';
      break;
  }

  result = capitalize
    ? result.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
    : result;
  return result;
};

export const normalizeFormValues = (values: PageFormType): any => {
  let result = {
    states: values.states,
    pageStyle: values.pageStyle,
    shopifyPageConfig: {
      title: values.shopifyPageConfig?.title,
      isHidden: values.shopifyPageConfig?.isHidden,
    }
  };
  if (result.shopifyPageConfig) {
    result.shopifyPageConfig.isHidden =
      values?.shopifyPageConfig?.isHidden === 'true' ||
      values?.shopifyPageConfig?.isHidden === true
        ? true
        : false;
  }
  return omitDeep(result, '__typename');
};

export const convertToFormValues = (data: ReferralPage) => {
  return omitDeep({ ...(data || {}), pageType: 'join' }, '__typename');
};

export const getTemplate = (type: pageType, offers: Offer[], business: GetBusinessDetailsQuery, values?: any ) => {
  const templates = {
    [pageType.join]: ReferralPreviewJoin,
    [pageType.share]: ReferralPreviewShare,
    [pageType.thankYou]: ReferralPreviewThankYou
  };

  const _config = Object.assign({}, values);
  if (offers?.length) {
  }
  const resultTemplate = templates[type];
  return React.createElement(
    resultTemplate,
    { config: _config, offer: offers[0], interactable: false, business: business },
    null
  );
};

export const getReviewAndPublishCodeSnippet = (url: string) => {
  const chunks = url.split('/');
  chunks.splice(-2, 0 , 'iframe');
  const resultUrl = chunks.join('/');
  return `<iframe style="width: 100%; height: 700px" src="${resultUrl}"></iframe>`;
};