
const trackedElementsIds = {
  logInWithShopifyButton: 'drum_logInWithShopifyButton',
  forgotPasswordButton: 'drum_forgotPasswordButton',
  logInWithEmail: {
    logInButton: 'drum_logInWithEmail_logInButton',
    emailInput: 'drum_logInWithEmail_emailInput'
  },
  signUp: {
    signUpButton: 'drum_signUp_signUpButton',
    emailInput: 'drum_signUp_emailInput'
  },
  completionSteps: {
    completeBusinessProfileLink:
      'drum_completionSteps_completeBusinessProfileLink',
    createDraftOpportunityLink:
      'drum_completionSteps_createDraftOpportunityLink',
    setupCustomerTrackingLink: 'drum_completionSteps_setupCustomerTrackingLink',
    choosePaymentMethodLink: 'drum_completionSteps_choosePaymentMethodLink'
  },
  inviteCustomers: {
    banner: {
      learnMoreButton: 'drum_inviteCustomers_banner_learnMoreButton'
    },
    modal: {
      copyToClipboardButton: 'drum_inviteCustomers_modal_copyToClipboardButton',
      title: 'drum_inviteCustomers_modal_title',
      customInvitationLinkTitle:
        'drum_inviteCustomers_modal_customInvitationLinkTitle',
      previewButton: 'drum_inviteCustomers_modal_previewButton'
    }
  },
  topMenuBar: {
    dashboardLink: 'drum_topMenuBar_dashboardLink',
    opportunitiesLink: 'drum_topMenuBar_opportunitiesLink'
  },
  accountMenuButton: 'drum_accountMenuButton',
  accountMenu: {
    menuItemId(linkName: string) {
      return `drum_accountMenu_${linkName}`;
    }
  },
  performanceMenu: {
    metricId(idName: string) {
      return `drum_performanceMenu_${idName}`
    }
  }
};

export default trackedElementsIds;
