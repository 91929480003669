import React, { FC, useState, ChangeEvent } from 'react';
import { ColorResult, Color, SketchPicker } from 'react-color';
import { Container, PickerContainer, ButtonsContainer, ApplyButton, CancelButton } from './styles';

export interface Props {
  initialColor: string;
  onChange?: (color: ColorResult) => void;
  onApply?: (color: Color) => void;
  onCancel?: () => void;
}

const ColorPicker: FC<Props> = ({initialColor , onChange, onApply, onCancel}) => {
  const [color, setColor] = useState<Color>(initialColor);

  const handleChange = (color: ColorResult, event: ChangeEvent<HTMLInputElement>) => {
    setColor(color.hex);
    onChange && onChange(color)
  }
  const handleApply = () => {
    onApply && onApply(color)
  }
  const handleCancel = () => {
    onCancel && onCancel();
  }

  return (
    <Container>
      <PickerContainer>
        <SketchPicker color={color} onChange={handleChange}/>
      </PickerContainer>
      <ButtonsContainer>
        <ApplyButton onClick={handleApply}>Apply</ApplyButton>
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

export default ColorPicker;
