import styled from 'styled-components';
import {
  ButtonTextMixin,
  LinkTextMixin,
  PrimaryButton
} from 'uikit';

export const FormHeading = styled.h3`
  ${ButtonTextMixin};
`;

export const ReturnButton = styled.button`
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 0;
  border: none;
  text-align: left;
  background-color: transparent;
  ${LinkTextMixin};
`;

export const Form = styled.form`
  position: relative;
`;

export const InputGroup = styled.div`
  :not(:last-of-type) {
    margin-bottom: 30px;
  }

  :last-of-type {
    margin-bottom: 25px;
  }
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
`;
