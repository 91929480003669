import React, { FC, useState, useEffect } from 'react';
import { Modal, Loader, FullScreenLoader } from 'uikit';
import { ErrorText } from './styles';
import { isShopifyWithDefaultCreditCard } from 'helpers/billing';
import {
  useGetCurrentBusinessPaymentsLazyQuery,
  useDeleteCreditCardMutation,
  Payment,
  DeleteCreditCardMutation
} from '../../graphql';

interface Props {
  onClose: (payments?: Payment[]) => void;
  onSuccess?: (payments?: Payment[]) => void;
  check: boolean;
  performIntegrationCheck?: boolean;
  title?: string;
}

const CreditCardDeletionModal: FC<Props> = ({
  onClose,
  onSuccess,
  check = false,
  performIntegrationCheck = true,
  title = 'You cannot use credit card as a payment method with Shopify integration. We’ll delete your credit card details. Please use the PayPal account as a payment method'
}) => {
  const [
    getPayments,
    { data: paymentsData, loading: paymentsDataLoading }
  ] = useGetCurrentBusinessPaymentsLazyQuery({
    fetchPolicy: 'cache-first'
  });

  const [
    deleteCreditCard,
    { loading: deleteCreditCardLoading, error: deleteCreditCardError }
  ] = useDeleteCreditCardMutation({
    onCompleted: (data: DeleteCreditCardMutation) => {
      onSuccess &&
        onSuccess(
          data?.deleteCreditCard ? [data?.deleteCreditCard as Payment] : []
        );
      setIsOpen(false);
    }
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onMainButtonClick = () => {
    deleteCreditCard();
  };
  const handleClose = (payments?: Payment[] | null) => {
    setIsOpen(false);
    onClose && onClose(payments || []);
  };

  useEffect(() => {
    if (check) {
      getPayments();
    }
    // eslint-disable-next-line
  }, [check]);

  useEffect(() => {
    if (!paymentsData?.getCurrentBusiness || !check) return;
    if (
      !performIntegrationCheck ||
      isShopifyWithDefaultCreditCard(
        paymentsData.getCurrentBusiness?.integrationType,
        (paymentsData?.getCurrentBusiness?.payments as Payment[]) || []
      )
    ) {
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [paymentsData]);

  return (
    <>
      <Modal
        type="warning"
        title={title}
        mainButton={
          !deleteCreditCardLoading && !deleteCreditCardError
            ? { text: 'Okay', onClick: onMainButtonClick }
            : undefined
        }
        isOpen={isOpen}
        onClose={() =>
          handleClose(paymentsData?.getCurrentBusiness?.payments as Payment[])
        }
      >
        <>
          {deleteCreditCardLoading && <Loader />}
          {deleteCreditCardError && (
            <ErrorText>Trouble removing your credit card</ErrorText>
          )}
        </>
      </Modal>
      {(paymentsDataLoading || deleteCreditCardLoading) && <FullScreenLoader />}
    </>
  );
};

export default CreditCardDeletionModal;
