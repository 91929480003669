import { generatePath } from 'react-router-dom';
import { History } from 'history';
import routes from 'constants/routes';
import { OPPORTUNITY_DASHBOARD_PARAM } from 'constants/opportunityQueryParams';

export const redirectToEditOpportunity = (id: string, history: History) => {
  const path = generatePath(routes.EDIT_OPPORTUNITY, { id });
  history.push(path);
};

export const redirectToOpportunityDashboard = (
  id: string,
  history: History
) => {
  const path = `${routes.DASHBOARD}?${OPPORTUNITY_DASHBOARD_PARAM}=${id}`;
  history.push(path);
};
