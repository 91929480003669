import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, BackButton, SaveDraftButton, SubmitButton } from './styles';
import routes from 'constants/routes';
import Tooltip from 'rc-tooltip';

interface Props {
  onPublish: () => void;
  onSaveDraft: () => void;
  publishDisabled: boolean
}

const FormFooter: FC<Props> = ({ onPublish, onSaveDraft, publishDisabled }) => {
  const history = useHistory();

  return (
    <Container>
      <BackButton onClick={() => history.push(routes.DASHBOARD)} type="button">
        Back
      </BackButton>
      <SaveDraftButton onClick={onSaveDraft} type="button">
        Save Draft
      </SaveDraftButton>
      {/* <SubmitButton type="button" disabled={publishDisabled} onClick={onPublish}> */}
      {publishDisabled ? 
        <Tooltip
          placement="top" trigger={['hover']} overlay={<span>Before you can Publish this opportunity, you must complete all steps of account setup.</span>}
        >  
            <span>
            <SubmitButton type="button" disabled={publishDisabled} onClick={onPublish}>
              Publish
            </SubmitButton>
            </span>
        </Tooltip>
        :
        <span>
          <SubmitButton type="button" disabled={publishDisabled} onClick={onPublish}>
            Publish
          </SubmitButton>
        </span>
      }

      
    </Container>
  );
};

export default FormFooter;
