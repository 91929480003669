import styled from 'styled-components';
import Arrow from '../../../../assets/svg/Arrow';
import { breakpoint } from '../../../../helpers';
import { SortOrder } from 'components/ListOpportunities/constants';
import { ITheme } from 'types';

export const Container = styled.td`
  ${breakpoint('md', 'lg')`
  :nth-child(n+2) {
    padding-right: 4px;
    text-align: center;
  }`};
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  ${breakpoint('md', 'lg')`
   font-size: ${({ theme }) => theme.size.caption};
  `};
`;

export const Button = styled.button`
  border: 0;
  background: transparent;
`;

interface ArrowIconProps extends ITheme {
  sortOrder: SortOrder | boolean;
}

export const ArrowIcon = styled(Arrow)<ArrowIconProps>`
  fill: #a4a4a4;
  transform: ${({ sortOrder }) =>
    sortOrder === SortOrder.DESC ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
