import styled from 'styled-components';
import { Label, Body2, H4, LinkTextMixin, PrimaryButton, OutlinedButton, InputBase } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
	${breakpoint('lg')`
		padding: 25px 45px 25px 45px;
	`}

	${breakpoint('xs', 'lg')`
		padding: 25px 10px;
	`}
`;
export const Content = styled.div`
	margin-top: 50px;
	display: flex;
	flex-direction: row;
`;
export const LeftBlock = styled.div`
	flex: 1;
	padding-right: 68px;

	${breakpoint('xs', 'lg')`
		padding-right: 0;
	`}
`;
export const RightBlock = styled.div`
	width: 490px;
	${breakpoint('xs', 'lg')`
		display: none;
	`}
`;
export const StyledLabel = styled(Label)`
  margin-bottom: 5px;
`;
export const Paragraph = styled(Body2)`
	color: ${({ theme }) => theme.colors.bodyTextColor};
	max-width: 560px;
	line-height: 24px;
	&.alt {
		color: ${({ theme }) => theme.colors.black};
	}
	&.label {
		margin-left: 10px;
	}

	.bold {
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}

	${breakpoint('xs', 'lg')`
		max-width: 999px;
	`}
`;
export const Subheader = styled(H4)`
	margin: 38px 0 15px 0;
`;
export const StyledLink = styled.a`
	${LinkTextMixin};
	font-size: ${({ theme }) => theme.size.body2};
	color: ${({ theme }) => theme.colors.accentSecondaryColor};
	cursor: pointer;
`;

export const InputBlock = styled.div`
	
`;
export const InputBlockInner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
export const InputBlockButtons = styled.div`
	display: flex;
	margin-top: 16px;
	${breakpoint('xs', 'lg')`
    justify-content: space-between;
  `}
`;
export const IBILeft = styled.div`
	flex: 1;
	position: relative;
`;
export const IBIRight = styled.div`
	
`;

export const InputGroup = styled.div`
  
`;
export const StyledInput = styled(InputBase)`
	${breakpoint('xs', 'lg')`
		font-size: 10px;
		padding: 11px 19px 11px 7px;
	`}
`;

export const CopyButton = styled(OutlinedButton)`
	width: 90px;

	margin-left: 20px;

	color: #17AACF;
  border-color: #17AACF;

  :hover,
  :active,
  :focus {
    color: #4cc2e0;
    border-color: #4cc2e0;
	}
	
	${breakpoint('xs', 'lg')`
		margin-left: 6px;
		padding: 4px 0;
	`}
`;
export const VisitButton = styled(PrimaryButton)`
	width: 170px;
	margin-right: 12px;
	${breakpoint('xs', 'lg')`
		width: 48%;
		margin-right: 0;
  `}
`;
export const EditButton = styled(PrimaryButton)`
	width: 170px;
	${breakpoint('xs', 'lg')`
		width: 48%;
		margin-right: 0;
  `}
`;
export const CustomizeButton = styled(PrimaryButton)`
	width: 257px;
	margin-top: 50px;

	${breakpoint('xs', 'lg')`
		width: 100%;
	`}
`;
export const PreviewContainer = styled.div`
	width: 980px;
	transform: scale(0.5);
	transform-origin: top left;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
`;
export const PreviewButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 11px;
  padding: 0;
  border: none;
  border-radius: 50%;
	background-color: transparent;
	z-index: 2;

  ${breakpoint('lg')`
    display: none;
  `}
`;
export const MobilePreviewContainer = styled.div`
	width: 100%;
`;