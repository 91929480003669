import React, { FC, useRef, useCallback, useEffect, useState } from 'react';
import BraintreeWebDropIn from 'braintree-web-drop-in';
import Braintree from 'braintree-web';
import { Loader } from 'uikit';

export enum DropInStates {
  SHOW_FORM,
  REQUEST_PAYMENT,
  PAYMENT_REQUESTED
}

export enum DropInPaymentMethods {
  CARD,
  PAYPAL
}

interface DropInProps {
  state: DropInStates;
  token: string;
  handlePaymentMethodPayload: (payload: any) => void;
  handlePaypalPopupClosed: () => void;
  paymentMethod: DropInPaymentMethods;
}

interface DropInInstance {
  teardown: () => Promise<void>;
  requestPaymentMethod: () => Promise<any>;
  on: (eventName: string, callback: (payload: any) => void) => void;
  isPaymentMethodRequestable: () => boolean;
}

const makePaymentMethodOptions = (paymentMethod: DropInPaymentMethods) => {
  switch (paymentMethod) {
    case DropInPaymentMethods.CARD:
      return {
        card: {
          cardholderName: true,
          vault: {
            allowVaultCardOverride: false,
            vaultCard: true
          }
        }
      };
    case DropInPaymentMethods.PAYPAL: {
      return {
        card: false,
        paypal: {
          flow: 'vault'
        }
      };
    }
    default:
      throw new Error(`Bad paymentMethodOption: ${paymentMethod}`);
  }
};

const DropIn: FC<DropInProps> = ({
  state,
  token,
  handlePaymentMethodPayload,
  handlePaypalPopupClosed,
  paymentMethod
}) => {
  const containerElRef = useRef<HTMLDivElement | null>(null);
  const dropInInstanceRef = useRef<DropInInstance | null>(null);

  const [dropinLoading, setDropinLoading] = useState<boolean>(false);
  const [paypalLoading, setPaypalLoading] = useState<boolean>(false);

  const createBrainTreeDropIn = async () => {
    if (dropInInstanceRef.current) {
      return;
    }

    const paymentMethodOptions = makePaymentMethodOptions(paymentMethod);
    dropInInstanceRef.current = await BraintreeWebDropIn.create({
      container: containerElRef.current,
      authorization: token,
      ...paymentMethodOptions
    });

    if (!dropInInstanceRef.current) {
      throw new Error(
        `Drop-in instance is falsy: ${dropInInstanceRef.current}`
      );
    }
  };

  const createPaypalWindow = async () => {
    try {
      setPaypalLoading(true);

      const braintreeClient = await Braintree.client.create({
        authorization: token
      });
      const paypalInstance = await Braintree.paypal.create({
        client: braintreeClient
      });
      const payload = await paypalInstance.tokenize({
        flow: 'vault'
      });
      handlePaymentMethodPayload(payload);
    } catch (err) {
      console.log('createPaypalWindow::err', err);
      if (err.code === 'PAYPAL_POPUP_CLOSED') {
        handlePaypalPopupClosed();
        return;
      }
      throw err;
    } finally {
      setPaypalLoading(false);
    }
  };

  const createDropInForm = async (): Promise<void> => {
    try {
      setDropinLoading(true);
      if (paymentMethod === DropInPaymentMethods.PAYPAL) {
        await createPaypalWindow();
      } else {
        await createBrainTreeDropIn();
      }
    } catch (err) {
      console.log('createDropInForm::err', err);
    } finally {
      setDropinLoading(false);
    }
  };

  useEffect(() => {
    if (
      !dropInInstanceRef.current &&
      typeof token === 'string' &&
      state !== DropInStates.PAYMENT_REQUESTED &&
      dropinLoading === false
    ) {
      createDropInForm();
    }
    // eslint-disable-next-line
  }, [dropinLoading, state, token]);

  const requestPaymentMethodAndCallback = useCallback(async () => {
    if (!dropInInstanceRef.current?.isPaymentMethodRequestable()) {
      // TODO: handle this
      return;
    }
    const payload = await dropInInstanceRef.current!.requestPaymentMethod();
    handlePaymentMethodPayload(payload);
  }, [handlePaymentMethodPayload]);

  useEffect(() => {
    if (state === DropInStates.REQUEST_PAYMENT) {
      requestPaymentMethodAndCallback();
    }
  }, [state, requestPaymentMethodAndCallback]);

  return (
    <>
      {paypalLoading && (
        <div className="connect-credit-card-content-text">
          <Loader />
        </div>
      )}
      <div ref={containerElRef}></div>
    </>
  );
};

export default DropIn;
