import styled from 'styled-components';
import { PrimaryButton, H2, LinkTextMixin } from 'uikit';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${breakpoint('lg')`
    padding: 30px 60px 60px;
  `}

  ${breakpoint('md', 'lg')`
    padding: 25px 20px 50px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px 25px 90px;
    margin: 0 auto;
    border-radius: 8px;
  `};
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Content = styled.div`
  
`;
export const Title = styled(H2)`
  margin: 34px 0;
`;

export const TemplateListContainer = styled.div`
  
`;
export const TemplateList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;
export const TemplateListMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  ${breakpoint('lg')`
    display: none;
  `}
`;
export const TemplateItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > div:first-of-type {
    width: 100%;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    

    & > div {
      border-radius: 0;
      border: none !important;
      & > div > div {
        border-radius: 0;
        & > div {
          border-radius: 3px 3px 0 0;
        }
      }
    }
  }

  ${breakpoint('lg')`
    flex: 0.31;
  `}
`;
export const TemplateDescription = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  color: #7c7c7c;
  width: 175px !important;
  text-align: center;
  margin-top: 30px;
`;
export const TemplatePreviewButton = styled(PrimaryButton)`
  width: 270px;
  margin: 30px 0;
`;
export const Slide = styled.div`
  box-sizing: border-box;
  padding: 0 30px !important;
`;
export const ViewExamplesLink = styled.a`
  ${LinkTextMixin};
`;
export const ViewContainer = styled.div`
  position: relative;
`;
