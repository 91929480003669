import React, { FC } from 'react';
import IconProps from './IconProps';

const Google: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M29.4036 15.3444C29.4036 14.3247 29.3209 13.2996 29.1446 12.2964H14.9961V18.0727H23.0983C22.762 19.9356 21.6818 21.5836 20.0999 22.6308V26.3788H24.9336C27.7722 23.7662 29.4036 19.9081 29.4036 15.3444Z"
          fill="#4285F4"
        />
        <path
          d="M14.9981 29.9999C19.0437 29.9999 22.4554 28.6716 24.9412 26.3788L20.1074 22.6308C18.7626 23.5458 17.0264 24.0639 15.0036 24.0639C11.0904 24.0639 7.77233 21.4238 6.58181 17.8743H1.59375V21.7379C4.14014 26.8032 9.32662 29.9999 14.9981 29.9999Z"
          fill="#34A853"
        />
        <path
          d="M6.57427 17.8744C5.94594 16.0114 5.94594 13.9941 6.57427 12.1312V8.26752H1.59172C-0.535782 12.506 -0.535782 17.4996 1.59172 21.738L6.57427 17.8744Z"
          fill="#FBBC04"
        />
        <path
          d="M14.9981 5.93604C17.1366 5.90297 19.2035 6.70767 20.7523 8.1848L25.0348 3.90223C22.3231 1.35584 18.724 -0.0441201 14.9981 -2.67652e-05C9.32661 -2.67652e-05 4.14014 3.19674 1.59375 8.26747L6.57629 12.1312C7.7613 8.57613 11.0848 5.93604 14.9981 5.93604Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="29.4047" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Google;
