import styled from 'styled-components';
import { Body2, Body3, PrimaryButton } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`

`;
export const InputTitle = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
export const InputContainer = styled.div`
  width: 440px;
  margin-right: 25px;
  ${breakpoint('xs', 'md')`
    width: 100%;
    margin-right: 0;
  `}
`;
export const ButtonContainer = styled.div`
  ${breakpoint('xs', 'md')`
    width: 100%;
    margin: 8px 0;
  `}
`;
export const SendButton = styled(PrimaryButton)`
  width: 170px;
  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
`;
export const SubText1 = styled(Body2)`
  margin-top: 5px;
`;
export const SubText2 = styled(Body3)`
  font-style: italic;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'md')`
    flex-direction: column
  `}
`;