import React, { FC } from 'react';
import IconProps from './IconProps';

const Google: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="30"
      height="25"
      fill="none"
      viewBox="0 0 30 25"
    >
      <path
        fill="#03A9F4"
        d="M30 2.886c-1.116.489-2.304.813-3.544.97A6.116 6.116 0 0029.162.459a12.292 12.292 0 01-3.9 1.488 6.148 6.148 0 00-10.639 4.206c0 .487.041.956.143 1.402-5.114-.249-9.638-2.7-12.677-6.433a6.192 6.192 0 00-.842 3.109 6.16 6.16 0 002.732 5.111A6.074 6.074 0 011.2 8.584v.067c0 2.989 2.132 5.472 4.928 6.043-.501.137-1.047.203-1.613.203-.394 0-.791-.023-1.164-.105.796 2.435 3.058 4.226 5.747 4.284A12.358 12.358 0 011.471 21.7c-.504 0-.988-.022-1.472-.084a17.314 17.314 0 009.435 2.76C20.753 24.375 26.94 15 26.94 6.874c0-.272-.01-.535-.023-.795A12.27 12.27 0 0030 2.886z"
      ></path>
    </svg>
  );
};

export default Google;
