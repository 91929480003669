//export type emailType = "invitationEmail" | "reminderEmail" | "newOpportunityEmail";
export enum emailType {
  invitationEmail = 'invitationEmail',
  reminderEmail = 'reminderEmail',
  newOpportunityEmail = 'newOpportunityEmail'
}
export enum sendType {
  all = 'all',
  tags = 'tags',
}
export type SetField = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;
  
