import { FC } from 'react'
import { FormattingFailed, IncorrectFileType, SizeExceeded, UnknownError } from '../components'

export const errorCodes = {
  SIZE_EXCEEDED: 'sizeExceeded',
  INCORRECT_FILE_TYPE: 'incorrectFileType',
  FORMATTING_FAILED: 'formattingFailed',
  UNKNOWN_ERROR: 'unknownError',
}

export interface IUploadError {
  code: string;
  message: string;
  component: FC
}

export const sizeExceeded: IUploadError = {
  code: errorCodes.SIZE_EXCEEDED,
  message: 'File size too large.',
  component: FormattingFailed
};
export const incorrectFileType: IUploadError = {
  code: errorCodes.INCORRECT_FILE_TYPE,
  message: 'Incorrect file type',
  component: IncorrectFileType
};
export const formattingFailed: IUploadError = {
  code: errorCodes.FORMATTING_FAILED,
  message: 'CSV Formatting Problem',
  component: SizeExceeded
};
export const unknownError: IUploadError = {
  code: errorCodes.UNKNOWN_ERROR,
  message: 'Unknown Error occured',
  component: UnknownError
};

export const getErrorByCode = (code: string) => {
  switch(code) {
    case 'sizeExceeded': 
      return sizeExceeded;
    case 'incorrectFileType': 
      return incorrectFileType;
    case 'formattingFailed': 
      return formattingFailed;
    default: 
      return unknownError;
  }

}