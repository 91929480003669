import React, { FC } from 'react';
import IconProps from './IconProps';

const Minus: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="2"
    fill="none"
    viewBox="0 0 18 2"
  >
    <rect width="18" height="2" fill="#000" rx="1"></rect>
  </svg>
);

export default Minus;
