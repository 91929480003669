import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  useGeneratePaymentTokenMutation,
  useSavePaymentMethodNonceMutation,
  useGetCurrentBusinessPaymentsLazyQuery,
  PaymentProvider
} from '../../../graphql';
import {
  SubmitButton,
  DesktopReturnButton,
  MobileReturnButton,
  StyledChevron,
  TwoButtonsContainer,
} from '../styles';
import ReactModal from 'react-modal';
import { Modal } from 'uikit';
import { ModalProps } from 'uikit/Modal/Modal';
import { Loader } from 'uikit';

import DropIn, { DropInStates, DropInPaymentMethods } from './DropIn';
import GetCurrentPaymentsFailed from './GetCurrentPaymentsFailed/GetCurrentPaymentsFailed';

interface ConnectCreditCardContainerProps {
  handleClose: () => void;
  handlePaymentMethodConnected: () => void;
  paymentMethod: DropInPaymentMethods;
}

const selectProviderPramSaveNonceCall = (
  paymentMethod: DropInPaymentMethods
) => {
  switch (paymentMethod) {
    case DropInPaymentMethods.CARD:
      return PaymentProvider.Credit;
    case DropInPaymentMethods.PAYPAL:
      return PaymentProvider.Paypal;
    default:
      throw new Error(`Bad payment method: ${paymentMethod}`);
  }
};

const SUCCESS_MODAL_CLOSE_TIMEOUT = 3000;

const modalIDs = {
  LOADING: 'loading',
  SAVE_ERROR: 'savePaymentMethodDataError',
  BUSINESS_PAYMENTS_ERROR: 'currentBusinessPaymentsError'
};

interface BillingModalProps extends ModalProps {
  children?: FC | null;
  id: string;
}

const defaultModalData: BillingModalProps = {
  id: '',
  type: 'warning',
  title: '',
  mainButton: {
    text: 'Okay',
    onClick: () => {}
  },
  isOpen: false,
  onClose: () => {},
  canClose: false,
  children: null
};

const ConnectCreditCardContainer: FC<ConnectCreditCardContainerProps> = ({
  handleClose,
  handlePaymentMethodConnected,
  paymentMethod
}) => {
  const [dropInState, setDropInState] = useState<DropInStates>(
    DropInStates.SHOW_FORM
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPaymentMethodConnected, setShowPaymentMethodConnected] = useState<
    boolean
  >(false);
  const [modalData, setModalData] = useState<BillingModalProps>(
    defaultModalData
  );

  const openModal = (options: any) => {
    setModalData({ ...modalData, ...options, isOpen: true });
  };
  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };

  const [
    generatePaymentToken,
    {
      data: generatePaymentTokenData,
      loading: generatePaymentTokenLoading,
      error: generatePaymentTokenError,
      called: generatePaymentTokenCalled
    }
  ] = useGeneratePaymentTokenMutation();

  const [
    savePaymentMethod,
    {
      data: savePaymentMethodData,
      loading: savePaymentMethodDataLoading,
      error: savePaymentMethodDataError
    }
  ] = useSavePaymentMethodNonceMutation({
    onError: (error) => {
      //On paypal save error
      let modalAdditionalConfig: BillingModalProps = {
        id: modalIDs.SAVE_ERROR,
        childrenBeforeButtons: false,
        isOpen: true
      };
      switch (error.message) {
        case 'Payment method nonces cannot be used to update an existing PayPal account.':
        case 'GraphQL error: Payment method nonces cannot be used to update an existing PayPal account.':
          modalAdditionalConfig = {
            ...modalAdditionalConfig,
            title: 'You can add only one PayPal account',
            children: null,
            mainButton: { text: 'Okay', onClick: closeModal },
          };
          break;
        case 'Do Not Honor':
          modalAdditionalConfig = {
            ...modalAdditionalConfig,
            title: 'Trouble connecting your payment method.',
            mainButton: { text: 'Please try again', onClick: closeModal },
            children: GetCurrentPaymentsFailed,
          };
          break;
        case 'Gateway Rejected: avs':
          modalAdditionalConfig = {
            ...modalAdditionalConfig,
            title:
              'Our payment processor was unable to verify your card’s billing address with your bank. Please double check your information and try again or reach out to your bank directly to confirm your billing address.',
            children: null,
            mainButton: { text: 'Okay', onClick: closeModal },
          };
          break;
        default:
          modalAdditionalConfig = {
            ...modalAdditionalConfig,
            title: 'Trouble connecting your payment method.',
            mainButton: { text: 'Please try again', onClick: closeModal },
            children: GetCurrentPaymentsFailed,
          };
          break;
      }
      openModal({
        type: 'warning',
        mainButton: undefined,
        onClose: closeModal,
        canClose: true,
        ...modalAdditionalConfig
      });
    }
  });

  const [
    getCurrentBusinessPayments,
    {
      data: currentBusinessPaymentsData,
      loading: getCurrentBusinessPaymentsLoading,
      error: currentBusinessPaymentsError
    }
  ] = useGetCurrentBusinessPaymentsLazyQuery();

  useEffect(() => {
    if (generatePaymentTokenCalled && generatePaymentTokenData) {
      return;
    }
    generatePaymentToken();
  }, [
    generatePaymentToken,
    generatePaymentTokenCalled,
    generatePaymentTokenData
  ]);

  useEffect(() => {
    if (!currentBusinessPaymentsError) return;
    openModal({
      id: modalIDs.BUSINESS_PAYMENTS_ERROR,
      type: 'warning',
      title: 'Trouble connecting your payment method',
      mainButton: { text: 'Please try again', onClick: closeModal },
      onClose: closeModal,
      children: <GetCurrentPaymentsFailed />,
      canClose: true
    });
    // eslint-disable-next-line
  }, [currentBusinessPaymentsError]);

  useEffect(() => {
    if (
      (generatePaymentTokenLoading ||
        savePaymentMethodDataLoading ||
        getCurrentBusinessPaymentsLoading) &&
      !savePaymentMethodDataError &&
      !currentBusinessPaymentsError
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [
    generatePaymentTokenLoading,
    savePaymentMethodDataLoading,
    getCurrentBusinessPaymentsLoading,
    savePaymentMethodDataError,
    currentBusinessPaymentsError
  ]);
  useEffect(() => {
    if (isLoading) {
      openModal({
        id: modalIDs.LOADING,
        type: 'progress',
        title: 'Setting up your account…',
        mainButton: undefined,
        onClose: closeModal,
        children: null,
        canClose: false
      });
    } else {
      if (modalData.id === modalIDs.LOADING) {
        closeModal();
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleLinkCard = () => {
    setDropInState(DropInStates.REQUEST_PAYMENT);
  };

  const handlePaymentMethodPayload = useCallback(
    async (payload: any) => {
      setDropInState(DropInStates.PAYMENT_REQUESTED);

      const { nonce } = payload;
      await savePaymentMethod({
        variables: {
          input: {
            provider: selectProviderPramSaveNonceCall(paymentMethod),
            paymentMethodNonce: nonce
          }
        }
      });
      // TODO: handle errors in response
      getCurrentBusinessPayments();
    },
    [paymentMethod, savePaymentMethod, getCurrentBusinessPayments]
  );

  const handleShowPaymentModalClose = () => {
    handlePaymentMethodConnected();
    setShowPaymentMethodConnected(true);
  }

  useEffect(() => {
    if (currentBusinessPaymentsData && savePaymentMethodData) {
      setShowPaymentMethodConnected(true);
    }
    setTimeout(() => {
      showPaymentMethodConnected && handleShowPaymentModalClose();
    }, SUCCESS_MODAL_CLOSE_TIMEOUT);
    // eslint-disable-next-line
  }, [currentBusinessPaymentsData, savePaymentMethodData]);

  const token = generatePaymentTokenData?.generatePaymentToken?.token;
  if (!token) {
    return (
      <div className="connect-credit-card-content">
        <div className="connect-credit-card-content-text">
          {generatePaymentTokenLoading && <Loader />}
          {generatePaymentTokenError && "Didn't get the token"}
        </div>
        <DesktopReturnButton type="button" onClick={handleClose}>
          Close
        </DesktopReturnButton>
      </div>
    );
  }

  return (
    <div className="connect-credit-card-content">
      <DropIn
        state={dropInState}
        token={token}
        paymentMethod={paymentMethod}
        handlePaymentMethodPayload={handlePaymentMethodPayload}
        handlePaypalPopupClosed={handleClose}
      ></DropIn>
      <TwoButtonsContainer>
        <MobileReturnButton type="button">
          <StyledChevron />
        </MobileReturnButton>
        <DesktopReturnButton type="button" onClick={handleClose}>
          Close
        </DesktopReturnButton>
        <SubmitButton type="submit" onClick={handleLinkCard}>
          Connect
        </SubmitButton>
      </TwoButtonsContainer>
      <Modal {...modalData} />
      <Modal
        isOpen={showPaymentMethodConnected}
        type="success"
        title="Payment method connected"
        onClose={handleShowPaymentModalClose}
      />
    </div>
  );
};

interface ConnectCreditCardModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handlePaymentMethodConnected: () => void;
  paymentMethod: DropInPaymentMethods;
}

const ConnectPaymentMethodModal: FC<ConnectCreditCardModalProps> = ({
  isOpen,
  handleClose,
  handlePaymentMethodConnected,
  paymentMethod
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      className="connect-credit-card-modal"
      overlayClassName="connect-credit-card-overlay"
    >
      <ConnectCreditCardContainer
        handleClose={handleClose}
        handlePaymentMethodConnected={handlePaymentMethodConnected}
        paymentMethod={paymentMethod}
      />
    </ReactModal>
  );
};

export default ConnectPaymentMethodModal;
