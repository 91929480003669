import styled from 'styled-components';
import { PrimaryButton, SecondaryButton, Body1, LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  
`;
export const Paragraph = styled(Body1)`
  margin-bottom: 20px;
`;
export const SnippetButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 105px;

  ${breakpoint('xs', 'lg')`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const ReturnLink = styled.a`
  width: 100%;
  ${LinkTextMixin};
  display: inline-block;
  margin-bottom: 30px; 
  cursor: pointer;

  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const SnippetButton = styled(PrimaryButton)`
  width: 150px;
  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;
export const VerifyButton = styled(SecondaryButton)`
  width: 150px;
  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;
export const ShopifyButtonsContainer = styled.div`
  margin-top: 20px;
`;
export const PreviewShopifyPageButton = styled(SecondaryButton)`
  width: 100%;
  margin-bottom: 18px;
`;
export const CreateShopifyPageButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 84px;
`;
export const DoneButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 50px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  & > div {
    margin-right: 2px;
    min-width: 115px;
    margin-top: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  
`;

export const InputGroup = styled.div`
  margin-bottom: 5px;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FooterText = styled(Body1)`
  margin-bottom: 25px;
  max-width: 240px;
  text-align: center;
`;
export const FooterLink = styled.a`
  ${LinkTextMixin};
`;
