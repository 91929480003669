import styled from 'styled-components';

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;
  border: solid 1px #dedede;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export default IconButton;
