import styled from 'styled-components';
import BillingAccountsTable from '../../components/BillingAccountsTable';
import { breakpoint } from '../../helpers';
import PageHeading from '../../components/PageHeading';
import { PrimaryButton, SecondaryButton, LinkTextMixin } from 'uikit';
import { ChevronLeft } from 'assets/svg';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 40px 65px 35px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 0;
  `}
`;

export const HeadingContainer = styled.div`
  display: flex;

  ${breakpoint('md')`
    margin-bottom: 20px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 25px;
  `}
  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
`;

export const StyledHeading = styled(PageHeading)`
  flex: 1;
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.main};
`;

export const Content = styled.div`
  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
  ${breakpoint('md')`
    max-width: 550px;
  `}
  ${breakpoint('xl')`
    max-width: 850px;
  `}
`;

export const BillingInstructions = styled.p`
  line-height: 1.15;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};

  ${breakpoint('xs', 'md')`
    margin-bottom: 15px;
  `}

  ${breakpoint('md')`
    margin-bottom: 30px;
  `}
`;

export const BillingOptions = styled.div`
  margin-bottom: 40px;

  ${breakpoint('md')`
    display: flex;
  `}
`;

export const PaymentMethodPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 100px;
  border: solid 1px #d4d4d4;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  ${breakpoint('md')`
    :not(:last-child) {
      margin-right: 17px;
    }
  `}

  ${breakpoint('xs', 'md')`
    margin-left: auto;
    margin-right: auto;

    :not(:last-child) {
      margin-bottom: 7px;
    }
  `}
`;

export const ImageContainer = styled.div`
  margin-bottom: 10px;
`;

export const Image = styled.img`
  :not(:last-child) {
    margin-right: 24px;
  }
`;

export const Caption = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
`;

export const TwoButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SingleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 160px;
`;

export const StyledChevron = styled(ChevronLeft)`
  width: 15px;
  height: 15px;
  fill: currentColor;
`;

export const MobileReturnButton = styled.button`
  width: 42px;
  height: 42px;
  border: solid 2px ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  line-height: 42px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};

  ${breakpoint('sm')`
    display: none;
  `}
`;

export const DesktopReturnButton = styled(SecondaryButton)`
  width: 120px;

  ${breakpoint('xs', 'sm')`
    display: none;
  `}
`;


export const StyledTable = styled(BillingAccountsTable)`
  ${breakpoint('xs', 'md')`
    margin-bottom: 30px;
  `}

  ${breakpoint('md')`
    margin-bottom: 25px;
  `}
`;

export const AccountButton = styled.button`
  padding: 10px 22px;
  border: solid 2px #02a5ff;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #02a5ff;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 18px;

  ${breakpoint('xs', 'md')`
    margin-bottom: 110px;
  `}

  ${breakpoint('md')`
    margin-bottom: 85px;
  `}
`;
