import styled from 'styled-components';
import { PrimaryButton, Body3 } from 'uikit';

export const Container = styled.div`
  position: relative;
  margin-top: -40px;
  text-align: initial;
`;
export const ConfirmationContainer = styled.div`
  position: relative;
  text-align: center;
  min-height: 367px;
  box-sizing: border-box;
  padding-top: 80px;
`;

export const FormContent = styled.div`
  width: 100%:
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 18px;

  span {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 232px;
`;

export const SelectedTagsContainer = styled.div`
  margin-top: 10px;
  min-height: 220px;
`;
export const SelectedTagsContainerInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`;
export const TagName = styled(Body3)`
  margin-right: 5px;
`;

export const SelectedTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  padding: 10px 10px 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const RemoveTagButton = styled.button`
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
`;

export const ModalText = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  margin-bottom: 15px;
  &.emphasized {
    font-size: 21px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  &:last-of-type {
    margin-bottom: 50px;
  }
`;