import React, { FC } from 'react';
import { MainActionButton, SecondaryActionButton } from 'uikit/Modal/styles';
import { ConfirmationContainer, ModalText } from './styles';

interface Props {
  className?: string;
  onConfirm: () => void;
  onReject: () => void;
  tagName: string;
  recordsAmount?: number;
}

const AddTagsConfirmation: FC<Props> = ({
  className,
  onConfirm,
  onReject,
  tagName,
  recordsAmount = 0
}) => {
  return (
    <ConfirmationContainer className={className}>
      <ModalText>Are you sure you wish to delete the tag</ModalText>
      <ModalText className="emphasized">“{tagName}”</ModalText>
      <ModalText>from {recordsAmount} customers in your list?</ModalText>
      <MainActionButton onClick={onConfirm} type="button">
        Yes, please delete
      </MainActionButton>
      <SecondaryActionButton onClick={onReject} type="button">
        No thanks, Cancel
      </SecondaryActionButton>
    </ConfirmationContainer>
  );
};

export default AddTagsConfirmation;
