import React, { FC } from 'react';

interface Props {
  className?: string;
}

const Tag: FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
  >
    <path
      fill="#fff"
      d="M6.698 0L.816.14A.693.693 0 00.14.815L0 6.697a.692.692 0 00.203.507l7.594 7.594c.27.27.71.27.98 0l6.02-6.02c.27-.27.27-.71 0-.98L7.204.203a.692.692 0 00-.506-.202zM3.858 5.75a1.892 1.892 0 110-3.784 1.892 1.892 0 010 3.784z"
    ></path>
  </svg>
);

export default Tag;
