import React, { FC, ChangeEvent } from 'react';
import { InputBase, FieldFooter } from 'uikit';
import {
  Container,
  LinkTitleFieldGroup,
  URLFieldGroup,
  StyledLabel
} from './styles';

interface InputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  error?: string | undefined;
}

interface Props {
  className?: string;
  urlFieldProps: InputProps;
  linkTitleFieldProps: InputProps;
}

const ReferrerEducationLinkFields: FC<Props> = ({
  className,
  urlFieldProps,
  linkTitleFieldProps
}) => (
  <Container className={className}>
    <LinkTitleFieldGroup>
      <StyledLabel title="Link title" />
      <InputBase
        name={linkTitleFieldProps.name}
        placeholder="Example: How it works video"
        value={linkTitleFieldProps.value}
        onChange={linkTitleFieldProps.onChange}
      />
      <FieldFooter error={linkTitleFieldProps.error} />
    </LinkTitleFieldGroup>
    <URLFieldGroup>
      <StyledLabel title="URL" />
      <InputBase
        name={urlFieldProps.name}
        value={urlFieldProps.value}
        onChange={urlFieldProps.onChange}
        placeholder="Example: https://youtu.be/Q9B5ixstk-c"
      />
      <FieldFooter error={urlFieldProps.error} />
    </URLFieldGroup>
  </Container>
);

export default ReferrerEducationLinkFields;
