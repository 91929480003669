import * as yup from 'yup';
import { emailRegExp } from 'constants/regExp';

const validationSchema = yup.object({
  email: yup
    .string()
    .test('invalid email', 'Invalid email address', function(value) {
      return !value || emailRegExp.test(value);
    })
    .required('Email is required')
});

export type ForgotPasswordFormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
