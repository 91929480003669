import styled from 'styled-components';
import { LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';

export const SubInputText = styled.p`
  display: block;
  text-align: right;
  font-size: ${({ theme }) => theme.size.body2};
  ${breakpoint('xs', 'lg')`
    text-align: initial;
  `}
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 16px;
  margin-left: 4px;
  cursor: pointer;
`;


