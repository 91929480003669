import React, { FC, useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import './OpportunityCoverCarousel.scss';

// TODO: delete mock slides after API integration
interface Props {
  images: string[];
}

const OpportunityCoverCarousel: FC<Props> = ({ images }) => {
  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    if (images && images.length) {
      if (swiper?.update) {
        swiper.update();
      } else {
        setSwiper(
          new Swiper('.swiper-container', {
            pagination: { clickable: true, el: '.swiper-pagination' }
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [images]);

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {images.map((imageBlob: string) => (
          <div className="swiper-slide" key={imageBlob}>
            <img src={imageBlob} alt={imageBlob.substring(0, 15)} />
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default OpportunityCoverCarousel;
