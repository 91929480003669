import React, { FC } from 'react';
import cn from 'classnames';
import {
  DraftCaption,
  StepLink,
  StepItemBullet,
  StepItemCaption,
  StyledChevron,
  StyledTick,
  SubLink
} from './styles';

interface Props {
  index: number;
  stepDescription: string;
  isActive: boolean;
  isCompleted: boolean;
  subLinkText?: string;
  subLinkTo?: string;
  to: string;
  readyToPublish? : boolean
  linkId: string
}

const ProfileSetupStep: FC<Props> = ({
  index,
  isActive,
  isCompleted,
  stepDescription,
  subLinkText,
  subLinkTo,
  to,
  linkId
}) => (
  <StepLink
    className={cn({ active: isActive, completed: isCompleted })}
    to={to}
    id={linkId}
  >
    <StepItemBullet isActive={isActive} isCompleted={isCompleted}>
      {isCompleted ? <StyledTick /> : index}
    </StepItemBullet>
    <StepItemCaption>{stepDescription}</StepItemCaption>
    {/* {isDraftSaved && <DraftCaption>{readyToPublish ? 'Publish' : 'View Opportunity'}</DraftCaption>} */}
    <DraftCaption><SubLink to={subLinkTo || ''}>{subLinkText}</SubLink></DraftCaption>
    {isActive && <StyledChevron />}
  </StepLink>
);

export default ProfileSetupStep;
