import * as yup from 'yup';
import { MIN_PASSWORD_LENGTH } from 'constants/parameters';

const validationSchema = yup.object({
  password: yup
    .string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password must be at least ${MIN_PASSWORD_LENGTH} in length.`
    )
    .required('Empty password not allowed'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      "Passwords don't match. Please check again."
    )
    .required('Empty password not allowed')
});

export type ResetPasswordFormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
