import React, { FC, useState, ChangeEvent } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { TEXTAREA_MAX_LENGTH } from 'constants/fieldLengthLimits';
import { FieldFooter, TextareaBase } from 'uikit';
import {
  EducationLinkFields,
  EducationMaterialEntry,
  EducationMaterialButton,
  SectionHeading,
  SectionDescription,
  InputGroup
} from './styles';

const ReferrerEducation: FC = () => {
  const { handleChange, values, errors } = useFormikContext<
    CreateOpportunityType
  >();

  const [newLinkUrl, setUrl] = useState('');
  const [newLinkTitle, setTitle] = useState('');

  return (
    <div>
      <SectionHeading title="Would you like to provide any selling tips or notes that only referrers can see?" />
      <SectionDescription>
        Providing selling tips, target customer description, elevator pitches,
        or disclaimer copy is a great way to educate referrers and make them
        more successful promoting your business.
      </SectionDescription>

      <InputGroup>
        <TextareaBase
          rows={5}
          name="referrerEducation.noteForDrummer"
          onChange={handleChange}
          value={values.referrerEducation.noteForDrummer}
          maxLength={TEXTAREA_MAX_LENGTH}
        />
        <FieldFooter
          error={errors.referrerEducation?.noteForDrummer}
          limit={TEXTAREA_MAX_LENGTH}
          fieldValueLength={values.referrerEducation.noteForDrummer?.length}
        />
      </InputGroup>

      <div>
        <SectionHeading title="Would you like to provide referrers with additional educational materials?" />
        <SectionDescription>
          Consider referrers your on-demand salesforce. The more educated they
          are on your product or service the more effectively they will be
          referring.
        </SectionDescription>
      </div>
      <FieldArray
        name="referrerEducation.educationMaterials"
        render={({ remove, push }) => (
          <>
            {values.referrerEducation.educationMaterials.map(
              ({ title, url }, i) => (
                <EducationMaterialEntry key={i}>
                  <EducationLinkFields
                    urlFieldProps={{
                      name: `referrerEducation.educationMaterials[${i}].url`,
                      onChange: handleChange,
                      value: url,
                      error:
                        // @ts-ignore
                        errors?.referrerEducation?.educationMaterials?.[i]?.url
                    }}
                    linkTitleFieldProps={{
                      name: `referrerEducation.educationMaterials[${i}].title`,
                      onChange: handleChange,
                      value: title,
                      error:
                      errors?.referrerEducation?.educationMaterials?.[i]
                        // @ts-ignore
                          ?.title
                    }}
                  />
                  <EducationMaterialButton
                    onClick={() => remove(i)}
                    type="button"
                  >
                    Remove
                  </EducationMaterialButton>
                </EducationMaterialEntry>
              )
            )}

            <EducationMaterialEntry>
              <EducationLinkFields
                urlFieldProps={{
                  name: 'addUrl',
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    setUrl(e.target.value),
                  value: newLinkUrl
                }}
                linkTitleFieldProps={{
                  name: 'addLinkTitle',
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    setTitle(e.target.value),
                  value: newLinkTitle
                }}
              />

              <EducationMaterialButton
                disabled={!newLinkTitle || !newLinkUrl}
                onClick={() => {
                  push({ title: newLinkTitle, url: newLinkUrl });
                  setTitle('');
                  setUrl('');
                }}
                type="button"
              >
                Add
              </EducationMaterialButton>
            </EducationMaterialEntry>
          </>
        )}
      />
    </div>
  );
};

export default ReferrerEducation;
