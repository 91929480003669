import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogInEmailForm from 'components/LogInEmailForm';
import ShopifyAuthInfo from 'components/ShopifyAuthInfo';
import { LinkTextMixin } from 'uikit';

export const StyledLogInEmailForm = styled(LogInEmailForm)`
  margin-bottom: 25px;
`;

export const ForgotPasswordLink = styled(Link)`
  display: block;
  ${LinkTextMixin};
`;

export const StyledShopifyAuthInfo = styled(ShopifyAuthInfo)`
  margin-top: 25px;
`;

export const ModalErrorText = styled.p`
  margin-bottom: 20px;
`;
