import React, { FC } from 'react';
import IconProps from './IconProps';

const Plus: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path fill="#000" d="M8 1a1 1 0 012 0v16a1 1 0 11-2 0V1z"></path>
    <path fill="#000" d="M17 8a1 1 0 110 2H1a1 1 0 010-2h16z"></path>
  </svg>
);

export default Plus;
