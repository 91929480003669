import styled from 'styled-components';
import { Body2, Body3, H2, H4 } from 'uikit';
import IconButton from 'components/IconButton';
import { ITheme } from 'types';
import { Heart, Plane } from 'assets/svg';
import { WidgetContainer } from 'uikit';

export const Container = styled(WidgetContainer)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px 12px 0 0;
`;

export const OpportunityButtons = styled.div`
  position: absolute;
  z-index: 10;
  top: 12px;
  right: 12px;
  display: flex;
`;

export const OpportunityCover = styled.div`
  position: relative;
  margin-bottom: 14px;
`;

export const OpportunityCoverPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 310px;
  background: linear-gradient(242.65deg, #C4C4C4 -2.3%, #F8F8F8 101.9%);
  border-radius: 11px 11px 0 0;
`;

export const Placeholder = styled(H2)`
  color: ${({ theme }) => theme.colors.placeholder};
`;

export const CompanyLogoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 25px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 89px;
  height: 89px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  transform: translateY(50%);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.23);
  background-color: #e2e2e2;
  color: ${({ theme }) => theme.colors.placeholder};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CompanyLogo = styled.img`
  max-width: 100%;
  height: auto;
`;

export const BusinessInfo = styled.div`
  min-height: 45px;
  padding: 0 40px 14px 130px;
  box-sizing: border-box;
  position: relative;
`;

export const EarnSection = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: right;
  color: ${({ theme }) => theme.colors.alertColor};
`;

export const EarnTitle = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
`;

export const EarnAmount = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
`;
export const EarnAmountInline = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.alertColor};
`;

export const OpportunityShareButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  :not(:last-child) {
    margin-right: 15px;
  }
`;

export const StyledPlaneIcon = styled(Plane)`
  width: 17px;
  height: auto;
`;

export const StyledHeartIcon = styled(Heart)`
  width: 17px;
  height: auto;
  fill: ${({ theme }) => theme.colors.alertColor};
`;

export const CompanyTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  word-break: break-word;
`;

export const ExpirationDate = styled(Body3)`
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const OpportunityDetails = styled.div`
  padding: 20px 30px 20px 30px;
`;

interface MainInfoProps extends ITheme {
  hasDescription: boolean;
}

export const MainInfo = styled.div<MainInfoProps>`
  // margin-bottom: ${({ hasDescription }) => (hasDescription ? '30px' : '100px')};
  margin-bottom: 30px;
`;

export const OpportunityTitle = styled(H4)`
  margin-bottom: 15px;
  word-break: break-word;
`;

export const OpportunityDescription = styled(Body2)`
  color: ${({ theme }) => theme.colors.bodyTextColor};
  min-height: 63px;
`;
