import React, { FC, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import RestorePasswordPageLayout from 'layouts/RestorePasswordPageLayout';
import ResetPasswordForm from 'components/ResetPasswordForm';
import PasswordUpdateSuccess from 'components/PasswordUpdateSuccess';
import routes from 'constants/routes';

interface Props {
  isBusiness: boolean;
}

const ResetPasswordPage: FC<Props> = ({isBusiness}) => {
  const location = useLocation();
  const [isPasswordChanged, setPasswordChangeStatus] = useState(false);

  const queryParams = querystring.parse(location.search);
  const { email, code, rdr } = queryParams; //m is a one of login options

  const isString = (input: any): input is string => typeof input === 'string';

  if (!isString(email) || !isString(code)) {
    return <Redirect to={routes.LOGIN} />;
  }

  return (
    <RestorePasswordPageLayout>
      {isPasswordChanged ? (
        <PasswordUpdateSuccess backButtonLink={rdr ? decodeURI(rdr.toString()) : undefined} logOutOnRedirect={isBusiness}/>
      ) : (
        <ResetPasswordForm
          code={code}
          email={email}
          onSuccess={() => setPasswordChangeStatus(true)}
        />
      )}
    </RestorePasswordPageLayout>
  );
};

export default ResetPasswordPage;
