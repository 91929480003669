import styled from 'styled-components';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  
`;
export const TableContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;


export const Row = styled.div`
  display: flex;
  height: 87px;
  border-bottom: 1px solid #ccc;
`;

export const Col = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.black};
  padding-right: 7px;

  &.pending {
    color: #a5a5a5;
    font-style: italic;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  &.switched-column {
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !impotant;
  }

  &:nth-of-type(1) {
    flex: 0.38;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    ${breakpoint('xs', 'lg')`
      flex: 0.55;
      padding-left: 20px
    `}
  }
  &:nth-of-type(2) {
    flex: 0.17;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};

    &.pending {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }

    ${breakpoint('xs', 'lg')`
      flex: 0.35;
      font-size: ${({ theme }) => theme.size.body2};
    `}
  }
  &:nth-of-type(3) {
    flex: 0.13;
    ${breakpoint('xs', 'lg')`
      justify-content: center;
      flex: 0.1;
    `}
  }
  &:nth-of-type(4) {
    flex: 0.13;
  }
  &:nth-of-type(5) {
    flex: 0.14;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.danger};

    &.pending {
      color: #a5a5a5;
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }
  &:nth-of-type(6) {
    flex: 0.05;
  }

`;

export const Name = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};

  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;

export const DateBlock = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body1};
  
  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;
export const Emphasized = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.danger};

  &.margined {
    margin-right: 5px;

    ${breakpoint('xs', 'lg')`
      display: block;
      font-size: ${({ theme }) => theme.size.caption};
    `}
  }
`;
export const ArrowContainer = styled.span`
  display: inline-block;
  transform: rotate(-90deg);
  cursor: pointer;
  
`;



export const HeaderRow = styled(Row)`

`;
export const HeaderCol = styled(Col)`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  word-spacing: 9999999px;

  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.caption} !important;
    word-spacing: initial;
  `}
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 400px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #C2C2C2;
`;

export const MobileTableContainer = styled.div`
  ${breakpoint('lg')`
    display: none;
  `}
`;
export const MobileColSwitchContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  word-spacing: initial;
`;
export const MobileColControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
`;
export const MCC = styled.div`
  width: 50%;
  height: 100%;
  cursor: pointer;
`;
export const MobileSwitchArrowContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: inline-block;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin: 0 5px;

  svg {
    width: 14px !important;
    height: 14px !important;
  }

  &.left {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(-90deg);
  }
`;
