import styled from 'styled-components';
import { Share } from '../../assets/svg';
import { breakpoint } from '../../helpers';
import { ITheme } from '../../types';

export const Container = styled.div`
  width: 100%;
  height: 92px;
  margin-bottom: 25px;
`;

export interface LayoutElementProps extends ITheme {
  canSharePage: boolean;
}

export const HeaderContent = styled.div<LayoutElementProps>`
  display: flex;
  justify-content: ${({ canSharePage }) =>
    canSharePage ? 'space-between' : 'center'};
  align-items: center;
  height: 100%;

  ${breakpoint('md')`
    margin: 0 auto;
  `}

  ${breakpoint('xs', 'md')`
    padding: 0 20px;
  `}
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 50%;
`;

export const Avatar = styled.img`
  max-width: 100%;
  height: auto;
`;

export const UserDetails = styled.div<LayoutElementProps>`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-right: ${({ canSharePage }) => (canSharePage ? '30px' : 0)};
`;

export const UserName = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const UserAction = styled.div`
  color: #4d4d4d;
`;

export const ShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  border: solid 2px currentColor;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.alertColor};
  background-color: transparent;
  border-radius: 20px;
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
`;

export const ShareIcon = styled(Share)`
  margin-left: 8px;
  fill: currentColor;
`;
