import React, { FC } from 'react';
import { InputBase, GlassPanel } from 'uikit';
import PreviewFooter from '../PreviewFooter';
import { Props as PreviewProps } from '../../types';
import { TERMS_OF_SERVICE_LINK } from 'constants/outerLinks';
import {
  Container,
  Header,
  Paragraph,
  BodyParagraph,
  ParagraphSmall,
  ViewRewardLink,
  InputGroup,
  MainButton,
  NoticeText,
  NoticeLink,
  LaunchAppLink
} from './styles';
import {
  GetBusinessDetailsQuery,
} from '../../../../graphql';

export interface Props extends PreviewProps{
  business: GetBusinessDetailsQuery,
  launchAppLink?: string,
  onButtonClick?: () => void,
  interactable?: boolean,
}

const ReferralPreviewJoin: FC<Props> = ({ config, business, launchAppLink, onButtonClick, interactable = true }) => {

  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      {!interactable && <GlassPanel className="no-cursor"/>}
      <Header>{config.states.join.config.title}</Header>
      <BodyParagraph>{config.states.join.config.body}</BodyParagraph>
      <ViewRewardLink href="">View reward opportunity</ViewRewardLink>
      <Paragraph>Enter your email to start sharing with friends:</Paragraph>

      <InputGroup>
        <InputBase
          name="link"
          type="text"
          value=""
          placeholder="Your email address"
        />
      </InputGroup>

      <MainButton 
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={onButtonClick}
      >
        Start Sharing
      </MainButton>

      <NoticeText>*Restrictions apply. See <NoticeLink href={TERMS_OF_SERVICE_LINK}>Terms</NoticeLink> for details</NoticeText>
      <ParagraphSmall>Already part of {business?.getCurrentBusiness?.businessName} rewards program?</ParagraphSmall>
      <LaunchAppLink href={launchAppLink}>Launch the app</LaunchAppLink>

      <PreviewFooter />
    </Container>
  );
};

export default ReferralPreviewJoin;
