import React, { FC } from 'react';
import IconProps from './IconProps';

const ShopifyIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="27"
    height="30"
    viewBox="0 0 27 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1283 5.8348C23.1107 5.67662 22.9701 5.60633 22.8647 5.60633C22.7592 5.60633 20.4569 5.43058 20.4569 5.43058C20.4569 5.43058 18.8576 3.83128 18.6643 3.67311C18.4886 3.49736 18.1547 3.55009 18.0141 3.58524C17.9965 3.58524 17.6626 3.69069 17.1178 3.86643C16.5729 2.30229 15.6415 0.878735 13.9719 0.878735H13.8137C13.3568 0.281195 12.7592 0 12.2671 0C8.4007 0 6.53779 4.83304 5.95782 7.2935C4.4464 7.76801 3.37434 8.08436 3.25132 8.13708C2.40773 8.4007 2.39016 8.41828 2.28471 9.20914C2.19684 9.80668 0 26.8014 0 26.8014L17.1002 30L26.3796 27.9965C26.3972 27.9613 23.1459 5.99297 23.1283 5.8348ZM16.1687 4.11248C15.7469 4.2355 15.2373 4.39367 14.7276 4.56942V4.25307C14.7276 3.30404 14.6046 2.53076 14.3761 1.91564C15.2548 2.02109 15.7997 2.9877 16.1687 4.11248ZM13.304 2.10896C13.5325 2.7065 13.6907 3.55009 13.6907 4.71002V4.88576C12.7417 5.18453 11.7399 5.4833 10.703 5.81722C11.283 3.60281 12.3902 2.51318 13.304 2.10896ZM12.1793 1.01933C12.355 1.01933 12.5308 1.08963 12.6714 1.19508C11.4236 1.77504 10.1054 3.23374 9.56063 6.18629C8.73462 6.44991 7.94376 6.67838 7.18805 6.92443C7.82074 4.67487 9.40246 1.01933 12.1793 1.01933Z"
      fill="#95BF47"
    />
    <path
      d="M22.8641 5.57114C22.7587 5.57114 20.4564 5.39539 20.4564 5.39539C20.4564 5.39539 18.8571 3.79609 18.6638 3.63792C18.5935 3.56762 18.5056 3.53247 18.4353 3.53247L17.1523 30L26.4318 27.9964C26.4318 27.9964 23.1805 5.99293 23.1629 5.83476C23.0926 5.67658 22.9696 5.60629 22.8641 5.57114Z"
      fill="#5E8E3E"
    />
    <path
      d="M13.9727 10.703L12.8479 14.0949C12.8479 14.0949 11.8286 13.5501 10.6159 13.5501C8.80572 13.5501 8.71784 14.6924 8.71784 14.9736C8.71784 16.5202 12.76 17.1177 12.76 20.7557C12.76 23.6204 10.9498 25.4657 8.50695 25.4657C5.5544 25.4657 4.07812 23.638 4.07812 23.638L4.86899 21.0369C4.86899 21.0369 6.41556 22.3726 7.71609 22.3726C8.57725 22.3726 8.92874 21.7047 8.92874 21.2126C8.92874 19.1916 5.6247 19.1037 5.6247 15.7645C5.6247 12.9701 7.62821 10.2636 11.688 10.2636C13.1994 10.246 13.9727 10.703 13.9727 10.703Z"
      fill="white"
    />
  </svg>
);

export default ShopifyIcon;
