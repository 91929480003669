import styled from 'styled-components';
import { ITheme } from 'types';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  p {
    line-height: 23px;
    
  }
  & > p {
    margin-top: 20px;
    font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  }
`;
export const Contacts = styled.div`
  margin-top: 20px;
`;
export const StyledLink = styled.a`
  ${LinkTextMixin};
`;
export const Phone = styled.p`
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.extraBold};
`;
export const Email = styled.p`
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
`;
export const EmailLink = styled.a`
  ${LinkTextMixin};
  cursor: pointer;
`;