import React, { FC } from 'react';
import {
  Container,
  PageTitle,
  DescriptionText,
} from './styles';

type Props = {
  title: string;
}

const PasswordPageHeading: FC<Props> = ({title, children}) => {
  
  return (
    <Container>
      <PageTitle>{title}</PageTitle>
      {children && <DescriptionText>
        {children}
      </DescriptionText>}
    </Container>
  );
};

export default PasswordPageHeading;
