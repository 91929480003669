export const SHOPIFY_LOGIN_MODAL_STORAGE_FIELD = "_shopifyLoginModalParams";

export const setShopifyLoginData = (data: any) => {
  localStorage.setItem(SHOPIFY_LOGIN_MODAL_STORAGE_FIELD, JSON.stringify(data));
}
export const getShopifyLoginData = () => {
  const _shopifyLoginData = localStorage.getItem(SHOPIFY_LOGIN_MODAL_STORAGE_FIELD);
  const _parsed = _shopifyLoginData ? JSON.parse(_shopifyLoginData) : null;
  return _parsed;
}
export const removeShopifyLoginData = () => {
  localStorage.removeItem(SHOPIFY_LOGIN_MODAL_STORAGE_FIELD);
}