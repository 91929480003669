import styled from 'styled-components';
import { LabelTextMixin, PrimaryButton } from 'uikit';

export const FormHeader = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

export const InputGroup = styled.div`
  margin-bottom: 30px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  ${LabelTextMixin};
`;

export const ConfirmPasswordLabel = styled(Label)`
  display: block;
  margin-bottom: 8px;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
`;
