import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { FieldFooter, TextareaBase } from 'uikit';
import { PageFormType } from 'components/ReferralReward/validationSchema';
import {
  REFERRAL_WEBPAGE_TITLE_MAX_LENGTH,
  REFERRAL_WEBPAGE_BODY_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import { InputGroup, StyledLabel } from '../../styles';
import { pageType } from 'components/ReferralReward/types';

interface Props {
  pageType: pageType;
}

const PageTextsSection: FC<Props> = ({ pageType }) => {
  const { handleChange, values, errors } = useFormikContext<
    PageFormType
  >();

  return (
    <div>
      <InputGroup>
        <StyledLabel
          title="Title"
        />
        <TextareaBase
          name={'states.' + pageType + '.config.title'}
          rows={3}
          onChange={handleChange}
          value={values.states?.[pageType]?.config?.title}
          maxLength={REFERRAL_WEBPAGE_TITLE_MAX_LENGTH}
        />
        <FieldFooter
          error={errors?.states?.[pageType]?.config?.title}
          limit={REFERRAL_WEBPAGE_TITLE_MAX_LENGTH}
          fieldValueLength={values.states?.[pageType]?.config?.title.length}
        />
      </InputGroup>
      <InputGroup>
        <StyledLabel
          title="Body"
        />
        <TextareaBase
          name={'states.' + pageType + '.config.body'}
          rows={3}
          onChange={handleChange}
          value={values.states?.[pageType]?.config?.body}
          maxLength={REFERRAL_WEBPAGE_BODY_MAX_LENGTH}
        />
        <FieldFooter
          error={errors.states?.[pageType]?.config?.body}
          limit={REFERRAL_WEBPAGE_BODY_MAX_LENGTH}
          fieldValueLength={values.states?.[pageType]?.config?.body.length}
        />
      </InputGroup>
    </div>
  );
};

export default PageTextsSection;
