import React, {
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback
} from 'react';
import { Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { checkAuthentication, signOut } from 'services/auth';
import ProfileStatusChecker from 'components/ProfileStatusChecker';
import authEvents from 'constants/authEvents';
import routes from 'constants/routes';
import {
  sendLoginTrackingEvent,
  removeShopifyTrackingEventFlag
} from 'helpers/segment';
import { getDefaultUtmCookies } from 'helpers/utm';
import { FullScreenLoader, Modal } from 'uikit';
import { removeShopifyLoginData } from 'helpers/shopifyLoginStorage';
import {
  useGetBusinessIdQuery,
  useGetBusinessDetailsQuery,
  useBusinessRegisterMutation,
  useMaybeTrackSignUpMutation
} from '../graphql';
import * as tabsMessanger from 'services/tabsMessanger';
import { ModalErrorText } from 'pages/LogInPage/styles';
import { executeAfterLoginHook } from 'helpers/afterLoginHook';

interface AuthContextType {
  isAuthenticated: boolean;
  setAuthStatus: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  setAuthStatus: () => null
});

const identifyToSegment = (data: any) => {
  (window as any)?.analytics?.identify(data.username, {
    email:
      data?.attributes?.email ||
      data?.signInUserSession?.idToken?.payload?.email
  });
};

interface Props {
  children: ReactNode;
}

const AuthProvider: FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isUserLoaded, setUserLoading] = useState(false);
  const [isAuthenticated, setAuthStatus] = useState(false);
  const [authProcessing, setAuthProcessing] = useState(false);
  const { refetch } = useGetBusinessIdQuery({
    fetchPolicy: 'no-cache',
    skip: true
  });

  const [businessRegister] = useBusinessRegisterMutation({
    variables: {
      input: {}
    },
    onCompleted: () => {
      setAuthStatus(true);
      setAuthProcessing(false);
    },
    onError: (error) => {
      setUserLoading(true);
      setAuthStatus(false);
      setAuthProcessing(false);
      setErrorModalOpen(true);
      console.log('Business register error:', error);
    }
  });
  const [maybeTrackSignUp] = useMaybeTrackSignUpMutation({});

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      (async () => {
        const utmCookies = getDefaultUtmCookies();
        switch (event) {
          case authEvents.LOG_IN:
            tabsMessanger.sendUserLoggedIn();
            identifyToSegment(data);
            setAuthProcessing(true);
            const { data: businessData } = await refetch();

            await maybeTrackSignUp({
              variables: {
                input: {
                  utmParams: utmCookies
                }
              }
            });
            sendLoginTrackingEvent(
              businessData?.getCurrentBusiness?.id || '',
              data,
              true
            );
            removeShopifyTrackingEventFlag();
            removeShopifyLoginData();
            executeAfterLoginHook();
            break;
          case authEvents.SIGN_UP:
            break;
          case authEvents.LOG_OUT:
          case authEvents.SOCIAL_LOG_OUT:
            setAuthStatus(false);
            removeShopifyLoginData();
            break;
          case authEvents.LOG_IN_FAILURE:
            sendLoginTrackingEvent(null, data, false, message);
            removeShopifyTrackingEventFlag();
            break;
          default:
            break;
        }
      })();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const utmCookies = getDefaultUtmCookies();
    if (authProcessing) {
      (async () => {
        try {
          let { data } = await refetch();
          if (!data?.getCurrentBusiness) {
            await businessRegister();
            await maybeTrackSignUp({
              variables: {
                input: {
                  utmParams: utmCookies
                }
              }
            });
          }
          setAuthProcessing(false);
          setAuthStatus(true);
        } catch (err) {
          setAuthStatus(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [authProcessing, businessRegister, refetch]);

  const { loading, client } = useGetBusinessDetailsQuery({
    skip: !isAuthenticated
  });

  useEffect(() => {
    checkAuthentication()
      .then((res) => setAuthStatus(res))
      .catch(() => setAuthStatus(false))
      .finally(() => setUserLoading(true));
  }, []);

  const handleLogIn = useCallback(async () => {
    client.stop();
    await client.clearStore();
    await signOut({ doNotReload: true });
    setErrorModalOpen(false);
    history.replace(routes.LOGIN);
  }, [client, history, setErrorModalOpen]);

  const handleSignUp = useCallback(async () => {
    client.stop();
    await client.clearStore();
    await signOut({ doNotReload: true });
    setErrorModalOpen(false);
    history.replace(routes.SIGN_UP);
  }, [client, history, setErrorModalOpen]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthStatus }} {...rest}>
      <Modal
        title={'Error'}
        type="warning"
        isOpen={isErrorModalOpen}
        canClose={false}
        childrenBeforeButtons={true}
        mainButton={{ text: 'Log In', onClick: handleLogIn }}
        secondaryButton={{
          text: 'Return to Sign Up',
          onClick: handleSignUp
        }}
      >
        <ModalErrorText>This email is used for Drummer account.</ModalErrorText>
      </Modal>
      {isErrorModalOpen ? null : isUserLoaded &&
        (!isAuthenticated || !loading) &&
        !authProcessing ? (
        <>
          {children}
          <ProfileStatusChecker isAuthenticated={isAuthenticated} />
        </>
      ) : (
        <FullScreenLoader />
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
