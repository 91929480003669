import { IntegrationPlatform } from 'components/SetUpCustomerTracking/types';

export const SHOPIFY_STATUS_POLLING_INTERVAL = 5000;

// TODO: remove cast and change props type of IntegrationTypeInfo component
export const SHOPIFY_PLATFORM_INFO = {
  id: 'shopify',
  name: 'Shopify',
  src: '/assets/shopify.png',
  link: 'shopify'
} as IntegrationPlatform;

export const setupInstructions =
  'To track a Drum referred customer from landing on your site to completing your desired action we need to integrate your Shopify Store.';
