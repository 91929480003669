import styled from 'styled-components';
import { Body2 } from 'uikit';
export const Container = styled.div`
  
`;
export const Text = styled(Body2)`
  font-size: 21px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
export const Count = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
