import React, { FC } from 'react';
import { Container, Title, Text } from './styled';

export interface Props {
  title: string;
  value: string | number;
  greened?: boolean;
}

const TextInfo: FC<Props> = ({ title, value, greened }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Text greened={greened}>{value}</Text>
    </Container>
  );
};

export default TextInfo;
