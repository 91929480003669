import { ImageStyleName, ImageStyle } from '../graphql';

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

const getImagePath = (styles?: ImageStyle[], type:ImageStyleName = ImageStyleName.Square, noDomain: boolean = false) => {
  if(!styles || !styles.length) return '';
  const companyLogoData = styles.find(
    (imageInfo: ImageStyle) => {
      return imageInfo?.name === type;
    }
  );

  return companyLogoData?.path
    ? !noDomain ? `${S3_DOMAIN}/${companyLogoData.path}` : `${companyLogoData.path}`
    : '';
};
export const getImagePathSimple = (url: string) => {
  return url
    ? `${S3_DOMAIN}/${url}`
    : '';
};

export default getImagePath;