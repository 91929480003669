import React, { FC, useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import routes from 'constants/routes';
import { FullScreenLoader } from 'uikit';
import {
  IntegrationType,
  useGetBusinessDetailsQuery,
  GetBusinessDetailsQuery
} from '../../graphql';
import { AlertIcon, Container, ErrorMessage, RedirectButton } from './styles';

const finishInstallationFetchCall = async (
  businessId: string,
  shopDomain: string
) => {
  const shopifyAppServerDomain = process.env.REACT_APP_SHOPIFY_DOMAIN;
  const SHOPIFY_APP_API_URL = `${shopifyAppServerDomain}/finish-installation?shop=${shopDomain}&businessId=${businessId}`;
  return fetch(SHOPIFY_APP_API_URL, { credentials: 'include' });
};

enum ErrorMessageTypes {
  HAS_ACTIVE_INTEGRATION = 1,
  FAILED_TO_CONNECT
}

const ERROR_MESSAGE_TEXTS = {
  [ErrorMessageTypes.HAS_ACTIVE_INTEGRATION]: `You cannot integrate Shopify Store with a Business account that has active Integration.`,
  [ErrorMessageTypes.FAILED_TO_CONNECT]: `Failed to connect with Shopify Store.`
};

const isCurrentIntegraionDifferentFromTheAttempted = (
  businessProfileData: GetBusinessDetailsQuery | undefined,
  searchString: string
) => {
  const integrationType =
    businessProfileData?.getCurrentBusiness?.integrationType;
  const shopAddress =
    businessProfileData?.getCurrentBusiness?.shopifyShop?.shopAddress;
  const shopQueryParam = queryString.parse(searchString).shop;

  const hasOtherIntegration =
    !!integrationType &&
    (integrationType !== IntegrationType.Shopify ||
      shopAddress !== shopQueryParam);

  return hasOtherIntegration;
};

const SetupShopifyIntegrationPage: FC<RouteComponentProps> = ({
  location: { search: searchString },
  history
}) => {
  const [errorMessageType, setError] = useState<ErrorMessageTypes | null>(null);

  const {
    data: businessProfileData,
    loading: businessProfileDataLoading,
    refetch
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'network-only'
  });

  const hasOtherIntegration = isCurrentIntegraionDifferentFromTheAttempted(
    businessProfileData,
    searchString
  );

  const finishInstallation = useCallback(
    async (businessId: string, shop: string) => {
      try {
        const fetchResponse = await finishInstallationFetchCall(
          businessId,
          shop
        );
        if (fetchResponse.status === 200) {
          await refetch();
          history.push(routes.DASHBOARD);
        } else {
          const { code } = await fetchResponse.json();
          if (code === 'shop_integrated') {
            await refetch();
            history.push(routes.DASHBOARD);
          } else {
            setError(ErrorMessageTypes.FAILED_TO_CONNECT);
          }
        }
      } catch (err) {
        console.log(err);
        setError(ErrorMessageTypes.FAILED_TO_CONNECT);
      }
    },
    [history, refetch]
  );

  useEffect(() => {
    if (businessProfileDataLoading) {
      return;
    }

    const { shop } = queryString.parse(searchString);
    if (typeof shop === 'string' && hasOtherIntegration) {
      setError(ErrorMessageTypes.HAS_ACTIVE_INTEGRATION);
    } else {
      finishInstallation(
        String(businessProfileData?.getCurrentBusiness?.id),
        String(shop)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProfileDataLoading]);

  return errorMessageType ? (
    <Container>
      <AlertIcon src="/assets/notification-icons/alert.png" alt="" />
      <ErrorMessage>{ERROR_MESSAGE_TEXTS[errorMessageType]}</ErrorMessage>
      <RedirectButton
        onClick={() => history.push(routes.DASHBOARD)}
        type="button"
      >
        Return to Dashboard
      </RedirectButton>
    </Container>
  ) : (
    <FullScreenLoader />
  );
};

export default SetupShopifyIntegrationPage;
