import styled from 'styled-components';
import { Label } from 'uikit';

export const InputGroup = styled.div`
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 10px;
  }
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 15px;
`;

export const Attention = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-top: 20px;
  span:first-of-type {
    color: ${({ theme }) => theme.colors.danger};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-right: 4px;
  }
`;
