import React, { FC, useState } from 'react';
import copy from 'copy-to-clipboard';
import Tooltip from 'rc-tooltip';
import { RadioButton, InputBase, FieldFooter, TextareaBase } from 'uikit';
import { useFormikContext, FormikErrors } from 'formik';
import { PageFormType } from 'components/ReferralReward/validationSchema';
import {
  Container,
  Paragraph,
  SnippetButtonsContainer,
  SnippetButton,
  VerifyButton,
  ShopifyButtonsContainer,
  PreviewShopifyPageButton,
  CreateShopifyPageButton,
  DoneButton,
  RadioContainer,
  InputGroup,
  ReturnLink,
  Footer,
  FooterText,
  FooterLink
} from './styles';
import { Title, StyledLabel } from '../../styles';
import { getReviewAndPublishCodeSnippet } from 'helpers/referralWebpage';
import { REFERRAL_WEBPAGE_SETUP_HELP } from 'constants/outerLinks';
import { REFERRAL_WEBPAGE_SHOPIFY_TITLE_MAX_LENGTH } from 'constants/fieldLengthLimits';
import './styles.scss';

const shopifyShowHideTooltip = <>
  <p>Selecting Make Visible will publish this page on your Shopify site and make it available to visitors. </p>
  <p>Selecting Hide Page will create a hidden page that you can review before publishing.</p>
</>;

interface Props {
  isShopify?: boolean;
  onBack?: () => void;
  onSave: (
    validateForm: (
      values?: PageFormType
    ) => Promise<FormikErrors<PageFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: PageFormType
  ) => void;
  onPreviewShopify: (values: PageFormType) => void;
  onVerificationClick: () => void;
  isShopifyPageCreated: boolean;
  showPreviewShopify?: boolean;
}

const ReviewAndPublishForm: FC<Props> = ({
  isShopify = false,
  onBack,
  onSave,
  onPreviewShopify,
  isShopifyPageCreated,
  onVerificationClick,
  showPreviewShopify = true,
  
}) => {
  const TOOLTIP_TIMEOUT = 1000;
  const {
    handleChange,
    validateForm,
    setSubmitting,
    values,
    errors
  } = useFormikContext<PageFormType>();
  const [isCopyTooltipShown, setIsCopyTooltipShown] = useState(false);

  const handleCopySnippet = () => {
    if (values.url) {
      setIsCopyTooltipShown(true);
      setTimeout(() => setIsCopyTooltipShown(false), TOOLTIP_TIMEOUT);
      copy(getReviewAndPublishCodeSnippet(values.url));
    }
  };

  const handleSave = () => {
    onSave(validateForm, setSubmitting, values);
  };
  const previewShopifyPage = () => {
    onPreviewShopify(values);
  };
  const handleVerifyCode = () => {
    onVerificationClick();
  };

  return (
    <Container>
      <Title>Review and publish</Title>
      <ReturnLink
        onClick={() => {
          onBack && onBack();
        }}
      >
        &lt; Back to design and preview
      </ReturnLink>
      {isShopify ? (
        <>
          <Paragraph>
            Drum enables you to put a referral page neatly into your existing
            website.
          </Paragraph>
          <Paragraph>
            A new referral page has been added to your Shopify site. To preview,
            review, and publish your page to your Shopify store, Click the “Open
            in Shopify” button.
          </Paragraph>

          <InputGroup>
            <StyledLabel title="Shopify Page Title" />
            <InputBase
              name={'shopifyPageConfig.title'}
              onChange={handleChange}
              value={values?.shopifyPageConfig?.title}
              maxLength={REFERRAL_WEBPAGE_SHOPIFY_TITLE_MAX_LENGTH}
            />
            <FieldFooter
              error={(errors?.shopifyPageConfig as any)?.title}
              limit={REFERRAL_WEBPAGE_SHOPIFY_TITLE_MAX_LENGTH}
              fieldValueLength={values.shopifyPageConfig?.title?.length || 0}
            />
          </InputGroup>

          <InputGroup>
            <StyledLabel title="Page visibility" tooltip={shopifyShowHideTooltip} />
            <RadioContainer>
              <RadioButton
                id="false"
                name="shopifyPageConfig.isHidden"
                label="Make Visible"
              />
              <RadioButton
                id="true"
                name="shopifyPageConfig.isHidden"
                label="Hide Page"
              />
            </RadioContainer>
          </InputGroup>
          <ShopifyButtonsContainer>
            {showPreviewShopify && (
              <PreviewShopifyPageButton onClick={previewShopifyPage}>
                Preview Shopify Page
              </PreviewShopifyPageButton>
            )}
            <CreateShopifyPageButton onClick={handleSave}>
              {isShopifyPageCreated
                ? 'Update Shopify Page'
                : 'Create Shopify Page'}
            </CreateShopifyPageButton>
          </ShopifyButtonsContainer>
        </>
      ) : (
        <>
          <Paragraph>
            Drum enables you to put a referral page neatly into your existing
            website.
          </Paragraph>
          <Paragraph>
            Simply create a new page on your website with nothing between your{' '}
            {`<Body> </Body>`} tags.
          </Paragraph>
          <Paragraph>
            Give the page a title. Example: “Refer a Friend”.
          </Paragraph>
          <Paragraph>
            Once you have created your page, paste this snippet of code between
            your {`<Body> </Body>`} tags.
          </Paragraph>
          <Paragraph>
            Once you have pasted the snippet, hit verify code to make sure your
            page load correctly, then publish your page.
          </Paragraph>

          <InputGroup>
            <StyledLabel title="Referral code snippet" />
            <TextareaBase
              name={'codeSnippet'}
              rows={4}
              value={getReviewAndPublishCodeSnippet(values.url)}
              contentEditable={false}
            />
          </InputGroup>

          <SnippetButtonsContainer>
            <Tooltip
              placement="top"
              overlay="Copied"
              visible={isCopyTooltipShown}
            >
              <SnippetButton onClick={handleCopySnippet}>
                Copy Snippet
              </SnippetButton>
            </Tooltip>
            <VerifyButton onClick={handleVerifyCode}>
              Verify Code
            </VerifyButton>
          </SnippetButtonsContainer>

          <DoneButton onClick={handleSave}>I'm done</DoneButton>
        </>
      )}
      <Footer>
        <FooterText>
          {isShopify
            ? 'Having trouble setting up your Shopify page?'
            : 'Having trouble setting up your referral web page?'}
        </FooterText>
        <FooterLink href={REFERRAL_WEBPAGE_SETUP_HELP} target="_blank">Click here for help.</FooterLink>
      </Footer>
    </Container>
  );
};

export default ReviewAndPublishForm;
