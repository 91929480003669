import React, { FC } from 'react';

interface Props {
  className?: string;
}

const CogPaused: FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="#979797"
      d="M24 8.805l-1.257-3.033-3.597.711a8.925 8.925 0 00-1.632-1.629l.711-3.6L15.195 0l-2.043 3.048a8.864 8.864 0 00-2.307 0L8.805 0 5.772 1.257l.711 3.597a8.925 8.925 0 00-1.629 1.632l-3.6-.711L0 8.805l3.048 2.043a8.864 8.864 0 000 2.307L0 15.195l1.257 3.033 3.597-.711a8.925 8.925 0 001.632 1.629l-.711 3.6L8.805 24l2.043-3.048a8.864 8.864 0 002.307 0L15.195 24l3.033-1.257-.711-3.597a8.925 8.925 0 001.629-1.632l3.6.711L24 15.195l-3.048-2.043a8.864 8.864 0 000-2.307L24 8.805zm-10.116 7.743a4.923 4.923 0 11-3.767-9.098 4.923 4.923 0 013.767 9.098z"
    ></path>
    <circle cx="18.5" cy="18.5" r="7" fill="white" stroke="#F95373"/>
    <rect x="16" y="15" width="2" height="7" fill="#F95373"/>
    <rect x="19" y="15" width="2" height="7" fill="#F95373"/>
  </svg>

);

export default CogPaused;
