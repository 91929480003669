import styled from 'styled-components';
import { MenuContainer as OriginMenuContainer } from '../../styles';

export const Container = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #CCCCCC;

  &:first-of-type {
    border-top: 1px solid #CCCCCC;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  cursor: pointer;
  padding-left: 20px;
`;

export const CheckBlock = styled.div`
  width: 42px;
`;
export const NameBlock = styled.div`
  flex: 0.6;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #17AACF;
`;
export const StatusBlock = styled.div`
  flex: 0.4;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  color: #ababab;

  &.subscribed {
    color: ${({ theme }) => theme.colors.green}
  }
  &.pending {
    color: #f99700;
  }
  &.unsubscribed {
    color: ${({ theme }) => theme.colors.danger}
  }
`;
export const IconBlock = styled.div`
  width: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
`;
export const Row = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
`;
export const Block = styled.div`
  flex: 0.25;

  &:nth-of-type(1) {
    flex: 0.26;
  }
  &:nth-of-type(2) {
    flex: 0.33;
  }
  &:nth-of-type(3) {
    flex: 0.25;
  }
  &:nth-of-type(4) {
    flex: 0.16;
  }
  &.options {
    position: relative;
    display: flex;
    align-self: stretch;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 6px;
  }
`;
export const BlockTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  height: 22px;
  line-height: 22px;
  margin-bottom: 6px;
`;
export const BlockValue = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 14px;
`;
export const MenuContainer = styled(OriginMenuContainer)`
  z-index: 100001;
`;
export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;





