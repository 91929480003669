import styled from 'styled-components';
import { ITheme } from '../../types';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  padding: 2px 5px;
  min-height: 13px;
  border-radius: 21px;
  background: #17AACF;
  color: ${({ theme }: ITheme) => theme.colors.white};
`;

