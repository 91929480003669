import React, { FC } from 'react';
import { Body2, Emphasized } from 'uikit';
import { emailType } from '../../../../types';
import { Container } from './styles';

interface Props {
  type: emailType;
}

const EmailTypeDescription: FC<Props> = ({ type }) => {
  return (
    <Container>
      {type === emailType.invitationEmail && (
        <Body2>
          Your <Emphasized>Invitiation email</Emphasized> will automatically
          send to any new customer added to your list. They will receive this
          email once a week for 4 weeks, then once a month after that.
        </Body2>
      )}
      {type === emailType.reminderEmail && (
        <Body2>
          Your <Emphasized>Reminder email</Emphasized> will automatically send
          to customers who have signed up for your referral program every 4
          weeks informing them of your active referral opportunities.
        </Body2>
      )}
      {type === emailType.newOpportunityEmail && (
        <Body2>
          Your <Emphasized>New Opportunity email</Emphasized> will automatically
          send to customers who have signed up for your referral program
          immediatly upon publishing a new referral opportunity.
        </Body2>
      )}
    </Container>
  );
};

export default EmailTypeDescription;
