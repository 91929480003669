import React, { FC, useEffect, useState } from 'react';
import { ApolloError } from 'apollo-boost';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import {
  useCreateDraftOpportunityMutation,
  usePublishOpportunityMutation,
  useGetUserIntegrationQuery,
  useGetOfferLazyQuery,
  useGetFirstOpportunityStatusQuery,
  useGetBusinessDetailsQuery,
  GetFirstOpportunityStatusDocument,
  GetFirstOpportunityStatusQuery,
  OfferStatus,
  Offer,
  PublishOpportunityMutation
} from '../../graphql';
import CreateOpportunityForm from 'components/CreateOpportunity';
import { FullScreenLoader } from 'uikit';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import CreateOpportunitySuccessModal from 'components/CreateOpportunitySuccessModal';
import {
  normalizeOpportunityFormValues,
  getIsProfileSetupCompleted,
  getProfileStatus
} from 'helpers';
import { sendOpportunityPublishedTrackingEvent } from 'helpers/segment';
import routes from 'constants/routes';
import getInitialValues from 'components/CreateOpportunity/utils';
import { OPPORTUNITY_DUPLICATE_PARAM } from 'constants/opportunityQueryParams';
import formatDuplicatedOpportunity from './utils';

import { AcceptButton, DraftOpportunityMessage } from './styles';

const DRAFT_OPPORTUNITY_DEFAULT_TITLE = 'Draft';

const CreateOpportunityFormPage: FC<RouteComponentProps> = ({
  location,
  history
}) => {
  const [isSuccessPublishModalOpen, setSuccessPublishModal] = useState(false);
  const [isFirstOpportunityModalOpen, setFirstOpportunityModal] = useState(
    false
  );
  const [isFormSubmissionSucceeded, setFormSubmissionSucceeded] = useState<
    boolean | null
  >(null);

  const [error, setError] = useState<null | ApolloError>(null);

  const params = queryString.parse(location.search);

  const id = params[OPPORTUNITY_DUPLICATE_PARAM];

  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });
  const isProfileSetupCompleted = getIsProfileSetupCompleted(
    businessProfileData
  );

  const profileSetupStatus = getProfileStatus(businessProfileData);

  const { completeProfile, setupBilling, setupTracking } = profileSetupStatus;

  // TODO: handle errors of GraphQL operations
  const [
    getOpportunity,
    { data: opportunityData, loading: getOpportunityLoading }
  ] = useGetOfferLazyQuery();

  const {
    data: firstOpportunityStatusData,
    loading: getFirstOpportunityLoading
  } = useGetFirstOpportunityStatusQuery();

  const redirectUser = () => {
    if (isProfileSetupCompleted) {
      history.push(routes.MY_OPPORTUNITIES);
    } else {
      history.push(routes.DASHBOARD);
    }
  };

  useEffect(() => {
    if (typeof id === 'string') {
      getOpportunity({ variables: { id } });
    }
  }, [id, getOpportunity]);

  const {
    data: integrationInfo,
    loading: isUserIntegrationLoading
  } = useGetUserIntegrationQuery();

  const [
    saveDraftOpportunity,
    { data: draftOpportunityData, loading: isSaveDraftOpportunityLoading }
  ] = useCreateDraftOpportunityMutation({
    update: (cache, result) => {
      const firstDraftOfferId = result.data?.saveDraftOffer?.id;
      const businessIdQuery = cache.readQuery<GetFirstOpportunityStatusQuery>({
        query: GetFirstOpportunityStatusDocument
      });

      if (
        firstDraftOfferId &&
        businessIdQuery?.getCurrentBusiness?.id &&
        !businessIdQuery?.getCurrentBusiness?.didPublishOffers &&
        !businessIdQuery?.getCurrentBusiness?.firstDraftOfferId
      ) {
        cache.writeQuery({
          query: GetFirstOpportunityStatusDocument,
          data: {
            getCurrentBusiness: {
              __typename: 'Business',
              id: businessIdQuery.getCurrentBusiness.id,
              firstDraftOfferId,
              didPublishOffers: false
            }
          }
        });
      }
    },
    onError: (error) => {
      setError(error);
      setFormSubmissionSucceeded(false);
    },
    onCompleted: () => {
      setFormSubmissionSucceeded(true);
      if (
        !firstOpportunityStatusData?.getCurrentBusiness?.didPublishOffers &&
        !firstOpportunityStatusData?.getCurrentBusiness?.firstDraftOfferId
      ) {
        setFirstOpportunityModal(true);
      }
    }
  });

  const draftOpportunityDataBool = !!draftOpportunityData;

  useEffect(() => {
    const isFirstOpportunity =
      !firstOpportunityStatusData?.getCurrentBusiness?.didPublishOffers &&
      !firstOpportunityStatusData?.getCurrentBusiness?.firstDraftOfferId;
    if (
      !(
        draftOpportunityDataBool &&
        isFormSubmissionSucceeded &&
        !isFirstOpportunity &&
        !isFirstOpportunityModalOpen
      )
    )
      return;
    redirectUser();
    // eslint-disable-next-line
  }, [draftOpportunityDataBool, isFormSubmissionSucceeded, redirectUser]);

  const [
    publishOpportunity,
    { data: publishedOpportunityData, loading: isPublishOpportunityLoading }
  ] = usePublishOpportunityMutation({
    update: (cache) => {
      const businessIdQuery = cache.readQuery<GetFirstOpportunityStatusQuery>({
        query: GetFirstOpportunityStatusDocument
      });

      if (
        businessIdQuery?.getCurrentBusiness?.id &&
        !businessIdQuery?.getCurrentBusiness?.didPublishOffers
      ) {
        cache.writeQuery({
          query: GetFirstOpportunityStatusDocument,
          data: {
            getCurrentBusiness: {
              __typename: 'Business',
              id: businessIdQuery.getCurrentBusiness.id,
              firstDraftOfferId: null,
              didPublishOffers: true
            }
          }
        });
      }
    },
    onCompleted: (data: PublishOpportunityMutation) => {
      setFormSubmissionSucceeded(true);
      setSuccessPublishModal(true);
      sendOpportunityPublishedTrackingEvent(data.v15CreateOffer as Offer);
    },
    onError: (error) => {
      setError(error);
      setFormSubmissionSucceeded(false);
    }
  });

  const isLoading =
    isUserIntegrationLoading ||
    isPublishOpportunityLoading ||
    isSaveDraftOpportunityLoading ||
    getOpportunityLoading ||
    getFirstOpportunityLoading;

  const handlePublishOpportunity = (values: CreateOpportunityType) => {
    const input = normalizeOpportunityFormValues(values, integrationInfo);

    if (
      !completeProfile.isCompleted ||
      !setupBilling.isCompleted ||
      !setupTracking.isCompleted
    ) {
      input.status = OfferStatus.InQueue;
    }

    publishOpportunity({ variables: { input } });
  };

  const handleSaveDraftOpportunity = async (values: CreateOpportunityType) => {
    const formattedValues = normalizeOpportunityFormValues(
      values,
      integrationInfo
    );

    const input = {
      ...formattedValues,
      title: formattedValues.title || DRAFT_OPPORTUNITY_DEFAULT_TITLE
    };

    await saveDraftOpportunity({ variables: { input } });
  };

  const closePublishSuccessModal = () => {
    setSuccessPublishModal(false);
    redirectUser();
  };

  const closeFirstOpportunityModal = () => {
    setFirstOpportunityModal(false);
    redirectUser();
  };

  const handleStepClick = (index: number) => {
    if (index === 1) {
      history.push(routes.CREATE_OPPORTUNITY);
    }
  };

  return (
    <>
      <CreateOpportunityForm
        error={error}
        onCloseErrorModal={() => setError(null)}
        integrationInfo={integrationInfo}
        onPublish={handlePublishOpportunity}
        onSaveDraft={handleSaveDraftOpportunity}
        values={formatDuplicatedOpportunity(opportunityData)}
        onStepClick={handleStepClick}
        title="Create Opportunity"
        isSubmissionSucceeded={isFormSubmissionSucceeded}
      />

      <CreateOpportunitySuccessModal
        title="Opportunity Published!"
        // TODO: remove this ugly adaptation
        values={getInitialValues(
          publishedOpportunityData
            ? { getOffer: publishedOpportunityData.v15CreateOffer }
            : publishedOpportunityData,
          businessProfileData
        )}
        isOpen={isSuccessPublishModalOpen}
        onClose={closePublishSuccessModal}
      >
        <AcceptButton onClick={closePublishSuccessModal} type="button">
          Done
        </AcceptButton>
      </CreateOpportunitySuccessModal>

      <CreateOpportunitySuccessModal
        title="You have created your first opportunity."
        // TODO: remove this ugly adaptation
        values={getInitialValues(
          draftOpportunityData
            ? { getOffer: draftOpportunityData.saveDraftOffer }
            : draftOpportunityData,
          businessProfileData
        )}
        isOpen={isFirstOpportunityModalOpen}
        onClose={closeFirstOpportunityModal}
      >
        <DraftOpportunityMessage>
          You will have the option to publish this opportunity once you complete
          all the steps of account set up
        </DraftOpportunityMessage>
        <AcceptButton onClick={closeFirstOpportunityModal} type="button">
          Continue
        </AcceptButton>
      </CreateOpportunitySuccessModal>

      {isLoading && <FullScreenLoader />}
    </>
  );
};

export default CreateOpportunityFormPage;
