import React, { FC } from 'react';
import IconProps from './IconProps';

const ChevronBottom: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="10"
      height="10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H9.79809V9.79794H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48287 9.48287C9.06257 9.90316 8.37493 9.90316 7.95493 9.48287L0.315221 1.84316C-0.105074 1.42287 -0.105074 0.735221 0.315221 0.315221C0.735221 -0.105074 1.42287 -0.105074 1.84316 0.315221L9.48287 7.95493C9.90316 8.37493 9.90316 9.06257 9.48287 9.48287Z"
        fill="#020303"
      />
    </g>
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="0"
      width="10"
      height="10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27832 0H17.7965V9.79794H8.27832V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58453 9.48287C8.17625 9.06257 8.17625 8.37493 8.58453 7.95493L16.006 0.315221C16.4142 -0.105074 17.0822 -0.105074 17.4902 0.315221C17.8985 0.735221 17.8985 1.42287 17.4902 1.84316L10.0688 9.48287C9.66082 9.90316 8.99282 9.90316 8.58453 9.48287Z"
        fill="#020303"
      />
    </g>
  </svg>
);

export default ChevronBottom;
