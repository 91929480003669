import styled from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  position: absolute;
  z-index: 3;
  ${breakpoint('xs', 'sm')`
   flex-direction: column;
   height: 100px;
  `};
`;

export const Text = styled.span`
  margin-left: 5px;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  color: red;
  ${breakpoint('xs', 'sm')`
    margin-left: 0px;
    margin-bottom: 5px;
  `};
`;

export const Line = styled.small`
  flex: 1 1 auto;
  border: 1px solid red;
  margin-right: 5px;
  ${breakpoint('xs', 'sm')`
    order: 1;
  `};
  :before {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 1px;
    left: 0;
    content: '';
    background: red;
    border-radius: 100%;
    ${breakpoint('xs', 'sm')`
      bottom: 0px;
      left: 50px;
    `};
  }
`;
