import styled from 'styled-components';
import { PrimaryButton, H2, LinkTextMixin, Link } from 'uikit';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;
export const HeaderBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 25px 20px 40px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-bottom: none;

  ${breakpoint('xs', 'lg')`
    padding: 20px 15px 20px 15px;
  `}
`;
export const HeaderTitle = styled(H2)`
`;
export const HeaderLink = styled(Link)`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.main};
`;
export const Content = styled.div`
  
`;

export const DraftOpportunityMessage = styled.p`
  margin-bottom: 20px;
  text-align: center;
  font-size: 21px;
  line-height: 1.2;
`;

export const AcceptButton = styled(PrimaryButton)`
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;


