import styled, { css } from 'styled-components';
import IntegrationPageHeader from 'components/IntegrationPageHeader';
import { breakpoint } from '../../helpers';

export const paddingBlock = css`
  ${breakpoint('lg')`
    padding: 0 60px;
  `}

  ${breakpoint('md', 'lg')`
    padding: 0 20px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 0 25px;
    margin: 0 auto;
    border-radius: 8px;
  `};
`;

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 30px 0 0 0;
  `}

  ${breakpoint('md', 'lg')`
    padding: 25px 0 0 0;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px 0;
    margin: 0 auto;
    border-radius: 8px;
  `};
`;
export const ContainerInner = styled.div`
  ${paddingBlock}
`;

export const ListContainerInner = styled(ContainerInner)`
  ${paddingBlock}
  ${breakpoint('lg')`
    padding-bottom: 60px;
  `}

  ${breakpoint('md', 'lg')`
    padding-bottom: 50px;
  `}

  ${breakpoint('xs', 'md')`
    padding-bottom: 90px;
  `};
`;

export const StyledHeader = styled(IntegrationPageHeader)`
  ${breakpoint('lg')`
    margin-bottom: 15px;
  `}

  ${breakpoint('md', 'lg')`
    margin-bottom: 35px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 25px;
  `}
`;

// * Form
export const FormArea = styled.div``;
