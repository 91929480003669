import styled from 'styled-components';
import { H3, Body1, Body2, Body3 } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
	
`;
export const Header = styled.div`
	display: flex;
	background: #fff;
	justify-content: center;
	align-items: center;
	height: 120px;
`;
export const HeaderInner = styled.div`
	display: flex;
	flex-direction: row;
`;
export const HeaderInnerAlt = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #EDEDED;
	width: 100%;
	height: 100%;
`;
export const HeaderLogo = styled.div`
	width: 53px;
	height: 53px;
	margin-right: 15px;
	img {
		width: 100%;
		height: 100%;
	}
`;
export const HeaderInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;
export const BusinessName = styled(H3)`
	margin-bottom: 5px;
`;
export const HeaderText = styled(Body3)`
	color: ${({ theme }) => theme.colors.inputColor};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
export const TextAlt = styled(Body1)`
	color: ${({ theme }) => theme.colors.black};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
export const Content = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
	//background-image: url('/assets/preview-1.png');
	background: #ededed;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center center;
`;
export const ContentInner = styled.div`
	width: 370px;
	box-sizing: border-box;
	background-color: ${({ theme }) => theme.colors.white};
	position: relative;

	&.responsive {
    width: 327px;
	}
	${breakpoint('xs', 'sm')`
    width: 327px;
	`}
	
`;
export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: 310px;
	box-sizing: border-box;
	background-color: #BCBCBC;
	padding: 64px 20px 20px 20px;
`;
export const FooterAlt = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 310px;
	box-sizing: border-box;
	background-color: #EDEDED;
`;
export const MainLogo = styled.div`
	margin-top: 67px;
	margin-bottom: 23px;
`;
export const FooterText = styled(Body2)`
	color: ${({ theme }) => theme.colors.white};
	opacity: 0.57;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;  