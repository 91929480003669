import moment from 'moment';
import orderBy from 'lodash.orderby';
import { SortOrder, SortKey } from '../../constants';
import { FormattedOpportunityList } from './utils';
import { CommissionType } from '../../../../graphql';

export const sortByConversion = (
  items: FormattedOpportunityList,
  sortOrder: SortOrder
) =>
  orderBy(
    items,
    ({ conversion }) => (typeof conversion === 'number' ? conversion : -1),
    sortOrder
  );

export const sortByDate = (
  items: FormattedOpportunityList,
  key: SortKey.CREATED_AT | SortKey.PUBLISHED_AT | SortKey.CREATED_AT_UNFORMATTED | SortKey.PUBLISHED_AT_UNFORMATTED,
  sortOrder: SortOrder
) => {
  const res = orderBy(
    items,
    (item) => {
      const momentDate = moment(item[key]).unix();
      return momentDate ? momentDate : -1;
    },
    sortOrder
  );

  return res;
}

export const sortByCommission = (
  items: FormattedOpportunityList,
  sortOrder: SortOrder
) =>
  orderBy(
    items,
    [
      ({ commissionType }) => commissionType === CommissionType.Fixed,
      ({ commission }) => commission
    ],
    [SortOrder.DESC, sortOrder]
  );
