import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloError } from 'apollo-boost';
import { NetworkStatus } from 'apollo-client';
import EmailIcon from 'assets/svg/Email';
import { sendCreateEmailCampaignClickTrackingEvent, sendPauseEmailCampaignTrackingEvent } from 'helpers/segment';
import routes from 'constants/routes';
import { Modal, FullScreenLoader } from 'uikit';
import PageInfo from 'components/PageInfo';
import EmailsTable from './components/EmailsTable';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import ConfirmPauseModal from './components/ConfirmPauseModal';
import {
  Container,
  StyledLink,
  CreateButton,
  TableHeader,
  TableContainer
} from './styles';
import {
  EMAIL_STEP_NAME_PARAM,
  EMAIL_STEP_NAME_DEFINE_AND_SEND,
  EMAIL_STEP_ID_PARAM_NAME
} from 'constants/emailCampaignQueryParams';
import {
  useGetEmailCampaignsListQuery,
  useGetBusinessDetailsQuery,
  useGetCustomersQuery,
  useListOpportunitiesQuery,
  useUpdateEmailCampaignMutation,
  useDeleteEmailCampaignMutation,
  OfferStatus,
  CampaignStatus
} from '../../graphql';

import './styles.scss';

type confirmData = {
  id?: string | null;
  opened: boolean;
};

type Props = {
  onCustomerRedirect?: () => void;
};

const EmailsList: FC<Props> = ({ onCustomerRedirect }) => {
  const history = useHistory();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isNoOffersModalOpen, setIsNoOffersModalOpen] = useState<boolean>(
    false
  );
  const [isNoCustomersModalOpen, setIsNoCustomersModalOpen] = useState<boolean>(
    false
  );
  const [isConfirmDeleteModalData, setIsConfirmDeleteModalData] = useState<
    confirmData
  >({
    id: null,
    opened: false
  });
  const [isConfirmPauseModalData, setIsConfirmPauseModalData] = useState<
    confirmData
  >({
    id: null,
    opened: false
  });

  const {
    data: emailCampaignsData,
    loading: getEmailCampaignsLoading,
    refetch: refetchEmailCampaigns,
    networkStatus: emailCampaignsDataNetworkStatus
  } = useGetEmailCampaignsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        page: {
          limit: 9999,
          offset: 0
        }
      }
    }
  });
  const {
    data: customersData,
    loading: customersDataLoading
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        page: {
          offset: 0,
          limit: 1
        }
      }
    }
  });
  const {
    data: opportunitiesList,
    loading: isOpportunityListLoading
  } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: 1
        }
      }
    }
  });

  const {
    data: businessProfileData,
    loading: businessProfileDataLoading
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  const [
    updateCampaign,
    { loading: isCampaignUpdatingLoading }
  ] = useUpdateEmailCampaignMutation({
    onCompleted: () => {
      refetchEmailCampaigns();
    },
    onError: () => {
      setIsErrorModalOpen(true);
    }
  });

  const [
    deleteCampaign,
    { loading: isCampaignDeleteLoading }
  ] = useDeleteEmailCampaignMutation({
    onCompleted: () => {
      refetchEmailCampaigns();
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(true);
    }
  });

  const onCreateCampaignClick = () => {
    if (!businessProfileData || !customersData) return;
    sendCreateEmailCampaignClickTrackingEvent();
    const hasOpportunity = !!opportunitiesList?.listOffers?.items?.length;
    const hasCustomers = !!customersData.findCustomers?.total;
    if (!hasOpportunity) {
      setIsNoOffersModalOpen(true);
    } else if (!hasCustomers) {
      setIsNoCustomersModalOpen(true);
    } else {
      history.push(routes.CREATE_EMAIL_CAMPAIGN);
    }
  };
  const onItemEdit = (id: string) => {
    history.push(
      `${routes.CREATE_EMAIL_CAMPAIGN}?${EMAIL_STEP_NAME_PARAM}=${EMAIL_STEP_NAME_DEFINE_AND_SEND}&${EMAIL_STEP_ID_PARAM_NAME}=${id}`
    );
  };
  const onItemDelete = (id: string) => {
    setIsConfirmDeleteModalData({
      id: id,
      opened: true
    });
  };
  const onItemPause = (id: string) => {
    setIsConfirmPauseModalData({
      id: id,
      opened: true
    });
  };
  const onItemResume = (id: string) => {
    if (id) {
      updateCampaign({
        variables: {
          input: {
            id: id,
            status: CampaignStatus.Active
          }
        }
      });
    }
  };

  const handleDeleteItem = (id?: string | null) => {
    if (id) {
      deleteCampaign({ variables: { id: id } });
    }
    closeConfirmDeleteModal();
  };
  const handlePauseItem = (id?: string | null) => {
    if (id) {
      updateCampaign({
        variables: {
          input: {
            id: id,
            status: CampaignStatus.Paused
          }
        }
      });
      const campaign = (emailCampaignsData?.findCampaigns?.items)?.find(item => item?.id === id);
      if(campaign) {
        const type = campaign.sendToFilter?.tags?.in?.length ? 'Tag' : 'All customers';
        sendPauseEmailCampaignTrackingEvent(campaign.id, type);
      }
      
    }
    closeConfirmPauseModal();
  };

  const closeConfirmDeleteModal = () => {
    setIsConfirmDeleteModalData({
      id: null,
      opened: false
    });
  };
  const closeConfirmPauseModal = () => {
    setIsConfirmPauseModalData({
      id: null,
      opened: false
    });
  };

  return (
    <Container>
      <PageInfo 
        text={
          <span>Send an email to invite your customers to earn money promoting your
          opportunities. You can also send thank you emails to referrers who
          have send you customers. <StyledLink href="">Learn More</StyledLink>
          .</span>
        }
        icon={<EmailIcon />}
        rightBlockContent={
          <CreateButton onClick={onCreateCampaignClick}>
            Create Email Campaign
          </CreateButton>
        }
      />
      {/* Table */}
      <TableContainer>
        <TableHeader>Email</TableHeader>
        <EmailsTable
          onCreateClick={onCreateCampaignClick}
          onEdit={onItemEdit}
          onDelete={onItemDelete}
          onPause={onItemPause}
          onResume={onItemResume}
          data={emailCampaignsData?.findCampaigns?.items || []}
        />
      </TableContainer>
      {/* <PaginationContainer>
        <Pagination
          onPageChange={() => {}}
          pageCount={3}
        />
      </PaginationContainer> */}
      {(getEmailCampaignsLoading ||
        businessProfileDataLoading ||
        customersDataLoading ||
        isOpportunityListLoading ||
        isCampaignUpdatingLoading ||
        isCampaignDeleteLoading ||
        emailCampaignsDataNetworkStatus === NetworkStatus.refetch) && (
        <FullScreenLoader />
      )}

      <Modal
        type="warning"
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        childrenBeforeButtons={true}
        title="Error"
        message="An unexpected error has occured."
        mainButton={{
          text: 'Okay',
          onClick: () => {
            setIsErrorModalOpen(false);
          }
        }}
      />

      <Modal
        isOpen={isNoOffersModalOpen}
        onClose={() => setIsNoOffersModalOpen(false)}
        childrenBeforeButtons={true}
        title="Before we do that..."
        message="Please create and publish an opportunity to invite your customers to promote."
        mainButton={{
          text: 'Create Opportunity',
          onClick: () => {
            history.push(routes.CREATE_OPPORTUNITY);
          }
        }}
      />

      <Modal
        isOpen={isNoCustomersModalOpen}
        onClose={() => setIsNoCustomersModalOpen(false)}
        childrenBeforeButtons={true}
        title="Before we do that..."
        message="Please import customers to your customers’ list."
        mainButton={{
          text: 'Go to Customer List',
          onClick: () => {
            onCustomerRedirect && onCustomerRedirect();
          }
        }}
      />

      <Modal
        className="emails-list-confirmation-modal"
        isOpen={isConfirmDeleteModalData.opened}
        onClose={closeConfirmDeleteModal}
      >
        <ConfirmDeleteModal
          onApply={() => handleDeleteItem(isConfirmDeleteModalData.id)}
          onCancel={closeConfirmDeleteModal}
        />
      </Modal>

      <Modal
        className="emails-list-confirmation-modal"
        isOpen={isConfirmPauseModalData.opened}
        onClose={closeConfirmPauseModal}
      >
        <ConfirmPauseModal
          onApply={() => handlePauseItem(isConfirmPauseModalData.id)}
          onCancel={closeConfirmPauseModal}
        />
      </Modal>
    </Container>
  );
};

export default EmailsList;
