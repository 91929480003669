import React, { FC } from 'react';
import { Container, SaveDraftButton, SubmitButton } from './styles';
import Tooltip from 'rc-tooltip';
import './styles.scss';
import {
  CampaignStatus,
} from '../../../../../../graphql';

interface Props {
  onPublish: () => void;
  onSaveDraft: () => void;
  publishDisabled: boolean;
  saveDraftDisabled: boolean;
  values: any
}

const FormFooter: FC<Props> = ({
  onPublish,
  onSaveDraft,
  publishDisabled,
  saveDraftDisabled,
  values
}) => {
  const disabledTooltip =
    'Before you can Create your email campaign, you should publish an opportunity and add customers to your customer’s list first.';
  return (
    <Container>
      {values?.status === CampaignStatus.Draft && 
        <>
        {saveDraftDisabled ? (
          <Tooltip
            placement="top"
            trigger={['hover']}
            overlay={<span>{disabledTooltip}</span>}
            overlayClassName="email-campaign-save-draft-tooltip"
          >
            
              <span>
                <SaveDraftButton
                  disabled={saveDraftDisabled}
                  onClick={onSaveDraft}
                  type="button"
                >
                  Save Draft
                </SaveDraftButton>
              </span>
            
          </Tooltip>
        ) : (
          <span>
            <SaveDraftButton
              disabled={saveDraftDisabled}
              onClick={onSaveDraft}
              type="button"
            >
              Save Draft
            </SaveDraftButton>
          </span>
        )}
        </>
      }
      {publishDisabled ? (
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlay={<span>{disabledTooltip}</span>}
          overlayClassName="email-campaign-start-campaign-tooltip"
        >
          <span>
            <SubmitButton
              type="button"
              disabled={publishDisabled}
              onClick={onPublish}
            >
              {values?.status === CampaignStatus.Draft ? 'Start Campaign' : 'Update Campaign'}
            </SubmitButton>
          </span>
        </Tooltip>
      ) : (
        <span>
          <SubmitButton
            type="button"
            disabled={publishDisabled}
            onClick={onPublish}
          >
            {values?.status === CampaignStatus.Draft ? 'Start Campaign' : 'Update Campaign'}
          </SubmitButton>
        </span>
      )}
    </Container>
  );
};

export default FormFooter;
