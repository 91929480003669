import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoint } from 'helpers';
import Footer from 'components/Footer';
import PageMenu from 'components/PageMenu';
import { ButtonTextMixin } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const Content = styled.div`
  flex: 1 0 auto;

  ${breakpoint('xl')`
    display: flex;
    flex-direction: column;
    padding-left: 10%;
  `}

  ${breakpoint('xs', 'xl')`
    padding: 25px 12px 0;
  `}
  
`;

export const Header = styled.header`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('xs', 'xl')`
    padding: 0 5px;
    margin-bottom: 15px;
    flex-wrap: wrap;
  `}
`;

export const StyledPageMenu = styled(PageMenu)`
  ${breakpoint('xl')`
    width: 35%;
    padding: 50px 20px;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.blackPattern};
  `}
`;

export const Main = styled.main`
  ${breakpoint('xl')`
    display: flex;
    flex: 1 0 auto;
  `}

  &.no-sidebar {
    display: block;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blackPattern};
  }
`;

export const PageContent = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);

  ${breakpoint('xs', 'xl')`
    margin-bottom: 60px;
  `};

  ${breakpoint('xl')`
    width: 70%;
    z-index: 2;
    height: max-content;
    margin-right: -5%;
  `}

  ${breakpoint('xs', 'xl')`
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  `}

  &.no-sidebar {
    ${breakpoint('xl')`
    width: 90%;
    height: max-content;
    margin-right: -5%;
  `}
  }
`;

export const SidebarColumn = styled.div`
  z-index: 1;
  ${breakpoint('xl')`
    width: 35%;
    padding-left: calc(5% + 40px);
    box-sizing: border-box;
    padding-right: 40px;
    background-color: ${({ theme }) => theme.colors.blackPattern};
  `}

  ${breakpoint('xs', 'xl')`
    display: none;
  `}
`;

export const MobileLink = styled(Link)`
  display: block;
  margin: 0 -12px;
  padding: 15px 0;
  ${ButtonTextMixin};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentMainColor};

  ${breakpoint('xl')`
    display: none;
  `}
`;

export const SidebarContent = styled.div`
  position: sticky;
  top: 10%;
  display: flex;
  justify-content: center;
`;

export const StyledFooter = styled(Footer)`
  flex-shrink: 0;

  ${breakpoint('xl')`
    width: calc((100% - 10%) * 0.35);
    margin-left: auto;
    padding-top: 45px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    text-align: right;
  `}

  ${breakpoint('xs', 'xl')`
    padding-top: 180px;
    text-align: center;
  `}
`;
