const INTEGRATION_PAGE = '/set-up-customer-tracking';

const routes = {
  BILLING: '/billing',
  BILLING_HISTORY_DETAILED: '/billing-history-detailed',
  BILLING_ACCOUNTS: '/billing-accounts',
  BUSINESS_PROFILE: '/business-profile',
  WEB_INTEGRATION: '/web-integration',
  SET_UP_CUSTOMER_TRACKING: '/set-up-customer-tracking',
  SHOPIFY_INTEGRATION_PAGE: `${INTEGRATION_PAGE}/shopify`,
  STANDARD_INTEGRATION_PAGE: `${INTEGRATION_PAGE}/:platform`,
  CREATE_OPPORTUNITY: '/create-opportunity',
  CREATE_OPPORTUNITY_FORM: '/create-opportunity/form',
  HELP: '/help-center',
  MY_OPPORTUNITIES: '/my-opportunities',
  DASHBOARD: '/',
  SCHEDULE_ONBOARDING: '/schedule-onboarding',
  SUPPORT: '/support',
  LOGIN: '/login',
  SIGN_UP: '/register',
  RESET_PASSWORD: '/change-password',
  RESET_DRUMMER_PASSWORD: '/change-drummer-password',
  FORGOT_PASSWORD: '/forgot-password',
  OPPORTUNITY_EXAMPLE: '/opportunity-example',
  NOTIFICATIONS_EXAMPLES: '/notifications-examples',
  FAQ: '/faq',
  EDIT_OPPORTUNITY: '/edit-opportunity/:id',
  SETUP_SHOPIFY_INTEGRATION: '/offers/shopify-integration-complete',
  CUSTOMERS_MANAGEMENT: '/customers-management',
  CREATE_EMAIL_CAMPAIGN: '/create-email-campaign',
  REFERRAL_PAGE: '/referral-page',
};

export default routes;
