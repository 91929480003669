import styled from 'styled-components';
import { Label } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  ${breakpoint('md')`
    display: flex;
  `}
`;

export const InputGroup = styled.div`
  ${breakpoint('md')`
    margin-right: 20px;
  `}

  ${breakpoint('xs', 'md')`
    :not(:last-child) {
      margin-bottom: 20px;
    }
  `}
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 8px;
`;

export const LinkTitleFieldGroup = styled(InputGroup)`
  ${breakpoint('md')`
    width: 250px;
  `}
`;

export const URLFieldGroup = styled(InputGroup)`
  ${breakpoint('md')`
    flex: 1;
  `}
`;
