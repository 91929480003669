import React, { FC } from 'react';
import routes from 'constants/routes';
import { SCHEDULE_CALL_LINK } from 'constants/outerLinks';
import ScheduleCall from './ScheduleCall';
import {
  Container,
  HeadingContainer,
  ReturnLink,
  StyledHeading,
  ScheduleLink,
  Description
} from './styles';

interface Props {
  className?: string;
  setupInstructions: string;
  onScheduleCall: () => void;
}

const IntegrationPageHeader: FC<Props> = ({
  className,
  onScheduleCall,
  setupInstructions
}) => (
  <Container className={className}>
    <HeadingContainer>
      <StyledHeading>Set up customer tracking</StyledHeading>
    </HeadingContainer>
    <ReturnLink to={routes.DASHBOARD}>&lt; Back to Dashboard</ReturnLink>
    <Description>
      {setupInstructions} If you have ANY questions about setup. Please{' '}
      <ScheduleLink href={SCHEDULE_CALL_LINK}>schedule</ScheduleLink> a free
      onboarding call.
    </Description>
    <ScheduleCall onScheduleCall={onScheduleCall} />
  </Container>
);

export default IntegrationPageHeader;
