import React, { FC } from 'react';
import { Container, Option } from './styles';

interface OptionType {
  name: string;
  value: any;
}

interface Props {
  className?: string;
  options: OptionType[];
  onChange: (value: any) => void;
  selectedValue: any;
}

const Toggle: FC<Props> = ({ className, onChange, options, selectedValue }) => (
  <Container className={className}>
    {options.map(({ name, value }) => (
      <Option
        key={name}
        type="button"
        selected={value === selectedValue}
        onClick={() => onChange(value)}
      >
        {name}
      </Option>
    ))}
  </Container>
);

export default Toggle;
