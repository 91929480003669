import styled from 'styled-components';
import { breakpoint } from '../../helpers';

export const Table = styled.table`
  width: 100%;
`;

export const Th = styled.th`
  text-align: left;
  vertical-align: middle;
  font-family: ${(props) => props.theme.fontFamily.raleway};
  font-size: ${(props) => props.theme.size.caption};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.black};

  ${breakpoint('lg')`
    padding: 10px;
  `}
`;

export const Tr = styled.tr`
  border-bottom: solid 1px #dbdbdb;

  ${breakpoint('xs', 'lg')`
    border-top: solid 1px #dbdbdb;
  `}
`;

export const Td = styled.td`
  vertical-align: middle;

  ${breakpoint('lg')`
    padding: 10px 10px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 15px 10px;
  `}
`;
