import styled, { css } from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';

const lineStyles = css`
  margin-left: 17px;
  margin-right: 10px;
  border-top: 1px solid #d7d7d7;
  content: '';
`;

export const Title = styled.div<ITheme>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  margin-bottom: 8px;
  margin-top: 5px;
  margin-left: 10px;
`;

export const Text = styled.span``;

export const OptionalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  }
`;

export const LargeLine = styled.small`
  ${breakpoint('sm')`
${lineStyles};
flex: 8 1 auto;
`}
`;

export const TinyLine = styled.small`
  ${breakpoint('sm')`
${lineStyles};
flex: 1 1 auto;
`}
`;

export const OptionalText = styled.span`
  color: ${({ theme }: ITheme) => theme.colors.lightOrange};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  font-size: 13px;
`;
