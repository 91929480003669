import React, { FC } from 'react';
import { Container, TitleBlock, DateBlock, DataBlock, DataBlockNumber } from './styles';

interface Props {
  active?: boolean,
  payload?: any[],
}

const Tooltip: FC<Props> = ({active, payload}) => {
  const {payload: _payload} = ((payload && payload[0]) || {});
         
  return <div>
      {active && _payload && 
        <Container>
          {_payload.tooltipLabel && <TitleBlock>{_payload.tooltipLabel}</TitleBlock>}
          <DateBlock>{ _payload.tooltipDate }</DateBlock>
          <DataBlock>
            <DataBlockNumber>{ _payload.tooltipValue || _payload.value  }</DataBlockNumber>
            <span>{_payload.tooltipActionType}</span>
          </DataBlock>
        </Container>
      }
  </div>
};

export default Tooltip;
