import styled from 'styled-components';
import { ButtonTextMixin } from '../Typography';
import { ITheme } from 'types';
import { ChevronRight } from 'assets/svg';
import { breakpoint } from 'helpers';
import Tooltip from '../Tooltip';

export const AccordionItemContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.borderColor};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

interface AccordionTitleProps extends ITheme {
  isActive: boolean;
  isDisabled: boolean;
}

export const AccordionTitleContainer = styled.div<AccordionTitleProps>`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  ${ButtonTextMixin};

  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer' };
    
  color: ${({ isActive, isDisabled, theme }) => {
    if (isDisabled) {
      return theme.colors.borderColor;
    } else if (isActive) {
      return theme.colors.alertColor;
    }

    return theme.colors.black;
  }};
`;

interface ChevronProps extends ITheme {
  isExpanded: boolean;
}

export const StyledChevron = styled(ChevronRight)<ChevronProps>`
  width: 10px;
  height: auto;
  margin-right: 20px;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'};
  transition: all 0.5s;

  & path {
    stroke: currentColor;
  }
`;

export const AccordionItemContent = styled.div`
  padding: 20px;
  ${breakpoint('xs', 'lg')`
    padding: 0 20px 5px 20px;
  `}
`;

export const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 8px;
  height: 17px;
  margin-top: -2px;  
`;
