import * as yup from 'yup';
import { phoneRegExp, emailRegExp } from 'constants/regExp';

export const schema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string().test('invalid email', 'Email has to be valid', function(value) {
      return !value || emailRegExp.test(value);
    }),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
});

export type SchemaType = yup.InferType<typeof schema>;