import React from 'react';
import IconProps from './IconProps';

const Support: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <path
        fill="#00B3FF"
        fillRule="evenodd"
        d="M10.938 2.207c-1.193.568-2.244 1.49-3.743 2.988-1.01 1.01-1.714 2.326-2.165 3.572-.454 1.252-.63 2.368-.63 2.933v10.5h-.7v.2h-2c-.931 0-1.7-.433-1.7-1.2v-4.5C0 14.969.767 14 1.7 14H3v-2.3c0-.768.22-2.048.713-3.41.497-1.369 1.293-2.886 2.492-4.085 1.501-1.501 2.7-2.58 4.131-3.262C11.784.254 13.402 0 15.7 0c4.751 0 7.419 2.129 8.995 3.705.798.798 1.716 1.98 2.438 3.358.72 1.374 1.267 2.987 1.267 4.637V14h1.298c.934 0 1.7.969 1.7 2.7v4.5c0 .767-.768 1.2-1.7 1.2h-1.864a13.65 13.65 0 01-.24 1.393c-.361 1.59-1.078 3.304-2.447 4.445-1.966 1.638-4.069 2.337-5.45 2.568a2 2 0 01-1.998 1.894h-2a2 2 0 110-4h2c.634 0 1.2.295 1.566.756 1.111-.152 3.108-.729 4.986-2.294 1.031-.86 1.648-2.228 1.978-3.68.328-1.44.35-2.866.273-3.718l1.394-.127c.035.377.052.842.039 1.363h1.763c.141 0 .238-.014.3-.028V16.7c0-1.057-.293-1.266-.358-1.3H28.4v2.3H27v-6c0-1.35-.453-2.737-1.108-3.988-.653-1.247-1.485-2.315-2.187-3.017C22.281 3.271 19.949 1.4 15.7 1.4c-2.202 0-3.583.246-4.761.807zM3 15.4V21H1.7c-.14 0-.237-.014-.3-.028V16.7c0-1.057.294-1.266.359-1.3H3zm12.7 14.7h2a.6.6 0 110 1.2h-2a.6.6 0 110-1.2z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B3FF"
        d="M11.849 9.886c1 1.404 2.33 3.003 3.896 4.346 2.335 2.001 5.663 3.23 7.875 3.823.012.077.022.16.031.25.043.44.048 1.128-.144 2.28-.181 1.089-.682 2.26-1.259 3.3-.574 1.037-1.2 1.9-1.588 2.367l1.076.896a17.6 17.6 0 001.737-2.584c.614-1.108 1.197-2.438 1.415-3.749.184-1.1.21-1.863.174-2.428l.013.002.25-1.377c-1.784-.325-6.008-1.562-8.67-3.843-2.708-2.322-4.69-5.505-5.329-6.782l-1.252.626c.205.41.53.99.963 1.669-1.743.648-2.983 1.997-3.788 3.795C6.38 14.414 6 16.899 6 19.7c0 2.402 1.001 4.426 2.213 5.96 1.209 1.529 2.659 2.613 3.652 3.155l.67-1.23c-.84-.457-2.14-1.423-3.223-2.794-1.08-1.366-1.912-3.093-1.912-5.09 0-2.699.37-4.964 1.126-6.652.741-1.653 1.837-2.724 3.323-3.163z"
      ></path>
    </svg>
  );
};

export default Support;
