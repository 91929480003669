import routes from 'constants/routes';

// TODO: add codes for another situations
const setupProfileErrors = {
  SETUP_DRUM_PIXEL: {
    code: 'must_complete_drum_pixel',
    message:
      'Before you can Publish this opportunity, you must set up customer tracking',
    buttonText: 'Set up customer tracking',
    redirect: routes.SET_UP_CUSTOMER_TRACKING
  },
  PURCHASE_AMOUNT_FOR_PERCENTAGE_COMMISSION: {
    code: 'no_setting_send_purchase_amount_for_percentage_commission',
    message:
      'For percentage commission, you must configure the purchase amount property on the customer tracking setup page',
    buttonText: 'Set up customer tracking',
    redirect: routes.SET_UP_CUSTOMER_TRACKING
  },
  INCOMPLETE_PROFILE: {
    code: 'business_publish_profile',
    message:
      'Before you can Publish this opportunity, you must complete your business profile',
    buttonText: 'Go to business profile',
    redirect: routes.BUSINESS_PROFILE
  },
  INCOMPLETE_TRACKING: {
    code: 'must_finish_the_integrations_setting',
    message:
      'Before you can Publish this opportunity, you must set up customer tracking',
    buttonText: 'Set up customer tracking',
    redirect: routes.SET_UP_CUSTOMER_TRACKING
  },
  INCOMPLETE_PAYMENT: {
    code: 'payment_not_invalid',
    message:
      'Before you can Publish this opportunity, you must complete payments settings',
    buttonText: 'Go to payment settings',
    redirect: routes.BILLING
  }
};

export default setupProfileErrors;
