import React, { ReactNode, ComponentType, FC } from 'react';
import { H4 } from '../Typography';
import { Container, StyledTooltip } from './styles';

interface Props {
  className?: string;
  id?: string;
  tagname?: keyof JSX.IntrinsicElements | ComponentType<any>;
  title: string;
  tooltip?: string | ReactNode;
}

const Label: FC<Props> = ({ className, id, tagname, title, tooltip }) => (
  <Container as={tagname} className={className} htmlFor={id}>
    <H4 as="span">{title}</H4>
    {tooltip && <StyledTooltip text={tooltip} />}
  </Container>
);

export default Label;
