import styled, { css } from 'styled-components';
import { breakpoint } from '../../helpers';
import SearchInput from 'components/SearchInput';
import CheckFilter from './components/CheckFilter';
import { TooltipMixin } from 'uikit';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 25px 0 25px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 10px;
  `}
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  margin-right: 38px;
  ${breakpoint('xs', 'lg')`
    margin-right: 0;
  `}
`;

export const ContactsBlock = styled.div`
  display: flex;

  span {
    position: relative;
    display: inline-block;
    font-size: ${({ theme }) => theme.size.body2};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};

    &:nth-of-type(2) {
      font-size: ${({ theme }) => theme.size.body1};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      margin-left: 9px;
      top: -2px;
    }
  }

`;

export const Buttons = styled.div`
  
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-bottom: 16px;
  margin-right: 38px;

  & > div {
    &:first-of-type {
      flex: 1;

      ${breakpoint('xs', 'lg')`
        width: 100%;
        margin-bottom: 12px;
      `}
    }
    &:last-of-type {
      width: 250px;
      margin-left: 16px;

      ${breakpoint('xs', 'lg')`
        width: 100%;
        margin-left: 0;
      `}
    }
  }

  ${breakpoint('xs', 'lg')`
    flex-wrap: wrap;
    margin-right: 0;
  `}
`;

export const StyledSearchInput = styled(SearchInput)`

`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  margin-bottom: 30px;
  margin-right: 38px;

  ${breakpoint('xs', 'lg')`
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-right: 0;
    height: auto; 
  `}
`;
export const SelectedBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: ${({ theme }) => theme.size.body1};
    
    color: ${({ theme }) => theme.colors.black};
    &:nth-of-type(2) {
      margin-left: 4px;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: ${({ theme }) => theme.size.subtitleLarge};
    }
  }
`;
export const FiltersList = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  justify-content: flex-end;
    width: 100%;

  ${breakpoint('xs', 'lg')`
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
  `}  
`;
export const StyledCheckFilter = styled(CheckFilter)`
  margin-left: 35px;
  ${breakpoint('xs', 'lg')`
    margin-left: 0;
    label {
      font-size: ${({ theme }) => theme.size.caption};
      span:last-of-type {
        margin-left: 7px;
        line-height: 17px;
      }
    }
  `}
`;

export const TableContainer = styled.div`
  
`;
export const PaginationContainer = styled.div`
  margin: 20px 38px 20px 0;
`;

export const ModalText = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  margin-bottom: 15px;
  &.emphasized {
    font-size: 21px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  &:last-of-type {
    margin-bottom: 50px;
  }
`;

export const TooltipContent = css`
  ${TooltipMixin};
  max-width: 150px;
`;

