import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { ITheme } from '../../types';

export const Container = styled.div`
  position: relative;

  ${breakpoint('md')`
    display: none;
  `}
`;

export const MetricBlock = styled.div`
  padding: 6px 18px 14px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #707070;
`;

export const Value = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const SelectContainer = styled(MetricBlock)<ITheme & { isOpen: boolean }>`
  margin-bottom: 30px;
  border: solid 1px #d6d6d6;
  border-radius: ${({ isOpen }) => (isOpen ? '11px 11px 0 0' : '11px')};
`;

export const Options = styled.div<ITheme & { isOpen: boolean }>`
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 100%;
  border-bottom: solid 1px #d6d6d6;
  border-left: solid 1px #d6d6d6;
  border-right: solid 1px #d6d6d6;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0 0 11px 11px;
`;
