import React, { FC, useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { getUtmParams, getUtmCookies, setUtmCookies } from 'helpers/utm';
import routes from './constants/routes';
import LoginPage from './pages/LogInPage';
import SignUpPage from './pages/SignUpPage';
import SetupCustomerTracking from 'components/SetUpCustomerTracking';
import WebIntegration from './components/SetUpCustomerTracking/components/WebIntegration';
import ShopifyIntegrationPage from './pages/ShopifyIntegrationPage';
import CreateOpportunityPage from './pages/CreateOpportunityPage';
import CreateOpportunityFormPage from './pages/CreateOpportunityFormPage';
import DashboardPage from './pages/DashboardPage';
import SelectBillingPage from './pages/BillingPage';
import BillingHistoryDetailedPage from './pages/BillingHistoryDetailedPage';
import MyOpportunitiesPage from './pages/MyOpportunitiesPage';
import BusinessProfilePage from './pages/BusinessProfilePage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ProtectedRoute from 'components/ProtectedRoute';
import UnauthorizedRoute from 'components/UnauthorizedRoute';
import ServiceRoute from 'components/ServiceRoute';
import EditOpportunityPage from 'pages/EditOpportunityPage';
import SetupShopifyIntegrationPage from 'pages/SetupShopifyIntegrationPage';
import CustomersManagementPage from 'pages/CustomersManagementPage';
import CreateEmailCampaignPage from 'pages/CreateEmailCampaignPage';
import ReferralWebpage from 'pages/ReferralWebpage';

let prevLocation: any;

const App: FC = () => {
  let location = useLocation();
  let lastLocation = useLastLocation();

  useEffect(
    () => {
      const prev = omit(prevLocation, ['key', 'state']);
      const curr = omit(location, ['key', 'state']);
      if (isEqual(prev, curr)) {
        return;
      }
      const utmParams = getUtmParams(location.search);
      if (utmParams) {
        setUtmCookies(utmParams);
      }
      prevLocation = location;
      const utmCookies = getUtmCookies();
      const analytics = (window as any).analytics;
      analytics &&
        analytics.page({
          campaign:
            utmCookies && Object.keys(utmCookies).length
              ? utmCookies
              : undefined,
          referrer: lastLocation?.pathname || document.referrer
        });
    },
    // eslint-disable-next-line
    [location]
  );

  return (
    <>
      <Switch>
        <UnauthorizedRoute path={routes.LOGIN} exact component={LoginPage} />
        <UnauthorizedRoute path={routes.SIGN_UP} exact component={SignUpPage} />
        <UnauthorizedRoute
          path={routes.FORGOT_PASSWORD}
          exact
          component={ForgotPasswordPage}
        />

        <ServiceRoute
          path={routes.RESET_DRUMMER_PASSWORD}
          exact
          component={() => (<ResetPasswordPage isBusiness={false} />)}
        />

        <ServiceRoute
          path={routes.RESET_PASSWORD}
          exact
          component={() => (<ResetPasswordPage isBusiness={true} />)}
        />       

        <ProtectedRoute
          path={routes.BUSINESS_PROFILE}
          exact
          component={BusinessProfilePage}
        />
        <ProtectedRoute
          path={routes.SET_UP_CUSTOMER_TRACKING}
          exact
          component={SetupCustomerTracking}
        />
        <ProtectedRoute
          path={routes.SHOPIFY_INTEGRATION_PAGE}
          exact
          component={ShopifyIntegrationPage}
        />
        <ProtectedRoute
          path={routes.STANDARD_INTEGRATION_PAGE}
          exact
          component={WebIntegration}
        />
        <ProtectedRoute
          path={routes.CREATE_OPPORTUNITY_FORM}
          exact
          component={CreateOpportunityFormPage}
        />
        <ProtectedRoute
          path={routes.CREATE_OPPORTUNITY}
          exact
          component={CreateOpportunityPage}
        />
        <ProtectedRoute
          exact
          path={routes.EDIT_OPPORTUNITY}
          component={EditOpportunityPage}
        />
        <ProtectedRoute
          exact
          path={routes.DASHBOARD}
          component={DashboardPage}
        />
        <ProtectedRoute
          exact
          path={routes.BILLING}
          component={SelectBillingPage}
        />
        <ProtectedRoute
          exact
          path={routes.BILLING_HISTORY_DETAILED}
          component={BillingHistoryDetailedPage}
        />
        <ProtectedRoute
          path={routes.MY_OPPORTUNITIES}
          exact
          component={MyOpportunitiesPage}
        />
        <ProtectedRoute
          path={routes.SETUP_SHOPIFY_INTEGRATION}
          component={SetupShopifyIntegrationPage}
          exact
        />
        <ProtectedRoute
          path={routes.CUSTOMERS_MANAGEMENT}
          component={CustomersManagementPage}
          exact
        />
        <ProtectedRoute
          path={routes.CREATE_EMAIL_CAMPAIGN}
          component={CreateEmailCampaignPage}
          exact
        />
        <ProtectedRoute
          path={routes.REFERRAL_PAGE + '/:mode'}
          component={ReferralWebpage}
          exact
        />

        <ProtectedRoute component={DashboardPage} />
      </Switch>
    </>
  );
};

export default App;
