import React, { FC } from 'react';
import { Container, Text, Count } from './styles';
import { useGetDrummersCountQuery } from '../../graphql';

const DrummersCount: FC = () => {
  
  const {
    data: drummersCount,
  } = useGetDrummersCountQuery();

  return (
    <Container>
       <Text>
        <Count>{drummersCount?.getDrummersCount?.count || 0}</Count> 
        {' '}referrers ready to share your opportunity.
        </Text>
       
    </Container>
  );
};

export default DrummersCount;
