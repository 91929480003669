import React, { FC, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import OutsideClickHandler from 'react-outside-click-handler';
import InviteDrummerModal from 'components/InviteDrummerModal';
import DropDownArrow from 'assets/svg/DropDownArrow';
import { signOut } from 'services/auth';
import menu from './constants';
import { getImagePath } from 'helpers';
import { sendSignOutTrackingEvent } from 'helpers/segment';
import { useGetBusinessDetailsQuery, ImageStyle } from '../../graphql';
import {
  Button,
  Container,
  Link,
  OuterLink,
  MenuContainer,
  ArrowContainer,
  ProfileButton,
  StyledProfileIcon,
  ProfileInfo,
  ProfileInfoMain,
  AvatarContainer,
  Avatar,
  AvatarSubstitute,
  BusinessEmail,
  BusinessName
} from './styles';
import './styles.scss';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  className?: string;
  isProfileSetupCompleted: boolean;
  isDashboardPage: boolean;
}

const AccountMenu: FC<Props> = ({
  className,
  isDashboardPage,
  isProfileSetupCompleted
}) => {
  const client = useApolloClient();
  const { data } = useGetBusinessDetailsQuery({ fetchPolicy: 'cache-first' });
  const [isProfileMenuOpen, setProfileMenu] = useState<boolean>(false);
  const [isInviteModalOpen, setInviteModal] = useState<boolean>(false);

  const imgs = data?.getCurrentBusiness?.imagesS3;
  const imgPath =
    imgs && imgs[0]?.styles && getImagePath(imgs[0]?.styles as ImageStyle[]);

  const handleToggle = () => setProfileMenu(!isProfileMenuOpen);

  const handleLogout = async () => {
    client.stop();
    await client.clearStore();

    signOut();
    sendSignOutTrackingEvent(data?.getCurrentBusiness?.id);
  };

  return (
    <>
      <Container
        className={className}
        id={trackedElementsIds.accountMenuButton}
      >
        <OutsideClickHandler onOutsideClick={() => setProfileMenu(false)}>
          <ProfileButton onClick={handleToggle} type="button">
            <StyledProfileIcon isDashboardPage={isDashboardPage} />
          </ProfileButton>
          {isProfileMenuOpen && (
            <MenuContainer>
              <ArrowContainer>
                <DropDownArrow />
              </ArrowContainer>
              <ProfileInfo>
                <ProfileInfoMain>
                  <AvatarContainer>
                    {imgPath ? (
                      <Avatar src={imgPath} alt="business-logo" />
                    ) : (
                      <AvatarSubstitute>Logo</AvatarSubstitute>
                    )}
                  </AvatarContainer>
                  <BusinessName>
                    {data?.getCurrentBusiness?.primaryContact?.firstName}{' '}
                    {data?.getCurrentBusiness?.primaryContact?.lastName}
                  </BusinessName>
                </ProfileInfoMain>
                <BusinessEmail>{data?.getCurrentBusiness?.email}</BusinessEmail>
              </ProfileInfo>
              <ul>
                {menu.map(({ title, to, type, linkName }) => (
                  <li key={to}>
                    <span>
                      {type === 'outer' ? (
                        <OuterLink
                          href={to}
                          target="_blank"
                          id={trackedElementsIds.accountMenu.menuItemId(
                            linkName
                          )}
                        >
                          {title}
                        </OuterLink>
                      ) : (
                        <Link
                          to={to}
                          id={trackedElementsIds.accountMenu.menuItemId(
                            linkName
                          )}
                        >
                          {title}
                        </Link>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
              <Button onClick={() => setInviteModal(true)} type="button">
                <span>Invite your customers to promote your opportunities</span>
              </Button>
              <Button onClick={handleLogout} type="button">
                <span>Log out</span>
              </Button>
            </MenuContainer>
          )}
        </OutsideClickHandler>
      </Container>
      <InviteDrummerModal
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModal(false)}
      />
    </>
  );
};

export default AccountMenu;
