import styled from 'styled-components';
import { Body3 } from '../Typography';
import FieldError from '../FieldError';

export const Container = styled.div`
  display: flex;
  margin-top: 3px;
  &.show-always {
    min-height: 18px;
  }
`;

export const ErrorMessage = styled(FieldError)`
  margin-top: 0;
`;

export const CharactersLimit = styled(Body3)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;
