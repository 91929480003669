import { CommissionType, RewardActionType } from '../../../../graphql';

export const sectionHeadings = {
  INTRO_SECTION: {
    title:
      'What reward would you like to provide referrers for completing a successful action?',
    tooltip: `Referral rewards are a great way to encourage referrers to share your opportunity with their network. When choosing a referral reward amount we suggest you base it on what your current cost to acquire a customer is and apply something within that range. Consider these referrers your on-demand in the field sales people. 
      While we do require a $1 minimum, understand the more attractive your commission is the higher the likelyhood a referrer will drive you customers.
     `
  },
  REWARD_ACTION_TYPE: {
    title: 'What action would you like the customer to complete on your site?'
  },
  REWARD_TYPE: {
    title: 'Referral reward type',
    tooltip:
      'With rewards based on shopping cart purchases you can provide a simple flat dollar amount reward or a percentage of the shopping cart total as a reward. Dollar amount example: Earn $10 when a customer makes a purchase over $100. Percentage amount example: Earn 5% commission when a customer completes a purchase. In this 5% example, if the customer spent $300 at check out the referrer would earn a $15 reward.'
  },
  MINIMUM_PURCHASE_AMOUNT: {
    title:
      'Is a minimum shopping cart total required for a commission to be rewarded?',
    tooltip:
      'It doesn’t make sense to reward a referrer $50 for a $10 purchase. With Drum you can set a minimum shopping cart total purchase before a reward will be provided. Example: Earn $100 commission when a customer purchases over $1,000.'
  },
  BUDGET_LIMIT: {
    title: 'Would you like to set a reward budget limit for this opportunity?',
    tooltip:
      'You may set a budget limit on the total amount of rewards you will allocate for this opportunity. Once that budget limit is met the opportunity will expire and referrers will no longer be able to share the opportunity.  This option is only available for flat dollar amount commissions. Your opportunity will expire on or before you exceed your budget.'
  }
};

interface BooleanToggler {
  name: string;
  value: boolean;
}

export const MINIMUM_PURCHASE_AMOUNT_OPTIONS: [
  BooleanToggler,
  BooleanToggler
] = [
  { name: 'No', value: false },
  { name: 'Yes', value: true }
];

export const BUDGET_LIMIT_OPTIONS: [BooleanToggler, BooleanToggler] = [
  { name: 'No', value: false },
  { name: 'Yes', value: true }
];

const REWARD_ACTIONS = {
  PURCHASE: {
    label: 'Complete a purchase',
    value: RewardActionType.CompleteAPurchase
  },
  NAVIGATE_TO_PAGE: {
    label: 'Navigate to a specific page',
    value: RewardActionType.NavigateToSpecificPage
  }
};

export const REFERRAL_REWARD_ACTIONS = [
  REWARD_ACTIONS.PURCHASE,
  REWARD_ACTIONS.NAVIGATE_TO_PAGE
];

export const FIXED_COMMISSION_OPTION = {
  label: 'Flat dollar amount',
  value: CommissionType.Fixed
};

export const PERCENTAGE_COMMISSION_OPTION = {
  label: 'Percent',
  value: CommissionType.Percentage
};
