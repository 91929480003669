import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { InputBase, FieldFooter, TextareaBase } from 'uikit';
import { getEmailTypeLabel } from '../../../../helpers';

import {
  EMAIL_SUBJECT_MAX_LENGTH,
  EMAIL_BODY_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import { EmailCampaignFormType } from '../../../../validationSchema';
import { InputGroup, StyledLabel } from '../../styles';
import { SubInputText, StyledLink } from './styles';
import { emailType } from '../../../../types';
import { tooltips } from '../../constants';

const helpTexts = {
  SUBJECT_LINE: {
    label: 'Subject Line:',
    tooltip: tooltips.SUBJECT
  },
  BODY_COPY: {
    label: 'Body Copy:',
    tooltip: tooltips.EMAIL_BODY
  }
};

interface Props {
  emailType: emailType;
  onTipClick: () => void;
}

const EmailSubjectDetails: FC<Props> = ({ emailType, onTipClick }) => {
  const { handleChange, values, errors } = useFormikContext<
    EmailCampaignFormType
  >();

  return (
    <div>
      <InputGroup>
        <StyledLabel
          title={`${getEmailTypeLabel(emailType, true)} ${
            helpTexts.SUBJECT_LINE.label
          }`}
          tooltip={helpTexts.SUBJECT_LINE.tooltip}
        />
        <InputBase
          name={'emails.' + emailType + '.subjectLine'}
          onChange={handleChange}
          value={values.emails?.[emailType].subjectLine}
          maxLength={EMAIL_SUBJECT_MAX_LENGTH}
        />
        <FieldFooter
          error={errors.emails?.[emailType]?.subjectLine}
          limit={EMAIL_SUBJECT_MAX_LENGTH}
          fieldValueLength={values.emails?.[emailType].subjectLine.length}
        />
        <SubInputText>
          Want to personalize your email or subject line with their name?
          <StyledLink onClick={onTipClick}>Click here.</StyledLink>
        </SubInputText>
      </InputGroup>
      <InputGroup>
        <StyledLabel
          title={`${getEmailTypeLabel(emailType, true)} ${
            helpTexts.BODY_COPY.label
          }`}
          tooltip={helpTexts.BODY_COPY.tooltip}
        />
        <TextareaBase
          name={'emails.' + emailType + '.emailBody'}
          rows={5}
          onChange={handleChange}
          value={values.emails?.[emailType].emailBody}
          maxLength={EMAIL_BODY_MAX_LENGTH}
        />
        <FieldFooter
          error={errors.emails?.[emailType]?.emailBody}
          limit={EMAIL_BODY_MAX_LENGTH}
          fieldValueLength={values.emails?.[emailType].emailBody.length}
        />
        <SubInputText>
          Want to personalize your email body with their name?
          <StyledLink onClick={onTipClick}>Click here.</StyledLink>
        </SubInputText>
      </InputGroup>
    </div>
  );
};

export default EmailSubjectDetails;
