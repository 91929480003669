import styled from 'styled-components';
import { H3 } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`

`;
export const Title = styled(H3)`
  margin-bottom: 20px;
  &:nth-of-type(n+2) {
    margin-top: 20px;
  }

  ${breakpoint('xs', 'md')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
export const SelectContainer = styled(H3)`
  width: 100%;

  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
`;
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 2px;
    width: 105px;
    margin-top: 15px;

    &:last-of-type {
      margin-right: 0;
      width: 147px;
    }
  }
  
`;
export const ImageContainer = styled.div`
  margin-top: 15px;
`;
export const BgContainer = styled.div`
  margin-top: 15px;
  min-height: 160px;
`;