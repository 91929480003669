import styled from 'styled-components';
import { ITheme } from 'types';
import {
  Body2,
  ButtonTextMixin,
  LinkTextMixin,
} from '../Typography';
import FieldFooter from '../FieldFooter';

interface DropzoneContainerProps extends ITheme {
  hasPreview: boolean;
}

export const Container = styled.div`
  display: inline-block;
`;

export const DropzoneContainer = styled.div<DropzoneContainerProps>`
  display: ${({ hasPreview }) => (hasPreview ? 'none' : 'block')};
  width: 115px;
  height: 115px;
  border: dashed 2px ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 6px;
`;

export const Preview = styled.img`
  max-width: auto;
  height: 115px;
  border-radius: 6px;
`;

export const Caption = styled(Body2)`
  margin: 40px 0 16px;
  text-align: center;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.subtitleColor};
`;

export const UploadButton = styled.button`
  padding: 0 15px;
  border: solid 2px ${({ theme }) => theme.colors.accentMainColor};
  ${ButtonTextMixin};
  color: ${({ theme }) => theme.colors.accentMainColor};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
`;

export const ReplaceButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: none;
  ${LinkTextMixin};
  background-color: transparent;
`;

export const StyledFieldFooter = styled(FieldFooter)`
  min-height: 0;
`;