import styled from 'styled-components';
import AuthMethods from 'components/AuthMethods';
import ShopifyAuthInfo from 'components/ShopifyAuthInfo';
import { InputTextMixin, LinkTextMixin } from 'uikit';

export const StyledAuthMethods = styled(AuthMethods)`
  margin-bottom: 30px;
`;

export const Disclaimer = styled.p`
  ${InputTextMixin};
`;

export const StyledLink = styled.a`
  margin-left: 4px;
  ${LinkTextMixin};
  cursor: pointer;
`;

export const StyledShopifyAuthInfo = styled(ShopifyAuthInfo)`
  margin-top: 25px;
`;
