import React from 'react';
import {IndicatorComponentType } from 'react-select';
import DownloadIcon from 'assets/svg/Download';
import { Container } from './styles';

export interface IOption {
  label: string;
  value: string;
}

const MenuList: IndicatorComponentType<IOption> = ({
  children,
  ...props
}) => (
  <Container>
    <DownloadIcon />Import Customers
  </Container>
);

export default MenuList;
