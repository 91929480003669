import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid, Row, Text, Form } from '../../../../uikit';
import { ITheme } from '../../../../types';
import { breakpoint } from '../../../../helpers';
import FormFooter from '../../../FormFooter';

// TODO: fix tooltips and remove overflow
export const Container = styled.div`
  overflow: hidden;
`;

export const StandardFormContainer = styled.div`
  min-height: 35px;
`;

export const Help = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.secondary};
`;

export const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.main};
`;

export const Em = styled.em`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-style: normal;
`;

// * Form styles

export const FormTitle = styled.div<ITheme>`
  margin-left: -5px;
  display: flex;
  align-items: center;
  p {
    font-size: ${({ theme }: ITheme) => theme.size.body1};
    font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  }
`;

export const SectionTitle = styled.div<ITheme>`
  margin-top: 35px;
  font-size: ${({ theme }: ITheme) => theme.size.subtitleLarge};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};

  span {
    font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
  }
`;

export const ContactRow = styled(Row)`
  ${breakpoint('xs', 'sm')`
    flex-direction: column;
`};
`;

export const SubheaderText = styled(Text)<ITheme>`
  margin-top: 20px;
  line-height: 21px;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.medium};
`;


export const FormIntegration = styled(Form)`
  box-sizing: border-box;
`;
export const FormInner = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 26px 57px 31px;
  align-items: flex-start;
  margin-top: 14px;
  margin-bottom: 24px;
`;

export const Field = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonContainer = styled.div<ITheme>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3px;

  ${breakpoint('xs', 'md')`
    flex-direction: column;
    align-items: center;
  `};
  
`;

export const VerificationBox = styled.div<ITheme>`
  display: flex;
  align-items: center;
  margin-right: 30px;

  & > span {
    margin-left: 9px;
    font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  }

  ${breakpoint('xs', 'md')`
    align-self: flex-start;
    margin-bottom: 20px;
  `};
`;

export const CheckmarkBox = styled(VerificationBox)<ITheme>`
  & > span {
    color: ${({ theme }) => theme.colors.success};
  }
`;

export const ErrorBox = styled(VerificationBox)<ITheme>`
  & > span {
    color: ${({ theme }) => theme.colors.danger};
  }
  img {
    height: 24px; 
  }
`;

export const StyledFormFooter = styled(FormFooter)`
  align-self: stretch;
  margin-bottom: 13px;
  & > div {
    border-radius: 5px;
    box-shadow: none;
    height: 44px;
    box-sizing: border-box;

    &,
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.colors.borderColor};
    }
  }
  
  input {
    &::placeholder {
      font-style: italic;
    }
  }
`;

