import React, { FC, ReactNode } from 'react';
import {
  Container, 
  InfoContainer,
  InfoLeft,
  IconContainer,
  InfoText,
  InfoRight,
} from './styles';

type Props = {
  text: ReactNode | string,
  icon?: ReactNode,
  rightBlockContent?: ReactNode
};

const PageHeader: FC<Props> = ({ text, icon, rightBlockContent }) => {

  return (
    <Container>
      <InfoContainer>
        <InfoLeft>
          {!!icon && 
          <IconContainer>
            {icon}
          </IconContainer>
          }
          <InfoText className={rightBlockContent ? 'restricted' : ''}>
            {text}
          </InfoText>
        </InfoLeft>
        {!!rightBlockContent && 
          <InfoRight>
            {rightBlockContent}
          </InfoRight>
        }
      </InfoContainer>
    </Container>
  );

}

export default PageHeader;
