import React, { FC } from 'react';
import { Container, Image, RatingPosition } from './styles';

interface Props {
  className?: string;
  src?: string;
  ratingPosition?: number;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
}

const Avatar: FC<Props> = ({ className, ratingPosition, src, firstName, lastName, userName }) => (
  <Container className={className}>
    {src ? (
      <Image>
        <img src={src} alt="avatar" />
      </Image>
    ) : 
      (firstName && lastName ? 
        <Image>
          {(firstName || '').substr(0, 1)}{(lastName || '').substr(0, 1)}
        </Image>
        :
        <Image>
          {(userName || '').substr(0, 1)}
        </Image>
      )
    }
    {ratingPosition && <RatingPosition>{ratingPosition}</RatingPosition>}
  </Container>
);

export default Avatar;
