import styled from 'styled-components';
import { Share } from '../../assets/svg';

export const OpportunityLink = styled.a`
  position: relative;
  display: block;
  padding: 13px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.defaultRadius};
  background-color: ${({ theme }) => theme.colors.main};
`;

export const LinkIcon = styled(Share)`
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
`;
