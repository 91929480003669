import React, { FC } from 'react';
import { Container, ErrorMessage, CharactersLimit } from './styles';

interface Props {
  className?: string;
  error?: string | null;
  fieldValueLength?: number;
  limit?: number;
  showAlways?: boolean;
}

const FieldFooter: FC<Props> = ({ className, error, fieldValueLength, limit, showAlways = true }) => {
  const remainingCharacters =
    typeof limit === 'number' && typeof fieldValueLength === 'number'
      ? limit - fieldValueLength
      : null;

  const getRemainingCharactersMessage = () => {
    if (typeof remainingCharacters !== 'number') {
      return null;
    }

    return `${remainingCharacters} ${
      remainingCharacters === 1 ? 'Character' : 'Characters'
    } left`;
  };
  const remainingCharactersMessage = getRemainingCharactersMessage();

  return (
    <Container className={className + (showAlways ? ' show-always': '')}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {remainingCharactersMessage && (
        <CharactersLimit as="span">
          {remainingCharactersMessage}
        </CharactersLimit>
      )}
    </Container>
  );
};

export default FieldFooter;
