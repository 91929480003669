import styled from 'styled-components';
import { Label, H2, H3, Toggle } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  position: relative;
  
`;
export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:64px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  box-shadow: 0px -4px 5px -5px rgba(0,0,0,0.1), 0 6px 5px -5px rgba(0,0,0,0.1);
  z-index: 1;
  position: relative;

  ${breakpoint('xs', 'lg')`
    display: none;
  `}

`;
export const StyledToggle = styled(Toggle)`

`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: none;
  display: flex;
  

  ${breakpoint('xs', 'lg')`
    flex-direction: column
  `}
`;

export const LeftPanel = styled.div`
  padding: 0 25px 40px 40px;
  flex: 1;
  ${breakpoint('xs', 'lg')`
    padding: 0 15px 20px 15px;
  `}
`;

export const RightPanel = styled.div`
  width: 41.5%;
  max-width: 600px;
  box-sizing: border-box;
  & > div {
    width: 100%;
    position: sticky;
    top: 0;
  }
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
  &.responsive {
    width: 375px;
  }
`;

export const FormContainer = styled.div`
  
`;

export const Title = styled(H2)`
  margin: 0 0 25px 0; 
  padding-top: 35px;
`;
export const CommonSettings = styled.div`
  margin-top: 25px;
`;
export const InputGroup = styled.div`
  margin-bottom: 30px;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 15px;

  & > span {
    ${breakpoint('xs', 'md')`
      font-size: ${({ theme }) => theme.size.body2};
    `}
  }
`;

export const Paragraph = styled.p`
  
`;
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 13px;
    min-width: 185px;
    margin-top: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  
`;

export const ColorBlock = styled.div`
  display: inline-flex;
  width: auto;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.colorLabelColor};
  box-sizing: border-box;
  border-radius: 22.5px;
  min-width: 185px;
  padding: 7px 18px 7px 8px;
  margin-right: 20px;
  cursor: pointer;

  ${breakpoint('xs', 'md')`
    margin-bottom: 8px;
    width: 100%;
  `}
`;
export const Color = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.colorLabelInner};
  width: 31px;
  height: 31px;
  box-sizing: border-box;
  border-radius: 50%;
`;
export const Corners = styled.div`
  position:relative;
  width: 28px;
  height: 28px;
  border: 2px solid #A0A0A0;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 8px;
  
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: 12px;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
  }
  &:after {
    content: '';
    position: absolute;
    height: calc(100% + 4px);
    width: 12px;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }
`;
export const ColorLabel = styled.span`
  margin-left: 13px;
  display: inline-block;
`;
export const FlexRow = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const PickerContainer = styled.div`
  position: relative;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const PickerTitle = styled(H3)`
  position: relative;
  top: -40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const PreviewButton = styled.button`
  position: fixed;
  bottom: 80px;
  right: 11px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  ${breakpoint('lg')`
    display: none;
  `}
`;
export const EmailInviteContainer = styled.div`
  margin-top: -40px;
`;
export const EmailInviteModalText = styled.p`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 20px;
`;


