import React, { FC } from 'react';
import { Container, Title, Value } from './styles';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  isSelected?: boolean;
  onClick?: () => void;
  title: string;
  value: number | string;
  idName: string;
}

const OpportunityPerformanceMetric: FC<Props> = ({
  isSelected,
  onClick,
  title,
  value,
  idName
}) => (
  <Container
    isSelected={isSelected}
    onClick={onClick}
    id={trackedElementsIds.performanceMenu.metricId(idName)}
  >
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Container>
);

export default OpportunityPerformanceMetric;
