import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OpportunityMenuContainer from 'components/OpportunityMenuContainer';
import OpportunityMenu from 'components/OpportunityMenu';
import {
  redirectToOpportunityDashboard,
  redirectToEditOpportunity
} from 'helpers';
import { SortKey, SortOrder } from '../../constants';
import { TextInfo, Body1 } from 'uikit';
import TableHeader from '../TableHeader';
import formatOpportunityList, { FormattedOpportunityList } from './utils';
import { sortByCommission, sortByConversion, sortByDate } from './sortHelpers';
import { ListOpportunitiesQuery, OfferStatus } from '../../../../graphql';
import {
  DesktopTable,
  MobileTable,
  MobileOpportunityItem,
  EmptyListContainer,
  Description,
  AdditionInformation,
  THead,
  TBody,
  CardTd,
  StyledOpportunityCard,
  StyledOpportunityStatus,
  TD,
  DateTd,
  Tr,
  GreenTd
} from './styles';

interface Props {
  data: ListOpportunitiesQuery | undefined;
  onOfferStatusChange?: () => void;
}

const OpportunitiesListTable: FC<Props> = ({ data, onOfferStatusChange }) => {
  const history = useHistory();

  const [sortOrder, setSortOrder] = useState(SortOrder.DESC);
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.CREATED_AT);

  const sortOpportunityList = (
    data: FormattedOpportunityList,
    key: SortKey | null
  ) => {
    if (!data) {
      return [];
    }

    switch (key) {
      case SortKey.CONVERSION:
        return sortByConversion(data, sortOrder);
      case SortKey.COMMISSION:
        return sortByCommission(data, sortOrder);
      case SortKey.CREATED_AT:
        return sortByDate(data, SortKey.CREATED_AT_UNFORMATTED, sortOrder);
      case SortKey.PUBLISHED_AT:
        return sortByDate(data, SortKey.PUBLISHED_AT_UNFORMATTED, sortOrder);
      default:
        return data;
    }
  };

  const handleSelectSorting = (newSortKey: SortKey) => {
    const isSortKeyChanged = newSortKey === sortKey;

    if (isSortKeyChanged) {
      const newSortOrder =
        sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
      setSortOrder(newSortOrder);
    } else {
      setSortOrder(SortOrder.DESC);
      setSortKey(newSortKey);
    }
  };

  const formattedOpportunityList = formatOpportunityList(data);
  const sortedOpportunityList = sortOpportunityList(
    formattedOpportunityList,
    sortKey
  );

  const handleClickOpportunityCard = (
    id: string,
    status: OfferStatus | null | undefined
  ) => {
    if (status === OfferStatus.Active || status === OfferStatus.Paused) {
      redirectToOpportunityDashboard(id, history);
    } else if (status === OfferStatus.Draft || status === OfferStatus.InQueue || status === OfferStatus.Scheduled) {
      redirectToEditOpportunity(id, history);
    }
  };
  
  const handleOfferStatusChange = () => {
    onOfferStatusChange && onOfferStatusChange();
  };

  return sortedOpportunityList.length ? (
    <>
      <DesktopTable>
        <THead>
          <tr>
            <TableHeader title="Opportunities" />
            <TableHeader
              sortOrder={sortKey === SortKey.CREATED_AT && sortOrder}
              onClick={() => handleSelectSorting(SortKey.CREATED_AT)}
              title="Created"
            />
            <TableHeader
              sortOrder={sortKey === SortKey.PUBLISHED_AT && sortOrder}
              onClick={() => handleSelectSorting(SortKey.PUBLISHED_AT)}
              title="Published"
            />
            <TableHeader title="Start/End" />
            <TableHeader
              sortOrder={sortKey === SortKey.COMMISSION && sortOrder}
              onClick={() => handleSelectSorting(SortKey.COMMISSION)}
              title="Reward"
            />
            <TableHeader
              sortOrder={sortKey === SortKey.CONVERSION && sortOrder}
              onClick={() => handleSelectSorting(SortKey.CONVERSION)}
              title="Conversions"
            />
            <TableHeader title="Status" />
          </tr>
        </THead>
        <TBody>
          {sortedOpportunityList.map(
            ({
              businessName,
              businessPhoto,
              createdAt,
              publishedAt,
              id,
              title,
              opportunityDuration,
              formattedCommission,
              image,
              commission,
              conversion,
              budget,
              endedAt,
              redemptionCount,
              status
            }) => (
              <Tr key={id}>
                <CardTd
                  isClickable={
                    status === OfferStatus.Active ||
                    status === OfferStatus.Paused ||
                    status === OfferStatus.Draft || 
                    status === OfferStatus.Scheduled || 
                    status === OfferStatus.InQueue
                  }
                  onClick={() => handleClickOpportunityCard(id, status)}
                >
                  <StyledOpportunityCard
                    businessName={businessName}
                    businessPhoto={businessPhoto}
                    opportunityTitle={title}
                    coverImage={image}
                    earning={formattedCommission}
                    canShare={false}
                  />
                </CardTd>
                <DateTd>{createdAt}</DateTd>
                <DateTd>{publishedAt}</DateTd>
                <DateTd>{opportunityDuration}</DateTd>
                <GreenTd>{formattedCommission}</GreenTd>
                <GreenTd>{conversion}</GreenTd>
                <TD>
                  <StyledOpportunityStatus status={status} />
                </TD>
                <TD>
                  {status && (
                    <OpportunityMenuContainer
                      canViewPerformance
                      component={OpportunityMenu}
                      id={id}
                      status={status}
                      endedAt={endedAt}
                      budget={budget}
                      commission={commission}
                      redemptionCount={redemptionCount}
                      onOfferStatusChange={handleOfferStatusChange}
                    />
                  )}
                </TD>
              </Tr>
            )
          )}
        </TBody>
      </DesktopTable>

      <MobileTable>
        {sortedOpportunityList.map(
          ({
            id,
            createdAt,
            publishedAt,
            title,
            opportunityDuration,
            commission,
            conversion,
            budget,
            endedAt,
            formattedCommission,
            redemptionCount,
            status
          }) => (
            <MobileOpportunityItem key={id}>
              <div>
                <Description>{title}</Description>
                <TextInfo title="Created:" value={createdAt} />
                <TextInfo title="Published:" value={publishedAt} />
                <TextInfo title="Start/End:" value={opportunityDuration} />
                <TextInfo
                  title="Commission:"
                  value={formattedCommission}
                  greened
                />
                <TextInfo
                  title="Conversions:"
                  value={conversion}
                  greened={conversion > 0}
                />
              </div>
              <AdditionInformation>
                <StyledOpportunityStatus status={status} />
                {!!status && (
                  <OpportunityMenuContainer
                    canViewPerformance
                    component={OpportunityMenu}
                    id={id}
                    status={status}
                    endedAt={endedAt}
                    budget={budget}
                    commission={commission}
                    redemptionCount={redemptionCount}
                    onOfferStatusChange={handleOfferStatusChange}
                  />
                )}
              </AdditionInformation>
            </MobileOpportunityItem>
          )
        )}
      </MobileTable>
    </>
  ) : (
    <EmptyListContainer>
      <Body1>No opportunities yet.</Body1>
    </EmptyListContainer>
  );
};

export default OpportunitiesListTable;
