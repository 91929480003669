import styled from 'styled-components';
import { Body2 } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 25px;
  margin-top: -40px;
`;
export const SubTitle = styled(Body2)`
  margin-top: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
export const Paragraph = styled(Body2)`

`;
export const Result = styled(Body2)`
  margin-top: 15px;
`;
export const PersonalizationOption = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;