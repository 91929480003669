export const ALLOWED_IMAGE_MIME_TYPES = {
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  PNG: 'image/png'
};

export const ALLOWED_IMAGE_EXTENSIONS = {
  [ALLOWED_IMAGE_MIME_TYPES.JPEG]: 'jpg',
  [ALLOWED_IMAGE_MIME_TYPES.GIF]: 'gif',
  [ALLOWED_IMAGE_MIME_TYPES.PNG]: 'png'
};
