import { emailRegExpSub } from 'constants/regExp';
import { Business, ImageStyleName, ImageStyle, Offer } from '../graphql';
import getImagePath from 'helpers/getImagePath';

export const SHOPIFY_REGISTER_FIELD = "Drum:BusinessApp:ShopifyRegister";

export const sendLoginTrackingEvent = (businessId: string | null, data: any, result: boolean, message?: string) => {
  const analytics = (window as any).analytics;
  const errorMail = message?.match(emailRegExpSub);
  const isShopifyRegister = getShopifyTrackingEventFlag();
  analytics && analytics.track((result ? 'Log In Success' : 'Log In Failure'), {
    businessId: businessId,
    email: data?.attributes?.email || data?.signInUserSession?.idToken?.payload?.email || errorMail?.[0],
    method: isShopifyRegister ? "Shopify" : data?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName || "Email",
    applicationSource: 'Business',
    result: result,
    failurereason: result ? undefined : `${data?.message || ''} ${message}`
  });
}

export const sendSignUpTrackingEvent = (data: any) => {
  const analytics = (window as any).analytics;
  const isShopifyRegister = getShopifyTrackingEventFlag();
  const businessId = data?.user?.storage?.ajs_user_id;
  analytics && analytics.track("Completed Sign Up", {
    businessId: businessId?.replace('"', '') || null,
    email: data?.signInUserSession?.idToken?.payload?.email || data?.user?.username || null,
    method: isShopifyRegister ? "Shopify" : data?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName || "Email",
    applicationSource: 'Business',
  });
}

export const sendSignOutTrackingEvent = (businessId: string = '') => {
  const analytics = (window as any).analytics;
  analytics && analytics.track("Sign Out", {
    businessId: businessId?.replace('"', '') || null,
    applicationSource: 'Business',
  });
}

export const sendCompletedOnboardingTrackingEvent = (business?: Business) => {
  if(!business?.primaryContact) return;
  const analytics = (window as any).analytics;
  const { firstName, lastName, phoneNumber } = business.primaryContact;
  const { businessName, email } = business;
  analytics && analytics.track("Completed Onboarding", {
    firstName,
    lastName,
    phoneNumber,
    businessName,
    email,
    categoryId: business.category?.id,
    categoryName: business.category?.name,
    coverPhoto: getImagePath(business.imagesS3?.[0]?.styles as ImageStyle[], ImageStyleName.Original),
    paymentMethod: business.payments?.[0]?.provider,
    integrationSelected: business.integrations?.platformName,
    applicationSource: 'Business',
  });
}

export const sendOpportunityPublishedTrackingEvent = (offer?: Offer) => {
  if(!offer) return;
  const analytics = (window as any).analytics;

  const referrerLinks = (offer.drummerEducationLinks || []).map(item => ({
    title: item.title,
    url: item.url
  }))

  analytics && analytics.track("Published Opportunity", {
    opportunityID: offer.id,
    opportunityTitle: offer.title,
    destinationURL: offer.urlDestination,
    descriptionCharacterCount: (offer.description || '').length,
    rewardTriggeringEvent: offer.rewardActionType,
    rewardType: offer.drumRedemption?.commissionType,
    rewardAmount: offer.drumRedemption?.payDrum,
    shoppingCartMinimum: offer.drumRedemption?.buyerSpendPurchaseAmount,
    referrerEducationCharacterCount: (offer.drummerNote?.description || '').length, 
    referrerLinks,
    startDate: offer.startedAt,
    endDate: offer.endedAt,
    applicationSource: 'Business',
  });
}

export const sendResetPasswordTrackingEvent = (email: string, success: boolean) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track(`Reset Password: ${success ? 'Success' : 'Failed'}`, {
    email: email || null,
    success: success,
    applicationSource: 'Business',
  });
}

export const sendProfileCategorySearchTrackingEvent = (searchTerm: string, searchResult: string) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Searched For Category', {
    searchTerm,
    searchResult,
    applicationSource: 'Business',
  });
}

export const sendDestinationUrlFillTrackingEvent = (url: string) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Filled out URL', {
    url,
    applicationSource: 'Business',
  });
}

export const sendOpportunityListLoadedTrackingEvent = (opportunityList: string[], searchTerm: string) => {
  const resultCount = opportunityList.length || 0;
  const analytics = (window as any).analytics;
  analytics && analytics.track('Viewed opportunities', {
    opportunityList,
    searchTerm,
    resultCount,
    applicationSource: 'Business',
  });
}

export const sendDeleteDraftTrackingEvent = (offer: Offer) => {
  if(!offer) return;
  const referrerLinks = (offer.drummerEducationLinks || []).map(item => ({
    title: item.title,
    url: item.url
  }))

  const analytics = (window as any).analytics;
  analytics && analytics.track('Delete Draft', {
    opportunityID: offer.id,
    opportunityTitle: offer.title,
    destinationURL: offer.urlDestination,
    descriptionCharacterCount: (offer.description || '').length,
    rewardTriggeringEvent: offer.rewardActionType,
    rewardType: offer.drumRedemption?.commissionType,
    rewardAmount: offer.drumRedemption?.payDrum,
    shoppingCartMinimum: offer.drumRedemption?.buyerSpendPurchaseAmount,
    referrerEducationCharacterCount: (offer.drummerNote?.description || '').length, 
    referrerLinks,
    startDate: offer.startedAt,
    endDate: offer.endedAt,
    applicationSource: 'Business',
  });
}

export const sendDeleteOpportunityTrackingEvent = (offer: Offer) => {
  if(!offer) return;

  const analytics = (window as any).analytics;
  analytics && analytics.track('Deleted Opportunity', {
    opportunityID: offer.id,
    opportunityTitle: offer.title,
    applicationSource: 'Business',
  });
}

export const sendDeleteOfferCreationProgressTrackingEvent = (offer: Offer) => {
  if(!offer) return;
  const referrerLinks = (offer.drummerEducationLinks || []).map(item => ({
    title: item.title,
    url: item.url
  }))

  const analytics = (window as any).analytics;
  analytics && analytics.track('Delete Create Opportunity Progress', {
    opportunityID: offer.id,
    opportunityTitle: offer.title,
    destinationURL: offer.urlDestination,
    descriptionCharacterCount: (offer.description || '').length,
    rewardTriggeringEvent: offer.rewardActionType,
    rewardType: offer.drumRedemption?.commissionType,
    rewardAmount: offer.drumRedemption?.payDrum,
    shoppingCartMinimum: offer.drumRedemption?.buyerSpendPurchaseAmount,
    referrerEducationCharacterCount: (offer.drummerNote?.description || '').length, 
    referrerLinks,
    startDate: offer.startedAt,
    endDate: offer.endedAt,
    applicationSource: 'Business',
  });
}

export const sendCompleteProfileTrackingEvent = (business: Business, edit: boolean = false) => {
  if(!business?.primaryContact) return;

  const analytics = (window as any).analytics;
  const { firstName, lastName, phoneNumber } = business.primaryContact;
  const { businessName } = business;
  analytics && analytics.track(edit ? 'Edited Profile' : 'Business Profile Completed', {
    firstName,
    lastName,
    phoneNumber,
    businessName,
    categoryId: business.category?.id,
    categoryName: business.category?.name,
    profilePhoto: getImagePath(business.imagesS3?.[0]?.styles as ImageStyle[], ImageStyleName.Original),
    applicationSource: 'Business',
  });
}

//Customers events

export const sendOpenedCustomersMenuTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Opened Customers Menu', {
    applicationSource: 'Business',
  });
}

export const sendImportCustomersClickTrackingEvent = (importMethod: string) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Clicked on Import Customers', {
    importMethod,
    applicationSource: 'Business',
  });
}
export const sendImportedCustomersTrackingEvent = (importMethod: 'CSV' | 'Manual', success: boolean = true) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track(`Imported Customers: ${success ? 'Success' : 'Failed'}`, {
    importMethod,
    success,
    applicationSource: 'Business',
  });
}
export const sendEmailTabClickTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Clicked on Email Tab', {
    applicationSource: 'Business',
  });
}
export const sendCreateEmailCampaignClickTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Clicked on Create email campaign', {
    applicationSource: 'Business',
  });
}
export const sendSelectedEmailTemplateTrackingEvent = (templateNumber: number) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Selected a Template', {
    templateNumber,
    applicationSource: 'Business',
  });
}

export const sendStartEmailCampaignTrackingEvent = (recipients: number = 0, success: boolean = true) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Start Campaign', {
    recipients,
    success,
    applicationSource: 'Business',
  });
}

export const sendPauseEmailCampaignTrackingEvent = (campaignId: string, type: 'All customers' | 'Tag') => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Pause campaign', {
    campaignId,
    type,
    applicationSource: 'Business',
  });
}

//Referral webpage events

export const sendReferralWebpageTabClickTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Clicked on Referral Webpage', {
    applicationSource: 'Business',
  });
}

export const sendVisitReferralPageClickTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Visit page button clicked', {
    applicationSource: 'Business',
  });
}

export const sendEditReferralPageClickTrackingEvent = () => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Edit page button clicked ', {
    applicationSource: 'Business',
  });
}

export const sendReferralPageCustomizeAndPublishClickTrackingEvent = (type: 'Shopify' | 'Standard' = 'Standard') => {
  const analytics = (window as any).analytics;
  analytics && analytics.track('Customize and publish', {
    type,
    applicationSource: 'Business',
  });
}
export const sendReferralPageValidateIframeClickTrackingEvent = (url: string, success: boolean = true) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track(`Validate iframe installation: ${success ? 'Success' : 'Failed'}`, {
    url,
    success,
    applicationSource: 'Business',
  });
}

//Misc events

export const sendDpixOfferCompleted = () => {
  const dpix = (window as any)?.dpix;
  dpix && dpix('event', 'offer-completed', {});
}

export const setShopifyTrackingEventFlag = () => {
  localStorage.setItem(SHOPIFY_REGISTER_FIELD, 'true');
}
export const getShopifyTrackingEventFlag = () => {
  return localStorage.getItem(SHOPIFY_REGISTER_FIELD);
}
export const removeShopifyTrackingEventFlag = () => {
  localStorage.removeItem(SHOPIFY_REGISTER_FIELD);
}