import styled from 'styled-components';
import { MenuContainer as OriginMenuContainer } from '../../styles';

export const Container = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #CCCCCC;

  &:first-of-type {
    border-top: 1px solid #CCCCCC;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  cursor: pointer;
  padding-left: 20px;
`;

export const TypeBlock = styled.div`
  flex: 0.56;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const SubjectLineBlock = styled.div`
  display: flex;  
  align-items: center;
  flex: 0.31;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};

  &.active {
    color: ${({ theme }) => theme.colors.green}
  }
  &.pending {
    color: #f99700;
  }
  &.inactive {
    color: #ababab;
  }
`;
export const IconBlock = styled.div`
  flex: 0.13;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;
export const Row = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
`;
export const Block = styled.div`
  flex: 0.25;

  &:nth-of-type(1) {
    flex: 0.28;
  }
  &:nth-of-type(2) {
    flex: 0.25;
  }
  &:nth-of-type(3) {
    flex: 0.17;
  }
  &:nth-of-type(4) {
    flex: 0.17;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  &.options {
    flex: 0.13;
    position: relative;
    padding-top: 6px;
    justify-content: flex-end;
    display: flex;
  }
`;
export const BlockTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  height: 22px;
  line-height: 22px;
  margin-bottom: 6px;
`;
export const BlockValue = styled.div`
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 14px;

  &.sentType {
    display: flex;
    align-items: center;
  }
`;
export const MenuContainer = styled(OriginMenuContainer)`
  z-index: 100001;
`;





