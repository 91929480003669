import styled from 'styled-components';
import { ITheme } from '../../types';

export const Container = styled.div`
  width: 100%;

  .swiper-container {
    border: none;
    height: auto;
  }
  .swiper-slide {
    height: auto;
    padding: 0 5px;
    box-sizing: border-box;
  }

  
  .swiper-container {
    box-sizing: border-box;
    position: relative;
    border: none;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .swiper-button-nav {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #000;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      cursor: pointer;

      &:after {
        //display: none;
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        
        position: relative;
      }
      svg {
        width: 10px !important;
        height: 30px !important;
      }

      &.swiper-button-disabled {
        opacity: 1;
        border: 1px solid rgba(0, 0, 0, 0.35);
        cursor: default;
        & > *,
        &:after {
          opacity: 0.35;
        }
      }
    }
    .swiper-button-prev {
      left:0;

      &:after {
        left: 2px;
        border-left: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
        border-bottom: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
        transform: rotate(45deg);
      }
    }
    .swiper-button-next {
      right: 0;

      &:after {
        left: -2px;
        border-right: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
        border-top: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
        transform: rotate(45deg);
      }
    }
    
  }

`;

