import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import routes from 'constants/routes';
import { AuthContext } from 'providers/AuthProvider';

const ProtectedRoute: FC<RouteProps> = (props) => {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: routes.LOGIN, state: { from: location } }} />
  );
};

export default ProtectedRoute;
