import React, {FC} from 'react'
import {
  Container,
  Contacts,
  Email,
  EmailLink,
} from './styled'
import { CONTACT_SUPPORT_LINK_ALT } from 'constants/outerLinks';

const GetCurrentPaymentsFailed:FC = () => {
  return (
    <Container>
      <p>If you continue to have problems, please contact support</p>
      <Contacts>
        <Email>
          <EmailLink href={CONTACT_SUPPORT_LINK_ALT} target="_blank">
            help@drum.io
          </EmailLink>
        </Email>
      </Contacts>
    </Container>
  )
}

export default GetCurrentPaymentsFailed;





