import { Step } from 'components/CreationProgress/CreationProgress';
import { theme } from 'styles/theme';

export const DESKTOP_VIEW = 'desktopView';
export const MOBILE_VIEW = 'mobileView';

export type ViewModeType = typeof DESKTOP_VIEW | typeof MOBILE_VIEW;
type PreviewOption = { name: string; value: ViewModeType };

export const accordionBaseConfig = {
  isExpanded: false,
  activeOnOpen: true
};

export const PREVIEW_MODES: [PreviewOption, PreviewOption] = [
  {
    name: 'Desktop',
    value: DESKTOP_VIEW
  },
  {
    name: 'Mobile',
    value: MOBILE_VIEW
  }
];
export const PROGRESS_STEPS: Step[] = [
  {
    step: 1,
    caption: 'Define and Preview'
  },
  {
    step: 2,
    caption: 'Review and Publish'
  }
];

export const defaultColors = {
  bg: theme.colors.backgroundColor,
  buttonBg: theme.colors.gold,
  buttonTextColor: '#fff', 
}
export const defaultCorners = 21;
export const defaultFont = 'Roboto';

export const fonts = [
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Comic Sans MS',
    value: 'Comic Sans MS',
  },
  {
    label: 'Courier New',
    value: 'Courier New',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Lucida',
    value: 'Lucida',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman',
  },
  {
    label: 'Trebuchet MS',
    value: 'Trebuchet MS',
  },
  {
    label: 'Verdana',
    value: 'Verdana',
  },
];



