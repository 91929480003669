import styled, { CSSProperties } from 'styled-components';
import { CloseRoundIcon } from 'assets/svg';
import { resetButtonStyles, H3 } from 'uikit';

export const contentStyles: CSSProperties = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  maxWidth: '100%',
  border: 'none',
  borderRadius: 0,
  background: '#013040',
};

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  ${resetButtonStyles};
  z-index: 100;
`;

export const StyledCloseRoundIcon = styled(CloseRoundIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }

  & circle {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 40px 0;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.blackPattern};
`;
export const Title = styled(H3)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
