import React, { FC } from 'react';

interface Props {
  className?: string;
}

const Download: FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#17AACF"
      d="M19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1zm-9.71 1.71a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1.004 1.004 0 10-1.42-1.42L11 10.59V1a1 1 0 00-2 0v9.59l-2.29-2.3a1.004 1.004 0 10-1.42 1.42l4 4z"
    ></path>
  </svg>
);

export default Download;
