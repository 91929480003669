import branch from 'branch-sdk';

export interface BranchLinkGetter {
  businessId: string;
  onLinkCallback?: (err: branch.BranchError, link: branch.BranchError) => void;
}

export default function getBranchLink(
  props: BranchLinkGetter
): any {
  const {
    businessId,
    onLinkCallback
  } = props;
  let key = process.env.REACT_APP_BRANCH_KEY;

  branch.init(key || '', {} ,(err: any, data: any) => {
    err && console.log("Branch error:", err)
  });
  
  var linkData = {
    tags: ['get-app'],
    data: {
      businessId:businessId,
      $uri_redirect_mode: 2,
    }
  };

  
  branch.link(linkData, function(err, link) {
    // bind elements
    onLinkCallback && onLinkCallback(err, link)
  });

}