import styled from 'styled-components';
import { ITheme } from '../../../../types';

interface OptionNameProps extends ITheme {
  isActive: boolean;
}

export const OptionName = styled.div<OptionNameProps>`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 22px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.accentMainColor : theme.colors.black};
  min-height: 36px;
  line-height: 19px;
  cursor: pointer;
`;

export const OpportunityDetails = styled.div`
  text-align: right;
`;

export const OpportunityDate = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #727272;
`;
