import React, { FC, useState, useEffect } from 'react';
import { getProfileStatus } from 'helpers';
import { Modal } from 'uikit';
import { ModalProps } from 'uikit/Modal/Modal';
import routes from '../../constants/routes';
import { useHistory } from 'react-router';
import {
  useGetBusinessDetailsLazyQuery,
  usePublishOffersInQueueMutation
} from '../../graphql';

interface Props {
  onModalClose: () => void;
  defaultAction?: () => void;
  check: boolean;
}

const CompleteAccountSetUpModal: FC<Props> = ({
  onModalClose,
  defaultAction,
  check = false
}) => {
  const [
    getBusinessDetails,
    { data: businessData }
  ] = useGetBusinessDetailsLazyQuery({
    fetchPolicy: 'cache-first'
  });

  const [publishInQueueOffers] = usePublishOffersInQueueMutation();

  const closeErrorModal = () => {
    setErrorModalData({ ...errorModalData, isOpen: false });
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [setupWasCompletedInitially, setSetupWasCompletedInitially] = useState<
    boolean | null
  >(null);
  const [errorModalData, setErrorModalData] = useState<ModalProps>({
    title: 'An error occured while setting opportunities status',
    isOpen: false,
    mainButton: { text: 'Okay', onClick: closeErrorModal },
    onClose: closeErrorModal
  });

  const history = useHistory();

  const onMainButtonClick = () => {
    history.push(routes.MY_OPPORTUNITIES);
  };
  const handleClose = () => {
    setIsModalOpen(false);
    onModalClose && onModalClose();
  };

  useEffect(() => {
    getBusinessDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (check) {
      getBusinessDetails();
    }
    // eslint-disable-next-line
  }, [check]);

  useEffect(() => {
    async function execute() {
      if (!businessData) return;
      const {
        completeProfile,
        setupBilling,
        setupTracking,
        createOpportunity
      } = getProfileStatus(businessData);

      const isProfileCompleted =
        completeProfile.isCompleted &&
        setupBilling.isCompleted &&
        setupTracking.isCompleted;
      const hasDraftOffers = createOpportunity.draftOfferId;

      if (setupWasCompletedInitially === null) {
        setSetupWasCompletedInitially(isProfileCompleted);
      } else if (
        isProfileCompleted &&
        setupWasCompletedInitially !== isProfileCompleted
      ) {
        await publishInQueueOffers();
        if (hasDraftOffers) {
          !isModalOpen && setIsModalOpen(true);
        } else {
          defaultAction && defaultAction();
        }
      } else if (check && defaultAction) {
        defaultAction();
      }
    }
    execute();
    // eslint-disable-next-line
  }, [businessData]);

  return (
    <>
      <Modal
        type="success"
        title={
          'Ready to go! Review and publish your opportunities to the Drum app to start driving customers.'
        }
        mainButton={{ text: 'Go to opportunities', onClick: onMainButtonClick }}
        isOpen={isModalOpen}
        onClose={handleClose}
        onCloseOnButtons={false}
      ></Modal>
      {errorModalData && <Modal type="error" {...errorModalData}></Modal>}
    </>
  );
};

export default CompleteAccountSetUpModal;
