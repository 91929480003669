import styled from 'styled-components';
import { InputBase } from 'uikit';
import DatePicker from 'react-datepicker';

export const DatePickerContainer = styled(DatePicker)`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(InputBase)`
  width: 140px;
  margin-right: 20px;
`;
