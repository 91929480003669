import { Styles } from 'react-select';
import { Theme } from 'styled-components';

export const selectStyles = (theme: Theme): Styles => ({
  option: () => ({
    padding: '10px 0',
    marginRight: '50px',
    fontWeight: theme.fontWeight.extraBold,
    cursor: 'pointer'
  }),

});