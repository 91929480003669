import styled from 'styled-components';
import { InputBase, PrimaryButton } from 'uikit';
import { Search } from 'assets/svg';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;
export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(InputBase)`
  padding-left: 40px;
  width: 345px;

  ${breakpoint('xs', 'lg')`
    width: 100%;
    min-width: 205px;
  `}
  
`;

export const Icon = styled(Search)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;

export const AddButton = styled(PrimaryButton)`
  width: 64px;
  margin-left: 20px;
  padding: 6px 0;
`;
