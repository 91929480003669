import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Tooltip from 'rc-tooltip';
import { difference } from 'lodash';
import { Checkbox } from 'uikit';
import { COLUMNS } from './constants';
import { IMenuOption } from './types';
import { getSource, getStatus } from './helpers';
import CustomerCollapseItem from './components/CustomerCollapseItem/CustomerCollapseItem';
import useWindowDimensions from 'components/UseWindowDimensions';
import { CustomerTag, Customer, CustomerStatus, Maybe } from '../../../../graphql';
import DATE_FORMAT from 'constants/dateFormat';
import moment from 'moment';
import { theme } from 'styles/theme';

import {
  Container,
  TableContainer,
  MobileCardsContainer,
  DataRow,
  HeaderRow,
  HeaderRowMobile,
  DataCol,
  HeaderCol,
  HeaderColMobile,
  NoItemsBlock,
  StyledTableTag,
  TagContainer,
  MenuButton,
  MenuContainer,
  StyledMenuButton,
  Dot,
  TooltipContent,
  ImportButton
} from './styles';

export interface ICustomerItem {
  id: number | string;
  name: string;
  startDate: string;
  endDate: string;
  rewards: number;
  fees: number;
  charges: number;
  totalCharge: number;
  pending: boolean;
  emphasized?: boolean;
}

interface IMenuOptions {
  [key: string]: IMenuOption[]
}

interface Props {
  data?: Customer[];
  onImportClick?: () => void;
  onSelectItem?: (items: string) => void;
  onDeselectItem?: (items: string) => void;
  onSelectAll?: (items: string[]) => void;
  onDeselectAll?: (items: string[]) => void;
  onEdit?: (id: string) => void;
  onTag?: (id: string) => void;
  onUnsubscribe?: (id: string) => void;
  onDelete?: (id: string) => void;

  checkedItems?: string[];
}

const CustomersTable: FC<Props> = ({
  data = [],
  onImportClick,
  onSelectItem,
  onDeselectItem,
  onSelectAll,
  onDeselectAll,
  onEdit,
  onTag,
  onUnsubscribe,
  onDelete,
  checkedItems = []
}) => {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);
  const { width: wWidth } = useWindowDimensions();
  const menuOptions:IMenuOptions = {
    default: [
      {
        name: 'Edit',
        onClick: (id: string) => {
          onEdit && onEdit(id);
        }
      },
      {
        name: 'Tag',
        onClick: (id: string) => {
          onTag && onTag(id);
        }
      },
      {
        name: 'Unsubscribe',
        onClick: (id: string) => {
          onUnsubscribe && onUnsubscribe(id);
        }
      },
      {
        name: 'Delete',
        onClick: (id: string) => {
          onDelete && onDelete(id);
        }
      }
    ],
    alt: [
      {
        name: 'Edit',
        onClick: (id: string) => {
          onEdit && onEdit(id);
        }
      },
      {
        name: 'Tag',
        onClick: (id: string) => {
          onTag && onTag(id);
        }
      },
      {
        name: 'Delete',
        onClick: (id: string) => {
          onDelete && onDelete(id);
        }
      }
    ],

  };

  const getMenuOptions = (status?: CustomerStatus | null) => {
    switch(status) {
      case CustomerStatus.Subscribed:
      case CustomerStatus.Pending:
        return menuOptions.default;
      default:
        return menuOptions.alt;
    }
  }

  const handleToggle = (id: string) => {
    openedMenu === id ? setOpenedMenu(null) : setOpenedMenu(id);
  };

  const selectAll = () => {
    onSelectAll && onSelectAll(data.map((item) => item.id));
  };
  const deselectAll = () => {
    onDeselectAll && onDeselectAll(data.map((item) => item.id));
  };
  const selectItem = (id: string) => {
    onSelectItem && onSelectItem(id);
  };
  const deselectItem = (id: string) => {
    onDeselectItem && onDeselectItem(id);
  };

  return (
    <Container>
      {wWidth > theme.breakpoints.lg && (
        <TableContainer>
          <HeaderRow>
            <HeaderCol className={'check'}>
              <Checkbox
                onCheck={selectAll}
                onUncheck={deselectAll}
                checked={
                  !!checkedItems.length &&
                  !difference(
                    (data || []).map((item) => item.id),
                    checkedItems
                  ).length
                }
              />
            </HeaderCol>
            {COLUMNS.map((column) => (
              <HeaderCol key={column.name} className={column.name}>
                {column.description ? (
                  <Tooltip
                    placement="top"
                    trigger={['hover']}
                    mouseEnterDelay={2}
                    overlay={
                      <TooltipContent>
                        <h3>{column.caption || ''}</h3>
                        <p>{column.description || ''}</p>
                      </TooltipContent>
                    }
                  >
                    <span>{column.caption || ''}</span>
                  </Tooltip>
                ) : (
                  <span>{column.caption || ''}</span>
                )}
              </HeaderCol>
            ))}
          </HeaderRow>
          {data.length ? (
            data.map((item) => (
              <DataRow key={item.id}>
                <DataCol className={'check'}>
                  <Checkbox
                    onCheck={() => selectItem(item.id)}
                    onUncheck={() => deselectItem(item.id)}
                    checked={(checkedItems || []).indexOf(item.id) !== -1}
                  />
                </DataCol>
                <DataCol
                  className={'name'}
                >{`${item.firstName} ${item.lastName}`}</DataCol>
                <DataCol className={'dateAdded'}>
                  {moment(item.createdAt).format(DATE_FORMAT)}
                </DataCol>
                <DataCol className={'lastContacted'}>
                  {!!item.lastContacted && (
                    <>
                      {moment(item.lastContacted).format(DATE_FORMAT)}
                      <br />
                      {moment(item.lastContacted).format('LT')}
                    </>
                  )}
                </DataCol>
                <DataCol className={'tags'}>
                  {(item.tags || []).map((tag: Maybe<CustomerTag>, index) => (
                    <>
                      {index < 2 && (
                        <TagContainer
                          key={tag?.name || ''}
                          onClick={() => {
                            onTag && onTag(item.id);
                          }}
                        >
                          <StyledTableTag name={tag?.name || ''} />
                        </TagContainer>
                      )}
                    </>
                  ))}
                  {(item.tags || []).length > 2 && (
                    <TagContainer>
                      <StyledTableTag
                        name={'+' + ((item.tags || []).length - 2).toString()}
                      />
                    </TagContainer>
                  )}
                </DataCol>
                <DataCol className={'totalReward'}>
                  ${item.totalReward || 0}
                </DataCol>
                <DataCol className={'referrals'}>{item.referrals || 0}</DataCol>
                <DataCol className={'totalSales'}>
                  ${item.totalSales || 0}
                </DataCol>
                <DataCol className={'source'}>
                  {getSource(item.source || '')}
                </DataCol>
                <DataCol
                  className={'status ' + (item.status || '').toLowerCase()}
                >
                  {getStatus(item.status || '')}
                </DataCol>
                <DataCol
                  className={'options'}
                  onClick={() => handleToggle(item.id)}
                >
                  <MenuButton type="button">
                    {Array(3)
                      .fill(0)
                      .map((_, index) => (
                        <Dot key={index} />
                      ))}
                  </MenuButton>
                  {openedMenu === item.id && (
                    <MenuContainer>
                      <ul>
                        <OutsideClickHandler
                          onOutsideClick={() => setOpenedMenu(null)}
                        >
                          {(getMenuOptions(item.status) || []).map(({ name, onClick }) => (
                            <StyledMenuButton
                              key={name}
                              onClick={() => {
                                onClick(item.id);
                              }}
                              type="button"
                            >
                              {name}
                            </StyledMenuButton>
                          ))}
                        </OutsideClickHandler>
                      </ul>
                    </MenuContainer>
                  )}
                </DataCol>
              </DataRow>
            ))
          ) : (
            <NoItemsBlock>
              {/* <NoItemsText>No Activity Yet</NoItemsText> */}
              <ImportButton onClick={onImportClick}>
                Import your customers
              </ImportButton>
            </NoItemsBlock>
          )}
        </TableContainer>
      )}
      {wWidth <= theme.breakpoints.lg && (
        <MobileCardsContainer>
          <HeaderRowMobile>
            <HeaderColMobile className={'check'}>
              <Checkbox
                onCheck={selectAll}
                onUncheck={deselectAll}
                checked={checkedItems?.length === data.length}
              />
            </HeaderColMobile>
            <HeaderColMobile className={'name'}>Name</HeaderColMobile>
            <HeaderColMobile className={'status'}>Status</HeaderColMobile>
          </HeaderRowMobile>
          {data.length ? (
            data.map((item) => (
              <CustomerCollapseItem
                key={item.id}
                data={item}
                menuOptions={(getMenuOptions(item.status) || [])}
                menuOpened={openedMenu === item.id}
                selected={(checkedItems || []).indexOf(item.id) !== -1}
                onSelect={() => selectItem(item.id)}
                onDeselect={() => deselectItem(item.id)}
                onMenuOpen={() => setOpenedMenu(item.id)}
                onMenuClose={() => setOpenedMenu(null)}
                onTagClick={(id) => {
                  onTag && onTag(id);
                }}
              />
            ))
          ) : (
            <NoItemsBlock>
              {/* <NoItemsText>No Activity Yet</NoItemsText> */}
              <ImportButton onClick={onImportClick}>
                Import your customers
              </ImportButton>
            </NoItemsBlock>
          )}
        </MobileCardsContainer>
      )}
    </Container>
  );
};

export default CustomersTable;
