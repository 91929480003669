import React, {
  FC,
  useState,
  FormEvent,
  ChangeEvent as ReactChangeEvent,
  useEffect
} from 'react';
import Autosuggest, {
  ChangeEvent,
  OnSuggestionSelected,
  RenderSuggestion,
  SuggestionsFetchRequested
} from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useDebouncedQuery } from 'helpers';
import { useListCategoriesLazyQuery } from '../../graphql';
import { Container, StyledInput, Icon, InputContainer } from './styles';
import { Loader } from 'uikit';
import './styles.scss';

interface ICategory {
  name: string;
  id: string;
  searchValue?: string;
}

interface Props {
  selectedCategories: ICategory[];
  onSelect: (category: ICategory) => void;
}

// TODO: make this component generic for reusing in other places of app
// TODO: extract category type from generated code and remove `any`
const CategoriesAutosuggest: FC<Props> = ({ onSelect, selectedCategories }) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [value, setValue] = useState('');

  // TODO: handle error and loading states
  const [getCategories, { data, loading }] = useDebouncedQuery(
    useListCategoriesLazyQuery
  );

  useEffect(() => {
    if (data?.listCategories?.items) {
      setSuggestions(data?.listCategories?.items);
    }
  }, [data]);

  const handleInputChange = (
    _: FormEvent<HTMLInputElement>,
    { newValue }: ChangeEvent
  ) => setValue(newValue);

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = ({ value }) =>
    getCategories({ variables: { input: { query: value } } });

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected: OnSuggestionSelected<any> = (
    _,
    { suggestion }
  ) => {
    const { id, name } = suggestion;
    const categoryDuplicate = selectedCategories.find(
      ({ id }) => id === suggestion.id
    );

    if (!categoryDuplicate) {
      onSelect({ id, name, searchValue: value });
      setValue('');
    }
  };

  const renderSuggestion: RenderSuggestion<any> = (suggestion, { query }) => {
    const matchedText = match(suggestion.name, query);
    const parsedText = parse(suggestion.name, matchedText);

    return parsedText.map(({ text, highlight }, i) => (
      <span key={i}>{highlight ? <b>{text}</b> : text}</span>
    ));
  };

  return (
    <Container>
      <Autosuggest
        inputProps={{
          name: 'categoriesSearch',
          onChange: handleInputChange,
          value
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        suggestions={suggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion: ICategory) => suggestion.name}
        renderInputComponent={(inputProps) => (
          // TODO: use SearchInput component
          <InputContainer>
            {loading && <Loader className="category-autosuggest-loader"/>}
            <Icon />
            <StyledInput
              {...inputProps}
              // `onChange` from `react-autosuggest` is incompatible with input `onChange` handler.
              // See more details here - https://stackoverflow.com/questions/61785523/autosuggest-renderinputcomponent-inputprops-types-of-property-onchange-are-i
              onChange={(event: ReactChangeEvent<HTMLInputElement>): void => {
                inputProps.onChange(event, {
                  newValue: event.target.value,
                  method: 'type'
                });
              }}
            />
          </InputContainer>
        )}
        renderSuggestion={renderSuggestion}
      />
    </Container>
  );
};

export default CategoriesAutosuggest;
