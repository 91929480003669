import styled from 'styled-components';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #CCCCCC;

  &:first-of-type {
    border-top: 1px solid #CCCCCC;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  cursor: pointer;
  padding-left: 20px;
`;

export const DateBlock = styled.div`
  
  flex: 0.52;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.size.body2};

    &:first-of-type {
      margin-right: 10px
    }
  }
`;
export const TotalBlock = styled.div`
  flex: 0.32;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.size.body1};
`;
export const IconBlock = styled.div`
  flex: 0.16;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
`;
export const Row = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
`;
export const Block = styled.div`
  
  &.referrer {
    flex: 0.34;
  }
  &.opportunity {
    flex: 0.66;
  }
  &.transaction {
    flex: 0.34;
  }
  &.fees {
    flex: 0.34;
  }
  &.rewards {
    flex: 0.32;
  }
`;
export const BlockTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  height: 22px;
  line-height: 22px;
  margin-bottom: 6px;
`;
export const BlockValue = styled.div`
  &.referrer {
    font-size: ${({ theme }) => theme.size.body2};
    font-weight: 500;
  }
  &.opportunity {
    font-size: ${({ theme }) => theme.size.caption};
    font-weight: 500;
    max-height: 38px;
    line-height: 19px;
    overflow: hidden;
  }
  &.transaction {
    flex: 0.34;
  }
  &.fees {
    flex: 0.34;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.size.body1};
  }
  &.rewards {
    flex: 0.32;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.size.body1};
  }
`;
export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.main};
`;



