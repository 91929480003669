import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import FormattedPrice from '../FormattedPrice';
import Pagination from '../Pagination';
import { PaginationContainer } from '../Pagination/styles';
import {
  Container,
  DrummerAvatarTd,
  DrummerInfo,
  DrummerName,
  DesktopTable,
  MobileTable,
  StyledAvatar,
  StyledLoader,
  PurchaseTd,
  StyledTitle,
  ActiveHeader,
  NoItemsBlock,
  NoItemsText,
  TableContainer
} from './styles';
import { Tr, Td, Th } from '../Table';
import {
  useGetRewardActivityLazyQuery,
  V15QueryRewardActivityInput,
  RewardSortBy,
  RewardEntry,
  PageInfo,
  ImageStyle
} from '../../graphql';
import getImagePath from 'helpers/getImagePath';

interface Period {
  from: Date;
  to: Date;
}

interface Props {
  className?: string;
  isDisabled?: boolean;
  period?: Period;
  offerId?: string | null;
  showTotalColumn: boolean;
}

const widgetTitle = {
  disabled: 'Rewards Activity',
  active: 'Rewards Activity'
};

const TopRewardsTable: FC<Props> = ({
  className,
  isDisabled,
  period,
  offerId = '',
  showTotalColumn
}) => {
  const [filters, setFilters] = useState<V15QueryRewardActivityInput>({
    sortBy: RewardSortBy.Date,
    pagination: {
      page: 1,
      limit: 5
    },
    offerId: offerId || '',
    dateRange: period
      ? {
          from: period.from.toISOString(),
          to: period.to.toISOString()
        }
      : undefined
  });
  const [items, setItems] = useState<RewardEntry[] | undefined | null>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const [getTopDrummers, { data, loading }] = useGetRewardActivityLazyQuery();

  const onPageChange = (page: number) => {
    setFilters({ ...filters, pagination: { ...filters.pagination, page } });
  };

  const getFormattedDate = (date: string | null | undefined) => {
    return date ? moment(date).format('M/DD/YYYY hh:mm A') : '';
  };

  useEffect(() => {
    if (!filters.dateRange) return;
    getTopDrummers({
      variables: {
        input: filters
      }
    });
    // eslint-disable-next-line
  }, [filters.pagination, filters.dateRange, filters.offerId, getTopDrummers]);

  useEffect(() => {
    if (!period) return;
    setFilters({
      ...filters,
      offerId: offerId || '',
      dateRange: {
        ...filters.dateRange,
        from: period.from.toISOString(),
        to: period.to.toISOString()
      }
    });
    // eslint-disable-next-line
  }, [period?.from, period?.to, offerId, setFilters]);

  useEffect(() => {
    if (!data?.v15QueryRewardActivity) return;
    setItems(data.v15QueryRewardActivity.items as RewardEntry[]);
    setPageInfo(data.v15QueryRewardActivity.pageInfo);
  }, [data, setItems, setPageInfo]);

  return (
    <Container className={className} isDisabled={isDisabled}>
      {loading && <StyledLoader />}
      <StyledTitle
        title={isDisabled ? widgetTitle.disabled : widgetTitle.active}
        term={''}
      />

      <TableContainer>
        <DesktopTable>
          <thead>
            <Tr>
              <Th colSpan={2} className={showTotalColumn ? '' : 'name-long'}>
                Referrers
              </Th>
              <ActiveHeader>Date</ActiveHeader>
              <Th>Reward</Th>
              {showTotalColumn && <Th>Total Sales</Th>}
            </Tr>
          </thead>

          {items && !!items.length && (
            <tbody>
              {items.map((item, index) => (
                // <Tr key={item.redemptionId || undefined}> //We don't use it for now, because there are duplicated ids on BE
                <Tr>
                  <DrummerAvatarTd>
                    <StyledAvatar
                      firstName={item.drummer?.firstName}
                      lastName={item.drummer?.lastName}
                      userName={item.drummer?.username}
                      src={getImagePath(
                        (item.drummer?.avatarImage?.styles ||
                          []) as ImageStyle[]
                      )}
                    />
                  </DrummerAvatarTd>
                  <Td className={showTotalColumn ? '' : 'name-long'}>
                    {item.drummer?.firstName && item.drummer?.lastName ? 
                      <DrummerName>
                        {item.drummer.firstName}{' '}
                        {(item.drummer.lastName || '').substr(0, 1)}
                      </DrummerName>
                      : 
                      <DrummerName>
                        {item.drummer?.username}
                      </DrummerName>
                    }
                  </Td>
                  <Td>{getFormattedDate(item.createdAt)}</Td>
                  <Td>
                    <FormattedPrice value={(item.income || 0).toFixed(2)} />
                  </Td>
                  {showTotalColumn && (
                    <Td>
                      <FormattedPrice
                        value={(
                          Number(item.trackingEntry?.amount) || 0
                        ).toFixed(2)}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </tbody>
          )}
        </DesktopTable>

        {items && !!items.length && (
          <MobileTable>
            <tbody>
              {items.map((item, index) => (
                <Tr>
                  <DrummerAvatarTd>
                    <StyledAvatar
                      firstName={item.drummer?.firstName}
                      lastName={item.drummer?.lastName}
                      userName={item.drummer?.username}
                      src={getImagePath(
                        (item.drummer?.avatarImage?.styles ||
                          []) as ImageStyle[]
                      )}
                    />
                  </DrummerAvatarTd>
                  <Td>
                    <DrummerInfo>
                      {item.drummer?.firstName && item.drummer?.lastName ? 
                        <div>
                          {item.drummer.firstName}{' '}
                          {(item.drummer.lastName || '').substr(0, 1)}
                        </div>
                        : 
                        <div>
                          {item.drummer?.username}
                        </div>
                      }
                      <div>{getFormattedDate(item.createdAt)}</div>
                      <div>Reward: </div>
                    </DrummerInfo>
                  </Td>
                  <PurchaseTd>
                    <FormattedPrice value={(item.income || 0).toFixed(2)} />
                  </PurchaseTd>
                </Tr>
              ))}
            </tbody>
          </MobileTable>
        )}

        {!items ||
          (!items.length && (
            <NoItemsBlock>
              <NoItemsText>No Activity Yet</NoItemsText>
            </NoItemsBlock>
          ))}
      </TableContainer>
      <PaginationContainer>
        {items && !!items.length && pageInfo && (
          <Pagination
            onPageChange={onPageChange}
            pageCount={Math.ceil(
              Number(pageInfo.total || 5) / (filters.pagination.limit || 5)
            )}
          />
        )}
      </PaginationContainer>
    </Container>
  );
};

export default TopRewardsTable;
