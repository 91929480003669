// TODO: delete mock data after API integration
export const metricsOptions = {
  SAVES: 'saves',
  SHARES: 'shares',
  VISITS: 'visits',
  CONVERSIONS: 'conversions',
  PURCHASE_AMOUNT: 'purchaseAmount'
} as const;

type metricsOptionsKeys = keyof typeof metricsOptions;
export type metricsOptionsValues = typeof metricsOptions[metricsOptionsKeys];

export type Metric = {
  id: string;
  title: string;
  value: number;
  description: string;
  groups: any[],
  formattedValue?: string;
  idName: string
};

export type PerformanceData = {
  term: string;
  metrics: Metric[];
};

export const performanceData: PerformanceData = {
  term: '03/01/2020 - TODAY',
  metrics: [
    {
      id: metricsOptions.VISITS,
      title: 'Visits',
      value: 0,
      description:
        'Represents the number of times a customer clicked on the opportunity link and visited your destination URL.',
      groups: [],
      idName: 'visitsMetric'
    },
    {
      id: metricsOptions.SAVES,
      title: 'Saves',
      value: 0,
      description: 'The number of times Referrers have saved your opportunity.',
      groups: [],
      idName: 'savesMetric'
    },
    // {
    //   id: metricsOptions.SHARES,
    //   title: 'Shares',
    //   value: 0,
    //   description:
    //     'The number of times Referrers have clicked the share button on the opportunity.',
    //   groups: []
    // },
    {
      id: metricsOptions.CONVERSIONS,
      title: 'Conversions',
      value: 0,
      description:
        'The number of times customers clicked on the opportunity link and followed through to the conversion page.',
      groups: [],
      idName: 'conversionsMetric'
    },
    {
      id: metricsOptions.PURCHASE_AMOUNT,
      title: 'Total Sales',
      value: 0,
      description: 'The total sales generated by this opportunity each day.',
      groups: [],
      formattedValue: '$0',
      idName: 'totalSalesMetric'
    }
  ]
};

export const widgetTitle = {
  ACTIVE: 'Performance',
  DISABLED: 'Your future performance'
};
