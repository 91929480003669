import React, { ReactNode } from 'react';
import useCollapse from 'react-collapsed';
import { Container, Button, Content } from './styled';

type Props = {
  title: string;
  children: ReactNode;
};

const SnippetCollapse: React.FC<Props> = ({ title, children }: Props) => {
  const { getCollapseProps, getToggleProps } = useCollapse();
  return (
    <Container>
      <Button {...getToggleProps()}>{title}</Button>
      <Content {...getCollapseProps()}>{children}</Content>
    </Container>
  );
};

export default SnippetCollapse;
