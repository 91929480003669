import styled from 'styled-components';
import { InputBase, TextareaBase, PrimaryButton, Checkbox, FieldFooter } from 'uikit';
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	* {
		font-family: inherit !important;
	}
`;
export const Content = styled.div`
	padding-top: 10px;
`;
export const InputGroup = styled.div`
	width: 100%;
	min-height: 40px;
	box-sizing: border-box;
	padding: 0 13px;
	display: flex;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 4px;
	margin-bottom: 9px;
	
	&.invalid {
		border: 1px solid ${({ theme }) => theme.colors.danger};
	}
`;
export const TextAreaGroup = styled(InputGroup)`
	padding: 0 7px 0 13px;
	&.invalid {
		border: 1px solid ${({ theme }) => theme.colors.danger};
	}
`;
export const InputLabel = styled.span`
	min-width: 45px;
	font-size: ${({ theme }) => theme.size.body1};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	height: 38px;
	line-height: 38px;
`;
export const MainButton = styled(PrimaryButton)`
	margin-top: 63px;
	width: 100%;
`;
export const StyledInput = styled(InputBase)`
	flex: 1;
	height: 38px;
	border: none;
	padding: 0 3px;
`;
export const StyledTextarea = styled(TextareaBase)`
	border: none;	
	padding: 10px 0;
	resize: none;
	line-height: 15px;
`;
export const StyledCheckbox = styled(Checkbox)`
	font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
export const StyledFieldFooterContainer = styled.div`
	min-height: 18px;
	margin: 5px 0;
`;
export const StyledFieldFooter = styled(FieldFooter)`
	
`;
