import React, { FC, useState } from 'react';
import { selectStyles } from './styles';
import CustomSelect from '../CustomSelect';
import MenuList from './components/MenuList';
import { withTheme } from 'styled-components';
import { ITheme } from '../../types';
import moment from 'moment';



export interface IOption {
  label: string;
  value: string;
  disabled?: boolean
}

export interface IDates {
  startDate: Date;
  endDate: Date;
}

interface Props extends ITheme {
  className?: string;
  onChange?: (date: IDates) => void
}

const options = [
  {
    label: 'Last 7 days',
    value: 'lastWeek',
  },
  {
    label: 'Last 30 days',
    value: 'lastMonth'
  },
  {
    label: 'Last 12 months',
    value: 'lastYear'
  }
];

const ActivityFilter: FC<Props> = ({ className, theme, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<IOption | null>(options[1]);

  const selectOption = (option: IOption) => {
    setSelectedOption(option);

    let dates: IDates;

    switch(option.value) {
      case 'lastWeek':
        dates = {
          startDate: moment().subtract(1, 'weeks').toDate(),
          endDate: moment().toDate()
        }
        break;
      case 'lastMonth':
        dates = {
          startDate: moment().subtract(1, 'months').toDate(),
          endDate: moment().toDate()
        }
        break;
      case 'lastYear':  
        dates = {
          startDate: moment().subtract(1, 'years').toDate(),
          endDate: moment().toDate()
        }
        break;
      default:  
        dates = {
          startDate: moment().subtract(1, 'months').toDate(),
          endDate: moment().toDate()
        }
        break;
    }

    onChange && onChange(dates);

  }

  return (
    <CustomSelect<IOption>
      className={className}
      label="Performance date range"
      options={options}
      components={{ MenuList }}
      // We need to cast because `react-select` allows to choose array values for multiselect
      onChange={(option) => selectOption(option as IOption)}
      selectStyles={selectStyles(theme)}
      value={selectedOption}
    />
  );
};

export default withTheme(ActivityFilter);
