export interface Column {
  field: number | string;
  title: string;
}

export const mobileColumns: Column[] = [
  {
    field: "payoutsNum",
    title: "Rewards Issued"
  },
  {
    field: "totalDrumFee",
    title: "Platform Fees"
  },
  {
    field: "totalDrummerReward",
    title: "Reward Charges"
  },
  {
    field: "totalAmount",
    title: "Total Charge"
  }
]