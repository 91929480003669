import React, {
  FC,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';
import authMethods from 'constants/authMethods';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Container,
  Content,
  Copyright,
  BackgroundImage,
  BackgroundImageOverlay,
  ImageCaption,
  ImageContainer,
  PageContent,
  PageHead,
  PageFooter,
  ReturnLinkWrapper,
  StyledLogo,
  Subtitle,
  Title,
  Watermark
} from './styles';

type AuthMethods = authMethods.EMAIL | authMethods.SHOPIFY | null;

interface Props {
  renderContent: (
    authMethod: AuthMethods,
    selectAuthMethod: Dispatch<SetStateAction<AuthMethods>>
  ) => ReactNode;
  returnLink: ReactNode;
  subtitle: string;
  authMethodByDefault?: AuthMethods;
}

const _DATA = {
  image: '/assets/sign-up.jpg',
  caption: 'Supercharge your referral sales'
};

const AuthPageLayout: FC<Props> = ({ subtitle, renderContent, returnLink, authMethodByDefault }) => {
  const [authMethod, selectAuthMethod] = useState<AuthMethods>(authMethodByDefault || null);
  const location = useLocation();

  useEffect(() => {
    const { authWithShopify } = queryString.parse(location.search);

    if (authWithShopify) {
      selectAuthMethod(authMethods.SHOPIFY);
    }
  }, [location.search]);

  return (
    <Container>
      <Content>
        <PageHead>
          <StyledLogo />
          <Title>Welcome to Drum!</Title>
          <Subtitle>{subtitle}</Subtitle>
        </PageHead>

        <PageContent>{renderContent(authMethod, selectAuthMethod)}</PageContent>

        <PageFooter>
          <ReturnLinkWrapper>{returnLink}</ReturnLinkWrapper>
          <Watermark src="/assets/drum-grey.png" alt="drum_copy_watermark" />
          <Copyright>© 2020 Drum Inc. All rights reserved.</Copyright>
        </PageFooter>
      </Content>
      <ImageContainer>
        <BackgroundImage url={_DATA.image} />
        <BackgroundImageOverlay />
        <ImageCaption>{_DATA.caption}</ImageCaption>
      </ImageContainer>
    </Container>
  );
};

export default AuthPageLayout;
