import styled from 'styled-components';
import { H3, PrimaryButton } from 'uikit';

export const Container = styled.div`
  position: fixed;
  width: 300px;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

export const AlertIcon = styled.img`
  margin-bottom: 20px;
`;

export const ErrorMessage = styled(H3)`
  margin-bottom: 25px;
  text-align: center;
`;

export const RedirectButton = styled(PrimaryButton)`
  width: 100%;
`;
