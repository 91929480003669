import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import routes from 'constants/routes';

const UnauthorizedRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? (
    <Redirect to={routes.DASHBOARD} />
  ) : (
    <Route {...props} />
  );
};

export default UnauthorizedRoute;
