import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { ButtonTextMixin } from 'uikit';

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 0;
  width: 230px;
  padding: 10px 12px;
  box-sizing: border-box;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.5);
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  border: none;
  box-sizing: border-box;
  text-align: left;
  background-color: transparent;
  border-radius: 4px;
  ${ButtonTextMixin};

  :hover {
    background-color: #f2f2f2;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const Dot = styled.span`
  width: 7px;
  height: 7px;
  margin-right: 4px;
  background-color: #d8d8d8;
  border-radius: 100%;

  ${breakpoint('xs', 'md')`
    width: 9px;
    height: 9px;
    margin-top: 10px;
  `};

  ${breakpoint('md', 'lg')`
    width: 6px;
    height: 6px;
  `};
`;
