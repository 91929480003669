import React, { FC } from 'react';
import { Container, Caption, Paragraph, StyledLink } from './styles';

interface Props {
  onLinkClick: () => void;
}

const SummaryLearnMoreModalContent: FC<Props> = ({ onLinkClick }) => {
  return (
    <Container>
      <Caption>Drum hosted page:</Caption>
      <Paragraph>
        Drum has already built for you a referral program webpage we are hosting
        on Drum. We suggest you display the URL link prominently on your website
        and email communications directing them to this page.
      </Paragraph>
      <Caption>Host on your site:</Caption>
      <Paragraph>
        Drum makes it simple for you to display the body contents of the
        referral program on your own hosted page. Choose{' '}
        <StyledLink onClick={onLinkClick}>Customize and Publish</StyledLink>{' '}
        to modify the design and copy to your liking. Once finished, proceed to
        the next step to copy a snippet of code that you can place within the
        body tags of a new page on your site.
      </Paragraph>
      <Paragraph>
        If you use Shopify, you can simply choose publish new page from within
        Drum.
      </Paragraph>
    </Container>
  );
};

export default SummaryLearnMoreModalContent;
