import React, {FC} from 'react'
import { useHistory } from 'react-router-dom'
import { getAuthErrorTitleByType } from 'constants/errors';
import { getCensoredEmail } from 'helpers/emails';
import {
  Modal
} from 'uikit';
import {
  Container,
  Content,
  Email,
  TextBlock
} from './styled'
import routes from 'constants/routes'

export interface Props {
  title?: string,
  email?: string,
  provider?: string,
  text?: string,
  isOpen?: boolean,
  onClose?: () => void,
  onMainButtonClick?: () => void,
  onSecondaryButtonClick?: () => void,
}

const AccountCreatedModal:FC<Props> = ({
  title,
  isOpen = false,
  text,
  email,
  provider,
  onClose,
  onMainButtonClick,
  onSecondaryButtonClick,
}) => {

  const history = useHistory();

  const handleMainButtonClick = () => {
    onMainButtonClick ? onMainButtonClick() : history.push(routes.LOGIN);
  }
  const handleSecondaryButtonClick = () => {
    onSecondaryButtonClick ? onSecondaryButtonClick() : history.push(routes.SIGN_UP);
  }
  const handleClose = () => {
    onClose && onClose()
  }

  const getDefaultTitle = () => {
    return getAuthErrorTitleByType(provider || '');
  }

  return (
      <Modal
        title={title || getDefaultTitle() || ''}
        type="warning"
        mainButton={{ text: 'Log In', onClick: handleMainButtonClick }}
        secondaryButton={{ text: 'Return to Sign Up', onClick: handleSecondaryButtonClick }}
        isOpen={isOpen}
        onClose={handleClose}
        childrenBeforeButtons={true}
      >
        <Container>
          <Content>
            {email && <Email>
              {getCensoredEmail(email)}
            </Email>}
            <TextBlock>
              {text || 'Please log in using this account or sign up with a different email address.'}
            </TextBlock>
          </Content>
        </Container>
      </Modal>
    
  )
}

export default AccountCreatedModal;





