import React, { FC, useState } from 'react';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import Tooltip from 'rc-tooltip';
import DATE_FORMAT from 'constants/dateFormat';
import OutsideClickHandler from 'react-outside-click-handler';
import EmailCollapseItem from './components/EmailCollapseItem/EmailCollapseItem';
import useWindowDimensions from 'components/UseWindowDimensions';
import { IMenuOption } from './types';
import CogIcon from 'assets/svg/Cog';
import CogIconPaused from 'assets/svg/CogPaused';
import TagIcon from 'assets/svg/Tag';
import { Emphasized, Body2 } from 'uikit';
import { CampaignStatus } from '../../../../graphql';
import {
  Container,
  TableContainer,
  MobileCardsContainer,
  DataRow,
  HeaderRow,
  HeaderRowMobile,
  DataCol,
  HeaderCol,
  HeaderColMobile,
  NoItemsBlock,
  SentTypeIcon,
  MenuButton,
  MenuContainer,
  StyledMenuButton,
  TagContainer,
  Dot,
  CreateButton
} from './styles';
import { theme } from 'styles/theme';

export interface CustomerItem {
  id: number | string;
  name: string;
  startDate: string;
  endDate: string;
  rewards: number;
  fees: number;
  charges: number;
  totalCharge: number;
  pending: boolean;
  emphasized?: boolean;
}

interface Props {
  data?: any[];
  onCreateClick?: () => void;
  onEdit?: (id: string) => void;
  onPause?: (id: string) => void;
  onResume?: (id: string) => void;
  onDelete?: (id: string) => void;
}

interface IMenuOptions {
  [key: string]: IMenuOption[];
}

const EmailsTable: FC<Props> = ({
  data = [],
  onCreateClick,
  onEdit,
  onPause,
  onResume,
  onDelete
}) => {
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);
  const { width: wWidth } = useWindowDimensions();
  const menuOptions: IMenuOptions = {
    active: [
      {
        name: 'Edit',
        onClick: (id: string) => {
          onEdit && onEdit(id);
        }
      },
      {
        name: 'Pause Automated Settings',
        onClick: (id: string) => {
          onPause && onPause(id);
        }
      },
      {
        name: 'Delete Email',
        onClick: (id: string) => {
          onDelete && onDelete(id);
        }
      }
    ],
    paused: [
      {
        name: 'Edit',
        onClick: (id: string) => {
          onEdit && onEdit(id);
        }
      },
      {
        name: 'Resume Automated Settings',
        onClick: (id: string) => {
          onResume && onResume(id);
        }
      },
      {
        name: 'Delete Email',
        onClick: (id: string) => {
          onDelete && onDelete(id);
        }
      }
    ],
    draft: [
      {
        name: 'Edit',
        onClick: (id: string) => {
          onEdit && onEdit(id);
        }
      },
      {
        name: 'Delete Email',
        onClick: (id: string) => {
          onDelete && onDelete(id);
        }
      }
    ]
  };

  const handleToggle = (id: number) => {
    openedMenu === id ? setOpenedMenu(null) : setOpenedMenu(id);
  };
  const handleOpenMenu = (id: number) => {
    setOpenedMenu(id);
  };
  const handleCloseMenu = (id: number) => {
    setOpenedMenu(null);
  };

  //data = mockData;

  return (
    <Container>
      {wWidth > theme.breakpoints.lg && (
        <TableContainer>
          <HeaderRow>
            <HeaderCol className={'to'}>To</HeaderCol>
            <HeaderCol className={'sent'}>Sent</HeaderCol>
            <HeaderCol className={'joined'}>Joined</HeaderCol>
            <HeaderCol className={'dateCreated'}>Date Created</HeaderCol>
            <HeaderCol className={'lastSent'}>Last Sent</HeaderCol>
            <HeaderCol className={'sentType'}>Status</HeaderCol>
            <HeaderCol className={'options'}></HeaderCol>
            {/* <HeaderCol className={'subjectLine'}>Subject Line</HeaderCol>
          <HeaderCol className={'source'}>Source</HeaderCol>
          <HeaderCol className={'dateCreated'}>Date Created</HeaderCol>
          <HeaderCol className={'lastSent'}>Last Sent</HeaderCol>
          <HeaderCol className={'sent'}>Sent</HeaderCol>
          <HeaderCol className={'opens'}>Opens</HeaderCol>
          <HeaderCol className={'clicks'}>Clicks</HeaderCol>
          <HeaderCol className={'sentType'}>Sent Type</HeaderCol>
          <HeaderCol className={'options'}></HeaderCol> */}
          </HeaderRow>
          {data.length ? (
            data.map((item) => (
              <DataRow key={item.id}>
                <DataCol className={'to'}>
                  {item.sendToFilter?.tags ? (
                    <>
                      <Emphasized>Customers</Emphasized>
                      <Emphasized>Tagged with</Emphasized>
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={
                          <div>
                            <Body2><Emphasized>Tags</Emphasized></Body2>
                            <br />
                            <Body2>This list was sent only to contacts tagged with:</Body2>
                            <br />
                            {(item.sendToFilter.tags.in || []).slice(0,3).map((item:any) => (
                              <Body2><Emphasized>{item.name}</Emphasized></Body2>
                            ))}
                            {item.sendToFilter.tags.in.length > 3 && <Body2><Emphasized>+{item.sendToFilter.tags.in.length - 3}</Emphasized></Body2>}
                          </div>
                        }
                      >
                        <TagContainer>
                          <TagIcon />
                          <span>
                            {item.sendToFilter?.tags?.in?.length || 0}
                          </span>
                        </TagContainer>
                      </Tooltip>
                    </>
                  ) : (
                    <Emphasized>All Customers</Emphasized>
                  )}
                </DataCol>
                <DataCol className={'sent'}>
                  {item?.campaignStats?.sent || 0}
                </DataCol>
                <DataCol className={'joined'}>
                  {item?.campaignStats?.joined || 0}
                </DataCol>
                <DataCol className={'dateCreated'}>
                  {item?.campaignStats?.createdAt && (
                    <>
                      {moment(item.campaignStats.createdAt).format(DATE_FORMAT)}
                      <br />
                      {moment(item.campaignStats.createdAt).format('LT')}
                    </>
                  )}
                </DataCol>
                <DataCol className={'lastSent'}>
                  {item?.campaignStats?.lastTimeSent && (
                    <>
                      {moment(item.campaignStats.lastTimeSent).format(
                        DATE_FORMAT
                      )}
                      <br />
                      {moment(item.campaignStats.lastTimeSent).format('LT')}
                    </>
                  )}
                </DataCol>
                <DataCol className={'sentType'}>
                  <SentTypeIcon>
                    {item.status === CampaignStatus.Active && <CogIcon />}
                    {item.status !== CampaignStatus.Active && <CogIconPaused />}
                  </SentTypeIcon>
                  {item.status === CampaignStatus.Active
                    ? 'Enabled'
                    : capitalize(item.status || '')}
                </DataCol>
                <DataCol className={'options'}>
                  <MenuButton
                    onClick={() => handleToggle(item.id)}
                    type="button"
                  >
                    {Array(3)
                      .fill(0)
                      .map((_, index) => (
                        <Dot key={index} />
                      ))}
                  </MenuButton>
                  {openedMenu === item.id && (
                    <MenuContainer>
                      <ul>
                        <OutsideClickHandler
                          onOutsideClick={() => setOpenedMenu(null)}
                        >
                          {(menuOptions[item.status as string] || []).map(
                            ({ name, onClick }) => (
                              <StyledMenuButton
                                key={name}
                                onClick={() => {
                                  onClick(item.id);
                                  setOpenedMenu(null);
                                }}
                                type="button"
                              >
                                {name}
                              </StyledMenuButton>
                            )
                          )}
                        </OutsideClickHandler>
                      </ul>
                    </MenuContainer>
                  )}
                </DataCol>
              </DataRow>
            ))
          ) : (
            <NoItemsBlock>
              <CreateButton onClick={onCreateClick}>
                Create Email Campaign
              </CreateButton>
            </NoItemsBlock>
          )}
        </TableContainer>
      )}
      {wWidth <= theme.breakpoints.lg && (
        <MobileCardsContainer>
          <HeaderRowMobile>
            <HeaderColMobile className={'to'}>To</HeaderColMobile>
            <HeaderColMobile className={'status'}>Status</HeaderColMobile>
          </HeaderRowMobile>

          {data.length ? (
            data.map((item) => (
              <EmailCollapseItem
                data={item}
                menuOptions={menuOptions[item.status as string]}
                menuOpened={openedMenu === item.id}
                onMenuOpen={() => handleOpenMenu(item.id)}
                onMenuClose={() => handleCloseMenu(item.id)}
              />
            ))
          ) : (
            <NoItemsBlock>
              <CreateButton onClick={onCreateClick}>
                Create Email Campaign
              </CreateButton>
            </NoItemsBlock>
          )}
        </MobileCardsContainer>
      )}
    </Container>
  );
};

export default EmailsTable;
