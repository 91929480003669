import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import {
  Container,
  DesktopReturnButton,
  MobileReturnButton,
  StyledChevron,
  SubmitButton
} from './styles';

interface Props {
  // TODO: Add flag for all pages and make prop required
  canSubmit?: boolean;
  className?: string;
  onSubmit?: () => void;
  onClick?: () => void;
}

const FormFooter: FC<Props> = ({ canSubmit, className, onSubmit, onClick }) => {
  const history = useHistory();
  
  return (
    <Container className={className}>
      <MobileReturnButton
        onClick={() => history.push(routes.DASHBOARD)}
        type="button"
      >
        <StyledChevron />
      </MobileReturnButton>
      <DesktopReturnButton
        onClick={() => history.push(routes.DASHBOARD)}
        type="button"
      >
        Back
      </DesktopReturnButton>
      <SubmitButton onClick={onClick} onSubmit={onSubmit} disabled={!canSubmit} type="submit">
        I'm Done
      </SubmitButton>
    </Container>
  );
};

export default FormFooter;
