import moment from 'moment';
import {
  GetOfferQuery,
  LimitRedemptionType,
  ImageStyleName,
  CommissionType,
  GetBusinessDetailsQuery,
  IntegrationType,
  RewardActionType
} from '../../graphql';
import { CreateOpportunityType } from './validationSchema';
import { isDateValid } from 'helpers';

const isOpportunityDateValid = (date: string | null | undefined) =>
  isDateValid(date) && moment.utc().isBefore(date);

const getOpportunityPhotos = (values: GetOfferQuery | undefined): any => {
  return values?.getOffer?.photos
    ?.map((photo) => {
      const photoStyle = photo?.styles?.find(
        (photoData) => photoData?.name === ImageStyleName.Original
      );

      return !!photoStyle
        ? { name: photoStyle.name, path: photoStyle.path }
        : null;
    })
    .filter(Boolean);
};

const getRewardActionType = (
  values: GetOfferQuery | undefined,
  businessProfileData: GetBusinessDetailsQuery | undefined
) => {
  if (
    businessProfileData?.getCurrentBusiness?.integrationType ===
      IntegrationType.Shopify &&
    values?.getOffer?.rewardActionType ===
      RewardActionType.NavigateToSpecificPage
  ) {
    return RewardActionType.CompleteAPurchase;
  }

  return values?.getOffer?.rewardActionType || null;
};

const getInitialValues = (
  values: GetOfferQuery | undefined,
  businessProfileData: GetBusinessDetailsQuery | undefined
): CreateOpportunityType => {
  const startDate =
    typeof values?.getOffer?.startedAt === 'string' &&
    isOpportunityDateValid(values?.getOffer?.startedAt)
      ? values?.getOffer?.startedAt
      : moment.utc().format();

  const endDate =
    typeof values?.getOffer?.endedAt === 'string' &&
    isOpportunityDateValid(values?.getOffer?.endedAt)
      ? values?.getOffer?.endedAt
      : '';

  return {
    opportunityDetails: {
      title: values?.getOffer?.title || '',
      urlDestination: values?.getOffer?.urlDestination || '',
      description: values?.getOffer?.description || '',
      photos: getOpportunityPhotos(values) || []
    },
    referralReward: {
      rewardActionType: getRewardActionType(values, businessProfileData),
      commissionAmount: values?.getOffer?.drumRedemption?.payDrum || 0,
      commissionType:
        !values?.getOffer?.drumRedemption?.commissionType ||
        // BE sends assigns commissionType to `Paydrum` by default.
        // This commission types isn't used in app and we reset commissionType
        values?.getOffer?.drumRedemption?.commissionType ===
          CommissionType.Paydrum
          ? null
          : values?.getOffer?.drumRedemption?.commissionType,
      isMinimumPurchaseAmountRequired:
        values?.getOffer?.drumRedemption?.isRequireMinimumPurchaseAmount ||
        false,
      minimumPurchaseAmount:
        values?.getOffer?.drumRedemption?.buyerSpendPurchaseAmount || 0,
      isBudgetLimited:
        values?.getOffer?.drumRedemption?.limitRedemption?.type ===
          LimitRedemptionType.Budget || false,
      budget: values?.getOffer?.drumRedemption?.limitRedemption?.value || 0
    },
    referrerEducation: {
      noteForDrummer: values?.getOffer?.drummerNote?.description || '',
      educationMaterials:
        values?.getOffer?.drummerEducationLinks?.map(({ title, url }) => ({
          title,
          url
        })) || []
    },
    opportunityDates: { startDate, endDate }
  };
};

export const shouldShowOptionalParams = (
  values: CreateOpportunityType | undefined,
): boolean => {
  return !!(values?.opportunityDates.endDate || values?.referrerEducation?.noteForDrummer || values?.referrerEducation?.educationMaterials?.length);
};

export default getInitialValues;
