import React, { FC } from 'react';
import { Body1 } from 'uikit';
import routes from 'constants/routes';
import { IntegrationPlatform } from '../../types';
import { OTHER_PLATFORM } from '../../helpers';
import {
  Container,
  StyledListContainer,
  StyledList,
  StyledListHeading,
  StyledItem,
  StyledListItemOther,
  StyledOtherTitle
} from './styles';

interface Props {
  className?: string;
  items: Array<IntegrationPlatform>;
}

const PlatformList: FC<Props> = ({ className, items }) => (
  <Container className={className}>
    <StyledListHeading>
      What platform did you build you site on?
    </StyledListHeading>
    <StyledListContainer>
      <StyledList>
        {items.map((item) => (
          <StyledItem
            key={item.name}
            to={`${routes.SET_UP_CUSTOMER_TRACKING}/${item.link}`}
          >
            <img src={item.src} alt={item.name} title={item.name} />
          </StyledItem>
        ))}
      </StyledList>
      {/* TODO: change 'other' link later */}
      <StyledListItemOther
        to={`${routes.SET_UP_CUSTOMER_TRACKING}/${OTHER_PLATFORM}`}
      >
        <StyledOtherTitle>Other</StyledOtherTitle>
        <Body1>I built my site on a custom platform.</Body1>
      </StyledListItemOther>
    </StyledListContainer>
  </Container>
);

export default PlatformList;
