import styled from 'styled-components';
import { ITheme } from '../../types';

export const Container = styled.div<ITheme>`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Label = styled.span`
  color: #ea8700;
`;

export const Text = styled.span`
  color: #737373;
`;
