import React, { FC, useState, useEffect } from 'react';
import { InputBase, FieldFooter } from 'uikit';
import { Formik } from 'formik';
import { CloseRedMedium, AcceptGreenMedium } from 'assets/svg';
import { CONTACT_SUPPORT_LINK_ALT } from 'constants/outerLinks';
import { sendReferralPageValidateIframeClickTrackingEvent } from 'helpers/segment';
import { extractHostname } from 'helpers/misc';
import {
  Container,
  TextContainer,
  Paragraph,
  InputGroup,
  Content,
  LoaderContainer,
  SubmitButton,
  SuccessContainer,
  ErrorContainer,
  IconTextContainer,
  ITCIcon,
  ITCText,
  ErrorDescription,
  SupportLink
} from './styles';
import validationSchema, { FormType } from './validationSchema';

interface Props {}

const CodeVerification: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  let sentUrl = '';

  const initialValues = {
    url: ''
  };

  const windowOpenTimeout = 10000;
  let verificationTimeout: number | undefined = undefined;
  let verificationWindow: any | undefined = undefined;

  const verifyPage = (message: any) => {
    const url = sentUrl || '';
    if (!url) {
      onVerificationFail();
    }

    const origin = message?.origin || '';
    if (
      !message?.data?.type ||
      (message &&
        extractHostname(origin) !==
          extractHostname(process.env.REACT_APP_REFERRAL_PAGE_DOMAIN || ''))
    ) {
      return;
    }

    if (
      message?.data?.event &&
      message?.data?.type === '__verificationSuccess'
    ) {
      onVerificationSuccess();
    } else {
      onVerificationFail();
    }
    unsubscribeFromMessages();

    verificationWindow?.close();
  };
  const unsubscribeFromMessages = () => {
    window.removeEventListener('message', verifyPage);
    clearTimeout(verificationTimeout);
  };

  const subscribeToMessages = () => {
    window.addEventListener('message', verifyPage, false);
  };

  const onVerificationFail = () => {
    setError(true);
    setSuccess(false);
    setLoading(false);
    unsubscribeFromMessages();
    sendReferralPageValidateIframeClickTrackingEvent(sentUrl || '', false);
  };

  const onVerificationSuccess = () => {
    setSuccess(true);
    setError(false);
    setLoading(false);
    unsubscribeFromMessages();
    sendReferralPageValidateIframeClickTrackingEvent(sentUrl || '', true);
  };

  const handleSubmit = (values: FormType, { setSubmitting }: any) => {
    setSubmitting(true);

    setSuccess(false);
    setError(false);
    setLoading(true);

    verificationTimeout = setTimeout(() => {
      onVerificationFail();
      verificationWindow?.close();
      unsubscribeFromMessages();
    }, windowOpenTimeout);
    subscribeToMessages();
    sentUrl = values.url;
    verificationWindow = window.open(values.url || '', '_blank');

    setSubmitting(false);
  };

  useEffect(() => {
    return () => {
      unsubscribeFromMessages();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {({ errors, handleChange, values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <TextContainer>
              <Paragraph>
                Please enter the URL where you placed your code snipped. We will
                attempt to load the page successfully.
              </Paragraph>
            </TextContainer>
            <InputGroup>
              <InputBase
                name={'url'}
                onChange={handleChange}
                value={values.url}
                placeholder={'https://'}
              />
              <FieldFooter error={errors?.url} />
            </InputGroup>

            <Content>
              {loading && (
                <LoaderContainer>
                  <img src="/assets/loader.gif" alt="loader" />
                  <Paragraph>Processing Page Load</Paragraph>
                </LoaderContainer>
              )}

              {success && (
                <SuccessContainer>
                  <IconTextContainer>
                    <ITCIcon>
                      <AcceptGreenMedium />
                    </ITCIcon>
                    <ITCText>Page loaded successfully.</ITCText>
                  </IconTextContainer>
                </SuccessContainer>
              )}

              {error && (
                <ErrorContainer>
                  <IconTextContainer>
                    <ITCIcon>
                      <CloseRedMedium />
                    </ITCIcon>
                    <ITCText>No code snippet found.</ITCText>
                  </IconTextContainer>
                  <ErrorDescription>
                    Please try again. If you continue to have problems, please{' '}
                    <SupportLink
                      href={CONTACT_SUPPORT_LINK_ALT}
                      target="_blank"
                    >
                      Contact Support.
                    </SupportLink>
                  </ErrorDescription>
                </ErrorContainer>
              )}
            </Content>
            <SubmitButton type="submit">Submit</SubmitButton>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default CodeVerification;
