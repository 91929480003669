import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FieldFooter, PrimaryButton, LinkTextMixin } from 'uikit';

export const LoginLink = styled(Link)`
  display: block;
  text-align: center;
  ${LinkTextMixin};
`;

export const StyledFieldFooter = styled(FieldFooter)`
  height: 38px;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
  margin-bottom: 32px;
`;

