import React, { FC, Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import OpportunityPerformanceMetric from '../OpportunityPerformanceMetric';
import OpportunityMetricsSelect from '../OpportunityMetricsSelect';
import OpportunityChart, {
  ChartItem
} from '../OpportunityChart/OpportunityChart';
import {
  Container,
  Metrics,
  MetricDescription,
  MetricDetails,
  MetricTitle,
  StyledTitle,
  MetaContainer,
  DateContainer,
  StatusContainer,
  StatusValue,
  NoItemsBlock,
  NoItemsText
} from './styles';
import {
  performanceData,
  metricsOptions,
  widgetTitle,
  Metric
} from './constants';
import { Offer, OfferStatus } from '../../graphql';

interface Props {
  isDisabled?: boolean;
  data?: any;
  className?: string;
  offers: Offer[];
  offerId: string | null;
}

const OpportunityPerformance: FC<Props> = ({
  className,
  isDisabled,
  data = [],
  offers = [],
  offerId
}) => {
  const [metrics, setMetrics] = useState<Metric[]>(performanceData.metrics);

  const [selected, setSelectedOption] = useState<string>(
    performanceData.metrics[0].id
  );
  const [selectedOffer, setSelectedOffer] = useState<Offer>();
  const selectedMetric =
    metrics.find((metric) => selected === metric.id) || metrics[0];
  const getParsedData = (metric: any) => {
    let _data = metric.groups.map((item: any) => {
      const _todayStart = moment().startOf('day');
      const _yesterdayStart = moment()
        .subtract('day', 1)
        .startOf('day');
      const _dateStart = moment(item.dayStart).startOf('day');

      const tooltipLabel = _dateStart.isSame(_todayStart)
        ? 'Today'
        : _dateStart.isSame(_yesterdayStart)
        ? 'Yesterday'
        : null;

      return {
        value: item.count || 0,
        label: getParsedDate(item.dayStart),
        tooltipDate: moment(item.dayStart).format('MMMM DD, YYYY'),
        tooltipActionType: selectedMetric?.title.toLowerCase(),
        tooltipLabel,
        tooltipValue:
          metric.id === metricsOptions.PURCHASE_AMOUNT
            ? '$' + (item.count || 0)
            : item.count || 0
      } as ChartItem;
    });
    return _data;
  };
  const getParsedDate = (date: string) => {
    return moment(date).format('MM/DD');
  };
  const onMetricSelectMobile = (metric: string) => {
    const _metric = metrics.find((m) => m.id === metric);
    _metric && setSelectedOption(_metric.id);
  };

  const getMetricById = (id: string) => {
    const metric = metrics.find((m) => m.id === id);

    return metric;
  };
  const getMetricValue = (metric: any, format?: string) => {
    const value =
      metric.id === metricsOptions.PURCHASE_AMOUNT
        ? data['conversions' as any]?.amountInDateRange || 0
        : data[metric.id as any]?.countInDateRange || 0;
    return format ? format + value : value;
  };

  useEffect(() => {
    if (!offers || !offers.length) return;
    let result = offerId
      ? offers.find((item) => item.id === offerId)
      : undefined;
    setSelectedOffer(result);
  }, [offers, offerId]);

  useEffect(() => {
    if (!data || !Object.keys(data).length) return;

    let _metrics = performanceData.metrics.map((metric) => {
      const groups =
        metric.id === metricsOptions.PURCHASE_AMOUNT
          ? (data.conversions?.groupedByDays || []).map((item: any) => ({
              ...item,
              count: item.amount
            }))
          : data[metric.id]?.groupedByDays || [];

      return {
        ...metric,
        value: getMetricValue(metric),
        formattedValue:
          metric.id === metricsOptions.PURCHASE_AMOUNT
            ? getMetricValue(metric, '$')
            : getMetricValue(metric),
        groups: groups
      };
    });

    _metrics = _metrics.filter(
      (item) => item.id !== metricsOptions.PURCHASE_AMOUNT || item.value
    );
    setMetrics(_metrics);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Container className={className}>
      <StyledTitle
        title={isDisabled ? widgetTitle.DISABLED : widgetTitle.ACTIVE}
      >
        <MetaContainer>
          {!!offers.length && offerId && (
            <Fragment>
              {selectedOffer?.startedAt && (
                <DateContainer>
                  Published:{' '}
                  {moment(selectedOffer.startedAt).format('M/DD/YYYY')}
                </DateContainer>
              )}
              {selectedOffer?.endedAt && (
                <DateContainer>
                  Expires: {moment(selectedOffer.endedAt).format('M/DD/YYYY')}
                </DateContainer>
              )}
            </Fragment>
          )}
          {offerId && selectedOffer?.status && (
            <StatusContainer>
              <span>Status:</span>
              <StatusValue className={selectedOffer.status}>
                {selectedOffer.status === OfferStatus.Active
                  ? 'LIVE'
                  : selectedOffer.status}
              </StatusValue>
            </StatusContainer>
          )}
          {!!offers.length && !offerId && (
            <StatusContainer>
              <span>Status:</span>
              <StatusValue>{`${
                offers.filter((item) => item.status === OfferStatus.Active)
                  .length
              } Live Opportunities`}</StatusValue>
            </StatusContainer>
          )}
        </MetaContainer>
      </StyledTitle>
      {!!selectedMetric && (
        <>
          <Metrics>
            {metrics.map((metric) =>
              metric.id !== metricsOptions.PURCHASE_AMOUNT || metric.value ? (
                <OpportunityPerformanceMetric
                  key={metric.id}
                  isSelected={metric.id === selected}
                  onClick={() => setSelectedOption(metric.id)}
                  {...metric}
                  value={metric.formattedValue || metric.value}
                />
              ) : null
            )}
          </Metrics>
          <OpportunityMetricsSelect
            options={metrics}
            onSelect={onMetricSelectMobile}
            selectedMetric={getMetricById(selected) || metrics[0]}
          />
          <MetricDetails>
            <MetricTitle>{selectedMetric.title}</MetricTitle>
            <MetricDescription>{selectedMetric?.description}</MetricDescription>
          </MetricDetails>

          {selectedMetric.groups?.length ? (
            <OpportunityChart
              data={getParsedData(selectedMetric)}
            ></OpportunityChart>
          ) : (
            <NoItemsBlock>
              <NoItemsText>No Activity Yet</NoItemsText>
            </NoItemsBlock>
          )}
        </>
      )}
    </Container>
  );
};

export default OpportunityPerformance;
