import React, { FC, Fragment } from 'react';
import { components, MenuListComponentProps } from 'react-select';
import { DDArrow } from './styles';
import { DropDownArrow } from 'assets/svg';

export interface IOption {
  label: string;
  value: string;
}

const MenuList: FC<MenuListComponentProps<IOption>> = ({
  children,
  ...props
}) => (
  <Fragment>
    <DDArrow ><DropDownArrow /></DDArrow>
    <components.MenuList {...props}>
      {children}
    </components.MenuList>
  </Fragment>
);

export const MenuListPlain: FC<MenuListComponentProps<IOption>> = ({
  children,
  ...props
}) => (
  <Fragment>
    <components.MenuList {...props}>
      {children}
    </components.MenuList>
  </Fragment>
);

export default MenuList;
