import React, { FC } from 'react';
import { Container } from './styles';
import { Checkbox } from '../../../../uikit';

interface Props {
  className?: string;
  label?: string;
  checked?: boolean; 
  onCheck?: () => void;
  onUncheck?: () => void;
}

const CheckFilter: FC<Props> = ({
  className,
  label,
  checked = false,
  onCheck,
  onUncheck
}) => {
  return (
    <Container className={`${className} check-filter`}>
      <Checkbox text={label} name={label || ''} onCheck={onCheck} onUncheck={onUncheck} checked={checked} />
    </Container>
  );
}

export default CheckFilter;
