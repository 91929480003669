import React, { Fragment, useState, useEffect } from 'react';
import { ApolloError } from 'apollo-client';
import { useParams, useHistory } from 'react-router-dom';
import { FullScreenLoader } from 'uikit';
import { FormArea } from '../../styled';
import StandardForm from './StandardForm/StandardForm';
import IntegrationInfo from 'components/IntegrationInfo';
import routes from 'constants/routes';
import { Container, ContainerInner, StyledHeader } from '../../styled';
import {
  getIntegrationPlatforms,
  OTHER_PLATFORM,
  getOtherPlatform,
  setupInstructions
} from '../../helpers';
import { IntegrationPlatform } from '../../types';
import { StandardIntegration } from './validationSchemaStandard';
import { useGetBusinessDetailsQuery } from '../../../../graphql';
type IntegrationType = 'standard' | 'shopify';

const WebIntegrationForm: React.FC = () => {
  const { platform: routePlatform } = useParams();
  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [platform, setPlatform] = useState<IntegrationPlatform | null>();
  const [formData, setFormData] = useState<StandardIntegration | null>();
  const [formError, setFormError] = useState<ApolloError | null>();
  const history = useHistory();
  const onFormDataReceive = (integration: StandardIntegration) => {
    setFormData(integration);
  };
  const onFormDataError = (error: ApolloError) => {
    setFormError(error);
  };
  const isFormDataConnected = () => {
    return !!formData?.baseCodeUrl;
  };

  useEffect(() => {
    const id = businessProfileData?.getCurrentBusiness?.id;
    const _platform =
      routePlatform === OTHER_PLATFORM
        ? getOtherPlatform(id)
        : getIntegrationPlatforms(id).find((p) => p.link === routePlatform);
    if (_platform === undefined) {
      history.push(routes.DASHBOARD);
      return;
    }
    setPlatform(_platform);
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {platform && (
        <Fragment>
          <Container>
            {/*TODO: define scheduleCall*/}
            <ContainerInner>
              <StyledHeader
                setupInstructions={setupInstructions}
                onScheduleCall={() => {}}
              />
              <IntegrationInfo
                platform={platform}
                isConnected={isFormDataConnected()}
                connectionError={formError}
              />
            </ContainerInner>
            <FormArea>
              <StandardForm
                platform={platform}
                onDataReceive={onFormDataReceive}
                onDataError={onFormDataError}
              />
            </FormArea>
          </Container>
        </Fragment>
      )}
      {isLoading && <FullScreenLoader />}
    </Fragment>
  );
};

export default WebIntegrationForm;
