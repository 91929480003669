import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

const ServiceRoute: FC<RouteProps> = (props) => {
  return (
    <Route {...props} />
  );
};

export default ServiceRoute;
