import * as yup from 'yup';
import { urlRegExp } from 'constants/regExp';

const baseFields = {
  url: yup
  .string()
  .required('URL is required')
  .matches(
    urlRegExp,
    'Invalid URL format.'
  ),
};

const validationSchema = yup.object(baseFields);
export type FormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
