import styled from 'styled-components';
import { ITheme } from 'types';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.accentMainColor};
  width: max-content;
  padding: 3px 4px;
  border-radius: 15px;
`;

interface ToggleProps extends ITheme {
  selected: boolean;
}

export const Option = styled.button<ToggleProps>`
  padding: 4px 12px;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.white : 'transparent'};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.accentMainColor : theme.colors.white};
  border: 0;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: background-color 0.3s ease-out;
`;
