import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { ITheme } from '../../types';
import { LinkTextMixin, Body2 } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 12px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px ${({ theme }) => theme.colors.alertColor};
  margin: 14px 2px 0 2px;

  ${breakpoint('lg')`
    flex-direction: row;
    padding: 40px 20px 25px 20px;
  `}
`;

export const SupportLink = styled.a`
  ${LinkTextMixin};
`;
export const ErrorBlock = styled.div``;
export const ImageBlock = styled.div`
  text-align:center;
  ${breakpoint('xs', 'lg')`
    margin-bottom: 24px
  `}
  ${breakpoint('lg')`
    padding-top: 8px;
    margin-right: 32px;
  `}
`;
export const Paragraph = styled(Body2)`
  margin-bottom: 20px;
  ${breakpoint('lg')`
    font-size: ${({ theme }: ITheme) => theme.size.body1};
  `}
`;
