import styled from 'styled-components';
import { Body3 } from 'uikit';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 15px;
  margin: 18px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
`;
export const Caption = styled(Body3)`
  color: ${({ theme }) => theme.colors.watermark};
  font-size: 9px;
  font-style: italic;
  margin-top: 2px;
`;
export const ImageContainer = styled.div`
  margin-left: 7px;
`;
