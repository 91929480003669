import styled from 'styled-components';
import { breakpoint } from '../../../../helpers';
import {
  LinkTextMixin,
  ButtonTextMixin,
  TooltipMixin,
  OutlinedButton
} from 'uikit';
import { TableTag as Tag } from 'uikit';

export const Container = styled.div``;
export const TableContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;
export const MobileCardsContainer = styled.div`
  ${breakpoint('lg')`
    display: none;
  `}
`;

export const Row = styled.div`
  display: flex;
  height: 70px;
  border-bottom: 1px solid #ccc;

  & > div {
    &.check {
      flex: 0.035;
    }
    &.name {
      flex: 0.135;
      overflow: hidden;
      margin-right: 9px;
      padding-right: 0;
    }
    &.dateAdded {
      flex: 0.12;
    }
    &.lastContacted {
      flex: 0.11;
    }
    &.tags {
      flex: 0.1;
    }
    &.totalReward {
      flex: 0.1;
    }
    &.referrals {
      flex: 0.1;
    }
    &.totalSales {
      flex: 0.08;
    }
    &.source {
      flex: 0.1;
    }
    &.status {
      flex: 0.067;
    }
    &.options {
      flex: 0.053;
    }
  }
`;

export const HeaderRow = styled(Row)`
  height: 35px;
`;

export const DataRow = styled(Row)``;

export const Col = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.black};
  padding-right: 7px;
`;

export const HeaderCol = styled(Col)`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  align-items: center;

  &.check {
    justify-content: center;
    align-items: center;
  }
`;
export const HeaderRowMobile = styled.div`
  height: 35px;
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
`;
export const HeaderColMobile = styled.div`
  display: flex;
  height: 100%;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  align-items: center;

  &.check {
    width: 42px;
    align-items: center;
  }
  &.name {
    flex: 0.6;
  }
  &.status {
    flex: 0.4;
    margin-right: 50px;
  }
`;

export const DataCol = styled(Col)`
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;

  &.check {
    justify-content: center;
    align-items: center;
  }
  &.name {
    font-size: ${({ theme }) => theme.size.body1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: #17aacf;
    align-items: center;
  }
  &.tags {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  &.status {
    font-size: ${({ theme }) => theme.size.body2};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: #ababab;
    &.subscribed {
      color: ${({ theme }) => theme.colors.green};
    }
    &.pending {
      color: #f99700;
    }
    &.unsubscribed {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
  &.options {
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.main};
`;

export const Name = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};
`;

export const DateBlock = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body1};
`;
export const Emphasized = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.danger};

  &.margined {
    margin-right: 5px;
  }
`;
export const ArrowContainer = styled.span`
  display: inline-block;
  transform: rotate(-90deg);
  cursor: pointer;
`;

export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 500px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #c2c2c2;
`;

export const StyledTableTag = styled(Tag)``;
export const TagContainer = styled.span`
  display: inline-block;
  margin-top: 4px;
  cursor: pointer;
  &:first-of-type {
    margin-top: 0;
  }
  ${breakpoint('xs', 'lg')`
    margin-right: 2px;
  `};
`;

export const MenuButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;

  ${breakpoint('xs', 'lg')`
    margin-right: 20px;
  `};
`;

export const Dot = styled.span`
  width: 7px;
  height: 7px;
  margin-right: 4px;
  background-color: #d8d8d8;
  border-radius: 100%;

  ${breakpoint('xs', 'md')`
    width: 6px;
    height: 6px;
  `};

  ${breakpoint('md')`
    width: 7px;
    height: 7px;
  `};
`;
export const MenuContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 0;
  width: 230px;
  padding: 10px 12px;
  box-sizing: border-box;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.5);
`;

export const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: none;
  box-sizing: border-box;
  text-align: left;
  background-color: transparent;
  border-radius: 4px;
  ${ButtonTextMixin};

  :hover {
    background-color: #f2f2f2;
  }
`;

export const TooltipContent = styled.div`
  ${TooltipMixin};
`;

export const ImportButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  color: #17aacf;
  border-color: #17aacf;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  :hover,
  :active,
  :focus {
    color: #4cc2e0;
    border-color: #4cc2e0;
  }

  ${breakpoint('xs', 'lg')`
    width: 200px;
  `}
`;
