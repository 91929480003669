import styled, { css } from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';

const centredFlex = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Controls = styled.div<ITheme>`
  ${centredFlex};
`;

export const Description = styled.span`
  margin-right: 30px;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.medium};
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 12px 10px 12px 21px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: ${({ theme }: ITheme) => theme.defaultRadius};

  ${breakpoint('xs', 'lg')`
  font-size: ${({ theme }: ITheme) => theme.size.caption};
  `};
  ::placeholder {
    font-size: ${({ theme }: ITheme) => theme.size.body2};
    color: #797979;
    ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }: ITheme) => theme.size.caption};
    `};
`;

export const AddButton = styled.button`
  margin-left: 30px;
  padding: 6px 20px;
  border-radius: 17px;
  border: 0;
  background-color: #00b3ff;
  color: ${({ theme }: ITheme) => theme.colors.white};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
`;

export const TagsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: dashed 2px #cdcdcd;
`;

export const TagItem = styled.div`
  ${centredFlex};
  height: min-content;
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 10px 0px 10px;
  background-color: #05ce7c;
  border-radius: 16px;
`;

export const TagText = styled.span`
  margin: 8px 20px 8px 10px;
  color: ${({ theme }: ITheme) => theme.colors.white};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
`;

export const RemoveButton = styled.button`
  padding: 3px 6px;
  font-size: 10px;
  background: transparent;
  color: ${({ theme }: ITheme) => theme.colors.white};
  border-color: ${({ theme }: ITheme) => theme.colors.white};
  border-radius: 100%;
  border: 1px solid;
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
`;
