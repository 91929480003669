import React, { FC } from 'react';
import { CommissionType } from '../../graphql';
import {
  Container,
  ImageContainer,
  CoverImage,
  ImageSubstitute,
  Content,
  ContentLeft,
  ContentRight,
  TitleContainer,
  SmallImageContainer,
  SmallImage,
  SmallImageSubstitute,
  Title,
  Text,
  AmountContent,
  AmountContentText,
  Amount
} from './styles';

interface Props {
  className?: string;
  cover?: string;
  businessAvatar?: string;
  businessName?: string;
  title: string;
  amountType: CommissionType | string;
  amount: string | number;
}

const OpportunityItem: FC<Props> = ({
  className,
  cover,
  businessAvatar,
  businessName,
  title,
  amountType,
  amount
}) => (
  <Container className={className}>
    <ImageContainer>
      {cover ? (
        <CoverImage src={cover} alt="li" />
      ) : (
        <ImageSubstitute>Photo</ImageSubstitute>
      )}
    </ImageContainer>
    <Content>
      <ContentLeft>
        <TitleContainer>
          <SmallImageContainer>
            {businessAvatar ? (
              <SmallImage
                src={businessAvatar}
                alt="li-small"
              />
            ) : (
              <SmallImageSubstitute>Logo</SmallImageSubstitute>
            )}
          </SmallImageContainer>
          <Title>{businessName}</Title>
        </TitleContainer>
        <Text>{title}</Text>
      </ContentLeft>
      <ContentRight>
        <AmountContent>
          <AmountContentText>Earn</AmountContentText>
          <Amount>
            {amountType !== CommissionType.Percentage && '$'}
            {amount}
            {amountType === CommissionType.Percentage && '%'}
          </Amount>
        </AmountContent>
      </ContentRight>
    </Content>
  </Container>
);
export default OpportunityItem;
