import React, { FC, useState } from 'react';
import useCollapse from 'react-collapsed';
import { Checkbox } from 'uikit';
import { Overlay } from 'uikit/Loader/styles';
import OutsideClickHandler from 'react-outside-click-handler';
import { Plus, Minus } from 'assets/svg';
import { IMenuOption } from '../../types';
import { getSource, getStatus } from '../../helpers';
import { CustomerTag, Customer, Maybe } from '../../../../../../graphql';
import moment from 'moment';
import DATE_FORMAT from 'constants/dateFormat';
import {
  Container,
  TopContainer,
  Row,
  CheckBlock,
  NameBlock,
  StatusBlock,
  IconBlock,
  Block,
  BlockTitle,
  BlockValue,
  MenuContainer,
  TagsContainer
} from './styles';
import {
  StyledTableTag,
  TagContainer,
  MenuButton,
  StyledMenuButton,
  Dot
} from '../../styles';

interface Props {
  data: Customer;
  expanded?: boolean;
  selected?: boolean;
  menuOpened?: boolean;
  menuOptions: IMenuOption[];
  onSelect: (id: string) => void;
  onDeselect: (id: string) => void;
  onMenuOpen: (id: string) => void;
  onMenuClose: (id: string) => void;
  onTagClick: (id: string) => void;
}

const CustomerCollapseItem: FC<Props> = ({
  data,
  expanded = false,
  selected = false,
  menuOpened = false,
  menuOptions,
  onSelect,
  onDeselect,
  onMenuOpen,
  onMenuClose,
  onTagClick
}) => {
  const [isExpanded, setExpanded] = useState(!!expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleMenuToggle = () => {
    menuOpened ? onMenuClose(data.id) : onMenuOpen(data.id);
  };
  return (
    <Container>
      <TopContainer
        expanded={isExpanded}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          }
        })}
      >
        <CheckBlock className={'check'}>
          <Checkbox
            onCheck={() => onSelect(data.id)}
            onUncheck={() => onDeselect(data.id)}
            checked={selected}
          />
        </CheckBlock>
        <NameBlock>{`${data.firstName} ${data.lastName}`}</NameBlock>
        <StatusBlock className={'status ' + (data.status || '').toLowerCase()}>
          {getStatus(data.status || '')}
        </StatusBlock>
        <IconBlock>{!isExpanded ? <Plus /> : <Minus />}</IconBlock>
      </TopContainer>
      <div {...getCollapseProps()}>
        <Row>
          <Block className="dateAdded">
            <BlockTitle>Date Added</BlockTitle>
            <BlockValue className="dateAdded">
              {moment(data.createdAt).format(DATE_FORMAT)}
            </BlockValue>
          </Block>
          <Block className="lastContacted">
            <BlockTitle>Last Contacted</BlockTitle>
            {!!data.lastContacted && (
              <>
                <BlockValue className="lastContacted">
                  {moment(data.lastContacted).format(DATE_FORMAT)}
                </BlockValue>
                <BlockValue className="lastContacted">
                  {moment(data.lastContacted).format('LT')}
                </BlockValue>
              </>
            )}
          </Block>
          <Block className="tags">
            <BlockTitle>Tags</BlockTitle>
            <TagsContainer>
              {(data.tags || []).map((tag: Maybe<CustomerTag>, index) => (
                <>
                  {index < 2 && (
                    <TagContainer
                      key={tag?.name || ''}
                      onClick={() => {
                        onTagClick(data.id);
                      }}
                    >
                      <StyledTableTag name={tag?.name || ''} />
                    </TagContainer>
                  )}
                </>
              ))}
              {(data.tags || []).length > 2 && (
                <TagContainer>
                  <StyledTableTag
                    name={'+' + ((data.tags || []).length - 2).toString()}
                  />
                </TagContainer>
              )}
            </TagsContainer>
          </Block>
          <Block className="referrals">
            <BlockTitle>Referrals</BlockTitle>
            <BlockValue className="referrals">{data.referrals || 0}</BlockValue>
          </Block>
        </Row>
        <Row>
          <Block className="totalReward">
            <BlockTitle>Total Reward</BlockTitle>
            <BlockValue className="totalReward">
              ${data.totalReward || 0}
            </BlockValue>
          </Block>
          <Block className="totalSales">
            <BlockTitle>Total Sales</BlockTitle>
            <BlockValue className="totalSales">
              ${data.totalSales || 0}
            </BlockValue>
          </Block>
          <Block className="Source">
            <BlockTitle>Source</BlockTitle>
            <BlockValue className="Source">
              {getSource(data.source || '')}
            </BlockValue>
          </Block>
          <Block className="options" onClick={handleMenuToggle}>
            <MenuButton type="button">
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <Dot key={index} />
                ))}
            </MenuButton>
            {menuOpened && (
              <>
                <Overlay />
                <MenuContainer>
                  <ul>
                    <OutsideClickHandler
                      onOutsideClick={() => onMenuClose(data.id)}
                    >
                      {menuOptions.map(({ name, onClick }) => (
                        <StyledMenuButton
                          key={name}
                          onClick={() => onClick(data.id)}
                          type="button"
                        >
                          {name}
                        </StyledMenuButton>
                      ))}
                    </OutsideClickHandler>
                  </ul>
                </MenuContainer>
              </>
            )}
          </Block>
        </Row>
      </div>
    </Container>
  );
};

export default CustomerCollapseItem;
