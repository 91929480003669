import React, { FC } from 'react';
import { Container, StepsContainer, Step, StepNumber, StepName } from './styles';

export interface Step {
  step: number,
  caption: string
}

interface Props {
  steps: Step[],
  className?: string;
  currentStep?: number;
  onStepClick?: (index: number) => void;
}

const CreationProgress: FC<Props> = ({ steps, className, onStepClick, currentStep = 1 }) => (
  <Container className={className}>
    <StepsContainer>
      {steps.map((item) => (
        <Step key={'step-' + item.step} onClick={() => {onStepClick && onStepClick(item.step)}}>
          <StepNumber className={item.step === currentStep? 'active' : ''}>{item.step}</StepNumber>
          <StepName className={item.step === currentStep? 'active' : ''}>{item.caption}</StepName>
        </Step>  
      ))}
    </StepsContainer>  
  </Container>
);

export default CreationProgress;
