import styled from 'styled-components';
import { Body2, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  text-align: left;
`;
export const Caption = styled(Body2)`
font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
export const Paragraph = styled(Body2)`
  margin-bottom: 25px;
`;
export const StyledLink = styled.a`
	${LinkTextMixin};
	font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.accentSecondaryColor};
  cursor: pointer;
`;
