import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { Tick } from '../../assets/svg';
import { ITheme } from '../../types';

export const DesktopTable = styled.table`
  ${breakpoint('md')`
    width: 100%;
  `}

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;

export const Tr = styled.tr`
  border-top: solid 1px #cecece;
  border-bottom: solid 1px #cecece;

  ${breakpoint('md')`
    height: 70px;
  `}
`;

export const Td = styled.td`
  ${breakpoint('md')`
    vertical-align: middle;
  `}

  ${breakpoint('xs', 'md')`
    vertical-align: top;
    padding-top: 18px;
    padding-bottom: 18px;
  `}
`;

interface CheckMarkProps extends ITheme {
  isActive: boolean;
}

export const CheckMark = styled.div<CheckMarkProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: ${({ isActive }) => (isActive ? 'none' : 'solid 1px #c1c1c1')};
  background-color: ${({ isActive, theme }) =>
    isActive ? '#00ce7c' : theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledTick = styled(Tick)`
  width: 16px;
  height: 11px;
`;

export const CheckingMessage = styled.span`
  margin-left: 16px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${breakpoint('xs', 'md')`
    font-size: ${({ theme }) => theme.size.body2};
    line-height: 1.42;
  `}

  ${breakpoint('md')`
    font-size: ${({ theme }) => theme.size.body1};
    line-height: 1.15;
  `}
`;

export const UserData = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
`;

export const ConnectionStatusConnected = styled(Text)`
  color: ${({ theme }) => theme.colors.success};
`;

export const ConnectionStatusDisconnected = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const MobileTable = styled.table`
  ${breakpoint('md')`
    display: none;
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
`;

export const BillingStatusTd = styled(Td)`
  padding-right: 10px;
`;

export const BankIcon = styled.img`
  max-width: 30px;
  height: auto;

  /* Override global style */
  vertical-align: top;
`;

export const BankNameTd = styled(Td)`
  padding-left: 20px;
  padding-right: 10px;
`;

export const BankName = styled(Text)`
  display: inline-block;
  max-width: 90px;
  color: ${({ theme }) => theme.colors.black};
`;

export const BillingInfoTd = styled(Td)`
  text-align: right;
`;

export const SelectPaymentButton = styled.button`
  display: inline-block;
  margin-top: 15px;
  padding: 0;
  border: none;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.main};
  background-color: transparent;
`;
