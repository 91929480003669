import styled, { css } from 'styled-components';
import SearchInput from 'components/SearchInput';
import { breakpoint } from 'helpers';
import { WidgetContainerMixin } from 'uikit';
// TODO: apply ui kit styling
const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.section`
  ${flexColumn};
  ${WidgetContainerMixin};
  padding: 20px 24px 20px 36px;
  background-color: ${({ theme }) => theme.colors.white};

  ${breakpoint('xs', 'md')`
    padding: 20px;
  `};
`;

export const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 30px;

  ${breakpoint('md')`
    width: 320px;
    margin-left: auto;
  `}
`;

export const PaginationContainer = styled.div`
  ${breakpoint('md')`
    margin-top: 60px;
    border-top: solid 3px #dbdbdb;
  `};
`;

export const EmptyListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;

  ${breakpoint('lg')`
    padding: 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px;
  `}
`;
