import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DATE_FORMAT from 'constants/dateFormat';
import { mobileColumns } from './constants';
import {
  Container,
  TableContainer,
  MobileTableContainer,
  MobileColSwitchContainer,
  MobileSwitchArrowContainer,
  MobileColControls,
  MCC,
  Row,
  HeaderRow,
  Col,
  HeaderCol,
  Name,
  Emphasized,
  DateBlock,
  ArrowContainer,
  NoItemsBlock,
  NoItemsText
} from './styles';
import { formatCurrency } from 'helpers';
import { DropDownArrow } from 'assets/svg';
import { V15PayoutGroup, PayoutGroupType, Meta } from '../../../../graphql';
import routes from 'constants/routes';

export interface HistoryItem {
  id: number | string;
  name: string;
  startDate: string;
  endDate: string;
  rewards: number;
  fees: number;
  charges: number;
  totalCharge: number;
  pending: boolean;
  emphasized?: boolean;
}

interface Props {
  data?: V15PayoutGroup[];
  meta?: Meta | null;
}

const getKeyValue = <U extends keyof T, T extends any>(key: U) => (obj: T) =>
  obj[key];

const BillingHistoryTable: FC<Props> = ({ data = [], meta }) => {
  const history = useHistory();

  const [currentMobileColumn, setCurrentMobileColumn] = useState<number>(3);

  const setColIndex = (idx: number) => {
    if(idx < 0) {
      setCurrentMobileColumn(mobileColumns.length - 1);
    } else if(idx > mobileColumns.length - 1) {
      setCurrentMobileColumn(0);
    } else {
      setCurrentMobileColumn(idx);
    }
  } 
  const switchColRight = () => {
    setColIndex(currentMobileColumn + 1);
  } 
  const switchColLeft = () => {
    setColIndex(currentMobileColumn - 1);
  } 

  const onArrowClick = (item: V15PayoutGroup) => {
    const { pending, dateRange, payoutGroupType } = item;
    if (!dateRange || !payoutGroupType) return;
    history.push({
      pathname: routes.BILLING_HISTORY_DETAILED,
      search: `?from=${dateRange.from}&to=${dateRange.to}&pending=${pending}&payoutGroupType=${payoutGroupType}`
    });
  };

  return (
    <Container>
      <TableContainer>
        <HeaderRow>
          <HeaderCol>Billing Period</HeaderCol>
          <HeaderCol>Rewards Issued</HeaderCol>
          <HeaderCol>Platform Fees</HeaderCol>
          <HeaderCol>Reward Charges</HeaderCol>
          <HeaderCol>Total Charge</HeaderCol>
          <HeaderCol></HeaderCol>
        </HeaderRow>
        {data.length ? (
          data.map((item) => (
            <Row>
              <Col>
                <Name>
                  {item.pending && (
                    <Emphasized className="margined">PENDING: </Emphasized>
                  )}
                  {item.payoutGroupType === PayoutGroupType.MonthlyGroup ? (
                    !item.pending ? (
                      <Emphasized>Monthly Drum Referral Fees</Emphasized>
                    ) : (
                      'Monthly Drum Referral Fees'
                    )
                  ) : (
                    'Weekly Rewards to referrers'
                  )}
                </Name>
                <DateBlock>
                  {item.dateRange?.from && (
                    <>
                      {moment(item.dateRange?.from).format(DATE_FORMAT)} -{' '}
                      {item.pending ? (
                        <>TODAY {moment().format(DATE_FORMAT)}</>
                      ) : (
                        moment(item.dateRange?.to).format(DATE_FORMAT)
                      )}
                    </>
                  )}
                </DateBlock>
              </Col>
              <Col className={item.pending ? 'pending' : ''}>
                {item.payoutsNum}
              </Col>
              <Col className={item.pending ? 'pending' : ''}>
                {formatCurrency(item.totalDrumFee)}
              </Col>
              <Col className={item.pending ? 'pending' : ''}>
                {formatCurrency(item.totalDrummerReward)}
              </Col>
              <Col className={item.pending ? 'pending' : ''}>
                {formatCurrency(item.totalAmount)}
              </Col>
              <Col>
                <ArrowContainer onClick={() => onArrowClick(item)}>
                  <DropDownArrow />
                </ArrowContainer>
              </Col>
            </Row>
          ))
        ) : (
          <NoItemsBlock>
            <NoItemsText>No Activity Yet</NoItemsText>
          </NoItemsBlock>
        )}
      </TableContainer>

      <MobileTableContainer>
        <HeaderRow>
          <HeaderCol>Billing Period</HeaderCol>
          <HeaderCol className="switched-column">
            <MobileColSwitchContainer>
              <MobileSwitchArrowContainer
                className="left"
              >
                <DropDownArrow className="mobile-arrow" />
              </MobileSwitchArrowContainer>
              <span>{mobileColumns[currentMobileColumn].title}</span>
              <MobileSwitchArrowContainer
                className="right"
              >
                <DropDownArrow className="mobile-arrow" />
              </MobileSwitchArrowContainer>

              <MobileColControls>
                <MCC onClick={switchColLeft}/>
                <MCC onClick={switchColRight}/>
              </MobileColControls>  

            </MobileColSwitchContainer>
          </HeaderCol>
          <HeaderCol></HeaderCol>
        </HeaderRow>
        {data.length ? (
          data.map((item) => (
            <Row>
              <Col>
                <Name>
                  {item.pending && (
                    <Emphasized className="margined">PENDING: </Emphasized>
                  )}
                  {item.payoutGroupType === PayoutGroupType.MonthlyGroup ? (
                    !item.pending ? (
                      <Emphasized>Monthly Drum Referral Fees</Emphasized>
                    ) : (
                      'Monthly Drum Referral Fees'
                    )
                  ) : (
                    'Weekly Rewards to referrers'
                  )}
                </Name>
                <DateBlock>
                  {item.dateRange?.from && (
                    <>
                      {moment(item.dateRange?.from).format(DATE_FORMAT)} -{' '}
                      {item.pending ? (
                        <>TODAY {moment().format(DATE_FORMAT)}</>
                      ) : (
                        moment(item.dateRange?.to).format(DATE_FORMAT)
                      )}
                    </>
                  )}
                </DateBlock>
              </Col>
              <Col className={(item.pending ? 'pending' : '') + ' switched-column'}>
                {mobileColumns[currentMobileColumn].field === 'totalDrumFee' ||
                mobileColumns[currentMobileColumn].field ===
                  'totalDrummerReward' ||
                mobileColumns[currentMobileColumn].field === 'totalAmount' ? (
                  <>
                    {formatCurrency(
                      getKeyValue<keyof V15PayoutGroup, V15PayoutGroup>(
                        mobileColumns[
                          currentMobileColumn
                        ].field.toString() as keyof V15PayoutGroup
                      )(item)
                    )}
                  </>
                ) : (
                  <>
                    {getKeyValue<keyof V15PayoutGroup, V15PayoutGroup>(
                      mobileColumns[
                        currentMobileColumn
                      ].field.toString() as keyof V15PayoutGroup
                    )(item)}
                  </>
                )}
              </Col>
              <Col>
                <ArrowContainer onClick={() => onArrowClick(item)}>
                  <DropDownArrow />
                </ArrowContainer>
              </Col>
            </Row>
          ))
        ) : (
          <NoItemsBlock>
            <NoItemsText>No Activity Yet</NoItemsText>
          </NoItemsBlock>
        )}
      </MobileTableContainer>
    </Container>
  );
};

export default BillingHistoryTable;
