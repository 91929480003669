import styled from 'styled-components';
import { Body1, H2 } from 'uikit';

export const Container = styled.div`
  text-align: center;
`;

export const PageTitle = styled(H2)`
  line-height: 46px;
  height: 46px;
  margin: 35px 0 18px 0;
`;
export const DescriptionText = styled(Body1)`
  text-align: initial;
  margin-bottom: 32px;
`;