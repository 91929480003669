import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Tab, Tabs, TabList, TabPanel } from 'uikit';
import { sendEmailTabClickTrackingEvent, sendReferralWebpageTabClickTrackingEvent } from 'helpers/segment';
import DashboardPageLayout from 'layouts/DashboardPageLayout';
import CustomersList from 'components/CustomersList';
import EmailsList from 'components/EmailsList';
import ReferralReward from 'components/ReferralReward';
import {
  Container,
  HeadingContainer,
  StyledLink
} from './styles';
import { CUSTOMER_TAB_NAME_PARAM, CUSTOMER_TAB_NAME_CUSTOMERS_LIST, CUSTOMER_TAB_NAME_EMAILS_LIST, CUSTOMER_TAB_NAME_REFERRAL_PAGE } from 'constants/customersQueryParams';

const CustomersManagementPage: FC<RouteComponentProps> = (props) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  })
  const params = queryString.parse(props.location.search);
  const currentTab = params[CUSTOMER_TAB_NAME_PARAM];

  const getIndexByTabName = (name: string) => {
    switch(name) {
      case CUSTOMER_TAB_NAME_EMAILS_LIST:
        return 1;
      case CUSTOMER_TAB_NAME_REFERRAL_PAGE:   
        return 2;
      default: 
        return 0;
    }
  }
  const getTabNameByIndex = (index: number) => {
    switch(index) {
      case 1:
        return CUSTOMER_TAB_NAME_EMAILS_LIST;
      case 2:   
        return CUSTOMER_TAB_NAME_REFERRAL_PAGE;
      default: 
        return CUSTOMER_TAB_NAME_CUSTOMERS_LIST;
    }
  }
  
  const [tabIndex, setTabIndex] = useState<number>(getIndexByTabName(currentTab?.toString() || ''));

  const setTab = (index: number) => {
    props.history.push({
      pathname: props.location.pathname,
      search: `${CUSTOMER_TAB_NAME_PARAM}=${getTabNameByIndex(index)}`
    });
    setTabIndex(index);

    if(getTabNameByIndex(index) === CUSTOMER_TAB_NAME_EMAILS_LIST) {
      sendEmailTabClickTrackingEvent();
    }
    else if(getTabNameByIndex(index) === CUSTOMER_TAB_NAME_REFERRAL_PAGE) {
      sendReferralWebpageTabClickTrackingEvent();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    })
  }
  const onCustomersRedirect = () => {
    setTab(0);
  }

  return (
    <DashboardPageLayout>
      <HeadingContainer>
        <p>
          Tell your existing customers about your referral reward opportunity. 
        </p>
        <p>
          <StyledLink href=""> Import your customer list</StyledLink> and invite them <StyledLink href="">via email</StyledLink> to earn money promoting your opportunities. 
        </p>
      </HeadingContainer>  
      <Container>
        <Tabs selectedIndex={tabIndex} onSelect={setTab}>
          <TabList>
            <Tab>Customer List</Tab>
            <Tab>Email</Tab>
            <Tab>Referral Webpage</Tab>
          </TabList>
          <TabPanel>
            <CustomersList></CustomersList>  
          </TabPanel>
          <TabPanel>
            <EmailsList onCustomerRedirect={onCustomersRedirect}></EmailsList>
          </TabPanel>
          <TabPanel>
            <ReferralReward></ReferralReward>  
          </TabPanel>
        </Tabs>

      </Container>
    </DashboardPageLayout>
  );
};

export default CustomersManagementPage;
