import React from "react"
import { ReferralPageStateConfigPageStyleBackgroundType } from "../../graphql"
import { PageFormType, SendEmailFormType } from "./validationSchema"
import { theme } from 'styles/theme';
import { Facebook, Twitter, WhatsApp } from 'assets/svg';

export enum socialMedias {
	facebook,
	twitter,
	whatsapp,
	scout,
}

export const shareSocialMediaItems = [
	{
		id: socialMedias.facebook,
		name: "Facebook",
		icon: React.createElement(Facebook)
	},
	{
		id: socialMedias.twitter,
		name: "Twitter",
		icon: React.createElement(Twitter)
	},
	{
		id: socialMedias.whatsapp,
		name: "WhatsApp",
		icon: React.createElement(WhatsApp)
	},
	// {
	// 	id: socialMedias.scout,
	// 	name: "Scout",
	// 	icon: Scout
	// },
]

export const getDefaultValues = ():PageFormType => {
	return {
		url: '',
		states: {
			join: {
				config: {
					title: "Refer a Friend. They save money and you earn rewards.",
					body: "Help your friends get a great deal. When you refer a friend they save money and you earn rewards.",
				}
			},
			share: {
				config: {
					title: "Refer a Friend. They save money and you earn rewards.",
					body: "Help your friends get a great deal. When you refer a friend they save money and you earn rewards.",
				}
			},
			thankYou: {
				config: {
					title: "Thank you for sharing!",
					body: "Thank you for sharing our offer with your friends. We greatly appreciate your business.\n\nYou will receive your referral reward after your friend makes a purchase on our website using the link you sent them.",
				}
			},
		},
		pageStyle: {
			buttonColor: theme.colors.gold,
			textColor: '#fff',
			cornersRadius: 21,
			fontName: "Raleway",
			background: {
				backgroundType: ReferralPageStateConfigPageStyleBackgroundType.Image,
				backgroundImage: '/assets/preview-1.png',
				backgroundColor: '#ededed',
			}
		},
		shopifyPageConfig: null,
		pageType: "join"
	}
}

export const getDefaultEmailValues = (businessName: string, offerName: string):SendEmailFormType => {
	return {
		to: '',
		from: 'johnsmith@yahoo.com',
		message: `Check out this great offer from ${businessName}\n${offerName}`,
		sendLater: true
	}
}