import React, { FC } from 'react';
import IconProps from './IconProps';

const Arrow: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="12px"
    height="11px"
    viewBox="0 0 213.333 213.333"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <polygon points="0,53.333 106.667,160 213.333,53.333" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default Arrow;
