const apiUrl = process.env.REACT_APP_AWS_COGNITO_API_URL_ALT;

interface IData {
  exists?: string
  provider?: string
  roles?: string[]
  providers?: string[]
  enabled?: string | boolean
  everified?: string | boolean
}

export const fetchAuthData = (email: string, callback: (data: IData) => void, errorCallback?: () => void) => {
  fetch(
    `${apiUrl}/noauth/ei?a=${email}&s=d4b8289622bf5ca6c0935f73f32510`
  ).then(response => {
    return response.json();
  }).then(data => {
    callback(data);  
  }).catch(() => {
    errorCallback && errorCallback();
  })
}