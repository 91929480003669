import { theme } from 'styles/theme';
import { getInitialValues } from '../../constants';

const initialValues = getInitialValues();

export const getBaseConfig = (businessName: string | null = 'Business name') => ({
  ...getInitialValues(businessName),
  businessAvatar: '',
  listItems: [],
  listCaption: 'Reward Opportunities',
  buttonText: 'Join our referral program',
  showShadow: false,
  earnRewardsLink: '',
  buttonLink: ''
});

export const getTemplateConfigs = (businessName: string | null = 'Business name') => ({
  invitationEmail: {
    alt: {
      ...getBaseConfig(businessName),
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.white
      },
    },
    default: {
      ...getBaseConfig(businessName),
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.backgroundColor
      },
      showShadow: true
    }
  },
  reminderEmail: {
    alt: {
      ...getBaseConfig(businessName),
      buttonText: 'Refer a Friend',
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.white
      },
    },
    default: {
      ...getBaseConfig(businessName),
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.backgroundColor
      },
      buttonText: 'Refer a Friend',
      showShadow: true
    }
  },
  newOpportunityEmail: {
    alt: {
      ...getBaseConfig(businessName),
      buttonText: 'Refer a Friend',
      listCaption: 'New reward opportunity!',
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.white
      },
    },
    default: {
      ...getBaseConfig(businessName),
      emailStyle: {
        ...initialValues.emailStyle,
        backgroundColor: theme.colors.backgroundColor
      },
      buttonText: 'Refer a Friend',
      listCaption: 'New reward opportunity!',
      showShadow: true
    }
  }
});
export const MAX_OPPORTUNITIES_IN_EMAIL = 2;
