import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 2px solid  rgba(206, 206, 206, 0.5);
  height: 50px;
`;

export const ViewAllLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  color: ${({ theme }) => theme.colors.accentMainColor};
  background-color: ${({ theme }) => theme.colors.white};
`;
