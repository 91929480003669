import React, { FC, useState, useEffect } from 'react';
import { ApolloError } from 'apollo-boost';
import { useLocation } from 'react-router-dom';

import { Formik, FormikErrors } from 'formik';
import queryString from 'query-string';
import CreationProgress from 'components/CreationProgress';
import PageHeader from 'components/PageHeader';
import OpportunityStatus from 'components/OpportunityStatus';
import { normalizeOpportunityFormValues } from 'helpers';
import { sendDeleteOfferCreationProgressTrackingEvent } from 'helpers/segment';
import { AccordionCollapse, Body1, Modal } from 'uikit';
import {
  OPPORTUNITY_TEMPLATE_PARAM,
  STEPS,
  OPPORTUNITY_SETUP_GUIDE_URL,
  getTemplates
} from 'constants/createOpportunityParams';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  REDIRECT_PROMPT_TITLE,
  REDIRECT_PROMPT_TEXT,
  REDIRECT_PROMPT_SAVE_DRAFT,
  REDIRECT_PROMPT_OK
} from 'constants/promptText';
import {
  CARD_NO_PAYPAL,
  CARD_AND_PAYPAL,
  DEFAULT_DELETION_TITLE
} from 'constants/creditCardDeletionModalTitles';
import routes from 'constants/routes';
import {
  Container,
  OptionalFeaturesTitle,
  PreviewButton,
  ProgressContainer,
  AccordionContainer,
  OpportunityStatusContainer,
  StatusCaption,
  ViewExamplesLink,
  OptionalFeaturesContainer
} from './styled';
import {
  OpportunityDetails,
  ReferralReward,
  ReferrerEducation,
  OpportunityDates,
  FormFooter,
  OpportunityPreviewModal
} from './components';
import validationSchema, { CreateOpportunityType } from './validationSchema';
import {
  GetUserIntegrationQuery,
  GetOfferQuery,
  useGetBusinessDetailsQuery,
  ShopifyChargeStatus,
  Payment,
  Offer,
  PaymentProvider
} from '../../graphql';
import { default as getInitialValues, shouldShowOptionalParams } from './utils';
import { isShopifyWithDefaultCreditCard } from 'helpers/billing';
import { getBlankFormValue } from './constants';
import GeneralLayout from 'layouts/GeneralLayout';
import OpportunityPreview from 'components/OpportunityPreview';
import SaveOpportunityErrorModal from 'components/SaveOpportunityErrorModal';
import CreditCardDeletionModal from 'components/CreditCardDeletionModal';
import { Eye } from 'assets/svg';

interface Props {
  error: null | ApolloError;
  integrationInfo: GetUserIntegrationQuery | undefined;
  title: string;
  values?: GetOfferQuery | undefined;
  isSubmissionSucceeded: boolean | null;
  isEdit?: boolean;
  onCloseErrorModal: () => void;
  onPublish: (values: CreateOpportunityType) => void;
  onSaveDraft: (values: CreateOpportunityType) => Promise<void>;
  onStepClick?: (index: number) => void;
}

const accordionBaseConfig = {
  isExpanded: false,
  activeOnOpen: true
};

const CreateOpportunityForm: FC<Props> = ({
  error,
  integrationInfo,
  title,
  values: preFilledValues,
  isSubmissionSucceeded,
  isEdit,
  onCloseErrorModal,
  onPublish,
  onSaveDraft,
  onStepClick
}) => {
  const location = useLocation();

  const {
    data: businessProfileData,
    refetch: refetchBusinessProfileData
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  const TEMPLATES = getTemplates(
    businessProfileData?.getCurrentBusiness?.businessName || ''
  );

  const params = queryString.parse(location.search);
  const templateNumber = params[OPPORTUNITY_TEMPLATE_PARAM];
  const templateValues = templateNumber
    ? TEMPLATES[Number(templateNumber)]
    : undefined;

  // TODO: make @client field for derived data
  const pendingCharge = businessProfileData?.getCurrentBusiness?.notAcceptedCharges?.notAcceptedCharges?.find(
    (charge) =>
      !!charge?.confirmation_url &&
      charge?.status === ShopifyChargeStatus.Pending
  );
  const pendingChargeUrl = pendingCharge?.confirmation_url;
  const shopifyWithCreditCards = isShopifyWithDefaultCreditCard(
    businessProfileData?.getCurrentBusiness?.integrationType,
    (businessProfileData?.getCurrentBusiness?.payments as Payment[]) || []
  );

  const [isShopifyBillingModalShown, setShopifyBillingModal] = useState(false);
  const [checkCardDeletionModal, setCheckCardDeletionModal] = useState<boolean>(
    false
  );
  const [cardDeletionModalTitle, setCardDeletionModalTitle] = useState<string>(
    DEFAULT_DELETION_TITLE
  );
  const [
    cardDeletionSuccessCallback,
    setCardDeletionSuccessCallback
  ] = useState<(payments?: Payment[]) => void>(() => {});

  const [initialValues, setInitialValues] = useState<CreateOpportunityType>(
    getBlankFormValue(businessProfileData, templateValues)
  );
  const [optionalFeaturesAreShown, setOptionalFeaturesAreShown] = useState<
    boolean
  >(false);

  useEffect(() => {
    if (preFilledValues && businessProfileData) {
      const updatedInitialValues = getInitialValues(
        preFilledValues,
        businessProfileData
      );
      setInitialValues(updatedInitialValues);
      if (isEdit) {
        setOptionalFeaturesAreShown(
          shouldShowOptionalParams(updatedInitialValues)
        );
      }
    }
    // eslint-disable-next-line
  }, [preFilledValues, businessProfileData]);

  const [isValidationErrorModalOpen, setValidationErrorModal] = useState(false);
  const [isPublishModalOpen, setPublishModal] = useState(false);
  const [isSaveDraftModalOpen, setSaveDraftModal] = useState(false);
  const [isMobilePreviewOpen, setMobilePreview] = useState(false);
  const [errorAccordionsOpen, setErrorAccordionsOpen] = useState(false);

  const handleClickPublish = (
    validateForm: (
      values?: CreateOpportunityType
    ) => Promise<FormikErrors<CreateOpportunityType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: CreateOpportunityType
  ) => () => {
    setSubmitting(true);

    const validationCallback = (
      callback?: () => void,
      errorCallback?: () => void
    ) => {
      validateForm(values).then((errors) => {
        const errorList = Object.values(errors);
        const hasErrors = !!errorList.length;

        setSubmitting(false);
        if (hasErrors) {
          errorCallback ? errorCallback() : setValidationErrorModal(true);
        } else {
          callback ? callback() : setPublishModal(true);
        }
      });
    };

    if (pendingChargeUrl) {
      setShopifyBillingModal(true);
      setSubmitting(false);
    } else if (shopifyWithCreditCards) {
      setCardDeletionSuccessCallback((payments: Payment[] = []) => () => {
        refetchBusinessProfileData();
        setPublishModal(true);
      });

      validationCallback(() => {
        const hasPaypal = businessProfileData?.getCurrentBusiness?.payments?.find(
          (item) => item?.provider === PaymentProvider.Paypal
        );
        if (hasPaypal) {
          setCardDeletionModalTitle(CARD_AND_PAYPAL);
        } else {
          setCardDeletionModalTitle(CARD_NO_PAYPAL);
        }

        setCheckCardDeletionModal(true);
      });
    } else {
      validationCallback();
    }
  };

  const onCreditCardDeletionSuccess = (payments?: Payment[]) => {
    setCheckCardDeletionModal(false);
    cardDeletionSuccessCallback(payments);
    setCardDeletionSuccessCallback(() => {});
  };
  const onCreditCardDeletionModalClose = () => {
    setCheckCardDeletionModal(false);
  };

  const handleEnableShopifyBilling = async (values: CreateOpportunityType) => {
    await onSaveDraft(values);

    setShopifyBillingModal(false);

    window.location.href = pendingChargeUrl as string;
  };

  const toggleOptionalFeaturesAreShown = (
    errors: FormikErrors<CreateOpportunityType>
  ) => {
    if (
      !Object.keys(errors.referrerEducation || {}).length &&
      !Object.keys(errors.opportunityDates || {}).length
    ) {
      setOptionalFeaturesAreShown(!optionalFeaturesAreShown);
    } else {
      setOptionalFeaturesAreShown(true);
    }
  };

  const onMissingFieldsClick = () => {
    setValidationErrorModal(false);
    setErrorAccordionsOpen(true);
    setTimeout(() => {
      setErrorAccordionsOpen(false);
    })
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={onPublish}
    >
      {({
        dirty,
        handleSubmit,
        values,
        validateForm,
        setSubmitting,
        errors
      }) => (
        <GeneralLayout
          sidebar={<OpportunityPreview showDescription values={values} />}
        >
          <form onSubmit={handleSubmit}>
            <Container>
              <PageHeader
                title={title}
                link={{
                  text:
                    isEdit || preFilledValues
                      ? 'Back to Opportunities'
                      : 'Back to Templates',
                  to:
                    isEdit || preFilledValues
                      ? routes.MY_OPPORTUNITIES
                      : routes.CREATE_OPPORTUNITY
                }}
              >
                {preFilledValues?.getOffer?.status ? (
                  <OpportunityStatusContainer>
                    <StatusCaption>Status:</StatusCaption>
                    <OpportunityStatus
                      status={preFilledValues.getOffer.status}
                    />
                  </OpportunityStatusContainer>
                ) : (
                  <ProgressContainer>
                    <CreationProgress
                      steps={STEPS}
                      currentStep={2}
                      onStepClick={(index) => {
                        onStepClick && onStepClick(index);
                      }}
                    />
                  </ProgressContainer>
                )}
              </PageHeader>
              <Body1>
                An opportunity is a landing page that you create below that
                describes your product, service, or special discount. Referrers
                share your landing page with their network and drive customers
                to your website to complete a transaction.{' '}
                <ViewExamplesLink
                  href={OPPORTUNITY_SETUP_GUIDE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View example opportunities
                </ViewExamplesLink>
              </Body1>

              <AccordionContainer>
                <AccordionCollapse
                  {...accordionBaseConfig}
                  title="Opportunity Details"
                  isExpanded={true}
                  openCheck={
                    errorAccordionsOpen &&
                    !!Object.keys(errors.opportunityDetails || {}).length
                  }
                >
                  <OpportunityDetails />
                </AccordionCollapse>

                <AccordionCollapse
                  {...accordionBaseConfig}
                  title="Referral Reward"
                  isExpanded={true}
                  openCheck={errorAccordionsOpen && !!Object.keys(errors.referralReward || {}).length}
                >
                  <ReferralReward
                    integrationType={
                      integrationInfo?.getCurrentBusiness?.integrationType
                    }
                    isEdit={isEdit}
                  />
                </AccordionCollapse>

                <OptionalFeaturesTitle
                  onClick={() => toggleOptionalFeaturesAreShown(errors)}
                >
                  {optionalFeaturesAreShown
                    ? 'Hide Optional Features'
                    : 'Show Optional Features'}
                </OptionalFeaturesTitle>

                {(optionalFeaturesAreShown ||
                  !!Object.keys(errors.referrerEducation || {}).length ||
                  !!Object.keys(errors.opportunityDates || {}).length) && (
                  <OptionalFeaturesContainer>
                    <AccordionCollapse
                      {...accordionBaseConfig}
                      title="Referrer Education"
                      openCheck={
                        errorAccordionsOpen && 
                        !!Object.keys(errors.referrerEducation || {}).length
                      }
                    >
                      <ReferrerEducation />
                    </AccordionCollapse>

                    <AccordionCollapse
                      {...accordionBaseConfig}
                      title="Start &amp; End Date"
                      openCheck={
                        errorAccordionsOpen && 
                        !!Object.keys(errors.opportunityDates || {}).length
                      }
                    >
                      <OpportunityDates />
                    </AccordionCollapse>
                  </OptionalFeaturesContainer>
                )}
              </AccordionContainer>
            </Container>

            <FormFooter
              //publishDisabled={(!completeProfile.isCompleted || !setupBilling.isCompleted || !setupTracking.isCompleted)}
              publishDisabled={false}
              onPublish={handleClickPublish(
                validateForm,
                setSubmitting,
                values
              )}
              onSaveDraft={() => setSaveDraftModal(true)}
            />
          </form>
          <CreditCardDeletionModal
            onClose={onCreditCardDeletionModalClose}
            check={checkCardDeletionModal}
            onSuccess={onCreditCardDeletionSuccess}
            title={cardDeletionModalTitle}
          />
          <Modal
            type="warning"
            canClose
            isOpen={isValidationErrorModalOpen}
            onClose={() => setValidationErrorModal(false)}
            title="Before you can Publish this opportunity, you must go back and fill in the missing fields!"
            mainButton={{
              text: 'Go to Missing Fields',
              onClick: onMissingFieldsClick
            }}
            secondaryButton={{
              text: 'Save Draft',
              onClick: () => onSaveDraft(values)
            }}
          />
          <Modal
            imageURL="/assets/notification-icons/go-live.png"
            canClose
            isOpen={isPublishModalOpen}
            onClose={() => setPublishModal(false)}
            title="Are you sure you want to publish the opportunity?"
            mainButton={{
              text: 'Accept',
              onClick: () => onPublish(values)
            }}
            secondaryButton={{
              text: 'Cancel',
              onClick: () => setPublishModal(false)
            }}
          />
          <Modal
            imageURL="/assets/notification-icons/save.png"
            canClose
            isOpen={isSaveDraftModalOpen}
            onClose={() => setSaveDraftModal(false)}
            title="Are you sure you want to save the opportunity as draft?"
            mainButton={{
              text: 'Accept',
              onClick: () => onSaveDraft(values)
            }}
            secondaryButton={{
              text: 'Cancel',
              onClick: () => setSaveDraftModal(false)
            }}
          />
          <Modal
            imageURL="/assets/notification-icons/save.png"
            canClose
            isOpen={isShopifyBillingModalShown}
            onClose={() => setShopifyBillingModal(false)}
            title="Enable Shopify billing"
            message="You will be redirected to your Shopify store to approve a Zero fee recurring charge. Drum does not have a subscription fee and only charges commissions for successful referrals. Your progress will be saved as a draft and you can return to your opportunity list and publish the draft."
            mainButton={{
              text: 'Enable Shopify Billing',
              onClick: () => handleEnableShopifyBilling(values)
            }}
            secondaryButton={{
              text: 'Close',
              onClick: () => setShopifyBillingModal(false)
            }}
          />
          <SaveOpportunityErrorModal
            isOpen={!!error}
            error={error}
            onSaveDraft={() => onSaveDraft(values)}
            onClose={onCloseErrorModal}
          />
          <NavigationPrompt
            when={
              !isShopifyBillingModalShown &&
              ((isSubmissionSucceeded === null && dirty) ||
                isSubmissionSucceeded === false)
            }
          >
            {({ onConfirm, onCancel }) => (
              <Modal
                type="warning"
                isOpen={true}
                onClose={onCancel}
                title={REDIRECT_PROMPT_TITLE}
                message={REDIRECT_PROMPT_TEXT}
                mainButton={{
                  text: REDIRECT_PROMPT_SAVE_DRAFT,
                  onClick: async () => {
                    await onSaveDraft(values);
                    onCancel();
                  }
                }}
                secondaryButton={{
                  text: REDIRECT_PROMPT_OK,
                  onClick: () => {
                    sendDeleteOfferCreationProgressTrackingEvent(
                      normalizeOpportunityFormValues(
                        values,
                        integrationInfo
                      ) as Offer
                    );
                    onConfirm();
                  }
                }}
              />
            )}
          </NavigationPrompt>
          ;
          <PreviewButton onClick={() => setMobilePreview(true)} type="button">
            <Eye />
          </PreviewButton>
          <OpportunityPreviewModal
            isOpen={isMobilePreviewOpen}
            onClose={() => setMobilePreview(false)}
            values={values}
          />
        </GeneralLayout>
      )}
    </Formik>
  );
};

export default CreateOpportunityForm;
