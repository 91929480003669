import styled from 'styled-components';
import { OutlinedButton } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  padding: 92px 20px 0 20px;

  ${breakpoint('lg')`
    display: flex;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 88px 20px 0 20px;
  `}
`;

export const BackButton = styled(OutlinedButton)`
  ${breakpoint('lg')`
    width: 120px;
  `}

  ${breakpoint('xs', 'lg')`
    width: 100%;
    margin-bottom: 15px;
  `}
`;
