import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GeneralLayout from 'layouts/GeneralLayout';
import FAQ from 'components/FAQ';
import routes from 'constants/routes';
import { FullScreenLoader } from 'uikit';
import {
  useGetBusinessDetailsQuery,
  useGetShopifyShopUrlQuery,
  useGetShopifyScriptTagsInfoQuery
} from '../../graphql';
import { ShopifyIntegrationForm } from './components';
import {
  setupInstructions,
  SHOPIFY_PLATFORM_INFO,
  SHOPIFY_STATUS_POLLING_INTERVAL
} from './constants';
import {
  BackButton,
  Container,
  PageFooter,
  StyledIntegrationErrorContainer,
  StyledIntegrationInfo,
  StyledIntegrationPageHeader
} from './styles';

const { REACT_APP_SHOPIFY_DOMAIN } = process.env;

const ShopifyIntegrationPage: FC<RouteComponentProps> = ({ history }) => {
  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  const { data: shopData, loading } = useGetShopifyShopUrlQuery();
  const shopAddress =
    shopData?.getCurrentBusiness?.shopifyShop?.shopAddress || '';
  const { data, refetch: fetchScriptsInfo } = useGetShopifyScriptTagsInfoQuery({
    skip: !shopAddress,
    fetchPolicy: 'no-cache',
    variables: { shopAddress }
  });

  useEffect(() => {
    let timeout = setTimeout(function getConnectionInfo() {
      if (shopAddress) {
        fetchScriptsInfo({ shopAddress });

        timeout = setTimeout(
          getConnectionInfo,
          SHOPIFY_STATUS_POLLING_INTERVAL
        );
      }
    }, SHOPIFY_STATUS_POLLING_INTERVAL);

    return () => clearTimeout(timeout);
  }, [shopAddress, fetchScriptsInfo]);

  const getConnectionStatus = () =>
    // TODO: implement disconnected status
    typeof data?.getShopifyScriptTagsInfo?.connected !== 'boolean'
      ? null
      : {
          isIntegrationActive: data.getShopifyScriptTagsInfo.connected
        };

  const connectionStatus = getConnectionStatus();

  const handleConnectStore = (shopUrl: string) => {
    const id = businessProfileData?.getCurrentBusiness?.id;

    if (id) {
      const redirectUrl = `${REACT_APP_SHOPIFY_DOMAIN}/auth?shop=${shopUrl}&businessId=${id}`;

      window.open(redirectUrl);
    }
  };

  return (
    <>
      <GeneralLayout sidebar={<FAQ preset="tracking"/>}>
        <Container>
          <StyledIntegrationPageHeader
            // TODO: add link for onboarding call
            onScheduleCall={() => {}}
            setupInstructions={setupInstructions}
          />
          <StyledIntegrationInfo
            platform={SHOPIFY_PLATFORM_INFO}
            isConnected={!!connectionStatus?.isIntegrationActive}
          />
          {connectionStatus && !connectionStatus.isIntegrationActive && (
            <StyledIntegrationErrorContainer />
          )}

          <ShopifyIntegrationForm
            onConnectStore={handleConnectStore}
            shopUrl={shopAddress}
            connectionStatus={connectionStatus}
          />
        </Container>

        <PageFooter>
          <BackButton
            onClick={() => history.push(routes.DASHBOARD)}
            type="button"
          >
            Back
          </BackButton>
        </PageFooter>
      </GeneralLayout>
      {loading && <FullScreenLoader />}
    </>
  );
};

export default ShopifyIntegrationPage;
