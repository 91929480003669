import React, { FC, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ApolloError } from 'apollo-boost';
import { Color as PickerColor } from 'react-color';
import { Formik, FormikErrors } from 'formik';
import get from 'lodash.get';
import filter from 'lodash.filter';
import { getProfileStatus } from 'helpers';
import { sendStartEmailCampaignTrackingEvent } from 'helpers/segment';
import routes from 'constants/routes';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  REDIRECT_PROMPT_TITLE,
  REDIRECT_PROMPT_TEXT,
  REDIRECT_PROMPT_SAVE_DRAFT,
  REDIRECT_PROMPT_OK
} from 'constants/promptText';
import {
  EMAIL_STEP_TEMPLATE_STYLE_PARAM_NAME,
  EMAIL_STEP_ID_PARAM_NAME
} from 'constants/emailCampaignQueryParams';
import {
  getEmailTypeLabel,
  normalizeCampaignFormValues,
  convertToFormValues,
  isTextFormatCorrect,
  getTemplate
} from '../../helpers';
import { tooltips } from './constants';
import {
  DESKTOP_VIEW,
  PREVIEW_MODES,
  ViewModeType,
  getInitialValues,
  MOBILE_VIEW
} from '../../constants';

import { MAX_OPPORTUNITIES_IN_EMAIL } from '../EmailCampaignTemplates/constants';

import validationSchema, {
  EmailCampaignFormType
} from '../../validationSchema';
import {
  Container,
  ToggleContainer,
  StyledToggle,
  Content,
  LeftPanel,
  RightPanel,
  FormContainer,
  InputGroup,
  RadioContainer,
  Title,
  PickerContainer,
  PickerTitle,
  PreviewButton,
  CommonSettings,
  EmailInviteContainer,
  EmailInviteModalText
} from './styles';
import { RadioButton, Body1, FullScreenLoader, AccordionCollapse } from 'uikit';
import EmailSubjectDetails from './components/EmailSubjectDetails';
import EmailFrom from './components/EmailFrom';
import EmailSendTo from './components/EmailSendTo';
import EmailStyle from './components/EmailStyle';
import EmailTypeDescription from './components/EmailTypeDescription';
import SendTestEmail from './components/SendTestEmail';
import PersonalizationTip from './components/PersonalizationTip';
import FormFooter from './components/FormFooter';
import PreviewModal from './components/PreviewModal';
import WrongFormat from './components/WrongFormat';
import CampaignCreated, {
  Props as CampaignCreatedProps
} from './components/CampaignCreated/CampaignCreated';
import { emailType, SetField } from '../../types';
import ColorPicker, {
  Props as ColorPickerProps
} from 'components/ColorPicker/ColorPicker';
import CornersPicker, {
  Props as CornersPickerProps
} from 'components/CornersPicker/CornersPicker';
import IImageUpload from 'types/ImageUpload';
import {
  useGetBusinessDetailsQuery,
  useListOpportunitiesQuery,
  useGetCustomersQuery,
  useCreateEmailCampaignMutation,
  useUpdateEmailCampaignMutation,
  useGetEmailCampaignQuery,
  UpdateEmailCampaignMutation,
  CreateEmailCampaignMutation,
  OfferStatus,
  Offer,
  CampaignStatus,
  TemplateStyles,
  Campaign,
  useGetEmailCampaignsListQuery
} from '../../../../graphql';
import { ITag } from 'components/TagsAutosuggest/TagsAutosuggest';
import Modal, { ModalProps } from 'uikit/Modal/Modal';
import { Eye } from 'assets/svg';
import { EmailInvite } from 'assets/svg';
import {
  CUSTOMER_TAB_NAME_PARAM,
  CUSTOMER_TAB_NAME_EMAILS_LIST
} from 'constants/customersQueryParams';

interface Props {
  className?: string;
}
interface IColorModalData extends ModalProps, ColorPickerProps {
  modalTitle?: string;
}
interface ICornersModalData extends ModalProps, CornersPickerProps {
  modalTitle?: string;
}
interface ISuccessModalData extends ModalProps, CampaignCreatedProps {}

const accordionBaseConfig = {
  isExpanded: false,
  activeOnOpen: true
};

const DefineAndSend: FC<Props> = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const id = params[EMAIL_STEP_ID_PARAM_NAME] as string;
  const templateStyle = Object.values(TemplateStyles).includes(
    params[EMAIL_STEP_TEMPLATE_STYLE_PARAM_NAME] as TemplateStyles
  )
    ? params[EMAIL_STEP_TEMPLATE_STYLE_PARAM_NAME]
    : TemplateStyles.TemplateStyle1;

  const {
    data: businessData,
    loading: businessDataLoading
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'no-cache'
  });

  const {
    data: opportunitiesList,
    loading: isOpportunityListLoading
  } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: MAX_OPPORTUNITIES_IN_EMAIL
        }
      }
    }
  });

  const {
    data: customersData,
    loading: customersDataLoading
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        page: {
          offset: 0,
          limit: 1
        }
      }
    }
  });

  const { data: emailCampaignsData } = useGetEmailCampaignsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        page: {
          limit: 2,
          offset: 0
        },
        filter: { hasTags: false }
      }
    }
  });

  const { data: getCampaignData } = useGetEmailCampaignQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id
    },
    skip: id === undefined
  });

  const [
    createCampaign,
    { loading: isCampaignCreatingLoading }
  ] = useCreateEmailCampaignMutation({
    onCompleted: (data: CreateEmailCampaignMutation) => {
      const successAmount =
        (data.createCampaign?.campaignSetupResult?.customersProcessingResult
          ?.notInvited?.total || 0) +
        (data.createCampaign?.campaignSetupResult?.customersProcessingResult
          ?.pending?.total || 0) +
        (data.createCampaign?.campaignSetupResult?.customersProcessingResult
          ?.subscribed?.total || 0);
      const errorAmount =
        (data.createCampaign?.campaignSetupResult?.customersProcessingResult
          ?.noEmailCustomers?.total || 0) +
        (data.createCampaign?.campaignSetupResult?.customersProcessingResult
          ?.unsubscribed?.total || 0);
      setSubmitSuccessModalData({
        ...submitSuccessModalData,
        isOpen: true,
        successAmount,
        errorAmount
      });
      sendStartEmailCampaignTrackingEvent(successAmount, true);
    },
    onError: (error: ApolloError) => {
      onSaveError(error);
      sendStartEmailCampaignTrackingEvent(0, false);
    }
  });

  const [
    updateCampaign,
    { loading: isCampaignUpdatingLoading }
  ] = useUpdateEmailCampaignMutation({
    onCompleted: (data: UpdateEmailCampaignMutation) => {
      const successAmount =
        (data.updateCampaign?.campaignSetupResult?.customersProcessingResult
          ?.notInvited?.total || 0) +
        (data.updateCampaign?.campaignSetupResult?.customersProcessingResult
          ?.pending?.total || 0) +
        (data.updateCampaign?.campaignSetupResult?.customersProcessingResult
          ?.subscribed?.total || 0);
      const errorAmount =
        (data.updateCampaign?.campaignSetupResult?.customersProcessingResult
          ?.noEmailCustomers?.total || 0) +
        (data.updateCampaign?.campaignSetupResult?.customersProcessingResult
          ?.unsubscribed?.total || 0);
      setSubmitSuccessModalData({
        ...submitSuccessModalData,
        isOpen: true,
        successAmount,
        errorAmount
      });
      if (lastStatusBeforeSave === CampaignStatus.Draft) {
        sendStartEmailCampaignTrackingEvent(successAmount, true);
        setLastStatusBeforeSave(null);
      }
    },
    onError: (error: ApolloError) => {
      if (lastStatusBeforeSave === CampaignStatus.Draft) {
        sendStartEmailCampaignTrackingEvent(0, false);
        setLastStatusBeforeSave(null);
      }
      onSaveError(error);
    }
  });

  const [
    sendTest,
    { loading: isSendTestLoading }
  ] = useCreateEmailCampaignMutation({
    onCompleted: () => {},
    onError: (error: ApolloError) => {
      onSendTestError(error);
    }
  });

  const [
    saveDraft,
    { loading: isSaveDraftLoading }
  ] = useCreateEmailCampaignMutation({
    onCompleted: () => {
      onSaveDraftSuccess();
    },
    onError: (error: ApolloError) => {
      onSaveDraftError(error);
    }
  });

  const [
    editSaveDraft,
    { loading: isEditSaveDraftLoading }
  ] = useUpdateEmailCampaignMutation({
    onCompleted: () => {
      onSaveDraftSuccess();
    },
    onError: (error: ApolloError) => {
      onSaveDraftError(error);
    }
  });

  const getConfigType = (templateStyle: TemplateStyles) => {
    return templateStyle === TemplateStyles.TemplateStyle1 ||
      templateStyle === TemplateStyles.TemplateStyle3
      ? 'default'
      : 'alt';
  };

  const onSaveError = (error: ApolloError) => {
    setIsErrorModalOpen(true);
  };
  const onSendTestError = (error: ApolloError) => {
    setIsTestErrorModalOpen(true);
  };
  const onSaveDraftSuccess = () => {
    history.push(
      `${routes.CUSTOMERS_MANAGEMENT}?${CUSTOMER_TAB_NAME_PARAM}=${CUSTOMER_TAB_NAME_EMAILS_LIST}`
    );
  };
  const onSaveDraftError = (error: ApolloError) => {
    setIsErrorModalOpen(true);
  };

  const closeColorModal = () => {
    setColorModalData({ ...colorModalData, isOpen: false });
  };
  const closeCornersModal = () => {
    setCornersModalData({ ...cornersModalData, isOpen: false });
  };
  const closeSuccessSaveModal = () => {
    setSubmitSuccessModalData({ ...submitSuccessModalData, isOpen: false });
    history.push(
      `${routes.CUSTOMERS_MANAGEMENT}?${CUSTOMER_TAB_NAME_PARAM}=${CUSTOMER_TAB_NAME_EMAILS_LIST}`
    );
  };
  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };
  const closeTestErrorModal = () => {
    setIsTestErrorModalOpen(false);
  };

  const [formValues, setFormValues] = useState<any>(null);
  const [previewMode, setPreviewMode] = useState<ViewModeType>(DESKTOP_VIEW);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isTestErrorModalOpen, setIsTestErrorModalOpen] = useState(false);
  const [isFormatErrorModalOpen, setIsFormatErrorModalOpen] = useState(false);
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [isValidationErrorModalOpen, setValidationErrorModal] = useState(false);
  const [errorAccordionsOpen, setErrorAccordionsOpen] = useState(false);
  const [
    lastStatusBeforeSave,
    setLastStatusBeforeSave
  ] = useState<CampaignStatus | null>(null);

  const [colorModalData, setColorModalData] = useState<IColorModalData>({
    isOpen: false,
    onClose: closeColorModal,
    onCancel: closeColorModal,
    initialColor: '#fff',
    modalTitle: 'Color'
  });
  const [cornersModalData, setCornersModalData] = useState<ICornersModalData>({
    isOpen: false,
    onClose: closeCornersModal,
    onCancel: closeCornersModal,
    initialValue: 5,
    modalTitle: 'Corners'
  });
  const [submitSuccessModalData, setSubmitSuccessModalData] = useState<
    ISuccessModalData
  >({
    isOpen: false,
    successAmount: 0,
    errorAmount: 0
  });
  const [
    personalizationTipModalOpen,
    setPersonalizationTipModalOpen
  ] = useState<boolean>(false);

  const handleAddImage = (setFieldValue: SetField) => (
    imageData: IImageUpload
  ) => {
    setFieldValue('emailStyle.coverImage', imageData.path);
  };

  const handleAddTag = (
    tag: ITag,
    setFieldValue: SetField,
    values: EmailCampaignFormType
  ) => {
    if (
      (values.sendToFilter.tags.in || []).find((item) => item.id === tag.id)
    ) {
      return;
    }

    setFieldValue('sendType', 'tags');
    setFieldValue('sendToFilter.tags.in', [
      ...values.sendToFilter.tags.in,
      tag
    ]);
  };
  const handleRemoveTag = (
    tag: ITag,
    setField: SetField,
    values: EmailCampaignFormType
  ) => {
    setField(
      'sendToFilter.tags.in',
      (values.sendToFilter.tags.in || []).filter((item) => item.id !== tag.id)
    );
  };
  const handleColorPick = (
    field: string,
    setField: SetField,
    values: EmailCampaignFormType,
    label: string
  ) => {
    setColorModalData({
      ...colorModalData,
      isOpen: true,
      modalTitle: label,
      initialColor: get(values, field),
      onApply: (color: PickerColor) => {
        setField(field, color);
        closeColorModal();
      }
    });
  };
  const handleCornersPick = (
    field: string,
    setField: SetField,
    values: EmailCampaignFormType,
    label: string
  ) => {
    setCornersModalData({
      ...cornersModalData,
      isOpen: true,
      modalTitle: label,
      initialValue: get(values, field),
      onApply: (value: number) => {
        setField(field, value);
        closeCornersModal();
      }
    });
  };

  const handleSaveClick = (
    validateForm: (
      values?: EmailCampaignFormType
    ) => Promise<FormikErrors<EmailCampaignFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: EmailCampaignFormType
  ) => () => {
    setSubmitting(true);
    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      setSubmitting(false);
      const wrongFormats = filter(values.emails, (item: any) => {
        return (
          !isTextFormatCorrect(item.subjectLine) ||
          !isTextFormatCorrect(item.emailBody)
        );
      });
      if (wrongFormats.length) {
        setIsFormatErrorModalOpen(true);
        return;
      }
      hasErrors ? setValidationErrorModal(true) : setIsSaveModalOpen(true);
    });
  };

  const handleSendTestEmails = (
    validateForm: (
      values?: EmailCampaignFormType
    ) => Promise<FormikErrors<EmailCampaignFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: EmailCampaignFormType,
    chunks: string[]
  ) => {
    setSubmitting(true);
    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      setSubmitting(false);
      if (hasErrors) {
        setValidationErrorModal(true);
        return;
      }
      const input = {
        ...normalizeCampaignFormValues(values, CampaignStatus.Active),
        testEmails: chunks
      };
      for (let type in emailType) {
        input.emails[type].subjectLine =
          '[TEST] ' + input.emails[type].subjectLine;
      }
      sendTest({ variables: { input } });
    });
  };

  const handleStartCampaign = async (
    values: EmailCampaignFormType,
    resetForm: (values: any) => void
  ) => {
    const input = normalizeCampaignFormValues(values, CampaignStatus.Active);
    if (id) {
      setLastStatusBeforeSave(values.status);
    }

    id
      ? updateCampaign({ variables: { input: { ...input, id } } })
      : createCampaign({ variables: { input } });

    resetForm(values);
  };

  const handleSaveDraft = async (
    values: EmailCampaignFormType,
    resetForm: (values: any) => void
  ) => {
    const input = {
      ...normalizeCampaignFormValues(values, CampaignStatus.Draft)
    };

    id
      ? editSaveDraft({ variables: { input: { ...input, id } } })
      : saveDraft({ variables: { input } });
    resetForm(values);
  };

  const canSave = () => {
    const hasOpportunity = getProfileStatus(businessData).createOpportunity
      .isCompleted;
    const hasCustomers = !!customersData?.findCustomers?.total;
    return hasOpportunity && hasCustomers;
  };

  const onMissingFieldsClick = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, errors: FormikErrors<EmailCampaignFormType>) => {
    setValidationErrorModal(false);
    setErrorAccordionsOpen(true);
    if(errors.emails?.invitationEmail) {
      setFieldValue('emailType', emailType.invitationEmail);
    } else if(errors.emails?.reminderEmail) {
      setFieldValue('emailType', emailType.reminderEmail);
    } else if(errors.emails?.newOpportunityEmail) {
      setFieldValue('emailType', emailType.newOpportunityEmail);
    }
    setTimeout(() => {
      setErrorAccordionsOpen(false);
    });
  };

  useEffect(() => {
    if (
      businessData &&
      opportunitiesList &&
      emailCampaignsData &&
      (id ? getCampaignData : true)
    ) {
      const initialValues = getInitialValues(
        businessData.getCurrentBusiness?.businessName
      );

      const canSetAllCustomers = !emailCampaignsData.findCampaigns?.items?.find(
        (item: any) => item.id !== id
      );
      const editData = convertToFormValues(
        getCampaignData?.getCampaign as Campaign,
        (getCampaignData?.getCampaign?.templateStyle ||
          templateStyle) as TemplateStyles,
        canSetAllCustomers
      );
      setFormValues({
        ...initialValues,
        fromEmail: businessData.getCurrentBusiness?.email,
        ...(editData || {})
      });
    }
    // eslint-disable-next-line
  }, [businessData, opportunitiesList, getCampaignData, emailCampaignsData]);

  return (
    <Container className={className}>
      <ToggleContainer>
        <StyledToggle
          options={PREVIEW_MODES}
          onChange={(value: ViewModeType) => setPreviewMode(value)}
          selectedValue={previewMode}
        />
      </ToggleContainer>

      {formValues && (
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={() => {}}
        >
          {({
            handleSubmit,
            values,
            validateForm,
            setSubmitting,
            dirty,
            resetForm,
            setFieldValue,
            errors
          }) => (
            <Content>
              <LeftPanel>
                <form onSubmit={handleSubmit}>
                  <FormContainer>
                    <Title>Emails in Campaign</Title>
                    <InputGroup>
                      <RadioContainer>
                        <RadioButton
                          id="invitationEmail"
                          name="emailType"
                          label="Invitation Email"
                          labelClass={'emphasized-label'}
                        />
                        <RadioButton
                          id="reminderEmail"
                          name="emailType"
                          label="Reminder Email"
                          labelClass={'emphasized-label'}
                        />
                        <RadioButton
                          id="newOpportunityEmail"
                          name="emailType"
                          label="New Opportunity Email"
                          labelClass={'emphasized-label'}
                        />
                      </RadioContainer>
                    </InputGroup>

                    <EmailTypeDescription type={values.emailType} />

                    <Title>{getEmailTypeLabel(values.emailType, true)}</Title>
                    <AccordionCollapse
                      {...accordionBaseConfig}
                      title={'Email Subject Line and Body'}
                      isExpanded={true}
                      openCheck={
                        errorAccordionsOpen &&
                        !!Object.keys((errors.emails as any || {})[values.emailType] || {}).length
                      }
                    >
                      <EmailSubjectDetails
                        emailType={values.emailType as emailType}
                        onTipClick={() => setPersonalizationTipModalOpen(true)}
                      />
                    </AccordionCollapse>

                    <Title>Email Campaign settings</Title>
                    <Body1>
                      These settings will apply to all three emails in your
                      referral email campaign.
                    </Body1>

                    <CommonSettings>
                      <AccordionCollapse
                        {...accordionBaseConfig}
                        title={'Send to'}
                        tooltip={
                          <div>
                            {tooltips.SEND_TO1}
                            <br />
                            <br />
                            {tooltips.SEND_TO2}
                            <br />
                            <br />
                            {tooltips.SEND_TO3}
                          </div>
                        }
                        openCheck={
                          errorAccordionsOpen && (
                          !!Object.keys(errors.sendToFilter || {}).length ||
                          !!errors.canSetAllCustomers)
                        }
                      >
                        <EmailSendTo
                          itemsCount={customersData?.findCustomers?.total || 0}
                          onAdd={handleAddTag}
                          onRemove={handleRemoveTag}
                        />
                      </AccordionCollapse>
                      <AccordionCollapse
                        {...accordionBaseConfig}
                        title={'Reply to'}
                        tooltip={tooltips.FROM}
                        openCheck={
                          errorAccordionsOpen &&
                          !!errors.fromEmail
                        }
                      >
                        <EmailFrom />
                      </AccordionCollapse>
                      <AccordionCollapse
                        {...accordionBaseConfig}
                        title={'Email Style'}
                        tooltip={tooltips.STYLING}
                      >
                        <EmailStyle
                          onColorPick={handleColorPick}
                          onCornersPick={handleCornersPick}
                          onAddImage={handleAddImage}
                          showUploadImage={
                            values.templateStyle ===
                              TemplateStyles.TemplateStyle1 ||
                            values.templateStyle ===
                              TemplateStyles.TemplateStyle2
                          }
                        />
                      </AccordionCollapse>
                      <AccordionCollapse
                        {...accordionBaseConfig}
                        title={'Send Test Email'}
                        tooltip={
                          <div>
                            {tooltips.TEST_EMAILS1}
                            <br />
                            <br />
                            {tooltips.TEST_EMAILS2}
                            <br />
                            <br />
                            {tooltips.TEST_EMAILS3}
                          </div>
                        }
                      >
                        <SendTestEmail
                          onSend={(chunks: string[]) =>
                            handleSendTestEmails(
                              validateForm,
                              setSubmitting,
                              values,
                              chunks
                            )
                          }
                        />
                      </AccordionCollapse>
                    </CommonSettings>
                  </FormContainer>

                  <Modal {...colorModalData}>
                    <PickerContainer>
                      <PickerTitle>
                        {colorModalData.modalTitle || 'Color'}
                      </PickerTitle>
                      <ColorPicker {...colorModalData} />
                    </PickerContainer>
                  </Modal>
                  <Modal {...cornersModalData}>
                    <PickerContainer>
                      <PickerTitle>
                        {cornersModalData.modalTitle || 'Corners'}
                      </PickerTitle>
                      <CornersPicker {...cornersModalData} />
                    </PickerContainer>
                  </Modal>
                  <Modal
                    isOpen={personalizationTipModalOpen}
                    onClose={() => setPersonalizationTipModalOpen(false)}
                    mainButton={{
                      text: 'Close',
                      onClick: () => setPersonalizationTipModalOpen(false)
                    }}
                    childrenBeforeButtons={true}
                  >
                    <PersonalizationTip />
                  </Modal>
                  <FormFooter
                    values={values}
                    publishDisabled={!canSave()}
                    saveDraftDisabled={!canSave()}
                    onPublish={handleSaveClick(
                      validateForm,
                      setSubmitting,
                      values
                    )}
                    onSaveDraft={() => setIsSaveDraftModalOpen(true)}
                  />
                </form>
              </LeftPanel>
              <RightPanel
                className={previewMode === MOBILE_VIEW ? 'responsive' : ''}
              >
                {getTemplate(
                  values.emailType,
                  values.templateStyle as TemplateStyles,
                  opportunitiesList?.listOffers?.items as Offer[],
                  values,
                  getConfigType(values.templateStyle)
                )}
              </RightPanel>
              <PreviewButton
                onClick={() => setIsMobilePreviewOpen(true)}
                type="button"
              >
                <Eye />
              </PreviewButton>
              <PreviewModal
                isOpen={isMobilePreviewOpen}
                onClose={() => setIsMobilePreviewOpen(false)}
              >
                {getTemplate(
                  values.emailType,
                  values.templateStyle as TemplateStyles,
                  opportunitiesList?.listOffers?.items as Offer[],
                  values,
                  getConfigType(values.templateStyle)
                )}
              </PreviewModal>
              <Modal
                imageURL="/assets/notification-icons/go-live.png"
                canClose
                isOpen={isSaveModalOpen}
                onClose={() => setIsSaveModalOpen(false)}
                title="Are you sure you want to start this campaign?"
                mainButton={{
                  text: 'Accept',
                  onClick: () => handleStartCampaign(values, resetForm)
                }}
                secondaryButton={{
                  text: 'Cancel',
                  onClick: () => setIsSaveModalOpen(false)
                }}
              />
              <Modal
                imageURL="/assets/notification-icons/save.png"
                canClose
                isOpen={isSaveDraftModalOpen}
                onClose={() => setIsSaveDraftModalOpen(false)}
                title="Are you sure you want to save this campaign as draft?"
                mainButton={{
                  text: 'Accept',
                  onClick: () => handleSaveDraft(values, resetForm)
                }}
                secondaryButton={{
                  text: 'Cancel',
                  onClick: () => setIsSaveDraftModalOpen(false)
                }}
              />
              <Modal
                type="warning"
                canClose
                isOpen={isValidationErrorModalOpen}
                onClose={() => setValidationErrorModal(false)}
                title="Before you can save this email campaign, you must go back and fill in the missing fields!"
                mainButton={{
                  text: 'Go to Missing Fields',
                  onClick: () => onMissingFieldsClick(setFieldValue, errors)
                }}
                secondaryButton={{
                  text: 'Save Draft',
                  onClick: () => handleSaveDraft(values, resetForm)
                }}
              />
              <Modal
                {...submitSuccessModalData}
                onClose={closeSuccessSaveModal}
                mainButton={{
                  text: 'Close',
                  onClick: closeSuccessSaveModal
                }}
                childrenBeforeButtons={true}
              >
                <CampaignCreated {...submitSuccessModalData} />
              </Modal>
              <Modal
                type="warning"
                isOpen={isErrorModalOpen}
                onClose={closeErrorModal}
                title="An error has occured while trying to save the campaign"
                mainButton={{
                  text: 'Close',
                  onClick: closeErrorModal
                }}
                childrenBeforeButtons={true}
              ></Modal>
              <Modal
                type="warning"
                isOpen={isTestErrorModalOpen}
                onClose={closeTestErrorModal}
                title="An error has occured while sending test emails"
                mainButton={{
                  text: 'Close',
                  onClick: closeTestErrorModal
                }}
                childrenBeforeButtons={true}
              ></Modal>
              <Modal
                type="warning"
                isOpen={isFormatErrorModalOpen}
                onClose={() => setIsFormatErrorModalOpen(false)}
                title="Personalization error"
                mainButton={{
                  text: 'Close',
                  onClick: () => setIsFormatErrorModalOpen(false)
                }}
                childrenBeforeButtons={true}
              >
                <WrongFormat />
              </Modal>
              <Modal
                isOpen={isCampaignCreatingLoading || isSendTestLoading}
                canClose={false}
              >
                <EmailInviteContainer>
                  <EmailInvite />
                  <EmailInviteModalText>
                    Sending Invitations
                  </EmailInviteModalText>
                </EmailInviteContainer>
              </Modal>
              {(!formValues ||
                customersDataLoading ||
                businessDataLoading ||
                isOpportunityListLoading ||
                isEditSaveDraftLoading ||
                isCampaignUpdatingLoading ||
                isSaveDraftLoading) && <FullScreenLoader />}
              {/* <Prompt
                when={dirty}
                message="Are you sure to leave this page? Your progress will be deleted."
              /> */}
              <NavigationPrompt when={dirty}>
                {({ onConfirm, onCancel }) => (
                  <Modal
                    type="warning"
                    isOpen={true}
                    onClose={onCancel}
                    title={REDIRECT_PROMPT_TITLE}
                    message={REDIRECT_PROMPT_TEXT}
                    mainButton={{
                      text: REDIRECT_PROMPT_SAVE_DRAFT,
                      onClick: async () => {
                        await handleSaveDraft(values, resetForm);
                        onCancel();
                      }
                    }}
                    secondaryButton={{
                      text: REDIRECT_PROMPT_OK,
                      onClick: onConfirm
                    }}
                  />
                )}
              </NavigationPrompt>
            </Content>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default DefineAndSend;
