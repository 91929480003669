import { Auth } from 'aws-amplify';
import { clearUtmCookies } from 'helpers/utm';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { FALLBACK_AUTH_ERROR_MESSAGE } from 'constants/errors';
import { GetBusinessIdDocument } from '../graphql';
import { getApolloClient } from 'apollo';

export const getCurrentBusinessId = async () => {
  const apolloClient = getApolloClient();
  const res = await apolloClient.query({
    query: GetBusinessIdDocument
  });
  return res?.data?.getCurrentBusiness?.id;
};

export const signIn = async (email: string, password: string) => {
  try {
    const user = await Auth.signIn(email, password);
    return { user };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const checkAuthentication = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const getCurrentCredentials = async () => {
  try {
    const res = await Auth.currentCredentials();

    return res;
  } catch (error) {
    return null;
  }
};

export const signUp = async (email: string, password: string) => {
  try {
    const user = await Auth.signUp({
      username: email.toLowerCase(),
      password,
      // Send this clientMetadata for new accounts to indicate that
      // server should perform mutation `businessRegister`.
      // The mutation is required for account setup.
      // In old version user should complete separate registration step with this mutation manually,
      // but new UI doesn't have this step. So we need to send this request from BE.
      clientMetadata: {
        userRole: 'BUSINESS',
        registerUser: 'true'
      }
    });

    // TODO setup verify via email once correct aws settings will setup
    // Auth.verifyCurrentUserAttribute('email');
    return { user };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

interface SingOutOptions {
  doNotReload: boolean
}

export const signOut = async (singOutOptions: SingOutOptions = { doNotReload: false }) => {
  try {
    await Auth.signOut();
    clearUtmCookies();
    if (!singOutOptions.doNotReload) {
      window.location.reload(false);
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const facebookSignIn = async () => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook
    });
    return { credentials };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const googleSignIn = async () => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    });

    return { credentials };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const forgotPassword = async (username: string) => {
  try {
    const data = await Auth.forgotPassword(username);
    return { data };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

export const resetPassword = async (
  username: string,
  code: string,
  newPassword: string
) => {
  try {
    const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
    return { data };
  } catch (error) {
    return { code: error.code, message: error.message };
  }
};

const { REACT_APP_SHOPIFY_DOMAIN } = process.env;

interface ShopifyAuthResponse {
  authenticated: boolean;
  reason: string;
}

const extractSubjectFromJwt = (jwtToken: string) => {
  try {
    return JSON.parse(atob(jwtToken.split('.')[1])).sub
  } catch (err) {
    console.error('err parsing token');
    return null
  }
};

export const handleShopifyAuth = async (
  shop: string,
  email: string
): Promise<ShopifyAuthResponse> => {
  const SHOPIFY_APP_API_URL = `${REACT_APP_SHOPIFY_DOMAIN}/shopify-shop-jwt?shop=${shop}`;

  const response = await fetch(SHOPIFY_APP_API_URL, {
    credentials: 'include'
  });

  const { jwtToken, authenticated, reason } = await response.json();

  if (!authenticated) {
    return { authenticated, reason };
  }

  const formattedEmail = email.toLowerCase();
  const authUsername = extractSubjectFromJwt(jwtToken) || formattedEmail;
  const userObj = await Auth.signIn(authUsername);

  if (userObj.challengeName === 'CUSTOM_CHALLENGE') {
    await Auth.sendCustomChallengeAnswer(userObj, jwtToken);
    return { authenticated, reason };
  } else {
    return { authenticated: false, reason: FALLBACK_AUTH_ERROR_MESSAGE };
  }
};
