import React, { FC } from 'react';
import OpportunityPreview from 'components/OpportunityPreview';
import getInitialValues from 'components/CreateOpportunity/utils';
import {
  useGetBusinessDetailsQuery,
  GetOfferQuery
} from '../../graphql';
import { Container, Title } from './styles';

interface Props {
  className?: string;
  offer: GetOfferQuery
}

const DashboardOpportunityPreview: FC<Props> = ({ className, offer }) => {
  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  return (
    <Container className={className}>
      {offer ? (
        <OpportunityPreview
          values={getInitialValues(offer, businessProfileData)}
          showDescription={false}
        />
      ) : (
        <>
          <Title>Opportunity Preview</Title>
          <img src="/assets/grey-phone-placeholder.png" alt="" />
        </>
      )}
    </Container>
  );
};

export default DashboardOpportunityPreview;
