import React, { FC, Fragment, useEffect, useState } from 'react';
import { ApolloError } from 'apollo-client';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import routes from 'constants/routes';
import { FullScreenLoader, Modal } from 'uikit';
import {
  FormInner,
  FormIntegration,
  FormTitle,
  StyledFormFooter,
  StandardFormContainer
} from '../styled';
import { ContainerInner } from '../../../styled';
import {
  BASE_PREFIX,
  COMPLETION_PREFIX,
  COMPLETION_EVENT_URL_PLACEHOLDER
} from '../../../helpers';
import {
  WEB_INTEGRATION_NO_URL_FILLED,
  WEB_INTEGRATION_NO_BASE_VERIFICATION,
  WEB_INTEGRATION_NO_COMPLETION_VERIFICATION,
  WEB_INTEGRATION_SAVE_FAILED,
  WEB_INTEGRATION_SAVE_SUCCESS,
  WEB_INTEGRATION_VERIFICATION_SUCCESS_BASE,
  WEB_INTEGRATION_VERIFICATION_SUCCESS_COMPLETION,
  WEB_INTEGRATION_VERIFICATION_FAILED_BASE,
  WEB_INTEGRATION_VERIFICATION_FAILED_COMPLETION
} from 'constants/webIntegrationMessages';
import {
  Prefix,
  IntegrationPlatform,
  VWindow,
  VTimeout,
  ModalData,
  VerificationData
} from '../../../types';
import CustomerJourney from './CustomerJourney';
import ActivityCompletion from './ActivityCompletion';
import validationSchema, {
  StandardIntegration
} from '../validationSchemaStandard';
import IntegrationErrorContainer from 'components/IntegrationErrorContainer';
import VerificationFailed from './components/VerificationFailed/VerificationFailed';
import BackToDashboard from './components/BackToDashboard/BackToDashboard';
import {
  useGetCurrentBusinessIntegrationsQuery,
  useUpdateBusinessIntegrationsMutation,
  useVerifyEventTriggerMutation
} from '../../../../../graphql';
import CompleteAccountSetUp from 'components/CompleteAccountSetUp';
const windowOpenTimeout = 10000;

type Props = {
  platform: IntegrationPlatform;
  onDataReceive: (integration: StandardIntegration) => void;
  onDataError: (error: ApolloError) => void;
};

interface FormModalData extends ModalData {
  children?: FC;
  showMainButton: boolean;
}

const BusinessForm: FC<Props> = ({ platform, onDataReceive, onDataError }) => {
  const history = useHistory();
  const {
    data,
    loading: isIntegrationsLoading,
    refetch: refetchintegrations,
    error: integrationsError
  } = useGetCurrentBusinessIntegrationsQuery();

  const [
    updateIntegrations,
    {
      loading: isUpdateIntegrationsLoading,
      error: updateIntegrationsError,
      data: updateData
    }
  ] = useUpdateBusinessIntegrationsMutation();
  const [verifyCompletion] = useVerifyEventTriggerMutation({
    onCompleted: (data) => {
      if (data?.verifyEventTrigger?.success) {
        onVerificationSuccess(COMPLETION_PREFIX);
      } else {
        onVerificationFail(COMPLETION_PREFIX);
      }
    },
    onError: () => {
      onVerificationFail(COMPLETION_PREFIX);
    }
  });

  const defaultValues: StandardIntegration = {
    //Customer journey
    //baseCodeUrl: 'https://geniusee-drum-test-store-df.myshopify.com/', //this url can be tested to return positive result
    baseCodeUrl: '',
    baseVerified: false,
    baseVerificationFailed: false,
    baseCodeSnippet: platform.baseCode,

    //Activity completion
    completionEventUrl: '',
    completionVerified: false,
    completionVerificationFailed: false,
    completionCodeSnippet: platform.completionCode,

    //Other
    isSendPurchaseAmount: true
  };

  const [checkSetupModal, setCheckSetupModal] = useState<boolean>(false);
  const [values, setValues] = useState<StandardIntegration | null>();
  const [verificationLoading, setVerificationLoading] = useState<boolean>(
    false
  );
  const [verificationData, setVerificationData] = useState<VerificationData>({
    base: {},
    completion: {}
  });
  const [modalData, setModalData] = useState<FormModalData>({
    title: '',
    isOpen: false,
    mainButtonText: 'Okay',
    onClick: () => {},
    onClose: () => {},
    showMainButton: true
  });

  let verificationTimeouts: VTimeout = {
    base: undefined,
    completion: undefined
  };
  let verificationWindows: VWindow = {
    base: undefined,
    completion: undefined
  };

  const handleSubmit = (values: StandardIntegration) => {
    const error = getError(values);
    const oldData = data?.getCurrentBusiness?.integrations;
    if (
      oldData &&
      oldData.baseCodeUrl === values.baseCodeUrl &&
      oldData.completionEventUrl === values.completionEventUrl
    ) {
      history.push(routes.DASHBOARD);
      return;
    }

    if (error.error) {
      openModal({
        title: error.text,
        children: <BackToDashboard />
      });
    } else {
      const input = {
        baseCodeUrl: values.baseCodeUrl || null,
        completionEventUrl: values.completionVerified
          ? COMPLETION_EVENT_URL_PLACEHOLDER
          : null,
        isSendPurchaseAmount: values.isSendPurchaseAmount,
        platformName: platform.id
      } as any;

      updateIntegrations({
        variables: {
          input
        }
      });
    }
  };

  const onVerificationSuccess = (prefix: Prefix) => {
    const callback = verificationData[prefix].success;
    callback && callback();
    setVerificationLoading(false);
    unsubscribeFromMessages(prefix);

    if (prefix === BASE_PREFIX) {
      const url = verificationData[prefix].url || '';
      const analytics = (window as any).analytics;
      analytics &&
        analytics.track('Base Code Verification Success', {
          businessId: data?.getCurrentBusiness?.id,
          baseCodeUrl: url,
          baseCodeVerificationResult: true
        });
    }

    openModal({
      title:
        prefix === BASE_PREFIX
          ? WEB_INTEGRATION_VERIFICATION_SUCCESS_BASE
          : WEB_INTEGRATION_VERIFICATION_SUCCESS_COMPLETION,
      type: 'success',
      showMainButton: false
    });
  };
  const onVerificationFail = (prefix: Prefix) => {
    const callback = verificationData[prefix].fail;
    callback && callback();

    if (prefix === BASE_PREFIX) {
      const url = verificationData[prefix].url || '';
      const analytics = (window as any).analytics;
      analytics &&
        analytics.track('Base Code Verification Failed', {
          businessId: data?.getCurrentBusiness?.id,
          baseCodeUrl: url,
          baseCodeVerificationResult: false
        });
    }

    openModal({
      title:
        prefix === BASE_PREFIX
          ? WEB_INTEGRATION_VERIFICATION_FAILED_BASE
          : WEB_INTEGRATION_VERIFICATION_FAILED_COMPLETION,
      type: 'warning',
      mainButtonText: 'Please try again',
      children: (
        <VerificationFailed
          type={prefix === BASE_PREFIX ? 'base code' : 'completion event'}
        />
      )
    });

    setVerificationLoading(false);
    unsubscribeFromMessages(prefix);
  };

  const unsubscribeFromMessages = (prefix: Prefix) => {
    if (prefix === BASE_PREFIX) {
      window.removeEventListener('message', verifyBaseCode);
    } else if (prefix === COMPLETION_PREFIX) {
      window.removeEventListener('message', verifyCompletionCode);
    }
    clearTimeout(verificationTimeouts[prefix]);
  };

  const subscribeToMessages = (prefix: Prefix) => {
    if (prefix === BASE_PREFIX) {
      window.addEventListener('message', verifyBaseCode, false);
    } else if (prefix === COMPLETION_PREFIX) {
      window.addEventListener('message', verifyCompletionCode, false);
    }
  };

  function extractHostname(url: string) {
    var hostname;
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
  }

  const verifyCode = (message: any, prefix: Prefix) => {
    const url = verificationData[prefix].url || '';
    if (!url) {
      onVerificationFail(prefix);
    }
    const origin = message?.origin || '';
    if (message && extractHostname(origin) !== extractHostname(url)) {
      return;
    }

    if (message?.data?.event) {
      onVerificationSuccess(prefix);
    } else {
      onVerificationFail(prefix);
    }
    unsubscribeFromMessages(prefix);

    if (verificationWindows[prefix]) {
      verificationWindows[prefix].close();
    }
  };

  const verifyBaseCode = (message: any) => {
    verifyCode(message, BASE_PREFIX);
  };

  const verifyCompletionCode = (message: any) => {
    verifyCode(message, COMPLETION_PREFIX);
  };

  const onVerificationPress = (prefix: Prefix) => {
    verificationTimeouts[prefix] = setTimeout(() => {
      onVerificationFail(prefix);
      verificationWindows[prefix]?.close();
      unsubscribeFromMessages(prefix);
    }, windowOpenTimeout);
    subscribeToMessages(prefix);
    verificationWindows[prefix] = window.open(
      verificationData[prefix].url || ''
    );
    setVerificationLoading(true);
  };

  const onBaseCodeVerificationPress = (
    url: string,
    success: () => void,
    fail: () => void
  ) => {
    setVerificationData({
      ...verificationData,
      [BASE_PREFIX]: {
        url,
        success,
        fail
      }
    });
  };

  useEffect(() => {
    if (
      verificationData[BASE_PREFIX] &&
      Object.keys(verificationData[BASE_PREFIX]).length
    ) {
      onVerificationPress(BASE_PREFIX);
    }
    // eslint-disable-next-line
  }, [verificationData[BASE_PREFIX]]);

  const verifyCompletionEvent = () => {
    setVerificationLoading(true);
    verifyCompletion({
      variables: {
        input: {}
      }
    });
  };

  const onCompletionEventVerificationPress = (
    success: () => void,
    fail: () => void
  ) => {
    setVerificationData({
      ...verificationData,
      [COMPLETION_PREFIX]: {
        success,
        fail
      }
    });
    verifyCompletionEvent();
  };

  const getError = (
    values: StandardIntegration
  ): { error: boolean; text: string } => {
    if (
      !values.baseCodeUrl &&
      !values.baseVerified &&
      !values.completionVerified
    ) {
      return { error: true, text: WEB_INTEGRATION_NO_URL_FILLED };
    } else if (!values.baseCodeUrl || !values.baseVerified) {
      return { error: true, text: WEB_INTEGRATION_NO_BASE_VERIFICATION };
    } else if (!values.completionVerified) {
      return { error: true, text: WEB_INTEGRATION_NO_COMPLETION_VERIFICATION };
    }

    return { error: false, text: '' };
  };

  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };
  const openModal = (options: any) => {
    const {
      title,
      type = 'warning',
      onClick = closeModal,
      onClose = closeModal,
      children = null,
      mainButtonText,
      showMainButton = true
    } = options;

    const value = {
      ...modalData,
      isOpen: true,
      type,
      title,
      onClick,
      onClose,
      children,
      mainButtonText,
      showMainButton
    };
    setModalData(value);
  };

  useEffect(() => {
    if (data?.getCurrentBusiness) {
      let integrations = data.getCurrentBusiness.integrations || {
        baseCodeUrl: '',
        completionEventUrl: '',
        isSendPurchaseAmount: true,
        platformName: platform.id
      };
      setValues({
        ...defaultValues,
        ...integrations,

        baseVerified: !!integrations.baseCodeUrl,
        completionVerified: !!integrations.completionEventUrl
      });
      onDataReceive(integrations);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (integrationsError) {
      setValues(defaultValues);
      onDataError && onDataError(integrationsError);
    }
    // eslint-disable-next-line
  }, [integrationsError]);

  //ON SAVE ERROR
  useEffect(() => {
    if (updateIntegrationsError) {
      openModal({
        title: WEB_INTEGRATION_SAVE_FAILED
      });
    }
    // eslint-disable-next-line
  }, [updateIntegrationsError]);

  const completeDefaultAction = () => {
    openModal({
      title: WEB_INTEGRATION_SAVE_SUCCESS,
      type: 'success',
      onClick: () => history.push(routes.DASHBOARD),
      onClose: () => history.push(routes.DASHBOARD)
    });
    setCheckSetupModal(false);
  };

  //ON SAVE
  useEffect(() => {
    if (!updateData) return;
    refetchintegrations();
    setCheckSetupModal(true);
    // eslint-disable-next-line
  }, [updateData]);

  useEffect(() => {
    return () => {
      unsubscribeFromMessages(BASE_PREFIX);
      unsubscribeFromMessages(COMPLETION_PREFIX);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StandardFormContainer>
      {values && (
        <Formik
          validationSchema={validationSchema}
          initialValues={values}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Fragment>
              <ContainerInner>
                {integrationsError && <IntegrationErrorContainer />}
              </ContainerInner>
              <FormIntegration onSubmit={handleSubmit}>
                <ContainerInner>
                  <FormInner>
                    <FormTitle>
                      <p>Integration Details</p>
                    </FormTitle>
                    <CustomerJourney
                      onVerificationPress={onBaseCodeVerificationPress}
                    />
                    <ActivityCompletion
                      onVerificationPress={onCompletionEventVerificationPress}
                    />
                  </FormInner>
                </ContainerInner>
                <StyledFormFooter canSubmit={true} />
              </FormIntegration>
            </Fragment>
          )}
        </Formik>
      )}
      {(!values || isUpdateIntegrationsLoading || isIntegrationsLoading) && (
        <FullScreenLoader />
      )}
      <Modal
        type={modalData.type}
        title={modalData.title}
        mainButton={
          modalData.showMainButton
            ? {
                text: modalData.mainButtonText || 'Okay',
                onClick: modalData.onClick
              }
            : undefined
        }
        isOpen={modalData.isOpen}
        onClose={modalData.onClose}
        children={modalData.children}
      ></Modal>

      {/*Verification progress modal*/}
      <Modal
        title="Verification processing…"
        isOpen={verificationLoading}
        canClose={false}
        type="progress"
      ></Modal>

      <CompleteAccountSetUp
        onModalClose={() => {
          setCheckSetupModal(false);
          history.push(routes.MY_OPPORTUNITIES);
        }}
        defaultAction={completeDefaultAction}
        check={checkSetupModal}
      />
    </StandardFormContainer>
  );
};

export default BusinessForm;
