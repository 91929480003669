import React, { FC, ReactType } from 'react';
import { ThemeProvider } from 'styled-components';
import { StaticRouterContext, StaticRouterProps } from 'react-router';
import {
  BrowserRouter,
  BrowserRouterProps,
  StaticRouter
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider as ApolloProvider } from './apollo';

import GlobalStyles from './styles/styled';
import { theme } from './styles/theme';
import Amplify from 'aws-amplify';
import { config } from './config/amplify';
import MetaData from './components/Meta';
import ScrollTop from 'components/ScrollTop';
import AuthProvider from 'providers/AuthProvider';
import Routes from './Routes';

interface IApp {
  location?: string | object;
  serverContext?: StaticRouterContext;
  apolloClient?: any;
}

Amplify.configure(config);

const App: FC<IApp> = ({ serverContext, apolloClient, location }) => {
  const Router: ReactType<
    StaticRouterProps | BrowserRouterProps
  > = serverContext ? StaticRouter : BrowserRouter;
  const routerProps = serverContext ? { serverContext, location } : {};
  return (
    <Router {...routerProps}>
      
      <MetaData />
      <ApolloProvider client={apolloClient} isServer={!!serverContext}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <GlobalStyles />
            <ScrollTop />
            <LastLocationProvider>
              <Routes />
            </LastLocationProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
      
    </Router>
  );
};

export default App;
