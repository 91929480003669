import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import AuthPageLayout from 'layouts/AuthPageLayout';
import SignUpEmailForm from 'components/SignUpEmailForm';
import AuthReturnLink from 'components/AuthReturnLink';
import ShopifyAuthForm from 'components/ShopifyAuthForm';
import shopifyAuthEvents from 'constants/shopifyAuthEvents';
import routes from 'constants/routes';
import authMethods from 'constants/authMethods';
import { SHOPIFY_AUTH_REDIRECT_URL } from 'constants/shopifyUrls';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK
} from 'constants/outerLinks';
import { googleSignIn as onAuthByGoogle } from 'services/auth';
import SignUpError, {
  Props as SignUpErrorProps
} from 'components/SignUpError/SignUpError';
import {
  shopifyErrors,
  FALLBACK_AUTH_ERROR_MESSAGE,
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL
} from 'constants/errors';
import {
  StyledAuthMethods,
  StyledShopifyAuthInfo,
  Disclaimer,
  StyledLink
} from './styles';

const SignUpPage: FC<RouteComponentProps> = ({ location }) => {
  const [isShopifyInfoShown, setShopifyInfo] = useState(false);
  const [error, setShopifyError] = useState<string | null>(null);
  const [modalData, setModalData] = useState<SignUpErrorProps>({
    title: FALLBACK_AUTH_ERROR_MESSAGE,
    text: ' ',
    isOpen: false,
    email: ''
  });

  const closeErrorModal = () => {
    setModalData({
      isOpen: false
    });
  };

  const findErrorMessageByCode = (code: string) => {
    const error = shopifyErrors.find((e) => e.code === code);

    return error?.message;
  };

  const handleShopifyLogin = () => {
    window.location.href = SHOPIFY_AUTH_REDIRECT_URL;
  };

  useEffect(() => {
    const {
      authViaShopify,
      errorMessage,
      shop,
      shopifyAuthError: errorCode,
      shopifyEmail
    } = queryString.parse(location.search);

    if (authViaShopify) {
      setShopifyInfo(true);

      if (errorCode) {
        const shopifyError =
          (typeof errorMessage === 'string' && errorMessage) ||
          (typeof errorCode === 'string' && findErrorMessageByCode(errorCode));
        const error = shopifyError || FALLBACK_AUTH_ERROR_MESSAGE;

        if (shopifyError) {
          setModalData({
            ...modalData,
            email:
              typeof shopifyEmail == 'string'
                ? shopifyEmail || ''
                : (shopifyEmail && shopifyEmail[0]) || '',
            title: FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
            text: FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL,
            isOpen: true
          });
        } else {
          setModalData({
            ...modalData,
            email:
              typeof shopifyEmail == 'string'
                ? shopifyEmail || ''
                : (shopifyEmail && shopifyEmail[0]) || '',
            isOpen: true
          });
        }

        setShopifyError(error);
      } else if (typeof shop === 'string' && typeof shopifyEmail === 'string') {
      }
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <>
      <AuthPageLayout
        subtitle="Business Sign Up"
        returnLink={
          <AuthReturnLink
            accentedText="Log in"
            mainText="Already have a business account?"
            to={routes.LOGIN}
          />
        }
        renderContent={(authMethod, selectAuthMethod) => (
          <>
            {authMethod === authMethods.EMAIL && (
              <SignUpEmailForm onReturnBack={() => selectAuthMethod(null)} />
            )}
            {authMethod === authMethods.SHOPIFY && (
              <ShopifyAuthForm
                authEvent={shopifyAuthEvents.SIGN_UP}
                onReturnBack={() => selectAuthMethod(null)}
              />
            )}
            {!authMethod && (
              <>
                <StyledAuthMethods
                  authProcedureName="Sign up"
                  onAuthByEmail={() => selectAuthMethod(authMethods.EMAIL)}
                  onAuthByGoogle={onAuthByGoogle}
                  onAuthByShopify={handleShopifyLogin}
                />
                <Disclaimer>
                  By continuing, you agree to the
                  <StyledLink href={TERMS_OF_SERVICE_LINK} target="_blank">
                    Drum Terms of Service
                  </StyledLink>{' '}
                  and
                  <StyledLink href={PRIVACY_POLICY_LINK} target="_blank">
                    Privacy Policy
                  </StyledLink>
                </Disclaimer>
                {isShopifyInfoShown && <StyledShopifyAuthInfo error={error} />}
                <SignUpError {...modalData} onClose={closeErrorModal} />
              </>
            )}
          </>
        )}
      />
    </>
  );
};

export default SignUpPage;
