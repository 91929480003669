import styled from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';
import { SecondaryButton } from '../Buttons';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

export const Title = styled.h6`
  margin-bottom: 11px;
  font-size: 13px;
`;

export const Content = styled.code<ITheme>`
  display: block;
  padding: 20px 0px 20px 15px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background-color: ${({ theme }: ITheme) => theme.colors.white};
  font-family: ${({ theme }: ITheme) => theme.fontFamily.raleway};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.regular};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  line-height: 18px;
`;

export const ScriptBox = styled.p`
  padding-left: 30px;
`;

export const Button = styled(SecondaryButton)`
  width: 200px;
  font-size: 14px;
  align-self: flex-end;
  padding: 7px 0;
  margin-top: 17px;
  ${breakpoint('xs', 'md')`
    align-self: center;
  `}
`;