import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FullScreenLoader, Body1, Swiper, GlassPanel } from 'uikit';
import CreationProgress from 'components/CreationProgress';
import PageHeader from 'components/PageHeader';
import GeneralLayout from 'layouts/GeneralLayout';
import routes from 'constants/routes';
import {
  OPPORTUNITY_TEMPLATE_PARAM,
  STEPS,
  OPPORTUNITY_SETUP_GUIDE_URL,
  getTemplates
} from 'constants/createOpportunityParams';
import DrummerView from 'components/OpportunityPreview/components/DrummerView';
import {
  Container,
  Content,
  ProgressContainer,
  Title,
  TemplateListContainer,
  TemplateList,
  TemplateListMobile,
  TemplateItem,
  TemplateDescription,
  TemplatePreviewButton,
  Slide,
  ViewExamplesLink,
  ViewContainer
} from './styles';
import { useGetBusinessRepresentationQuery } from '../../graphql';

const CreateOpportunityPage: FC<RouteComponentProps> = ({ history }) => {
  const [templates, setTemplates] = useState<Array<any>>([]);
  const {
    data: businessData,
    loading: businessLoading
  } = useGetBusinessRepresentationQuery({
    fetchPolicy: 'no-cache'
  });
  const handleProgressStepClick = () => {};

  const handleTemplateButtonClick = (index: number) => {
    const paramString =
      index === 2 ? '' : `?${OPPORTUNITY_TEMPLATE_PARAM}=${index}`;
    history.push(`${routes.CREATE_OPPORTUNITY_FORM}${paramString}`);
  };

  useEffect(() => {
    setTemplates(
      getTemplates(businessData?.getCurrentBusiness?.businessName || '')
    );
  }, [businessData]);

  return (
    <GeneralLayout>
      <Container>
        <PageHeader
          title="Create Opportunity"
          link={{
            text: 'Back to Opportunities',
            to: routes.MY_OPPORTUNITIES
          }}
        >
          <ProgressContainer>
            <CreationProgress
              steps={STEPS}
              currentStep={1}
              onStepClick={handleProgressStepClick}
            />
          </ProgressContainer>
        </PageHeader>
        <Content>
          <Body1>
            An opportunity is a landing page that you create below that
            describes your product, service, or special discount. Referrers
            share your landing page with their network and drive customers to
            your website to complete a transaction.{' '}
            <ViewExamplesLink
              href={OPPORTUNITY_SETUP_GUIDE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View example opportunities
            </ViewExamplesLink>
          </Body1>

          <Title>Choose a template</Title>

          {businessData && templates.length && (
            <TemplateListContainer>
              <TemplateList>
                {templates.map((item: any, index) => (
                  <TemplateItem>
                    <ViewContainer>
                      <DrummerView
                        companyInfo={businessData}
                        showDrummerMaterials={false}
                        values={item}
                        showShareButtons={false}
                      ></DrummerView>
                      <GlassPanel
                        onClick={() => handleTemplateButtonClick(index)}
                      ></GlassPanel>
                    </ViewContainer>
                    <TemplateDescription>
                      {item.blockDescription}
                    </TemplateDescription>
                    <TemplatePreviewButton
                      onClick={() => handleTemplateButtonClick(index)}
                    >
                      {item.selectButtonText}
                    </TemplatePreviewButton>
                  </TemplateItem>
                ))}
              </TemplateList>

              <TemplateListMobile>
                <Swiper>
                  {templates.map((item: any, index) => (
                    <Slide>
                      <TemplateItem>
                        <ViewContainer>
                          <DrummerView
                            companyInfo={businessData}
                            showDrummerMaterials={false}
                            values={item}
                            showShareButtons={false}
                          ></DrummerView>
                          <GlassPanel
                            onClick={() => handleTemplateButtonClick(index)}
                          ></GlassPanel>
                        </ViewContainer>
                        <TemplateDescription>
                          {item.blockDescription}
                        </TemplateDescription>
                        <TemplatePreviewButton
                          onClick={() => handleTemplateButtonClick(index)}
                        >
                          {item.selectButtonText}
                        </TemplatePreviewButton>
                      </TemplateItem>
                    </Slide>
                  ))}
                </Swiper>
              </TemplateListMobile>
            </TemplateListContainer>
          )}
        </Content>
      </Container>
      {businessLoading && <FullScreenLoader />}
    </GeneralLayout>
  );
};

export default CreateOpportunityPage;
