import React, { FC } from 'react';
import {  useFormikContext } from 'formik';
import { InputBase, FieldFooter} from 'uikit';
import { EmailCampaignFormType } from '../../../../validationSchema';
import { Container } from './styles';

const EmailFrom: FC = () => {
  const { handleChange, values, errors } = useFormikContext<
    EmailCampaignFormType
  >();

  return (
    <Container>
      <InputBase
        name={'fromEmail'}
        onChange={handleChange}
        value={values.fromEmail}
        placeholder="Example: hello@casper.com"
      />
      <FieldFooter
        error={errors.fromEmail}
      />
    </Container>
  );
};

export default EmailFrom;
