import React, { FC, ReactNode, useState } from 'react';
import Truncate from 'react-truncate';
import { ToggleTruncateButton } from './styles';

interface Props {
  children: ReactNode | string;
  maxLines?: number;
}

const MAX_DESCRIPTION_LINES = 3;

const TruncatedText: FC<Props> = ({
  children,
  maxLines = MAX_DESCRIPTION_LINES
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isTruncated, setTruncated] = useState(false);

  const handleTruncate = (truncated: boolean) => {
    if (truncated !== isTruncated) {
      setTruncated(truncated);
    }
  };

  return (
    <>
      <Truncate
        lines={!isExpanded && maxLines}
        ellipsis={
          <ToggleTruncateButton
            onClick={() => setExpanded(!isExpanded)}
            type="button"
          >
            Show more
          </ToggleTruncateButton>
        }
        onTruncate={handleTruncate}
      >
        {children}
      </Truncate>
      {!isTruncated && isExpanded && (
        <ToggleTruncateButton
          onClick={() => setExpanded(!isExpanded)}
          type="button"
        >
          Show Less
        </ToggleTruncateButton>
      )}
    </>
  );
};

export default TruncatedText;
