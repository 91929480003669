import { CustomerStatus } from '../../graphql';

export interface IFilter {
  id: string;
  label: string;
  description: string;
}
export const FILTERS: IFilter[] = [
  {
    id: CustomerStatus.Subscribed,
    label: 'Subscribed',
    description: 'Customer has provided their email address and has enrolled in your referral program.'
  },
  {
    id: CustomerStatus.Pending,
    label: 'Pending',
    description: 'Customer has received an email invitation but has yet to enroll in your referral program.'
  },
  {
    id: CustomerStatus.NotInvited,
    label: 'Not Invited',
    description: 'Customer has not received an email invitation to join the referral program.'
  },
  {
    id: CustomerStatus.Unsubscribed,
    label: 'Unsubscribed',
    description: 'Customer has requested to not receive any more emails via Drum regarding your referral program.'
  },
]

export const ACTION_OPTIONS = [
  {
    label: 'Actions',
    value: 'actions',
    disabled: true
  },
  {
    label: 'Tag',
    value: 'tag'
  },
  {
    label: 'Unsubscribe',
    value: 'unsubscribe'
  },
  {
    label: 'Delete',
    value: 'delete'
  }
];

export const IMPORT_OPTIONS = [
  {
    label: 'Upload .CSV',
    value: 'uploadCsv'
  },
  // {
  //   label: 'Sync with shopify',
  //   value: 'syncWithShopify'
  // },
  {
    label: 'Manually add a customer',
    value: 'manual'
  }
];

export const DEFAULT_FILTERS = {
  [CustomerStatus.Subscribed]: true,
  [CustomerStatus.Unsubscribed]: true,
  [CustomerStatus.NotInvited]: true,
  [CustomerStatus.Pending]: true
};