import React, { FC } from 'react';
import {
  Container,
  Contacts,
  Email,
  EmailLink
} from '../styled';

const IncorrectFileType: FC = () => {
  return (
    <Container>
      <p>
        Drum only supports the upload of a .CSV file. Please download our CSV
        template and try again. If you continue to have problems, please contact
        support.
      </p>
      <Contacts>
        <Email>
          <EmailLink href={'mailto:help@drum.io'}>help@drum.io</EmailLink>
        </Email>
      </Contacts>
    </Container>
  );
};

export default IncorrectFileType;
