import React, { FC } from 'react';
import IconProps from './IconProps';

const Share: FC<IconProps> = ({ className }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    className={className}
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="14"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.07959H13.8635V16.9999H0V3.07959Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1362 14.2998C12.1362 14.5628 12.0313 14.8114 11.8406 14.9998C11.6501 15.1883 11.3984 15.292 11.1324 15.292H2.73129C2.46513 15.292 2.21377 15.1883 2.02308 14.9998C1.83255 14.8114 1.72747 14.5626 1.72747 14.2998V5.77968C1.72747 5.5167 1.83255 5.26804 2.02308 5.07968C2.21377 4.89116 2.46513 4.78743 2.73129 4.78743H8.71653L10.4438 3.07975L2.73129 3.07959C2.00377 3.07959 1.31841 3.36102 0.80154 3.87202C0.284828 4.38302 0 5.06059 0 5.77968V14.2998C0 15.019 0.284667 15.6965 0.80154 16.2075C1.31841 16.7185 2.00377 17 2.73129 17H11.1324C11.8599 17 12.5453 16.7185 13.0622 16.2075C13.579 15.6965 13.8637 15.019 13.8635 14.2998V6.55286L12.1362 8.2607V14.2998Z"
        fill="#F95373"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0095 0H13.5795H11.3486C10.8015 0 10.358 0.443492 10.358 0.990512C10.358 1.53769 10.8015 1.98102 11.3486 1.98102H11.5987H13.6183L12.19 3.40915L11.9073 3.69195L10.44 5.1593L5.28999 10.3091C4.90334 10.6959 4.90334 11.3232 5.28999 11.7099C5.67695 12.0967 6.30401 12.0967 6.69082 11.7099L11.7946 6.60617L13.2904 5.11056L13.591 4.80997L15.0191 3.38184V5.40161V5.65138C15.0191 6.1984 15.4625 6.6419 16.0095 6.6419C16.2831 6.6419 16.5306 6.53106 16.7099 6.35179C16.8892 6.17252 17 5.92481 17 5.65138V3.42059V0.990354C17 0.72772 16.8957 0.475884 16.7099 0.290104C16.5241 0.104323 16.2723 0 16.0095 0Z"
      fill="#F95373"
    />
  </svg>
);

export default Share;
