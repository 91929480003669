import React, { FC } from 'react';
import Modal from 'react-modal';
import {
  Container,
  contentStyles,
  StyledCloseButton,
  StyledCloseRoundIcon,
  Title
} from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PreviewModal: FC<Props> = ({ isOpen, onClose, children }) => (
  <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{ content: contentStyles, overlay: {
      zIndex: 2
    }, }}
  >
    <StyledCloseButton onClick={onClose} type="button">
      <StyledCloseRoundIcon />
    </StyledCloseButton>
    <Container>
      <div>
        <Title>Preview</Title>
        {children}
      </div>
    </Container>
  </Modal>
);

export default PreviewModal;
