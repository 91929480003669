import styled from 'styled-components';
import { PrimaryButton, H3 } from 'uikit';

export const Container = styled.div`
  position: relative;
  margin-top: -40px;
  text-align: initial;
`;
export const FormContent = styled.div`

`;
export const FormTitle = styled(H3)`
 margin-bottom: 10px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;
export const SubmitButton = styled(PrimaryButton)`
  width: 232px;
`;

export const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 2px;
  & > div:nth-of-type(1) {
    margin-bottom: 13px;
    margin-left: 10px;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold}
  }

  input {
    &::placeholder {
      font-style: italic;
    }
    border-radius: 5px;
    box-shadow: none;
    box-sizing: border-box;

    &,
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
    }
  }
`;

