import React from 'react';
import { Paragraph, StyledLink } from './styles';
import { SCHEDULE_CALL_LINK } from 'constants/outerLinks'
const presets: any = {
  //Billing
  billing: [
    {
      title: 'How do Payments work?',
      content: (
        <Paragraph>
          Drum will look for any successful redemptions for your opportunities
          over the last week, and then charge you for any commissions that need
          to be paid out to Drummers. Drum handles the payments to Drummers
          directly. You will be charged weekly (On Mondays) and sent an overview
          of the charges via email.
        </Paragraph>
      )
    },
    {
      title: 'What does Drum cost?',
      content: (
        <Paragraph>
          Drum is free to try. (yes, really) You only pay for the opportunities
          that result in a customer taking the action you want to pay a
          commission for. (Ex: A purchase through your online store) You’ll
          never pay simply for giving it a try.
        </Paragraph>
      )
    },
    {
      title: 'Is my financial information safe?',
      content: (
        <Paragraph>
          Safeguarding individuals’ personal information is a priority for Drum.
          We take all necessary measures to protect personal information. We
          partner with Hyperwallet (A PCI Level 1 compliance service provider,
          SOC1, SOC2, and ISO27001 certified organization) to ensure your
          financial information is secure.
        </Paragraph>
      )
    },
    {
      title: 'Can I dispute a charge?',
      content: (
        <Paragraph>
          We’re always happy to help should you have questions about any billing
          you encounter. Send us a request at{' '}
          <StyledLink href="mailto:help@drum.io" target="_blank">
            help@drum.io
          </StyledLink>{' '}
          and we’ll work together to sort through any questions.
        </Paragraph>
      )
    }
  ],
  //Profile
  profile: [
    {
      title: 'How does Drum work?',
      content: (
        <Paragraph>
          Drum helps you create your own referral program. You’ll deliver
          commission-based incentives to anyone who refers your business by
          using the Drum app. You will only pay for performance and never just
          to use our service.
        </Paragraph>
      )
    },
    {
      title: 'What does it cost?',
      content: (
        <Paragraph>
          Drum is 100% free to use. You are only charged the reward amount you
          specified when a referrer shares your offer with a customer that
          results in the completion of the action on your site you defined.
          Example: You offer a reward of 10% of the shopping cart total for
          anyone who refers a customer who makes a purchase on your site. A
          referrer shares your offer and the customer purchases $100 worth of
          goods. The referrer would earn a reward of $10. During this free
          period, Drum is waiving our 15% per transaction fee.
        </Paragraph>
      )
    },
    {
      title: 'What is an opportunity?',
      content: (
        <Paragraph>
          An opportunity is a listing you will create that referrers will use to
          promote your business. You may provide a referral reward to your
          opportunity that is only paid out when a referrer shares your offer
          with a customer who completes your desired translation on your site.
          You can view some{' '}
          <StyledLink
            href="https://help.drum.io/hc/en-us/articles/360050181093-What-kind-of-offer-should-I-create-for-my-e-commerce-business-"
            target="_blank"
          >
            example opportunities here
          </StyledLink>{' '}
          to give you a few ideas.
        </Paragraph>
      )
    },
    {
      title: 'Who can refer my opportunities?',
      content: (
        <Paragraph>
          Anyone using the Drum app will see your opportunities and be able to
          share them with their network. We recommend actively encouraging your
          loyal existing customers to take part in your Drum-based referral
          program to promote on your behalf. Once you have a live opportunity
          posted you can invite your customers to your opportunity by clicking
          on your profile icon, then choosing Invite your customers.
        </Paragraph>
      )
    }
  ],
  //Tracking
  tracking: [
    {
      title: 'Why do I need to set up customer tracking?',
      content: (
        <Paragraph>
          Customer tracking allows Drum to report back the effectiveness of your
          referral program. With tracking, we can tell you how many sales were
          made because of Drum referrals, award commission to the correct
          referrer for their efforts, and determine who your most successful
          referrers are.
        </Paragraph>
      )
    },
    {
      title: 'What is being tracked on my site?',
      content: (
        <Paragraph>
          Tracking allows for Drum to know who has sent referrals to your web
          site from your Drum opportunities and then confirm when that referral
          has completed a transaction on your web site. No personal information
          or other unrelated data is collected.
        </Paragraph>
      )
    },
    {
      title: 'Can I get help setting this up?',
      content: (
        <Paragraph>
          Absolutely! We are here to help.
          <StyledLink
            href={SCHEDULE_CALL_LINK}
            target="_blank"
          >
            Click here to set up some time
          </StyledLink>{' '}
          to chat with our team and get an onboarding scheduled for your
          business.
        </Paragraph>
      )
    },
    {
      title: 'What if I’m unsure which platform my site was built on?',
      content: (
        <Paragraph>
          We are here to help.
          <StyledLink
            href={SCHEDULE_CALL_LINK}
            target="_blank"
          >
            Click here to set up some time to talk
          </StyledLink>{' '}
          with our team or email us at
          <StyledLink href="mailto:help@drum.io" target="_blank">
            help@drum.io
          </StyledLink>{' '}
          and we can work together to get you set up.
        </Paragraph>
      )
    }
  ]
};

export default presets;
