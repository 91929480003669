import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerInput from './DatePickerInput';

interface Props {
  className?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const DatePickerComponent: FC<Props> = ({
  className,
  minDate,
  maxDate,
  value,
  onChange
}) => (
  <div className={className}>
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      selected={value}
      onChange={onChange}
      isClearable
      customInput={<DatePickerInput />}
    />
  </div>
);

export default DatePickerComponent;
