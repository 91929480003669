import styled from 'styled-components';
import { ErrorTextMixin } from '../Typography';

const FieldError = styled.div`
  ${ErrorTextMixin};
  margin-top: 3px;
  min-height: 18px;
`;;

export default FieldError;
