import React, { FC } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { RadioButton, Emphasized, FieldFooter } from 'uikit';
import { EmailCampaignFormType } from '../../../../validationSchema';
import { InputGroup } from '../../styles';
import { SetField } from '../../../../types';
import {
  Container,
  RadioButtonContainer,
  RadioLabel,
  CustomersNumberLabel,
  SelectedTagsContainer,
  SelectedTagsContainerInner,
  SelectedTag,
  TagName,
  RemoveTagButton,
  TagFieldContainer,
  RadioLabelText
} from './styles';
import TagsAutosuggest, { ITag } from 'components/TagsAutosuggest/TagsAutosuggest';
import { CloseRoundIcon } from 'assets/svg';

interface Props {
  itemsCount?: number,
  onRemove: (tag: ITag, setField: SetField, values: EmailCampaignFormType) => void;
  onAdd: (tag: ITag, setField: SetField, values: EmailCampaignFormType) => void;
}

const EmailFrom: FC<Props> = ({ itemsCount, onAdd, onRemove }) => {
  const { values, errors, setFieldValue } = useFormikContext<
    EmailCampaignFormType
  >();

  return (
    <Container>
      <InputGroup>
        {!!values.canSetAllCustomers && 
          <RadioButtonContainer>
            <RadioButton id="all" name="sendType" disabled={!values.canSetAllCustomers}>
              <RadioLabel>
                <CustomersNumberLabel>Customers imported into Drum: <Emphasized>{itemsCount || 0}</Emphasized></CustomersNumberLabel>
              </RadioLabel>
            </RadioButton>
          </RadioButtonContainer>
        }
        <RadioButtonContainer>
          <RadioButton id="tags" name="sendType">
            <RadioLabel><RadioLabelText>Only customers tagged with</RadioLabelText> 
              
            </RadioLabel>
            
          </RadioButton>
          <FieldArray
              name="tagsList"
              render={() => (
                <TagFieldContainer>
                  <TagsAutosuggest
                    selectedTags={[]}
                    onSelect={(tag) => onAdd(tag, setFieldValue, values)}
                    allowNotExistingTags={false}
                    filter={{notUsedInCampaigns: true}}
                  />
                  <FieldFooter error={errors?.sendToFilter?.tags as string} showAlways={false}/>
                </TagFieldContainer>
              )}
            />
          

        </RadioButtonContainer>

        <SelectedTagsContainer>
          <SelectedTagsContainerInner>
            {(values.sendToFilter?.tags?.in || []).map((tag, index) => (
              <SelectedTag key={tag.id}>
                <TagName>{tag.name}</TagName>
                <RemoveTagButton
                  onClick={(e) => {
                    onRemove(tag, setFieldValue, values);
                  }}
                  type="button"
                >
                  <CloseRoundIcon />
                </RemoveTagButton>
              </SelectedTag>
            ))}
          </SelectedTagsContainerInner>
        </SelectedTagsContainer>
        <FieldFooter
          error={errors.canSetAllCustomers}
        />
      </InputGroup>
      
    </Container>
  );
};

export default EmailFrom;
