import React, { FC } from 'react';
import IconProps from './IconProps';

const Logo: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
  >
    <defs>
      <style>
        {`
          .cls-1_1{
            fill: #fff;
          }

          .cls-2_1{
            fill: #f95373;
          }

          .cls-3_1 {
            mask: url(#mask);
          }

          .cls-4_1{
            fill:#17aacf;
          }

          .cls-5_1{
            mask:url(#mask-2);
          }
        `}
      </style>

      <mask
        id="mask"
        x="20.6"
        y="15.15"
        width="16.79"
        height="20.14"
        maskUnits="userSpaceOnUse"
      >
        <g transform="translate(-168.49 -29.92)">
          <g id="mask0">
            <path className="cls-1_1" d="M189.09,45.07h16.79V65.21H189.09Z" />
          </g>
        </g>
      </mask>
      <mask
        id="mask-2"
        x="8.47"
        y="14.17"
        width="9.52"
        height="9.51"
        maskUnits="userSpaceOnUse"
      >
        <g transform="translate(-168.49 -29.92)">
          <g id="mask1">
            <path className="cls-1_1" d="M177,44.08h9.52v9.5H177Z" />
          </g>
        </g>
      </mask>
    </defs>
    <title>Drum logo</title>
    <path
      className="cls-1_1"
      d="M192.49,77.92a24,24,0,1,0-24-24A24,24,0,0,0,192.49,77.92Z"
      transform="translate(-168.49 -29.92)"
    />
    <path
      className="cls-2_1"
      d="M185.29,56.87h0L180.23,61l-.06,0,0,0h0a1.15,1.15,0,1,0,1.63,1.62h0L186,57.59h0a.51.51,0,0,0,.13-.34.51.51,0,0,0-.85-.38"
      transform="translate(-168.49 -29.92)"
    />
    <g className="cls-3_1">
      <path
        className="cls-4_1"
        d="M203,48a9.34,9.34,0,0,0-6.93-2.95h-5a2,2,0,0,0,0,4.08h5a5.54,5.54,0,0,1,4.12,1.76,6.07,6.07,0,0,1,0,8.47,5.54,5.54,0,0,1-4.12,1.76h-5a2,2,0,0,0,0,4.08h5a9.32,9.32,0,0,0,6.93-3A10.24,10.24,0,0,0,203,48"
        transform="translate(-168.49 -29.92)"
      />
    </g>
    <path
      className="cls-4_1"
      d="M190.37,60.27h0a2,2,0,0,1-2.12-2v-6a2.12,2.12,0,0,1,4.24,0v6a2,2,0,0,1-2.12,2"
      transform="translate(-168.49 -29.92)"
    />
    <g className="cls-5_1">
      <path
        className="cls-2_1"
        d="M186.28,52.32h0L180,44.76l-.07-.09,0,0h0a1.72,1.72,0,1,0-2.43,2.43h0l7.7,6.33h0a.76.76,0,0,0,1.08-1.08"
        transform="translate(-168.49 -29.92)"
      />
    </g>
  </svg>
);

export default Logo;
