import React, { FC } from 'react';
import { Disclaimer, ErrorMessage } from './styles';

interface Props {
  className?: string;
  error: string | null;
}

const ShopifyAuthInfo: FC<Props> = ({ className, error }) => (
  <div className={className}>
    <Disclaimer>
      Cookies Required. Please enable cookies in your browser preferences to
      authorize with Shopify.
    </Disclaimer>
    {!!error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);

export default ShopifyAuthInfo;
