import React, { FC, useState } from 'react';
import { getImageS3Url } from 'helpers';
import { Props as PreviewProps } from '../../../../types';
import {
  Container,
  Content,
  SocialButtonsContainer,
  SocialButton,
  SocialIconBlock,
  SocialLabel,
  OfferPreview,
  OfferImageContainer,
  OfferImage,
  OfferTextContainer,
  MainButton,
} from './styles';
import {
  shareSocialMediaItems,
  socialMedias
} from '../../../../constants';
import {
  GetBusinessDetailsQuery
} from '../../../../../../graphql';

export interface Props extends PreviewProps {
  business: GetBusinessDetailsQuery,
  onButtonClick?: (socialMedia: socialMedias) => void,
}

const Social: FC<Props> = ({ config, business, offer, onButtonClick }) => {
  const [selectedMedia, setSelectedMedia] = useState<socialMedias>(socialMedias.facebook);

  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      <Content>
        <SocialButtonsContainer>
          {
            shareSocialMediaItems.map((item) => (
              <SocialButton className={item.id === selectedMedia ? 'active' : ''} onClick={() => setSelectedMedia(item.id)}>
                <SocialIconBlock>
                  {item.icon}
                </SocialIconBlock>
                <SocialLabel>{item.name}</SocialLabel>
              </SocialButton>
            ))
          }
        </SocialButtonsContainer>  
        <OfferPreview>
          <OfferImageContainer>
            <OfferImage src={getImageS3Url(offer?.coverPhoto?.styles?.[0]?.path) || ''} alt="offer_cover"/>
          </OfferImageContainer>
          <OfferTextContainer>
            Check out this great offer from {business.getCurrentBusiness?.businessName}<br/> {offer?.title}
          </OfferTextContainer>
        </OfferPreview>    

      </Content>
      <MainButton 
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={() => {onButtonClick && onButtonClick(selectedMedia)}}
      >
        Share
      </MainButton>
    </Container>
  );
};

export default Social;
