import styled from 'styled-components';
import { PreviewContainer } from '../../styles';
import { Tab, Tabs, TabList, TabPanel } from 'uikit';

export const Container = styled(PreviewContainer)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	* {
		font-family: inherit !important;
	}
`;
export const Header = styled.pre`
	line-height: 21px;
	margin-bottom: 33px;
	font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1.33;
	white-space: break-spaces;
	overflow: hidden;
`;
export const BodyParagraph = styled.pre`
	font-size: ${({ theme }) => theme.size.body2};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1.57;
	white-space: break-spaces;
	overflow: hidden;
`;
export const TopContent = styled.pre`

`;
export const StyledTabs = styled(Tabs)`
	margin-top: 35px;
`;
export const StyledTabList = styled(TabList)`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
`;
export const StyledTab = styled(Tab)`
	height: auto;
	font-size: ${({ theme }) => theme.size.caption};
	line-height: 19px;
	color: ${({ theme }) => theme.colors.black};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	width: auto;
	min-width: auto;
	padding: 0 0 2px 0;
	margin-right: 0;
	border: none;
	border-radius: none;
	border-bottom: 3px solid transparent;

	&.react-tabs__tab--selected {
    color: ${({ theme }) => theme.colors.gold};
    border-bottom: 3px solid ${({ theme }) => theme.colors.gold};
  }
`;
export const StyledTabPanel = styled(TabPanel)`
	position: relative;
	border: none;
	height: 340px;
	margin-bottom: 25px;
	&.react-tabs__tab-panel--selected {
		border: none;
	}
`;

// StyledTabs,
//   StyledTabList,
//   StyledTab,
//   StyledTabPanel