import styled from 'styled-components';
import { ITheme } from '../../types';

export const Container = styled.div`
  margin-bottom: 15px;
`;

export const Button = styled.button<ITheme>`
  padding: 0;
  color: ${({ theme }: ITheme) => theme.colors.accentSecondaryColor};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  border: 0;
  background: transparent;
  text-align: left;
`;

export const Content = styled.section<ITheme>`
  margin-top: 15px;
  color: ${({ theme }: ITheme) => theme.colors.white};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
`;
