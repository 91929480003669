import styled from 'styled-components';
import { LinkTextMixin } from 'uikit';
import { breakpoint } from '../../helpers';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
  position: relative;
  margin-top: -40px;

  ${breakpoint('xs', 'lg')`
    justify-content: space-between;
    height: calc(100% + 40px);
  `}
`;
export const TextContainer = styled.div`

`;
export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 18px;
`;
export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  margin-bottom: 18px;
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body1};
`;

export const Warning = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.size.body1};
`;
