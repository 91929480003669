import React, { FC, useState, useEffect } from 'react';
import CustomSelect from '../CustomSelect';
import { MenuList, Option, SingleValue } from './components';
import { selectStyles } from './styles';
import { ITheme } from '../../types';
import { withTheme } from 'styled-components';
import { Offer } from 'graphql';

export interface IOption extends Offer{}


interface Props extends ITheme {
  className?: string;
  initialValue? : IOption;
  items: any[],
  onChange?: (option: IOption) => void
}

const OpportunitySelect: FC<Props> = ({ initialValue, className, theme, items, onChange }) => {
  const [selectedOption, setOption] = useState<IOption>();
  
  // TODO: remove mock after API integration
  const handleChange = (option: IOption ) => {
    setOption(option);
    onChange && onChange(option);
  }

  useEffect(() => {
    if(!initialValue || selectedOption) {
      return;
    }
      setOption(initialValue);
      onChange && onChange(initialValue);
  }, [initialValue, selectedOption, setOption, onChange])

  const options = items || [];

  return (
    <CustomSelect<IOption>
      className={className}
      label="Opportunities"
      components={{ MenuList, Option, SingleValue }}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      // We need to cast because `react-select` allows to choose array values for multiselect
      onChange={(option) => handleChange(option as IOption)}
      options={options}
      selectStyles={selectStyles(theme)}
      value={selectedOption}
    />
  );
};

export default withTheme(OpportunitySelect);
