import React, { FC } from 'react';
import { Modal } from 'uikit';
import { Container, Title, Paragraph } from './styles';

interface Props {
  isShopify: boolean;
  isOpen: boolean;
  shopifyTitle?: string;
  isEdit?: boolean;
  onClose: () => void;
  onMainButtonClick: () => void;
  onShopifyMainButtonClick: () => void;
  onShopifySecondaryButtonClick: () => void;
}

const CompletionSuccessModal: FC<Props> = ({
  isShopify,
  isOpen,
  shopifyTitle,
  isEdit,
  onClose,
  onMainButtonClick,
  onShopifyMainButtonClick,
  onShopifySecondaryButtonClick
}) => {
  return (
    <Modal
      type="success"
      isOpen={isOpen}
      onClose={onClose}
      childrenBeforeButtons={true}
      mainButton={
        isShopify && !isEdit
          ? {
              text: 'Open Shopify',
              onClick: onShopifyMainButtonClick
            }
          : {
              text: 'Back to Referral Webpage',
              onClick: onMainButtonClick
            }
      }
      secondaryButton={
        isShopify && !isEdit
          ? {
              text: 'Back to Referral Webpage',
              onClick: onShopifySecondaryButtonClick
            }
          : undefined
      }
    >
      {isEdit ? 
        <>
          <Container>
            <Title>Congratulations!</Title>
            <Paragraph>Your changes have been successfully saved</Paragraph>
          </Container>  
        </>
        :
        <>
        {isShopify ?
          <Container>
            <Title>Congratulations!</Title>
            <Paragraph>Your Shopify page has been created.</Paragraph>

            <Paragraph>To add your page titled</Paragraph>

            <Title>{shopifyTitle || ''}</Title>

            <Paragraph>to any of your navigation menus on your Shopify site, please log into Shopify and specify where you wish to display a link to this new page.</Paragraph>
          </Container>
          :
          <Container>
            <Title>Congratulations!</Title>
            <Paragraph>Your Referral Webpage has been created.</Paragraph>
          </Container>
        }
        </>
      }
    </Modal>
  );
};

export default CompletionSuccessModal;
