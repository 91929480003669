import styled from 'styled-components';
import { H3 } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`

`;
export const Title = styled(H3)`
  margin-bottom: 20px;
  &:nth-of-type(n+2) {
    margin-top: 20px;
  }

  ${breakpoint('xs', 'md')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
export const SelectContainer = styled(H3)`
  width: 355px;

  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
`;