import React, { FC } from 'react';
import { Container, Title } from './styles';
import { Collapse } from '../../uikit';
import presets from './presets';

type Props = {
  preset?: string
}

const FAQ: FC<Props> = ({ preset = 'profile'}) => {

  return (
    <Container>
      <Title>
        <h3>Frequently asked questions</h3>
        <span />
      </Title>
      {(presets[preset]).map((item: any, idx: number) => (
        <Collapse key={idx} title={item.title}>
          {item.content}
        </Collapse>  
      ))}
    </Container>
  );
};

export default FAQ;
