import React, { FC } from 'react';
import IconProps from './IconProps';

const Google: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#E0E0E0"
        d="M29.767 5.086A17.24 17.24 0 0017.495 0C7.932 0 .149 7.78.145 17.342a17.311 17.311 0 002.316 8.671L0 35l9.197-2.412a17.337 17.337 0 008.29 2.112h.008c9.561 0 17.345-7.782 17.349-17.344a17.238 17.238 0 00-5.077-12.27zM17.495 31.77h-.006a14.4 14.4 0 01-7.34-2.01l-.526-.311-5.458 1.43 1.458-5.319-.344-.545a14.377 14.377 0 01-2.204-7.672C3.078 9.395 9.546 2.93 17.5 2.93a14.326 14.326 0 0110.195 4.228 14.327 14.327 0 014.22 10.198c-.004 7.948-6.473 14.415-14.42 14.415z"
      ></path>
      <path
        fill="url(#paint0_linear)"
        d="M.797 34.003l2.349-8.577a16.52 16.52 0 01-2.21-8.274C.939 8.027 8.366.602 17.49.602a16.451 16.451 0 0111.712 4.854 16.447 16.447 0 014.845 11.71c-.004 9.125-7.432 16.55-16.556 16.55h-.007c-2.77-.001-5.493-.697-7.911-2.015L.797 34.004z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.207 9.98c-.32-.714-.659-.728-.964-.74-.25-.011-.536-.01-.821-.01-.286 0-.75.107-1.143.535-.393.43-1.5 1.466-1.5 3.574 0 2.108 1.536 4.146 1.75 4.432.214.286 2.964 4.75 7.32 6.468 3.62 1.427 4.357 1.143 5.142 1.072.786-.072 2.535-1.036 2.893-2.037.357-1 .357-1.858.25-2.037-.108-.179-.393-.286-.822-.5-.428-.215-2.535-1.251-2.928-1.394-.393-.143-.678-.215-.964.215-.286.428-1.107 1.393-1.357 1.679-.25.286-.5.322-.928.108-.429-.215-1.809-.668-3.446-2.127-1.274-1.136-2.134-2.539-2.384-2.968-.25-.428-.027-.66.188-.874.192-.192.429-.5.643-.75.214-.25.285-.43.428-.715.143-.286.071-.536-.036-.75-.107-.215-.94-2.334-1.32-3.181z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M29.625 5.031A17.043 17.043 0 0017.495.003C8.04.003.347 7.693.344 17.146a17.113 17.113 0 002.288 8.572L.2 34.602l9.092-2.384a17.138 17.138 0 008.195 2.087h.007c9.452 0 17.146-7.692 17.15-17.145a17.04 17.04 0 00-5.018-12.129zM17.495 31.41h-.006a14.236 14.236 0 01-7.255-1.986l-.521-.31-5.395 1.415 1.44-5.258-.34-.539a14.212 14.212 0 01-2.178-7.583C3.243 9.29 9.637 2.899 17.5 2.899a14.162 14.162 0 0110.077 4.179 14.163 14.163 0 014.171 10.081c-.003 7.857-6.398 14.25-14.254 14.25z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.422"
          x2="17.422"
          y1="34.003"
          y2="0.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038"></stop>
          <stop offset="1" stopColor="#60D66A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Google;
