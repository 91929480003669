import styled from 'styled-components';
import { Label, Body1, H2, Toggle } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

export const StepsContainer = styled.div`
  display: flex;  
  height: 72px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};

  ${breakpoint('xs', 'lg')`
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
  `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:64px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  box-shadow: inset 0px -4px 5px -5px rgba(0,0,0,0.1), inset 0 6px 5px -5px rgba(0,0,0,0.1);
  z-index: 1;
  position: relative;

  ${breakpoint('xs', 'lg')`
    display: none;
  `}

`;
export const StyledToggle = styled(Toggle)`

`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: none;
  display: flex;
  

  ${breakpoint('xs', 'lg')`
    flex-direction: column
  `}
`;

export const LeftPanel = styled.div`
  width: 420px;
  box-sizing: border-box;
  padding: 18px 18px 18px 20px;
  border-right: 7px solid ${({ theme }) => theme.colors.borderColor};

  ${breakpoint('xs', 'lg')`
    width: 100%;
    border-right: none;
  `}
`;

export const RightPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  & > div {
    width: 100%;
    position: sticky;
    top: 0;
  }
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
  &.responsive {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export const RightPanelInner = styled.div`
  &.responsive {
    width: 375px;
  }
`;

export const FormContainer = styled.div`
  
`;

export const Title = styled(H2)`
  margin: 0 0 25px 0; 
  padding-top: 35px;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 15px;

  & > span {
    ${breakpoint('xs', 'md')`
      font-size: ${({ theme }) => theme.size.body2};
    `}
  }
`;

export const Paragraph = styled.p`
  
`;
export const InputGroup = styled.div`
  margin-bottom: 30px;
`;

export const ColorBlock = styled.div`
  display: inline-flex;
  width: 135px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.colorLabelColor};
  box-sizing: border-box;
  border-radius: 22.5px;
  padding: 7px 8px;
  margin-right: 13px;
  cursor: pointer;

  &:last-of-type {
    margin-top: 17px;
  }

  &.wide {
    width: 165px;
  }

  ${breakpoint('xs', 'md')`
    margin-bottom: 8px;
    width: 100%;
  `}
`;
export const Color = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.colorLabelInner};
  width: 31px;
  height: 31px;
  box-sizing: border-box;
  border-radius: 50%;
`;
export const Corners = styled.div`
  position:relative;
  width: 28px;
  height: 28px;
  border: 2px solid #A0A0A0;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 8px;
  
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: 12px;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
  }
  &:after {
    content: '';
    position: absolute;
    height: calc(100% + 4px);
    width: 12px;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }
`;
export const ColorLabel = styled.span`
  margin-left: 13px;
  display: inline-block;
`;
export const FlexRow = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const PreviewButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 11px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  ${breakpoint('lg')`
    display: none;
  `}
`;

export const ModalParagraph = styled(Body1)`
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 40px;
  }
`;