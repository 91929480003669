import styled from 'styled-components';
import { Search } from 'assets/svg';
import { InputBase } from 'uikit';

export const Container = styled.div`
  position: relative;
`;

export const Input = styled(InputBase)`
  padding-left: 40px;
`;

export const Icon = styled(Search)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;
