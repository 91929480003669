import React, { FC, ReactNode } from 'react';
import { StyledLink, StyledCustomLink, Title, TitleContainer } from './styles';
import routes from '../../constants/routes';

interface Props {
  className?: string;
  aside?: ReactNode;
  title: string;
  backButtonText?: string;
  onBackClick?: () => void;
}

const PageHeading: FC<Props> = ({ className, title, aside, backButtonText = 'Back to Dashboard', onBackClick }) => (
  <div className={className}>
    <TitleContainer>
      <Title>{title}</Title>
      {aside}
    </TitleContainer>
    {onBackClick ? 
      <StyledCustomLink onClick={onBackClick}>
        &lt; {backButtonText}
      </StyledCustomLink>
      :
      <StyledLink to={routes.DASHBOARD}>
        &lt; {backButtonText}
      </StyledLink>
    }
  </div>
);

export default PageHeading;
