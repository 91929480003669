import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { H2, ButtonTextMixin, LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';

export const HeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  ${breakpoint('lg')`
    justify-content: space-between;
  `}
`;

export const StyledHeading = styled(H2)`
  margin-bottom: 10px;
`;

export const OpportunityStatusContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    width: 100%;
    margin-bottom: 10px;
  `}
`;

export const StatusCaption = styled.span`
  display: inline-block;
  margin-right: 4px;
  ${ButtonTextMixin};
`;

export const ViewExamplesLink = styled.a`
  ${LinkTextMixin};

  ${breakpoint('xs', 'lg')`
    order: 1;
    margin-top: 5px;
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const ReturnLink = styled(Link)`
  width: 100%;
  ${LinkTextMixin};

  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
