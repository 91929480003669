import styled from 'styled-components';
import { breakpoint } from 'helpers';
import SmallOpportunityCard from 'components/SmallOpportunityCard';
import OpportunityStatus from 'components/OpportunityStatus';
import { ITheme } from 'types';

export const DesktopTable = styled.table`
  width: 100%;
  ${breakpoint('xs', 'xl')`
    display: none;
  `};
`;

export const THead = styled.thead`
  border-bottom: solid 3px #dbdbdb;
  line-height: 2;
`;

export const TBody = styled.tbody`
  margin-top: 10px;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
`;

export const MobileTable = styled.div`
  ${breakpoint('xl')`
    display: none;
  `};
`;

export const MobileOpportunityItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
`;

export const Description = styled.p`
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TextInfo = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const TextGray = styled.span`
  color: #808080;
`;

export const AdditionInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 15px;
`;

interface CardTdProps extends ITheme {
  isClickable: boolean;
}

export const CardTd = styled.td<CardTdProps>`
  width: 400px;
  padding: 21px 0px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};

  ${breakpoint('md', 'lg')`
    width: 270px;
  `};

  ${breakpoint('lg', 'xl')`
    width: 300px;
  `};
`;

export const StyledOpportunityCard = styled(SmallOpportunityCard)`
  max-width: 95%;
`;

export const TD = styled.td`
  vertical-align: middle;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  ${breakpoint('md', 'lg')`
   font-size: ${({ theme }) => theme.size.caption};
   text-align: center;
`};
`;

export const DateTd = styled.td`
  vertical-align: middle;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  max-width: 100px;

  ${breakpoint('md', 'lg')`
   font-size: ${({ theme }) => theme.size.caption};
   text-align: center;
   padding-right: 8px;
 `};
`;

export const GreenTd = styled.td`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: #01aa65;
  vertical-align: middle;

  ${breakpoint('md', 'lg')`
    font-size: ${({ theme }) => theme.size.body2};
    text-align: center;
  `};
`;

export const EmptyListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;

  ${breakpoint('lg')`
    padding: 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px;
  `}
`;

export const StyledOpportunityStatus = styled(OpportunityStatus)`
  ${breakpoint('xs', 'xl')`
    margin-bottom: 10px;
  `}
`;
