import styled from 'styled-components';
import { ITheme } from 'types';
import { Link } from 'uikit';

export const Container = styled.div`

`;
export const StyledLink = styled(Link)`
  margin-top: 32px;
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.extraBold};
`;
