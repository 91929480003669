import React from 'react';
import {
  SectionTitle,
  Field,
  ButtonContainer,
  CheckmarkBox,
  ErrorBox
} from '../styled';
import {
  SubSectionContainer,
  SubSectionContainerAlt,
  SubSectionHeadingRight,
  VerifyButton,
  Paragraph,
  WarningParagraph,
  WarningText
} from './styled';
import { Codebox } from 'uikit';
import { useFormikContext } from 'formik';
import { StandardIntegration } from '../validationSchemaStandard';
import Checkmark from 'assets/svg/Checkmark';

type Props = {
  onVerificationPress: (
    onVerifySuccess: () => void,
    onVerifyFail: () => void
  ) => void;
};

const ActivityCompletion: React.FC<Props> = ({ onVerificationPress }) => {
  const { values, setFieldValue } = useFormikContext<
    StandardIntegration
  >();

  const onVerifySuccess = () => {
    setFieldValue('completionVerified', true);
    setFieldValue('completionVerifiedFailed', false);
  };

  const onVerifyFail = () => {
    setFieldValue('completionVerified', false);
    setFieldValue('completionVerifiedFailed', true);
  };

  const handleVerificationPress = () => {
    onVerificationPress(onVerifySuccess, onVerifyFail);
  };

  return (
    <Field>
      <SectionTitle>
        <span>Step 2:</span> Install code snippet to track activity completion
      </SectionTitle>
      <SubSectionContainer>
        <Paragraph>
          The completion event code attributes credit and commission to the
          referring Referrer for the action a customer completing the online
          purchase and landing on the completion page. This code should be
          placed in the header of the Completion Event URL. Without it, you will
          not be able to track the effectiveness of Referrers promoting your
          business or reward them for their work.
        </Paragraph>
        <h5>Purchase Amount Data</h5>
        <WarningParagraph>
          To enable contingent commissions upon shopping cart total you will
          need to replace $amount in the code with the ID of the purchase amount
          field on your web site that captures the purchase. You will also need
          to replace the field value name in the code or your{' '}
          <WarningText>verification will fail.</WarningText> This code update
          will send Drum the purchase amount total so we may award the Referrers
          correctly.
        </WarningParagraph>
        <Codebox
          title="Event Snippet"
          content={values.completionCodeSnippet || ''}
        />
      </SubSectionContainer>
      <SubSectionContainerAlt>
        <SubSectionHeadingRight>
          Verify the installation{' '}
        </SubSectionHeadingRight>
        <WarningParagraph className="right">
          Once you have placed the code into your site, click the "Verify
          installation" button to ensure the snippet was installed properly.
        </WarningParagraph>
        <ButtonContainer>
          {values.completionVerified && (
            <CheckmarkBox>
              <Checkmark /> <span>Verified installation</span>
            </CheckmarkBox>
          )}
          {values.completionVerificationFailed && (
            <ErrorBox>
              <img src="/assets/error.png" alt="Unable to verify" />{' '}
              <span>Unable to verify</span>
            </ErrorBox>
          )}
          <VerifyButton type="button" onClick={handleVerificationPress}>
            Verify Installation
          </VerifyButton>
        </ButtonContainer>
      </SubSectionContainerAlt>
    </Field>
  );
};

export default ActivityCompletion;
