import React, {Fragment} from 'react';
import { Container, Title, Content, ScriptBox, Button } from './styled';
import copy from 'copy-to-clipboard';
import { SnippetCollapse } from 'uikit';

interface Props {
  title: string;
  content: string;
  useCollapse?: boolean;
}

const Codebox: React.FC<Props> = ({ title, content, useCollapse = true }) => {
  const scriptText = `<script>${content}</script>`;
  const scriptOpen = '<script>';
  const scriptClose = '</script>';

  const handleCopy = () => copy(scriptText);

  function renderContent() {
    return (
      <Content>
        {scriptOpen}
        <ScriptBox>{content}</ScriptBox>
        {scriptClose}
      </Content>
    )
  }

  return (
    <Container>
      <Title>{title}</Title>
      {useCollapse ? 
        <SnippetCollapse title="Show Code">
          {renderContent()}
        </SnippetCollapse> :
        <Fragment>
          {renderContent()}
        </Fragment>
      }
      <Button type="button" onClick={handleCopy}>Copy Code</Button>
    </Container>
  );
};

export default Codebox;
