import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { EmailCampaignFormType } from '../../../../validationSchema';
import { emailFonts } from '../../../../constants';
import { SetField } from '../../../../types';
import CustomSelect from 'components/CustomSelect';
import MenuList from 'components/MenuList';
import IImageUpload from 'types/ImageUpload';
import { getEmailCampaignImageUploadURL } from 'helpers';
import { ImageUpload, FieldFooter } from 'uikit';
import { getLogoURL } from '../../utils';
import { ColorBlock, Color, ColorLabel, Corners } from '../../styles';
import { Container, Title, SelectContainer } from './styles';

interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  onColorPick: (
    field: string,
    setField: SetField,
    values: EmailCampaignFormType,
    label: string
  ) => void;
  onCornersPick: (
    field: string,
    setField: SetField,
    values: EmailCampaignFormType,
    label: string
  ) => void;
  showUploadImage: boolean;
  onAddImage: (setFieldValue: any) => (imageData: IImageUpload) => void;
}

const EmailStyle: FC<Props> = ({
  onColorPick,
  onCornersPick,
  showUploadImage,
  onAddImage
}) => {
  const { values, setFieldValue, errors } = useFormikContext<
    EmailCampaignFormType
  >();

  return (
    <Container>
      {showUploadImage && (
        <>
          <Title>Background Image</Title>
          <ImageUpload
            allowMultipleUpload={false}
            onSuccess={onAddImage && onAddImage(setFieldValue)}
            previewURL={getLogoURL(values.emailStyle.coverImage)}
            getImageUploadUrl={getEmailCampaignImageUploadURL}
          />
          <FieldFooter error={errors.emailStyle?.coverImage} />
        </>
      )}
      {/* Button style */}
      <Title>Button Style</Title>
      <div>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'emailStyle.buttonStyle.buttonColor',
              setFieldValue,
              values,
              'Button Color'
            )
          }
        >
          <Color
            style={{
              backgroundColor: values.emailStyle.buttonStyle.buttonColor
            }}
          />
          <ColorLabel>Button color</ColorLabel>
        </ColorBlock>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'emailStyle.buttonStyle.textColor',
              setFieldValue,
              values,
              'Button Text Color'
            )
          }
        >
          <Color
            style={{ backgroundColor: values.emailStyle.buttonStyle.textColor }}
          />
          <ColorLabel>Text color</ColorLabel>
        </ColorBlock>
        <ColorBlock
          onClick={() =>
            onCornersPick(
              'emailStyle.buttonStyle.cornerRadius',
              setFieldValue,
              values,
              'Button Corner Radius'
            )
          }
        >
          <Corners />
          <ColorLabel>Corners</ColorLabel>
        </ColorBlock>
      </div>

      {/* Font */}
      <Title>Font</Title>
      <div>
        <SelectContainer>
          <CustomSelect<IOption>
            options={emailFonts}
            components={{ MenuList }}
            onChange={(option) => {
              setFieldValue('emailStyle.font', (option as IOption)?.value);
            }}
            value={{
              label: values.emailStyle.font,
              value: values.emailStyle.font
            }}
          />
        </SelectContainer>
      </div>

      {/* Background Color */}
      {/* <Title>Background Color</Title>
      <div>
        <ColorBlock onClick={() => onColorPick('emailStyle.backgroundColor', setFieldValue, values, 'Background Color')}>
          <Color style={{backgroundColor: values.emailStyle.backgroundColor}}/>
          <ColorLabel>Background Color</ColorLabel>
        </ColorBlock>
      </div> */}

      {/* Footer */}
      <Title>Footer</Title>
      <div>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'emailStyle.footerStyle.backgroundColor',
              setFieldValue,
              values,
              'Footer Color'
            )
          }
        >
          <Color
            style={{
              backgroundColor: values.emailStyle.footerStyle.backgroundColor
            }}
          />
          <ColorLabel>Background Color</ColorLabel>
        </ColorBlock>
        <ColorBlock
          onClick={() =>
            onColorPick(
              'emailStyle.footerStyle.textColor',
              setFieldValue,
              values,
              'Footer Text Color'
            )
          }
        >
          <Color
            style={{ backgroundColor: values.emailStyle.footerStyle.textColor }}
          />
          <ColorLabel>Text color</ColorLabel>
        </ColorBlock>
      </div>
    </Container>
  );
};

export default EmailStyle;
