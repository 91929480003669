import React, { FC, useState } from 'react';
import { Plus, Minus } from 'assets/svg';
import moment from 'moment';
import useCollapse from 'react-collapsed';
import DATE_FORMAT from 'constants/dateFormat';
import { getName } from '../../helpers';
import {
  Container,
  TopContainer,
  Row,
  DateBlock,
  TotalBlock,
  IconBlock,
  Block,
  BlockTitle,
  BlockValue,
  StyledLink
} from './styles';
import {formatCurrency} from 'helpers';

interface Props {
  data?: any;
  expanded?: boolean;
  isShopify?: boolean;
}

const HistoryCollapseItem: FC<Props> = ({
  data,
  expanded,
  isShopify
}) => {
  const [isExpanded, setExpanded] = useState(!!expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <Container>
      <TopContainer
        expanded={isExpanded}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          }
        })}
      >
        <DateBlock>
          <span>{moment(data.createdAt).format(DATE_FORMAT)}</span>
          <span>{moment(data.createdAt).format('LT')}</span>
        </DateBlock>
        <TotalBlock>
          {formatCurrency(data.amount)}
        </TotalBlock>
        <IconBlock>
          {!isExpanded ? <Plus /> : <Minus />}
        </IconBlock>
        
      </TopContainer>
      <div {...getCollapseProps()}>
        {/* Put content here*/}
        <Row>
          <Block className="referrer">
            <BlockTitle>Referrer</BlockTitle>
            <BlockValue className="referrer">{getName(data.drummer)}</BlockValue>
          </Block>

          <Block className="opportunity">
            <BlockTitle>Opportunity</BlockTitle>
            <BlockValue className="opportunity">{data.offer?.title || ''}</BlockValue>
          </Block>
        </Row>  
        <Row>
          {isShopify && (
            <Block className="transaction">
              <BlockTitle>Shopify Order ID</BlockTitle>
              <BlockValue className="transaction">
                <StyledLink target="_blank" href={`https://${data.shopDomain}/admin/orders/${data.shopifyOrderId}`}>{data.shopifyOrderId}</StyledLink>
              </BlockValue>
            </Block>
          )}
          

          <Block className="fees">
            <BlockTitle>Platform Fees</BlockTitle>
            <BlockValue className="fees">{formatCurrency(data.drumFee)}</BlockValue>
          </Block>

          <Block className="rewards">
            <BlockTitle>Reward Charges</BlockTitle>
            <BlockValue className="rewards">{formatCurrency(data.drummerReward)}</BlockValue>
          </Block>
        </Row>  
      </div>
    </Container>
  );
};

export default HistoryCollapseItem;
