import React, { FC } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Props as PreviewProps } from '../../../../types';
import { SendEmailFormType, sendEmailSchema } from '../../../../validationSchema';
import {
  Container,
  MainButton,
  Content,
  InputGroup,
  TextAreaGroup,
  InputLabel,
  StyledInput,
  StyledTextarea,
  StyledCheckbox,
  StyledFieldFooterContainer,
  StyledFieldFooter,
} from './styles';

export interface Props extends PreviewProps{
  initialValues: SendEmailFormType,
  onButtonClick?: () => void;
}

const RewardsApp: FC<Props> = ({ config, initialValues, onButtonClick }) => {

  const getError = (errors: FormikErrors<SendEmailFormType>) => {
    return errors.to || errors.from || errors.message;
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={sendEmailSchema}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={() => {}}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <InputGroup className={errors.to ? 'invalid' : ''}>
                <InputLabel>To:</InputLabel>
                <StyledInput
                  name={'to'}
                  onChange={handleChange}
                  value={values.to}
                />
              </InputGroup>
              <InputGroup className={errors.from ? 'invalid' : ''}>
                <InputLabel>From:</InputLabel>
                <StyledInput
                  name={'from'}
                  onChange={handleChange}
                  value={values.from}
                />
              </InputGroup>
              <TextAreaGroup className={errors.message ? 'invalid' : ''}>
                <StyledTextarea
                  name={'message'}
                  rows={3}
                  onChange={handleChange}
                  value={values.message}
                />
              </TextAreaGroup>

              <StyledFieldFooterContainer>
                <StyledFieldFooter
                  error={getError(errors)}
                />
              </StyledFieldFooterContainer>

              <StyledCheckbox
                onCheck={() => setFieldValue('sendLater', true)}
                onUncheck={() => setFieldValue('sendLater', false)}
                checked={values.sendLater}
                name={"sendLater"}
                text="Send my friend a reminder in 3 days"
              />
            </form>
          )}
        </Formik>
      </Content>
      <MainButton 
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={onButtonClick}
      >
        Send
      </MainButton>
    </Container>
  );
};

export default RewardsApp;
