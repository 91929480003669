import React, { FC } from 'react';
import {
  BillingInfoTd,
  BillingStatusTd,
  CheckMark,
  ConnectionStatusConnected,
  ConnectionStatusDisconnected,
  DesktopTable,
  MobileTable,
  SelectPaymentButton,
  StyledTick,
  Td,
  Tr,
  UserData
} from './styles';
import { Payment, PaymentStatus, PaymentProvider, Maybe } from '../../graphql';

interface Props {
  className?: string;
  onCheck: (provider: PaymentProvider | null) => void
  paymentAccounts?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Payment' } & Pick<
          Payment,
          'provider' | 'status' | 'default' | 'maskedNumber' | 'expirationDate'
        >
      >
    >
  >;
}

const BillingAccountsTable: FC<Props> = ({
  className,
  paymentAccounts = [],
  onCheck
}) => (
  <div className={className}>
    <DesktopTable>
      <tbody>
        {paymentAccounts?.map((method, index) => (
          <Tr>
            <BillingStatusTd>
              <CheckMark isActive={!!method?.default} onClick={() => {onCheck(method?.provider || null)}}>
                {!!method?.default && <StyledTick />}
              </CheckMark>
            </BillingStatusTd>
            <Td>
              {method?.provider === PaymentProvider.Paypal ? (
                <img src="/assets/paypal-logo.png" alt="paypal-logo" />
              ) : (
                <img src="/assets/credit-card.png" alt="" />
              )}
              {/* {method?.isChecking && <CheckingMessage>Checking</CheckingMessage>} */}
            </Td>
            <Td>
              <UserData>{method?.maskedNumber}</UserData>
            </Td>
            <Td>
              {method?.status === PaymentStatus.Activated ||
              method?.status === PaymentStatus.Linked ? (
                <ConnectionStatusConnected>Connected</ConnectionStatusConnected>
              ) : (
                <ConnectionStatusDisconnected>
                  Disconnected
                </ConnectionStatusDisconnected>
              )}
            </Td>
          </Tr>
        ))}
      </tbody>
    </DesktopTable>

    <MobileTable>
      <tbody>
        {paymentAccounts?.map((method, index) => (
          <Tr>
            <BillingStatusTd>
              <CheckMark isActive={!!method?.default} onClick={() => {onCheck(method?.provider || null)}}>
                {!!method?.default && (<StyledTick />)}
              </CheckMark>
            </BillingStatusTd>
            {method?.provider === PaymentProvider.Paypal ? (
              <Td colSpan={2}>
                <img src="/assets/paypal-logo.png" alt="paypal-logo" />
              </Td>
            ) : (
              <>
                <Td>
                  <img src="/assets/credit-card.png" alt="" />
                </Td>
              </>
            )}
            <BillingInfoTd>
              <div>
                <UserData>{method?.maskedNumber}</UserData>
              </div>
              <div>
                {method?.status === PaymentStatus.Activated ||
                method?.status === PaymentStatus.Linked ? (
                  <ConnectionStatusConnected>
                    Connected
                  </ConnectionStatusConnected>
                ) : (
                  <ConnectionStatusDisconnected>
                    Disconnected
                  </ConnectionStatusDisconnected>
                )}
              </div>
              {!method?.default && (
                <SelectPaymentButton>Make default</SelectPaymentButton>
              )}
            </BillingInfoTd>
          </Tr>
        ))}
      </tbody>
    </MobileTable>
  </div>
);

export default BillingAccountsTable;
