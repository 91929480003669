import * as yup from 'yup';
import moment from 'moment';
import { isDateValid } from 'helpers';
import {
  TEXTAREA_MAX_LENGTH,
  FIELD_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import { urlRegExp } from 'constants/regExp';
import { CommissionType, RewardActionType } from '../../graphql';

const MAX_IMAGES = 8;
const MIN_OPPORTUNITY_BUDGET = 0;

const MIN_FIXED_COMMISSION = 5;
const MIN_PERCENTAGE_COMMISSION = 1;

// TODO: merge branch with codegen and use generated types
export enum COMMISSION_TYPE {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT'
}

const opportunityDetailsSchema = yup.object({
  title: yup
    .string()
    .required('Title is required')
    .max(FIELD_MAX_LENGTH, `${FIELD_MAX_LENGTH} characters allowed`),
  urlDestination: yup
    .string()
    .required('URL is required')
    .matches(
      urlRegExp,
      'Invalid URL format.'
    ),
  description: yup
    .string()
    .required('Description is required')
    .max(TEXTAREA_MAX_LENGTH, `${TEXTAREA_MAX_LENGTH} characters allowed`),
  photos: yup
    .array()
    .of(
      yup.object().shape({
        path: yup.string().required('Required'),
        name: yup.string().required('Required')
      })
    )
    .required('Images are required')
    .max(MAX_IMAGES, `Cannot upload more than ${MAX_IMAGES} images.`)
});

const referralRewardSchema = yup.object({
  rewardActionType: yup
    .string()
    .oneOf(Object.values(RewardActionType), 'Please choose value from select')
    .nullable()
    .required('Action type is required'),
  commissionType: yup
    .string()
    .nullable()
    .test(
      'isCommissionTypeAllowed',
      'Please choose value from select',
      function(value: string) {
        const { rewardActionType } = this.parent;

        if (!rewardActionType) {
          return true;
        } else if (
          rewardActionType === RewardActionType.NavigateToSpecificPage
        ) {
          return value === CommissionType.Fixed;
        }

        return (
          value === CommissionType.Fixed || value === CommissionType.Percentage
        );
      }
    ),
  commissionAmount: yup
    .number()
    .when('commissionType', {
      is: (commissionType) => commissionType === COMMISSION_TYPE.FIXED,
      then: yup
        .number()
        .min(
          MIN_FIXED_COMMISSION,
          `Commission amount should be equal or more than $${MIN_FIXED_COMMISSION}`
        ),
      otherwise: yup
        .number()
        .min(
          MIN_PERCENTAGE_COMMISSION,
          `Commission amount should be equal or more than ${MIN_PERCENTAGE_COMMISSION}%`
        )
    })
    .required('Commission amount is required'),
  isMinimumPurchaseAmountRequired: yup.bool().when('rewardActionType', {
    is: (rewardActionType) =>
      rewardActionType === RewardActionType.NavigateToSpecificPage,
    then: yup.bool().required('Field is required')
  }),
  minimumPurchaseAmount: yup.number().when('isMinimumPurchaseAmountRequired', {
    is: true,
    then: yup
      .number()
      .required('Purchase amount is required')
      .min(1, 'Purchase amount must be at least $1')
  }),
  isBudgetLimited: yup.bool().when('commissionType', {
    is: (commissionType) => commissionType === COMMISSION_TYPE.FIXED,
    then: yup.bool().required('Field is required')
  }),
  budget: yup.number().when(['commissionType', 'isBudgetLimited'], {
    is: (commissionType, isBudgetLimited) =>
      commissionType === COMMISSION_TYPE.FIXED && isBudgetLimited,
    then: yup
      .number()
      .required('Opportunity budget is required')
      .test(
        'isBudgetExceedCommission',
        'Commission should not exceed opportunity budget',
        function(value: number) {
          const commissionAmount = this.parent.commissionAmount;

          return commissionAmount <= value;
        }
      )
      .min(
        MIN_OPPORTUNITY_BUDGET,
        'Opportunity budget should be equal or more than 0.'
      )
  })
});

const referrerEducationSchema = yup.object({
  noteForDrummer: yup
    .string()
    .max(TEXTAREA_MAX_LENGTH, `${TEXTAREA_MAX_LENGTH} characters allowed`)
    .notRequired(),
  educationMaterials: yup.array().of(
    yup.object({
      title: yup
        .string()
        .required('Link Title is required')
        .max(FIELD_MAX_LENGTH, `${FIELD_MAX_LENGTH} characters allowed`),
      url: yup
        .string()
        .required('URL is required')
        .matches(
          urlRegExp,
          'Invalid URL format.'
        )
    })
  )
});

const opportunityDatesSchema = yup.object({
  startDate: yup
    .string()
    .required('Start date is required')
    .test(
      'isValidDateFormat',
      'Please enter date in valid format',
      (date: string) => !date || isDateValid(date)
    )
    .test(
      'isDateInPast',
      'Start Date must be greater than now',
      (date: string) => {
        const startOfStartDay = moment(date).startOf('day');
        const startOfCurrentDay = moment().startOf('day');

        return startOfStartDay.diff(startOfCurrentDay) >= 0;
      }
    ),
  endDate: yup.string().when('startDate', {
    is: (startDate) => !!startDate,
    then: yup
      .string()
      .test(
        'isValidDateFormat',
        'Please enter date in valid format',
        (date: string) => !date || isDateValid(date)
      )
      .test(
        'isBeforeStart',
        'End Date must be greater than Start Date',
        function(endDate: string) {
          const { startDate } = this.parent;

          if (!isDateValid(startDate as string) || !isDateValid(endDate)) {
            return true;
          }

          const startOfEndDay = moment(endDate).startOf('day');
          const startOfStartDay = moment(startDate as string).startOf('day');

          return startOfEndDay.diff(startOfStartDay) >= 0;
        }
      )
  })
});

const validationSchema = yup.object({
  opportunityDetails: opportunityDetailsSchema,
  referralReward: referralRewardSchema,
  referrerEducation: referrerEducationSchema,
  opportunityDates: opportunityDatesSchema
});

export type CreateOpportunityType = yup.InferType<typeof validationSchema>;

export default validationSchema;
