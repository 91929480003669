import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import { Logo } from 'uikit';
import {
  Container,
  Content,
  Main,
  Header,
  PageContent,
  MobileLink,
  StyledPageMenu,
  SidebarColumn,
  SidebarContent,
  StyledFooter
} from './styles';

interface Props {
  children: ReactNode;
  link?: {
    to: string;
    text: string;
  };
  sidebar?: ReactNode;
}

const GeneralLayout: React.FC<Props> = ({ children, link, sidebar }) => (
  <Container>
    <Content>
      <Header>
        <Link to={routes.DASHBOARD}>
          <Logo />
        </Link>
        <StyledPageMenu isDashboardPage={false} />
      </Header>
      <Main className={!sidebar ? "no-sidebar" : ""}>
        <PageContent className={!sidebar ? "no-sidebar" : ""}>{children}</PageContent>
        <SidebarColumn>
          <SidebarContent>{sidebar}</SidebarContent>
        </SidebarColumn>
        {link && <MobileLink to={link.to}>{link.text}</MobileLink>}
      </Main>
    </Content>
    <StyledFooter />
  </Container>
);

export default GeneralLayout;
