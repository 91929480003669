import React, { FC } from 'react';
import cn from 'classnames';
import routes from 'constants/routes';
import { getIsProfileSetupCompleted } from 'helpers';
import { sendOpenedCustomersMenuTrackingEvent } from 'helpers/segment';
import { useGetBusinessDetailsQuery } from '../../graphql';
import {
  activeLinkStyle,
  Container,
  Links,
  StyledLink
} from './styles';
import AccountMenu from 'components/AccountMenu';
import trackedElementsIds from 'constants/trackedElementsIds';

export interface Props {
  className?: string;
  isDashboardPage: boolean;
}

const PageMenu: FC<Props> = ({ className, isDashboardPage }) => {
  const { data } = useGetBusinessDetailsQuery({ fetchPolicy: 'cache-first' });
  const isProfileSetupCompleted = getIsProfileSetupCompleted(data);

  return (
    <Container className={className}>
      <Links className={cn(isDashboardPage && 'isDashboard')}>
        <StyledLink
          activeStyle={activeLinkStyle}
          className={cn(!isDashboardPage && 'white')}
          to={routes.DASHBOARD}
          exact
          id={trackedElementsIds.topMenuBar.dashboardLink}
        >
          Dashboard
        </StyledLink>
        <StyledLink
          activeStyle={activeLinkStyle}
          className={cn(!isDashboardPage && 'white')}
          to={routes.MY_OPPORTUNITIES}
          exact
          id={trackedElementsIds.topMenuBar.opportunitiesLink}
        >
          Opportunities
        </StyledLink>
        <StyledLink
          activeStyle={activeLinkStyle}
          className={cn(!isDashboardPage && 'white')}
          to={routes.CUSTOMERS_MANAGEMENT}
          exact
          onClick={sendOpenedCustomersMenuTrackingEvent}
        >
          Customers
        </StyledLink>
      </Links>
      <AccountMenu
        isDashboardPage={isDashboardPage}
        isProfileSetupCompleted={isProfileSetupCompleted}
      />
    </Container>
  );
};

export default PageMenu;
