import styled from 'styled-components';
import { H3, Body1 } from 'uikit';

export const Container = styled.div`
  min-height: 200px;
`;
export const Title = styled(H3)`
  margin-bottom: 20px;
`;
export const Paragraph = styled(Body1)`
  margin-bottom: 20px;
`;



