import React, { FC, useState, useEffect } from 'react';
import {
  Container,
  StyledActivityFilter,
  StyledOpportunitySelect
} from './styles';
import { IDates } from '../ActivityFilter/ActivityFilter';
import { IOption as IOpportunityOption } from '../OpportunitySelect/OpportunitySelect';
import moment from 'moment';

export interface FilterData {
  opportunity: IOpportunityOption;
  date?: IDates;
}

interface Props {
  className?: string;
  opportunities: any;
  onChange: (data: FilterData) => void;
  initialValue: string | undefined;
}

const OpportunityFilters: FC<Props> = ({
  className,
  opportunities,
  onChange,
  initialValue
}) => {
  const allOpportunitiesValue = { id: '', title: 'All opportunities' };
  const opportunitiesArray = [...opportunities, allOpportunitiesValue];
  //const [filterInitialValue, setFilterInitialValue] = useState;

  const getInitialValue = (value?: string) => {
    let result = allOpportunitiesValue;
    if (value && value !== 'undefined' && opportunities) {
      result = opportunities.find((item: any) => item.id === initialValue);
    } else if (opportunities && opportunities.length === 1) {
      result = opportunities[0];
    }
    return result;
  };

  const [filters, setFilters] = useState<FilterData>({
    opportunity: getInitialValue(initialValue),
    date: {
      startDate: moment()
        .subtract(1, 'months')
        .toDate(),
      endDate: moment().toDate()
    }
  });

  const onOpportunitySelect = (option: IOpportunityOption) => {
    setFilters({ ...filters, opportunity: option });
  };
  const onDateSelect = (date: IDates) => {
    setFilters({ ...filters, date: date });
  };

  useEffect(() => {
    if (!filters.opportunity) return;
    onChange(filters);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <Container className={className}>
      <StyledOpportunitySelect
        initialValue={getInitialValue(initialValue)}
        items={opportunitiesArray}
        onChange={onOpportunitySelect}
      />
      <StyledActivityFilter onChange={onDateSelect} />
    </Container>
  );
};

export default OpportunityFilters;
