import * as yup from 'yup';
import {
  TEXTAREA_MAX_LENGTH,
  EMAIL_SUBJECT_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import every from 'lodash.every';
import { emailRegExp } from 'constants/regExp';
import { CampaignStatus } from '../../graphql';

const subjectDetailsSchema = yup.object({
  subjectLine: yup
    .string()
    .required('Subject line is required')
    .max(EMAIL_SUBJECT_MAX_LENGTH, `${EMAIL_SUBJECT_MAX_LENGTH} characters allowed`),
  emailBody: yup
    .string()
    .required('Body copy is required')
    .max(TEXTAREA_MAX_LENGTH, `${TEXTAREA_MAX_LENGTH} characters allowed`)
});

const styleSchema = yup.object({
  coverImage: yup
    .string()
    .nullable()
    .default('')
    .required('Cover image is required'),
  font: yup.string().required('Font is required'),
  backgroundColor: yup.string().required('Background color is required'),
  buttonStyle: yup.object({
    buttonColor: yup.string().required('Button color is required'),
    textColor: yup.string().required('Text color is required'),
    cornerRadius: yup.number().required('Corner radius is required')
  }),
  footerStyle: yup.object({
    backgroundColor: yup.string().required('Background color is required'),
    textColor: yup.string().required('Text color is required')
  })
});

const baseFields = {
  emails: yup.object({
    invitationEmail: subjectDetailsSchema,
    reminderEmail: subjectDetailsSchema,
    newOpportunityEmail: subjectDetailsSchema
  }),
  emailStyle: styleSchema || undefined,
  emailType: yup.string(),
  sendType: yup.string(),
  status: yup.mixed<CampaignStatus>().oneOf(Object.values(CampaignStatus)),
  fromEmail: yup
    .string()
    .test('invalid email', 'Invalid email address', function(value) {
      return !value || emailRegExp.test(value);
    })
    .required('Email is required'),
  sendToFilter: yup.object({
    tags: yup.object({
      in: yup.array().of(
        yup.object({
          id: yup.string(),
          name: yup.string()
        })
      )
    })
  }),
  templateStyle: yup.string(),
  canSetAllCustomers: yup
    .boolean()
    .test('tags are empty', 'Tags are required for this option', function(value) {
      if (!value && !this.parent.sendToFilter?.tags?.in?.length) {
        return false;
      }
      return true;
    })
};

const sendTestFields = {
  sendTest: yup.string()
  .test('invalid emails', 'Invalid emails', function(value) {
    const chunks = this.parent.sendTest.split(',');
    const trimmedChunks = chunks.map((item: string) => item.trim());
    return every(trimmedChunks, (item: string) =>
      emailRegExp.test(item)
    );
  })
};

const validationSchema = yup.object(baseFields);
export const sendTestSchema = yup.object(sendTestFields);

export type EmailCampaignFormType = yup.InferType<typeof validationSchema>;
export type SendTestFormType = yup.InferType<typeof sendTestSchema>;


export default validationSchema;
