import styled from 'styled-components';
import {
  PrimaryButton,
  CloseModalButton,
  Body1,
  LinkTextMixin
} from 'uikit';
import { breakpoint } from '../../helpers';
const containerHeight = '226px';

export const Container = styled.div`
  display: block;
  position: relative;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  height: ${containerHeight};

  ${breakpoint('xs', 'lg')`
    height: auto;
    width: 100%;
  `}
`;

export const UploadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  border: 1px dashed #9d9d9d;
  box-sizing: border-box;
  border-radius: 0;
  padding: 20px;

  ${breakpoint('xs', 'lg')`
    height: auto;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
  `}
`;
export const PreviewsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  border: 1px dashed #9d9d9d;
  box-sizing: border-box;
  border-radius: 0;
  padding: 20px;

  ${breakpoint('xs', 'lg')`
    height: auto;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
    margin-bottom: 10px;
    padding-left: 16px;
  `}
`;

export const Preview = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%:
`;
export const PreviewIcon = styled.div`
  position: relative;
`;
export const PreviewExtension = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 8px;
  bottom: 8px;
  left: 4px;
`;
export const PreviewText = styled(Body1)`
  display: inline-block;
  margin-left: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Caption = styled(Body1)`
  margin: 30px 0 27px;
  color: #818181;
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;

export const UploadButton = styled(PrimaryButton)`
  width: 232px;
  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;

export const ReplaceButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: none;
  ${LinkTextMixin};
  background-color: transparent;
`;

export const StyledCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
  ${breakpoint('xs', 'lg')`
    top: 5px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  ${breakpoint('xs', 'lg')`
    margin-top: 10px;
  `}
`;

export const ActionButton = styled(PrimaryButton)`
  width: 232px;
  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;
