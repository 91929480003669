import React, { FC, Fragment } from 'react';
import { components, MenuListComponentProps } from 'react-select';
import { IOption } from '../../ActivityFilter';
import { DDArrow } from '../../../CustomSelect/styles';
import { DropDownArrow } from 'assets/svg';
const MenuList: FC<MenuListComponentProps<IOption>> = ({
  children,
  ...props
}) => (
  <Fragment>
    <DDArrow ><DropDownArrow /></DDArrow>
    <components.MenuList {...props}>
      {children}
    </components.MenuList>
  </Fragment>
);

export default MenuList;
