import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { signOut } from 'services/auth';
import { useGetBusinessDetailsQuery } from '../../graphql';
import DashboardPage from './DashboardPage';
import routes from 'constants/routes';
import {
  SHOULD_REDIRECT_TO_REFERRAL_PAGE_AFTER_SHOPIFY_FLAG
} from 'constants/referralPageShopify';
import {
  CUSTOMER_TAB_NAME_PARAM,
  CUSTOMER_TAB_NAME_REFERRAL_PAGE
} from 'constants/customersQueryParams';

const DashboardPageContainer: FC<RouteComponentProps> = (props) => {
  const {
    history,
    location: { search }
  } = props;

  const { data, client } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });
  const id = data?.getCurrentBusiness?.id;

  const handleLogout = useCallback(async () => {
    client.stop();
    await client.clearStore();
    await signOut({ doNotReload: true });
  }, [client]);

  useEffect(() => {
    if (search) {
      const { signOutBeforeAuth, redirectUrl, businessId } = queryString.parse(
        search
      );

      const shouldRedirectToReferral = localStorage.getItem(SHOULD_REDIRECT_TO_REFERRAL_PAGE_AFTER_SHOPIFY_FLAG);

      if(redirectUrl?.indexOf('authWithShopify') !== -1 && shouldRedirectToReferral) {
        localStorage.removeItem(SHOULD_REDIRECT_TO_REFERRAL_PAGE_AFTER_SHOPIFY_FLAG);
        history.push(
          `${routes.CUSTOMERS_MANAGEMENT}?${CUSTOMER_TAB_NAME_PARAM}=${CUSTOMER_TAB_NAME_REFERRAL_PAGE}`
        );
        return;
      }

      // TODO: refactor old implementation
      if (typeof businessId === 'string') {
        if (id !== businessId) {
          handleLogout().then(() => {
            const url =
              typeof redirectUrl === 'string' ? redirectUrl : routes.LOGIN;
            history.push(url);
            return;
          });
        }

        if (businessId === id && typeof redirectUrl === 'string') {
          history.push(redirectUrl);
          return;
        } else {
          return;
        }
      }

      if (typeof signOutBeforeAuth === 'string') {
        handleLogout().then(() => {
          const url =
            typeof redirectUrl === 'string' ? redirectUrl : routes.LOGIN;
          history.push(url);
        });
      }
    }
  }, [id, history, search, handleLogout]);

  return <DashboardPage {...props} />;
};

export default DashboardPageContainer;
