import styled from 'styled-components';
import { breakpoint } from 'helpers';

const containerBg = '#FFFEE6';

export const Container = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 135px;
  padding: 6px 15px;
  background-color: ${containerBg};
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
  border-radius: 3px;

  ${breakpoint('xs', 'lg')`
    
  `}

  ${breakpoint('lg')`
    
  `}
  
`;

export const TitleBlock = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.bodyTextColor}; 
  line-height: 14px;
`;

export const DateBlock = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black}; 
  line-height: 21px;
  margin-top: 5px;
  text-transform: capitalize;
`;

export const DataBlock = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.bodyTextColor}; 
  line-height: 19px;
`;

export const DataBlockNumber = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #22B271; 
  margin-right: 4px;
`;
export const TooltipTriangle = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 10px 0 10px;
  border-color: ${containerBg} transparent transparent transparent;
  box-shadwo: 0 5px 1px #979797;
`;