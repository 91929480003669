import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoint } from '../../helpers';
import { Logo as LogoImg } from '../../assets/svg';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('md')`
    margin-bottom: 30px;
  `}

  ${breakpoint('xs', 'md')`
    flex-wrap: wrap;
    margin-bottom: 15px;
  `}
`;

export const LogoAndGreeting = styled.div`
    display: flex;
    align-items: center;
`;
export const LogoWrapper = styled(Link)`
  ${breakpoint('md')`
    display: flex;
    align-items: center;
  `}
`;

export const Logo = styled(LogoImg)`
  width: 68px;
  height: 68px;
  border-radius: 100%;
  box-shadow: 0 0 11px 0 rgba(0,0,0,0.25);

  ${breakpoint('md')`
    margin-right: 30px;
  `}

  ${breakpoint('xs', 'md')`
    margin-right: 15px;
  `}
`;
