import styled, { css } from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';

type ButtonUploadProps = ITheme & {
  errors: boolean;
};

const sharedStyles = css`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: max-content;
`;

export const ButtonUpload = styled.button<ButtonUploadProps>`
  margin: 15px 0px 0px 18px;
  padding: 1.5px 16px;
  background: transparent;
  font-size: ${({ theme }: ButtonUploadProps) => theme.size.body2};
  color: ${({ errors, theme }: ButtonUploadProps) =>
    errors ? theme.colors.danger : theme.colors.main};
  border: 2px solid
    ${({ errors, theme }: ButtonUploadProps) =>
      errors ? theme.colors.danger : theme.colors.main};
  border-radius: 15.5px;
`;

export const DropContainer = styled.div<ITheme>`
  height: 97px;
  width: 97px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 1px;
  border-radius: 14.5px;
  border-color: #979797;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const Drop = styled.div`
  ${sharedStyles};
`;

export const DropInput = styled.input`
  ${sharedStyles};
`;

export const DropPlaceholder = styled.div`
  margin-top: 35px;
  text-align: center;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
`;

export const ImageContainer = styled.div<ITheme>`
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  ${breakpoint('xs', 'md')`
  flex-direction: column;
  margin-top: -10px;
  `};
`;

export const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  position: relative;
  ${breakpoint('xs', 'md')`
    margin-right: 0px;
    margin-top: 10px;
  `};
`;

export const CoverText = styled.span`
  margin-top: 10px;
  font-size: 15px;
  color: #838383;
`;

export const Image = styled.img`
  margin: auto;
  height: 97px;
  width: 160px;
  object-fit: cover;
  border-radius: 14.5px;
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
  background-color: ${({ theme }: ITheme) => theme.colors.white};
`;

export const Close = styled.span`
  color: ${({ theme }: ITheme) => theme.colors.main};
  font-size: ${({ theme }: ITheme) => theme.size.caption};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
  transform: rotate(45deg);
`;

export const Error = styled.p<ITheme>`
  margin-top: 12px;
  min-height: 12px;
  color: ${({ theme }: ITheme) => theme.colors.danger};
  font-size: ${({ theme }: ITheme) => theme.size.caption};
`;
