import React, { FC } from 'react';
import IImageUpload from 'types/ImageUpload';
import {
  Container,
  ImageContainer,
  StyledImage,
  StyledImageUpload,
  RemoveImageButton
} from './styles';
import { BoldCloseIcon } from 'assets/svg';

interface Props {
  className?: string;
  images: IImageUpload[];
  onAddImage: (imageData: IImageUpload) => void;
  onRemoveImage: (index: number) => void;
  getImageUploadUrl: (identityId: string, imageExtension: string) => string;
}

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

const ImageList: FC<Props> = ({
  className,
  onAddImage,
  onRemoveImage,
  getImageUploadUrl,
  images
}) => (
  <Container className={className}>
    <StyledImageUpload
      allowMultipleUpload={false}
      onSuccess={onAddImage}
      getImageUploadUrl={getImageUploadUrl}
    />
    {images.map((img, index) => (
      <ImageContainer key={index}>
        <StyledImage alt="" src={`${S3_DOMAIN}/${img.path}`} />
        <RemoveImageButton onClick={() => onRemoveImage(index)} type="button">
          <BoldCloseIcon />
        </RemoveImageButton>
      </ImageContainer>
    ))}
  </Container>
);

export default ImageList;
