import React from 'react';
import {
  Container,
} from './styled';

interface Props {
  name: string;
}

const TableTag: React.FC<Props> = ({
  name
}) => {
  return (
    <Container>
      {name}
    </Container>
  );
};

export default TableTag;
