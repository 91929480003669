import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { metricsOptionsValues, Metric } from '../OpportunityPerformance/constants';
import {
  Container,
  MetricBlock,
  Options,
  SelectContainer,
  Title,
  Value
} from './styles';

// export interface Metric extends Metric {

// }

interface Props {
  onSelect: (title: metricsOptionsValues) => void;
  options: Metric[];
  selectedMetric: Metric;
}

const OpportunityMetricsSelect: FC<Props> = ({
  options,
  onSelect,
  selectedMetric,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleSelect = (title: metricsOptionsValues) => {
    onSelect(title);
    setOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <Container>
        {selectedMetric && (
          <SelectContainer isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
            <Title>{selectedMetric.title}</Title>
            <Value>{selectedMetric.formattedValue || selectedMetric.value}</Value>
          </SelectContainer>
        )}
        <Options isOpen={isOpen}>
          {options.map((option: any) => {
            return (
              
                <MetricBlock
                  key={option.id}
                  onClick={() => handleSelect(option.id)}
                >
                  <Title>{option.title}</Title>
                  <Value>{option.formattedValue || option.value}</Value>
                </MetricBlock>
              );
            }
          )}
        </Options>
      </Container>
    </OutsideClickHandler>
  );
};

export default OpportunityMetricsSelect;
