import React, { FC } from 'react';
import { OfferStatus, Maybe } from '../../graphql';
import { Container } from './styles';

interface Props {
  className?: string;
  status: Maybe<OfferStatus> | undefined;
}

const getStatusName = (status?: OfferStatus) => {
  switch(status) {
    case OfferStatus.Active:
      return 'Live';
    case OfferStatus.InQueue:
      return 'In Queue';
    default:
      return status;
  }
}

const OpportunityStatus: FC<Props> = ({ className, status }) =>
  status ? (
    <Container className={className} status={status}>
      {getStatusName(status)}
    </Container>
  ) : null;

export default OpportunityStatus;
