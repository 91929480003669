import React, { FC } from 'react';
import { Emphasized } from 'uikit';
import { Title } from '../../styles';
import {
  Container,
  SubTitle,
  Paragraph,
  Result,
  PersonalizationOption
} from './styles';

const PersonalizationTip: FC = () => {
  return (
    <Container>
      <Title>Personalizing your email</Title>

      <Paragraph>
        Adding someone’s name to the subject line or body copy is a great way to
        grab the customer’s attention. To add the customers first name simply
        add: <Emphasized>{`{{First_Name}}`}</Emphasized> where you want their
        name inserted.
      </Paragraph>
      <SubTitle>Example:</SubTitle>
      <Result>
        Hey {`{{First_Name}}`}, check out these great reward opportunities.
      </Result>
      <SubTitle>May display as:</SubTitle>
      <Result>Hey Sarah, check out these great reward opportunities.</Result>
      <SubTitle>Personalization options:</SubTitle>
      <PersonalizationOption>{`{{First_Name}}`}</PersonalizationOption>
      <PersonalizationOption>{`{{Last_Name}}`}</PersonalizationOption>
    </Container>
  );
};

export default PersonalizationTip;
