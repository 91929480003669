import React, { FC } from 'react';
import SelectComponent, { Option } from 'rc-select';
import { ChevronBottom } from 'assets/svg';
import SelectOption from 'types/SelectOption';
import './styles.scss';

interface Props {
  className?: string;
  placeholder?: string;
  options: SelectOption<string>[];
  onSelect: (value: string) => void;
  value: string | undefined;
}

const DEFAULT_PLACEHOLDER = 'Please choose';

const Select: FC<Props> = ({
  className,
  placeholder = DEFAULT_PLACEHOLDER,
  options,
  onSelect,
  value
}) => {
  // Suppress wrong TS errors;
  // first argument is value of option, but not option itself
  // value of select is not option, but `option.value`
  return (
    <SelectComponent<SelectOption<string>>
      className={className}
      placeholder={placeholder}
      // @ts-ignore
      onSelect={(value) => onSelect(value)}
      inputIcon={<ChevronBottom />}
      // @ts-ignore
      value={value}
    >
      {options.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default Select;
