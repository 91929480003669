import React, { FC } from 'react';
import { GlassPanel } from 'uikit';
import PreviewFooter from '../PreviewFooter';
import { Props as PreviewProps } from '../../types';
import {
  Container,
  Content,
  Header,
  BodyParagraph,
  MainButton
} from './styles';
import {
  GetBusinessDetailsQuery,
} from '../../../../graphql';

export interface Props extends PreviewProps {
  business: GetBusinessDetailsQuery,
  onButtonClick?: () => void;
  interactable?: boolean;
}

const ReferralPreviewThankYou: FC<Props> = ({
  config,
  business,
  onButtonClick,
  interactable = true,
}) => {
  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      {!interactable && <GlassPanel className="no-cursor"/>}
      <Content>
        <Header>{config.states.thankYou.config.title}</Header>
        <BodyParagraph>{config.states.thankYou.config.body}</BodyParagraph>
      </Content>
      <MainButton
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={onButtonClick}
      >
        Share Again
      </MainButton>
      <PreviewFooter />
    </Container>
  );
};

export default ReferralPreviewThankYou;
