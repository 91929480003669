import React, { FC } from 'react';
import { Container, Heading, SelectedTerm } from './styles';

interface Props {
  className?: string;
  title: string;
  term?: string;
}

const WidgetTitle: FC<Props> = ({ className, term, title, children }) => (
  <Container className={className}>
    <Heading>{title}</Heading>
    {term && <SelectedTerm>{term}</SelectedTerm>}
    {children}
  </Container>
);

export default WidgetTitle;
