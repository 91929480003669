import { PageFormType } from './validationSchema';
import {
  Offer
} from '../../graphql';

export enum BackgroundTypes {
	"color",
	"image"
}
export enum pageType {
	join = 'join',
  share = 'share',
  thankYou = 'thankYou'
}

export interface Props {
  config: PageFormType;
  offer?: Offer;
}
