import styled from 'styled-components';
import { ITheme } from '../../types';

interface TextProps {
  greened?: boolean;
  theme: ITheme;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Title = styled.span`
  margin-right: 9px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Text = styled.span<TextProps>`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme, greened }) =>
    greened ? theme.fontWeight.bold : theme.fontWeight.semiBold};
  color: ${({ theme, greened }) => (greened ? theme.colors.green : '#a8a8a8')};
`;
