import React, { FC, useState } from 'react';
import get from 'lodash.get';
import { useFormikContext, FormikErrors } from 'formik';
import { Color as PickerColor } from 'react-color';
import { RadioButton, AccordionCollapse, Emphasized } from 'uikit';
import Modal, { ModalProps } from 'uikit/Modal/Modal';
import IImageUpload from 'types/ImageUpload';
import ColorPicker, {
  Props as ColorPickerProps
} from 'components/ColorPicker/ColorPicker';
import CornersPicker, {
  Props as CornersPickerProps
} from 'components/CornersPicker/CornersPicker';
import { PageFormType } from 'components/ReferralReward/validationSchema';
import { pageType } from 'components/ReferralReward/types';
import PageTextsSection from '../PageTextsSection';
import PageStyle from '../PageStyle';
import DefineAndPreviewFormFooter from '../DefineAndPreviewFormFooter';
import {
  Container,
  PageDescription,
  RadioContainer,
  PickerContainer,
  PickerTitle
} from './styles';
import { Title, InputGroup } from '../../styles';
import { getTypeLabel } from 'helpers/referralWebpage';
import { SetField } from '../../types';
import { accordionBaseConfig } from '../../constants';
import './styles.scss';

interface IColorModalData extends ModalProps, ColorPickerProps {
  modalTitle?: string;
}
interface ICornersModalData extends ModalProps, CornersPickerProps {
  modalTitle?: string;
}

interface Props {
  onCancel: () => void;
  onSave: (
    validateForm: (
      values?: PageFormType
    ) => Promise<FormikErrors<PageFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: PageFormType
  ) => void;
  canSave?: boolean;
  isEdit?: boolean;
  openAccordionCheck?: boolean;
}

const DefineAndPreviewForm: FC<Props> = ({
  onCancel,
  onSave,
  canSave = true,
  isEdit,
  openAccordionCheck
}) => {
  const closeColorModal = () => {
    setColorModalData({ ...colorModalData, isOpen: false });
  };
  const closeCornersModal = () => {
    setCornersModalData({ ...cornersModalData, isOpen: false });
  };

  const [colorModalData, setColorModalData] = useState<IColorModalData>({
    isOpen: false,
    onClose: closeColorModal,
    onCancel: closeColorModal,
    initialColor: '#fff',
    modalTitle: 'Color'
  });
  const [cornersModalData, setCornersModalData] = useState<ICornersModalData>({
    isOpen: false,
    onClose: closeCornersModal,
    onCancel: closeCornersModal,
    initialValue: 5,
    modalTitle: 'Corners'
  });

  const { values, validateForm, setSubmitting, errors } = useFormikContext<
    PageFormType
  >();

  const handleColorPick = (
    field: string,
    setField: SetField,
    values: PageFormType,
    label: string
  ) => {
    setColorModalData({
      ...colorModalData,
      isOpen: true,
      modalTitle: label,
      initialColor: get(values, field),
      onApply: (color: PickerColor) => {
        setField(field, color);
        closeColorModal();
      }
    });
  };

  const handleCornersPick = (
    field: string,
    setField: SetField,
    values: PageFormType,
    label: string
  ) => {
    setCornersModalData({
      ...cornersModalData,
      isOpen: true,
      modalTitle: label,
      initialValue: get(values, field),
      onApply: (value: number) => {
        setField(field, value);
        closeCornersModal();
      }
    });
  };

  const handleAddImage = (setFieldValue: SetField) => (
    imageData: IImageUpload
  ) => {
    setFieldValue('pageStyle.background.backgroundImage', imageData.path);
  };

  const handleCancelClick = () => {
    onCancel();
  };
  const handleSaveClick = (
    validateForm: (
      values?: PageFormType
    ) => Promise<FormikErrors<PageFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: PageFormType
  ) => () => {
    onSave(validateForm, setSubmitting, values);
  };

  return (
    <Container>
      <Title>Referral Webpage</Title>
      <InputGroup>
        <RadioContainer>
          <RadioButton
            id="join"
            name="pageType"
            label="Join"
            labelClass={'emphasized-label'}
          />
          <RadioButton
            id="share"
            name="pageType"
            label="Share"
            labelClass={'emphasized-label'}
          />
          <RadioButton
            id="thankYou"
            name="pageType"
            label="Thank You"
            labelClass={'emphasized-label'}
          />
        </RadioContainer>
      </InputGroup>

      <PageDescription>
        Drum enables you to put a referral page neatly into your website. The
        referral page has 3 states a customer would see,{' '}
        <Emphasized>join</Emphasized> the program,{' '}
        <Emphasized>share</Emphasized> with friends, and a{' '}
        <Emphasized>thank you</Emphasized> message. Please review each step and
        click continue when finished.
      </PageDescription>

      <Title>{getTypeLabel(values.pageType as pageType, true)}</Title>

      <AccordionCollapse
        {...accordionBaseConfig}
        title={'Title & body copy'}
        isExpanded={true}
        openCheck={openAccordionCheck && !!(errors.states as any || {})[values.pageType]}
      >
        <PageTextsSection pageType={values.pageType as pageType} />
      </AccordionCollapse>

      <AccordionCollapse {...accordionBaseConfig} title={'Email Style'}>
        <PageStyle
          onColorPick={handleColorPick}
          onCornersPick={handleCornersPick}
          onAddImage={handleAddImage}
        />
      </AccordionCollapse>

      <DefineAndPreviewFormFooter
        publishDisabled={!canSave}
        onCancel={handleCancelClick}
        onPublish={handleSaveClick(validateForm, setSubmitting, values)}
        isEdit={isEdit}
      />

      <Modal {...colorModalData}>
        <PickerContainer>
          <PickerTitle>{colorModalData.modalTitle || 'Color'}</PickerTitle>
          <ColorPicker {...colorModalData} />
        </PickerContainer>
      </Modal>
      <Modal {...cornersModalData}>
        <PickerContainer>
          <PickerTitle>{cornersModalData.modalTitle || 'Corners'}</PickerTitle>
          <CornersPicker {...cornersModalData} />
        </PickerContainer>
      </Modal>
    </Container>
  );
};

export default DefineAndPreviewForm;
