import React, { ComponentType, FC, useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import capitalize from 'lodash.capitalize';
import { isDateValid, useDebouncedQuery } from 'helpers';
import { sendDeleteDraftTrackingEvent, sendDeleteOpportunityTrackingEvent } from 'helpers/segment';
import {
  OfferStatus,
  useChangeOpportunityStatusMutation,
  ChangeOpportunityStatusMutation,
  useListOpportunitiesLazyQuery,
  Offer
} from '../../graphql';
import routes from 'constants/routes';
import { OPPORTUNITY_DUPLICATE_PARAM } from 'constants/opportunityQueryParams';
import { CONTACT_SUPPORT_LINK } from 'constants/outerLinks';
import { FullScreenLoader, Modal } from 'uikit';
import {
  redirectToOpportunityDashboard,
  redirectToEditOpportunity
} from 'helpers';
import { WarningModalText } from './styles';

interface Props {
  className?: string;
  component: ComponentType<any>;
  canViewPerformance: boolean;
  id: string;
  status: OfferStatus;
  redemptionCount: number | null | undefined;
  endedAt: string | null | undefined;
  budget: number | null | undefined;
  commission: number | null | undefined;
  onOfferStatusChange?: () => void;
}

interface IOfferEssential {
  id: string,
  status: OfferStatus
}

const opportunityStatusPublicName = {
  [OfferStatus.Active]: 'Live'
};

const DELETE_MODAL_DATA = {
  [OfferStatus.Active]: {
    _type: OfferStatus.Active,
    mainButtonText: 'Yes, Delete Opportunity',
    secondaryButtonText: 'No, Thanks',
    imageURL: '/assets/notification-icons/delete.png',
    title: 'Delete confirmation',
    message:
      'Deleting this live opportunity will remove it from the Drum app. If a referrer has it saved it will appear as expired and will not be sharable. The customer link will be expired and not accessible as well.'
  },
  misc: {
    _type: 'misc',
    mainButtonText: 'Yes, Delete Opportunity',
    secondaryButtonText: 'No, Thanks',
    imageURL: '/assets/notification-icons/delete.png',
    title: 'Delete confirmation',
    message: 'Are you sure you want to delete the opportunity?'
  },
  last: {
    _type: 'last',
    type: 'warning',
    mainButtonText: 'Do not delete opportunity',
    secondaryButtonText: 'I still wish to delete',
    imageURL: '',
    title: 'Warning',
    message: '',
    children: (
      <WarningModalText>
        If you delete your only Live opportunity your referral webpage will be
        disabled.
        <br />
        <br />
        Visitors to your referral webpage will see a “This referral program is
        no longer active” message.
      </WarningModalText>
    )
  }
};

const PAUSE_MODAL_DATA = {
  misc: {
    _type: 'misc',
    mainButtonText: 'Yes, Pause Opportunity',
    secondaryButtonText: 'No, Thanks',
    imageURL: '/assets/notification-icons/pause.png',
    title: 'Pause confirmation',
    message:
      'Pausing this live opportunity will hide it from the Drum app. If a referrer has it saved it will appear as expired and will not be sharable. The customer link will be expired and not accessible as well.'
  },
  last: {
    _type: 'last',
    type: 'warning',
    mainButtonText: 'Do not pause opportunity',
    secondaryButtonText: 'I still wish to pause',
    imageURL: '',
    title: 'Warning',
    message: '',
    children: (
      <WarningModalText>
        If you pause your only Live opportunity your referral webpage will be
        disabled.
        <br />
        <br />
        Visitors to your referral webpage will see a “This referral program is
        no longer active” message.
      </WarningModalText>
    )
  }
};

enum WARNING_MODAL_TYPES {
  delete,
  pause
}
let modalToOpenAfterActiveOffersRequest: WARNING_MODAL_TYPES | null = null;

const OpportunityMenuContainer: FC<Props> = ({
  className,
  canViewPerformance,
  component: Component,
  id,
  status,
  redemptionCount,
  budget,
  commission,
  endedAt,
  onOfferStatusChange
}) => {
  const history = useHistory();

  const [
    getActiveOpportunityList,
    {
      data: activeOffers,
      loading: activeOffersLoading,
    }
  ] = useDebouncedQuery(useListOpportunitiesLazyQuery, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: 2
        }
      }
    }
  });

  // TODO: handleErrors
  const [
    changeOpportunityStatus,
    { loading }
  ] = useChangeOpportunityStatusMutation({
    onCompleted: (data: ChangeOpportunityStatusMutation) => {
      let fullOfferData = data.updateOffer;
      if(fullOfferData?.status === OfferStatus.Deleted) {
        if(fullOfferData?.id === id && status === OfferStatus.Draft) {
          sendDeleteDraftTrackingEvent(fullOfferData as Offer);
        } else {
          sendDeleteOpportunityTrackingEvent(fullOfferData as Offer);
        }
      }
      
      onOfferStatusChange && onOfferStatusChange();
    }
  });

  const [isEditModalOpen, setEditModal] = useState(false);
  const [pauseModalData, setPauseModalData] = useState({
    ...PAUSE_MODAL_DATA.misc,
    isOpen: false
  });
  const [deleteModalData, setDeleteModalData] = useState({
    ...DELETE_MODAL_DATA.misc,
    isOpen: false
  });
  //Is only called when we pause/delete an active opportunity
  useEffect(() => {
    const items = activeOffers?.listOffers?.items;
    if (items) {
      switch (modalToOpenAfterActiveOffersRequest) {
        case WARNING_MODAL_TYPES.delete:
          setDeleteModalData({
            ...(items.length >= 2
              ? DELETE_MODAL_DATA[OfferStatus.Active]
              : DELETE_MODAL_DATA.last),
            isOpen: true
          });
          break;
        case WARNING_MODAL_TYPES.pause:
          setPauseModalData({
            ...(items.length >= 2
              ? PAUSE_MODAL_DATA.misc
              : PAUSE_MODAL_DATA.last),
            isOpen: true
          });
          break;
      }
      modalToOpenAfterActiveOffersRequest = null;
    }
  }, [activeOffers]);

  const getCanReactivateOpportunity = () => {
    if (status !== OfferStatus.Paused) {
      return false;
    }

    const isNotExpiredDate =
      !endedAt ||
      (isDateValid(endedAt) && moment(endedAt).isSameOrAfter(moment()));

    const isNotExceededBudget =
      !budget ||
      !redemptionCount ||
      !commission ||
      budget > redemptionCount * commission;

    return isNotExpiredDate && isNotExceededBudget;
  };
  const canReactivateOpportunity = getCanReactivateOpportunity();

  const handleEditOpportunity = (id: string, status: OfferStatus) => {
    if (
      status === OfferStatus.Draft ||
      status === OfferStatus.Scheduled ||
      status === OfferStatus.InQueue
    ) {
      redirectToEditOpportunity(id, history);
    } else {
      setEditModal(true);
    }
  };

  const handleChangeOpportunityStatus = (status: OfferStatus) => () => {
    changeOpportunityStatus({ variables: { input: { id, status } } });
    
  }

  const handleDuplicateOpportunity = () => {
    const url = `${routes.CREATE_OPPORTUNITY_FORM}?${OPPORTUNITY_DUPLICATE_PARAM}=${id}`;

    history.push(url);
  };

  const handlePauseOpportunity = (status: OfferStatus) => {
    if (status === OfferStatus.Active) {
      modalToOpenAfterActiveOffersRequest = WARNING_MODAL_TYPES.pause;
      getActiveOpportunityList();
    } else {
      setPauseModalData({
        ...PAUSE_MODAL_DATA.misc,
        isOpen: true
      });
    }
  };

  const handleDeleteOpportunity = (status: OfferStatus) => {
    if (status === OfferStatus.Active) {
      modalToOpenAfterActiveOffersRequest = WARNING_MODAL_TYPES.delete;
      getActiveOpportunityList();
    } else {
      setDeleteModalData({
        ...DELETE_MODAL_DATA.misc,
        isOpen: true
      });
    }
  };

  const closePauseModal = () => {
    setPauseModalData({
      ...pauseModalData,
      isOpen: false
    });
  };
  const closeDeleteModal = () => {
    setDeleteModalData({
      ...deleteModalData,
      isOpen: false
    });
  };

  const getOpportunityMenu = () => {
    const BASE_OPPORTUNITY_OPTIONS = [
      { name: 'Edit', onClick: () => handleEditOpportunity(id, status) },
      { name: 'Make a copy', onClick: handleDuplicateOpportunity },
      { name: 'Delete', onClick: () => handleDeleteOpportunity(status) }
    ];

    const PUBLISHED_OPPORTUNITY_OPTIONS = canViewPerformance
      ? [
          {
            name: 'View Performance',
            onClick: () => redirectToOpportunityDashboard(id, history)
          },
          ...BASE_OPPORTUNITY_OPTIONS
        ]
      : BASE_OPPORTUNITY_OPTIONS;

    const PAUSED_OPPORTUNITY_OPTIONS = canReactivateOpportunity
      ? [
          ...PUBLISHED_OPPORTUNITY_OPTIONS,
          {
            name: 'Activate',
            onClick: handleChangeOpportunityStatus(OfferStatus.Active)
          }
        ]
      : PUBLISHED_OPPORTUNITY_OPTIONS;

    const ACTIVE_OPPORTUNITY_OPTIONS = [
      { name: 'Pause', onClick: () => handlePauseOpportunity(status) },
      ...PUBLISHED_OPPORTUNITY_OPTIONS
    ];

    switch (status) {
      case OfferStatus.Active:
        return ACTIVE_OPPORTUNITY_OPTIONS;
      case OfferStatus.Draft:
      case OfferStatus.Scheduled:
      case OfferStatus.InQueue:
        return BASE_OPPORTUNITY_OPTIONS;
      case OfferStatus.Paused:
        return PAUSED_OPPORTUNITY_OPTIONS;
      default:
        return [];
    }
  };

  const getStatusPublicName = (status: OfferStatus) =>
    // @ts-ignore
    opportunityStatusPublicName[status] || status;

  const options = getOpportunityMenu();
  const statusPublicName = getStatusPublicName(status);

  return (
    <>
      <Component className={className} options={options} />

      <Modal
        message={`Please contact support to edit a ${statusPublicName.toLowerCase()} opportunity. This is meant to ensure the changes do not impact the referrers currently sharing your offer.`}
        imageURL="/assets/notification-icons/edit.png"
        isOpen={isEditModalOpen}
        canClose
        mainButton={{
          text: 'Contact Support',
          // TODO: add link for redirect to support
          onClick: () => {
            window.open(CONTACT_SUPPORT_LINK, '_blank');
          }
        }}
        secondaryButton={{
          text: 'No, Thanks',
          onClick: () => setEditModal(false)
        }}
        onClose={() => setEditModal(false)}
        title={`Edit ${capitalize(statusPublicName)} opportunity`}
      />

      <Modal
        canClose
        childrenBeforeButtons={true}
        mainButton={{
          text: deleteModalData.mainButtonText,
          onClick:
            deleteModalData._type === 'last'
              ? closeDeleteModal
              : handleChangeOpportunityStatus(OfferStatus.Deleted)
        }}
        secondaryButton={{
          text: deleteModalData.secondaryButtonText,
          onClick:
            deleteModalData._type === 'last'
              ? handleChangeOpportunityStatus(OfferStatus.Deleted)
              : closeDeleteModal
        }}
        onClose={closeDeleteModal}
        {...deleteModalData}
      />

      <Modal
        canClose
        childrenBeforeButtons={true}
        mainButton={{
          text: pauseModalData.mainButtonText,
          onClick:
            pauseModalData._type === 'last'
              ? closePauseModal
              : handleChangeOpportunityStatus(OfferStatus.Paused)
        }}
        secondaryButton={{
          text: pauseModalData.secondaryButtonText,
          onClick:
            pauseModalData._type === 'last'
              ? handleChangeOpportunityStatus(OfferStatus.Paused)
              : closePauseModal
        }}
        onClose={closePauseModal}
        {...pauseModalData}
      />

      {(loading || activeOffersLoading) && <FullScreenLoader />}
    </>
  );
};

export default OpportunityMenuContainer;
