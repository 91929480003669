import React, { FC } from 'react';
import {
  BadgeContainer,
  Container,
  DataPolicyHeading,
  SecurityPolicy
} from './styles';

const DataPolicyDisclaimer: FC = () => (
  <Container>
    <BadgeContainer>
      <img src="/assets/secure-badge.jpg" alt="secure-badge" />
    </BadgeContainer>
    <div>
      <DataPolicyHeading>Safe &amp; Secure</DataPolicyHeading>
      <SecurityPolicy>
        Drum does not store or have access to your login credentials for your
        bank or PayPal.
      </SecurityPolicy>
    </div>
  </Container>
);

export default DataPolicyDisclaimer;
