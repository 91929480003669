import { FC, useEffect } from 'react';
import { useGetBusinessIdQuery } from '../../graphql';
import { signOut } from 'services/auth';

const BUSINESS_ID_FETCH_INTERVAL = 10000;
const BUSINESS_ID_FAIL_FETCH_INTERVAL = 2000;
const BUSINESS_ID_FAIL_FETCH_ATTEMPTS = 3;

interface Props {
  isAuthenticated: boolean;
}

let currentAttempt = 0;
let failFetchInterval: number;

const ProfileStatusChecker: FC<Props> = ({ isAuthenticated }) => {
  const { refetch, client } = useGetBusinessIdQuery({
    fetchPolicy: 'no-cache',
    skip: !isAuthenticated
  });

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        await refetch().then((res) => {
          const id = res.data.getCurrentBusiness?.id;
          if (id) {
            currentAttempt = 0;
            clearInterval(failFetchInterval);
          } else if (isAuthenticated && !id) {
            if (currentAttempt === BUSINESS_ID_FAIL_FETCH_ATTEMPTS) {
              client.stop();
              client.clearStore().then(() => signOut());
              currentAttempt = 0;
              clearInterval(failFetchInterval);
            } else {
              currentAttempt++;
            }
          }
        });
      }
    };

    let timeout = setTimeout(function getBusinessId() {
      if (isAuthenticated) {
        failFetchInterval = setInterval(
          fetchData,
          BUSINESS_ID_FAIL_FETCH_INTERVAL
        );
      }
      timeout = setTimeout(getBusinessId, BUSINESS_ID_FETCH_INTERVAL);
    }, BUSINESS_ID_FETCH_INTERVAL);

    return () => clearTimeout(timeout);
  }, [client, refetch, isAuthenticated]);

  return null;
};

export default ProfileStatusChecker;
