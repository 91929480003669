import styled from 'styled-components';
import { resetButtonStyles, SecondaryButton } from 'uikit';

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 25px;
  border-radius: 50%;
  ${resetButtonStyles};
`;

export const Heading = styled.h2`
  margin-bottom: 7px;
  line-height: 1.15;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.31;
`;

export const Paragraph = styled.p`
  margin-bottom: 13px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5;
`;

export const Button = styled(SecondaryButton)`
  padding: 7px 20px;
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 1.15;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;
