import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  line-height: 1.15;
`;

export const BadgeContainer = styled.div`
  margin-right: 12px;
`;

export const DataPolicyHeading = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
`;

export const SecurityPolicy = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
`;
