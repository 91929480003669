import React, { FC, useState, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import { useHistory } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import Join from 'assets/svg/Join';
import PageInfo from 'components/PageInfo';
import {
  CUSTOMIZE_MODE_PARAM,
  EDIT_MODE_PARAM
} from 'constants/referralQueryParams';
import ReferralPreviewContainer from '../ReferralPreviewContainer';
import ReferralPreviewJoin from '../ReferralPreviewJoin';
import SummaryLearnMoreModalContent from '../SummaryLearnMoreModalContent';
import PreviewModal from '../PreviewModal';
import { DangerText, FullScreenLoader, GlassPanel, Modal } from 'uikit';
import { getDefaultValues } from '../../constants';
import { Eye } from 'assets/svg';
import { getProfileStatus } from 'helpers';
import {
  sendVisitReferralPageClickTrackingEvent,
  sendEditReferralPageClickTrackingEvent,
  sendReferralPageCustomizeAndPublishClickTrackingEvent
} from 'helpers/segment';
import { getImagePathSimple } from 'helpers/getImagePath';
import { convertToFormValues, getLogoURL } from 'helpers/referralWebpage';
import routes from 'constants/routes';
import {
  Container,
  Content,
  LeftBlock,
  RightBlock,
  StyledLabel,
  Paragraph,
  Subheader,
  StyledLink,
  InputBlock,
  StyledInput,
  InputBlockInner,
  InputBlockButtons,
  IBILeft,
  IBIRight,
  InputGroup,
  CopyButton,
  VisitButton,
  EditButton,
  CustomizeButton,
  PreviewContainer,
  PreviewButton,
  MobilePreviewContainer
} from './styles';

import {
  useGetBusinessDetailsQuery,
  useListOpportunitiesQuery,
  useGetReferralPageQuery,
  OfferStatus,
  ReferralPageStateConfigPageStyleBackgroundType,
  ReferralPage,
  IntegrationType
} from '../../../../graphql';
import { PageFormType } from '../../validationSchema';

const ReferralRewardSummary: FC = () => {
  const history = useHistory();

  const [previewValues, setPreviewValues] = useState<any>(null);
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [isCopyTooltipShown, setIsCopyTooltipShown] = useState(false);
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);
  const [isNoProfileModalOpen, setIsNoProfileModalOpen] = useState<boolean>(
    false
  );
  const [isNoOffersModalOpen, setIsNoOffersModalOpen] = useState<boolean>(
    false
  );

  const TOOLTIP_TIMEOUT = 1000;

  const {
    data: businessProfileData,
    loading: businessProfileDataLoading
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'no-cache'
  });

  const {
    data: opportunitiesList,
    loading: opportunitiesListLoading
  } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: 1
        }
      }
    }
  });

  const { data: referralPageData } = useGetReferralPageQuery({
    fetchPolicy: 'no-cache'
  });

  const handleCopyURL = () => {
    if (businessProfileData?.getCurrentBusiness?.referralPageUrl) {
      setIsCopyTooltipShown(true);
      setTimeout(() => setIsCopyTooltipShown(false), TOOLTIP_TIMEOUT);
      copy(businessProfileData?.getCurrentBusiness?.referralPageUrl);
    }
  };
  const handleVisitPage = () => {
    const link = businessProfileData?.getCurrentBusiness?.referralPageUrl;
    sendVisitReferralPageClickTrackingEvent();
    link && window.open(link, '_blank');
  };
  const handleCustomizeAndPublish = () => {
    const isShopify =
      businessProfileData?.getCurrentBusiness?.integrationType ===
      IntegrationType.Shopify;
    sendReferralPageCustomizeAndPublishClickTrackingEvent(
      isShopify ? 'Shopify' : 'Standard'
    );
    history.push(`${routes.REFERRAL_PAGE}/${CUSTOMIZE_MODE_PARAM}`);
  };
  const handleEditPage = () => {
    history.push(`${routes.REFERRAL_PAGE}/${EDIT_MODE_PARAM}`);

    sendEditReferralPageClickTrackingEvent();
  };

  /* -- EFFECTS -- */

  useEffect(() => {
    if (
      opportunitiesList?.listOffers &&
      referralPageData?.referralPage &&
      businessProfileData?.getCurrentBusiness
    ) {
      const { completeProfile, setupBilling, setupTracking } = getProfileStatus(
        businessProfileData
      );
      const isProfileCompleted =
        completeProfile.isCompleted &&
        setupBilling.isCompleted &&
        setupTracking.isCompleted;
      const hasOpportunity = !!opportunitiesList?.listOffers?.items?.length;

      if (!isProfileCompleted) {
        setIsNoProfileModalOpen(true);
        return;
      } else if (!hasOpportunity) {
        setIsNoOffersModalOpen(true);
        return;
      }

      const data = convertToFormValues(
        referralPageData.referralPage as ReferralPage
      ) as PageFormType;
      if (!data.pageStyle.background.backgroundImage) {
        data.pageStyle.background.backgroundImage =
          opportunitiesList.listOffers?.items?.[0]?.coverPhoto?.styles?.[0]
            ?.path || '';
      }

      setPreviewValues(data);
    }
  }, [referralPageData, opportunitiesList, businessProfileData]);

  return (
    <Container>
      <PageInfo
        text="Drum has created a join your referral program landing page for you. On this page visitors can view your referral reward opportunities and join your referral program. We encourage you to promote this link on your website and existing emails."
        icon={<Join />}
      />

      <Content>
        <LeftBlock>
          <StyledLabel
            title={'Your Join Referral Program and Share Opportunity page'}
            tooltip={`We created a referral page for ${businessProfileData?.getCurrentBusiness?.businessName} where visitors can sign up for your referral program and easily earn rewards by referring their friends.  
             `}
          />
          <Paragraph>
            <DangerText> We recommend</DangerText> that you link to your
            Referral Program page on your website and anywhere else you
            communicate with customers.
          </Paragraph>

          <Subheader>Hosting</Subheader>
          <Paragraph className="alt">
            You have the choice of leaving your page hosted by us or exporting
            just the body and widget to a page on your site.{' '}
            <StyledLink onClick={() => setIsLearnMoreModalOpen(true)}>
              Learn More
            </StyledLink>
            .
          </Paragraph>

          <Subheader>Drum Hosted Page</Subheader>
          <InputBlock>
            <Paragraph className="label">URL</Paragraph>
            <InputBlockInner>
              <IBILeft>
                <GlassPanel style={{ cursor: 'default' }} />
                <InputGroup>
                  <StyledInput
                    name="link"
                    type="text"
                    value={
                      businessProfileData?.getCurrentBusiness
                        ?.referralPageUrl || ''
                    }
                  />
                </InputGroup>
              </IBILeft>
              <IBIRight>
                <Tooltip
                  placement="top"
                  overlay="Copied"
                  visible={isCopyTooltipShown}
                >
                  <CopyButton onClick={handleCopyURL}>Copy</CopyButton>
                </Tooltip>
              </IBIRight>
            </InputBlockInner>
            <InputBlockButtons>
              <VisitButton onClick={handleVisitPage}>Visit Page</VisitButton>
              <EditButton onClick={handleEditPage}>Edit Page</EditButton>
            </InputBlockButtons>
          </InputBlock>

          <Subheader>Host on your site</Subheader>

          <Paragraph>
            Display the{' '}
            <span className="bold">
              “Join the referral program and sharing”
            </span>{' '}
            portion of this page on your site. Easily customomize and export the
            body and display on your page or create a new Shopify page for your
            site here.
          </Paragraph>

          <CustomizeButton onClick={handleCustomizeAndPublish}>
            Customize and Publish
          </CustomizeButton>
        </LeftBlock>
        <RightBlock>
          <PreviewContainer>
            {businessProfileData && opportunitiesList && previewValues && (
              <ReferralPreviewContainer
                business={businessProfileData}
                background={
                  previewValues.pageStyle.background.backgroundType ===
                  ReferralPageStateConfigPageStyleBackgroundType.Image
                    ? getLogoURL(
                        previewValues.pageStyle.background.backgroundImage
                      )
                    : previewValues.pageStyle.background.backgroundColor
                }
                backgroundType={
                  previewValues.pageStyle.background.backgroundType
                }
                isEdit={true}
              >
                <ReferralPreviewJoin
                  config={previewValues || getDefaultValues()}
                  business={businessProfileData}
                  interactable={false}
                />
              </ReferralPreviewContainer>
            )}
          </PreviewContainer>
        </RightBlock>
      </Content>
      <PreviewButton onClick={() => setIsMobilePreviewOpen(true)} type="button">
        <Eye />
      </PreviewButton>
      <PreviewModal
        isOpen={isMobilePreviewOpen}
        onClose={() => setIsMobilePreviewOpen(false)}
      >
        <MobilePreviewContainer>
          {businessProfileData && opportunitiesList && (
            <ReferralPreviewContainer
              business={businessProfileData}
              background={getImagePathSimple(
                opportunitiesList.listOffers?.items?.[0]?.coverPhoto
                  ?.styles?.[0]?.path || ''
              )}
              backgroundType={
                ReferralPageStateConfigPageStyleBackgroundType.Image
              }
              isEdit={true}
            >
              <ReferralPreviewJoin
                config={getDefaultValues()}
                business={businessProfileData}
                interactable={false}
              />
            </ReferralPreviewContainer>
          )}
        </MobilePreviewContainer>
      </PreviewModal>
      <Modal
        isOpen={isNoProfileModalOpen}
        canClose={false}
        childrenBeforeButtons={true}
        title="Before we do that..."
        message="Please complete your account set up to start configuring your referral page."
        mainButton={{
          text: 'Go to Dashboard',
          onClick: () => {
            history.push(routes.DASHBOARD);
          }
        }}
      />
      <Modal
        isOpen={isNoOffersModalOpen}
        canClose={false}
        childrenBeforeButtons={true}
        title="Before we do that..."
        message="Please publish an opportunity to start configuring your referral page."
        mainButton={{
          text: 'Go to Opportunities',
          onClick: () => {
            history.push(routes.MY_OPPORTUNITIES);
          }
        }}
      />

      <Modal
        isOpen={isLearnMoreModalOpen}
        childrenBeforeButtons={true}
        title="Hosting Options"
        mainButton={{
          text: 'Close',
          onClick: () => {
            setIsLearnMoreModalOpen(false);
          }
        }}
        onClose={() => setIsLearnMoreModalOpen(false)}
      >
        <SummaryLearnMoreModalContent onLinkClick={handleCustomizeAndPublish} />
      </Modal>
      {(businessProfileDataLoading || opportunitiesListLoading) && (
        <FullScreenLoader />
      )}
    </Container>
  );
};

export default ReferralRewardSummary;
