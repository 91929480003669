import React, { Fragment } from 'react';
import { ApolloError } from 'apollo-client';
import {
  WooCommerce,
  BigCommerce,
  SquareSpace,
  Magento,
  Wix,
  Weebly,
  GoDaddy,
  ShopifySmall
} from 'assets/svg';
import {
  Container,
  ContainerInner,
  ReturnLink,
  InstructionsButton,
  ImageContainer,
  PlatformName,
  StatusContainer,
  StatusLabel,
  StatusSuccess,
  StatusError,
  ReturnLinkContainer,
  PlatformInfoContainer,
  PlatformStatus,
  SelectedPlatform
} from './styled';
import { IntegrationPlatform } from 'components/SetUpCustomerTracking/types';
import { OTHER_PLATFORM } from 'components/SetUpCustomerTracking/helpers';
import routes from 'constants/routes';

interface Props {
  className?: string;
  platform: IntegrationPlatform;
  isConnected: boolean;
  connectionError?: ApolloError | null;
}

const IntegrationInfo: React.FC<Props> = ({
  className,
  platform,
  isConnected,
  connectionError
}) => {
  // TODO: restrict type of icon name
  const getShopIcon = (iconName: string = '') => {
    switch (iconName.toLowerCase()) {
      case 'shopify':
        return <ShopifySmall />;
      case 'woocommerce':
        return <WooCommerce />;
      case 'bigcommerce':
        return <BigCommerce />;
      case 'squarespace':
        return <SquareSpace />;
      case 'magento':
        return <Magento />;
      case 'wix':
        return <Wix />;
      case 'weebly':
        return <Weebly />;
      case 'godaddy':
        return <GoDaddy />;
      default:
        return <></>;
    }
  };

  const openInstructions = (link?: string) => {
    if(!link) return;
    window.open(link, '_blank');
  }

  return (
    <Container className={className}>
      <ContainerInner>
        
          <PlatformInfoContainer>
            
              <SelectedPlatform>
                <PlatformName>
                  My website was built using{' '}
                  {platform.id === OTHER_PLATFORM ? 'a custom platform' : platform.name}
                </PlatformName>
                <ImageContainer>{getShopIcon(platform.id)}</ImageContainer>
              </SelectedPlatform>
            
            <PlatformStatus>
              {platform.id !== 'shopify' && <InstructionsButton onClick={() => openInstructions(platform.instructionsLink)}>
                Open {platform.name} tracking installation instructions
              </InstructionsButton>}
              <StatusContainer>
                
                {isConnected && (
                  <Fragment>
                    <StatusLabel>Status</StatusLabel>
                    <StatusSuccess>Connected</StatusSuccess>
                  </Fragment>
                )}
                {connectionError && (
                  <Fragment>
                    <StatusLabel>Status</StatusLabel>
                    <StatusError>Disconnected</StatusError>
                  </Fragment>
                )}
              </StatusContainer>
            </PlatformStatus>
          </PlatformInfoContainer>
        

        <ReturnLinkContainer>
          {!isConnected && (
            <ReturnLink to={routes.SET_UP_CUSTOMER_TRACKING}>
              &lt; Back to platform options
            </ReturnLink>
          )}
        </ReturnLinkContainer>
      </ContainerInner>
    </Container>
  );
};

export default IntegrationInfo;
