import React from 'react';
import IconProps from './IconProps';

const Checkmark: React.FC<IconProps> = ({ className }: IconProps) => {
  return (
    <div
      className={className}
      style={{
        border: '2px solid #00ce7c',
        borderRadius: '100%',
        width: 23,
        height: 23
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        width="23px"
        height="23px"
      >
        <path
          fill="transparent"
          d="M40,77.5C19.3,77.5,2.5,60.7,2.5,40S19.3,2.5,40,2.5S77.5,19.3,77.5,40S60.7,77.5,40,77.5z"
        />
        <path
          fill="transparent"
          d="M40,3c20.4,0,37,16.6,37,37S60.4,77,40,77S3,60.4,3,40S19.6,3,40,3 M40,2C19,2,2,19,2,40 s17,38,38,38s38-17,38-38S61,2,40,2L40,2z"
        />
        <path
          fill="#00ce7c"
          d="M34 56L20.2 42.2 24.5 38 34 47.6 58.2 23.4 62.5 27.6z"
        />
      </svg>
    </div>
  );
};

export default Checkmark;
