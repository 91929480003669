import React, { FC } from 'react';
import {
  Badges,
  BusinessInfo,
  BusinessPhoto,
  BusinessPhotoWrapper,
  Container,
  Content,
  Earning,
  ShareButton,
  ShareIcon,
  OpportunityCoverWrapper,
  OpportunityDetails,
  OpportunityTitle,
  OpportunityPhoto,
  OpportunityPhotoPlaceholder,
  BusinessName,
  ViewLink
} from './styles';

interface Props {
  canShare: boolean;
  className?: string;
  earning?: string;
  businessName?: string;
  businessPhoto?: string | null;
  opportunityTitle?: string;
  coverImage?: string | null;
  opportunityLink?: string | null;
}

// TODO: delete mock data after API integration
const opportunityDetails = {
  description: 'Drummer facing title of the opportunity goes here.',
  title: 'Blue Apron'
};

const SmallOpportunityCard: FC<Props> = ({
  businessName,
  businessPhoto,
  opportunityTitle,
  coverImage,
  canShare,
  className,
  earning,
  opportunityLink
}) => {
  const { description, title } = opportunityDetails;

  return (
    <Container className={className}>
      {canShare && (
        <ShareButton>
          <ShareIcon />
        </ShareButton>
      )}
      <OpportunityCoverWrapper>
        {coverImage ? (
          <OpportunityPhoto src={coverImage} alt="" />
        ) : (
          <OpportunityPhotoPlaceholder>Image</OpportunityPhotoPlaceholder>
        )}
      </OpportunityCoverWrapper>
      <Content>
        <OpportunityDetails hasEarning={!!earning}>
          <BusinessInfo>
            {businessPhoto && (
              <BusinessPhotoWrapper>
                <BusinessPhoto alt="" src={businessPhoto} />
              </BusinessPhotoWrapper>
            )}
            <BusinessName>{businessName || title}</BusinessName>
          </BusinessInfo>
          <OpportunityTitle>{opportunityTitle || description}</OpportunityTitle>
        </OpportunityDetails>
        <Badges>
          {!!earning && <Earning>{earning}</Earning>}
          {!!opportunityLink && (
            <ViewLink
              href={opportunityLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              View
            </ViewLink>
          )}
        </Badges>
      </Content>
    </Container>
  );
};

export default SmallOpportunityCard;
