import React, { FC } from 'react';
import { Container, Input, Icon } from './styled';

interface Props {
  className?: string;
}

// TODO: smooth animation for it
const SearchInput: FC<Props> = ({ className, ...rest }) => (
  <Container className={className}>
    <Input {...rest} />
    <Icon />
  </Container>
);

export default SearchInput;
