import React, { FC } from 'react';
import { Props as PreviewProps } from '../../../../types';
import { getImagePath, getImageS3Url } from 'helpers';
import OpportunityItem from 'components/OpportunityItem';
import {
  Container,
  MainButton,
  ItemsList,
  Content
} from './styles';
import {
  BodyParagraph,
} from '../../styles';
import {
  GetBusinessDetailsQuery,
  ImageStyle,
  CommissionType
} from '../../../../../../graphql';

export interface Props extends PreviewProps{
  business: GetBusinessDetailsQuery,
  onButtonClick?: () => void,
  interactable?: boolean,
  isEdit?: boolean
}

const RewardsApp: FC<Props> = ({ config, business, offer, onButtonClick, interactable = true }) => {

  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      <Content>
        <BodyParagraph>
          Save the {business?.getCurrentBusiness?.businessName} rewards to your Drum rewards app for easy sharing. Receive exclusive {business?.getCurrentBusiness?.businessName} reward opportunities. Explore a marketplace of other great reward opportunities
        </BodyParagraph>
        <ItemsList>
          <OpportunityItem
            cover={getImageS3Url(offer?.coverPhoto?.styles?.[0]?.path) || ''}
            businessAvatar={getImagePath(business.getCurrentBusiness?.imagesS3?.[0].styles as ImageStyle[] || [])}
            businessName={business.getCurrentBusiness?.businessName || ''}
            title={offer?.title || ''}
            amountType={offer?.drumRedemption?.commissionType || CommissionType.Fixed}
            amount={offer?.drumRedemption?.payDrum || 'XX'}
          />
        </ItemsList>
      </Content>
      <MainButton 
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={onButtonClick}
      >
        Download Rewards App
      </MainButton>
    </Container>
  );
};

export default RewardsApp;
