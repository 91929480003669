import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Formik } from 'formik';
import { handleShopifyAuth } from 'services/auth';
import { setShopifyTrackingEventFlag, removeShopifyTrackingEventFlag } from 'helpers/segment';
import { InputBase, FullScreenLoader, FieldFooter } from 'uikit';
import shopifyAuthEvents from 'constants/shopifyAuthEvents';
import { shopifyErrors, FALLBACK_AUTH_ERROR_MESSAGE } from 'constants/errors';
import validationSchema, { ShopifyFormType } from './validationSchema';
import {
  Disclaimer,
  Form,
  Label,
  ReturnButton,
  StyledShopifyLogo,
  SubmitButton,
  Title
} from './styles';

interface Props {
  authEvent: shopifyAuthEvents;
  className?: string;
  onReturnBack: () => void;
}

const { REACT_APP_SHOPIFY_DOMAIN } = process.env;

const ShopifyAuthForm: FC<Props> = ({ authEvent, className, onReturnBack }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const location = useLocation();

  const handleAuthError = (errorMessage: string) => {
    setError(errorMessage);
    setLoading(false);
  };

  const findErrorMessageByCode = (code: string) => {
    const error = shopifyErrors.find((e) => e.code === code);

    return error?.message;
  };

  useEffect(() => {
    const {
      errorMessage,
      shop,
      shopifyAuthError: errorCode,
      shopifyEmail
    } = queryString.parse(location.search);

    if (errorCode) {
      const error =
        (typeof errorMessage === 'string' && errorMessage) ||
        (typeof errorCode === 'string' && findErrorMessageByCode(errorCode)) ||
        FALLBACK_AUTH_ERROR_MESSAGE;

      setError(error);
    } else if (typeof shop === 'string' && typeof shopifyEmail === 'string') {
      try {
        setLoading(true);
        setShopifyTrackingEventFlag();
        handleShopifyAuth(shop, shopifyEmail)
          .then((res) => {
            const { authenticated, reason } = res;

            authenticated ? setLoading(false) : handleAuthError(reason);
          })
          .catch(() => Promise.reject());
      } catch (error) {
        removeShopifyTrackingEventFlag();
        handleAuthError(FALLBACK_AUTH_ERROR_MESSAGE);
      }
    }
  }, [location.search]);

  const handleSubmit = ({ shopUrl }: ShopifyFormType) => {
    const AUTH_REDIRECT_URL = `${REACT_APP_SHOPIFY_DOMAIN}/auth?shop=${shopUrl}&authWithShopify=${authEvent}`;

    window.location.href = AUTH_REDIRECT_URL;
  };

  return (
    <>
      <div className={className}>
        <Title>Confirm details of your Shopify account</Title>
        <ReturnButton onClick={onReturnBack} type="button">
          &lt; Back to platform options
        </ReturnButton>
        <Formik
          enableReinitialize
          initialValues={{ shopUrl: '' }}
          initialErrors={error ? { shopUrl: error } : {}}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <StyledShopifyLogo />
              <Label htmlFor="shopify-url"> Enter Your Shopify shop URL</Label>
              <InputBase
                id="shopify-url"
                name="shopUrl"
                placeholder="acmeclothing.myshopify.com"
                value={values.shopUrl}
                onChange={handleChange}
              />
              <FieldFooter error={errors.shopUrl} />
              <SubmitButton disabled={isSubmitting} type="submit">
                Submit
              </SubmitButton>
            </Form>
          )}
        </Formik>
        <Disclaimer>
          Cookies Required. Please enable cookies in your browser preferences to
          authorize with Shopify.
        </Disclaimer>
      </div>
      {isLoading && <FullScreenLoader />}
    </>
  );
};

export default ShopifyAuthForm;
