import * as yup from 'yup';

const shopifyShopPattern = /^[a-z0-9][a-z0-9\\-]*[a-z0-9]\.myshopify\.com$/;

const validationSchema = yup.object({
  shopUrl: yup
    .string()
    .required('Shop url is required')
    .matches(shopifyShopPattern, "Enter shop in format 'storename.myshopify.com'")
});

export type ShopifyFormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
