import styled from 'styled-components';
import { H4, LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  display: flex;
  height: 42px;
  ${breakpoint('xs', 'lg')`
    margin: 32px 0 40px 0;
  `}
  ${breakpoint('lg')`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

export const CallIconContainer = styled.div`
  margin-right: 7px;
`;
export const CallTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;
export const CallTitle = styled(H4)`
  font-size: 14px;
  line-height: 16px;
`;
export const CallLink = styled.a`
  ${LinkTextMixin};
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
`;
