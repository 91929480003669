export enum Actions {
  RedirectTo = 'RedirectTo',
  Noop = 'Noop'
}

const AFTER_LOGIN_HOOK_FIELD_NAME = 'Drum:BusinessApp:AfterLoginHook';

const setHook = (value: string) => {
  localStorage.setItem(
    AFTER_LOGIN_HOOK_FIELD_NAME,
    value
  );
}

const readHook = () => {
  const afterLoginHook = localStorage.getItem(AFTER_LOGIN_HOOK_FIELD_NAME);
  localStorage.removeItem(AFTER_LOGIN_HOOK_FIELD_NAME);
  if (!afterLoginHook) {
    return { action: Actions.Noop }
  }
  return JSON.parse(afterLoginHook);
};

export const registerAfterLogInHook = (action: Actions, input: object) => {
  setHook(JSON.stringify({
    action,
    input
  }));
};

interface RedirectToActionInput {
  path: string
  search: string
}

const redirectToAction = (input: RedirectToActionInput) => {
  const newLocation = `${(window as any).location.origin}${input.path || ''}${input.search}`;
  (window as any).location = newLocation;
};

export const executeAfterLoginHook = () => {
  const { action, input } = readHook();
  switch (action) {
    case Actions.RedirectTo: {
      return redirectToAction(input);
    }
    case Actions.Noop:
    default:
      return;
  }
};