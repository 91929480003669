import styled from 'styled-components';
import { ITheme } from 'types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  p {
    line-height: 23px;
    
  }
  & > p {
    margin-top: 20px;
    font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  }
`;
export const Content = styled.div`
`;
export const TextBlock = styled.p`
  margin: 20px 0 30px 0;
`;
export const Email = styled.p`
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
`;
