import styled from 'styled-components';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  ${breakpoint('xs', 'sm')`
    justify-content: space-between;
    padding: 0 10px;
  `}
`;
export const Step = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 45px;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0 !important;
  }

  ${breakpoint('xs', 'sm')`
    margin-right: 0;
  `}
`;
export const StepNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 38px;
  width: 38px;
  height: 38px;
  margin-right: 12px;
  border: 1px solid #999;
  border-radius: 50%;
  color: #999;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.title};

  &.active {
    border: 1px solid ${({ theme }) => theme.colors.pink};
    color: ${({ theme }) => theme.colors.pink};
  }

  ${breakpoint('xs', 'sm')`
    width: 26px;
    height: 26px;
    min-width: 26px;
    font-size: ${({ theme }) => theme.size.body2};
    margin-right: 10px;
  `}
`;
export const StepName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  &.active {
    color: ${({ theme }) => theme.colors.pink};
  }

  ${breakpoint('xs', 'sm')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
