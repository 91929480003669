enum OpportunityStatuses {
  active = 'ACTIVE',
  draft = 'DRAFT',
  scheduled = 'SCHEDULED',
  paused = 'PAUSED',
  archived = 'ARCHIVED',
  deleted = 'DELETED',
}

export default OpportunityStatuses;
