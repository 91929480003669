import { IntegrationPlatform } from './types';

const { REACT_APP_TRACKING_PIXEL_URL } = process.env;

export const setupInstructions =
  'To track a Drum referred customer from landing on your site to completing your desired action we need to drop two snippets of tracking code on your site.';



export const getBaseCode = (id?: string) => `
    !function (e, t, n, a, p, s, c, i, o) {
      e[p] || ((c = e[p] = function () {
      c.process ? c.process.apply(c, arguments) : c.queue.push(arguments)
    }).queue = [], c.t = 1 * new Date, (i = t.createElement(n)).async = 1,
      i.src = "${REACT_APP_TRACKING_PIXEL_URL}?t=" + 864e5 * Math.ceil(new Date / 864e5),
      (o = t.getElementsByTagName(n)[0]).parentNode.insertBefore(i, o))
    }(window, document, "script", 0, "dpix"),
    dpix("init", "${id}"), dpix("event", "pageload");
`;
const completionCode: string = `
    dpix(
      "event",
      "offer-completed",
      {
        amount: $amount
      }
    );
`;

export const getIntegrationPlatforms = (id?: string): Array<IntegrationPlatform> => [
  {
    id: 'woocommerce',
    name: 'WooCommerce',
    src: '/assets/woocommerce.png',
    link: 'woocommerce',
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360052155734',
    baseCode: getBaseCode(id),
    completionCode
  },
  {
    id: 'bigcommerce',
    name: 'BigCommerce',
    src: '/assets/bigcommerce.png',
    link: 'bigcommerce',
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360051364994',
    baseCode: getBaseCode(id),
    completionCode
  },
  {
    id: 'squarespace',
    name: 'SquareSpace',
    src: '/assets/squarespace.png',
    link: 'squarespace',
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360052201793',
    baseCode: getBaseCode(id),
    completionCode
  },
  {
    id: 'magento',
    name: 'Magento',
    src: '/assets/magento.png',
    link: 'magento',
    baseCode: getBaseCode(id),
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360053324873',
    completionCode
  },
  {
    id: 'wix',
    name: 'Wix',
    src: '/assets/wix.png',
    link: 'wix',
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360051471694',
    baseCode: getBaseCode(id),
    completionCode
  },
  {
    id: 'godaddy',
    name: 'GoDaddy',
    src: '/assets/godaddy.png',
    link: 'godaddy',
    instructionsLink: 'https://help.drum.io/hc/en-us/articles/360051469254',
    baseCode: getBaseCode(id),
    completionCode
  },
  {
    id: 'shopify',
    name: 'Shopify',
    src: '/assets/shopify.png',
    link: 'shopify',
    baseCode: getBaseCode(id),
    completionCode
  }
];
export const OTHER_PLATFORM = 'other';
export const getOtherPlatform = (id?: string): IntegrationPlatform => ({
  id: OTHER_PLATFORM,
  name: '',
  src: '',
  link: OTHER_PLATFORM,
  baseCode: getBaseCode(id),
  completionCode
});

export const BASE_PREFIX = 'base';
export const COMPLETION_PREFIX = 'completion';
export const COMPLETION_EVENT_URL_PLACEHOLDER = 'https://PLACEHOLDER-URL.SIGNIFYING-THAT-OFFER-COMPLETION-INTEGRATION-IS-VERIFIED.drum.io'