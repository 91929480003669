import { useCallback } from 'react';
import debounce from 'lodash.debounce';

const DEFAULT_TIMEOUT = 400;

function useDebouncedQuery(
  // TODO: add type for query
  queryHook: any,
  options?: any,
  timeout: number = DEFAULT_TIMEOUT
) {
  const [doQuery, { ...rest }] = queryHook(options);

  const query = useCallback(debounce(doQuery, timeout), []);

  return [query, { ...rest }];
}

export default useDebouncedQuery;
