import { CommissionType } from '../graphql';
import ReferralRewardTypes from 'constants/referralRewardTypes';

const getFormattedEarning = (
  commissionType: string | null,
  commissionAmount: number | null
) =>
  commissionType === CommissionType.Percentage
    ? `${commissionAmount}${ReferralRewardTypes.PERCENTAGE}`
    : commissionAmount === null ? 'X' : `${ReferralRewardTypes.FIXED}${commissionAmount}`;

export default getFormattedEarning;
