import styled from 'styled-components';
import { Body2, H4, LinkTextMixin, PrimaryButton } from 'uikit';

export const Section = styled.div`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const RewardDetailsTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.alertColor};
`;

export const SectionDetails = styled(Body2)`
  color: ${({ theme }) => theme.colors.bodyTextColor};
  min-height: 63px;
`;

export const EarnButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
`;

export const LinksList = styled.div`
  margin-top: 10px;
`;

export const ReferrerEducationLink = styled.div`
  padding: 12px 20px;
  border: solid 1px ${({ theme }) => theme.colors.accentMainColor};
  text-align: center;
  ${LinkTextMixin};

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;
