import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { signIn } from 'services/auth';
import { fetchAuthData } from 'helpers/providerError';
import { InputBase, FullScreenLoader, FieldFooter } from 'uikit';
import PasswordField from 'components/PasswordField';
import { signInErrors, userNotFound, incorrectUserInputs, getAuthLoginErrorTitleByType, FALLBACK_AUTH_ERROR_MESSAGE, FALLBACK_AUTH_ERROR_MESSAGE_ANOTHER_ROLE } from 'constants/errors';
import validationSchema, { SignInType } from './validationSchema';
import {
  Form,
  FormHeading,
  ReturnButton,
  InputGroup,
  SubmitButton
} from './styles';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  className?: string;
  onReturnBack: () => void;
  onLoginSuccess: () => void;
}

const LogInEmailForm: FC<Props> = ({
  className,
  onLoginSuccess,
  onReturnBack
}) => {

  const [authDataFeching, setAuthDataFeching] = useState<boolean>(false);

  const initialValues: SignInType = {
    email: '',
    password: ''
  };

  const handleInvalidInputError = (values: SignInType, errorMessage: string, setFieldError: any) => {
    setAuthDataFeching(true);
    fetchAuthData(values.email.toString().toLowerCase().trim(), ({exists, provider, roles}) => {
      setAuthDataFeching(false);
      if(exists) {
        if(roles?.indexOf('DRUMMER') !== -1) {
          setFieldError('email', FALLBACK_AUTH_ERROR_MESSAGE_ANOTHER_ROLE);
        } else {
          setFieldError('email', getAuthLoginErrorTitleByType(provider || ''));
        }
      } else {
        setFieldError('email', errorMessage);
      }
    }, () => {
      setAuthDataFeching(false);
      setFieldError('email', errorMessage);
    })
  }

  const handleSubmit = async (values: SignInType, { setFieldError }: any) => {
    const { email, password } = values;

    const result = await signIn(email?.toLowerCase().trim(), password?.trim());

    if (result?.code) {
      const error = signInErrors.find((error) => error.code === result.code);
      const errorMessage = error?.message || FALLBACK_AUTH_ERROR_MESSAGE;
      if(result.code === userNotFound.code || result.code === incorrectUserInputs.code) {
        handleInvalidInputError(values, errorMessage, setFieldError);
      } else {
        setFieldError('email', errorMessage);
      }

      
    } else {
      onLoginSuccess();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
        <Form className={className} onSubmit={handleSubmit}>
          <FormHeading>Log In with Email</FormHeading>
          <ReturnButton onClick={onReturnBack} type="button">
            &lt; Back
          </ReturnButton>
          <InputGroup>
            <InputBase
              onChange={handleChange}
              name="email"
              type="text"
              placeholder="Email"
              value={values.email}
              id={trackedElementsIds.logInWithEmail.emailInput}
            />
            <FieldFooter error={errors.email} />
          </InputGroup>

          <InputGroup>
            <PasswordField
              onChange={handleChange}
              name="password"
              placeholder="Enter your password"
              showPasswordStrength={false}
              value={values.password}
            />
            <FieldFooter error={errors.password} />
          </InputGroup>
          {/* TODO: add captcha */}
          <SubmitButton
            // type="submit"
            disabled={isSubmitting}
            id={trackedElementsIds.logInWithEmail.logInButton}
          >
            Log In
          </SubmitButton>

          {(isSubmitting || authDataFeching) && <FullScreenLoader />}
        </Form>
      )}
    </Formik>
  );
};

export default LogInEmailForm;
