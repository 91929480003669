import styled, { css } from 'styled-components';
import { ButtonTextMixin } from 'uikit';
import { Envelope, Google, ShopifyIcon } from 'assets/svg';

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${ButtonTextMixin};
  background-color: transparent;
  border-radius: 30px;

  :not(:last-child) {
    margin-bottom: 25px;
  }
`;

const IconStyle = css`
  margin-right: 15px;
`;

export const StyledShopifyIcon = styled(ShopifyIcon)`
  ${IconStyle};
`;

export const StyledGoogleIcon = styled(Google)`
  ${IconStyle};
`;

export const StyledEnvelope = styled(Envelope)`
  ${IconStyle};
`;
