import React, {FC} from 'react'
import {
  Container,
  StyledLink,
  Contacts,
  Email,
  EmailLink,
} from './styled'

import {
  CONTACT_SUPPORT_LINK
} from 'constants/outerLinks'
interface Props {
  type: string
}

const VerificationFailed:FC<Props> = ({type}) => {

  return (
    <Container>
      <p>Visit this <StyledLink target='_blank' href={CONTACT_SUPPORT_LINK}>support page</StyledLink> for {type} verification trouble shooting tips.</p>
      <p>If you continue to have problems, please contact support</p>
      <Contacts>
        <Email>
          <EmailLink href={'mailto:help@drum.io'}>help@drum.io</EmailLink>
        </Email>

      </Contacts>

    </Container>
  )
}

export default VerificationFailed;





