import React, { FC } from 'react';
import { } from 'uikit';
import { getImagePath } from 'helpers';
import {
  Container,
  Header,
  HeaderInner,
  HeaderInnerAlt,
  HeaderLogo,
  HeaderInfo,
  BusinessName,
  HeaderText,
  TextAlt,
  Content,
  ContentInner,
  Footer,
  FooterAlt,
  MainLogo,
  FooterText
} from './styles';

import {
  GetBusinessDetailsQuery,
  ReferralPageStateConfigPageStyleBackgroundType,
  ImageStyle
} from '../../../../graphql';

export interface Props {
  className?: string;
  business: GetBusinessDetailsQuery,
  background?: string,
  backgroundType?: ReferralPageStateConfigPageStyleBackgroundType,
  isEdit?: boolean;
}

const ReferralPreviewContainer: FC<Props> = ({ 
  className, 
  business, 
  children, 
  background = '#ededed',
  backgroundType = ReferralPageStateConfigPageStyleBackgroundType.Color,
  isEdit = false
}) => {

  return (
    <Container>
      <Header>
        {isEdit ?
          <HeaderInner>
              
            <HeaderLogo>
              <img src={getImagePath(business.getCurrentBusiness?.imagesS3?.[0].styles as ImageStyle[] || [])} alt="drum" />
            </HeaderLogo>
            <HeaderInfo>
              <BusinessName>{business.getCurrentBusiness?.businessName}</BusinessName>
              <HeaderText>Referral Program</HeaderText>
            </HeaderInfo>
          </HeaderInner>
          :
          <HeaderInnerAlt>
            <TextAlt>Your website main navigation</TextAlt>
          </HeaderInnerAlt>
        }
        
      </Header>
      <Content style={
        backgroundType === ReferralPageStateConfigPageStyleBackgroundType.Image ?
        { backgroundImage: `url('${background}')` } : { backgroundColor: background }
      }>
        <ContentInner className={className || ''}>
          {children}
        </ContentInner>
      </Content>
      {isEdit ?
        <Footer>
          <MainLogo>
            <img src="/assets/drum-lightgray.png" alt="drum" />
          </MainLogo>
          <FooterText>
            © Drum Technologies Inc. All rights reserved.
          </FooterText>
        </Footer>
        :
        <FooterAlt>
          <TextAlt>Your website footer</TextAlt>
        </FooterAlt>  
      }
    </Container>
  );
};

export default ReferralPreviewContainer;
