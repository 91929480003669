import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import PageHeading from '../../components/PageHeading';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 40px 0 35px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 0;
  `}
`;

export const StyledHeading = styled(PageHeading)`
  flex: 1;
`;

export const HeadingContainer = styled.div`
  display: flex;

  ${breakpoint('md')`
    margin-bottom: 20px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 25px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.main};
`;

export const Content = styled.div`
  
`;



export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  padding-right: 65px;
  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
`;
export const InfoLeft = styled.div`
  ${breakpoint('xs', 'lg')`
    flex: 0.6
  `}
`;
export const InfoRight = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  justify-content: flex-end;
  ${breakpoint('xs', 'lg')`
    flex: 0.4
  `}
`;
export const NameBlock = styled.div`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
`;
export const HistoryName = styled.span`
  display: inline-block;
  margin-right: 12px;
`;
export const ReferrerCount = styled.span`
  position: relative;
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 10px;
`;

export const DateBlock = styled.div`
  margin-top: 14px;
  font-size: ${({ theme }) => theme.size.subtitle};
`;
export const PriceBlock = styled.span`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;




export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 65px;
  margin-top: 30px;

  ${breakpoint('xs', 'lg')`
    justify-content: center;
  `}
`;
