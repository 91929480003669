import React, { FC, Fragment } from 'react';
import { components, MenuListComponentProps } from 'react-select';
import routes from '../../../../constants/routes';
import { IOption } from '../../OpportunitySelect';
import { MenuFooter, ViewAllLink } from './styles';
import { DDArrow } from '../../../CustomSelect/styles';
import { DropDownArrow } from 'assets/svg';

const MenuList: FC<MenuListComponentProps<IOption>> = ({
  children,
  ...props
}) => (
  <Fragment>
    <DDArrow ><DropDownArrow /></DDArrow>
    <components.MenuList {...props}>
      {children}
    </components.MenuList>
    <MenuFooter>
      <ViewAllLink to={routes.MY_OPPORTUNITIES}>View All</ViewAllLink>
    </MenuFooter>
  </Fragment>
);

export default MenuList;
