import styled from 'styled-components';
import { PrimaryButton, OutlinedButton, SecondaryButton } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  ${breakpoint('lg')`
    display: flex;
    padding: 25px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 20px;
  `}
`;

export const BackButton = styled(OutlinedButton)`
  ${breakpoint('lg')`
    width: 120px;
  `}

  ${breakpoint('xs', 'lg')`
    width: 100%;
    margin-bottom: 15px;
  `}
`;

export const SaveDraftButton = styled(SecondaryButton)`
  ${breakpoint('lg')`
    width: 160px;
    margin: 0 20px 0 auto;
  `}

  ${breakpoint('xs', 'lg')`
    width: 100%;
    margin-bottom: 15px;
  `}
`;

export const SubmitButton = styled(PrimaryButton)`
  ${breakpoint('lg')`
    width: 160px;
  `}

  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;
