import FALLBACK_IMAGE_URL from "constants/fallbackImageURL";

export const CUSTOMER_VIEW = 'customerView';
export const DRUMMER_VIEW = 'drummerView';

export type ViewModeType = typeof CUSTOMER_VIEW | typeof DRUMMER_VIEW;
type PreviewOption = { name: string; value: ViewModeType };

export const PREVIEW_MODES: [PreviewOption, PreviewOption] = [
  {
    name: 'Customer',
    value: CUSTOMER_VIEW
  },
  {
    name: 'Referrer',
    value: DRUMMER_VIEW
  }
];

export const OPPORTUNITY_FALLBACK_DATA = {
  title: 'Title goes here...',
  description: 'Description goes here...',
  companyLogoPlaceholderURL: FALLBACK_IMAGE_URL,
  earning: 'X'
};
