import styled from 'styled-components';
import { breakpoint } from '../../helpers';

export const Container = styled.footer`
  flex-shrink: 0;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.raleway};
  font-size: ${(props) => props.theme.size.body2};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: rgba(255, 255, 255, 0.58);
  background-color: ${(props) => props.theme.colors.blackPattern};

  ${breakpoint('md')`
    padding-bottom: 30px;
  `}

  ${breakpoint('xs', 'md')`
    padding-bottom: 40px;
  `}
`;

export const LogoContainer = styled.div`
  margin-bottom: 20px;
`;
