import React, { FC } from 'react';
import { Calendar } from 'assets/svg';
import { InputContainer, StyledInput } from './styles';
import './styles.scss';

const DatePickerInput: FC<any> = ({ onClick, value }) => (
  <InputContainer onClick={onClick}>
    <StyledInput placeholder="mm/dd/yyyy" readOnly value={value} />
    <Calendar />
  </InputContainer>
);

export default DatePickerInput;
