import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  margin-top: 40px;
`;

export const HeadingContainer = styled.div`
  display: flex;

  ${breakpoint('md')`
    margin-bottom: 20px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 25px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.main};
`;

export const Content = styled.div`
  
`;

export const BillingInstructions = styled.p`
  ${breakpoint('xs', 'lg')`
    padding: 0 25px;
  `}
  line-height: 1.15;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};

  ${breakpoint('xs', 'md')`
    margin-bottom: 15px;
  `}

  ${breakpoint('md')`
    margin-bottom: 30px;
  `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  ${breakpoint('xs', 'md')`
    justify-content: center;
  `}
`;
