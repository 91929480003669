import React, { FC } from 'react';
import IconProps from './IconProps';

const CloseRedMedium: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <g fill="#CC2D28">
      <path d="M19.967 18.012l5.659-5.66c.529-.528.529-1.425 0-1.954a1.39 1.39 0 00-1.979 0l-5.635 5.635-5.682-5.659a1.39 1.39 0 00-1.978 0c-.53.53-.53 1.427 0 1.956l5.658 5.659-5.658 5.658a1.39 1.39 0 000 1.979 1.39 1.39 0 001.978 0l5.659-5.66 5.658 5.66a1.39 1.39 0 001.979 0 1.39 1.39 0 000-1.979l-5.66-5.635z"></path>
      <path d="M18.012 0C8.074 0 0 8.074 0 18.012c0 9.937 8.074 18.011 18.012 18.011 9.937 0 18.011-8.074 18.011-18.012C36 8.075 27.926 0 18.011 0zm0 33.24c-8.397 0-15.229-6.832-15.229-15.229-.023-8.419 6.81-15.25 15.229-15.25 8.396 0 15.228 6.831 15.228 15.227 0 8.42-6.832 15.252-15.229 15.252z"></path>
    </g>
  </svg>
);

export default CloseRedMedium;
