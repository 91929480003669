import React, { FC, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useDropzone, DropEvent } from 'react-dropzone';
import { useImperativeGetUploadJob } from './requests';
import { getCurrentCredentials } from 'services/auth';
import { FullScreenLoader, FieldFooter } from 'uikit';
import { FileIcon, CloseRoundIcon } from 'assets/svg';
import {
  useGetCustomersUploadUrlLazyQuery,
  CustomersUploadUrl,
  useStartProcessingUploadedCustomersMutation,
  JobStatus,
  UploadCustomersStats
} from '../../graphql';
import {
  IUploadError,
  getErrorByCode,
  errorCodes
} from './helpers/fileUploadErrors';
import {
  Container,
  DropzoneContainer,
  UploadContainer,
  Caption,
  PreviewsContainer,
  Preview,
  PreviewIcon,
  PreviewText,
  PreviewExtension,
  UploadButton,
  StyledCloseButton,
  ButtonContainer,
  ActionButton
} from './styles';

interface Props {
  className?: string;
  maxSize?: number;
  onSubmitSuccess?: (data?: UploadCustomersStats, fileName?: string) => void;
  onSubmitError?: (error: IUploadError) => void;
  onDropSuccess?: () => void;
  onDropError?: (error: IUploadError) => void;
}

const DEFAULT_MAX_SIZE = 26214400;
const DEFAULT_CSV_STATUS_REQUEST_INTERVAL = 3000;
const getExtension = (name: string) => {
  return name.split('.').pop();
};

const allowedFormats = '.csv, application/vnd.ms-excel, text/csv';

const CsvUpload: FC<Props> = ({
  className,
  maxSize = DEFAULT_MAX_SIZE,
  onSubmitSuccess,
  onSubmitError,
  onDropSuccess,
  onDropError
}) => {
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [dzFiles, setDzFiles] = useState<File[]>([]);
  const [fileReadyToSubmit, setFileReadyToSubmit] = useState<boolean>(false);

  const [
    getCustomersUploadUrl,
    { data: urlData, loading: urlDataLoading }
  ] = useGetCustomersUploadUrlLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const getUploadJob = useImperativeGetUploadJob();

  const [
    startProcessing,
    { data: processingData, loading: processingLoading }
  ] = useStartProcessingUploadedCustomersMutation();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setDzFiles([...dzFiles, ...acceptedFiles]);
    },
    [dzFiles]
  );

  const onDropAccepted = async (files: File[]) => {
    setLoading(true);

    const credentials = await getCurrentCredentials();
    const identityId = credentials?.identityId;

    if (!identityId) {
      setError("You don't have permissions to upload a file.");
      setLoading(false);
      return;
    }
    try {
      getCustomersUploadUrl();
    } catch (error) {
      setError('Upload failed.');
    }

    setLoading(false);
  };

  const onDropRejected = (files: File[], event: DropEvent) => {
    let [file] = files;
    if (file.size > maxSize) {
      onDropError && onDropError(getErrorByCode(errorCodes.SIZE_EXCEEDED));
    } else if (getExtension(file.name)?.toLowerCase() !== 'csv') {
      onDropError &&
        onDropError(getErrorByCode(errorCodes.INCORRECT_FILE_TYPE));
    } else {
      onDropError && onDropError(getErrorByCode(errorCodes.UNKNOWN_ERROR));
    }
    setError('Wrong format or size');
  };

  const onClear = () => {
    setDzFiles([]);
  };

  const uploadFile = (reqData: CustomersUploadUrl) => {
    if (!reqData.url || !reqData.meta) return;

    let config = {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress: function(progressEvent: {
        loaded: number;
        total: number;
      }) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        percentCompleted === 100 && setLoading(false);
      }
    };

    setLoading(true);

    axios.put(reqData.url, dzFiles[0], config).then(
      (response) => {
        setFileReadyToSubmit(true);
      },
      (error) => {
        setFileReadyToSubmit(false);
        setError('Error while uploading the file');
      }
    );
  };

  const startImport = () => {
    let reqData = urlData?.getCustomersUploadUrl;
    if (
      !reqData?.url ||
      !reqData?.meta?.key ||
      !reqData?.meta?.bucket ||
      !fileReadyToSubmit
    )
      return;
    startProcessing({
      variables: {
        input: {
          key: reqData.meta.key,
          bucket: reqData.meta.bucket
        }
      }
    });
  };

  //Starting file upload once we have a valid upload URL
  useEffect(() => {
    if (!urlData?.getCustomersUploadUrl) return;
    uploadFile(urlData?.getCustomersUploadUrl);
    // eslint-disable-next-line
  }, [urlData]);

  //Disabling import when there are no files
  useEffect(() => {
    if (!dzFiles?.length) {
      setFileReadyToSubmit(false);
    }
  }, [dzFiles]);

  //Getting process id
  useEffect(() => {
    const startAwaitUploadStatus = async (id: string) => {
      if (!id) return;
      setLoading(true);
      let interval = setInterval(async () => {
        let uploadJobData = await getUploadJob(id);
        const job = uploadJobData?.data?.getJob;
        if (uploadJobData.errors || job?.status === JobStatus.Error) {
          clearInterval(interval);
          onSubmitError &&
            onSubmitError(getErrorByCode(errorCodes.FORMATTING_FAILED));
          return;
        } else if (!job?.status || !job?.executionInfo) {
          return;
        } else if (job.status === JobStatus.Finished) {
          clearInterval(interval);
          setLoading(false);
          if (job.executionInfo.error) {
            onSubmitError &&
              onSubmitError(getErrorByCode(errorCodes.FORMATTING_FAILED));
          } else {
            onSubmitSuccess &&
              onSubmitSuccess(
                job.executionInfo.uploadCustomersStats || undefined,
                dzFiles[0].name || ''
              );
          }
        }
      }, DEFAULT_CSV_STATUS_REQUEST_INTERVAL);
    };

    const id = processingData?.startProcessingUploadedCustomers?.id;
    if (!id) return;
    startAwaitUploadStatus(id);
    // eslint-disable-next-line
  }, [processingData]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: allowedFormats,
    multiple: false,
    maxSize: maxSize,
    noClick: true,
    onDropAccepted,
    onDropRejected,
    onDrop
  });

  return (
    <>
      <Container className={className}>
        <DropzoneContainer {...getRootProps()}>
          {dzFiles?.length ? (
            <PreviewsContainer>
              {dzFiles.map((item) => (
                <Preview>
                  <PreviewIcon>
                    <FileIcon />
                    <PreviewExtension>
                      .{getExtension(item.name)}
                    </PreviewExtension>
                  </PreviewIcon>
                  <PreviewText>{item.name}</PreviewText>
                </Preview>
              ))}

              <StyledCloseButton onClick={onClear} type="button">
                <CloseRoundIcon />
              </StyledCloseButton>
            </PreviewsContainer>
          ) : (
            <UploadContainer>
              <input {...getInputProps()} />
              <UploadButton onClick={open} type="button">
                Browse your files
              </UploadButton>
              <Caption>Or drag and drop your file here.</Caption>
            </UploadContainer>
          )}
        </DropzoneContainer>
        <FieldFooter error={error} />

        <ButtonContainer>
          <ActionButton disabled={!fileReadyToSubmit} onClick={startImport}>
            Import .CSV
          </ActionButton>
        </ButtonContainer>
      </Container>
      {(loading || urlDataLoading || processingLoading) && <FullScreenLoader />}
    </>
  );
};

export default CsvUpload;
