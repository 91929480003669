import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloError } from 'apollo-boost';
import { Modal } from 'uikit';
import setupProfileErrors from './constants';

interface Props {
  error: ApolloError | null;
  isOpen: boolean;
  onClose: () => void;
  onSaveDraft: () => Promise<void>;
}

const SaveOpportunityErrorModal: FC<Props> = ({
  error,
  isOpen,
  onClose,
  onSaveDraft
}) => {
  const history = useHistory();

  const handlePublishOpportunityErrors = (error: ApolloError | null) => {
    // By default API sends single error
    if (error?.graphQLErrors.length === 1) {
      const [errorData] = error.graphQLErrors;
      const { errorType: errorCode } = errorData;

      const setupProfileErrorsList = Object.values(setupProfileErrors);
      return setupProfileErrorsList.find(({ code }) => code === errorCode);
    }

    return null;
  };

  const modalConfig = handlePublishOpportunityErrors(error);
  const mainButtonProps = !!modalConfig
    ? {
        text: modalConfig.buttonText,
        onClick: async () => {
          await onSaveDraft();
          history.push(modalConfig.redirect);
        }
      }
    : {
        text: 'OK',
        onClick: onClose
      };

  return (
    <Modal
      canClose
      type="warning"
      title={
        modalConfig?.message ||
        'We’re sorry. Something went wrong. Please try again'
      }
      isOpen={isOpen}
      mainButton={mainButtonProps}
      onClose={onClose}
    />
  );
};

export default SaveOpportunityErrorModal;
