import React, { FC, useState, useEffect } from 'react';
import { NetworkStatus } from 'apollo-client';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, FormikErrors, FieldArray } from 'formik';
import GeneralLayout from 'layouts/GeneralLayout';
import FAQ from 'components/FAQ';
import CompleteAccountSetUp from 'components/CompleteAccountSetUp';
import routes from 'constants/routes';
import {
  CategoriesCount,
  CategoriesDescription,
  Content,
  InputGroup,
  OptionalFieldCaption,
  PageHeader,
  PrimaryContactSection,
  PrimaryContactFields,
  PrimaryContactDescription,
  RemoveCategoryButton,
  ReturnLink,
  Section,
  SectionTitle,
  SelectedCategoriesContainer,
  SelectedCategory,
  StyledHeading,
  StyledFooter
} from './styled';
import CategoriesAutosuggest from 'components/CategoriesAutosuggest';
import { FIELD_MAX_LENGTH } from 'constants/fieldLengthLimits';
import {
  Body1,
  Body3,
  InputBase,
  ImageUpload,
  FullScreenLoader,
  Modal,
  PhoneInput,
  FieldLabel,
  FieldFooter
} from 'uikit';
import IImageUpload from 'types/ImageUpload';
import {
  useGetCurrentBusinessQuery,
  useUpdateCurrentBusinessMutation,
  UpdateCurrentBusinessMutation,
  GetCurrentBusinessQuery,
  Business
} from '../../graphql';
import { useIncFirstAccountCompletionStepShownTimesMutation } from '../../graphql';
import businessProfileFormSchema, {
  BusinessProfileFormType
} from './validationSchema';
import {
  getCategoriesList,
  getLogoImageList,
  getLogoURL,
  handleFormatBusinessProfileInput
} from './utils';
import { CATEGORIES_MAX_SELECTION, TOOLTIPS } from './constants';
import { getUserImageUploadURL } from 'helpers';
import {
  sendProfileCategorySearchTrackingEvent,
  sendCompleteProfileTrackingEvent
} from 'helpers/segment';
import { CloseRoundIcon } from 'assets/svg';

const BusinessProfilePage: FC<RouteComponentProps> = ({ history }) => {
  const [initialData, setInitialData] = useState<Business | null>(null);
  const [checkSetupModal, setCheckSetupModal] = useState<boolean>(false);
  const [isDraftProfileModalOpen, setDraftProfileModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorModalTitle, setErrorModalTitle] = useState<string>('');

  // TODO: handle graphql errors
  const {
    data,
    loading: isGetBusinessLoading,
    refetch: refetchBusiness,
    networkStatus: getBusinessStatus
  } = useGetCurrentBusinessQuery();
  const [
    incFirstAccountCompletionStepShownTimes
  ] = useIncFirstAccountCompletionStepShownTimesMutation();

  const onUpdateSuccess = async () => {
    await incFirstAccountCompletionStepShownTimes();
    await refetchBusiness();
    setCheckSetupModal(true);
  };

  const [
    updateCurrentBusiness,
    { loading: isUpdateBusinessLoading }
  ] = useUpdateCurrentBusinessMutation({
    onCompleted: (resultData: UpdateCurrentBusinessMutation) => {
      onUpdateSuccess();
      sendCompleteProfileTrackingEvent(
        resultData.updateCurrentBusiness as Business,
        !!initialData?.category?.id
      ); //Defining if a profile was completed before
      setInitialData(resultData.updateCurrentBusiness as Business);
    },
    onError: (err) => {
      if (err?.graphQLErrors?.length) {
        err && openModal(err?.graphQLErrors[0].message);
      }
    }
  });

  const getInitialValues = (
    data: GetCurrentBusinessQuery | undefined
  ): BusinessProfileFormType => {
    if (!data?.getCurrentBusiness) {
      return {
        businessName: '',
        firstName: '',
        lastName: '',
        logo: [],
        phoneNumber: '',
        category: []
      };
    }

    return {
      businessName: data?.getCurrentBusiness?.businessName || '',
      logo: (getLogoImageList(data?.getCurrentBusiness) as any) || [],
      category: (getCategoriesList(data?.getCurrentBusiness) as any) || [],
      firstName: data?.getCurrentBusiness?.primaryContact?.firstName || '',
      lastName: data?.getCurrentBusiness?.primaryContact?.lastName || '',
      phoneNumber: data?.getCurrentBusiness?.primaryContact?.phoneNumber || ''
    };
  };

  const closeErrorModal = () => {
    setModalIsOpen(false);
  };

  const openModal = (title: string) => {
    setErrorModalTitle(title);
    setModalIsOpen(true);
  };

  const handleUpdateProfile = (values: BusinessProfileFormType) => {
    const input = handleFormatBusinessProfileInput(values) as any;
    updateCurrentBusiness({ variables: { input } });
  };

  const handleClickSubmit = (
    validateForm: (
      values?: BusinessProfileFormType
    ) => Promise<FormikErrors<BusinessProfileFormType>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: BusinessProfileFormType
  ) => () => {
    if (isUpdateBusinessLoading) return;
    setSubmitting(true);

    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      setSubmitting(false);
      hasErrors ? setDraftProfileModal(true) : handleUpdateProfile(values);
    });
  };

  const handleAddImage = (setFieldValue: any) => (imageData: IImageUpload) => {
    setFieldValue('logo', [imageData]);
  };

  useEffect(() => {
    if (!data?.getCurrentBusiness) return;
    if (!initialData) {
      setInitialData(data?.getCurrentBusiness as Business);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {(isGetBusinessLoading ||
        isUpdateBusinessLoading ||
        getBusinessStatus === NetworkStatus.refetch) && <FullScreenLoader />}
      <GeneralLayout sidebar={<FAQ />}>
        <Formik
          enableReinitialize
          validationSchema={businessProfileFormSchema}
          initialValues={getInitialValues(data)}
          onSubmit={handleUpdateProfile}
          validateOnChange={true}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            validateForm,
            setSubmitting
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Content>
                  <PageHeader>
                    <StyledHeading>Business Profile</StyledHeading>
                    <ReturnLink to={routes.DASHBOARD}>
                      &lt; Back to Dashboard
                    </ReturnLink>
                    <Body1>
                      Please provide a little information about your business so
                      we may set up your profile.
                    </Body1>
                  </PageHeader>

                  <div>
                    <Section>
                      <SectionTitle
                        title="Business Name"
                        tooltip={TOOLTIPS.businessName}
                        tagname="h3"
                      />
                      <InputBase
                        onChange={handleChange}
                        name="businessName"
                        value={values.businessName}
                        maxLength={FIELD_MAX_LENGTH}
                      />
                      <FieldFooter
                        error={errors.businessName}
                        limit={FIELD_MAX_LENGTH}
                        fieldValueLength={values.businessName.length}
                      />
                    </Section>
                    <Section>
                      <SectionTitle
                        title="Logo"
                        tooltip={TOOLTIPS.logo}
                        tagname="h3"
                      />
                      <ImageUpload
                        allowMultipleUpload={false}
                        onSuccess={handleAddImage(setFieldValue)}
                        previewURL={getLogoURL(values.logo)}
                        getImageUploadUrl={getUserImageUploadURL}
                      />
                      <FieldFooter error={errors.logo as string} />
                    </Section>

                    <Section>
                      <SectionTitle
                        title="Business Category"
                        tooltip={TOOLTIPS.category}
                        tagname="h3"
                      />
                      <CategoriesDescription>
                        You may add up to
                        <CategoriesCount>
                          {CATEGORIES_MAX_SELECTION}
                        </CategoriesCount>
                        searchable categories for your business. Referrers will
                        be able to find your business within the categories you
                        define. Start typing to look up your category options.
                      </CategoriesDescription>

                      <FieldArray
                        name="category"
                        render={({ remove, push }) => (
                          <>
                            <CategoriesAutosuggest
                              selectedCategories={values.category}
                              onSelect={(obj: any) => {
                                sendProfileCategorySearchTrackingEvent(
                                  obj.searchValue,
                                  obj.name
                                );
                                push(obj);
                              }}
                            />
                            <FieldFooter error={errors.category as string} />
                            <SelectedCategoriesContainer>
                              {values.category.map(({ id, name }, index) => (
                                <SelectedCategory key={id}>
                                  <Body3>{name}</Body3>
                                  <RemoveCategoryButton
                                    onClick={() => remove(index)}
                                    type="button"
                                  >
                                    <CloseRoundIcon />
                                  </RemoveCategoryButton>
                                </SelectedCategory>
                              ))}
                            </SelectedCategoriesContainer>
                          </>
                        )}
                      />
                    </Section>

                    <PrimaryContactSection>
                      <SectionTitle
                        title="Main Contact Person"
                        tooltip={TOOLTIPS.mainContactPerson}
                        tagname="h3"
                      />
                      <PrimaryContactDescription>
                        This information will not appear in your public profile.
                        It is for Drum customer support purposes only.
                      </PrimaryContactDescription>
                      <PrimaryContactFields>
                        <InputGroup>
                          <FieldLabel text="First name"/>
                          <InputBase
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                          />
                          <FieldFooter error={errors.firstName} />
                        </InputGroup>
                        <InputGroup>
                          <FieldLabel text="Last Name" />
                          <InputBase
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                          <FieldFooter error={errors.lastName} />
                        </InputGroup>
                        <InputGroup>
                          <FieldLabel>
                            <span>
                              Phone number
                              <OptionalFieldCaption>
                                (Optional)
                              </OptionalFieldCaption>
                            </span>
                          </FieldLabel>
                          <PhoneInput
                            name="phoneNumber"
                            onChange={(value) =>
                              setFieldValue('phoneNumber', value)
                            }
                            value={values.phoneNumber}
                          />
                          <FieldFooter error={errors.phoneNumber} />
                        </InputGroup>
                      </PrimaryContactFields>
                    </PrimaryContactSection>
                  </div>
                </Content>

                <StyledFooter
                  canSubmit={!isUpdateBusinessLoading}
                  onClick={handleClickSubmit(
                    validateForm,
                    setSubmitting,
                    values
                  )}
                />

                <Modal
                  canClose
                  imageURL="/assets/notification-icons/save.png"
                  title="Business profile form will be saved as a draft. Please fill in all the required fields to complete the Business Profile step."
                  isOpen={isDraftProfileModalOpen}
                  onClose={() => setDraftProfileModal(false)}
                  mainButton={{
                    onClick: () => handleUpdateProfile(values),
                    text: 'Save Draft'
                  }}
                  secondaryButton={{
                    text: 'Complete Business Profile',
                    onClick: () => setDraftProfileModal(false)
                  }}
                />
              </form>
            </>
          )}
        </Formik>

        <Modal
          type="warning"
          title={errorModalTitle}
          mainButton={{ text: 'Okay', onClick: closeErrorModal }}
          isOpen={modalIsOpen}
          onClose={closeErrorModal}
        ></Modal>

        <CompleteAccountSetUp
          onModalClose={() => {
            setCheckSetupModal(false);
            history.push(routes.MY_OPPORTUNITIES);
          }}
          defaultAction={() => history.push(routes.DASHBOARD)}
          check={checkSetupModal}
        />
      </GeneralLayout>
    </>
  );
};

export default BusinessProfilePage;
