import React from 'react';
import omitDeep from 'omit-deep-lodash';
import { getTemplateConfigs } from './components/EmailCampaignTemplates/constants';
import { emailType, sendType } from './types';
import { EmailCampaignFormType } from './validationSchema';

import {
  ImageStyle,
  ImageStyleName,
  Offer,
  TemplateStyles,
  CampaignStatus,
  Campaign
} from '../../graphql';
import { getImagePath } from 'helpers';
import {
  InvitationA,
  InvitationB,
  InvitationC,
  InvitationD,
  ReminderA,
  ReminderB,
  ReminderC,
  ReminderD,
  NewOpportunityA,
  NewOpportunityB,
  NewOpportunityC,
  NewOpportunityD,
} from './components/EmailCampaignTemplates';
import { TEXT_FORMAT_OPTIONS, getInitialValues } from './constants';

export const getTemplate = (type: emailType, style: TemplateStyles, offers: Offer[], values?: any, configType?: 'default' | 'alt') => {
  const defaultConfigType = (style === TemplateStyles.TemplateStyle1 || style === TemplateStyles.TemplateStyle3) ? 'default' : 'alt';
  const templateConfig = getTemplateConfigs(offers?.[0]?.business?.businessName || '')[type][configType || defaultConfigType];
  const templates = {
    [TemplateStyles.TemplateStyle1]: {
      [emailType.invitationEmail]: InvitationA,
      [emailType.reminderEmail]: ReminderA,
      [emailType.newOpportunityEmail]: NewOpportunityA,
    },
    [TemplateStyles.TemplateStyle2]: {
      [emailType.invitationEmail]: InvitationB,
      [emailType.reminderEmail]: ReminderB,
      [emailType.newOpportunityEmail]: NewOpportunityB,
    },
    [TemplateStyles.TemplateStyle3]: {
      [emailType.invitationEmail]: InvitationC,
      [emailType.reminderEmail]: ReminderC,
      [emailType.newOpportunityEmail]: NewOpportunityC,
    },
    [TemplateStyles.TemplateStyle4]: {
      [emailType.invitationEmail]: InvitationD,
      [emailType.reminderEmail]: ReminderD,
      [emailType.newOpportunityEmail]: NewOpportunityD,
    }
  }

  const _config = {...templateConfig, ...(values || {})};
  if(offers?.length) {
    //Since we cannot set backgroundCOlor anymore
    _config.emailStyle.backgroundColor = templateConfig.emailStyle.backgroundColor;

    _config.businessName = offers[0].business?.businessName || '';
    _config.businessAvatar = getImagePath((offers[0].business?.coverImage?.styles || []) as ImageStyle[]);
    _config.emailStyle.coverImage = _config.emailStyle.coverImage || getImagePath((offers[0].coverPhoto?.styles || []) as ImageStyle[], ImageStyleName.Original, true);
    _config.listItems = offers.map((item => ({
      cover: item.coverPhoto?.styles ? getImagePath(item.coverPhoto.styles as ImageStyle[]) || getImagePath(item.coverPhoto.styles as ImageStyle[], ImageStyleName.Original): '',
      title: item.title,
      amount: item.drumRedemption?.payDrum || 0,
      amountType: item.drumRedemption?.commissionType || 'FIXED'
    })));
    if(type === emailType.newOpportunityEmail) {
      _config.listItems = _config.listItems.splice(0, 1);
    }
  }

  const resultTemplate = templates[style][type];

  return React.createElement(resultTemplate, {config: _config}, null);
}

export const getEmailTypeLabel = (
  type: emailType | string,
  capitalize?: boolean
) => {
  let result = 'email';
  switch (type) {
    case 'invitationEmail':
      result = 'invitation email';
      break;
    case 'newOpportunityEmail':
      result = 'new opportunity email';
      break;
    case 'reminderEmail':
      result = 'reminder email';
      break;
  }

  result = capitalize
    ? result.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
    : result;
  return result;
};

export const normalizeCampaignFormValues = (
  values: EmailCampaignFormType,
  status: CampaignStatus
): any => {
  const tags = values.sendToFilter?.tags?.in;
  const sendToFilter = values.sendType === sendType.tags ? {
    tags: {
      in: tags.map((item) => item.id)
    }
  }:{};

  return omitDeep({
    templateStyle: values.templateStyle,
    sendToFilter: sendToFilter,
    fromEmail: values.fromEmail.toLowerCase().trim(),
    emailStyle: values.emailStyle,
    emails: values.emails,
    status: status
  }, '__typename');
};

export const convertToFormValues = (
  data: Campaign,
  templateStyle?: TemplateStyles,
  canSetAllCustomers: boolean = true 
) => {
  return omitDeep({...(data || {}), 
    sendToFilter: {
      tags: {
        in: data?.sendToFilter?.tags?.in?.map(item => ({id: item?.id || '', name: item?.name || ''})) || []
      }
    },
    sendType: canSetAllCustomers ? (data?.sendToFilter?.tags ? sendType.tags : sendType.all) : sendType.tags,
    emailStyle: data?.emailStyle || getInitialValues().emailStyle,
    templateStyle: templateStyle || TemplateStyles.TemplateStyle1,
    canSetAllCustomers: !!canSetAllCustomers
  }, '__typename');
};

export const isTextFormatCorrect = (text: string) => {
  const reBrackets = /\{\{(.*?)\}\}/g;
  let match = reBrackets.exec(text);
  while(match) {
    if(TEXT_FORMAT_OPTIONS.indexOf(match[1]) === -1) return false;
    match = reBrackets.exec(text);
  };
  return true;
};