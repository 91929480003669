import styled from 'styled-components';
import { PrimaryButton, SecondaryButton, H2, Body2} from 'uikit';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -40px;
  text-align: left;
`;
export const TextContainer = styled.div`
  padding: 0 25px;
`;

export const Title = styled(H2)`
  margin: 0 0 25px 0; 
`;
export const Paragraph = styled(Body2)`
  
`;
export const EmphasizedParagraph = styled.div`
  margin: 30px 0 30px 0; 
  p {
    font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`;
export const ApplyButton = styled(PrimaryButton)`
  width: 203px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
export const CancelButton = styled(SecondaryButton)`
  width: 203px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;