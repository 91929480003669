import React, { FC } from 'react';
import IconProps from './IconProps';

const Search: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <defs>
      <path id="prefix__a" d="M0 0L19 0 19 19 0 19z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill="#999"
        d="M1.913 8.124c0-3.43 2.78-6.211 6.21-6.211 3.431 0 6.212 2.78 6.212 6.21 0 1.454-.503 2.787-1.338 3.845l-1.029 1.028c-1.058.836-2.391 1.339-3.844 1.339-3.43 0-6.211-2.781-6.211-6.211m16.591 7.987l-3.566-3.566c.827-1.273 1.31-2.79 1.31-4.421C16.248 3.637 12.61 0 8.124 0S0 3.637 0 8.124s3.637 8.124 8.124 8.124c1.63 0 3.148-.483 4.42-1.31l3.567 3.566c.661.661 1.732.661 2.394 0 .66-.66.66-1.732 0-2.393"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

export default Search;
