import styled from 'styled-components';
export const Container = styled.div`
  position: relative;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 9px;
`;
export const Number = styled.p`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 3px;

  &.warning {
    color: #f99700;
    font-size: ${({ theme }) => theme.size.body1};
  }
`;
export const SubNumber = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  margin-bottom: 30px;
  max-width: 210px;

  &.success {
    font-size: ${({ theme }) => theme.size.subtitle};
    color: ${({ theme }) => theme.colors.green};
  }
`;
export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  margin-bottom: 10px;
`;


