import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .no-radius {
    border-radius: 0 !important;
  }

  .no-radius-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .no-radius-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

export const LabelBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 50px;
  background-color: #f6f6f6;
  border: 1px solid #d4d4d4;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  &.left {
    border-right: none;
    border-radius: 5px 0 0 5px
  }
  &.right {
    border-left: none;
    border-radius: 0 5px 5px 0;
  }
`;
