import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ListOpportunities from 'components/ListOpportunities';
import DashboardPageLayout from 'layouts/DashboardPageLayout';
import routes from 'constants/routes';
import {
  CreateOpportunityButton,
  PageHeader,
  StyledPageHeading
} from './styled';

const MyOpportunitiesPage: FC<RouteComponentProps> = ({ history }) => (
  <DashboardPageLayout>
    <PageHeader>
      <StyledPageHeading title="Opportunities" />
      <CreateOpportunityButton
        onClick={() => history.push(routes.CREATE_OPPORTUNITY)}
        type="button"
      >
        Create new opportunity
      </CreateOpportunityButton>
    </PageHeader>
    <ListOpportunities />
  </DashboardPageLayout>
);

export default MyOpportunitiesPage;
