import moment from 'moment';
import omit from 'lodash.omit';
import { GetOfferQuery } from '../../graphql';


const formatDuplicatedOpportunity = (
  values: GetOfferQuery | undefined,
): GetOfferQuery | undefined => {
  if (!values?.getOffer) {
    return undefined;
  }
  const filteredValues = omit(values.getOffer, 'status');
  return {
    getOffer: {
      ...filteredValues,
      title: `Copy of - ${values.getOffer.title}`,
      startedAt: moment.utc().format(),
      endedAt: null
    }
  };
};

export default formatDuplicatedOpportunity;
