import styled from 'styled-components';
import { ITheme } from '../../types';
import { OfferStatus } from '../../graphql';
import { breakpoint } from '../../helpers';

interface ContainerProps extends ITheme {
  status: OfferStatus | null;
}

export const Container = styled.span<ContainerProps>`
  display: inline-block;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme, status }) => {
    switch (status) {
      case OfferStatus.Draft:
        return '#9e9e9e';
      case OfferStatus.InQueue:
        return '#8E8E8E';
      case OfferStatus.Active:
        return theme.colors.danger;
      case OfferStatus.Scheduled:
        return theme.colors.accentSecondaryColor;
      case OfferStatus.Archived:
        return theme.colors.borderColor;
      case OfferStatus.Paused:
        return '#ffa740';
      default:
        return theme.colors.black;
    }
  }};

  font-style: ${({ theme, status }) => {
    switch (status) {
      case OfferStatus.InQueue:
        return 'italic';
      default:
        return 'normal';
    }
  }};

  ${breakpoint('md', 'lg')`
    font-size: ${({ theme }) => theme.size.body2};
  `};
`;
