import React, { FC } from 'react';
import PageMenu from 'components/PageMenu';
import routes from 'constants/routes';
import { Header, Logo, LogoWrapper, LogoAndGreeting } from './styles';
import TimeOfDay from 'components/TimeOfDay';

const DashboardPageHeader: FC = () => (
  <Header>
    <LogoAndGreeting>
      <LogoWrapper to={routes.DASHBOARD}>
        <Logo />
        
      </LogoWrapper>
      <TimeOfDay />
    </LogoAndGreeting>
    <PageMenu isDashboardPage />
  </Header>
);

export default DashboardPageHeader;
