import React, { FC, useState } from 'react';
import {
  Form,
  Loader,
  PhoneInput,
  FieldLabel,
  FieldFooter,
  InputBase
} from '../../uikit';
import { Formik } from 'formik';
import {
  Container,
  FormContent,
  ButtonsContainer,
  SubmitButton,
  FormTitle,
  FieldContainer
} from './styles';
import { schema, SchemaType } from './validationSchema';

interface customError {
  field: string;
  code: string;
}

interface Props {
  className?: string;
  onSubmit: (values: SchemaType, setFieldError: any) => void;
  values?: SchemaType;
  buttonText?: string;
  title?: string;
}

const ManualCustomerAddForm: FC<Props> = ({
  className,
  onSubmit,
  values = {},
  buttonText = 'Add Customer',
  title = 'Add Customer'
}) => {
  const initialValues: SchemaType = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ...values
  };

  const [loading] = useState<boolean>(false);
  const handleSubmit = (values: SchemaType, { setFieldError }: any) => {
    onSubmit(values, setFieldError);
  };

  return (
    <Container className={className}>
      <FormTitle>{title}</FormTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={schema}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, errors }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <FormContent>
                  <Form>
                    {loading && <Loader />}

                    {/* FIRST NAME */}
                    <FieldContainer>
                      <FieldLabel text="First Name" />
                      <InputBase
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                        value={values.firstName}
                      />
                      <FieldFooter error={errors.firstName} />
                    </FieldContainer>

                    {/* LAST NAME */}
                    <FieldContainer>
                      <FieldLabel text="Last Name" />
                      <InputBase
                        name="lastName"
                        type="text"
                        onChange={handleChange}
                        value={values.lastName}
                      />
                      <FieldFooter error={errors.lastName} />
                    </FieldContainer>

                    {/* EMAIL */}
                    <FieldContainer>
                      <FieldLabel text="Email Address (Optional)" />
                      <InputBase
                        name="email"
                        type="text"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <FieldFooter error={errors.email} />
                    </FieldContainer>

                    {/* PHONE NUMBER */}
                    <FieldContainer>
                      <FieldLabel text="Phone number (Optional)" />
                      <PhoneInput
                        name="phone"
                        onChange={(value) =>
                          {setFieldValue('phone', value)}
                        }
                        value={values.phone}
                      />
                      <FieldFooter error={errors.phone} />
                    </FieldContainer>

                    <ButtonsContainer>
                      <SubmitButton type="submit" disabled={loading}>
                        {buttonText}
                      </SubmitButton>
                    </ButtonsContainer>
                  </Form>
                </FormContent>
              </form>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ManualCustomerAddForm;
