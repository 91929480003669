import imageTypes from 'constants/imageTypes';
import { GetCurrentBusinessQuery } from '../../graphql';
import { BusinessProfileFormType } from './validationSchema';
import { CATEGORIES_MAX_SELECTION } from './constants';

type BusinessDetails = GetCurrentBusinessQuery['getCurrentBusiness'];

export const getCategoriesList = (data: BusinessDetails) => {
  const categoryList = [
    data?.category,
    data?.category1,
    data?.category2,
    data?.category3,
    data?.category4
  ];

  return categoryList
    .map((category) => {
      if (category && category.name && category.id) {
        const { name, id } = category;

        return { name, id };
      }
      return null;
    })
    .filter(Boolean);
};

export const getLogoImageList = (data: BusinessDetails) => {
  const logoData = data?.imagesS3?.[0]?.styles;

  if (!logoData) return null;

  return logoData
    .map((style) => {
      if (style && style.name && style.path) {
        const { name, path } = style;

        return { name, path };
      }
      return null;
    })
    .filter(Boolean);
};

export const CATEGORIES_LIMIT = 5;

export const formatCategoriesList = (
  values: BusinessProfileFormType['category']
) => {
  const categoriesId: { [key: string]: string | null } = {};

  const CATEGORY_FIELDS_PREFIX = 'categoryId';

  for (let i = 0; i < CATEGORIES_MAX_SELECTION; i++) {
    const key = i ? `${CATEGORY_FIELDS_PREFIX}${i}` : CATEGORY_FIELDS_PREFIX;
    const value = values.length > i ? values[i].id : null;
    categoriesId[key] = value;
  }

  return categoriesId;
};

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

// TODO: handle lookup for cropped images
export const getLogoURL = (logoField: BusinessProfileFormType['logo']) => {
  if (!logoField.length) {
    return null;
  }

  const image = logoField.find(
    (style: any) => style.name === imageTypes.ORIGINAL && style.path
  );

  return image ? `${S3_DOMAIN}/${image.path}` : null;
};

export const handleFormatBusinessProfileInput = (
  values: BusinessProfileFormType
) => {
  const {
    businessName,
    category,
    firstName,
    lastName,
    phoneNumber,
    logo
  } = values;
  const categories = formatCategoriesList(category);

  return {
    businessInfo: {
      ...categories,
      businessName
    },
    businessPhotos: [{ styles: logo }],
    primaryContact: {
      firstName,
      lastName,
      phoneNumber
    }
  };
};
