import moment from 'moment';
import { CreateOpportunityType } from './validationSchema';
import {
  GetBusinessDetailsQuery,
  RewardActionType,
  IntegrationType
} from '../../graphql';
import { OpportunityTemplateData } from 'constants/createOpportunityParams';

export const getBlankFormValue = (
  businessProfileData: GetBusinessDetailsQuery | undefined,
  templateValues: OpportunityTemplateData | undefined,
): CreateOpportunityType => ({
  opportunityDetails: {
    title: templateValues?.opportunityDetails?.title || '',
    urlDestination: '',
    description: templateValues?.opportunityDetails?.description ||'',
    photos: []
  },
  referralReward: {
    rewardActionType: businessProfileData?.getCurrentBusiness?.integrationType ===
    IntegrationType.Shopify
      ? RewardActionType.CompleteAPurchase
      : templateValues?.referralReward?.rewardActionType || null,
    commissionAmount: templateValues?.referralReward?.commissionAmount || 0,
    commissionType: templateValues?.referralReward?.commissionType || null,
    isMinimumPurchaseAmountRequired: templateValues?.referralReward?.isMinimumPurchaseAmountRequired || false,
    minimumPurchaseAmount: templateValues?.referralReward?.minimumPurchaseAmount || 0,
    isBudgetLimited: false,
    budget: 0
  },
  referrerEducation: {
    noteForDrummer: '',
    educationMaterials: []
  },
  opportunityDates: {
    startDate: moment.utc().format(),
    endDate: ''
  }
});
