import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface IMeta {
  title?: string;
  description?: string;
  image?: string;
}

const defaults = {
  title: 'Business App',
  description: 'Cool service',
  image: 'favicon.ico'
};

type MetaKey = 'title' | 'description' | 'image';

const getValue = (props: IMeta, key: MetaKey): string =>
  props[key] || defaults[key];

const getURL = (pathname: string): string => {
  const { BASE_URL } = process.env;

  return `${BASE_URL}${pathname}`;
};

const MetaData: FC<IMeta> = (props) => {
  const { pathname } = useLocation();

  return (
    <Helmet>
      <title>{getValue(props, 'title')}</title>
      <meta name="description" content={getValue(props, 'description')} />
      <meta property="og:title" content={getValue(props, 'title')} />
      <meta property="og:image" content={getValue(props, 'image')} />
      <meta
        property="og:description"
        content={getValue(props, 'description')}
      />
      <meta name="theme-color" content="#7145d8" />
      <meta property="og:url" content={getURL(pathname)} />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default MetaData;
