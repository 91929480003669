import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { InputBase } from 'uikit';
import { CommissionType } from '../../../../graphql';
import { Container, LabelBlock } from './styles';

interface Props {
  className?: string;
  onChange: (value: string) => void;
  name: string;
  // TODO: use more strict type than string
  type: string | null;
  value: number;
}

const ReferralRewardInput: FC<Props> = ({
  className,
  name,
  onChange,
  type: rewardType,
  value
}) => (
  // TODO: add thousand separator and restrict number of decimal symbols
  <Container className={className}>
    {rewardType !== CommissionType.Percentage && 
      <LabelBlock className="left">$</LabelBlock>
    }
    <NumberFormat
      className={rewardType !== CommissionType.Percentage ? "no-radius-left" : "no-radius-right"}
      allowLeadingZeros={false}
      allowNegative={false}
      allowEmptyFormatting={true}
      customInput={InputBase}
      name={name}
      onValueChange={({ value }) => onChange(value)}
      value={value === null || value === undefined ? '' : value }
    />
    {rewardType === CommissionType.Percentage && 
      <LabelBlock className="right">%</LabelBlock>
    }
  </Container>
);

export default ReferralRewardInput;
