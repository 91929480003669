import React from 'react';
import {
  VerifyButton,
  SubSectionContainer,
  SubSectionContainerAlt,
  Paragraph
} from './styled';
import {
  SectionTitle,
  Field,
  ButtonContainer,
  CheckmarkBox,
  ErrorBox,
  Em
} from '../styled';
import { Codebox, InputBase, FieldFooter } from 'uikit';
import { useFormikContext } from 'formik';
import Checkmark from 'assets/svg/Checkmark';
import { StandardIntegration } from '../validationSchemaStandard';

type Props = {
  onVerificationPress: (
    url: string,
    onVerifySuccess: () => void,
    onVerifyFail: () => void
  ) => void;
};

const CustomerJourney: React.FC<Props> = ({ onVerificationPress }) => {
  const { values, handleChange, setFieldValue, errors } = useFormikContext<
    StandardIntegration
  >();

  const onVerifySuccess = () => {
    setFieldValue('baseVerified', true);
    setFieldValue('baseVerificationFailed', false);
  };

  const onVerifyFail = () => {
    setFieldValue('baseVerified', false);
    setFieldValue('baseVerificationFailed', true);
  };

  const clearVerification = () => {
    setFieldValue('baseVerified', false);
    setFieldValue('baseVerificationFailed', false);
  };

  const onFieldChange = ({ ...args }) => {
    handleChange(args);
    clearVerification();
  };

  const handleVerificationPress = () => {
    if (!values.baseCodeUrl || errors?.baseCodeUrl) {
      return;
    }
    onVerificationPress(values.baseCodeUrl, onVerifySuccess, onVerifyFail);
  };

  return (
    <Field>
      <SectionTitle>
        <span>Step 1:</span> Install code snippet to track customer journey
      </SectionTitle>
      <SubSectionContainer>
        <Paragraph>
          The tracking scripts include a base snippet and event codes for
          tracking purchases. The base code helps initialize Drum on your site
          and will let you know when a visitor from Drum comes to your website.
          The event codes will help you track the precise action that a Drum
          enabled visitor takes on your website. Tracking a purchase event will
          let you reward the Drummer only when an actual sale is completed.
        </Paragraph>
        <Paragraph>
          Copy and paste this piece of code within the <Em>header section</Em>{' '}
          of your website just before the <Em>{'</head>'}</Em> tag.
        </Paragraph>
        <Codebox title="Code Snippet" content={values.baseCodeSnippet || ''} />
      </SubSectionContainer>
      <SubSectionContainerAlt>
        <h5>Verify customer journey base code snippet Installation </h5>
        <Paragraph>
          Ensure that your website has been correctly setup to support Drum
          Online Conversions.
        </Paragraph>

        <InputBase
          name="baseCodeUrl"
          onChange={onFieldChange}
          value={values.baseCodeUrl || ''}
          placeholder="URL"
        />
        <FieldFooter error={errors?.baseCodeUrl} />

        <ButtonContainer>
          {values.baseVerified && (
            <CheckmarkBox>
              <Checkmark /> <span>Verified installation</span>
            </CheckmarkBox>
          )}
          {values.baseVerificationFailed && (
            <ErrorBox>
              <img src="/assets/error.png" alt="Unable to verify" />{' '}
              <span>Unable to verify</span>
            </ErrorBox>
          )}
          <VerifyButton type="button" onClick={handleVerificationPress}>
            Verify Installation
          </VerifyButton>
        </ButtonContainer>
      </SubSectionContainerAlt>
    </Field>
  );
};

export default CustomerJourney;
