import React, { FC, ReactNode } from 'react';
import Modal from 'react-modal';
import ScrollLock from 'react-scrolllock';
import {
  PreviewContainer,
  StyledCloseButton,
  StyledDrummerView,
  StyledTitle
} from './styles';
import { useGetBusinessRepresentationQuery } from '../../graphql';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { CloseRoundIcon } from 'assets/svg';
import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  values: CreateOpportunityType;
}

const CreateOpportunitySuccessModal: FC<Props> = ({
  isOpen,
  onClose,
  values,
  title,
  children
}) => {
  const { data: businessInfo } = useGetBusinessRepresentationQuery();

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick
        overlayClassName="create-opportunity-modal"
        className="create-opportunity-modal__content"
      >
        <StyledCloseButton onClick={onClose} type="button">
          <CloseRoundIcon />
        </StyledCloseButton>
        <StyledTitle>{title}</StyledTitle>
        <PreviewContainer>
          <StyledDrummerView
            values={values}
            companyInfo={businessInfo}
            showDrummerMaterials={false}
          />
        </PreviewContainer>
        {children}
      </Modal>
      {isOpen && <ScrollLock />}
    </>
  );
};

export default CreateOpportunitySuccessModal;
