import React, { FC, useState } from 'react';
import { Loader, FieldFooter } from 'uikit';
import { Formik, FieldArray } from 'formik';
import { CloseRoundIcon } from 'assets/svg';
import {
  Container,
  Title,
  FormContent,
  ButtonsContainer,
  SubmitButton,
  SelectedTagsContainer,
  SelectedTagsContainerInner,
  SelectedTag,
  TagName,
  RemoveTagButton
} from './styles';
import { SchemaType, ITag } from './validationSchema';
import AddTagsConfirmation from './AddTagsConfirmation';
import TagsAutosuggest from 'components/TagsAutosuggest';

interface Props {
  className?: string;
  onSubmit: (values: SchemaType, setFieldError: any) => void;
  tags?: ITag[];
  recordsAmount?: number;
  selectedRecord?: any;
}

const AddTags: FC<Props> = ({
  className,
  onSubmit,
  tags,
  recordsAmount,
  selectedRecord
}) => {
  const initialValues: SchemaType = {
    tags: tags || []
  };

  const [loading] = useState<boolean>(false);
  const [deletedTag, setDeletedTag] = useState<ITag | null>(null);
  const [formTags, setFormTags] = useState<ITag[]>([]);
  const [deletedTagRemoveCallback, setDeletedTagRemoveCallback] = useState<
    ((index: number) => void) | null
  >(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState<
    boolean
  >(false);

  const handleSubmit = (values: SchemaType, { setFieldError }: any) => {
    onSubmit(values, setFieldError);
  };

  const onRemove = (
    removeFunc: (index: number) => void,
    tag: ITag,
    tags: ITag[]
  ) => {
    setDeletedTag(tag);
    setDeletedTagRemoveCallback(() => removeFunc);
    setFormTags(tags);
    setDisplayDeleteConfirmation(true);
  };

  const onDeletionConfirm = () => {
    const index = (formTags || []).findIndex(
      (item) => item.name === deletedTag?.name
    );
    if (index !== -1) {
      deletedTagRemoveCallback && deletedTagRemoveCallback(index);
    }
    clearDeletionData();
    setDisplayDeleteConfirmation(false);
  };
  const onDeletionReject = () => {
    clearDeletionData();
    setDisplayDeleteConfirmation(false);
  };
  const clearDeletionData = () => {
    setDeletedTag(null);
    setDeletedTagRemoveCallback(null);
  };

  return (
    <Container className={className}>
      <Formik
        enableReinitialize
        //validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          validateForm,
          setSubmitting
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormContent>
                {loading && <Loader />}

                {!displayDeleteConfirmation && (
                  <>
                    <Title>
                      Tag Selected Customers:{' '}
                      {selectedRecord?.firstName || selectedRecord?.lastName ? (
                        <span>
                          {selectedRecord?.firstName || ''} {selectedRecord?.lastName || ''}
                        </span>
                      ) : (
                        `${recordsAmount || ''}`
                      )}
                    </Title>
                    <FieldArray
                      name="tags"
                      render={({ remove, push }) => (
                        <>
                          <TagsAutosuggest
                            selectedTags={values.tags}
                            //onSelect={(value) => onTagSelect(value, push)}
                            onSelect={push}
                          />
                          <FieldFooter error={errors.tags as string} />
                          <SelectedTagsContainer>
                            <SelectedTagsContainerInner>
                              {values.tags.map((tag, index) => (
                                <SelectedTag key={tag.name}>
                                  <TagName>{tag.name}</TagName>
                                  <RemoveTagButton
                                    onClick={(e) => {
                                      onRemove(remove, tag, values.tags);
                                    }}
                                    type="button"
                                  >
                                    <CloseRoundIcon />
                                  </RemoveTagButton>
                                </SelectedTag>
                              ))}
                            </SelectedTagsContainerInner>
                          </SelectedTagsContainer>
                        </>
                      )}
                    />

                    <ButtonsContainer>
                      <SubmitButton type="submit" disabled={loading}>
                        Submit Tags
                      </SubmitButton>
                    </ButtonsContainer>
                  </>
                )}

                {displayDeleteConfirmation && (
                  <AddTagsConfirmation
                    onConfirm={onDeletionConfirm}
                    onReject={onDeletionReject}
                    tagName={deletedTag?.name || ''}
                    recordsAmount={recordsAmount}
                  />
                )}
              </FormContent>
            </form>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default AddTags;
