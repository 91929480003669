import styled from 'styled-components';
import { LinkTextMixin, PrimaryButton } from 'uikit';
import { PreviewContainer, PreviewParagraph } from '../../styles';

export const Container = styled(PreviewContainer)`
	* {
		font-family: inherit !important;
	}
`;
export const Header = styled.pre`
	line-height: 21px;
	margin-bottom: 33px;
	font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1.33;
	white-space: break-spaces;
	overflow: hidden;
`;
export const Paragraph = styled(PreviewParagraph)`
	
`;
export const BodyParagraph = styled.pre`
	font-size: ${({ theme }) => theme.size.body2};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1.57;
	white-space: break-spaces;
	overflow: hidden;
`;
export const ParagraphSmall = styled(PreviewParagraph)`
	font-size: ${({ theme }) => theme.size.caption};
	text-align: center;
`;

export const ViewRewardLink = styled.a`
	${LinkTextMixin};
	font-size: ${({ theme }) => theme.size.body2};
	color: ${({ theme }) => theme.colors.accentSecondaryColor};
	margin: 20px 0 40px 0;
	display: inline-block;
`;
export const InputGroup = styled.div`
	margin: 20px 0 25px;
`;
export const MainButton = styled(PrimaryButton)`
	margin-bottom: 12px;
	width: 100%;
`;
export const NoticeText = styled(Paragraph)`
	font-size: ${({ theme }) => theme.size.caption};
	font-style: italic;
	margin-bottom: 40px;
`;
export const NoticeLink = styled.a`
	${LinkTextMixin};
	font-size: ${({ theme }) => theme.size.caption};
	color: ${({ theme }) => theme.colors.accentSecondaryColor};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
export const LaunchAppLink = styled.a`
	${LinkTextMixin};
	display: block;
	text-align: center;
	font-size: ${({ theme }) => theme.size.body1};
	color: ${({ theme }) => theme.colors.accentSecondaryColor};
	font-weight: ${({ theme }) => theme.fontWeight.extraBold};
`;