import React, { FC } from 'react';
import {
  Container,
  HeaderContent,
  ShareButton,
  ShareIcon,
  Avatar,
  AvatarContainer,
  UserAction,
  UserDetails,
  UserInfo,
  UserName
} from './styles';

interface Props {
  canSharePage: boolean;
  drummerName: string;
  subtitle: string;
  className?: string;
}

const OpportunityCardInvite: FC<Props> = ({
  canSharePage,
  drummerName,
  subtitle,
  className
}) => (
  <Container className={className}>
    <HeaderContent canSharePage={canSharePage}>
      <UserInfo>
        <AvatarContainer>
          <Avatar src="/assets/drummer-avatar.png" />
        </AvatarContainer>
        <UserDetails canSharePage={canSharePage}>
          <UserName>{drummerName}</UserName>
          <UserAction>{subtitle}</UserAction>
        </UserDetails>
      </UserInfo>
      {canSharePage && (
        <ShareButton>
          Open
          <ShareIcon />
        </ShareButton>
      )}
    </HeaderContent>
  </Container>
);

export default OpportunityCardInvite;
