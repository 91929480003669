import React, { FC, useState } from 'react';
import { Storage } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import get from 'lodash.get';
import imageTypes from 'constants/imageTypes';
import { getCurrentCredentials } from 'services/auth';
import { FullScreenLoader } from 'uikit';
import {
  ALLOWED_IMAGE_MIME_TYPES,
  ALLOWED_IMAGE_EXTENSIONS
} from 'constants/allowedImageTypes';
import IImageUpload from 'types/ImageUpload';
import {
  Container,
  DropzoneContainer,
  Caption,
  Preview,
  ReplaceButton,
  UploadButton,
  StyledFieldFooter
} from './styles';

interface Props {
  allowMultipleUpload: boolean;
  getImageUploadUrl: (identityId: string, imageExtension: string) => string;
  className?: string;
  onSuccess?: (imageData: IImageUpload) => void;
  previewURL?: string | null;
}

interface S3File {
  key: string;
}

const ALLOWED_IMAGE_MIME_TYPES_LIST = Object.values(ALLOWED_IMAGE_MIME_TYPES);

const ImageUpload: FC<Props> = ({
  allowMultipleUpload,
  className,
  onSuccess,
  previewURL,
  getImageUploadUrl
}) => {
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  // TODO: add cropping of image
  const onDropAccepted = async (files: File[]) => {
    setLoading(true);

    const credentials = await getCurrentCredentials();
    const identityId = credentials?.identityId;

    if (!identityId) {
      setError("You don't have permissions to upload image.");
      setLoading(false);
      return;
    }

    const [file] = files;
    const imageExtension = get(ALLOWED_IMAGE_EXTENSIONS, file.type);
    const uploadURL = getImageUploadUrl(identityId, imageExtension);

    try {
      const result = (await Storage.put(uploadURL, file, {
        contentType: file.type,
        level: 'public',
        customPrefix: { public: '' }
      })) as S3File;

      if (result.key && onSuccess) {
        onSuccess({ name: imageTypes.ORIGINAL, path: result.key });
      }
    } catch (error) {
      setError('Upload failed.');
    }

    setLoading(false);
  };

  const onDropRejected = () =>
    setError(
      "The file you're trying to upload is not supported. Please upload a .JPEG, .GIF or .PNG file and lower than 10MB."
    );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ALLOWED_IMAGE_MIME_TYPES_LIST,
    multiple: allowMultipleUpload,
    maxSize: 10485760, // 10mb
    noClick: true,
    onDropAccepted,
    onDropRejected
  });

  return (
    <>
      <Container className={className}>
        <DropzoneContainer hasPreview={!!previewURL} {...getRootProps()}>
          <input {...getInputProps()} />
          <Caption>Drag &amp; Drop</Caption>
          <UploadButton onClick={open} type="button">
            Add
          </UploadButton>
        </DropzoneContainer>
        {!!previewURL && (
          <>
            <Preview src={previewURL} />
            <ReplaceButton onClick={open} type="button">
              Add New
            </ReplaceButton>
          </>
        )}
        <StyledFieldFooter error={error} />
      </Container>
      {loading && <FullScreenLoader />}
    </>
  );
};

export default ImageUpload;
