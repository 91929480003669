import styled from 'styled-components';
import { breakpoint } from '../../helpers';

export const Container = styled.header`
  ${breakpoint('md')`
    padding-top: 18px;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${breakpoint('xs', 'md')`
    padding: 18px 35px 0;
  `}
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

export const ImageCaption = styled.span`
  display: block;
  text-align: center;
  margin: 0 auto 35px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #acacac;
`;

export const Invitation = styled.div`
  ${breakpoint('xs', 'md')`
    margin-bottom: 32px;
  `}

  ${breakpoint('md')`
    margin-bottom: 50px;
  `}
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black};
`;

export const OfferDetails = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.56;
  color: ${({ theme }) => theme.colors.black};
`;

export const OfferLink = styled.a`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.56;
  color: ${({ theme }) => theme.colors.accentMainColor};

  :hover {
    color: #0086a8;
  }

  :active {
    color: #005d75;
  }
`;

export const CenteredText = styled.div`
  text-align: center;
`;

export const GetAppButton = styled.a`
  display: inline-block;
  border-radius: 21px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentMainColor};

  :hover {
    background-color: #0086a8;
  }

  :active {
    background-color: #005d75;
  }

  ${breakpoint('md')`
    padding: 12px 70px;
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
    padding: 12px 0;
  `}
`;
