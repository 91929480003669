import React, { FC } from 'react';
import { H4, TruncatedText } from 'uikit';
import ShareOpportunityCard from 'components/ShareOpportunityCard';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import ReferralRewardTypes from 'constants/referralRewardTypes';
import { getDrummerEarning, getFormattedEarning } from 'helpers';
import {
  GetBusinessRepresentationQuery,
  RewardActionType
} from '../../../../graphql';
import {
  EarnButton,
  LinksList,
  ReferrerEducationLink,
  RewardDetailsTitle,
  Section,
  SectionDetails
} from './styles';

interface Props {
  className?: string;
  values: CreateOpportunityType;
  companyInfo: GetBusinessRepresentationQuery | undefined;
  showDrummerMaterials: boolean;
  showShareButtons?: boolean;
}

const DrummerView: FC<Props> = ({
  className,
  values,
  companyInfo,
  showDrummerMaterials,
  showShareButtons = true
}) => {
  const {
    commissionAmount,
    commissionType,
    minimumPurchaseAmount,
    isMinimumPurchaseAmountRequired,
    rewardActionType
  } = values.referralReward;

  const drummerEarning = commissionAmount === null ? commissionAmount : getDrummerEarning(commissionAmount);
  const formattedEarning = getFormattedEarning(commissionType, drummerEarning);

  const getRewardDetails = () => {
    const rewardAction =
      rewardActionType === RewardActionType.CompleteAPurchase
        ? 'completes a purchase'
        : 'navigates to page';

    const minimumPurchaseLimit =
      rewardActionType === RewardActionType.CompleteAPurchase &&
      isMinimumPurchaseAmountRequired &&
      minimumPurchaseAmount
        ? `over ${ReferralRewardTypes.FIXED}${minimumPurchaseAmount}`
        : '';

    return `Earn ${formattedEarning} when a customer your refer ${rewardAction} ${minimumPurchaseLimit}`;
  };

  return (
    <ShareOpportunityCard
      className={className}
      values={values}
      companyInfo={companyInfo}
      showEarnings
      showShareButtons={showShareButtons}
    >
      <Section>
        <RewardDetailsTitle>Referral Reward</RewardDetailsTitle>
        <SectionDetails>{getRewardDetails()}</SectionDetails>
      </Section>

      <EarnButton type="button">
        Share to earn {formattedEarning}
      </EarnButton>

      {showDrummerMaterials && (
        <>
          {(values.referrerEducation.noteForDrummer || !!values.referrerEducation.educationMaterials.length) && (
            <H4>Referral Notes</H4>
          )}
          {values.referrerEducation.noteForDrummer && (
            <Section>
              <SectionDetails>
                <TruncatedText>
                  {values.referrerEducation.noteForDrummer}
                </TruncatedText>
              </SectionDetails>
            </Section>
          )}

          {!!values.referrerEducation.educationMaterials.length && (
            <Section>
              <LinksList>
                {values.referrerEducation.educationMaterials.map(
                  ({ title }, i) => (
                    <ReferrerEducationLink key={i}>
                      {title}
                    </ReferrerEducationLink>
                  )
                )}
              </LinksList>
            </Section>
          )}
        </>
      )}
    </ShareOpportunityCard>
  );
};

export default DrummerView;
