import styled from 'styled-components';
import { ITheme } from 'types';
import { ChevronBottom } from 'assets/svg';

export const ButtonContainer = styled.div`
  position: relative;
`;

export const MenuButton = styled.button`
  width: 100%;
  padding: 11px 15px 11px 20px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  text-align: left;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.accentMainColor};
  border-radius: 21px;
  background-color: ${({ theme }) => theme.colors.white};

  :hover {
    border-color: ${({ theme }) => theme.colors.accentMainColor};
  }

  :focus {
    border-color: ${({ theme }) => theme.colors.inputColor};
  }
`;

interface ChevronProps extends ITheme {
  isMenuOpen: boolean;
}

export const StyledChevron = styled(ChevronBottom)<ChevronProps>`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%)
    rotate(${({ isMenuOpen }) => (isMenuOpen ? '180deg' : '0deg')});
  transition: all 0.3s;
`;
