import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { ITheme } from 'types';
import { Link } from 'react-router-dom';
import { SecondaryButton, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  padding: 24px 19px 24px 24px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`;
export const ContainerInner = styled.div`
  position: relative;
`;

export const PlatformInfoContainer = styled.div`
  ${breakpoint('sm')`
    display: flex;
    justify-content: space-between;
  `}
`;

export const SelectedPlatform = styled.div`
  margin-bottom: 20px;
`;

export const PlatformName = styled.p`
  margin-bottom: 10px;
`;

export const PlatformStatus = styled.div`
  ${breakpoint('sm')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  `}
`;

export const ReturnLinkContainer = styled.div`
  margin-top: 23px;
  height: 20px;
`;
export const ReturnLink = styled(Link)`
  display: block;
  ${LinkTextMixin};
  font-weight: 400;
`;
export const InstructionsButton = styled(SecondaryButton)`
  margin-bottom: 30px;
  padding: 7px 20px;
  font-size: 14px;
`;
export const StatusContainer = styled.div`
  height: 35px;
`;
export const StatusLabel = styled.p<ITheme>`
  display: flex;
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  margin-bottom: 4px;
`;
export const Status = styled.p<ITheme>`
  text-transform: uppercase;
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.bold};
  font-size: ${({ theme }: ITheme) => theme.size.body1};
`;
export const StatusSuccess = styled(Status)<ITheme>`
  color: ${({ theme }) => theme.colors.success};
  display: flex;
`;
export const StatusError = styled(Status)<ITheme>`
  color: ${({ theme }) => theme.colors.danger};
  display: flex;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
  margin-top: 3px;
`;
