import styled from 'styled-components';
import { PrimaryButton } from 'uikit';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	* {
		font-family: inherit !important;
	}
`;
export const Content = styled.div`
	padding-top: 10px;
`;
export const SocialButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const SocialButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	&.active,
	&:hover {
		& > div:first-of-type {
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); 
		}
	}
`;
export const SocialIconBlock = styled.div`
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
`;
export const SocialLabel = styled.div`
	margin-top: 8px;
	color: ${({ theme }) => theme.colors.watermark};
	font-size: ${({ theme }) => theme.size.caption};
`;
export const OfferPreview = styled.div`
	display: flex;
	margin-top: 35px;
`;
export const OfferImageContainer = styled.div`
	position: relative;
	width: 140px;
	min-height: 90px;
	overflow: hidden;
`;
export const OfferImage = styled.img`
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: -100%;
	right: -100%;
	height: 100%;
`;
export const OfferTextContainer = styled.div`
	flex: 1;
	margin: 0 3px 0 14px;
	font-size: ${({ theme }) => theme.size.caption};
	line-height: 14px;
`;

export const MainButton = styled(PrimaryButton)`
	width: 100%;
`;
