import React, { FC } from 'react';
import { Container, TextContainer, Title, Paragraph, EmphasizedParagraph, ButtonsContainer, ApplyButton, CancelButton } from './styles';

export interface Props {
  onApply?: () => void;
  onCancel?: () => void;
}

const ConfirmDeleteModal: FC<Props> = ({onApply, onCancel}) => {
  const handleApply = () => {
    onApply && onApply();
  }
  const handleCancel = () => {
    onCancel && onCancel();
  }

  return (
    <Container>
      <TextContainer>
        <Title>Delete confirmation</Title>
        <Paragraph>Are you sure you want to delete your email campaign? The following emails will be deleted and your customers will not longer receive these emails.</Paragraph>
        <EmphasizedParagraph>
          <Paragraph>Invitation Email</Paragraph>
          <Paragraph>Reminder Email</Paragraph>
          <Paragraph>New Opportunity Email</Paragraph>
        </EmphasizedParagraph>
      </TextContainer>
      <ButtonsContainer>
        <ApplyButton onClick={handleApply}>Yes, delete emails</ApplyButton>
        <CancelButton onClick={handleCancel}>No, do not delete</CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

export default ConfirmDeleteModal;
