import { useCallback } from 'react';
import {
  useGetCustomersQuery,
  useCreateCustomersMutation,
  useEditCustomersMutation,
  useDeleteCustomersMutation,
  useSetCustomerTagsMutation,
  FindCustomersInput,
  BulkUpdateCustomersInput,
  BulkUpdateCustomersInputSetOp
} from '../../../graphql';
import { SchemaType as ManualAddSchemaType } from 'components/ManualCustomerAddForm/validationSchema';

export const useImperativeGetCustomersQuery = (
  fetchPolicy?:
    | 'cache-first'
    | 'cache-and-network'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
) => {
  const { refetch } = useGetCustomersQuery({
    fetchPolicy: fetchPolicy || 'no-cache',
    skip: true
  });
  const imperativeQuery = useCallback(
    (input: FindCustomersInput) => {
      return refetch({ input });
    },
    [refetch]
  );
  return imperativeQuery;
};

export const useImperativeCreateCustomersMutation = () => {
  const [createCustomers] = useCreateCustomersMutation();
  const imperativeQuery = useCallback(
    (input: ManualAddSchemaType[]) => {
      return createCustomers({
        variables: {
          input: input
        }
      });
    },
    [createCustomers]
  );
  return imperativeQuery;
};

export const useImperativeEditCustomersMutation = () => {
  const [editCustomers] = useEditCustomersMutation();
  const imperativeQuery = useCallback(
    (ids: string[], set: BulkUpdateCustomersInputSetOp) => {
      return editCustomers({
        variables: {
          input: {
            ids,
            set
          }
        }
      });
    },
    [editCustomers]
  );
  return imperativeQuery;
};

export const useImperativeDeleteCustomersMutation = () => {
  const [deleteCustomers] = useDeleteCustomersMutation();
  const imperativeQuery = useCallback(
    (ids: string[]) => {
      return deleteCustomers({
        variables: {
          input: {
            deleteCustomers: ids
          }
        }
      });
    },
    [deleteCustomers]
  );
  return imperativeQuery;
};

export const useImperativeSetTagsMutation = () => {
  const [setCustomersTags] = useSetCustomerTagsMutation();
  const imperativeQuery = useCallback(
    (input: BulkUpdateCustomersInput) => {
      return setCustomersTags({
        variables: {
          input: input
        }
      });
    },
    [setCustomersTags]
  );
  return imperativeQuery;
};
