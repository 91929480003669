
export const COLUMNS = [
  {
    name:'name',
    caption:'Name',
    description: 'The first and last name of the customer you imported into Drum.',
  },
  {
    name:'dateAdded',
    caption:'Date Added',
    description: 'The date the customer was added into the Drum.',
  },
  {
    name:'lastContacted',
    caption:'Last Contacted',
    description: 'The date and time the customer was last sent an email from Drum.',
  },
  {
    name:'tags',
    caption:'Tags',
    description: 'Tags allow you to easily organize your customers.',
  },
  {
    name:'totalReward',
    caption:'Total Reward',
    description: 'This is the total amount of rewards earned by the referrer promoting your business.',
  },
  {
    name:'referrals',
    caption:'Referrals',
    description: 'The number of times a referrer has shared one or more of your campaigns that resulted in a completed sale or transaction.',
  },
  {
    name:'totalSales',
    caption:'Total Sales',
    description: 'The total amount of sales this referrer has generated sending customers to your business.',
  },
  {
    name:'source',
    caption:'Source',
    description: 'The source of where the customer came from when imported into Drum. Options include: import .csv, sync with Shopify, and manual adding of a customer.',
  },
  {
    name:'status',
    caption:'Status'
  },
  {
    name:'options',
    caption:'',
  }
]
