import styled from 'styled-components';
import { PrimaryButton, InputBase } from 'uikit';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	* {
		font-family: inherit !important;
	}
`;
export const Content = styled.div`
	padding-top: 10px;
`;
export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const StyledInput = styled(InputBase)`
	flex: 1;
	height: 40px;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 4px;
	padding: 0 13px;
	margin-top: 10px;
`;

export const MainButton = styled(PrimaryButton)`
	width: 100%;
`;
