import styled from 'styled-components';
import { InputBase } from 'uikit';

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(InputBase)`
  padding-right: 70px;
`;

export const ToggleInputTypeButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 5px;
  border: none;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.accentMainColor};
  transform: translateY(-50%);
  background-color: transparent;
`;
