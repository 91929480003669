import styled from 'styled-components';
import { H2, H4, ButtonTextMixin, LinkTextMixin } from 'uikit';
import { FormHeader } from './components';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 30px 60px 60px;
  `}

  ${breakpoint('md', 'lg')`
    padding: 25px 20px 50px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px 25px 90px;
    margin: 0 auto;
    border-radius: 8px;
  `};
`;

export const StyledFormHeader = styled(FormHeader)`
  ${breakpoint('lg')`
    margin-bottom: 15px;
  `}

  ${breakpoint('md', 'lg')`
    margin-bottom: 35px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 25px;
  `}
`;

export const StyledHeading = styled(H2)`
  margin-bottom: 10px;
`;

export const OpportunityStatusContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    width: 100%;
    margin-bottom: 10px;
  `}
`;

export const StatusCaption = styled.span`
  display: inline-block;
  margin-right: 4px;
  ${ButtonTextMixin};
`;

export const OptionalFeaturesTitle = styled(H4)`
  ${LinkTextMixin};
  padding: 25px 0;
  cursor: pointer;
`;

export const PreviewButton = styled.button`
  position: fixed;
  bottom: 80px;
  right: 11px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  ${breakpoint('xl')`
    display: none;
  `}
`;
export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const AccordionContainer = styled.div`
  margin: 34px 0;
`;

export const ViewExamplesLink = styled.a`
  ${LinkTextMixin};
`;
export const OptionalFeaturesContainer = styled.div`

`;

