import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent as ReactChangeEvent
} from 'react';
import { Modal, FullScreenLoader } from 'uikit';
import { uniq, difference, filter, map } from 'lodash';
import { useDebouncedValue } from 'helpers';
import {
  sendImportedCustomersTrackingEvent,
  sendImportCustomersClickTrackingEvent
} from 'helpers/segment';
import { useHistory } from 'react-router';
import Tooltip from 'rc-tooltip';
import routes from 'constants/routes';
import CustomersTable from './components/CustomersTable';
import {
  FILTERS,
  ACTION_OPTIONS,
  IMPORT_OPTIONS,
  DEFAULT_FILTERS
} from './constants';
import {
  IActionOption,
  IErrorModalData,
  IUploadSuccessfulUploadModalData,
  Filters,
  RequestOptions
} from './types';
import {
  useImperativeGetCustomersQuery,
  useImperativeCreateCustomersMutation,
  useImperativeEditCustomersMutation,
  useImperativeDeleteCustomersMutation,
  useImperativeSetTagsMutation
} from './requests';
import CustomSelect from '../CustomSelect';
import Pagination from '../Pagination';
import ImportFile from 'components/ImportFile';
import ImportSuccess from 'components/ImportSuccess';
import MenuList, { MenuListPlain } from '../MenuList';
import ImportButton from './components/ImportButton';
import ManualCustomerAddForm from 'components/ManualCustomerAddForm';
import AddTags from 'components/AddTags';
import { SchemaType as ManualAddSchemaType } from 'components/ManualCustomerAddForm/validationSchema';
import { SchemaType as TagSchema } from 'components/AddTags/validationSchema';
import { ITag } from 'components/AddTags/validationSchema';
import {
  IUploadError,
  errorCodes
} from 'components/CsvUpload/helpers/fileUploadErrors';
import {
  UploadCustomersStats,
  useGetMutualCustomersTagsQuery,
  Customer,
  CustomerStatus,
  FindCustomersInput,
  GetCustomersQuery
} from '../../graphql';
import { CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT } from 'constants/defaultPaginationLimit';
import {
  Container,
  ButtonsContainer,
  ContactsBlock,
  Buttons,
  ActionsContainer,
  StyledSearchInput,
  FiltersContainer,
  SelectedBlock,
  FiltersList,
  TableContainer,
  PaginationContainer,
  StyledCheckFilter,
  ModalText
} from './styles';
import './styles.scss';

const CustomersList: FC = () => {
  const history = useHistory();

  const getCustomers = useImperativeGetCustomersQuery();
  const createCustomers = useImperativeCreateCustomersMutation();
  const editCustomers = useImperativeEditCustomersMutation();
  const deleteCustomers = useImperativeDeleteCustomersMutation();
  const setCustomersTags = useImperativeSetTagsMutation();

  const [customersFilters, setCustomersFilters] = useState<RequestOptions>({
    filters: DEFAULT_FILTERS,
    page: 1,
    search: ''
  });
  const debouncedSearch = useDebouncedValue(customersFilters.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [customersData, setCustomersData] = useState<GetCustomersQuery | null>(
    null
  );
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);
  const [initialTags, setInitialTags] = useState<Array<ITag>>([]);
  const [editedRecord, setEditedRecord] = useState<Customer | null>(null);
  const [modifiedItems, setModifiedItems] = useState<Array<string>>([]); //This variable stores ids of currently modified items (Needed because we can call menu either for a group or for a single item)

  /* IMPORT MODALS */

  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isManualAddModalOpen, setIsManualAddModalOpen] = useState<boolean>(
    false
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  /* ACTION MODALS */

  const [isTagModalOpen, setIsTagModalOpen] = useState<boolean>(false);
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState<boolean>(
    false
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [errorModalData, setErrorModalData] = useState<IErrorModalData>({
    opened: false,
    title: '',
    mainButtonTitle: '',
    mainButtonAction: () => {}
  });
  const [successfulUploadModalData, setSuccessfulUploadModalData] = useState<
    IUploadSuccessfulUploadModalData
  >({
    opened: false,
    uploadedCount: 0,
    notUploadedDuplicatesCount: 0,
    notUploadedMissingFieldsCount: 0,
    mainButtonAction: () => {},
    fileName: '',
    data: null
  });

  const {
    loading: mutualTagsLoading,
    refetch: refetchMutualTagsData
  } = useGetMutualCustomersTagsQuery({
    fetchPolicy: 'no-cache',
    skip: true
  });

  const getFilters = (filtersArg?: Filters) => {
    let _filters = filtersArg || customersFilters.filters;
    return map(
      filter(FILTERS, (item) => !!_filters[item.id as CustomerStatus]),
      (item) => item.id
    );
  };

  const selectAllTableItems = (items: string[]) => {
    setCheckedItems(uniq([...checkedItems, ...items]));
  };
  const delectAllTableItems = (items: string[]) => {
    setCheckedItems(difference(checkedItems, items));
  };
  const selectTableItem = (id: string) => {
    setCheckedItems(uniq([...checkedItems, id]));
  };
  const deselectTableItem = (id: string) => {
    setCheckedItems(difference(checkedItems, [id]));
  };

  const handleSearchValueChange = (e: ReactChangeEvent<HTMLInputElement>) => {
    setCustomersFilters({
      ...customersFilters,
      search: e.target.value,
      page: 1
    });
  };
  const onCreateEmailClick = () => {
    history.push({
      pathname: routes.CREATE_EMAIL_CAMPAIGN
    });
  };
  const selectImportOption = (option: IActionOption) => {
    if (option.disabled) return;

    switch (option.value) {
      case 'uploadCsv': {
        setIsUploadModalOpen(true);
        sendImportCustomersClickTrackingEvent('CSV');
        break;
      }
      case 'syncWithShopify': {
        break;
      }
      case 'manual': {
        setIsManualAddModalOpen(true);
        sendImportCustomersClickTrackingEvent('Manual');
        break;
      }
    }
  };

  const selectOption = (option: IActionOption) => {
    if (option.disabled) return;

    switch (option.value) {
      case 'tag': {
        if (!checkedItems?.length) return;
        onTagRecords(checkedItems);
        return;
      }
      case 'unsubscribe': {
        if (!checkedItems?.length) return;
        onUnsubscribeRecords(checkedItems);

        return;
      }
      case 'delete': {
        if (!checkedItems?.length) return;
        onDeleteRecords(checkedItems);
        return;
      }
      default:
        return;
    }
  };

  const closeErrorModal = () => {
    setErrorModalData({ ...errorModalData, opened: false });
  };

  const onManualAddFormSubmit = async (
    values: ManualAddSchemaType,
    setFieldError: any
  ) => {
    setLoading(true);
    const executeRequest = async () => {
      return createCustomers([values]);
    };
    const result = (await executeRequest()) as any;
    const data = result?.data?.bulkUpdateCustomers?.addCustomersResult;

    if (data?.items?.length) {
      resetCustomersFilters();
      setIsManualAddModalOpen(false);
      sendImportedCustomersTrackingEvent('Manual');
    } else if (data?.notAddedReasons?.length) {
      const _errors = data.notAddedReasons.map((item: any) => item.code);
      switch (_errors[0]) {
        case 'duplicateEmail':
          setFieldError('email', 'A customer with this email already exists');
          break;
      }
      sendImportedCustomersTrackingEvent('Manual', false);
    }
    setLoading(false);
  };

  const onEditFormSubmit = async (
    values: ManualAddSchemaType,
    setFieldError: any
  ) => {
    if (!editedRecord?.id) return;
    setLoading(true);

    const executeRequest = async () => {
      return editCustomers([editedRecord.id], values);
    };
    const result = (await executeRequest()) as any;
    const data = result?.data?.bulkUpdateCustomers?.setResult;

    if (data?.customersModified) {
      onEditModalClose();
      fetchCustomers();
    } else if (data?.customersNotModifiedReason?.code) {
      const code = data?.customersNotModifiedReason?.code;
      switch (code) {
        case 'duplicateEmail':
        case 'cannotSetSameEmail':
          setFieldError('email', 'A customer with this email already exists');
          break;
      }
    }
    setLoading(false);
  };

  const onTagFormSubmit = async (values: TagSchema, setFieldError: any) => {
    setLoading(true);

    const { tags } = values;

    const deletedItems = initialTags.filter((item) => {
      return item.id && !tags.find(({ name }) => name === item.name);
    });
    const addedExisting = tags.filter((item) => {
      return item.id && !initialTags.find(({ name, id }) => name === item.name);
    });
    const addedNew = tags.filter((item) => {
      return (
        !item.id && !initialTags.find(({ name, id }) => name === item.name)
      );
    });

    const executeRequest = async () => {
      return setCustomersTags({
        ids: modifiedItems,
        addTags: (addedExisting || []).map((item) => item.id),
        addCreateTags: (addedNew || []).map((item) => ({ name: item.name })),
        removeTags: (deletedItems || []).map((item) => item.id)
      });
    };

    await executeRequest();

    onTagModalClose();
    fetchCustomers();

    setLoading(false);
  };

  const onUnsubscribeSubmit = async () => {
    if (!modifiedItems?.length) return;

    setLoading(true);
    const executeRequest = async () => {
      return editCustomers(modifiedItems, {
        status: CustomerStatus.Unsubscribed
      });
    };
    await executeRequest();

    onUnsubscribeModalClose();
    fetchCustomers();
    setLoading(false);
  };

  const onDeleteSubmit = async () => {
    if (!modifiedItems?.length) return;
    setLoading(true);
    const executeRequest = async () => {
      return deleteCustomers(modifiedItems);
    };
    const result = await executeRequest();
    if (result) {
      const lastPage = Math.max(
        Math.ceil(
          (Number(customersData?.findCustomers?.total || 0) -
            Number(modifiedItems?.length || 0)) /
            CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT
        ),
        1
      );
      setCustomersFilters({
        ...customersFilters,
        page: Math.min(customersFilters.page, lastPage)
      });
      setCheckedItems([]);
      onDeleteModalClose();
    } else {
      onDeleteModalClose();
      setErrorModalData({
        opened: true,
        title: 'Could not delete selected items',
        mainButtonTitle: 'Okay',
        mainButtonAction: () =>
          setErrorModalData({ ...errorModalData, opened: false })
      });
    }
    setLoading(false);
  };

  const onEditRecord = (id: string) => {
    if (!id) return;

    const item = (customersData?.findCustomers?.items || []).find(
      (item) => item?.id === id
    );
    setEditedRecord(item as Customer);
    setIsEditModalOpen(true);
  };

  const onTagRecords = async (ids: string[]) => {
    if (!ids?.length) return;
    setModifiedItems(ids);

    if (ids.length === 1) {
      const item = (customersData?.findCustomers?.items || []).find(
        (item) => item?.id === ids[0]
      );
      setEditedRecord(item as Customer);
      setInitialTags((item?.tags as ITag[]) || []);
      setIsTagModalOpen(true);
    } else {
      const mutualTags = await refetchMutualTagsData({
        input: {
          get: {
            ids: checkedItems || []
          }
        }
      });
      const tags = mutualTags?.data?.findCustomers?.get?.commonTags;
      if (!tags) return;
      setInitialTags((tags || []) as ITag[]);
      setIsTagModalOpen(true);
    }
  };

  const onUnsubscribeRecords = (ids: string[]) => {
    if (!ids?.length) return;
    setModifiedItems(ids);
    if (ids.length === 1) {
      const item = (customersData?.findCustomers?.items || []).find(
        (item) => item?.id === ids[0]
      );
      setEditedRecord(item as Customer);
    }

    setIsUnsubscribeModalOpen(true);
  };
  const onDeleteRecords = (ids: string[]) => {
    if (!ids?.length) return;
    setModifiedItems(ids);
    if (ids.length === 1) {
      const item = (customersData?.findCustomers?.items || []).find(
        (item) => item?.id === ids[0]
      );
      setEditedRecord(item as Customer);
    }

    setIsDeleteModalOpen(true);
  };

  const onCsvError = (error: IUploadError) => {
    setIsUploadModalOpen(false);
    setErrorModalData({
      opened: true,
      title: error.message,
      mainButtonTitle:
        error.code === errorCodes.FORMATTING_FAILED ||
        error.code === errorCodes.INCORRECT_FILE_TYPE
          ? 'Download CSV template'
          : 'Please try again',
      mainButtonAction: () => {},
      component: error.component
    });
    sendImportedCustomersTrackingEvent('CSV', false);
  };

  const onCsvSuccess = (data?: UploadCustomersStats, fileName?: string) => {
    if (!data) return;
    setIsUploadModalOpen(false);
    setSuccessfulUploadModalData({
      ...successfulUploadModalData,
      opened: true,
      uploadedCount: data.uploaded?.total || 0,
      notUploadedDuplicatesCount:
        (data.notUploaded?.customersWithSameEmail?.total || 0) +
        (data.notUploaded?.sameEmailsInCsv?.total || 0),
      notUploadedMissingFieldsCount:
        data.notUploaded?.requiredFieldsAreEmpty?.total || 0,
      fileName: fileName || '',
      data: data
    });
    sendImportedCustomersTrackingEvent('CSV');
  };

  const closeSuccessfulUploadModal = () => {
    setSuccessfulUploadModalData({
      ...successfulUploadModalData,
      opened: false
    });
    //Getting a new list of customers after uploading a csv
    resetCustomersFilters();
  };

  const toggleFilters = (id: CustomerStatus, value: boolean) => {
    setCustomersFilters({
      ...customersFilters,
      page: 1,
      filters: { ...customersFilters.filters, [id]: value },
      search: ''
    });
  };

  const fetchCustomers = async () => {
    setLoading(true);
    const input: FindCustomersInput = {
      page: {
        offset:
          (customersFilters.page - 1) * CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT,
        limit: CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT
      },
      filter: {
        status: {
          in: getFilters() as CustomerStatus[]
        }
      },
      search: customersFilters.search || ''
    };

    const data = await getCustomers(input);
    setCustomersData(data.data as GetCustomersQuery);
    setLoading(false);
  };

  const resetCustomersFilters = () => {
    setCheckedItems([]);
    setEditedRecord(null);
    setCustomersFilters({
      filters: DEFAULT_FILTERS,
      page: 1,
      search: ''
    });
  };

  /* Modals' onClose callbacks */
  const onEditModalClose = () => {
    setEditedRecord(null);
    setIsEditModalOpen(false);
  };

  const onManualAddModalClose = () => {
    setIsManualAddModalOpen(false);
  };

  const onTagModalClose = () => {
    setModifiedItems([]);
    setEditedRecord(null);
    setIsTagModalOpen(false);
  };

  const onUnsubscribeModalClose = () => {
    setModifiedItems([]);
    setEditedRecord(null);
    setIsUnsubscribeModalOpen(false);
  };
  const onDeleteModalClose = () => {
    setModifiedItems([]);
    setEditedRecord(null);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (debouncedSearch !== customersFilters.search) return;
    fetchCustomers();
    // eslint-disable-next-line
  }, [customersFilters, debouncedSearch]);

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <ButtonsContainer>
        <ContactsBlock>
          <span>Contacts</span>{' '}
          <span>{customersData?.findCustomers?.total || 0}</span>
        </ContactsBlock>
        <Buttons>
          <CustomSelect<IActionOption>
            options={IMPORT_OPTIONS}
            components={{
              MenuList: MenuListPlain,
              DropdownIndicator: ImportButton
            }}
            onChange={(option) => selectImportOption(option as IActionOption)}
            value={null}
            isDisabled={false}
            className="import-select"
          />
        </Buttons>
      </ButtonsContainer>
      <ActionsContainer>
        <StyledSearchInput
          // @ts-ignore
          name="search-customers"
          value={customersFilters.search}
          onChange={handleSearchValueChange}
          placeholder="Search by first name, last name, email"
        />
        <CustomSelect<IActionOption>
          options={ACTION_OPTIONS}
          components={{ MenuList }}
          onChange={(option) => selectOption(option as IActionOption)}
          value={null}
          isDisabled={false}
        />
      </ActionsContainer>
      <FiltersContainer>
        <SelectedBlock>
          <span>Selected</span> <span>{checkedItems?.length || 0}</span>
        </SelectedBlock>
        <FiltersList>
          {FILTERS.map((item) => (
            <Tooltip
              placement="top"
              trigger={['hover']}
              mouseEnterDelay={2}
              overlayClassName="customers-filter-tooltip"
              overlay={
                <div>
                  <h3>{item.label || ''}</h3>
                  <p>{item.description || ''}</p>
                </div>
              }
            >
              <div>
                <StyledCheckFilter
                  onCheck={() => toggleFilters(item.id as CustomerStatus, true)}
                  onUncheck={() =>
                    toggleFilters(item.id as CustomerStatus, false)
                  }
                  checked={customersFilters.filters[item.id as CustomerStatus]}
                  label={item.label}
                />
              </div>
            </Tooltip>
          ))}
        </FiltersList>
      </FiltersContainer>
      {/* Table */}
      <TableContainer>
        <CustomersTable
          data={customersData?.findCustomers?.items as Customer[]}
          onImportClick={() => {
            setIsUploadModalOpen(true);
            sendImportCustomersClickTrackingEvent('CSV');
          }}
          checkedItems={checkedItems}
          onSelectItem={selectTableItem}
          onDeselectItem={deselectTableItem}
          onSelectAll={selectAllTableItems}
          onDeselectAll={delectAllTableItems}
          onEdit={onEditRecord}
          onTag={(id) => onTagRecords([id])}
          onUnsubscribe={(id) => onUnsubscribeRecords([id])}
          onDelete={(id) => onDeleteRecords([id])}
        />
      </TableContainer>
      <PaginationContainer>
        <Pagination
          forcePage={customersFilters.page - 1}
          onPageChange={(page) =>
            setCustomersFilters({ ...customersFilters, page: page })
          }
          pageCount={Math.ceil(
            Number(
              customersData?.findCustomers?.total ||
                CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT
            ) / CUSTOMERS_LIST_DEFAULT_PAGINATION_LIMIT
          )}
        />
      </PaginationContainer>
      {(mutualTagsLoading || loading) && <FullScreenLoader />}
      <Modal
        type="warning"
        title={errorModalData.title}
        isOpen={errorModalData.opened}
        onClose={closeErrorModal}
        childrenBeforeButtons={false}
        mainButton={{
          text: errorModalData.mainButtonTitle,
          onClick: errorModalData.mainButtonAction
        }}
      >
        {errorModalData.component ? errorModalData.component({}) : <></>}
      </Modal>

      {/* Manual add modal*/}
      <Modal
        isOpen={isManualAddModalOpen}
        onClose={onManualAddModalClose}
        className="customers-manual-add-modal"
      >
        <ManualCustomerAddForm onSubmit={onManualAddFormSubmit} />
      </Modal>

      {/* Edit modal*/}
      <Modal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        className="customers-edit-modal"
      >
        <ManualCustomerAddForm
          onSubmit={onEditFormSubmit}
          values={{
            firstName: editedRecord?.firstName || '',
            lastName: editedRecord?.lastName || '',
            phone: editedRecord?.phone || '',
            email: editedRecord?.email || ''
          }}
          buttonText="Save Customer"
          title="Edit Customer"
        />
      </Modal>

      {/* Upload modal*/}
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => {
          setIsUploadModalOpen(false);
        }}
        className="customers-csv-upload-modal"
      >
        <ImportFile
          onDropError={onCsvError}
          onSubmitSuccess={onCsvSuccess}
          onSubmitError={onCsvError}
        />
      </Modal>

      {/* Tag modal*/}
      <Modal
        isOpen={isTagModalOpen}
        onClose={onTagModalClose}
        className="customers-tags-modal"
      >
        <AddTags
          onSubmit={onTagFormSubmit}
          tags={initialTags}
          recordsAmount={modifiedItems?.length}
          selectedRecord={editedRecord}
        />
      </Modal>

      {/* Unsubscribe modal*/}
      <Modal
        isOpen={isUnsubscribeModalOpen}
        onClose={onUnsubscribeModalClose}
        childrenBeforeButtons={true}
        mainButton={{
          text: 'Yes, please unsubscribe',
          onClick: onUnsubscribeSubmit
        }}
        secondaryButton={{
          text: 'No thanks, Cancel',
          onClick: onUnsubscribeModalClose
        }}
        className="customers-unsubscribe-modal"
      >
        <ModalText>Are you sure you wish to unsubscribe</ModalText>
        <ModalText className="emphasized">
          {modifiedItems.length === 1 && editedRecord
            ? `${editedRecord?.firstName || ''} ${editedRecord?.lastName || ''}`
            : `${modifiedItems?.length || ''} customers`}
        </ModalText>
        <ModalText>From receiving any more email communications?</ModalText>
      </Modal>

      {/* Delete Modal*/}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        childrenBeforeButtons={true}
        mainButton={{
          text: 'Yes, please delete',
          onClick: onDeleteSubmit
        }}
        secondaryButton={{
          text: 'No thanks, Cancel',
          onClick: onDeleteModalClose
        }}
        className="customers-delete-modal"
      >
        <ModalText>Are you sure you wish to delete</ModalText>
        <ModalText className="emphasized">
          {modifiedItems.length === 1 && editedRecord
            ? `${editedRecord?.firstName || ''} ${editedRecord?.lastName || ''}`
            : `${modifiedItems?.length || ''} customers`}
        </ModalText>
        <ModalText>From your list of customers?</ModalText>
      </Modal>

      {/* Upload success*/}
      <Modal
        isOpen={successfulUploadModalData.opened}
        onClose={closeSuccessfulUploadModal}
        childrenBeforeButtons={true}
        mainButton={
          successfulUploadModalData.uploadedCount
            ? {
                text: 'Create Invite Email',
                onClick: onCreateEmailClick
              }
            : {
                text: 'Okay',
                onClick: closeSuccessfulUploadModal
              }
        }
        secondaryButton={
          successfulUploadModalData.uploadedCount
            ? {
                text: 'No thanks, maybe later',
                onClick: closeSuccessfulUploadModal
              }
            : undefined
        }
        className="customers-upload-success-modal"
      >
        <ImportSuccess
          uploadedCount={successfulUploadModalData.uploadedCount}
          notUploadedDuplicatesCount={
            successfulUploadModalData.notUploadedDuplicatesCount
          }
          notUploadedMissingFieldsCount={
            successfulUploadModalData.notUploadedMissingFieldsCount
          }
          fileName={successfulUploadModalData.fileName}
        />
      </Modal>
    </Container>
  );
};

export default CustomersList;
