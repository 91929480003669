import React, { FC } from 'react';
import { Props } from '../types';
import { emailType } from '../../../types';
import '../styles.scss';

const InvitationD: FC<Props> = ({ config }) => {
  return (
    <div className="email-campaign-template-container" style={{ fontFamily: config.emailStyle.font }}>
      <div
        className="container-outer invitation"
        style={{ background: config.emailStyle.backgroundColor }}
      >
        <div className="container">
          <div className="content-header alt">
            <div className="content-header-inner content-header-left">
              <div className="header-image-container">
                {config.businessAvatar ? (
                  <img
                    className="header-image"
                    src={config.businessAvatar}
                    alt="main-cover"
                  />
                ) : (
                  <div className="header-image-substitute">Logo</div>
                )}
              </div>
              <div className="header-title">{config.businessName}</div>
            </div>
            <div className="content-header-inner content-header-right">
              <div className="header-buttons">
                <a
                  href={config.earnRewardsLink || ''}
                  className="btn header-button bg-white outlined btn-rounded"
                >
                  Earn Rewards
                </a>
              </div>
            </div>
          </div>
          <div className={'content' + (config.showShadow ? ' show-shadow': '')}>
            <div className="content-main bg-white">
              <div className="content-text">
                {config.emails[config.emailType as emailType].emailBody}
              </div>
              <div className="content-list-container">
                <h2 className="content-list-header">{config.listCaption}</h2>
                <div className="content-list">
                  {config.listItems.map((item) => (
                    <div className="list-item" key={item.id}>
                      <div className="li-image-container">
                        {item.cover ? (
                          <img
                            className="li-image"
                            src={item.cover}
                            alt="li"
                          />
                        ) : (
                          <div className="li-image-substitute">Photo</div>
                        )}
                      </div>
                      <div className="li-content">
                        <div className="li-content-left">
                          <div className="li-content-title-container">
                            <div className="li-image-small-container">
                              {config.businessAvatar ? (
                                <img
                                  className="li-image-small"
                                  src={config.businessAvatar}
                                  alt="li-small"
                                />
                              ) : (
                                <div className="li-image-small-substitute">
                                  Logo
                                </div>
                              )}
                            </div>
                            <p className="li-content-title">
                              {config.businessName}
                            </p>
                          </div>
                          <p className="li-content-text">{item.title}</p>
                        </div>
                        <div className="li-content-right">
                          <div className="li-content-amount-content">
                            <p className="lcac-text lcac-title">Earn</p>
                            <p className="lcac-text lcac-amount">
                              {item.amountType !== 'PERCENTAGE' && '$'}
                              {item.amount}
                              {item.amountType === 'PERCENTAGE' && '%'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="content-buttons">
                <a
                  href={config.buttonLink || ''}
                  className="btn content-button btn-rounded"
                  style={{
                    background: config.emailStyle.buttonStyle.buttonColor,
                    color: config.emailStyle.buttonStyle.textColor,
                    borderRadius: config.emailStyle.buttonStyle.cornerRadius
                  }}
                >
                  {config.buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="footer"
          style={{
            background: config.emailStyle.footerStyle.backgroundColor,
            color: config.emailStyle.footerStyle.textColor
          }}
        >
          <p className="footer-paragraph">Unsubscribe</p>
          <p className="footer-paragraph fp-small">
            Referral platform powered by
          </p>
          <p className="footer-paragraph">
            © Drum Technologies Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvitationD;
