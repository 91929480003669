import { StorageHelper } from '@aws-amplify/core';

const oauth = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  scope: ['email'],
  redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
  redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  responseType: 'code'
};

const AWS_CONFIG = {
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

const Auth = {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  storage: new StorageHelper().getStorage(),
  identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
  identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  oauth
};

export const config = {
  ...AWS_CONFIG,
  Auth,
  Storage: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_S3_REGION,
    level: process.env.REACT_APP_AWS_S3_LEVEL
  }
};
