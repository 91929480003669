import React, { ReactNode, FC } from 'react';
import { CheckboxInput, Checkmark, Container, Text } from './styles';

interface Props {
  text?: string | ReactNode;
  name?: string;
  className?: string;
  checked?: boolean; 
  onCheck?: () => void;
  onUncheck?: () => void;
}

const Checkbox: FC<Props> = ({ className, name, text, checked = false, onCheck, onUncheck }) => {
  const handleCheck = () => {
    if(!checked) {
      onCheck && onCheck();
    } else {
      onUncheck && onUncheck();
    }
  }
  return (
    <Container className={className} >
      <CheckboxInput type="checkbox" checked={checked}/>
      <Checkmark onClick={handleCheck}/>
      {text && <Text onClick={handleCheck}>{text}</Text>}
    </Container>
  );
};

export default Checkbox;
