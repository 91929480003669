import React, { FC, ReactNode } from 'react';
import { Container, LeftBlock, RightBlock, Title, Link } from './styles';
import routes from 'constants/routes';

type Props = {
  title: string,
  link?: {
    text: string,
    to: string
  },
  showLink?: boolean,
  children: ReactNode;
};

const PageHeader: FC<Props> = ({title, children, link, showLink = true}) => {
  

  return (
    <Container>
      <LeftBlock>
        <Title>{title}</Title>
        {showLink && <Link to={link?.to || `${routes.DASHBOARD}`}>&lt; {link?.text || 'Back to Dashboard'}</Link>}
      </LeftBlock>
      <RightBlock>
        {children}
      </RightBlock>
    </Container>
  );

}
  
export default PageHeader;
