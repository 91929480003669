import React, { FC } from 'react';
import { Container, Title, Paragraph, Number, SubNumber } from './styles';

interface Props {
  className?: string;
  uploadedCount: number;
  notUploadedDuplicatesCount: number;
  notUploadedMissingFieldsCount: number;
  fileName: string;
}

const ImportFile: FC<Props> = ({
  className,
  uploadedCount,
  notUploadedDuplicatesCount,
  notUploadedMissingFieldsCount,
  fileName
}) => (
  <Container className={className}>
    <Title>{fileName}</Title>

    {!!notUploadedDuplicatesCount && (
      <>
        <Number className="warning">{notUploadedDuplicatesCount}</Number>
        <SubNumber>Duplicate contacts not imported</SubNumber>
      </>
    )}
    {!!notUploadedMissingFieldsCount && (
      <>
        <Number className="warning">{notUploadedMissingFieldsCount}</Number>
        <SubNumber>Contacts with missing first or last name not imported</SubNumber>
      </>
    )}

    <Number>{uploadedCount}</Number>
    <SubNumber className="success">Contacts Imported</SubNumber>

    {!!uploadedCount && <Paragraph>
      Would you like to send them an invite email to join your referral program?
    </Paragraph>}
  </Container>
);

export default ImportFile;
