import React, { FC } from 'react';
import { Props as PreviewProps } from '../../../../types';
import {
  Container,
  Content,
  TitleContainer,
  StyledInput,
  MainButton,
} from './styles';
import {
  BodyParagraph,
} from '../../styles';

export interface Props extends PreviewProps {
  onButtonClick?: () => void,
}

const CopyLink: FC<Props> = ({ config, onButtonClick }) => {

  return (
    <Container style={{ fontFamily: config.pageStyle.fontName }}>
      <Content>
        <TitleContainer>
          <BodyParagraph>
          Copy and share your link wherever
          </BodyParagraph>
        </TitleContainer>
        <StyledInput
          value={config.url}
        />
      </Content>
      <MainButton 
        style={{
          backgroundColor: config.pageStyle.buttonColor,
          color: config.pageStyle.textColor,
          borderRadius: config.pageStyle.cornersRadius || 0,
        }}
        onClick={onButtonClick}
      >
        Copy Link
      </MainButton>
    </Container>
  );
};

export default CopyLink;
