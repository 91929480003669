import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import ActivityFilter from '../ActivityFilter';
import OpportunitySelect from '../OpportunitySelect';

export const Container = styled.div`
  ${breakpoint('md')`
    display: flex;
    align-items: flex-end;
  `}
`;

export const StyledOpportunitySelect = styled(OpportunitySelect)`
  ${breakpoint('md')`
    flex: 1;
    max-width: 585px;
    min-width: 0;
    margin-right: 20px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 18px;
  `}
`;

export const StyledActivityFilter = styled(ActivityFilter)`
  ${breakpoint('md')`
    flex: 1;
    max-width: 300px;
    min-width: 0;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 18px;
  `}
`;
