import styled from 'styled-components';
import { ITheme } from '../../types';

export const Container = styled.div`
`;

export const Button = styled.button<ITheme>`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  padding: 13px 22px;
  border: 0;
  border-radius: 5px;
  background-color: ${({ theme }: ITheme) => theme.colors.snippetCollapseBg};
  color: ${({ theme }: ITheme) => theme.colors.black};
  font-size: ${({ theme }: ITheme) => theme.size.body2};

  &::before {
    position: relative;
    top: -3px;
    display: block;
    content: '';
    width: 9.8px;
    height: 9.8px;
    border-right: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
    border-bottom: 2px solid ${({ theme }: ITheme) => theme.colors.snippetCollapseArrowColor};
    transform: rotate(45deg);
    margin-right: 19px;
  }

  &[aria-expanded=true]::before {
    top: 3px;
    transform: rotate(-135deg);
  }
`;

export const Content = styled.section<ITheme>`
  margin-top: 5px;
`;
