import React from 'react';
import SetupProfileLayout from 'layouts/GeneralLayout';
import WebIntegrationForm from './WebIntegration';
import FAQ from '../../../FAQ';

const WebIntegration: React.FC = () => {

  return (
    <SetupProfileLayout sidebar={<FAQ preset="tracking"/>}>
      <WebIntegrationForm />
    </SetupProfileLayout>
  );
}

export default WebIntegration;
