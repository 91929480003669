import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import WidgetTitle from '../WidgetTitle';

export const Container = styled.div`
  margin-bottom: 35px;
  box-shadow: none;
  box-sizing: border-box;

  ${breakpoint('xs', 'lg')`
    width: 100%;
    padding: 20px 25px 30px;
  `}

  ${breakpoint('lg')`
    flex: 1;
    padding: 20px 20px 35px 25px;
  `}
`;

export const StyledTitle = styled(WidgetTitle)`
  margin-bottom: 25px;
`;