import styled, { CSSProperties } from 'styled-components';
import { Body1, H3, LinkTextMixin } from '../Typography';
import { CloseModalButton, PrimaryButton } from '../Buttons';

export const Container = styled.div`
  position: relative;
  padding: 60px 15px 25px;
  text-align: center;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 40px auto;
`;

export const StatusIconContainer = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled(H3)`
  margin-bottom: 40px;
`;

export const Message = styled(Body1)`
  margin-bottom: 25px;
`;

export const MainActionButton = styled(PrimaryButton)`
  display: block;
  width: 220px;
  margin: 0 auto 15px;
`;

export const SecondaryActionButton = styled.button`
  padding: 15px;
  background-color: transparent;
  border: none;
  ${LinkTextMixin};
  padding: 7px 0;
  width: 220px;
`;

export const overlayStyle: CSSProperties = {
  position: 'fixed',
  zIndex: 10000,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
};

export const contentStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  width: '340px',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.5)',
  borderRadius: '16px',
  maxHeight: '90%'
};
