import React, { FC, ReactNode } from 'react';
import { Container, Content, LogoContainer } from './styles';
import { Logo } from 'uikit';
interface Props {
  children: ReactNode;
}

const RestorePasswordPageLayout: FC<Props> = ({ children }) => (
  <Container>
    <LogoContainer>
      <Logo />
    </LogoContainer>  
    
    <Content>{children}</Content>
  </Container>
);

export default RestorePasswordPageLayout;
