import * as yup from 'yup';

const standartIntegrationSchema = yup.object({
  baseCodeUrl: yup
    .string()
    .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{1,5}(:[0-9]{1,5})?(\/.*)?$/, 'Invalid URL format.')
    .nullable()
    .notRequired(),
  baseVerified: yup.boolean().notRequired(),
  baseVerificationFailed: yup.boolean().notRequired(),
  baseCodeSnippet: yup.string().notRequired(),
  completionEventUrl: yup
    .string()
    .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{1,5}(:[0-9]{1,5})?(\/.*)?$/, 'Invalid URL format.')
    .nullable()
    .notRequired(),
  completionVerified: yup.boolean().notRequired(),
  completionVerificationFailed: yup.boolean().notRequired(),
  completionCodeSnippet: yup.string().notRequired(),

  isSendPurchaseAmount: yup.boolean().notRequired(),
  platformName: yup.string().notRequired().nullable()
});

export type StandardIntegration = yup.InferType<
  typeof standartIntegrationSchema
>;

export default standartIntegrationSchema;
