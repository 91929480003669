import { useCallback } from 'react';
import {
  useGetUploadJobQuery
} from '../../../graphql';

export const useImperativeGetUploadJob = (
  fetchPolicy?:
    | 'cache-first'
    | 'cache-and-network'
    | 'cache-only'
    | 'no-cache'
    | 'standby',
) => {
  const { refetch } = useGetUploadJobQuery({
    fetchPolicy: fetchPolicy || 'no-cache',
    skip: true
  });
  const imperativeQuery = useCallback((id: string) => {
    return refetch({ id });
  }, [refetch]);
  return imperativeQuery;
};