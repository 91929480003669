import React, { FC } from 'react';
import IconProps from './IconProps';

const ProfileIcon: FC<IconProps> = ({ className }) => (
  <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <g
        stroke="#505050"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        clip-path="url(#clip0)"
      >
        <path d="M16.74 16.74c2.43 0 4.36-1.78 4.36-4.36V10.2c0-2.58-1.93-4.36-4.36-4.36-2.43 0-4.36 1.78-4.36 4.36v2.18c0 2.58 1.92 4.36 4.36 4.36zM25.46 27.64V24c0-3.26-5.79-5.09-8.72-5.09-2.93 0-8.72 1.84-8.72 5.09v3.63"></path>
        <path d="M16.74 32.73c8.831 0 15.99-7.159 15.99-15.99S25.571.75 16.74.75.75 7.909.75 16.74s7.159 15.99 15.99 15.99z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H33.47V33.47H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default ProfileIcon;
