import styled from 'styled-components';
import ShareOpportunityCard from 'components/ShareOpportunityCard';
import { InputTextMixin, PrimaryButton } from 'uikit';

export const Container = styled.div`
  padding: 20px 15px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const StyledOpportunityCard = styled(ShareOpportunityCard)`
  margin-bottom: 40px;
`;

export const GoToSiteButton = styled(PrimaryButton)`
  width: 100%;
  border-radius: 8px;
`;

export const StyledLogo = styled.img`
  display: block;
  margin-bottom: 10px;
`;

export const Copyright = styled.small`
  ${InputTextMixin};
  color: #495870;
  opacity: 0.3;
`;
