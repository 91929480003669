import styled from 'styled-components';
import { Body2, Label, PrimaryButton } from 'uikit';
import { breakpoint } from 'helpers';
import ReferrerEducationLinkFields from '../ReferrerEducationLinkFields';

export const SectionHeading = styled(Label)`
  margin-bottom: 10px;
`;

export const SectionDescription = styled(Body2)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const InputGroup = styled.div`
  margin-bottom: 40px;
`;

export const EducationMaterialEntry = styled.div`
  :not(:last-child) {
    margin-bottom: 20px;
  }

  ${breakpoint('md')`
    display: flex;
    align-items: flex-end;
  `}
`;

export const EducationLinkFields = styled(ReferrerEducationLinkFields)`
  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
  `}

  ${breakpoint('md')`
    flex: 1;
  `};
`;

export const EducationMaterialInputGroup = styled.div`
  margin-right: 20px;
`;

export const EducationMaterialButton = styled(PrimaryButton)`
  margin-bottom: 28px;

  ${breakpoint('md')`
    width: 100px;
  `}

  ${breakpoint('xs', 'md')`
    width: 160px;
  `}
`;
