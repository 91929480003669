import React, { FC, useState } from 'react';
import useCollapse from 'react-collapsed';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { Overlay } from 'uikit/Loader/styles';
import OutsideClickHandler from 'react-outside-click-handler';
import { Plus, Minus } from 'assets/svg';
import { IMenuOption } from '../../types';
import CogIcon from 'assets/svg/Cog';
import CogIconPaused from 'assets/svg/CogPaused';
import TagIcon from 'assets/svg/Tag';
import { Emphasized, Body2 } from 'uikit';
import { CampaignStatus } from '../../../../../../graphql';
import capitalize from 'lodash.capitalize';
import DATE_FORMAT from 'constants/dateFormat';

import {
  Container,
  TopContainer,
  Row,
  TypeBlock,
  SubjectLineBlock,
  IconBlock,
  Block,
  BlockTitle,
  BlockValue,
  MenuContainer
} from './styles';
import {
  SentTypeIcon,
  MenuButton,
  StyledMenuButton,
  TagContainer,
  Dot
} from '../../styles';

interface Props {
  data?: any;
  expanded?: boolean;
  menuOpened?: boolean;
  menuOptions: IMenuOption[];
  onMenuOpen: (id: string) => void;
  onMenuClose: (id: string) => void;
}

const EmailCollapseItem: FC<Props> = ({
  data,
  expanded = false,
  menuOpened = false,
  menuOptions,
  onMenuOpen,
  onMenuClose
}) => {
  const [isExpanded, setExpanded] = useState(!!expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleMenuToggle = () => {
    menuOpened ? onMenuClose(data.id) : onMenuOpen(data.id);
  };
  return (
    <Container>
      <TopContainer
        expanded={isExpanded}
        {...getToggleProps({
          onClick: () => {
            setExpanded(!isExpanded);
          }
        })}
      >
        <TypeBlock>
          {data.sendToFilter?.tags ? (
            <>
              <Emphasized>Customers</Emphasized>
              <Emphasized>Tagged with</Emphasized>
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={
                  <div>
                    <Body2><Emphasized>Tags</Emphasized></Body2>
                    <br />
                    <Body2>This list was sent only to contacts tagged with:</Body2>
                    <br />
                    {(data.sendToFilter.tags.in || []).slice(0,3).map((item:any) => (
                      <Body2><Emphasized>{item.name}</Emphasized></Body2>
                    ))}
                    {data.sendToFilter.tags.in.length > 3 && <Body2><Emphasized>+{data.sendToFilter.tags.in.length - 3}</Emphasized></Body2>}
                  </div>
                }
              >
                <TagContainer>
                  <TagIcon />
                  <span>
                    {data.sendToFilter?.tags?.in?.length || 0}
                  </span>
                </TagContainer>
              </Tooltip>
            </>
          ) : (
            <Emphasized>All Customers</Emphasized>
          )}
        </TypeBlock>
        <SubjectLineBlock>
          <SentTypeIcon>
            {data.status === CampaignStatus.Active && <CogIcon />}
            {data.status !== CampaignStatus.Active && <CogIconPaused />}
          </SentTypeIcon>
          {data.status === CampaignStatus.Active ? "Enabled" : capitalize(data.status || '')}
        </SubjectLineBlock>
        <IconBlock>{!isExpanded ? <Plus /> : <Minus />}</IconBlock>
      </TopContainer>
      <div {...getCollapseProps()}>
        {/* Put content here*/}
        <Row>
          <Block className="dateCreated">
            <BlockTitle>Date Created</BlockTitle>
            {data?.campaignStats?.lastTimeSent && (
              <>
                <BlockValue className="dateCreated">{moment(data.campaignStats.createdAt).format(
                  DATE_FORMAT
                )}</BlockValue>
                <BlockValue className="dateCreated">{moment(data.campaignStats.createdAt).format('LT')}</BlockValue>
              </>
            )}
          </Block>
          <Block className="lastSent">
            <BlockTitle>Last Sent</BlockTitle>
            {data?.campaignStats?.lastTimeSent && (
              <>
                <BlockValue className="lastSent">{moment(data.campaignStats.lastTimeSent).format(
                  DATE_FORMAT
                )}</BlockValue>
                <BlockValue className="lastSent">{moment(data.campaignStats.lastTimeSent).format('LT')}</BlockValue>
              </>
            )}
          </Block>
          <Block className="sent">
            <BlockTitle>Sent</BlockTitle>
            <BlockValue className="sent">{data?.campaignStats?.sent || 0}</BlockValue>
          </Block>
          <Block className="joined">
            <BlockTitle>Joined</BlockTitle>
            <BlockValue className="joined">{data?.campaignStats?.joined || 0}</BlockValue>
          </Block>
          <Block className="options">
            <MenuButton onClick={handleMenuToggle} type="button">
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <Dot key={index} />
                ))}
            </MenuButton>
            {menuOpened && (
              <>
                <Overlay />
                <MenuContainer>
                  <ul>
                    <OutsideClickHandler
                      onOutsideClick={() => onMenuClose(data.id)}
                    >
                      {menuOptions.map(({ name, onClick }) => (
                        <StyledMenuButton
                          key={name}
                          onClick={() => {onClick(data.id); onMenuClose(data.id)}}
                          type="button"
                        >
                          {name}
                        </StyledMenuButton>
                      ))}
                    </OutsideClickHandler>
                  </ul>
                </MenuContainer>
              </>
            )}
          </Block>
        </Row>
      </div>
    </Container>
  );
};

export default EmailCollapseItem;
