import styled from 'styled-components';
import { LinkTextMixin, Body2, H2, LabelTextMixin, PrimaryButton } from 'uikit';
import { ShopifyLogo } from 'assets/svg';

export const Title = styled(H2)`
  margin-bottom: 20px;
`;

export const ReturnButton = styled.button`
  margin-bottom: 30px;
  border: none;
  background-color: transparent;
  ${LinkTextMixin};
`;

export const Form = styled.form`
  margin-bottom: 20px;
  padding: 30px 30px 45px;
  /* TODO: use color from theme */
  background: rgba(149, 191, 71, 0.17);
  border-radius: 14px;
`;

export const StyledShopifyLogo = styled(ShopifyLogo)`
  display: block;
  max-width: 100%;
  height: 48px;
  margin-bottom: 55px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  ${LabelTextMixin};
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 35px;
`;

export const Disclaimer = styled(Body2)`
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;
