import styled from 'styled-components';
import { H2, LinkTextMixin, Link as UiLink } from 'uikit';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 30px;

  ${breakpoint('xs', 'lg')`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;
export const LeftBlock = styled.div`

`;
export const RightBlock = styled.div`
  ${breakpoint('xs', 'lg')`
    margin-top: 25px;
  `}
`;
export const Title = styled(H2)`
  margin-bottom: 10px;
`;
export const Link = styled(UiLink)`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};

  ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;
