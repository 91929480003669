import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ScrollLock from 'react-scrolllock';
import { ShareLinkStep, InvitationLandingPreview } from './components';
import {
  OfferStatus,
  useGenerateQrCodeMutation,
  useGetInvitationLandingDataLazyQuery,
  useListOpportunitiesQuery
} from '../../graphql';
import { CloseRoundIcon } from 'assets/svg';
import { CloseButton, LoaderContainer } from './styles';
import './styles.scss';
import { Loader } from 'uikit';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const steps = {
  OPEN_PREVIEW: 'openOpportunity',
  SHARE_LINK: 'shareLink'
};

const MAX_OPPORTUNITY_COUNT = 10;

const InviteDrummerModal: FC<Props> = ({ isOpen, onClose }) => {
  const [currentStep, setStep] = useState(steps.SHARE_LINK);

  // TODO: handle errors of all GraphQL operations

  const {
    data: opportunitiesList,
    loading: isOpportunityListLoading
  } = useListOpportunitiesQuery({
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: MAX_OPPORTUNITY_COUNT
        }
      }
    }
  });

  const [
    getInvitationLandingData,
    { data: businessData, loading: getCodeLoading }
  ] = useGetInvitationLandingDataLazyQuery();

  const [
    generateQRCode,
    { loading: generateCodeLoading }
  ] = useGenerateQrCodeMutation({
    onCompleted: () => getInvitationLandingData()
  });

  // TODO: handle situation when `code` is `null`
  // Can be reproduced on profiles that don't complete setup steps
  const code = businessData?.getCurrentBusiness?.qrCode;

  useEffect(() => {
    if (isOpen && !code) {
      generateQRCode();
    }
  }, [generateQRCode, isOpen, code]);

  const isLoading =
    getCodeLoading || generateCodeLoading || isOpportunityListLoading;

  const handleModalClose = () => {
    setStep(steps.SHARE_LINK);
    onClose();
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        className="invite-drummer-content"
        isOpen={isOpen}
        onRequestClose={handleModalClose}
        overlayClassName="invite-drummer-overlay"
        shouldCloseOnOverlayClick
      >
        <CloseButton onClick={handleModalClose} type="button">
          <CloseRoundIcon />
        </CloseButton>
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            {currentStep === steps.SHARE_LINK && (
              <ShareLinkStep
                isLoading={isLoading}
                code={code}
                onOpenPreview={() => setStep(steps.OPEN_PREVIEW)}
              />
            )}
            {currentStep === steps.OPEN_PREVIEW && (
              <InvitationLandingPreview
                code={code}
                businessData={businessData}
                opportunitiesList={opportunitiesList}
                onReturnToLink={() => setStep(steps.SHARE_LINK)}
              />
            )}
          </>
        )}
      </Modal>
      {isOpen && <ScrollLock />}
    </>
  );
};

export default InviteDrummerModal;
