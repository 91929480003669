import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(0, 105, 128, 0.1);
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #2A3039;

  img {
    height: 43px;
    border-radius: 50%;
  }
`;

export const RatingPosition = styled.div`
  position: absolute;
  box-sizing: border-box;
  right: -19px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border: solid 2px ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
  background-color: ${({ theme }) => theme.colors.danger};
`;
