import styled from 'styled-components';
import { breakpoint } from 'helpers';
import DashboardOpportunityMenu from 'components/DashboardOpportunityMenu';
import OpportunityPerformance from 'components/OpportunityPerformance';
import OpportunityFilters from 'components/OpportunityFilters/OpportunityFilters';
import InviteDrummerPanel from 'components/InviteDrummerPanel';
import ProfileSetup from 'components/ProfileSetup';
import DashboardOpportunityPreview from 'components/DashboardOpportunityPreview';
import TopDrummersTable from 'components/TopDrummersTable';
import TopRewardsTable from 'components/TopRewardsTable';
import { ITheme } from 'types';

export const StyledDrummerInvitationPanel = styled(InviteDrummerPanel)`
  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
  `}

  ${breakpoint('md')`
    margin-bottom: 35px;
  `}
`;

export const StyledProfileSetup = styled(ProfileSetup)`
  margin-bottom: 55px;
`;

interface TopSectionElementProps extends ITheme {
  hasSidebar: boolean;
}

export const TopSection = styled.div<TopSectionElementProps>`
  display: ${({ hasSidebar }) => (hasSidebar ? 'flex' : 'block')};
  flex-wrap: ${({ hasSidebar }) => (hasSidebar ? 'wrap' : 'initial')};
  margin-bottom: 10px;
`;
export const DrummersCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
export const MiddleSection = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${breakpoint('xs', 'xl')`
    flex-direction: column;
  `}
`;

export const StyledOpportunityFilters = styled(OpportunityFilters)`
  margin-bottom: 30px;

  ${breakpoint('xl')`
    flex: 1;
    min-width: 860px;
  `}

  ${breakpoint('xs', 'xl')`
    width: 100%;
  `}
`;

export const StyledOpportunityMenu = styled(DashboardOpportunityMenu)`
  margin-bottom: 30px;

  ${breakpoint('xs', 'xl')`
    width: 100%;
    order: 1;
  `}

  ${breakpoint('xl')`
    width: 180px;
    align-self: flex-end;
    margin-left: auto;
  `}
`;

export const StyledOpportunityPerformance = styled(OpportunityPerformance)<
  TopSectionElementProps
>`
  ${({ hasSidebar }) => hasSidebar && 'flex: 1;'};

  ${breakpoint('xs', 'xl')`
    flex: initial;
    margin-bottom: 30px;
  `}
`;

export const StyledDashboardOpportunityPreview = styled(
  DashboardOpportunityPreview
)`
  ${breakpoint('xl')`
    width: 350px;
    margin-left: 30px;
  `}

  ${breakpoint('xs', 'xl')`
    order: 2;
    width: 100%;
  `}
`;

export const BottomRow = styled.div`
  ${breakpoint('lg')`
    display: flex;
  `}
`;

export const StyledDrummersTable = styled(TopDrummersTable)`
  ${breakpoint('lg')`
    flex: 1;
    margin-right: 30px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 30px;
  `}
`;

export const StyledTopRewardTable = styled(TopRewardsTable)`
  ${breakpoint('lg')`
    flex: 1;
  `}
`;
