import React, { FC } from 'react';
import { Container, Title, Button, ArrowIcon } from './styled';
import { SortOrder } from 'components/ListOpportunities/constants';

// TODO implement render button logic
interface Props {
  title: string;
  className?: string;
  onClick?: () => void;
  sortOrder?: boolean | SortOrder;
}

const TableHeader: FC<Props> = ({ title, className, onClick, sortOrder }) => {
  return (
    <Container
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Title>{title}</Title>
      {!!sortOrder && (
        <Button>
          <ArrowIcon sortOrder={sortOrder} />
        </Button>
      )}
    </Container>
  );
};

export default TableHeader;
