import React, { FC, useEffect, useState } from 'react';
import branch from 'branch-sdk';
import formatOpportunitiesList from 'components/ListOpportunities/components/OpportunitiesListTable/utils';
import getBranchLink from './getBranchLink';
import { DRUMMER_APP_LINKS } from 'constants/landingPageLinks';
import {
  ListOpportunitiesQuery,
  GetInvitationLandingDataQuery
} from '../../graphql';
import {
  Container,
  Content,
  DownloadLabel,
  DownloadLink,
  DownloadSection,
  OpportunitiesContainer,
  OpportunityListTitle,
  PageBody,
  StoreLink,
  StoreLinkContainer,
  StoreLinkImage,
  StyledHeader,
  StyledOpportunityCard
} from './styles';

interface Props {
  businessData: GetInvitationLandingDataQuery | undefined;
  code: undefined | string | null;
  opportunitiesList: ListOpportunitiesQuery | undefined;
}

const { REACT_APP_DYNAMIC_LANDING_PAGE_DOMAIN } = process.env;

const InviteDrummerLanding: FC<Props> = ({
  businessData,
  code,
  opportunitiesList
}) => {
  const [appLink, setAppLink] = useState<string>();
  const formattedOpportunityList = formatOpportunitiesList(opportunitiesList);
  useEffect(() => {
    if(!businessData) return;
    getBranchLink({
      businessId: businessData?.getCurrentBusiness?.id || '',
      onLinkCallback: onLink
    });
  }, [businessData]);

  const onLink = (err: branch.BranchError, link: branch.BranchError) => {
    if (err) {
      //TODO: Hadle error here later
      return;
    }
    setAppLink(link || '');
  };

  const getOpportunityLandingUrl = (id: string, title: string) => {
    if (!code || !title) {
      return null;
    }

    const formattedTitle = title
      .toLowerCase()
      .replace(/[^a-z0-9-_~\\s]/g, '')
      .split(' ')
      .filter(Boolean)
      .join('-');

    const codeChunks = code.split('/');

    const formattedCode = codeChunks.length > 1 ? code[1] : code;

    return `${REACT_APP_DYNAMIC_LANDING_PAGE_DOMAIN}/business/invites/${formattedTitle}/${formattedCode}/${id}`;
  };

  return (
    <Container>
      <Content>
        <StyledHeader appLink={appLink} businessData={businessData} />
        <PageBody>
          {!!formattedOpportunityList?.length && (
            <>
              <OpportunityListTitle>
                Current Earning Opportunities
              </OpportunityListTitle>
              <OpportunitiesContainer>
                {formattedOpportunityList.map(
                  ({
                    businessName,
                    businessPhoto,
                    id,
                    title,
                    image,
                    formattedCommission
                  }) => (
                    <StyledOpportunityCard
                      key={id}
                      businessName={businessName}
                      businessPhoto={businessPhoto}
                      opportunityTitle={title}
                      coverImage={image}
                      earning={formattedCommission}
                      canShare={false}
                      opportunityLink={getOpportunityLandingUrl(id, title)}
                    />
                  )
                )}
              </OpportunitiesContainer>
            </>
          )}
          <DownloadSection>
            <DownloadLabel>
              Download the Drum app to earn money sharing this business.
            </DownloadLabel>
            <DownloadLink
              href={appLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              Get the App
            </DownloadLink>
            <StoreLinkContainer>
              <StoreLink
                href={`${DRUMMER_APP_LINKS.IOS}?businessId=${businessData?.getCurrentBusiness?.id}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <StoreLinkImage src="/assets/app-store-logo.png" alt="" />
              </StoreLink>
              <StoreLink
                href={`${DRUMMER_APP_LINKS.ANDROID}&businessId=${businessData?.getCurrentBusiness?.id}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <StoreLinkImage src="/assets/play-store-logo.png" alt="" />
              </StoreLink>
            </StoreLinkContainer>
          </DownloadSection>
        </PageBody>
      </Content>
    </Container>
  );
};
export default InviteDrummerLanding;
