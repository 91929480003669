import React, { FC } from 'react';
import { Emphasized, Body2 } from 'uikit';
import { TEXT_FORMAT_OPTIONS } from '../../../../constants';
import {
  Container,
} from './styles';

const WrongFormat: FC = () => (
  <Container>
    <Body2>Please use one of the personalization options:</Body2>
    <br />
    {TEXT_FORMAT_OPTIONS.map((option: string) => (
      <Body2><Emphasized>{`{{${option}}}`}</Emphasized></Body2>
    ))}
    <br />
    <br />
  </Container>
);

export default WrongFormat;
