import styled from 'styled-components';
import { Body1, PrimaryButton, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  min-height: 200px;
  text-align: center;
`;
export const TextContainer = styled.div`
  max-width: 333px;
  margin: 0 auto;
`;
export const Paragraph = styled(Body1)`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.size.subtitle};
`;
export const InputGroup = styled.div`
  max-width: 380px;
  min-height: 76px;
  margin: 0 auto;
`;
export const Content = styled.div`
  min-height: 150px;
  padding-top: 25px;
`;
export const LoaderContainer = styled.div`
  
  img {
    width: 34px;
    height: 34px;
  }
  p {
    margin-top: 15px;
  }
`;
export const SuccessContainer = styled.div`
  
`;
export const ErrorContainer = styled.div`
  
`;
export const IconTextContainer = styled.div`
  max-width: 333px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 11px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const ITCIcon = styled.div`
  margin-right: 11px;
`;
export const ITCText = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
`;
export const ErrorDescription = styled(Body1)`
  max-width: 333px;
  box-sizing: border-box;
  margin: 20px auto 30px auto;
  padding: 0 11px;
`;
export const SupportLink = styled.a`
  ${LinkTextMixin}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 232px;
`;



