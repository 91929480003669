import { useState, useEffect } from "react";

const DEFAULT_TIMEOUT = 400;

function useDebouncedValue(value: any) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, DEFAULT_TIMEOUT);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
}

export default useDebouncedValue;
