import styled from 'styled-components';
import { breakpoint } from '../../../../helpers';
import { LinkTextMixin, ButtonTextMixin, OutlinedButton } from 'uikit';

export const Container = styled.div`

`;
export const TableContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;
export const MobileCardsContainer = styled.div`
  ${breakpoint('lg')`
    display: none;
  `}
`;

export const Row = styled.div`
  display: flex;
  height: 70px;
  border-bottom: 1px solid #ccc;

  & > div {
    &.to {
      flex: 0.215;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    
    &.sent {
      flex: 0.12;
    }
    &.joined {
      flex: 0.12;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    &.dateCreated {
      flex: 0.17;
    }
    &.lastSent {
      flex: 0.17;
    }
    &.sentType {
      flex: 0.115;
    }
    &.options {
      flex: 0.09;
    }
  }
`;

export const HeaderRow = styled(Row)`
  height: 35px;
`;

export const DataRow = styled(Row)`
  
`;

export const Col = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.black};
  padding-right: 7px;
`;

export const HeaderCol = styled(Col)`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  align-items: center;

  &.dateCreated {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const HeaderRowMobile = styled.div`
  height: 35px;
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
`;
export const HeaderColMobile = styled.div`
  display: flex;
  height: 100%;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  align-items: center;

  &.to {
    flex: 0.56
  }
  &.status {
    flex: 0.44
  }
`;

export const DataCol = styled(Col)`
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;

  &.to {
    font-size: ${({ theme }) => theme.size.body1};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  &.source {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &.opens {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  &.clicks {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  &.sentType {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: #979797;
  }
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.accentMainColor};
  line-height: 19px
`;


export const DateBlock = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body1};
`;
export const SentTypeIcon = styled.span`
  display: inline-block;
  margin-right: 7px;
  margin-top: 5px;
`;
export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 9px;
  height: 24px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  background-color: #17AACF;
  border-radius: 12px;
  margin-top: 6px;
  width: 52px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    position: relative;
    top: -2px;
  }
`;


export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 400px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #C2C2C2;
`;

export const MenuContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 0;
  width: 230px;
  padding: 10px 12px;
  box-sizing: border-box;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.5);
`;

export const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: none;
  box-sizing: border-box;
  text-align: left;
  background-color: transparent;
  border-radius: 4px;
  ${ButtonTextMixin};

  :hover {
    background-color: #f2f2f2;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const Dot = styled.span`
  width: 7px;
  height: 7px;
  margin-right: 4px;
  background-color: #d8d8d8;
  border-radius: 100%;

  ${breakpoint('xs', 'md')`
    width: 6px;
    height: 6px;
  `};

  ${breakpoint('md')`
    width: 7px;
    height: 7px;
  `};
`;
export const CreateButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  color: #17AACF;
  border-color: #17AACF;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  :hover,
  :active,
  :focus {
    color: #4cc2e0;
    border-color: #4cc2e0;
  }

  ${breakpoint('xs', 'lg')`
    width: 200px;
  `}
`;

