import React, { FC } from 'react';
import {
  Container,
  Caption,
  ImageContainer,
} from './styles';

const PreviewFooter: FC = () => {

  return (
    <Container>
      <Caption>Powered by</Caption>
      <ImageContainer>
        <img src="/assets/drum_watermark.png" alt="watermark" />
      </ImageContainer>
    </Container>
  );
};

export default PreviewFooter;
