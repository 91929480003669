import React, { FC } from 'react';
import { Container, CancelButton, SubmitButton } from './styles';
import Tooltip from 'rc-tooltip';
import './styles.scss';

interface Props {
  onPublish: () => void;
  onCancel: () => void;
  publishDisabled: boolean;
  isEdit?: boolean
}

const DefineAndPreviewFormFooter: FC<Props> = ({
  onPublish,
  onCancel,
  publishDisabled,
  isEdit
}) => {
  const disabledTooltip =
    'Before you can Create your email campaign, you should publish an opportunity and add customers to your customer’s list first.';
  return (
    <Container>
      <CancelButton
        type="button"
        onClick={onCancel}
      >
        Cancel
      </CancelButton>

      {publishDisabled ? (
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlay={<span>{disabledTooltip}</span>}
          overlayClassName="referral-reward-page-publish-button-tooltip"
        >
            <SubmitButton
              type="button"
              disabled={publishDisabled}
              onClick={onPublish}
            >
              {isEdit ? 'Publish Changes' : 'Continue'}
            </SubmitButton>
        </Tooltip>
      ) : (
        <SubmitButton
          type="button"
          disabled={publishDisabled}
          onClick={onPublish}
        >
          {isEdit ? 'Publish Changes' : 'Continue'}
        </SubmitButton>
      )}
    </Container>
  );
};

export default DefineAndPreviewFormFooter;
