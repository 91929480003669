import styled from 'styled-components';
import { InputBase } from 'uikit';
import { Search } from 'assets/svg';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;
export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(InputBase)`
  max-width: 330px;
  padding-left: 40px;
`;

export const Icon = styled(Search)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;
