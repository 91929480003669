import styled from 'styled-components';
import Footer from 'components/Footer';
import { breakpoint } from 'helpers';
import { LinkTextMixin } from 'uikit';

export const ModalHeader = styled.div`
  padding: 20px 25px 12px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.borderColor};
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.67;

  ${breakpoint('md')`
    font-size: ${({ theme }) => theme.size.title};
  `}

  ${breakpoint('xs', 'md')`
    font-size: ${({ theme }) => theme.size.subtitle};
  `}
`;

export const ReturnLink = styled.button`
  border: none;
  background-color: transparent;
  ${LinkTextMixin}
`;


export const StyledFooter = styled(Footer)`
  padding-top: 120px;

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;
