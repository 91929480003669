import React, { FC } from 'react';
import { Container, TextContainer, Title, Paragraph, EmphasizedParagraph, ButtonsContainer, ApplyButton, CancelButton } from './styles';

export interface Props {
  onApply?: () => void;
  onCancel?: () => void;
}

const ConfirmPauseModal: FC<Props> = ({onApply, onCancel}) => {
  const handleApply = () => {
    onApply && onApply();
  }
  const handleCancel = () => {
    onCancel && onCancel();
  }

  return (
    <Container>
      <TextContainer>
        <Title>Pause sending confirmation</Title>
        <Paragraph>Are you sure you want to pause automated sending of all emails in your campaign?</Paragraph>
        <EmphasizedParagraph>
          <Paragraph>Invitation Email</Paragraph>
          <Paragraph>Reminder Email</Paragraph>
          <Paragraph>New Opportunity Email</Paragraph>
        </EmphasizedParagraph>
        <Paragraph>You can resume sending of these emails by clicking Resume Automated Sending.</Paragraph>
      </TextContainer>
      <ButtonsContainer>
        <ApplyButton onClick={handleApply}>Yes, pause sending</ApplyButton>
        <CancelButton onClick={handleCancel}>No, continue sending</CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

export default ConfirmPauseModal;
