import React, { FC, useState, useEffect } from 'react';
import { CONTACT_SUPPORT_LINK } from 'constants/outerLinks';
import BillingHistoryTable from './components/BillingHistoryTable';
import Pagination from 'components/Pagination';
import { FullScreenLoader } from 'uikit';
import { BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT } from 'constants/defaultPaginationLimit';
import {
  BillingInstructions,
  Container,
  Content,
  HeadingContainer,
  StyledLink,
  Title,
  PaginationContainer
} from './styles';
import { useGetPaymentHistoryLazyQuery, V15PayoutGroup } from '../../graphql';

const BillingHistory: FC = () => {
  const [
    getPaymentHistory,
    { data, loading }
  ] = useGetPaymentHistoryLazyQuery();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (!page) return;
    getPaymentHistory({
      variables: {
        input: {
          pagination: {
            page: page,
            limit: BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
          }
        }
      }
    });
    // eslint-disable-next-line
  }, [page]);

  return (
    <Container>
      {loading && <FullScreenLoader />}
      <HeadingContainer>
        <Title>Reward Payment History</Title>
      </HeadingContainer>
      <Content>
        <BillingInstructions>
          Drum charges the business each Monday for the successful referral
          rewards that occured over the previous 7 day period. If you have a
          question about a charge, please{' '}
          <StyledLink target="_blank" href={CONTACT_SUPPORT_LINK}>
            contact support.
          </StyledLink>
        </BillingInstructions>
        {data?.v15BillingPayoutGroups?.items && (
          <BillingHistoryTable
            data={
              (data?.v15BillingPayoutGroups?.items || []) as V15PayoutGroup[]
            }
            meta={data?.v15BillingPayoutGroups?.meta}
          ></BillingHistoryTable>
        )}
        {data?.v15BillingPayoutGroups?.meta && (
          <PaginationContainer>
            <Pagination
              onPageChange={(page) => setPage(page)}
              pageCount={Math.ceil(
                Number(
                  data.v15BillingPayoutGroups.meta.total ||
                    BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
                ) / BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
              )}
            />
          </PaginationContainer>
        )}
      </Content>
    </Container>
  );
};

export default BillingHistory;
