import React, {
  FC,
  useState,
  FormEvent,
  ChangeEvent as ReactChangeEvent,
  KeyboardEvent,
  useEffect
} from 'react';
import Autosuggest, {
  ChangeEvent,
  OnSuggestionSelected,
  RenderSuggestion,
  SuggestionsFetchRequested
} from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useDebouncedQuery } from 'helpers';
import {
  useFindTagsLazyQuery,
  FindTagsInput,
  FindTagsFilter
} from '../../graphql';
import { Container, StyledInput, Icon, InputContainer, AddButton } from './styles';
import { Loader } from 'uikit';
import './styles.scss';

export interface ITag {
  name: string;
  id: string;
}

interface Props {
  selectedTags: ITag[];
  onSelect: (tag: ITag) => void;
  allowNotExistingTags?: boolean;
  filter?: FindTagsFilter
}

// TODO: make this component generic for reusing in other places of app
// TODO: extract tag type from generated code and remove `any`
const TagsAutosuggest: FC<Props> = ({ onSelect, selectedTags, filter, allowNotExistingTags = true }) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [value, setValue] = useState('');

  // TODO: handle error and loading states
  const [getTags, { data, loading }] = useDebouncedQuery(useFindTagsLazyQuery);

  useEffect(() => {
    if (data?.findTags?.items) {
      setSuggestions(data?.findTags?.items);
    }
  }, [data]);

  const handleInputChange = (
    _: FormEvent<HTMLInputElement>,
    { newValue }: ChangeEvent
  ) => setValue(newValue);

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = ({ value }) => {
    let input: FindTagsInput = {
      search: value,
      page: {
        offset: 0,
        limit: 10
      }
    }
    if(filter) {
      input.filter = filter;
    }
    getTags({
      variables: {
        input
      }
    });
  }
    

  const onSuggestionSelected: OnSuggestionSelected<any> = (
    event,
    { suggestion, method }
  ) => {
    event.preventDefault();
    addTag(suggestion, suggestions); 
  };

  const addTag = (value: ITag, suggestions: any[]) => {
    const { id, name } = value;
    if(!name) {
      return;
    }
    const tagDuplicate = selectedTags.find(
      ({ name }) => name === value.name
    );
    const itemInSuggestions = suggestions.find(
      ({ name }) => name === value.name
    );

    if(!allowNotExistingTags && !itemInSuggestions) {
      return;
    }

    if (!tagDuplicate) {
      setValue('');
      onSelect({ id: itemInSuggestions?.id || id, name });
    }
  }

  const renderSuggestion: RenderSuggestion<any> = (suggestion, { query }) => {
    const matchedText = match(suggestion.name, query);
    const parsedText = parse(suggestion.name, matchedText);

    return parsedText.map(({ text, highlight }, i) => (
      <span key={i}>{highlight ? <b>{text}</b> : text}</span>
    ));
  };

  const onKeyDown = (event:KeyboardEvent) => {
    if(event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      addTag({id: '', name: value}, suggestions);
    }
  }

  return (
    <Container>
      <Autosuggest
        inputProps={{
          name: 'tagsSearch',
          onChange: handleInputChange,
          placeholder: "Tag Name: Example VIP",
          value
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        suggestions={suggestions}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={(suggestion: ITag) => suggestion.name}
        renderInputComponent={(inputProps) => (
          <InputContainer>
            {loading && <Loader className="tags-autosuggest-loader" />}
            <Icon />
            <StyledInput
              {...inputProps}
              onKeyDown = {onKeyDown}
              // `onChange` from `react-autosuggest` is incompatible with input `onChange` handler.
              // See more details here - https://stackoverflow.com/questions/61785523/autosuggest-renderinputcomponent-inputprops-types-of-property-onchange-are-i
              onChange={(event: ReactChangeEvent<HTMLInputElement>): void => {
                inputProps.onChange(event, {
                  newValue: event.target.value,
                  method: 'type'
                });
              }}
            />
            {allowNotExistingTags && 
              <AddButton type="button" onClick={() => addTag({id: '', name: value}, suggestions)}>Add</AddButton>
            }
            
          </InputContainer>
        )}
        renderSuggestion={renderSuggestion}
      />
    </Container>
  );
};

export default TagsAutosuggest;
