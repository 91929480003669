import styled from 'styled-components';
import WidgetContainer from 'components/WidgetContainer';
import { breakpoint } from 'helpers';

export const Container = styled(WidgetContainer)`
  position: relative;
  box-sizing: border-box;

  ${breakpoint('lg')`
    display: flex;
    min-height: 310px;
    padding: 35px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px;
  `}
`;

export const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 36px;
  line-height: 1.15;
`;

export const SetupCompleteTitle = styled(Title)`
  margin-bottom: 20px;
`;

export const StepTitle = styled(Title)`
  ${breakpoint('lg')`
    max-width: 350px;
    margin-right: 30px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 30px;
  `}
`;

export const SetupCompleteDetails = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Instructions = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.57;
  ${breakpoint('lg')`
    margin-bottom: 30px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 20px;
  `}
`;
