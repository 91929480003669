import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import queryString from 'query-string';
import AuthPageLayout from 'layouts/AuthPageLayout';
import AuthMethods from 'components/AuthMethods';
import AuthReturnLink from 'components/AuthReturnLink';
import ShopifyAuthForm from 'components/ShopifyAuthForm';
import AccountCreatedModal from 'components/AccountCreatedModal';
import shopifyAuthEvents from 'constants/shopifyAuthEvents';
import routes from 'constants/routes';
import { APP_SOURCE_QUERY_PARAM, APP_SOURCE_QUERY_BUSINESS } from 'constants/forgotPasswordParams';
import authMethods from 'constants/authMethods';
import { googleSignIn } from 'services/auth';
import { SHOPIFY_AUTH_REDIRECT_URL } from 'constants/shopifyUrls';
import {
  setShopifyLoginData,
  getShopifyLoginData,
  removeShopifyLoginData
} from 'helpers/shopifyLoginStorage';
import {
  ForgotPasswordLink,
  StyledLogInEmailForm,
  StyledShopifyAuthInfo,
  ModalErrorText
} from './styles';
import {
  shopifyErrors,
  preSignUpExcessPart,
  FALLBACK_AUTH_ERROR_MESSAGE
} from 'constants/errors';
import { Modal } from 'uikit';
import trackedElementsIds from 'constants/trackedElementsIds';
import { registerAfterLogInHook, Actions } from 'helpers/afterLoginHook';

type Props = RouteComponentProps<
  {},
  StaticContext,
  { from?: { search: string; pathname: string } }
>;

const LogInPage: FC<Props> = ({ location, history }) => {
  const [isShopifyInfoShown, setShopifyInfo] = useState(false);
  const [error, setShopifyError] = useState<string | null>(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [shopifyLoginModalData, setShopifyLoginModalData] = useState({
    isOpen: false,
    email: null,
    shop: null,
    provider: null
  });
  const [urlError, setUrlError] = useState<string>('');
  const forgotPasswordLink = routes.FORGOT_PASSWORD + `?${APP_SOURCE_QUERY_PARAM}=${APP_SOURCE_QUERY_BUSINESS}`;
  const queryParams = queryString.parse(location.search);
  const { m } = queryParams;

  const previousUrlQueryString = location?.state?.from?.search;
  useEffect(() => {
    if (typeof previousUrlQueryString === 'string') {
      //_e is the user's email
      //_s is a full shopify shop name
      //_p is a provider's name (Google/email/etc)
      //_flag determines if we should put this data in storage for it to be used on login page to display shopify login modal
      const { redirectUrl, _e, _s, _p, _flag } = queryString.parse(
        previousUrlQueryString
      );
      if (typeof redirectUrl === 'string') {
        if (_flag === '1' && _s) {
          attemptSettingLoginModalFlag(
            (_e as string) || '',
            (_s as string) || '',
            (_p as string) || ''
          );
        }
        history.push(redirectUrl);
      }
    }
  }, [previousUrlQueryString, history]);

  const attemptSettingLoginModalFlag = (
    email: string,
    shop: string,
    provider: string
  ) => {
    setShopifyLoginData({ email, shop, provider });
  };

  const findErrorMessageByCode = (code: string) => {
    const error = shopifyErrors.find((e) => e.code === code);

    return error?.message;
  };
  const handleErrorMainButtonClick = () => {
    setErrorModalOpen(false);
    history.push(routes.LOGIN);
  };
  const handleErrorSecondaryButtonClick = () => {
    setErrorModalOpen(false);
    history.push(routes.SIGN_UP);
  };
  const closeShopifyLoginModal = () => {
    setShopifyLoginModalData({ ...shopifyLoginModalData, isOpen: false });
    removeShopifyLoginData();
  };

  useEffect(() => {
    if (
      window.location.search.indexOf('error_description=') > 0 &&
      window.location.search.indexOf('error=') > 0 &&
      window.location.search.indexOf('state=') > 0
    ) {
      const { error_description } = queryString.parse(window.location.search);
      error_description &&
        setUrlError(
          error_description.toString().replace(preSignUpExcessPart, '') || ''
        );
      setErrorModalOpen(true);
    } else {
      const _shopifyLoginData = getShopifyLoginData();
      if (_shopifyLoginData) {
        setShopifyLoginModalData({
          ...shopifyLoginModalData,
          isOpen: true,
          email: _shopifyLoginData.email,
          shop: _shopifyLoginData.shop,
          provider: _shopifyLoginData.provider
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      authViaShopify,
      errorMessage,
      shop,
      shopifyAuthError: errorCode,
      shopifyEmail
    } = queryString.parse(location.search);

    if (authViaShopify) {
      setShopifyInfo(true);

      if (errorCode) {
        const error =
          (typeof errorMessage === 'string' && errorMessage) ||
          (typeof errorCode === 'string' &&
            findErrorMessageByCode(errorCode)) ||
          FALLBACK_AUTH_ERROR_MESSAGE;

        setShopifyError(error);
      } else if (typeof shop === 'string' && typeof shopifyEmail === 'string') {
      }
    }
  }, [location.search]);

  const handleRedirectOnSuccess = () => {
    const redirectPath = location?.state?.from?.pathname || routes.DASHBOARD;
    const redirectSearch = location?.state?.from?.search || '';
    registerAfterLogInHook(Actions.RedirectTo, {
      path: redirectPath,
      search: redirectSearch
    });
  };

  const handleGoogleLogIn = async () => {
    const result = await googleSignIn();
    if (!result?.code) {
      handleRedirectOnSuccess();
    }
  };

  const handleShopifyLogin = () => {
    window.location.href = SHOPIFY_AUTH_REDIRECT_URL;
  };

  return (
    <>
      <AuthPageLayout
        subtitle="Business Log In"
        returnLink={
          <AuthReturnLink
            accentedText="Sign up"
            mainText="Need a Drum business account?"
            to={routes.SIGN_UP}
          />
        }
        authMethodByDefault={m === "e" ? authMethods.EMAIL : null}
        renderContent={(authMethod, selectAuthMethod) => (
          <>
            {authMethod === authMethods.EMAIL && (
              <>
                <StyledLogInEmailForm
                  onReturnBack={() => selectAuthMethod(null)}
                  onLoginSuccess={handleRedirectOnSuccess}
                />
                <ForgotPasswordLink
                  to={forgotPasswordLink}
                  id={trackedElementsIds.forgotPasswordButton}
                >
                  Forgot password?
                </ForgotPasswordLink>
              </>
            )}
            {authMethod === authMethods.SHOPIFY && (
              <ShopifyAuthForm
                authEvent={shopifyAuthEvents.LOG_IN}
                onReturnBack={() => selectAuthMethod(null)}
              />
            )}
            {!authMethod && (
              <>
                <AuthMethods
                  authProcedureName="Log In"
                  onAuthByEmail={() => selectAuthMethod(authMethods.EMAIL)}
                  onAuthByGoogle={handleGoogleLogIn}
                  onAuthByShopify={handleShopifyLogin}
                />
                {isShopifyInfoShown && <StyledShopifyAuthInfo error={error} />}
              </>
            )}
          </>
        )}
      />
      <Modal
        title={urlError || ''}
        type="warning"
        isOpen={isErrorModalOpen}
        canClose={false}
        childrenBeforeButtons={true}
        mainButton={{ text: 'Log In', onClick: handleErrorMainButtonClick }}
        secondaryButton={{
          text: 'Return to Sign Up',
          onClick: handleErrorSecondaryButtonClick
        }}
      >
        <ModalErrorText>
          Please log in or try using a different email address to sign up.
        </ModalErrorText>
      </Modal>

      <AccountCreatedModal
        email={shopifyLoginModalData.email || ''}
        provider={shopifyLoginModalData.provider || ''}
        isOpen={shopifyLoginModalData.isOpen}
        onMainButtonClick={closeShopifyLoginModal}
        onClose={closeShopifyLoginModal}
      ></AccountCreatedModal>
    </>
  );
};

export default LogInPage;
