import React, { FC } from 'react';
import IconProps from './IconProps';

const Tick: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.63822 6.99997C3.39028 6.99997 3.15237 6.90143 2.97705 6.72598L0.142578 3.89645L1.46533 2.57621L3.63822 4.74523L8.2484 0.142822L9.57115 1.46346L4.2994 6.72598C4.12407 6.90143 3.88616 6.99997 3.63822 6.99997Z"
      fill="white"
    />
  </svg>
);

export default Tick;
