import styled from 'styled-components';
import { H3, Body2 } from 'uikit';

export const PreviewContainer = styled.div`
	padding: 37px;
	min-height: 600px;
`;
export const PreviewHeader = styled(H3)`
	line-height: 21px;
	margin-bottom: 33px;
`;
export const PreviewParagraph = styled(Body2)`

`;
export const PreviewFooter = styled(Body2)`

`;