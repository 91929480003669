import * as yup from 'yup';
import {
  REFERRAL_WEBPAGE_BODY_MAX_LENGTH,
  REFERRAL_WEBPAGE_TITLE_MAX_LENGTH
} from 'constants/fieldLengthLimits';
import every from 'lodash.every';
import { emailRegExp } from 'constants/regExp';
import { ReferralPageStateConfigPageStyleBackgroundType } from '../../graphql';

const subjectDetailsSchema = yup
  .object({
    config: yup.object({
      title: yup
        .string()
        .required('Title is required')
        .max(REFERRAL_WEBPAGE_TITLE_MAX_LENGTH, `${REFERRAL_WEBPAGE_TITLE_MAX_LENGTH} characters allowed`),
      body: yup
        .string()
        .required('Body is required')
        .max(REFERRAL_WEBPAGE_BODY_MAX_LENGTH, `${REFERRAL_WEBPAGE_BODY_MAX_LENGTH} characters allowed`)
    })
  })
  .required();

const styleSchema = yup.object({
  buttonColor: yup.string().required('Button color is required'),
  textColor: yup.string().required('Text color is required'),
  cornersRadius: yup.number().required('Corner radius is required'),
  fontName: yup.string().required('Font is required'),
  background: yup.object({
    backgroundType: yup
      .mixed<ReferralPageStateConfigPageStyleBackgroundType>()
      .oneOf(Object.values(ReferralPageStateConfigPageStyleBackgroundType)),
    backgroundImage: yup
      .string()
      .nullable()
      .default('')
      .required('Background image is required'),
    backgroundColor: yup
      .string()
      .nullable()
      .default('')
      .required('Background color is required')
  })
});

const shopifyPageSchema = yup
  .object({
    pageId: yup.string().nullable(),
    shopAddress: yup.string().nullable(),
    title: yup.string().required('Title is required'),
    body: yup.string(),
    isHidden: yup.lazy((value) => {
      switch (typeof value) {
        case 'boolean':
          return yup.boolean();
        case 'string':
          return yup.string();
        default:
          return yup.boolean();
      }
    }),
    userDidCreateTheShopifyPage: yup.boolean(),
    pageUrl: yup.string().nullable(),
    adminPageUrl: yup.string().nullable()
  })
  .nullable();

const baseFields = {
  url: yup.string(),
  states: yup.object({
    join: subjectDetailsSchema,
    share: subjectDetailsSchema,
    thankYou: subjectDetailsSchema
  }),
  pageStyle: styleSchema,
  shopifyPageConfig: shopifyPageSchema,
  pageType: yup.string()
};

//Send Email Form values
const sendEmailFields = {
  to: yup.string().test('invalid emails', 'Invalid emails', function(value) {
    const chunks = (this.parent.to || '').split(',');
    const trimmedChunks = chunks.map((item: string) => item.trim());
    return chunks.length && every(trimmedChunks, (item: string) => emailRegExp.test(item));
  }),
  from: yup.string().test('invalid email', 'Invalid email', function(value) {
    return emailRegExp.test(value);
  }),
  message: yup.string().required('Message cannot be empty'),
  sendLater: yup.boolean()
};

const validationSchema = yup.object(baseFields);
export const sendEmailSchema = yup.object(sendEmailFields);

export type SendEmailFormType = yup.InferType<typeof sendEmailSchema>;
export type PageFormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
