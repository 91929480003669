import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FormFooter from 'components/FormFooter';
import {
  Body2,
  H2,
  Label,
  LinkTextMixin,
  LabelTextMixin
} from 'uikit';
import { breakpoint } from 'helpers';

export const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  /* TODO: remove it after integration of categories control */
  overflow-x: hidden;

  ${breakpoint('lg')`
    padding: 35px 55px 70px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 25px 50px;
  `};
`;

export const PageHeader = styled.div`
  ${breakpoint('lg')`
    margin-bottom: 60px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 40px;
  `}
`;

export const StyledHeading = styled(H2)`
  margin-bottom: 10px;
`;

export const ReturnLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  ${LinkTextMixin};
`;

export const Section = styled.section`
  :not(:last-child) {
    ${breakpoint('xs', 'lg')`
      margin-bottom: 30px;
    `}

    ${breakpoint('lg')`
      margin-bottom: 50px;
    `}
  }
`;

export const SectionTitle = styled(Label)`
  margin-bottom: 10px;
`;

export const CategoriesDescription = styled(Body2)`
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const CategoriesCount = styled.span`
  display: inline-block;
  margin: 0 4px;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const SelectedCategoriesContainer = styled.div`
  margin-top: 10px;
`;

export const SelectedCategory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  padding: 10px 10px 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  height: 53px;
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const RemoveCategoryButton = styled.button`
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
`;

export const PrimaryContactSection = styled.section`
  ${breakpoint('lg')`
    max-width: 700px;
  `}
`;

export const PrimaryContactDescription = styled(Body2)`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const PrimaryContactFields = styled.div`
  ${breakpoint('md')`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 15px;
  `}
`;

export const InputGroup = styled.div`
  ${breakpoint('xs', 'lg')`
    :not(:last-child) {
      margin-bottom: 20px;
    }
  `}
`;

export const SmallLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  padding-left: 10px;
  ${LabelTextMixin};
`;

export const OptionalFieldCaption = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.inputColor};
`;

export const StyledFooter = styled(FormFooter)`
  width: 100%;
  box-sizing: border-box;
`;
