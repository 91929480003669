import React, { FC, useState, useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { PlatformList, Footer } from './components';
import { Container, ListContainerInner, StyledHeader } from './styled';
import { getIntegrationPlatforms, setupInstructions, OTHER_PLATFORM } from './helpers';
import { FullScreenLoader, Modal } from 'uikit';
import { useGetBusinessDetailsQuery } from '../../graphql';
import {
  IntegrationType,
  useGetCurrentBusinessIntegrationsQuery
} from '../../graphql';
import routes from 'constants/routes';

const SetupCustomerTracking: FC = () => {

  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  const id = businessProfileData?.getCurrentBusiness?.id;

  const history = useHistory();
  const {
    data,
    loading: isIntegrationsLoading,
    error: integrationsError
  } = useGetCurrentBusinessIntegrationsQuery();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const platforms = getIntegrationPlatforms(id);

  const openErrorModal = () => {
    setModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setModalIsOpen(false);
    history.push(routes.DASHBOARD);
  };

  useEffect(() => {
    const integrations = data?.getCurrentBusiness?.integrations;
    if (integrations?.baseCodeUrl) {
      const integrationPagePath = generatePath(
        routes.STANDARD_INTEGRATION_PAGE,
        {
          platform: integrations?.platformName || OTHER_PLATFORM
        }
      );

      history.push(integrationPagePath);
    }

    if (data?.getCurrentBusiness?.integrationType === IntegrationType.Shopify) {
      history.push(routes.SHOPIFY_INTEGRATION_PAGE);
    }
  }, [data, history]);

  useEffect(() => {
    integrationsError && openErrorModal();
  }, [integrationsError]);

  return (
    <Container>
      <ListContainerInner>
        <StyledHeader
          setupInstructions={setupInstructions}
          onScheduleCall={() => {}}
        />
        <PlatformList items={platforms} />
        <Footer />
      </ListContainerInner>
      {isIntegrationsLoading && <FullScreenLoader />}
      <Modal
        type="warning"
        title={'Could not load integrations'}
        mainButton={{ text: 'Okay', onClick: closeErrorModal }}
        isOpen={modalIsOpen}
        onClose={closeErrorModal}
      ></Modal>
    </Container>
  );
};

export default SetupCustomerTracking;
