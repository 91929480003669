import {
  IntegrationType,
  Payment,
  PaymentProvider
} from '../graphql';

export const isShopifyWithDefaultCreditCard = (integrationType?: IntegrationType | null, payments:Payment[] = []) => {
  const hasDefaultCreditCard = payments?.find(item => item?.provider === PaymentProvider.Credit && !!item.default);
  return !!(integrationType === IntegrationType.Shopify && hasDefaultCreditCard);
}
export const isShopifyWithCreditCard = (integrationType?: IntegrationType | null, payments:Payment[] = []) => {
  const hasCreditCard = payments?.find(item => item?.provider === PaymentProvider.Credit);
  return !!(integrationType === IntegrationType.Shopify && hasCreditCard);
}
export const hasPaypal = (payments:Payment[] = []) => {
  return payments?.find(item => item?.provider === PaymentProvider.Paypal);
}
