import styled from 'styled-components';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  
`;
export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 58px;
  margin-bottom: 30px;

  ${breakpoint('xs', 'lg')`
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 20px;
  `}
  
`;
export const InfoLeft = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    align-items: flex-start;
  `}
`;
export const IconContainer = styled.div`
  margin-right: 20px;
  margin-top: 6px;
`;
export const InfoText = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 26px;

  &.restricted {
    max-width: 600px;
  }
`;

export const InfoRight = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
  `}
`;