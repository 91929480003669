import React, {FC} from 'react'
import { useHistory } from 'react-router-dom'
import {
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL
} from 'constants/errors';
import {
  Modal
} from 'uikit';
import {
  Container,
  Content,
  Email,
  TextBlock
} from './styled'
import routes from 'constants/routes'

export interface Props {
  title?: string,
  email?: string,
  text?: string,
  isOpen?: boolean,
  onClose?: () => void,
  onMainButtonClick?: () => void,
  onSecondaryButtonClick?: () => void,
}

const SignUpError:FC<Props> = ({
  title = FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
  isOpen = false,
  text = FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL,
  email,
  onClose,
  onMainButtonClick,
  onSecondaryButtonClick,
}) => {

  const history = useHistory();

  const handleMainButtonClick = () => {
    onMainButtonClick ? onMainButtonClick() : history.push(routes.LOGIN);
  }
  const handleSecondaryButtonClick = () => {
    onSecondaryButtonClick ? onSecondaryButtonClick() : history.push(routes.SIGN_UP);
  }
  const handleCloseButton = () => {
    onClose && onClose()
  }

  return (
      <Modal
        title={title}
        type="warning"
        mainButton={{ text: 'Log In', onClick: handleMainButtonClick }}
        secondaryButton={{ text: 'Return to Sign Up', onClick: handleSecondaryButtonClick }}
        isOpen={isOpen}
        onClose={handleCloseButton}
        childrenBeforeButtons={true}
      >
        <Container>
          <Content>
            {email && <Email>
              {email}
            </Email>}
            <TextBlock>
              {text}
            </TextBlock>
          </Content>
        </Container>
      </Modal>
    
  )
}

export default SignUpError;





