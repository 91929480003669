import styled, { Theme } from 'styled-components';

export const Container = styled.div<{ theme: Theme; isSelected?: boolean }>`
  flex: 1;
  height: 60px;
  margin-bottom: 10px;
  padding: 14px;
  border: solid 1px #d6d6d6;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  border-radius: 7px;
  box-shadow: ${({ isSelected, theme }) =>
    isSelected ? `0 0 8px 0 ${theme.colors.boxShadowDefault}` : 'none'};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 9px;
  }

  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #707070;
`;

export const Value = styled.div`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;
