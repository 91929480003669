import React, { FC } from 'react';
import LogoSvg from 'assets/svg/Logo';
import { Container } from './styled';

interface Props {
  className?: string;
}

const Logo: FC<Props> = ({ className }) => (
  <Container className={className}>
    <LogoSvg />
  </Container>
);

export default Logo;
