import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { FullScreenLoader } from 'uikit';
import { getIsProfileSetupCompleted } from 'helpers';
import OpportunityMenuContainer from 'components/OpportunityMenuContainer';
import { FilterData } from 'components/OpportunityFilters/OpportunityFilters';
import DrummersCount from 'components/DrummersCount';
import DashboardPageLayout from 'layouts/DashboardPageLayout';
import {
  BottomRow,
  StyledDrummerInvitationPanel,
  StyledOpportunityPerformance,
  StyledOpportunityFilters,
  StyledOpportunityMenu,
  StyledProfileSetup,
  StyledDashboardOpportunityPreview,
  StyledDrummersTable,
  StyledTopRewardTable,
  DrummersCountContainer,
  TopSection,
  MiddleSection
} from './styles';
import {
  useGetListOffersQuery,
  useGetDashboardDataLazyQuery,
  SortType,
  RewardActionType,
  useGetBusinessDetailsQuery,
  useGetOfferLazyQuery
} from '../../graphql';
import { OPPORTUNITY_DASHBOARD_PARAM } from 'constants/opportunityQueryParams';
import OpportunityStatuses from 'constants/opportunityStatusesEnum';

const DashboardPage: FC<RouteComponentProps> = (props) => {
  const { data } = useGetBusinessDetailsQuery({ fetchPolicy: 'cache-first' });
  const isProfileSetupCompleted = getIsProfileSetupCompleted(data);
  const params = queryString.parse(props.location.search);
  const id = params[OPPORTUNITY_DASHBOARD_PARAM];
  const [currentFilters, setCurrentFilters] = useState<FilterData>();

  const [getOffer, { data: offer }] = useGetOfferLazyQuery();

  const [
    getDashboardData,
    { data: dashboardData, loading: getDashboardDataLoading }
  ] = useGetDashboardDataLazyQuery();

  const onFiltersChange = (filters: FilterData) => {
    setCurrentFilters(filters);
    let _params = Object.assign({}, params);
    if (filters?.opportunity?.id) {
      _params[OPPORTUNITY_DASHBOARD_PARAM] = filters?.opportunity?.id;
    }

    filters.opportunity !== undefined &&
      props.history.push({
        pathname: props.location.pathname,
        search: queryString.stringify({
          ..._params
        })
      });
  };

  const getFilteredListOffers = (items?: any[] | null) => {
    const listItems = (items || [])
      .filter(
        (item) =>
          item.status === OpportunityStatuses.paused ||
          item.status === OpportunityStatuses.active
      )
      .slice(0, 7);
    if (id && items) {
      let currentIdItem = items.find((item) => item && item.id === id);
      let currentIdItemInList = listItems.find(
        (item) => item && item.id === id
      );
      currentIdItem !== undefined &&
        !currentIdItemInList &&
        listItems.push(currentIdItem);
    }
    return listItems;
  };

  useEffect(() => {
    if (!currentFilters) return;
    let input = {
      dateRange: {
        from: currentFilters?.date?.startDate.toISOString(),
        to: currentFilters?.date?.endDate.toISOString()
      },
      offerId: currentFilters?.opportunity?.id || undefined,
      v15: true
    };
    getDashboardData({
      variables: {
        businessStatsInput: input
      }
    });
    if (currentFilters?.opportunity?.id) {
      getOffer({
        variables: { id: currentFilters.opportunity.id }
      });
    }
    // eslint-disable-next-line
  }, [currentFilters]);

  const {
    data: listOffers,
    loading: isListOffersLoading
  } = useGetListOffersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        filter: {
          status: {
            ne: 'DELETED'
          }
        },
        pagination: { limit: 9999 },
        sortBy: { startedAt: SortType.Desc }
      }
    }
  });

  const isSingleOpportunityShown = typeof id === 'string';

  return (
    <DashboardPageLayout>
      <DrummersCountContainer>
        <DrummersCount />
      </DrummersCountContainer>

      {isProfileSetupCompleted &&
        !data?.getCurrentBusiness?.inviteDrummersSuggestionShownTimes && (
          <StyledDrummerInvitationPanel />
        )}

      <StyledProfileSetup />

      <TopSection hasSidebar={isSingleOpportunityShown}>
        {listOffers && (
          <StyledOpportunityFilters
            opportunities={getFilteredListOffers(listOffers?.listOffers?.items)}
            onChange={onFiltersChange}
            initialValue={currentFilters?.opportunity?.id || (id as string)}
          />
        )}

        {offer?.getOffer?.status && currentFilters?.opportunity?.id && (
          <OpportunityMenuContainer
            id={offer.getOffer.id}
            endedAt={offer.getOffer.endedAt}
            budget={offer.getOffer.drumRedemption?.limitRedemption?.value}
            commission={offer.getOffer.drumRedemption?.payDrum}
            redemptionCount={offer.getOffer.redeemed}
            status={offer.getOffer.status}
            canViewPerformance={false}
            component={StyledOpportunityMenu}
          />
        )}
      </TopSection>
      <MiddleSection>
        <StyledOpportunityPerformance
          hasSidebar={isSingleOpportunityShown}
          offers={listOffers?.listOffers?.items || []}
          offerId={currentFilters?.opportunity?.id || null}
          data={dashboardData?.getStatsBusiness?.v15BusinessStats?.fields}
        />

        {offer && currentFilters?.opportunity?.id && (
          <StyledDashboardOpportunityPreview offer={offer} />
        )}
      </MiddleSection>

      <BottomRow>
        <StyledDrummersTable
          offerId={currentFilters?.opportunity?.id || null}
          period={
            currentFilters?.date
              ? {
                  from: currentFilters.date.startDate,
                  to: currentFilters.date.endDate
                }
              : undefined
          }
        />
        <StyledTopRewardTable
          offerId={currentFilters?.opportunity?.id || null}
          period={
            currentFilters?.date
              ? {
                  from: currentFilters.date.startDate,
                  to: currentFilters.date.endDate
                }
              : undefined
          }
          showTotalColumn={
            !(
              currentFilters?.opportunity?.rewardActionType ===
              RewardActionType.NavigateToSpecificPage
            )
          }
        />
      </BottomRow>

      {(getDashboardDataLoading || isListOffersLoading) && <FullScreenLoader />}
    </DashboardPageLayout>
  );
};

export default DashboardPage;
