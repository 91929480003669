import {TemplateStyles} from '../../graphql';
import { theme } from 'styles/theme';
import {
  CampaignStatus
} from '../../graphql';

export const DESKTOP_VIEW = 'desktopView';
export const MOBILE_VIEW = 'mobileView';
export const TEXT_FORMAT_OPTIONS = ['First_Name', 'Last_Name'];

export type ViewModeType = typeof DESKTOP_VIEW | typeof MOBILE_VIEW;
type PreviewOption = { name: string; value: ViewModeType };

export const PREVIEW_MODES: [PreviewOption, PreviewOption] = [
  {
    name: 'Desktop',
    value: DESKTOP_VIEW
  },
  {
    name: 'Mobile',
    value: MOBILE_VIEW
  }
];

export const defaultColors = {
  bg: theme.colors.backgroundColor,
  textColor: '#000',
  buttonBg: '#17AACF',
  buttonTextColor: '#fff',
  footerBg: '#939393',
  footerTextColor: '#fff',
  
}
export const defaultCorners = 21;
export const defaultFont = 'Roboto';

export const emailFonts = [
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Comic Sans MS',
    value: 'Comic Sans MS',
  },
  {
    label: 'Courier New',
    value: 'Courier New',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Lucida',
    value: 'Lucida',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman',
  },
  {
    label: 'Trebuchet MS',
    value: 'Trebuchet MS',
  },
  {
    label: 'Verdana',
    value: 'Verdana',
  },
];

export const getInitialValues = (businessName: string | null = 'Business name') => ({
  emails: {
    invitationEmail: {
      subjectLine: `Love ${businessName?.trim()}? Earn money sharing the love!`,
      emailBody: `Hi {{First_Name}}\n\nIf you love ${businessName?.trim()}, please spread the word. ${businessName?.trim()} has a referral opportunity where you can earn rewards while helping your friends save money`
    },
    reminderEmail: {
      subjectLine: `Reminder. Check out these referral opportunities from ${businessName?.trim()}`,
      emailBody: `Hi {{First_Name}}\n\nThank you for joining ${businessName?.trim()}'s referral program. Help your friends save money and earn rewards anytime you share our offers with your network. Thank you for choosing ${businessName}.`
    },
    newOpportunityEmail: {
      subjectLine: `Check out this new referral opportunity from ${businessName?.trim()}.`,
      emailBody: `Hi {{First_Name}}\n\nCheck out this great new referral opportunity from ${businessName?.trim()}!`
    }
  },
  emailType: 'invitationEmail',
  sendType: 'all',
  sendToFilter: {
    tags: {
      in: []
    }
  },
  buttonColor: defaultColors.bg,
  textColor: defaultColors.bg,
  backgroundColor: defaultColors.bg,
  fromEmail: '',
  emailStyle: {
    coverImage: null,
    font: defaultFont,
    backgroundColor: defaultColors.bg,
    buttonStyle: {
      buttonColor: defaultColors.buttonBg,
      textColor: defaultColors.buttonTextColor,
      cornerRadius: defaultCorners
    },
    footerStyle: {
      backgroundColor: defaultColors.footerBg,
      textColor: defaultColors.footerTextColor
    }
  },
  templateStyle: TemplateStyles.TemplateStyle1,
  sendTest: '',
  status: CampaignStatus.Draft
});



