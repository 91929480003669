import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { Body2, Label, LabelTextMixin } from 'uikit';

export const SectionTitle = styled(Label)`
  margin-bottom: 10px;
`;

export const SectionDescription = styled(Body2)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const DateFields = styled.div`
  ${breakpoint('sm')`
    display: flex;
  `}
`;

export const InputGroup = styled.div`
  :not(:last-child) {
    ${breakpoint('sm')`
      margin-right: 35px;
    `}

    ${breakpoint('xs', 'sm')`
      margin-bottom: 20px;
    `}
  }
`;

export const DateInputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  ${LabelTextMixin};
`;
