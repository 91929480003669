import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import WidgetContainer from '../WidgetContainer';
import WidgetTitle from '../WidgetTitle';
import { chartOptions } from '../OpportunityChart/constants';
export const Container = styled(WidgetContainer)`
  box-sizing: border-box;
  
  ${breakpoint('xs', 'xl')`
    width: 100%;
    padding: 20px 25px 30px;
  `}

  ${breakpoint('xl')`
    flex: 1;
    padding: 20px 20px 35px 25px;
  `}
`;

export const MetaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const DateContainer = styled.div`
  margin-right: 25px;
  font-size: ${({ theme }) => theme.size.body2};
`;
export const StatusContainer = styled.div`
  position:relative;
  top: -2px;
  display: flex;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
export const StatusValue = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.danger};
  margin-left: 10px;
  &.PAUSED {
    color: #ff7a00;
  }
`;

export const StyledTitle = styled(WidgetTitle)`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

export const Metrics = styled.div`
  ${breakpoint('md')`
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
    margin-bottom: 10px;
  `}

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;

export const MetricDetails = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  line-height: 1.17;

  ${breakpoint('md')`
    margin-bottom: 55px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 30px;
  `}
`;

export const MetricTitle = styled.h3`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const MetricDescription = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #616161;
`;

export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: ${chartOptions.height}px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #C2C2C2;
  
`;
