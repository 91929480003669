import styled from 'styled-components';
import IconButton from '../IconButton';
import { Share } from '../../assets/svg';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';
import { ButtonTextMixin, LinkTextMixin, MultilineOverflowMixin } from 'uikit';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 85px;
  border: solid 1px #dedede;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ShareButton = styled(IconButton)`
  position: absolute;
  top: -5px;
  right: 18px;
`;

export const ShareIcon = styled(Share)`
  fill: ${({ theme }) => theme.colors.main};
`;

export const OpportunityCoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  border-right: solid 1px #dedede;
  overflow: hidden;
  border-radius: 12px 0 0 12px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  ${breakpoint('md', 'lg')`
    margin-right: 10px;
  `};
`;

export const OpportunityPhoto = styled.img`
  max-width: 100%;
  height: auto;
`;

export const OpportunityPhotoPlaceholder = styled.span`
  ${ButtonTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.placeholder};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
`;

interface OpportunityDetailsProps extends ITheme {
  hasEarning: boolean;
}

export const OpportunityDetails = styled.div<OpportunityDetailsProps>`
  max-width: 210px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  line-height: 1.15;
`;

export const BusinessInfo = styled.div`
  display: flex;
  align-items: flex-start;
  height: 35px;
  margin-right: 6px;
  margin-bottom: 2px;
`;

export const BusinessPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const BusinessPhoto = styled.img`
  max-width: 100%;
  height: auto;
`;

export const BusinessName = styled.h2`
  ${MultilineOverflowMixin}
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 6px;
  &:before {
    display: none;
  }
  ${breakpoint('md', 'lg')`
  font-size: 12px;
`};
`;

export const OpportunityTitle = styled.p`
  ${MultilineOverflowMixin}
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 14px;
  max-height: 28px;
  &:before {
    display: none;
  }
  ${breakpoint('md', 'lg')`
    font-size: ${({ theme }) => theme.size.caption};
  `};
`;

export const Badges = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Earning = styled.div`
  ${LinkTextMixin};
  color: ${({ theme }) => theme.colors.alertColor};
  padding-left: 10px;
`;

export const ViewLink = styled.a`
  ${LinkTextMixin};
  text-align: right;
  font-size: 12px;
`;
