import React, { FC } from 'react';
import OpportunityCardInvite from 'components/OpportunityCardInvite';
import {
  Container,
  Copyright,
  GoToSiteButton,
  StyledLogo,
  StyledOpportunityCard
} from './styles';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { GetBusinessRepresentationQuery } from 'graphql';

interface Props {
  className?: string;
  values: CreateOpportunityType;
  companyInfo: GetBusinessRepresentationQuery | undefined;
}

const CustomerView: FC<Props> = ({ className, values, companyInfo }) => {
  const headerProps = {
    canSharePage: true,
    drummerName: 'Jane Murphy',
    subtitle: 'Shared a rec with you'
  };

  return (
    <Container className={className}>
      <OpportunityCardInvite {...headerProps} />

      <StyledOpportunityCard values={values} companyInfo={companyInfo}>
        <GoToSiteButton type="button">Go To Website</GoToSiteButton>
      </StyledOpportunityCard>

      <div>
        <StyledLogo src="/assets/drum-grey.png" alt="drum-logo" />
        <Copyright>
          &copy; Drum Technologies Inc. All rights reserved.
        </Copyright>
      </div>
    </Container>
  );
};

export default CustomerView;
