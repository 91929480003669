import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { FieldFooter, Select, Toggle } from 'uikit';
import ReferralRewardTypes from 'constants/referralRewardTypes';
import {
  CommissionType,
  IntegrationType,
  RewardActionType
} from '../../../../graphql';
import {
  sectionHeadings,
  MINIMUM_PURCHASE_AMOUNT_OPTIONS,
  BUDGET_LIMIT_OPTIONS,
  REFERRAL_REWARD_ACTIONS,
  PERCENTAGE_COMMISSION_OPTION,
  FIXED_COMMISSION_OPTION
} from './constants';
import {
  CommissionFields,
  CommissionTypeSection,
  FormGroup,
  MargedRewardInput,
  RewardTriggerEventSelect,
  SectionTitle,
  SectionDescription,
  StyledRewardInput
} from './styles';

interface Props {
  integrationType: IntegrationType | undefined | null;
  isEdit?: boolean;
}

const ReferralReward: FC<Props> = ({ integrationType, isEdit }) => {
  const { values, errors, setFieldValue } = useFormikContext<
    CreateOpportunityType
  >();
  const [ comissionAmountWasChanged, setComissionAmountWasChanged] = useState(false);

  const {
    rewardActionType,
    commissionType,
    commissionAmount,
    isMinimumPurchaseAmountRequired,
    minimumPurchaseAmount,
    isBudgetLimited,
    budget
  } = values.referralReward;

  const commissionTypeOptions =
    rewardActionType === RewardActionType.NavigateToSpecificPage
      ? [FIXED_COMMISSION_OPTION]
      : [PERCENTAGE_COMMISSION_OPTION, FIXED_COMMISSION_OPTION];

  const handleSelectRewardTrigger = (value: string) => {
    if (
      value === RewardActionType.NavigateToSpecificPage &&
      commissionType === CommissionType.Percentage
    ) {
      setFieldValue('referralReward.commissionType', CommissionType.Fixed);
      if(!isEdit && !comissionAmountWasChanged) {
        setFieldValue('referralReward.commissionAmount', 25)
      }
    }
    
    setFieldValue('referralReward.rewardActionType', value);
  };

  return (
    <div>
      <SectionTitle
        title={sectionHeadings.INTRO_SECTION.title}
        tooltip={sectionHeadings.INTRO_SECTION.tooltip}
        tagname="h4"
      />
      <SectionDescription>
        What reward would you like to provide referrers for completing a
        successful action? This referral reward will only be rewarded to the
        referrer when a customer they refer visits your site and completes the
        action you define below.
      </SectionDescription>
      {integrationType !== IntegrationType.Shopify && (
        <FormGroup>
          <SectionTitle
            title={sectionHeadings.REWARD_ACTION_TYPE.title}
            tagname="h4"
          />
          <RewardTriggerEventSelect
            onSelect={handleSelectRewardTrigger}
            options={REFERRAL_REWARD_ACTIONS}
            value={rewardActionType || undefined}
          />
          <FieldFooter error={errors.referralReward?.rewardActionType} />
        </FormGroup>
      )}
      {rewardActionType && (
        <>
          <FormGroup>
            <SectionTitle
              title={sectionHeadings.REWARD_TYPE.title}
              tooltip={sectionHeadings.REWARD_TYPE.tooltip}
              id="referralReward.commissionType"
            />
            <CommissionFields>
              <CommissionTypeSection>
                <Select
                  onSelect={(value) => {
                    setFieldValue('referralReward.commissionType', value);
                    if(!isEdit && !comissionAmountWasChanged) {
                      setFieldValue('referralReward.commissionAmount', (value === FIXED_COMMISSION_OPTION.value) ? 25 : 10 )
                    }
                  }}
                  options={commissionTypeOptions}
                  value={commissionType || undefined}
                />

                <FieldFooter error={errors.referralReward?.commissionType} />
              </CommissionTypeSection>
              <div>
                <StyledRewardInput
                  name="referralReward.commissionAmount"
                  onChange={(value) => {
                    if(Number(value) !== values.referralReward.commissionAmount) {
                      setComissionAmountWasChanged(true);
                    }
                    setFieldValue('referralReward.commissionAmount', value)
                  }}
                  type={commissionType}
                  value={commissionAmount}
                />
                <FieldFooter error={errors.referralReward?.commissionAmount} />
              </div>
            </CommissionFields>
          </FormGroup>

          {rewardActionType === RewardActionType.CompleteAPurchase && (
            <FormGroup>
              <SectionTitle
                title={sectionHeadings.MINIMUM_PURCHASE_AMOUNT.title}
                tooltip={sectionHeadings.MINIMUM_PURCHASE_AMOUNT.tooltip}
                tagname="h4"
              />
              <Toggle
                options={MINIMUM_PURCHASE_AMOUNT_OPTIONS}
                onChange={(value) => {
                  setFieldValue(
                    'referralReward.isMinimumPurchaseAmountRequired',
                    value
                  );
                }}
                selectedValue={isMinimumPurchaseAmountRequired}
              />
              {isMinimumPurchaseAmountRequired && (
                <>
                  <MargedRewardInput
                    name="referralReward.minimumPurchaseAmount"
                    onChange={(value) =>
                      setFieldValue(
                        'referralReward.minimumPurchaseAmount',
                        value
                      )
                    }
                    type={ReferralRewardTypes.FIXED}
                    value={minimumPurchaseAmount}
                  />
                  <FieldFooter
                    error={errors.referralReward?.minimumPurchaseAmount}
                  />
                </>
              )}
            </FormGroup>
          )}

          {commissionType === CommissionType.Fixed && !!commissionAmount && (
            <FormGroup>
              <SectionTitle
                title={sectionHeadings.BUDGET_LIMIT.title}
                tooltip={sectionHeadings.BUDGET_LIMIT.tooltip}
                tagname="h4"
              />
              <Toggle
                options={BUDGET_LIMIT_OPTIONS}
                onChange={(value) => {
                  setFieldValue('referralReward.isBudgetLimited', value);
                }}
                selectedValue={isBudgetLimited}
              />
              {isBudgetLimited && (
                <>
                  <MargedRewardInput
                    name="referralReward.budget"
                    onChange={(value) =>
                      setFieldValue('referralReward.budget', value)
                    }
                    type={ReferralRewardTypes.FIXED}
                    value={budget}
                  />
                  <FieldFooter error={errors.referralReward?.budget} />
                </>
              )}
            </FormGroup>
          )}
        </>
      )}
    </div>
  );
};

export default ReferralReward;
