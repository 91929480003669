import * as yup from 'yup';
import { MIN_PASSWORD_LENGTH } from '../../constants/parameters';
import { emailRegExp } from 'constants/regExp';

const validationSchema = yup.object({
  email: yup
    .string()
    .test('invalid email', 'Invalid email address', function(value) {
      return !value || emailRegExp.test(value);
    })
    .required('Email is required'),
  password: yup
    .string()
    .min(
      MIN_PASSWORD_LENGTH,
      `At least ${MIN_PASSWORD_LENGTH} characters and no space`
    )
    .required('Password is required')
});

export type SignUpFormType = yup.InferType<typeof validationSchema>;

export default validationSchema;
