import styled from 'styled-components';
import { PrimaryButton } from 'uikit';
import { PreviewContainer } from '../../styles';

export const Container = styled(PreviewContainer)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	box-sizing: border-box;
	* {
		font-family: inherit !important;
	}
`;
export const Content = styled.div`

`;
export const Header = styled.pre`
	line-height: 21px;
	margin-bottom: 33px;
	font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1.33;
	white-space: break-spaces;
	overflow: hidden;
`;
export const BodyParagraph = styled.pre`
	font-size: ${({ theme }) => theme.size.body2};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1.57;
	white-space: break-spaces;
	overflow: hidden;
`;
export const MainButton = styled(PrimaryButton)`
	margin-bottom: 12px;
	width: 100%;
	cursor: pointer;
`;