import React, { FC, useState } from 'react';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { useGetBusinessRepresentationQuery } from '../../graphql';
import { CustomerView, DrummerView } from './components';
import { CUSTOMER_VIEW, PREVIEW_MODES, ViewModeType } from './constants';
import {
  BoldText,
  PhoneContainer,
  PreviewContainer,
  PreviewContent,
  PreviewHeader,
  Title,
  Representation,
  StyledToggle
} from './styles';

interface Props {
  showDescription: boolean;
  values: CreateOpportunityType;
}

const Preview: FC<Props> = ({ showDescription, values }) => {
  const { data } = useGetBusinessRepresentationQuery();
  const [previewMode, setPreviewMode] = useState<ViewModeType>(CUSTOMER_VIEW);

  return (
    <PreviewContainer>
      <PreviewHeader>
        {showDescription && <Title>Preview</Title>}
        <StyledToggle
          options={PREVIEW_MODES}
          onChange={(value: ViewModeType) => setPreviewMode(value)}
          selectedValue={previewMode}
        />
        {showDescription && (
          <Representation>
            {previewMode === CUSTOMER_VIEW ? (
              <>
                Below is a representation of the landing page a
                <BoldText>Customer</BoldText> will see when a referrer shares
                your opportunity.
              </>
            ) : (
              <>
                Below is a representation of what the
                <BoldText>Referrer</BoldText> will see in the Drum mobile app.
              </>
            )}
          </Representation>
        )}
      </PreviewHeader>
      <PhoneContainer previewMode={previewMode}>
        <PreviewContent>
          {previewMode === CUSTOMER_VIEW ? (
            <CustomerView companyInfo={data} values={values} />
          ) : (
            <DrummerView
              companyInfo={data}
              showDrummerMaterials
              values={values}
            />
          )}
        </PreviewContent>
      </PhoneContainer>
    </PreviewContainer>
  );
};

export default Preview;
