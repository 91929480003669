import React, { FC, ReactNode } from 'react';
import {
  BusinessInfo,
  Container,
  CompanyLogo,
  CompanyLogoWrapper,
  CompanyTitle,
  EarnAmount,
  EarnSection,
  EarnTitle,
  ExpirationDate,
  MainInfo,
  OpportunityButtons,
  OpportunityDetails,
  OpportunityTitle,
  OpportunityCover,
  OpportunityCoverPlaceholder,
  OpportunityDescription,
  OpportunityShareButton,
  Placeholder,
  StyledHeartIcon,
  StyledPlaneIcon
} from './styles';
import OpportunityCoverCarousel from 'components/OpportunityCoverCarousel';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import { GetBusinessRepresentationQuery, ImageStyleName } from '../../graphql';
import { OPPORTUNITY_FALLBACK_DATA } from 'components/OpportunityPreview/constants';
import ReferralRewardTypes from 'constants/referralRewardTypes';
import { TruncatedText } from 'uikit';
import { formatDate, getDrummerEarning, getFormattedEarning } from 'helpers';

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

export interface CompanyDetailsProps {
  images: string[] | null;
  logo: string | null;
  name: string;
  title: string;
  description: string;
}

const BUSINESS_NAME_PLACEHOLDER = 'Business Name';

interface Props {
  className?: string;
  children: ReactNode;
  companyDetails?: CompanyDetailsProps;
  // TODO: apply changes in all components that use this one and make prop required
  values?: CreateOpportunityType;
  showEarnings?: boolean;
  companyInfo?: GetBusinessRepresentationQuery | undefined;
  showShareButtons?: boolean;
}

const ShareOpportunityCard: FC<Props> = ({
  children,
  className,
  values,
  showEarnings,
  companyInfo,
  showShareButtons = true
}) => {
  // TODO: extract `getImages` and `getBusinessLogo` to `helpers` and use in other places of app
  const getImages = () =>
    (values?.opportunityDetails?.photos || [])
      .filter((img) => typeof img?.path === 'string')
      .map((photo) => `${S3_DOMAIN}/${photo.path}`);

  const getBusinessLogo = () => {
    const companyLogoData = companyInfo?.getCurrentBusiness?.coverImage?.styles?.find(
      (imageInfo) => {
        return imageInfo?.name === ImageStyleName.Square;
      }
    );

    return companyLogoData?.path
      ? `${S3_DOMAIN}/${companyLogoData.path}`
      : null;
  };

  const getEarningAmount = () => {
    if (!values?.referralReward.commissionAmount) {
      return `${ReferralRewardTypes.FIXED}${OPPORTUNITY_FALLBACK_DATA.earning}`;
    }

    const drummerEarning = getDrummerEarning(
      values.referralReward.commissionAmount
    );

    const formattedEarning = getFormattedEarning(
      values?.referralReward.commissionType,
      drummerEarning
    );

    return formattedEarning;
  };

  const businessLogo = getBusinessLogo();

  return (
    <Container className={className}>
      {showShareButtons && 
        <OpportunityButtons>
          <OpportunityShareButton type="button">
            <StyledPlaneIcon />
          </OpportunityShareButton>
          <OpportunityShareButton type="button">
            <StyledHeartIcon />
          </OpportunityShareButton>
        </OpportunityButtons>
      }

      <div>
        <OpportunityCover>
          {getImages()?.length ? (
            <OpportunityCoverCarousel
              images={getImages() as string[]}
            />
          ) : (
            <OpportunityCoverPlaceholder>
              <Placeholder>Cover Photo</Placeholder>
            </OpportunityCoverPlaceholder>
          )}
          <CompanyLogoWrapper>
            {businessLogo ? <CompanyLogo src={businessLogo} alt="" /> : 'Logo'}
          </CompanyLogoWrapper>
        </OpportunityCover>

        <BusinessInfo>
          <CompanyTitle>
            {companyInfo?.getCurrentBusiness?.businessName ||
              BUSINESS_NAME_PLACEHOLDER}
          </CompanyTitle>
          {showEarnings && (
            <EarnSection>
              <EarnTitle>Earn</EarnTitle>
              <EarnAmount>{getEarningAmount()}</EarnAmount>
            </EarnSection>
          )}
          {values?.opportunityDates?.endDate && (
            <ExpirationDate>
              Expires{' '}
              {formatDate(values.opportunityDates.endDate, 'MM/DD/YYYY')}
            </ExpirationDate>
          )}
        </BusinessInfo>
      </div>

      <OpportunityDetails>
        <MainInfo hasDescription={!!values?.opportunityDetails.description}>
          <OpportunityTitle>
            {values?.opportunityDetails?.title ||
              OPPORTUNITY_FALLBACK_DATA.title}
          </OpportunityTitle>
          <OpportunityDescription>
            <TruncatedText>
              {values?.opportunityDetails.description ||
                OPPORTUNITY_FALLBACK_DATA.description}
            </TruncatedText>
          </OpportunityDescription>
        </MainInfo>
        {children}
      </OpportunityDetails>
    </Container>
  );
};

export default ShareOpportunityCard;
