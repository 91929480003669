import styled from 'styled-components';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily.raleway};
  line-height: 1.25;
`;

export const Heading = styled.h2`
  margin-right: 170px;
  font-size: ${(props) => props.theme.size.subtitleLarge};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.black};
`;

export const SelectedTerm = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: #707070;
  text-transform: capitalize;

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;
