import routes from 'constants/routes';
import { CONTACT_SUPPORT_LINK_ALT } from 'constants/outerLinks';

const menu = [
  { to: routes.BUSINESS_PROFILE, title: 'Business Profile', linkName: 'businessProfileLink' },
  { to: routes.BILLING, title: 'Billing', linkName: 'billingLink' },
  { to: routes.SET_UP_CUSTOMER_TRACKING, title: 'Customer Tracking', linkName: 'customerTrackingLink' },
  { to: CONTACT_SUPPORT_LINK_ALT, title: 'Support', type: "outer", linkName: 'supportLink' }
];

export default menu;
