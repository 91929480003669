import React, { FC } from 'react';
import IconProps from './IconProps';

const FileIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="31"
      fill="none"
      viewBox="0 0 24 31"
    >
      <path
        fill="#17AACF"
        d="M16.09 8.83c-.67 0-1.21-.54-1.21-1.21V0H3.03C1.36 0 0 1.36 0 3.03v24.2a3.04 3.04 0 003.03 3.03h17.65a3.04 3.04 0 003.03-3.03V8.83h-7.62z"
      ></path>
    </svg>
  );
};

export default FileIcon;
