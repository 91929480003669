import React, { FC } from 'react';
import { AccentedText, Container, MainText } from './styles';

interface Props {
  accentedText: string;
  className?: string;
  mainText: string;
  to: string;
}

const AuthReturnLink: FC<Props> = ({
  accentedText,
  className,
  mainText,
  to
}) => (
  <Container className={className} to={to}>
    <MainText>
      {mainText}
      <AccentedText>{accentedText}</AccentedText>
    </MainText>
  </Container>
);

export default AuthReturnLink;
