import React, { FC } from 'react';
import { components, OptionProps } from 'react-select';
import { IOption } from '../../OpportunitySelect';
import { OptionName } from './styles';

const Option: FC<OptionProps<IOption>> = (props) => (
  <components.Option {...props}>
    <OptionName isActive={props.isSelected}>{props.data.title}</OptionName>
  </components.Option>
);

export default Option;
