import styled, { css } from 'styled-components';
import { InputTextMixin } from 'uikit';

const InputMixin = css`
  width: 100%;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  background: transparent;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  ${InputTextMixin};

  ::placeholder {
    color: ${({ theme }) => theme.colors.inputColor};
    font-style: italic;
  }

  :hover:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.accentMainColor};
  }

  :focus:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.inputColor};
  }
`;

export const InputBase = styled.input`
  ${InputMixin};
`;

export const TextareaBase = styled.textarea`
  ${InputMixin};
`;

export const InputFooter = styled.div`
  display: flex;
  align-self: center;
  margin-top: 5px;
`;
