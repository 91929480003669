import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { signUp, signIn } from 'services/auth';
import { fetchAuthData } from 'helpers/providerError';
import PasswordField from 'components/PasswordField';
import SignUpError, {
  Props as SignUpErrorProps
} from 'components/SignUpError/SignUpError';
import { FullScreenLoader, InputBase, FieldFooter } from 'uikit';
import {
  emailAlreadyExists,
  emailAlreadyExistsFromSocial,
  FALLBACK_AUTH_ERROR_MESSAGE,
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
  FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL,
  getAuthErrorTitleByType
} from 'constants/errors';
import validationSchema, { SignUpFormType } from './validationSchema';
import {
  Form,
  FormHeading,
  InputGroup,
  ReturnButton,
  SubmitButton
} from './styles';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  onReturnBack: () => void;
}

const SignUpEmailForm: FC<Props> = ({ onReturnBack }) => {
  const initialValues: SignUpFormType = {
    email: '',
    password: ''
  };
  const [modalData, setModalData] = useState<SignUpErrorProps>({
    title: FALLBACK_AUTH_ERROR_MESSAGE,
    text: ' ',
    isOpen: false,
    email: ''
  });
  const [authDataFeching, setAuthDataFeching] = useState<boolean>(false);

  const closeErrorModal = () => {
    setModalData({
      isOpen: false
    });
  };

  const handleSubmit = async (
    values: SignUpFormType,
    { setFieldError }: any
  ) => {
    const { email, password } = values;

    const result = await signUp(email?.toLowerCase().trim(), password.trim());

    if (result?.code) {
      const errorMessage =
        result.code === emailAlreadyExists.code
          ? emailAlreadyExists.message
          : FALLBACK_AUTH_ERROR_MESSAGE;
      switch (result?.code) {
        case emailAlreadyExists.code:
        case emailAlreadyExistsFromSocial.code:
          setAuthDataFeching(true);
          fetchAuthData(email, ({exists, provider}) => {
            const prov = (provider || '').toLowerCase();
            const text = prov === 'google' || prov === 'shopify' ? FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL : ''

            setAuthDataFeching(false);
            setModalData({
              ...modalData,
              email,
              title: exists ? getAuthErrorTitleByType(provider || '') : FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
              text: exists ? text : FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL,
              isOpen: true
            });
          }, () => {
            setAuthDataFeching(false);
            setModalData({
              ...modalData,
              email,
              title: FALLBACK_AUTH_ERROR_MESSAGE_MODAL,
              text: FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL,
              isOpen: true
            });
          })
          
          break;
        default:
          setModalData({
            ...modalData,
            email,
            isOpen: true,
            text: ''
          });
          break;
      }

      setFieldError('email', errorMessage);
    } else {
      await signIn(email?.toLowerCase().trim(), password?.trim());
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormHeading>Sign Up with Email</FormHeading>
          <ReturnButton onClick={onReturnBack} type="button">
            &lt; Back
          </ReturnButton>
          <InputGroup>
            <InputBase
              onChange={handleChange}
              name="email"
              type="text"
              placeholder="Email"
              value={values.email}
              id={trackedElementsIds.signUp.emailInput}
            />
            <FieldFooter error={errors.email} />
          </InputGroup>

          <InputGroup>
            <PasswordField
              onChange={handleChange}
              name="password"
              placeholder="Create Password"
              showPasswordStrength
              value={values.password}
            />
            <FieldFooter error={errors.password} />
          </InputGroup>

          {/* TODO: add captcha */}
          <SubmitButton
            // type="submit"
            disabled={isSubmitting}
            id={trackedElementsIds.signUp.signUpButton}
          >
            Sign Up
          </SubmitButton>

          {/* TODO: 1. Add styling for loader position 2. Replace custom loader with npm package */}
          {(isSubmitting || authDataFeching) && <FullScreenLoader />}

          <SignUpError {...modalData} onClose={closeErrorModal} />
        </Form>
      )}
    </Formik>
  );
};

export default SignUpEmailForm;
