import styled from 'styled-components';
import { darken } from 'polished';
import { breakpoint } from 'helpers';
import { Body1, ButtonBase, H3, Label, SecondaryButton } from 'uikit';
import Share from 'assets/svg/Share';

export const ShopifyForm = styled.form`
  border: solid 1px ${({ theme }) => theme.colors.borderColor};
  border-radius: 5px;

  ${breakpoint('md')`
    padding: 15px 25px 35px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 20px 20px 35px;
  `}
`;

export const FormHeader = styled(H3)`
  margin-bottom: 30px;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 10px;
`;

export const InputGroup = styled.div`
  ${breakpoint('md')`
    margin-bottom: 50px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
  `}
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SHOPIFY_COLOR = '#a0c743';

export const ConnectButton = styled(ButtonBase)`
  margin-right: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${SHOPIFY_COLOR};

  :hover {
    background-color: ${darken(0.1, SHOPIFY_COLOR)};
  }

  :active {
    background-color: ${darken(0.2, SHOPIFY_COLOR)};
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.borderColor};
  }

  ${breakpoint('xs', 'sm')`
    flex: 1;
  `}
`;

export const StyledShareIcon = styled(Share)`
  margin-left: 6px;
  vertical-align: text-top;

  path {
    fill: currentColor;
  }
`;

export const ContactSupportSection = styled.div`
  ${breakpoint('md')`
    margin-top: 70px;
  `}

  ${breakpoint('xs', 'md')`
    margin-top: 25px;
  `}
`;

export const ContactSupportDescription = styled(Body1)`
  margin-bottom: 15px;
`;

export const ContactSupportLink = styled(SecondaryButton)`
  display: inline-block;
  text-align: center;

  ${breakpoint('md')`
    padding-right: 35px;
    padding-left: 35px;
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
`;
