import styled from 'styled-components';
import { OutlinedButton, H2, LinkTextMixin, Link } from 'uikit';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;
export const HeaderBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 25px 20px 40px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-bottom: none;

  ${breakpoint('xs', 'lg')`
    padding: 20px 15px 20px 15px;
  `}
`;
export const HeaderTitle = styled(H2)`
`;
export const HeaderLink = styled(Link)`
  ${LinkTextMixin};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.main};
`;
export const Content = styled.div`
  
`;
export const CreateCampaingContainer = styled.div`
  padding: 40px 45px;
  ${breakpoint('xs', 'lg')`
    padding: 20px 15px;
  `}
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: none;
`;
export const CreateCampaingTextContainer = styled.div`
  
`;

export const CreateTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  margin-bottom: 16px;
`;
export const CreateText = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  margin-bottom: 95px;
  ${breakpoint('xs', 'lg')`
    margin-bottom: 25px;
  `}
`;
export const TemplateListContainer = styled.div`
  
`;
export const TemplateList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;
export const TemplateListMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  ${breakpoint('lg')`
    display: none;
  `}
`;
export const TemplateItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  height: 590px;

  ${breakpoint('lg')`
    max-width: 24%;
  `}
`;
export const TemplateTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.pink};
`;
export const TemplateSubtitle = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  color: #7c7c7c;
`;
export const TemplateContent = styled.div`
  transform: scale(0.44);
  transform-origin: top;
  margin-top: 13px;
  border: 2px solid #B7B7B7;
  position: relative;
  width: 600px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .container {
    min-height: 970px;
  }
`;
export const TemplatePreviewButton = styled(OutlinedButton)`
  width: 232px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.accentMainColor};
    border-color: ${({ theme }) => theme.colors.accentMainColor};
  }
`;