import React from 'react';
import { Container, SupportLink, ErrorBlock, ImageBlock, Paragraph } from './styled';
import {
  CONTACT_SUPPORT_LINK
} from 'constants/outerLinks'

interface Props {
  className?: string;
}

const ErrorContainer: React.FC<Props> = ({ className, children }) => (
  <Container className={className}>
    <ImageBlock>
      <img src="/assets/error.png" alt="error" />
    </ImageBlock>
    <ErrorBlock>
      {children ? (
        children
      ) : (
        <div>
          <Paragraph>
            It appears that Drum is unable to connect with your website. This
            means Drum cannot track where a customer came from and if they
            completed the desired task on your site. We have temporarily disabled
            your opportunities until tracking is restored.
          </Paragraph>
          {/* TODO: define support link behaviour */}
          <Paragraph>
            Please try to reinstall the customer tracking code. If you have any
            problems or questions, please{' '}
            <SupportLink target="_blank" href={CONTACT_SUPPORT_LINK}>contact customer support.</SupportLink>
          </Paragraph>
        </div>
      )}
    </ErrorBlock>
  </Container>
);

export default ErrorContainer;
