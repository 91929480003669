import React from 'react';
import { useField } from 'formik';
import { RadioFieldProp } from '../../types';
import {
  RadioContainer,
  Radio,
  RadioLabel,
  CheckMark
} from './styled';

const RadioButton: React.FC<RadioFieldProp> = ({
  name,
  label,
  id,
  className,
  labelClass,
  disabled,
  children
}) => {
  const [field] = useField<string>(name);
  const inputProps = {
    ...field
  };

  return (
    <RadioContainer>
      <Radio disabled={disabled} {...inputProps} className={className} id={id} type="radio" value={id} checked={field.value === id}/>
      <CheckMark className={field.value?.toString() === id ? 'checked' : ''}/>
      {label && <RadioLabel className={`${labelClass} ${field.value === id ? 'checked' : ''}`} htmlFor={id}>{label}</RadioLabel>}
      {children && <RadioLabel className={field.value?.toString() === id ? 'checked' : ''} htmlFor={id}>{children}</RadioLabel>}
    </RadioContainer>
  );
};

export default RadioButton;
