import styled from 'styled-components';
import { Body2 } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  box-shadow: 0px 2px 7px rgba(33, 45, 65, 0.14);
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 98px;
  overflow: hidden;
  margin-bottom: 9px;
`;
export const ImageContainer = styled.div`
  width: 91px;
  height: 100%;
  min-width: 100px;
  position: relative;
  overflow: hidden;
`;
export const CoverImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -100%;
  right: -100%;
  height: 100%;
`;
export const ImageSubstitute = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'Arial';
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
  background: ${({ theme }) => theme.colors.lightGrey};
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-left: -15px;
  padding: 13px 15px;
  background-color: ${({ theme }) => theme.colors.white};
  align-self: stretch;
  border-radius: 8px 0 0 8px;
`;
export const ContentLeft = styled.div`
  max-width: 100%;
`;
export const ContentRight = styled.div`
  
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 34px;
  overflow: hidden;
  margin-bottom: 5px;
`;
export const SmallImageContainer = styled.div`
  width: 19px;
  min-width: 19px;
  height: 19px;
  border-radius: 4px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
`;
export const SmallImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -100%;
  right: -100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.white};
`;
export const SmallImageSubstitute = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'Arial';
  font-weight: bold;
  font-size: 7px;
  color: ${({ theme }) => theme.colors.bodyTextColor};
  background: ${({ theme }) => theme.colors.lightGrey};
`;
export const Title = styled(Body2)`
  line-height: 15px;
  max-height: 30px;
`;
export const Text = styled(Body2)`
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 14px;
`;
export const AmountContent = styled.div`
  width: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
`;
export const AmountContentText = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 13px;
  color: ${({ theme }) => theme.colors.pink};
`;
export const Amount = styled(AmountContentText)`
  line-height: 17px;
  font-size: ${({ theme }) => theme.size.body1};
`;
