import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { lighten } from 'polished';
import { ProfileIcon } from 'assets/svg';
import { ITheme } from 'types';
import { breakpoint } from 'helpers';
import { WidgetContainer } from 'uikit';

export const Container = styled.div`
  position: relative;
`;

export const ProfileButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
`;

interface ProfileIconProps extends ITheme {
  isDashboardPage: boolean;
}

export const StyledProfileIcon = styled(ProfileIcon)<ProfileIconProps>`
  /* TODO: replace this icon by valid one that can be recolored via CSS */
  & path {
    stroke: ${({ isDashboardPage, theme }) =>
      isDashboardPage ? theme.colors.black : theme.colors.white};

    ${breakpoint('xs', 'xl')`
      stroke: ${({ theme }) => theme.colors.black};
    `}
  }
`;

export const MenuContainer = styled(WidgetContainer)`
  position: absolute;
  box-sizing: border-box;
  z-index: 100;
  top: 100%;
  right: 0;
  width: 300px;
  padding: 23px 0 13px 0;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  
`;
export const ArrowContainer = styled.div`
  position: absolute;
  top: 34px;
  right: 24px;

  svg {
    width: 27px;
    height: 27px;
  }
`;
export const LinkTextMixin = css`
  display: block;
  padding: 13px 23px 13px 29px;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: ${({ theme }) =>
      lighten(0.5, theme.colors.accentMainColor)};
  }

  &.active {
    color: ${({ theme }) => theme.colors.accentMainColor};
  }
`;


export const Link = styled(NavLink)`
  ${LinkTextMixin}
`;
export const OuterLink = styled.a`
  ${LinkTextMixin}
`;

export const Button = styled.button`
  width: 100%;
  
  border: none;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.black};

  :hover {
    background-color: ${({ theme }) =>
      lighten(0.5, theme.colors.accentMainColor)};
  }
  span {
    display: block;
    border-top: 1px solid #dcdcdc;
    margin: 0 23px 0 29px;
    padding: 13px 0;
    box-sizing: border-box;
  }
`;

export const ProfileInfo = styled.div`
  margin: 0 23px 2px 29px;
  border-bottom: 1px solid #dcdcdc;
`;
export const ProfileInfoMain = styled.div`
  display: flex;
  flex-direction: row;
`;
export const AvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f0f0f0;
`;
export const Avatar = styled.img`
  max-width: 100%;
  height: auto;
`;
export const AvatarSubstitute = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  color: #A8A8A8;
`;
export const BusinessName = styled.p`
  flex: 1;
  box-sizing: border-box;
  padding: 4px 11px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 21px;
  height: 50px;
  overflow: hidden;
`;
export const BusinessEmail = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  height: 21px;
  line-height: 21px;
  margin:7px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;


