import styled from 'styled-components';
import { H4 } from 'uikit';
import { breakpoint } from '../../helpers';
import InviteDrummerHeader from '../../components/InviteDrummerHeader';
import SmallOpportunityCard from 'components/SmallOpportunityCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoint('md')`
    padding: 0 30px;
  `}
`;

export const Content = styled.div`
  flex: 1 0 auto;
`;

export const StyledHeader = styled(InviteDrummerHeader)`
  margin-bottom: 40px;
`;

export const PageBody = styled.section`
  ${breakpoint('md')`
    max-width: 375px;
    margin: 0 auto;
    padding-bottom: 30px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 0 35px 20px;
  `}
`;

export const OpportunityListTitle = styled(H4)`
  ${breakpoint('md')`
    margin-bottom: 20px;
    text-align: center;
  `}

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;

export const OpportunitiesContainer = styled.div`
  margin-bottom: 35px;
`;

export const StyledOpportunityCard = styled(SmallOpportunityCard)`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const DownloadSection = styled.section`
  text-align: center;
`;

export const DownloadLabel = styled.h2`
  margin-bottom: 25px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

export const DownloadLink = styled.a`
  display: inline-block;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentMainColor};
  border-radius: 21px;

  :hover {
    background-color: #0086a8;
  }

  :active {
    background-color: #005d75;
  }

  ${breakpoint('md')`
    padding: 12px 70px;
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
    padding: 12px 0;
  `}
`;

export const StoreLinkContainer = styled.div`
  text-align: center;
`;

export const StoreLink = styled.a`
  display: inline-block;
  width: 150px;

  :not(:last-child) {
    margin-right: 10px;
  }
`;

export const StoreLinkImage = styled.img`
  max-width: 100%;
  height: auto;
`;
