import React from 'react';
import GeneralLayout from 'layouts/GeneralLayout';
import SetUpCustomerTracking from './SetUpCustomerTracking';
import FAQ from '../FAQ';

const SetUpCustomerTrackingPage: React.FC = () => (
  <GeneralLayout sidebar={<FAQ preset="tracking"/>}>
    <SetUpCustomerTracking />
  </GeneralLayout>
);

export default SetUpCustomerTrackingPage;
