import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Menu, { MenuItem } from 'rc-menu';
import cn from 'classnames';
import { ButtonContainer, MenuButton, StyledChevron } from './styles';
import './styles.scss';

interface Props {
  className?: string;
  options: { name: string; onClick: () => void }[];
}

const DashboardOpportunityMenu: FC<Props> = ({ className, options }) => {
  const [isMenuOpen, setDisplayMenu] = useState(false);

  return options.length ? (
    <div className={className}>
      <OutsideClickHandler onOutsideClick={() => setDisplayMenu(false)}>
        <ButtonContainer>
          <StyledChevron isMenuOpen={isMenuOpen} />
          <MenuButton onClick={() => setDisplayMenu(!isMenuOpen)} type="button">
            Options
          </MenuButton>

          <Menu
            className={cn(
              'opportunity-menu',
              !isMenuOpen && 'opportunity-menu_hidden'
            )}
          >
            {options.map(({ onClick, name }) => (
              <MenuItem
                className="opportunity-menu__option"
                key={name}
                onClick={() => {
                  setDisplayMenu(false);
                  onClick();
                }}
              >
                {name}
              </MenuItem>
            ))}
          </Menu>
        </ButtonContainer>
      </OutsideClickHandler>
    </div>
  ) : null;
};

export default DashboardOpportunityMenu;
