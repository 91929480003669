import React, { FC } from 'react';
import { Price } from './styles';

interface Props {
  value?: number | string;
}

const currency = '$';
const placeholder = 'X';

const FormattedPrice: FC<Props> = ({ value }) => (
  <Price>{`${currency}${value === undefined || value === null ? placeholder : value}`}</Price>
);

export default FormattedPrice;
