import React, { FC } from 'react';
import CsvUpload from 'components/CsvUpload';
import { CSV_TEMPLATE_ADDRESS } from 'constants/customersCSV';
import { Container, TextContainer, Title, Paragraph, Warning, StyledLink } from './styles';
import { IUploadError } from 'components/CsvUpload/helpers/fileUploadErrors';
import { UploadCustomersStats } from 'graphql';

interface Props {
  className?: string;
  onSubmitSuccess?: (data?: UploadCustomersStats, fileName?: string) => void;
  onSubmitError?: (error: IUploadError) => void;
  onDropError?: (error: IUploadError) => void;
}

const ImportFile: FC<Props> = ({ className, onSubmitSuccess, onSubmitError, onDropError }) => (
  <Container className={className}>
    <TextContainer>
      <Title>Upload Customer .CSV File</Title>
      <Paragraph>
        Drum allows you to easily upload your customer list, invite them via email
        and remind them periodically about your live opportunities. Simply upload
        a .CSV file containing all the customers you wish to be part of your Drum
        referral program.
      </Paragraph>
      <Paragraph>
        <Warning>Important:</Warning> Use this <StyledLink href={CSV_TEMPLATE_ADDRESS}>.CSV template</StyledLink> to ensure the correct format and
        successful import.
      </Paragraph>
    </TextContainer>
    <CsvUpload
      onSubmitSuccess={onSubmitSuccess}
      onSubmitError={onSubmitError}
      onDropError={onDropError}
    />
  </Container>
);

export default ImportFile;
