import styled, { Theme } from 'styled-components';
import { breakpoint } from '../../helpers';
import { WidgetContainerMixin } from 'uikit';

interface WidgetContainerProps {
  theme: Theme;
  isDisabled?: boolean;
}

const WidgetContainer = styled.div<WidgetContainerProps>`
  background-color: ${({ theme }) => theme.colors.white};

  /* Use grayscale filter on descendants, not on container,
  * because we need to leave ability to override it on separate elements.
  */

  & * {
    filter: ${({ isDisabled }) => isDisabled && 'grayscale(1)'};
  }

  ${breakpoint('md')`
    ${WidgetContainerMixin}
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
    ${WidgetContainerMixin}

    &:not(:first-child, :last-child) {
      margin-bottom: 15px;
    }
  `}
`;

export default WidgetContainer;
