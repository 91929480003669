import styled from 'styled-components';
import { LinkTextMixin, Body3 } from 'uikit';
import { breakpoint } from 'helpers';

export const Container = styled.div`

`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 16px;
  margin: 0 4px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 52px;
  margin-bottom: 10px;

  ${breakpoint('xs', 'lg')`
    display: block;
    min-height: auto;
  `}
`;

export const RadioLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${breakpoint('xs', 'md')`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;
export const CustomersNumberLabel = styled.span`
  margin-right: 5px;
`;

export const SelectedTagsContainer = styled.div`
  margin-top: 5px;

  ${breakpoint('xs', 'lg')`
    padding-left: 43px;
  `}
  
`;
export const SelectedTagsContainerInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`;
export const TagName = styled(Body3)`
  margin-right: 5px;
`;

export const SelectedTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  padding: 10px 10px 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const RemoveTagButton = styled.button`
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
`;

export const TagFieldContainer = styled.div`
  margin-left:  15px;

  ${breakpoint('xs', 'lg')`
    margin-left:  0;
    margin-top:  12px;
    padding-left: 43px;
  `}
`;
export const RadioLabelText = styled.span`
  display: inline-block;
`;