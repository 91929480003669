import * as yup from 'yup';
import { FIELD_MAX_LENGTH } from 'constants/fieldLengthLimits';
import { CATEGORIES_MAX_SELECTION } from './constants';
import { phoneRegExp } from 'constants/regExp';

const businessProfileFormSchema = yup.object({
  businessName: yup
    .string()
    .required('Business name is required')
    .max(FIELD_MAX_LENGTH, 'Only 80 characters allowed'),
    
  // TODO: get rid of array
  logo: yup.array().of(
    yup.object({
      name: yup.string().nullable(),
      path: yup.string().nullable()
    })
    
  ).required('Logo is required'),
  category: yup
    .array()
    .of(yup.object().shape({ id: yup.string(), name: yup.string() }))
    .required('At least one category is required')
    .max(CATEGORIES_MAX_SELECTION, 'You cannot pick up more than 5 categories'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid')
});

export type BusinessProfileFormType = yup.InferType<
  typeof businessProfileFormSchema
>;

export default businessProfileFormSchema;
