import styled, { CSSProperties, css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { breakpoint } from 'helpers';

export const linkMixin = css`
  display: flex;
  flex: 1;
  justify-content: center;

  ${breakpoint('xs', 'xl')`
    color: ${({ theme }) => theme.colors.black};
  `}

  ${breakpoint('xl')`
    color: ${({ theme }) => theme.colors.black};
  `}

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.pink};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  &.white {
    ${breakpoint('xl')`
      color: ${({ theme }) => theme.colors.white}
    `}

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colors.pink};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }

  :not(:last-child) {
    ${breakpoint('xs', 'sm')`
      margin-right: 10px;
    `}
  }

  ${breakpoint('xs', 'sm')`
    font-size: ${({ theme }) => theme.size.caption};
  `}

  ${breakpoint('sm')`
    font-size: ${({ theme }) => theme.size.body1};
  `}
`;

export const Container = styled.nav`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'md')` 
    width: 100%;
    justify-content: flex-end;
  `}
`;

export const Links = styled.div`
  ${breakpoint('xs', 'sm')`
    margin-right: 15px;
    width: 100%;
    display: flex;
  `}

  ${breakpoint('sm')`
    display: flex;
    width: 100%;
    min-width: 400px;
    margin-right: 10px;
  `}

  ${breakpoint('xl', 'xxl')`
    min-width: 300px;
  `}

  &.isDashboard {
    ${breakpoint('sm')`
      min-width: 400px;
    `}
  }
  text-align: right;
`;

export const StyledLink = styled(NavLink)`
  ${linkMixin}
`;
export const StyledOuterLink = styled.a`
  ${linkMixin}
`;

export const activeLinkStyle: CSSProperties = {
  fontWeight: 800
};
