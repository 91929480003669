import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { PrimaryButton, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 25px 0 25px 50px;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 10px;
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 58px;
  margin-bottom: 30px;

  ${breakpoint('xs', 'lg')`
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 20px;
  `}
  
`;
export const InfoLeft = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    align-items: flex-start;
  `}
`;
export const IconContainer = styled.div`
  margin-right: 20px;
`;
export const InfoText = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 26px;
  max-width: 600px;
`;
export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.accentMainColor};
`;
export const InfoRight = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
  `}
`;
export const CreateButton = styled(PrimaryButton)`
  width: 232px;
  font-weight: normal;

  ${breakpoint('xs', 'md')`
    width: 100%;
  `}
  ${breakpoint('xs', 'lg')`
    height: 56px;
    border-radius: 38px;
  `}
`;

export const TableContainer = styled.div`
  min-height: 500px;
`;

export const TableHeader = styled.div`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 30px;
`;

export const PaginationContainer = styled.div`
  margin: 20px 38px 20px 0;
`;
