import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { FullScreenLoader, Modal } from 'uikit';
import { Formik, FormikErrors } from 'formik';
import { Eye } from 'assets/svg';
import routes from 'constants/routes';
import { getProfileStatus } from 'helpers';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  REDIRECT_PROMPT_TITLE,
  REDIRECT_PROMPT_TEXT,
  REDIRECT_PROMPT_CANCEL,
  REDIRECT_PROMPT_OK
} from 'constants/promptText';
import {
  CUSTOMER_TAB_NAME_PARAM,
  CUSTOMER_TAB_NAME_REFERRAL_PAGE
} from 'constants/customersQueryParams';
import { SHOULD_REDIRECT_TO_REFERRAL_PAGE_AFTER_SHOPIFY_FLAG } from 'constants/referralPageShopify';
import { EDIT_MODE_PARAM } from 'constants/referralQueryParams';
import DashboardPageLayout from 'layouts/DashboardPageLayout';
import CreationProgress from 'components/CreationProgress';
import validationSchema, {
  PageFormType
} from 'components/ReferralReward/validationSchema';
import ReferralPreviewContainer from 'components/ReferralReward/components/ReferralPreviewContainer';
import PreviewModal from 'components/ReferralReward/components/PreviewModal';
import { pageType } from 'components/ReferralReward/types';
import DefineAndPreviewForm from './components/DefineAndPreviewForm';
import ReviewAndPublishForm from './components/ReviewAndPublishForm';
import CompletionSuccessModal from './components/CompletionSuccessModal';
import CodeVerification from './components/CodeVerification';
import './styles.scss';

import {
  convertToFormValues,
  getTemplate,
  normalizeFormValues,
  getLogoURL
} from 'helpers/referralWebpage';

import {
  useGetReferralPageQuery,
  useListOpportunitiesQuery,
  useGetBusinessDetailsQuery,
  useUpdateReferralPageMutation,
  useCreateShopifyReferralPageMutation,
  UpdateReferralPageMutation,
  CreateShopifyReferralPageMutation,
  ReferralPage,
  OfferStatus,
  Offer,
  ReferralPageStateConfigPageStyleBackgroundType,
  IntegrationType
} from '../../graphql';
import {
  Container,
  StepsContainer,
  ToggleContainer,
  StyledToggle,
  Content,
  LeftPanel,
  RightPanel,
  RightPanelInner,
  FormContainer,
  PreviewButton,
  ModalParagraph
} from './styles';
import {
  DESKTOP_VIEW,
  PREVIEW_MODES,
  ViewModeType,
  MOBILE_VIEW,
  PROGRESS_STEPS
} from './constants';
import {
  IParamTypes,
  IFormValues,
  IValidate,
  IProgressStepChanger
} from './types';

interface ShopifyPermissionData {
  isOpen: boolean;
  link: string;
}

const ReferralWebpage: FC<RouteComponentProps> = ({ history }) => {
  let { mode: creationMode } = useParams<IParamTypes>();

  /* -- STATES -- */

  const [formValues, setFormValues] = useState<any>(null);
  const [previewMode, setPreviewMode] = useState<ViewModeType>(DESKTOP_VIEW);
  const [currentProgressStep, setCurrentProgressStep] = useState<number>(1);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [
    isCreateShopifyPageModalOpen,
    setIsCreateShopifyPageModalOpen
  ] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [
    shopifyPermissionErrorModalData,
    setShopifyPermissionErrorModalData
  ] = useState<ShopifyPermissionData>({
    isOpen: false,
    link: ''
  });
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [isValidationErrorModalOpen, setValidationErrorModal] = useState(false);
  const [isNoProfileModalOpen, setIsNoProfileModalOpen] = useState<boolean>(
    false
  );
  const [isNoOffersModalOpen, setIsNoOffersModalOpen] = useState<boolean>(
    false
  );
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [updatedReferralPageData, setUpdatedReferralPageData] = useState<
    UpdateReferralPageMutation | undefined
  >(undefined);
  const [valuesBuffer, setValuesBuffer] = useState<any>(undefined);
  const [errorAccordionsOpen, setErrorAccordionsOpen] = useState(false);

  /* -- REQUESTS -- */

  const {
    data: businessProfileData,
    loading: businessProfileDataLoading
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  const {
    data: referralPageData,
    loading: referralPageDataLoading
  } = useGetReferralPageQuery({
    fetchPolicy: 'no-cache'
  });

  const {
    data: opportunitiesList,
    loading: opportunitiesListLoading
  } = useListOpportunitiesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          status: {
            eq: OfferStatus.Active
          }
        },
        pagination: {
          limit: 1
        }
      }
    }
  });

  const [
    createShopifyReferralPage,
    { loading: isCreateShopifyReferralPageLoading }
  ] = useCreateShopifyReferralPageMutation({
    onCompleted: (data: CreateShopifyReferralPageMutation) => {
      if (data?.createShopifyReferralPage?.__typename === 'ErrorResponse') {
        if (
          data.createShopifyReferralPage.code ===
          'DrumError::postShopifyPage.forbidden'
        ) {
          const linkItem = (
            data.createShopifyReferralPage.detailFields || []
          ).find((item) => item.key === 'linkToScopesUpdate');
          setShopifyPermissionErrorModalData({
            isOpen: true,
            link: linkItem?.value || ''
          });
          saveForm(valuesBuffer);
          setValuesBuffer(undefined);
        } else {
          setIsErrorModalOpen(true);
        }
      } else {
        saveForm(valuesBuffer);
        setValuesBuffer(undefined);
      }
    },
    onError: () => {
      setIsErrorModalOpen(true);
    }
  });

  const [
    updateReferralPage,
    { loading: isUpdateReferralPageLoading }
  ] = useUpdateReferralPageMutation({
    onCompleted: (data: UpdateReferralPageMutation) => {
      setUpdatedReferralPageData(data);
      setIsSuccessModalOpen(true);
    },
    onError: () => {
      setIsErrorModalOpen(true);
    }
  });

  /* -- METHODS -- */
  const validate: IValidate = (
    validateForm,
    setSubmitting,
    values,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;
      setSubmitting(false);
      hasErrors ? errorCallback() : successCallback();
    });
  };

  //Redirect to step 1
  const redirectToDefineAndPrewiew: IFormValues = (
    validateForm,
    setSubmitting,
    values
  ) => {
    validate(
      validateForm,
      setSubmitting,
      values,
      () => setCurrentProgressStep(1),
      () => setValidationErrorModal(true)
    );
  };
  //Redirect to step 2
  const redirectToReviewAndPublish: IFormValues = (
    validateForm,
    setSubmitting,
    values
  ) => {
    handleDefineAndPreviewFinish(validateForm, setSubmitting, values);
  };

  const handleDefineAndPreviewFinish: IFormValues = (
    validateForm,
    setSubmitting,
    values
  ) => {
    validate(
      validateForm,
      setSubmitting,
      values,
      () => {
        creationMode === EDIT_MODE_PARAM
          ? setIsSaveModalOpen(true)
          : setCurrentProgressStep(2);
      },
      () => setValidationErrorModal(true)
    );
  };

  const handleCreateShopifyPage: IFormValues = (
    validateForm,
    setSubmitting,
    values
  ) => {
    validate(
      validateForm,
      setSubmitting,
      values,
      () => {
        const val = normalizeFormValues(values);
        setValuesBuffer(val);
        createShopifyReferralPage({
          variables: {
            input: {
              pageTitle: val?.shopifyPageConfig?.title,
              hidden: val?.shopifyPageConfig?.isHidden
            }
          }
        });
      },
      () => setValidationErrorModal(true)
    );
  };

  const handleSave: IFormValues = (validateForm, setSubmitting, values) => {
    validate(
      validateForm,
      setSubmitting,
      values,
      () => saveForm(values), //SAVE FORM HERE
      () => setValidationErrorModal(true)
    );
  };

  const saveForm = (values: PageFormType) => {
    const input = normalizeFormValues(values);

    if (
      input.pageStyle.background.backgroundImage ===
      opportunitiesList?.listOffers?.items?.[0]?.coverPhoto?.styles?.[0]?.path
    ) {
      input.pageStyle.background.backgroundImage = null;
    }

    updateReferralPage({
      variables: {
        input
      }
    });
  };

  const redirectToReferralPageTab = () => {
    history.push(
      `${routes.CUSTOMERS_MANAGEMENT}?${CUSTOMER_TAB_NAME_PARAM}=${CUSTOMER_TAB_NAME_REFERRAL_PAGE}`
    );
  };

  const handleDefineAndPreviewCancel = () => {
    redirectToReferralPageTab();
  };
  const canFinishDefineAndPreview = () => {
    //TODO: Add condition here if needed
    return true;
  };

  const handleProgressStepClick: IProgressStepChanger = (
    index,
    validateForm,
    setSubmitting,
    values
  ) => {
    if (index === currentProgressStep) return;
    if (index === 1) {
      redirectToDefineAndPrewiew(validateForm, setSubmitting, values);
    } else if (index === 2) {
      redirectToReviewAndPublish(validateForm, setSubmitting, values);
    }
  };
  const handleOpenShopifyClick = () => {
    if (shopifyPermissionErrorModalData.link) {
      localStorage.setItem(
        SHOULD_REDIRECT_TO_REFERRAL_PAGE_AFTER_SHOPIFY_FLAG,
        'true'
      );
      window.open(shopifyPermissionErrorModalData.link, '_blank');
    } else {
      setShopifyPermissionErrorModalData({
        ...shopifyPermissionErrorModalData,
        isOpen: false
      });
    }
  };

  const handleReviewBack = () => {
    setCurrentProgressStep(1);
  };
  const handlePreviewShopify = (values: PageFormType) => {
    const data = values.shopifyPageConfig;
    const link =
      data?.isHidden === true || data?.isHidden === 'true'
        ? data?.adminPageUrl
        : data?.pageUrl;
    link && window.open(link, '_blank');
  };
  const handleReviewAndPublishSave: IFormValues = (
    validateForm,
    setSubmitting,
    values
  ) => {
    if (
      businessProfileData?.getCurrentBusiness?.integrationType ===
        IntegrationType.Shopify &&
      !values?.shopifyPageConfig?.userDidCreateTheShopifyPage
    ) {
      setIsCreateShopifyPageModalOpen(true);
    } else {
      setIsSaveModalOpen(true);
    }
  };
  const handleSuccessModalClose = () => {
    redirectToReferralPageTab();
  };
  const handleSuccessModalMainClick = () => {
    redirectToReferralPageTab();
  };
  const handleSuccessModalShopifyMainClick = () => {
    const data = (updatedReferralPageData?.updateReferralPage as any)
      ?.shopifyPageConfig;
    const link =
      data?.isHidden === true || data?.isHidden === 'true'
        ? data?.adminPageUrl
        : data?.pageUrl;
    link && window.open(link, '_blank');
  };
  const handleSuccessModalShopifySecondaryClick = () => {
    redirectToReferralPageTab();
  };
  const handleVerificationClick = () => {
    setIsVerificationModalOpen(true);
  };
  
  const onMissingFieldsClick = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, errors: FormikErrors<PageFormType>) => {
    setValidationErrorModal(false);
    setErrorAccordionsOpen(true);
    if(errors.states?.join) {
      setFieldValue('pageType', pageType.join);
    } else if(errors.states?.share) {
      setFieldValue('pageType', pageType.share);
    } else if(errors.states?.thankYou) {
      setFieldValue('pageType', pageType.thankYou);
    }
    setTimeout(() => {
      setErrorAccordionsOpen(false);
    });
  };

  /* -- EFFECTS -- */

  useEffect(() => {
    if (
      opportunitiesList?.listOffers &&
      referralPageData?.referralPage &&
      businessProfileData?.getCurrentBusiness
    ) {
      const { completeProfile, setupBilling, setupTracking } = getProfileStatus(
        businessProfileData
      );
      const isProfileCompleted =
        completeProfile.isCompleted &&
        setupBilling.isCompleted &&
        setupTracking.isCompleted;
      const hasOpportunity = !!opportunitiesList?.listOffers?.items?.length;
      if (!isProfileCompleted) {
        setIsNoProfileModalOpen(true);
        return;
      } else if (!hasOpportunity) {
        setIsNoOffersModalOpen(true);
        return;
      }
      const data = convertToFormValues(
        referralPageData.referralPage as ReferralPage
      ) as PageFormType;

      if (!data.pageStyle.background.backgroundImage) {
        data.pageStyle.background.backgroundImage =
          opportunitiesList.listOffers?.items?.[0]?.coverPhoto?.styles?.[0]
            ?.path || '';
      }

      setFormValues(data);
    }
  }, [referralPageData, opportunitiesList, businessProfileData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentProgressStep]);

  return (
    <DashboardPageLayout>
      <Container>
        {formValues && (
          <Formik
            enableReinitialize
            initialValues={formValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={() => {}}
          >
            {({
              handleSubmit,
              values,
              validateForm,
              setSubmitting,
              dirty,
              setFieldValue,
              errors
            }) => (
              <>
                {creationMode !== EDIT_MODE_PARAM && (
                  <StepsContainer>
                    <CreationProgress
                      steps={PROGRESS_STEPS}
                      currentStep={currentProgressStep}
                      onStepClick={(index) =>
                        handleProgressStepClick(
                          index,
                          validateForm,
                          setSubmitting,
                          values
                        )
                      }
                    />
                  </StepsContainer>
                )}

                <ToggleContainer>
                  <StyledToggle
                    options={PREVIEW_MODES}
                    onChange={(value: ViewModeType) => setPreviewMode(value)}
                    selectedValue={previewMode}
                  />
                </ToggleContainer>
                <Content>
                  <LeftPanel>
                    <form onSubmit={handleSubmit}>
                      <FormContainer>
                        {currentProgressStep === 1 && (
                          <DefineAndPreviewForm
                            canSave={canFinishDefineAndPreview()}
                            onSave={handleDefineAndPreviewFinish}
                            onCancel={handleDefineAndPreviewCancel}
                            isEdit={creationMode === EDIT_MODE_PARAM}
                            openAccordionCheck={errorAccordionsOpen}
                          ></DefineAndPreviewForm>
                        )}
                        {currentProgressStep === 2 &&
                          creationMode !== EDIT_MODE_PARAM && (
                            <ReviewAndPublishForm
                              onSave={handleReviewAndPublishSave}
                              isShopify={
                                businessProfileData?.getCurrentBusiness
                                  ?.integrationType === IntegrationType.Shopify
                              }
                              onBack={handleReviewBack}
                              onPreviewShopify={handlePreviewShopify}
                              onVerificationClick={handleVerificationClick}
                              showPreviewShopify={false}
                              isShopifyPageCreated={
                                values.shopifyPageConfig
                                  ?.userDidCreateTheShopifyPage
                              }
                            ></ReviewAndPublishForm>
                          )}
                      </FormContainer>
                    </form>
                  </LeftPanel>
                  <RightPanel
                    className={previewMode === MOBILE_VIEW ? 'responsive' : ''}
                  >
                    <RightPanelInner
                      className={
                        previewMode === MOBILE_VIEW ? 'responsive' : ''
                      }
                    >
                      {businessProfileData && (
                        <ReferralPreviewContainer
                          business={businessProfileData}
                          background={
                            values.pageStyle.background.backgroundType ===
                            ReferralPageStateConfigPageStyleBackgroundType.Image
                              ? getLogoURL(
                                  values.pageStyle.background.backgroundImage
                                )
                              : values.pageStyle.background.backgroundColor
                          }
                          backgroundType={
                            values.pageStyle.background.backgroundType
                          }
                          className={
                            previewMode === MOBILE_VIEW ? 'responsive' : ''
                          }
                          isEdit={creationMode === EDIT_MODE_PARAM}
                        >
                          {getTemplate(
                            values.pageType || pageType.join,
                            opportunitiesList?.listOffers?.items as Offer[],
                            businessProfileData,
                            values
                          )}
                        </ReferralPreviewContainer>
                      )}
                    </RightPanelInner>
                  </RightPanel>

                  <PreviewButton
                    onClick={() => setIsMobilePreviewOpen(true)}
                    type="button"
                  >
                    <Eye />
                  </PreviewButton>
                  <PreviewModal
                    isOpen={isMobilePreviewOpen}
                    onClose={() => setIsMobilePreviewOpen(false)}
                  >
                    {businessProfileData && (
                      <ReferralPreviewContainer
                        business={businessProfileData}
                        background={
                          values.pageStyle.background.backgroundType ===
                          ReferralPageStateConfigPageStyleBackgroundType.Image
                            ? getLogoURL(
                                values.pageStyle.background.backgroundImage
                              )
                            : values.pageStyle.background.backgroundColor
                        }
                        backgroundType={
                          values.pageStyle.background.backgroundType
                        }
                      >
                        {getTemplate(
                          values.pageType || pageType.join,
                          opportunitiesList?.listOffers?.items as Offer[],
                          businessProfileData,
                          values
                        )}
                      </ReferralPreviewContainer>
                    )}
                  </PreviewModal>

                  <NavigationPrompt when={dirty && !isSuccessModalOpen}>
                    {({ onConfirm, onCancel }) => (
                      <Modal
                        type="warning"
                        isOpen={true}
                        onClose={onCancel}
                        title={REDIRECT_PROMPT_TITLE}
                        message={REDIRECT_PROMPT_TEXT}
                        mainButton={{
                          text: REDIRECT_PROMPT_CANCEL,
                          onClick: onCancel
                        }}
                        secondaryButton={{
                          text: REDIRECT_PROMPT_OK,
                          onClick: onConfirm
                        }}
                      />
                    )}
                  </NavigationPrompt>
                </Content>

                <Modal
                  type="warning"
                  canClose
                  isOpen={isValidationErrorModalOpen}
                  onClose={() => setValidationErrorModal(false)}
                  title="Before you can proceed, you must go back and fill in the missing fields!"
                  mainButton={{
                    text: 'Go to Missing Fields',
                    onClick: () => onMissingFieldsClick(setFieldValue, errors)
                  }}
                />

                <Modal
                  imageURL="/assets/notification-icons/go-live.png"
                  canClose
                  isOpen={isSaveModalOpen}
                  onClose={() => setIsSaveModalOpen(false)}
                  title="Are you sure you want to save these changes?"
                  mainButton={{
                    text: 'Accept',
                    onClick: () =>
                      handleSave(validateForm, setSubmitting, values)
                  }}
                  secondaryButton={{
                    text: 'Cancel',
                    onClick: () => setIsSaveModalOpen(false)
                  }}
                />

                <Modal
                  imageURL="/assets/notification-icons/go-live.png"
                  canClose
                  isOpen={isCreateShopifyPageModalOpen}
                  onClose={() => setIsCreateShopifyPageModalOpen(false)}
                  title="Are you sure you want to create shopify page?"
                  mainButton={{
                    text: 'Accept',
                    onClick: () => {
                      values?.shopifyPageConfig?.userDidCreateTheShopifyPage
                        ? handleSave(validateForm, setSubmitting, values)
                        : handleCreateShopifyPage(
                            validateForm,
                            setSubmitting,
                            values
                          );
                    }
                  }}
                  secondaryButton={{
                    text: 'Cancel',
                    onClick: () => setIsCreateShopifyPageModalOpen(false)
                  }}
                />

                <Modal
                  type="warning"
                  isOpen={isErrorModalOpen}
                  onClose={() => setIsErrorModalOpen(false)}
                  childrenBeforeButtons={true}
                  title="Error"
                  message="An unexpected error has occured."
                  mainButton={{
                    text: 'Okay',
                    onClick: () => {
                      setIsErrorModalOpen(false);
                    }
                  }}
                />

                <Modal
                  className="referral-page-verification-modal"
                  isOpen={isVerificationModalOpen}
                  onClose={() => setIsVerificationModalOpen(false)}
                  childrenBeforeButtons={true}
                  title="Verify Code"
                >
                  <CodeVerification />
                </Modal>

                <CompletionSuccessModal
                  isShopify={
                    businessProfileData?.getCurrentBusiness?.integrationType ===
                    IntegrationType.Shopify
                  }
                  isOpen={
                    isSuccessModalOpen &&
                    !shopifyPermissionErrorModalData.isOpen
                  }
                  onClose={handleSuccessModalClose}
                  shopifyTitle={
                    (updatedReferralPageData?.updateReferralPage as any)
                      ?.shopifyPageConfig?.title
                  }
                  onMainButtonClick={handleSuccessModalMainClick}
                  onShopifyMainButtonClick={handleSuccessModalShopifyMainClick}
                  onShopifySecondaryButtonClick={
                    handleSuccessModalShopifySecondaryClick
                  }
                  isEdit={creationMode === EDIT_MODE_PARAM}
                />
              </>
            )}
          </Formik>
        )}

        <Modal
          isOpen={shopifyPermissionErrorModalData.isOpen}
          type="warning"
          onClose={redirectToReferralPageTab}
          childrenBeforeButtons={true}
          title="Enable app permission to perform this action."
          message=""
          mainButton={{
            text: 'Enable Permission',
            onClick: handleOpenShopifyClick
          }}
          secondaryButton={{
            text: 'Back to Referral Webpage',
            onClick: redirectToReferralPageTab
          }}
        >
          <ModalParagraph>
            Your progress will be saved and you can return to the Referral
            Webpage to finish creating a Shopify page.{' '}
          </ModalParagraph>
        </Modal>

        <Modal
          isOpen={isNoProfileModalOpen}
          canClose={false}
          childrenBeforeButtons={true}
          title="Before we do that..."
          message="Please complete your account set up to start configuring your referral page."
          mainButton={{
            text: 'Go to Dashboard',
            onClick: () => {
              history.push(routes.DASHBOARD);
            }
          }}
        />
        <Modal
          isOpen={isNoOffersModalOpen}
          canClose={false}
          childrenBeforeButtons={true}
          title="Before we do that..."
          message="Please publish an opportunity to start configuring your referral page."
          mainButton={{
            text: 'Go to Opportunities',
            onClick: () => {
              history.push(routes.MY_OPPORTUNITIES);
            }
          }}
        />

        {(referralPageDataLoading ||
          opportunitiesListLoading ||
          businessProfileDataLoading ||
          isUpdateReferralPageLoading ||
          isCreateShopifyReferralPageLoading) && <FullScreenLoader />}
      </Container>
    </DashboardPageLayout>
  );
};

export default ReferralWebpage;
