import jsCookie from 'js-cookie';

const UTM_COOKIE_FIELD = 'utm-cookie';

export const getUtmParams = (search: string) => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params:any = {};
  let match = regex.exec(search);

  while(match) {
    
    if(match?.[1].indexOf("utm_") === 0) {
      params[match[1].slice(4)] = match[2];
    }
    match = regex.exec(search);
  }
  return params;
}

export const getUtmCookies = () => {
  return JSON.parse(jsCookie.get(UTM_COOKIE_FIELD) || 'null');
}

export const getDefaultUtmCookies = () => {
  const utmCookies = JSON.parse(jsCookie.get(UTM_COOKIE_FIELD) || '{}');
  return {
    utm_source: utmCookies.source,
    utm_medium: utmCookies.medium,
    utm_campaign: utmCookies.campaign,
    utm_term: utmCookies.term,
    utm_content: utmCookies.content
  }
}

export const setUtmCookies = (values: object) => {
  const utmValues = getUtmCookies();
  jsCookie.set(UTM_COOKIE_FIELD, JSON.stringify({
    ...utmValues,
    ...values
  }));
}

export const clearUtmCookies = () => {
  jsCookie.remove(UTM_COOKIE_FIELD);
}