import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { H3 } from 'uikit';
import { breakpoint } from 'helpers';


export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 5px;
`;
export const StyledListContainer = styled.div`
  box-sizing: border-box;
  margin: 0 45px 45px 45px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  ${breakpoint('xs', 'md')`
    margin: 0 20px 20px 20px;
  `}
`;

export const StyledListHeading = styled(H3)`
  margin: 28px 39px 22px 39px;
`;
export const StyledList = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  box-sizing: border-box;
  
  flex-wrap: wrap;
  

`;

export const StyledItem = styled(Link)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 71px;
  border-right: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorAlt};

  ${breakpoint('xs', 'md')`
    width: 50%;

    :nth-child(2n) {
      border-right: none;
    }

    :nth-last-child(-n+2) {
      border-bottom: none;
    }
  `}
  ${breakpoint('lg')`
    :nth-child(4n) {
      border-right: none;
    }
    :nth-last-child(-n+4) {
      border-bottom: none;
    }
    :nth-of-type(3),
    :nth-of-type(4) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
    }
  `}
`;

export const StyledListItemOther = styled(Link)`
  display: flex;
  height: 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  border-top: none;

  :link,
  :visited,
  :hover,
  :active {
    color: ${({ theme }) => theme.colors.bodyTextColor};
  }
`;
export const StyledOtherTitle = styled(H3)`
  font-size: 18px;
`;
