import styled from 'styled-components';
import { breakpoint } from 'helpers';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  

  ${breakpoint('sm')`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.backgroundColor}};
  `}

  ${breakpoint('xs', 'sm')`
    background-color: ${({ theme }) => theme.colors.white};
  `}
`;

export const Content = styled.div`
  padding: 0 10vw 5vh 10vw;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.137811);
  box-sizing: border-box;

  ${breakpoint('sm')`
    width: 470px;
    border-radius: 11px;
    padding: 0 44px 45px 44px;
  `}

  ${breakpoint('xs', 'sm')`
    margin-top: 100px;
  `}
`;
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;