import styled from 'styled-components';
import { PrimaryButton, SecondaryButton} from 'uikit';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const PickerContainer = styled.div`
  width: 100%;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
`;
export const ApplyButton = styled(PrimaryButton)`
  width: 110px;
  margin-right: 15px;
`;
export const CancelButton = styled(SecondaryButton)`
  width: 110px;
`;
export const InputContainer = styled.div`
  display: flex;
  width: 90px;
`;