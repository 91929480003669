import React, { FC } from 'react';
import { ModalHeader, ReturnLink, StyledFooter, Title } from './styles';
import InviteDrummerLanding from 'pages/InviteDrummerLanding';
import {
  ListOpportunitiesQuery,
  GetInvitationLandingDataQuery
} from '../../../../graphql';

interface Props {
  code: undefined | string | null;
  onReturnToLink: () => void;
  businessData: GetInvitationLandingDataQuery | undefined;
  opportunitiesList: ListOpportunitiesQuery | undefined;
}

const InvitationLandingPreview: FC<Props> = ({
  businessData,
  code,
  opportunitiesList,
  onReturnToLink
}) => (
  <div>
    <ModalHeader>
      <Title>Landing page for invite preview</Title>
      <ReturnLink onClick={onReturnToLink} type="button">
        &lt; Back to custom link
      </ReturnLink>
    </ModalHeader>
    <InviteDrummerLanding
      businessData={businessData}
      code={code}
      opportunitiesList={opportunitiesList}
    />
    <StyledFooter />
  </div>
);

export default InvitationLandingPreview;
