import styled from 'styled-components';
import { Body2, H3, Toggle } from 'uikit';
import { ViewModeType, CUSTOMER_VIEW } from './constants';
import { ITheme } from 'types';

const PREVIEW_WIDTH = '290px';

export const PreviewContainer = styled.div`
  width: ${PREVIEW_WIDTH};
`;

export const Title = styled(H3)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledToggle = styled(Toggle)`
  margin: 0 auto 25px;
`;

export const Representation = styled(Body2)`
  margin-bottom: 15px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const BoldText = styled.span`
  padding-left: 4px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

interface PhoneContainerProps extends ITheme {
  previewMode: ViewModeType;
}

export const PhoneContainer = styled.div<PhoneContainerProps>`
  position: relative;
  width: ${PREVIEW_WIDTH};
  height: 600px;
  padding: 20px 17px;
  box-sizing: border-box;
  background: url('/assets/phone-container.png') no-repeat;
  z-index: 15;

  &::after {
    position: absolute;
    z-index: 10;
    content: '';
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 15px;
    background-color: ${({ previewMode, theme }) =>
      previewMode === CUSTOMER_VIEW
        ? theme.colors.backgroundColor
        : theme.colors.white};
  }
`;

export const PreviewContent = styled.div`
  position: relative;
  width: 375px;
  max-height: 790px;
  overflow-y: auto;
  transform: scale(0.68);
  transform-origin: top left;
  z-index: 20;
  border-radius: 10px;

  ms-overflow-style: none; 
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none
  }

  // ::-webkit-scrollbar-thumb {
  //   background-color: ${({ theme }) => theme.colors.black};
  // }
`;
