export const tooltips = {
  SUBJECT: 'This is the subject of the email that will be sent to your customers to invite them to your referral program.',
  EMAIL_BODY: 'Enter the email body that will be sent to all subscribed customers when you publish a new opportunity.',
  TEST_EMAILS1: 'Test your campaign before sending it out to your customers',
  TEST_EMAILS2: 'Sending the preview test email will send all three emails for the invitation, reminders and new opportunity emails.',
  TEST_EMAILS3: 'Please note that your customers will not receive all emails at the same time but will receive them at specific intervals for maximum engagement.',
  STYLING: 'You can customize the colors and style of the emails to match your company branding and style',
  FROM: 'Select the senders email address that will be seen by all customers receiving referral emails.',
  SEND_TO1: 'Select the group of customers that will receive referral emails.',
  SEND_TO2: 'Choosing All Subscribed customers will enroll all customers in the default email campaign.',
  SEND_TO3: 'Choosing customers with tags will override the default campaign for tagged customers.',
}