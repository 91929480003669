import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { H2, Body1, LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';
import { ITheme } from 'types';

export const Container = styled.div`
  position: relative;
`;
export const HeadingContainer = styled.div`
  ${breakpoint('lg')`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StyledHeading = styled(H2)`
  margin-bottom: 10px;
`;

export const ReturnLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  ${LinkTextMixin};
`;
export const Description = styled(Body1)`
  color: ${({ theme }: ITheme) => theme.colors.subTitlesTextColor};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
`;
export const ScheduleLink = styled.a`
  ${LinkTextMixin};
  cursor: pointer;
`;
