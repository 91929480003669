import styled from 'styled-components';
import { ImageUpload } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledImageUpload = styled(ImageUpload)`
  margin-right: 15px;
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 15px;

  :not(:last-child) {
    margin-right: 15px;
  }
`;

export const StyledImage = styled.img`
  width: auto;
  height: 115px;
  border-radius: 6px;
`;

export const RemoveImageButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
`;
