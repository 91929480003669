import React, { FC } from 'react';
import { components, SingleValueProps } from 'react-select';
import { IOption } from '../../OpportunitySelect';
import { StyledTitle } from './styles';

const SingleValue: FC<SingleValueProps<IOption>> = (props) => (
  <components.SingleValue {...props}>
    <StyledTitle>{props.data.title}</StyledTitle>
  </components.SingleValue>
);

export default SingleValue;
