import React, {FC} from 'react'
import routes from 'constants/routes';

import {
  Container,
  StyledLink,
} from './styled'

const BackToDashboard:FC = () => {

  return (
    <Container>
      <StyledLink to={routes.DASHBOARD}>Back to Dashboard</StyledLink>
    </Container>
  )
}

export default BackToDashboard;





