import styled from 'styled-components';
import WidgetContainer from 'components/WidgetContainer';
import { breakpoint } from 'helpers';
import { H2 } from 'uikit';

export const Container = styled(WidgetContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 700px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};

  ${breakpoint('xs', 'lg')`
    padding: 25px;
  `}

  ${breakpoint('lg')`
    padding: 30px 30px 20px;
  `}
`;

export const Title = styled(H2)`
  margin-bottom: 25px;
  text-align: center;
  color: #bdbdbd;
`;
