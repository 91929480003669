import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, BackButton } from './styles';
import routes from 'constants/routes';

const Footer: FC = () => {
  const history = useHistory();

  const onBack = () => {
    history.push(routes.DASHBOARD);
  }

  return (
    <Container>
      <BackButton
        onClick={onBack}
        type="button"
      >
        Back
      </BackButton>
    </Container>
  );
};

export default Footer;
