import {
  CommissionType,
  ImageStyle,
  ImageStyleName,
  ListOpportunitiesQuery,
  Maybe,
  Offer
} from '../../../../graphql';
import { getFormattedEarning, formatDate, isDateValid } from 'helpers';
import DATE_FORMAT from 'constants/dateFormat';

const EMPTY_FIELD_PLACEHOLDER = 'N/A';

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

const getOpportunityDuration = (
  opportunity: Pick<Offer, 'endedAt' | 'startedAt'>
) => {
  const { startedAt, endedAt } = opportunity;

  if (!startedAt || !endedAt) {
    return EMPTY_FIELD_PLACEHOLDER;
  }

  const formattedStartDate =
    startedAt && isDateValid(startedAt)
      ? formatDate(startedAt, DATE_FORMAT)
      : EMPTY_FIELD_PLACEHOLDER;

  const formattedEndDate =
    endedAt && isDateValid(endedAt)
      ? formatDate(endedAt, DATE_FORMAT)
      : EMPTY_FIELD_PLACEHOLDER;

  return `${formattedStartDate} - ${formattedEndDate}`;
};

type ImageStyleFragment = Maybe<Pick<ImageStyle, 'path' | 'name'>>;
type CoverImagesList =
  | Maybe<{
    styles?: Maybe<Array<ImageStyleFragment>>;
  }>
  | undefined;

const getImageStyle = (
  imageStyles: Maybe<Array<ImageStyleFragment>> | undefined,
  imageType: ImageStyleName
) => imageStyles?.find((style) => style?.name === imageType);

const getFullImagePath = (path: string) => `${S3_DOMAIN}/${path}`;

const getCoverImage = (coverImages: CoverImagesList) => {
  const squareImage = getImageStyle(coverImages?.styles, ImageStyleName.Square);
  const originalImage = getImageStyle(
    coverImages?.styles,
    ImageStyleName.Original
  );

  return (
    (squareImage?.path && getFullImagePath(squareImage.path)) ||
    (originalImage?.path && getFullImagePath(originalImage.path)) ||
    null
  );
};

const getFormattedCommission = (
  commissionType: CommissionType | null | undefined,
  commission: number | null | undefined
) => {
  if (
    typeof commission !== 'number' ||
    (commissionType !== CommissionType.Fixed &&
      commissionType !== CommissionType.Percentage)
  ) {
    return EMPTY_FIELD_PLACEHOLDER;
  }

  return getFormattedEarning(commissionType, commission);
};

const formatOpportunityDate = (date: string | null | undefined) =>
  typeof date === 'string' && isDateValid(date)
    ? (formatDate(date, DATE_FORMAT) as string)
    : EMPTY_FIELD_PLACEHOLDER;

export const formatOpportunitiesList = (
  queryResult: ListOpportunitiesQuery | undefined
) =>
  queryResult?.listOffers?.items?.map((opportunity) => ({
    id: opportunity.id,
    title: opportunity.title,
    businessName: opportunity.business?.businessName || 'Business Name',
    businessPhoto: getCoverImage(opportunity.business?.coverImage),
    createdAt: formatOpportunityDate(opportunity.createdAt),
    endedAt: opportunity.endedAt,
    publishedAt: formatOpportunityDate(opportunity.publishedAt),
    // conversion: opportunity.stats?.buyerRedeemed || EMPTY_FIELD_PLACEHOLDER,
    conversion: opportunity.redeemed || EMPTY_FIELD_PLACEHOLDER,
    status: opportunity.status,
    opportunityDuration: getOpportunityDuration(opportunity),
    image: getCoverImage(opportunity.coverPhoto),
    redemptionCount: opportunity.redeemed,
    commissionType: opportunity.drumRedemption?.commissionType,
    commission: opportunity.drumRedemption?.payDrum,
    budget: opportunity.drumRedemption?.limitRedemption?.value,
    formattedCommission: getFormattedCommission(
      opportunity.drumRedemption?.commissionType,
      opportunity.drumRedemption?.payDrum
    ),
    createdAtUnformatted: opportunity.createdAt,
    publishedAtUnformatted: opportunity.publishedAt
  }));

export type FormattedOpportunityList = ReturnType<
  typeof formatOpportunitiesList
>;

export default formatOpportunitiesList;
