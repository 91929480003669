import get from 'lodash.get';
import ProfileSetupSteps from 'constants/profileSetupSteps';
import {
  GetBusinessDetailsQuery,
  IntegrationType,
  PaymentProvider,
  ShopifyChargeStatus
} from '../graphql';

const initialValue = {
  completeProfile: {
    isDraft: false,
    isCompleted: false,
    stepNumber: ProfileSetupSteps.COMPLETE_PROFILE
  },
  createOpportunity: {
    isCompleted: false,
    draftOfferId: null,
    stepNumber: ProfileSetupSteps.CREATE_OPPORTUNITY
  },
  setupTracking: {
    isCompleted: false,
    stepNumber: ProfileSetupSteps.SETUP_TRACKING
  },
  setupBilling: {
    isCompleted: false,
    stepNumber: ProfileSetupSteps.SETUP_BILLING
  }
};

const BUSINESS_REQUIRED_FIELDS = [
  'businessName',
  'category',
  'imagesS3.length',
  'primaryContact.firstName',
  'primaryContact.lastName'
];

const getIntegrationSetupStatus = (
  data: GetBusinessDetailsQuery | undefined
) => {
  if (data?.getCurrentBusiness?.integrationType === IntegrationType.Shopify) {
    return true;
  }

  return (
    !!data?.getCurrentBusiness?.integrations?.baseCodeUrl ||
    !!data?.getCurrentBusiness?.isUserCreatedByShopifyDrumApp
  );
};

export const getProfileStatus = (data: GetBusinessDetailsQuery | undefined) => {
  if (!data) {
    return initialValue;
  }

  const isBusinessAccountCompleted = BUSINESS_REQUIRED_FIELDS.every(
    (fieldName) => !!get(data?.getCurrentBusiness, fieldName)
  );

  const isBusinessAccountSetupStarted =
    BUSINESS_REQUIRED_FIELDS.some(
      (fieldName) => !!get(data?.getCurrentBusiness, fieldName)
    ) && !!data?.getCurrentBusiness?.firstAccountCompletionStepShownTimes;

  return {
    completeProfile: {
      isDraft: !isBusinessAccountCompleted && isBusinessAccountSetupStarted,
      isCompleted: isBusinessAccountCompleted,
      stepNumber: ProfileSetupSteps.COMPLETE_PROFILE
    },
    createOpportunity: {
      isCompleted:
        !!data.getCurrentBusiness?.offersCount ||
        !!data.getCurrentBusiness?.didPublishOffers ||
        !!data.getCurrentBusiness?.firstDraftOfferId,
      draftOfferId: data.getCurrentBusiness?.firstDraftOfferId || null,
      stepNumber: ProfileSetupSteps.CREATE_OPPORTUNITY
    },
    setupTracking: {
      isCompleted: getIntegrationSetupStatus(data),
      stepNumber: ProfileSetupSteps.SETUP_TRACKING
    },
    setupBilling: {
      // TODO: should we check status of payment method?
      isCompleted: data?.getCurrentBusiness?.integrationType === IntegrationType.Shopify ?
      !!data.getCurrentBusiness?.payments?.find(item => item?.provider === PaymentProvider.Paypal)
      :
      !!data.getCurrentBusiness?.payments?.length,
      stepNumber: ProfileSetupSteps.SETUP_BILLING
    }
  };
};

export const getIsProfileSetupCompleted = (
  data: GetBusinessDetailsQuery | undefined
) => {
  const profileSetupStatus = getProfileStatus(data);
  const stepList = Object.values(profileSetupStatus);

  return stepList.every(({ isCompleted }) => isCompleted);
};

export const getBusinessDetails = (
  data: GetBusinessDetailsQuery | undefined
) => {
  return data?.getCurrentBusiness;
};

export const getIsProfileSetupCompletedWithDraft = (
  data: GetBusinessDetailsQuery | undefined
) => {
  const {
    completeProfile,
    createOpportunity,
    setupTracking,
    setupBilling
  } = getProfileStatus(data);

  return !!(
    completeProfile.isCompleted &&
    setupTracking.isCompleted &&
    setupBilling.isCompleted &&
    (createOpportunity.draftOfferId || createOpportunity.isCompleted)
  );
};

export const getPendingCharge = (data: GetBusinessDetailsQuery | undefined) =>
  data?.getCurrentBusiness?.notAcceptedCharges?.notAcceptedCharges?.find(
    (charge) =>
      !!charge?.confirmation_url &&
      charge?.status === ShopifyChargeStatus.Pending
  );
