import styled, { Theme } from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronRight, Tick } from 'assets/svg';

export const StepLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px 25px 5px 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: ${(props) => props.theme.fontFamily.raleway};
  border-radius: 19px;
  color: #bdbdbd;

  &:not(:last-child) {
    margin-bottom: 13px;
  }

  &.completed {
    color: ${({ theme }) => theme.colors.accentMainColor};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.accentMainColor};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const DraftCaption = styled.span`
  padding: 0 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
`;

export const SubLink = styled(Link)`
  &,
  &:link,
  &:active,
  &:visited,
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.size.body1};
    color: inherit;
    padding: 0 10px;
  }
  
`;

export const StepItemBullet = styled.div<{
  isActive: boolean;
  isCompleted: boolean | null;
  theme: Theme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: 13px;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : '#bdbdbd'};
  background-color: ${({ isCompleted, theme }) =>
    isCompleted ? theme.colors.accentMainColor : 'transparent'};
  border-radius: 50%;
`;

export const StyledTick = styled(Tick)`
  width: 13px;
  height: 15px;
`;

export const StepItemCaption = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 1.57;
`;

export const StyledChevron = styled(ChevronRight)`
  width: 10px;
  height: auto;

  & path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;
