import React, { FC } from 'react';
import { Emphasized, H2 } from 'uikit';
import { Title } from '../../styles';
import { Container, Paragraph } from './styles';

export interface Props {
  successAmount?: number;
  errorAmount?: number;
}

const CampaignCreated: FC<Props> = ({ successAmount = 0, errorAmount = 0 }) => {
  return (
    <Container>
      <Title>Campaign Started</Title>

      <Paragraph>Your referral email campaign has started.</Paragraph>
      <Paragraph>
        Here is a summary of the emails that will be sent today.
      </Paragraph>
      <H2>{successAmount}</H2>
      <Paragraph>Customers will be included in the email campaign.</Paragraph>
      {!!errorAmount && (
        <Paragraph>
          <Emphasized>{errorAmount}</Emphasized> Customers in your list do not
          have an email address or have unsubscribed and will not receive
          emails.
        </Paragraph>
      )}
      <Paragraph>
        As new email addresses are added to the list, they will automatically
        receive an invitation email.
      </Paragraph>
    </Container>
  );
};

export default CampaignCreated;
