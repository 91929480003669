import React, { FC } from 'react';
import {
  Container,
  Contacts,
  Email,
  EmailLink,
  StyledLink
} from '../styled';
import { CONTACT_SUPPORT_LINK } from 'constants/outerLinks';


const SizeExceeded: FC = () => {
  return (
    <Container>
      <p>
        The CSV file you are attempting to import does not match our formatting.
        We will be unable to read the file. Please use this{' '}
        <StyledLink target="_blank" href={CONTACT_SUPPORT_LINK}>
          template
        </StyledLink>{' '}
        to match the preferred format. If you continue to have problems, please
        contact support.
      </p>
      <Contacts>
        <Email>
          <EmailLink href={'mailto:help@drum.io'}>help@drum.io</EmailLink>
        </Email>
      </Contacts>
    </Container>
  );
};

export default SizeExceeded;
