import React, { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import routes from 'constants/routes';
import { useGetBusinessDetailsQuery, IntegrationType } from '../../graphql';
import ShopifyIntegrationPage from './ShopifyIntegrationPage';

const ShopifyIntegrationPageContainer: FC<RouteComponentProps> = (props) => {
  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  return (!businessProfileData?.getCurrentBusiness?.integrationType || businessProfileData?.getCurrentBusiness?.integrationType ===
    IntegrationType.Shopify) ? (
    <ShopifyIntegrationPage {...props} />
  ) : (
    <Redirect to={routes.SET_UP_CUSTOMER_TRACKING} />
  );
};

export default ShopifyIntegrationPageContainer;
