import React, { FC } from 'react';
import { OverlayAbsolute, StyledLoader } from './styles';

const BlockLoader: FC = () => (
  <OverlayAbsolute>
    <StyledLoader />
  </OverlayAbsolute>
);

export default BlockLoader;
