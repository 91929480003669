import React, { FC, useState, useEffect } from 'react';
import BillingHistoryDetailedTable from './components/BillingHistoryDetailedTable';
import moment from 'moment';
import DATE_FORMAT from 'constants/dateFormat';
import Pagination from 'components/Pagination';
import SetupProfileLayout from '../../layouts/GeneralLayout';
import FAQ from '../../components/FAQ';
import routes from 'constants/routes';
import { BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT } from 'constants/defaultPaginationLimit';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { formatCurrency } from 'helpers';
import { FullScreenLoader } from 'uikit';
import {
  Container,
  Content,
  HeadingContainer,
  PaginationContainer,
  StyledHeading,
  InfoContainer,
  InfoLeft,
  InfoRight,
  NameBlock,
  PriceBlock,
  HistoryName,
  ReferrerCount,
  DateBlock
} from './styles';
import {
  useGetPaymentHistoryDetailedLazyQuery,
  useGetBusinessDetailsQuery,
  V15Payout,
  PayoutGroupType,
  IntegrationType
} from '../../graphql';

const BillingHistoryDetailedPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [
    getPaymentHistory,
    { data, loading }
  ] = useGetPaymentHistoryDetailedLazyQuery();
  const [page, setPage] = useState<number>(1);

  const { data: businessProfileData } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (!page) return;
    getPaymentHistory({
      variables: {
        input: {
          dateRange: {
            from: params.from as string,
            to:
              params.to === 'null'
                ? new Date().toISOString()
                : (params.to as string)
          },
          pagination: {
            page: page,
            limit: BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
          },
          filter: {
            payoutGroupType: params.payoutGroupType as PayoutGroupType,
            pending: !!(params.pending === 'true')
          }
        }
      }
    });
    // eslint-disable-next-line
  }, [page]);

  const handleHeaderBackClick = () => {
    history.push(routes.BILLING);
  };

  return (
    <SetupProfileLayout sidebar={<FAQ preset="billing" />}>
      {loading && <FullScreenLoader />}
      <Container>
        <HeadingContainer>
          <StyledHeading
            title="Billing"
            backButtonText="Back to Billing"
            onBackClick={handleHeaderBackClick}
          />
        </HeadingContainer>
        <Content>
          <InfoContainer>
            <InfoLeft>
              <NameBlock>
                <HistoryName>
                  {params.payoutGroupType === PayoutGroupType.MonthlyGroup
                    ? 'Monthly Drum Referral Fees'
                    : 'Weekly Rewards to referrers'}
                  <ReferrerCount>
                    {data?.v15BillingPayouts?.meta?.total || 0}
                  </ReferrerCount>
                </HistoryName>
              </NameBlock>
              <DateBlock>
                {moment(params.from).format(DATE_FORMAT)} -{' '}
                {moment(
                  params.pending === 'true' ? new Date() : params.to
                ).format(DATE_FORMAT)}
              </DateBlock>
            </InfoLeft>
            <InfoRight>
              <PriceBlock>
                {formatCurrency(
                  data?.v15BillingPayouts?.aggregations?.totalAmount
                )}
              </PriceBlock>
            </InfoRight>
          </InfoContainer>
          {data?.v15BillingPayouts?.items &&
            businessProfileData?.getCurrentBusiness && (
              <BillingHistoryDetailedTable
                data={(data?.v15BillingPayouts?.items || []) as V15Payout[]}
                meta={data?.v15BillingPayouts?.meta}
                isShopify={
                  !!(
                    businessProfileData?.getCurrentBusiness?.integrationType ===
                    IntegrationType.Shopify
                  )
                }
              ></BillingHistoryDetailedTable>
            )}
          {data?.v15BillingPayouts?.meta && (
            <PaginationContainer>
              <Pagination
                onPageChange={(page) => setPage(page)}
                pageCount={Math.ceil(
                  Number(
                    data.v15BillingPayouts.meta.total ||
                      BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
                  ) / BILLING_HISTORY_DEFAULT_PAGINATION_LIMIT
                )}
              />
            </PaginationContainer>
          )}
        </Content>
      </Container>
    </SetupProfileLayout>
  );
};

export default BillingHistoryDetailedPage;
