import React, { FC, ReactNode, useState, useEffect } from 'react';
import useCollapse from 'react-collapsed';
import {
  AccordionItemContainer,
  AccordionItemContent,
  AccordionTitleContainer,
  StyledChevron,
  StyledTooltip
} from './styles';

interface Props {
  children: ReactNode;
  className?: string;
  title: string;
  isExpanded?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  activeOnOpen?: boolean;
  manualExpandControl?: boolean;
  forceOpen?: boolean;
  openCheck?: boolean;
  tooltip?: (() => React.ReactNode) | React.ReactNode;
  
}

const AccordionCollapse: FC<Props> = ({
  children,
  className,
  title,
  isExpanded,
  isActive,
  isDisabled,
  activeOnOpen = false,
  manualExpandControl = false,
  forceOpen = false,
  openCheck,
  tooltip
}) => {
  const [isExpandedState, setIsExpandedState] = useState(!!isExpanded);
  const { getCollapseProps, getToggleProps } = useCollapse({isExpanded: manualExpandControl ? isExpanded: (forceOpen || isExpandedState)});

  useEffect(() => {
    if(openCheck) {
      setIsExpandedState(true);
    }
  }, [openCheck])

  return (
    <AccordionItemContainer>
      <AccordionTitleContainer
        className={className}
        isDisabled={isDisabled}
        isActive={activeOnOpen ? (manualExpandControl ? !!isExpanded : (forceOpen || !!isExpandedState)) : isActive}
        expanded={manualExpandControl ? isExpanded : (forceOpen || isExpandedState)}
        {...getToggleProps({
          onClick: () => {
            if (!isDisabled && !(forceOpen && isExpandedState)) {
              setIsExpandedState(!isExpandedState);
            }
          }
        })}
      >
        <StyledChevron isExpanded={manualExpandControl ? !!isExpanded : (forceOpen || !!isExpandedState)} />
        {title}
        {tooltip && <StyledTooltip text={tooltip} />}
      </AccordionTitleContainer>
      <div {...getCollapseProps()}>
        <AccordionItemContent>{children}</AccordionItemContent>
      </div>
    </AccordionItemContainer>
  );
};

export default AccordionCollapse;
