export interface ICognitoError {
  code: string;
  message: string;
}

export const userNotFound: ICognitoError = {
  code: 'UserNotFoundException',
  message: 'User does not exist'
};

export const incorrectUserInputs: ICognitoError = {
  code: 'NotAuthorizedException',
  message: 'Incorrect username or password'
};

export const signInErrors = [userNotFound, incorrectUserInputs];

export const emailAlreadyExists: ICognitoError = {
  code: 'UsernameExistsException',
  message: 'An account with the given email already exists'
};

export const emailAlreadyExistsFromSocial: ICognitoError = {
  code: 'UserLambdaValidationException',
  message: 'An account with the given email already exists'
};

export const emailDoesntExist: ICognitoError = {
  code: 'UserNotFoundException',
  message: "We couldn't find an account associated with that email address."
};

export const emailDoesntConfirmed: ICognitoError = {
  code: 'CodeDeliveryFailureException',
  message:
    'Amazon SES account is in Sandbox. Verify Send-to email address or Amazon SES Account'
};

export const preSignUpExcessPart: string = "PreSignUp failed with error ";

export const restorePasswordErrors = [emailDoesntConfirmed, emailDoesntExist];

export const shopifyErrors = [
  {
    code: 'user_email_exists',
    message: 'User exists'
  }
];

export const FALLBACK_AUTH_ERROR_MESSAGE =
  'Authorization failed. Please try again.';
export const FALLBACK_AUTH_ERROR_MESSAGE_MODAL =
  'This email has already been registered.';
export const FALLBACK_AUTH_ERROR_MESSAGE_MODAL_ADDITIONAL =
  'Please log in or try using a different email address to sign up';
export const FALLBACK_AUTH_ERROR_MESSAGE_ANOTHER_ROLE =
  'Incorrect username or password';

export const AUTH_SIGNUP_MODAL_TITLES = {
  shopify: "This email has already been registered using a Shopify account.",
  google: "This email has already been registered using a Google account.",
  email: "This email has already been used to sign up. Please log in using your email address below.",
};
export const FORGOT_PASSWORD_MODAL_TITLES = {
  shopify: "This email has been registered using a Shopify account. Please log in.",
  google: "This email has been registered using a Google account. Please log in.",
  email: emailDoesntExist.message,
};
export const AUTH_LOGIN_MODAL_TITLES = {
  shopify: "This email has been registered using a Shopify account. Please log in with Shopify.",
  google: "This email has been registered using a Google account.Please log in with Google.",
  email: FALLBACK_AUTH_ERROR_MESSAGE_ANOTHER_ROLE,
};

export const getAuthErrorTitleByType = (type: string) => {
  switch(type.toLowerCase()) {
    case "google":
      return AUTH_SIGNUP_MODAL_TITLES.google;
    case "shopify":
      return AUTH_SIGNUP_MODAL_TITLES.shopify;
    default: 
      return AUTH_SIGNUP_MODAL_TITLES.email;
  }
};
export const getForgotPasswordErrorTitleByType = (type: string) => {
  switch(type.toLowerCase()) {
    case "google":
      return FORGOT_PASSWORD_MODAL_TITLES.google;
    case "shopify":
      return FORGOT_PASSWORD_MODAL_TITLES.shopify;
    default: 
      return FORGOT_PASSWORD_MODAL_TITLES.email;
  }
};
export const getAuthLoginErrorTitleByType = (type: string) => {
  switch(type.toLowerCase()) {
    case "google":
      return AUTH_LOGIN_MODAL_TITLES.google;
    case "shopify":
      return AUTH_LOGIN_MODAL_TITLES.shopify;
    default: 
      return AUTH_LOGIN_MODAL_TITLES.email;
  }
};