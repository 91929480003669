import React, { FC, Fragment, useState, useEffect } from 'react';
import routes from 'constants/routes';
import { getIsProfileSetupCompleted, getProfileStatus } from 'helpers';
import { sendCompletedOnboardingTrackingEvent } from 'helpers/segment';
import ProfileSetupSteps from 'constants/profileSetupSteps';
import {
  useGetBusinessDetailsQuery,
  useIncCongratsMessageShownTimesMutation,
  useIncCompletionEventFiredTimesMutation,
  useTrackOnboardingCompletionMutation,
  Business
} from '../../graphql';
import { ProfileSetupStep } from './components';
import {
  Container,
  Instructions,
  SetupCompleteDetails,
  SetupCompleteTitle,
  StepTitle
} from './styles';
import { StyledCloseButton } from 'uikit/Modal/styles';
import { CloseRoundIcon } from 'assets/svg';
import trackedElementsIds from 'constants/trackedElementsIds';
import { sendDpixOfferCompleted } from 'helpers/segment';

export interface Props {
  className?: string;
}

const ProfileSetup: FC<Props> = ({ className }) => {
  const {
    data: businessDetails,
    refetch: refetchBusinessDetails
  } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });
  const [showCongrats, setShowCongrats] = useState<boolean>(true);
  const profileSetupStatus = getProfileStatus(businessDetails);
  const isProfileSetupCompleted = getIsProfileSetupCompleted(businessDetails);

  const [
    incCongratsMessage,
    { data: incCongratsMessageData }
  ] = useIncCongratsMessageShownTimesMutation();

  const [incCompletionEvent] = useIncCompletionEventFiredTimesMutation({
    onCompleted() {
      refetchBusinessDetails();
    }
  });

  const [
    trackOnboardingCompletionMutation
  ] = useTrackOnboardingCompletionMutation({
    onCompleted() {
      incCompletionEvent();
      sendDpixOfferCompleted();
      if(businessDetails?.getCurrentBusiness) {
        sendCompletedOnboardingTrackingEvent( businessDetails.getCurrentBusiness as Business );
      }
    }
  });

  useEffect(() => {
    if (incCongratsMessageData?.incCongratsMessageShownTimes) {
      setShowCongrats(false);
    }
  }, [incCongratsMessageData]);
  useEffect(() => {
    setShowCongrats(
      !businessDetails?.getCurrentBusiness?.congratsMessageShownTimes
    );
  }, [businessDetails]);
  useEffect(() => {
    if (
      isProfileSetupCompleted &&
      !businessDetails?.getCurrentBusiness?.completionEventFiredTimes
    ) {
      trackOnboardingCompletionMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessDetails?.getCurrentBusiness?.completionEventFiredTimes]);

  const {
    completeProfile,
    createOpportunity,
    setupBilling,
    setupTracking
  } = profileSetupStatus;

  const stepsList = Object.values(profileSetupStatus);
  const sortedStepList = stepsList.sort((a, b) => a.stepNumber - b.stepNumber);

  const getActiveStep = () => {
    const firstUncompletedStep = sortedStepList.find(
      // TODO: fix it
      // @ts-ignore
      ({ isCompleted, draftOfferId, isDraft }) => {
        const shouldSkipDraftSteps = !(
          setupBilling.isCompleted && setupTracking.isCompleted
        );

        return shouldSkipDraftSteps &&
          (typeof draftOfferId === 'string' || isDraft)
          ? false
          : !isCompleted;
      }
    );

    return firstUncompletedStep?.stepNumber;
  };

  const redirectOpportunityUrl = routes.CREATE_OPPORTUNITY;

  const activeStep = getActiveStep();

  const isReadyToPublishOpportunity = !!(
    createOpportunity.draftOfferId &&
    completeProfile.isCompleted &&
    !createOpportunity.isCompleted &&
    setupTracking.isCompleted &&
    setupBilling.isCompleted
  );

  const onClose = () => {
    incCongratsMessage();
  };

  const getStepTitle = () => {
    if (isReadyToPublishOpportunity) {
      return 'Publish Opportunity. To begin attracting customers please publish your opportunity.';
    }

    switch (activeStep) {
      case ProfileSetupSteps.COMPLETE_PROFILE:
        return 'Welcome to Drum!';

      case ProfileSetupSteps.CREATE_OPPORTUNITY:
        return 'Great! Now let’s create your first opportunity.';

      case ProfileSetupSteps.SETUP_TRACKING:
        return 'Now let’s get your website set up for tracking conversion.';

      case ProfileSetupSteps.SETUP_BILLING:
        return 'Last Step. Set up billing so the Referrers can get paid commissions.';

      default:
        return null;
    }
  };

  return (
    <Fragment>
      {isProfileSetupCompleted && showCongrats && (
        <Container className={className}>
          <StyledCloseButton onClick={onClose} type="button">
            <CloseRoundIcon />
          </StyledCloseButton>
          <div>
            <SetupCompleteTitle>
              Congrats{' '}
              {businessDetails?.getCurrentBusiness?.primaryContact?.firstName}!
              You are all set up.
            </SetupCompleteTitle>
            <SetupCompleteDetails>
              Referrers can begin sending customers your way by sharing your
              link.
            </SetupCompleteDetails>
          </div>
        </Container>
      )}
      {!isProfileSetupCompleted && (
        <Container className={className}>
          <StepTitle>{getStepTitle()}</StepTitle>
          <div>
            <Instructions>
              {isReadyToPublishOpportunity
                ? 'Our network of referrers are ready to being promoting your offer. Please publish your opportunity to make it live and viewable on the Drum referral marketplace'
                : 'Complete the following steps to get your opportunity live so referrers can start driving customers to your website.'}
            </Instructions>
            <div>
              <ProfileSetupStep
                isActive={activeStep === ProfileSetupSteps.COMPLETE_PROFILE}
                isCompleted={completeProfile.isCompleted}
                subLinkText={completeProfile.isDraft ? 'View Draft' : ''}
                subLinkTo={routes.BUSINESS_PROFILE}
                index={ProfileSetupSteps.COMPLETE_PROFILE}
                stepDescription="Complete business profile"
                to={routes.BUSINESS_PROFILE}
                linkId={
                  trackedElementsIds.completionSteps.completeBusinessProfileLink
                }
              />
              <ProfileSetupStep
                isActive={activeStep === ProfileSetupSteps.CREATE_OPPORTUNITY}
                isCompleted={createOpportunity.isCompleted}
                subLinkText={!!createOpportunity.draftOfferId ? 'View Opportunities' : ''}
                subLinkTo={routes.MY_OPPORTUNITIES}
                index={ProfileSetupSteps.CREATE_OPPORTUNITY}
                stepDescription="Create opportunity"
                to={redirectOpportunityUrl}
                readyToPublish={isReadyToPublishOpportunity}
                linkId={
                  trackedElementsIds.completionSteps.createDraftOpportunityLink
                }
              />
              {/* TODO: hide tracking step if user uses Shopify */}
              {!businessDetails?.getCurrentBusiness
                ?.isUserCreatedByShopifyDrumApp && (
                <ProfileSetupStep
                  isActive={activeStep === ProfileSetupSteps.SETUP_TRACKING}
                  isCompleted={setupTracking.isCompleted}
                  index={ProfileSetupSteps.SETUP_TRACKING}
                  stepDescription="Set up customer tracking"
                  to={routes.SET_UP_CUSTOMER_TRACKING}
                  linkId={
                    trackedElementsIds.completionSteps.setupCustomerTrackingLink
                  }
                />
              )}
              <ProfileSetupStep
                isActive={activeStep === ProfileSetupSteps.SETUP_BILLING}
                isCompleted={setupBilling.isCompleted}
                index={
                  businessDetails?.getCurrentBusiness
                    ?.isUserCreatedByShopifyDrumApp
                    ? ProfileSetupSteps.SETUP_BILLING - 1
                    : ProfileSetupSteps.SETUP_BILLING
                }
                stepDescription="Choose the payment method"
                to={routes.BILLING}
                linkId={
                  trackedElementsIds.completionSteps.choosePaymentMethodLink
                }
              />
            </div>
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default ProfileSetup;
