import React, { FC, ReactNode } from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from '../../assets/svg';
import './Pagination.scss';

const PaginationControl: FC<{ children: ReactNode }> = ({ children }) => (
  <button className="pagination__control">{children}</button>
);

// TODO: delete mock data after API integration
const defaultPaginationConfig = {
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 2
};

// TODO: integrate dashboard tables and make props required
interface Props {
  onPageChange?: (page: number) => void;
  pageCount?: number;
  forcePage?: number;
}

const Pagination: FC<Props> = ({ onPageChange = () => null, pageCount, forcePage = undefined }) => (
  <ReactPaginate
    pageCount={pageCount || defaultPaginationConfig.pageCount}
    forcePage={forcePage}
    pageRangeDisplayed={defaultPaginationConfig.pageRangeDisplayed}
    marginPagesDisplayed={defaultPaginationConfig.marginPagesDisplayed}
    // Increment `selected` by 1, because page list starts from 0.
    onPageChange={({ selected }) => onPageChange(selected + 1)}
    breakClassName="pagination__link"
    pageLinkClassName="pagination__link"
    containerClassName="pagination"
    previousLabel={
      <PaginationControl>
        <ChevronLeft />
      </PaginationControl>
    }
    previousLinkClassName="pagination__prev"
    nextLabel={
      <PaginationControl>
        <ChevronRight />
      </PaginationControl>
    }
    nextLinkClassName="pagination__next"
  />
);

export default Pagination;
