import styled from 'styled-components';
import { ITheme } from 'types';
import { breakpoint } from 'helpers';
import { SecondaryButton } from 'uikit';
import { InputFooter, ErrorTextMixin } from 'uikit';

export const SubSectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    margin-top: 21px;
  }
`;
export const SubSectionContainerAlt = styled(SubSectionContainer)`
  ${breakpoint('md')`
    margin-left: 38px;
  `};
`;
export const SubSectionHeadingRight = styled.h5`
  text-align: right;
  margin-top: 55px !important;
`;

export const VerifyButton = styled(SecondaryButton)`
  width: 200px;
  align-self: flex-end;
  font-size: 14px;
  padding: 7px 0;
  ${breakpoint('xs', 'md')`
    align-self: center;
  `};
`;

export const Paragraph = styled.p<ITheme>`
  display: block;
  font-size: ${({ theme }: ITheme) => theme.size.body2};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.semiBold};
  margin-top: 26px;
  line-height: 21px;
  color: #70757a;
`;
export const WarningParagraph = styled(Paragraph)<ITheme>`
  font-size: ${({ theme }: ITheme) => theme.size.body1};

  &.right {
    display: flex;
    max-width: 600px;
    text-align:right;
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: 35px;
    margin-top: 21px;
    font-size: ${({ theme }: ITheme) => theme.size.body2};
  }
`;
export const WarningText = styled.span<ITheme>`
  color: ${({ theme }: ITheme) => theme.colors.warning};
`;

export const StyledInputFooter = styled(InputFooter)`
  align-self: flex-start;
  justify-content: flex-start;
  margin-top: 0;
  div {
    font-size: ${({ theme }: ITheme) => theme.size.body1};
  }
`;

export const ErrorMessage = styled.div`
  ${ErrorTextMixin};
`;
