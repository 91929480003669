import React, { FC, ReactNode } from 'react';
import DashboardPageHeader from 'components/DashboardPageHeader';
import { PageContainer, PageBody, StyledFooter } from './styles';

interface Props {
  children: ReactNode;
}

const DashboardPageLayout: FC<Props> = ({ children }) => (
  <PageContainer>
    <PageBody>
      <DashboardPageHeader />
      {children}
    </PageBody>
    <StyledFooter />
  </PageContainer>
);

export default DashboardPageLayout;
