import React, { FC, useState, useEffect } from 'react';
import FormattedPrice from '../FormattedPrice';
import Pagination from '../Pagination';
import { PaginationContainer } from '../Pagination/styles';
import {
  Container,
  Conversions,
  DrummerAvatarTd,
  DrummerHeading,
  DrummerInfo,
  DrummerName,
  DesktopTable,
  MobileTable,
  StyledAvatar,
  StyledLoader,
  PurchaseTd,
  StyledTitle,
  TableContainer,
  ConversionHeader,
  NoItemsBlock,
  NoItemsText
} from './styles';
import { Tr, Td, Th } from '../Table';
import {
  useGetTopDrummersByBusinessLazyQuery,
  QueryTopDrummersByBusinessInput,
  QueryTopDrummersByBusinessInputSortBy,
  DrummerByBusiness,
  PageInfo,
  ImageStyle
} from '../../graphql';
import getImagePath from 'helpers/getImagePath';

interface Period {
  from: Date;
  to: Date;
}

interface Props {
  className?: string;
  isDisabled?: boolean;
  period?: Period;
  offerId?: string | null;
}

const widgetTitle = {
  disabled: 'Future Top Referrers',
  active: 'Top Referrers'
};

const TopDrummersTable: FC<Props> = ({
  className,
  isDisabled,
  period,
  offerId = null
}) => {
  const [filters, setFilters] = useState<QueryTopDrummersByBusinessInput>({
    sortBy: QueryTopDrummersByBusinessInputSortBy.Conversions,
    pagination: {
      page: 1,
      limit: 5
    },
    offerId: offerId,
    dateRange: period
      ? {
          from: period.from.toISOString(),
          to: period.to.toISOString()
        }
      : undefined
  });
  const [items, setItems] = useState<DrummerByBusiness[] | undefined | null>(
    []
  );
  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const [
    getTopDrummers,
    { data, loading }
  ] = useGetTopDrummersByBusinessLazyQuery();

  const onPageChange = (page: number) => {
    setFilters({ ...filters, pagination: { ...filters.pagination, page } });
  };

  useEffect(() => {
    if (!filters.dateRange) return;
    getTopDrummers({
      variables: {
        input: filters
      }
    });
    // eslint-disable-next-line
  }, [filters.pagination, filters.dateRange, filters.offerId, getTopDrummers]);

  useEffect(() => {
    if (!period) return;
    setFilters({
      ...filters,
      offerId,
      dateRange: {
        ...filters.dateRange,
        from: period.from.toISOString(),
        to: period.to.toISOString()
      }
    });
    // eslint-disable-next-line
  }, [period?.from, period?.to, offerId]);

  useEffect(() => {
    if (!data?.queryTopDrummersByBusiness) return;
    setItems(data.queryTopDrummersByBusiness.items as DrummerByBusiness[]);
    setPageInfo(data.queryTopDrummersByBusiness.pageInfo);
    // eslint-disable-next-line
  }, [data?.queryTopDrummersByBusiness]);

  return (
    <Container className={className} isDisabled={isDisabled}>
      {loading && <StyledLoader />}
      <StyledTitle
        title={isDisabled ? widgetTitle.disabled : widgetTitle.active}
        term={''}
      />
      <TableContainer>
        <DesktopTable>
          <thead>
            <Tr>
              <Th colSpan={2}>
                <DrummerHeading>Referrers</DrummerHeading>
              </Th>
              <ConversionHeader>Conversions</ConversionHeader>
              <Th>Reward</Th>
            </Tr>
          </thead>

          {items && !!items.length && (
            <tbody>
              {items.map((item, index) => (
                <Tr key={item.drummer?.id}>
                  <DrummerAvatarTd>
                    <StyledAvatar
                      firstName={item.drummer?.firstName}
                      lastName={item.drummer?.lastName}
                      userName={item.drummer?.username}
                      src={getImagePath(
                        (item.drummer?.avatarImage?.styles ||
                          []) as ImageStyle[]
                      )}
                      ratingPosition={
                        (filters.pagination.page - 1) *
                          filters.pagination.limit +
                        index +
                        1
                      }
                    />
                  </DrummerAvatarTd>
                  <Td>
                    <DrummerName>
                      {item.drummer?.firstName && item.drummer?.lastName ? (
                        <DrummerName>
                          {item.drummer.firstName}{' '}
                          {(item.drummer.lastName || '').substr(0, 1)}
                        </DrummerName>
                      ) : (
                        <DrummerName>{item.drummer?.username}</DrummerName>
                      )}
                    </DrummerName>
                  </Td>
                  <Td>
                    <Conversions>{item.conversions || 0}</Conversions>
                  </Td>
                  <Td>
                    <FormattedPrice value={(item.income || 0).toFixed(2)} />
                  </Td>
                </Tr>
              ))}
            </tbody>
          )}
        </DesktopTable>

        {items && !!items.length && (
          <MobileTable>
            <tbody>
              {items.map((item, index) => (
                <Tr key={item.drummer?.id}>
                  <DrummerAvatarTd>
                    <StyledAvatar
                      firstName={item.drummer?.firstName}
                      lastName={item.drummer?.lastName}
                      userName={item.drummer?.username}
                      src={getImagePath(
                        (item.drummer?.avatarImage?.styles ||
                          []) as ImageStyle[]
                      )}
                      ratingPosition={
                        (filters.pagination.page - 1) *
                          filters.pagination.limit +
                        index +
                        1
                      }
                    />
                  </DrummerAvatarTd>
                  <Td>
                    <DrummerInfo>
                      {item.drummer?.firstName && item.drummer?.lastName ? (
                        <div>
                          {item.drummer.firstName}{' '}
                          {(item.drummer.lastName || '').substr(0, 1)}
                        </div>
                      ) : (
                        <div>{item.drummer?.username}</div>
                      )}
                      <div>{item.conversions || 0}</div>
                      <div>Purchase amount</div>
                    </DrummerInfo>
                  </Td>
                  <PurchaseTd>
                    <FormattedPrice value={item.income || 0} />
                  </PurchaseTd>
                </Tr>
              ))}
            </tbody>
          </MobileTable>
        )}

        {!items ||
          (!items.length && (
            <NoItemsBlock>
              <NoItemsText>No Activity Yet</NoItemsText>
            </NoItemsBlock>
          ))}
      </TableContainer>
      <PaginationContainer>
        {items && !!items.length && pageInfo && (
          <Pagination
            onPageChange={onPageChange}
            pageCount={Math.ceil(
              Number(pageInfo.total || 5) / filters.pagination.limit
            )}
          />
        )}
      </PaginationContainer>
    </Container>
  );
};

export default TopDrummersTable;
