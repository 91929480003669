import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { Formik } from 'formik';
import { forgotPassword } from 'services/auth';
import { fetchAuthData } from 'helpers/providerError';
import { sendResetPasswordTrackingEvent } from 'helpers/segment';
import RestorePasswordPageLayout from 'layouts/RestorePasswordPageLayout';
import PasswordPageHeading from 'components/PasswordPageHeading';
import { APP_SOURCE_QUERY_BUSINESS } from 'constants/forgotPasswordParams';
import routes from 'constants/routes';
import {
  restorePasswordErrors,
  emailDoesntExist,
  incorrectUserInputs,
  FALLBACK_AUTH_ERROR_MESSAGE,
  getForgotPasswordErrorTitleByType
} from 'constants/errors';
import { FullScreenLoader, InputBase } from 'uikit';
import validationSchema, { ForgotPasswordFormType } from './validationSchema';
import {
  StyledFieldFooter,
  LoginLink,
  SubmitButton
} from './styles';

const ForgotPassword: FC = () => {
  const location = useLocation();
  const queryParams = querystring.parse(location.search);
  const { source } = queryParams;
  const [isEmailSent, setSendEmailStatus] = useState(false);
  const [authDataFeching, setAuthDataFeching] = useState<boolean>(false);
  const initialValues: ForgotPasswordFormType = { email: '' };

  const onSubmit = async (
    values: ForgotPasswordFormType,
    { setFieldError }: any
  ) => {
    const result = await forgotPassword(values.email.toString().toLowerCase().trim());
    const email = values.email.toString().toLowerCase().trim()
    if (result?.code) {
      sendResetPasswordTrackingEvent(email, false);
      const error = restorePasswordErrors.find((e) => e.code === result.code);
      const errorMessage = error?.message || FALLBACK_AUTH_ERROR_MESSAGE;

      if(result.code === emailDoesntExist.code || result.code === incorrectUserInputs.code) {
        fetchAuthData(email, ({exists, provider}) => {
          setAuthDataFeching(false);
          setFieldError('email', getForgotPasswordErrorTitleByType(provider || ''));
        }, () => {
          setAuthDataFeching(false);
          setFieldError('email', 'Could not retrieve user data');
        })
      } else {
        setFieldError('email', errorMessage);
      }
    } else {
      sendResetPasswordTrackingEvent(email, true);
      setSendEmailStatus(true);
    }
  };

  return (
    <RestorePasswordPageLayout>
      {isEmailSent ? (
          <PasswordPageHeading title="Reset password email sent">
            <span>You should soon receive an email allowing you to reset your
            password. Please make sure to check your spam and trash if you
            can't find the email.</span>
          </PasswordPageHeading>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, handleSubmit, isSubmitting, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              {(isSubmitting || authDataFeching) && <FullScreenLoader />}

              <PasswordPageHeading title="Forgot Password">
                <span>Not a problem. Happens to us all. Please provide the email
                  address on your account and we will send you an email to reset
                  your password.</span>
              </PasswordPageHeading>
              <InputBase
                onChange={handleChange}
                value={values.email}
                type="text"
                name="email"
                placeholder="Email Address"
              />
              <StyledFieldFooter error={errors.email} />
              <SubmitButton type="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
              {source === APP_SOURCE_QUERY_BUSINESS &&
                <LoginLink to={routes.LOGIN}>Return to log in</LoginLink>
              }
              
            </form>
          )}
        </Formik>
      )}
    </RestorePasswordPageLayout>
  );
};

export default ForgotPassword;
