import styled from 'styled-components';
import { DrummerView } from 'components/OpportunityPreview/components';
import { breakpoint } from 'helpers';
import { H2 } from 'uikit';

export const Container = styled.div`
  ${breakpoint('md')`
    padding: 30px 90px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px;
  `}
`;

export const PreviewContainer = styled.div`
  height: 490px;
  margin: 0 auto 30px;
`;

export const StyledTitle = styled(H2)`
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledDrummerView = styled(DrummerView)`
  width: 350px;
  margin: 0 auto;
  transform: scale(0.7);
  transform-origin: top center;
  max-height: 700px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 13px;
  right: 13px;
  border: none;
  background: transparent;
`;
