import moment from 'moment';
import { isDateValid } from 'helpers';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import {
  CommissionType,
  LimitRedemptionType,
  OfferAvailable,
  RewardActionType,
  GetUserIntegrationQuery,
  OfferType
} from '../graphql';

const getMinimumPurchaseAmountFields = (
  values: CreateOpportunityType,
  integrationInfo: GetUserIntegrationQuery | undefined
) => {
  const {
    rewardActionType,
    isMinimumPurchaseAmountRequired,
    minimumPurchaseAmount
  } = values.referralReward;

  if (
    rewardActionType === RewardActionType.NavigateToSpecificPage
  ) {
    return null;
  }

  return {
    isRequireMinimumPurchaseAmount: isMinimumPurchaseAmountRequired,
    buyerSpendPurchaseAmount: isMinimumPurchaseAmountRequired
      ? Number(minimumPurchaseAmount)
      : null
  };
};

const getLimitRedemptionFields = (values: CreateOpportunityType) => {
  const { commissionType, budget, isBudgetLimited } = values.referralReward;

  if (commissionType === CommissionType.Percentage) {
    return null;
  }

  return {
    type: isBudgetLimited
      ? LimitRedemptionType.Budget
      : LimitRedemptionType.Unlimited,
    value: isBudgetLimited ? budget : null
  };
};

const getPhotosField = (values: CreateOpportunityType) => {
  const { photos } = values.opportunityDetails;

  return photos.map((photo) => ({ styles: [photo] }));
};

const normalizeOpportunityFormValues = (
  values: CreateOpportunityType,
  integrationInfo: GetUserIntegrationQuery | undefined
): any => {
  const {
    opportunityDetails: { title, description, urlDestination },
    opportunityDates: { startDate, endDate },
    referralReward: { commissionType, commissionAmount, rewardActionType },
    referrerEducation: { noteForDrummer, educationMaterials }
  } = values;

  const formattedEndDate =
    !!startDate && isDateValid(endDate)
      ? moment(endDate)
          .endOf('day')
          .utc()
          .format()
      : null;

  return {
    // By default all opportunities created from new UI is available in online.
    // But there are old opportunities that were available only offline, and this status is used by other services
    availableType: OfferAvailable.Online,
    type: OfferType.Offer,

    title,
    description,
    photos: getPhotosField(values),
    urlDestination,

    rewardActionType,
    drumRedemption: {
      commissionType,
      payDrum: commissionAmount || 0,
      limitRedemption: getLimitRedemptionFields(values),
      ...getMinimumPurchaseAmountFields(values, integrationInfo)
    },

    drummerNote: { description: noteForDrummer },
    drummerEducationLinks: educationMaterials,

    startedAt: startDate,
    endedAt: formattedEndDate
  };
};

export default normalizeOpportunityFormValues;
