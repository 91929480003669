import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import Avatar from '../Avatar';
import WidgetContainer from '../WidgetContainer';
import WidgetTitle from '../WidgetTitle';
import { Table, Td, Th } from '../Table';
import { BlockLoader } from 'uikit';

export const Container = styled(WidgetContainer)`
  position: relative;
  padding: 25px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
`;

export const TableContainer = styled.div`
  min-height: 361px;
`;

export const StyledLoader = styled(BlockLoader)`
background-color: red;
  div {
    border-radius: 11px;
  }
`;

export const StyledTitle = styled(WidgetTitle)`
  margin-bottom: 25px;
`;

export const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
`;

export const DesktopTable = styled(Table)`
  ${breakpoint('xs', 'xl')`
    display: none;
  `}
  tr {
    td:nth-of-type(1) {
      width: 10%;
    }
    td:nth-of-type(2) {
      width: 20%;
    }
    th:nth-of-type(1) {
      width: 30%;
    }

    
    th:nth-of-type(2),
    td:nth-of-type(3) {
      width: 26%;
    }
    th:nth-of-type(3),
    td:nth-of-type(4) {
      width: 18%;
      text-align: center;
    }
    th:nth-of-type(4),
    td:nth-of-type(5) {
      width: 20%;
      text-align: center;
    }

    th.name-long {
      width: 50%;
    }
    td.name-long {
      width: 40%;
    }
  }
  

  tr:last-of-type {
    border-bottom: none;
  }
`;

export const Conversions = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const MobileTable = styled(Table)`
  ${breakpoint('xl')`
    display: none;
  `}
  tr {
    td:nth-of-type(1) {
      width: 10%;
    }
    td:nth-of-type(2) {
      width: 35%;
    }
    th:nth-of-type(1) {
      width: 45%;
    }

    
    th:nth-of-type(2),
    td:nth-of-type(3) {
      width: 35%;
    }
    th:nth-of-type(3),
    td:nth-of-type(4) {
      width: 20%;
    }
  }
  

  tr:last-of-type {
    border-bottom: none;
  }
`;
export const ActiveHeader = styled(Th)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const DrummerAvatarTd = styled(Td)`
  width: 55px;
`;

export const PurchaseTd = styled(Td)`
  vertical-align: bottom;
  text-align: right;
`;

export const DrummerInfo = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.15;
  color: ${({ theme }) => theme.colors.black};
`;

export const DrummerName = styled(DrummerInfo)`
  color: ${({ theme }) => theme.colors.main};
`;

export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 330px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #C2C2C2;
  
`;
