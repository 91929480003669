import React, { FC, useState } from 'react';
import InviteDrummerModal from '../InviteDrummerModal';
import {
  Container,
  Content,
  CustomerIcon,
  Heading,
  HideButton,
  InviteDetails,
  InviteDescription,
  Title,
  LearnMoreButton
} from './styles';
import { useIncInviteDrummersSuggestionShownTimesMutation } from '../../graphql';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  className?: string;
}

const InviteDrummerPanel: FC<Props> = ({ className }) => {
  const [isHidden, setHidePanel] = useState(false);
  const [isModalOpen, setModal] = useState<boolean>(false);

  const [
    incCongratsMessage
  ] = useIncInviteDrummersSuggestionShownTimesMutation();

  const hidePanel = () => {
    incCongratsMessage();
    setHidePanel(true);
  };

  return (
    <>
      <Container className={className} isHidden={isHidden}>
        <div>
          <CustomerIcon src="/assets/customers.png" alt="" />
        </div>
        <Content>
          <Heading>
            <Title>Invite your customers to Drum up business</Title>
            {/* TODO: implement API integration for hiding widget */}
            <HideButton onClick={hidePanel} type="button">
              Hide
            </HideButton>
          </Heading>
          <InviteDescription>
            <InviteDetails>
              As you know your existing customers are often your best
              salespeople. Invite them to share your Drum opportunity and earn
              money.
            </InviteDetails>
            <LearnMoreButton
              onClick={() => setModal(true)}
              type="button"
              id={trackedElementsIds.inviteCustomers.banner.learnMoreButton}
            >
              Learn more
            </LearnMoreButton>
          </InviteDescription>
        </Content>
      </Container>
      <InviteDrummerModal
        isOpen={isModalOpen}
        onClose={() => setModal(false)}
      />
    </>
  );
};

export default InviteDrummerPanel;
