import React, { FC } from 'react';
import IconProps from './IconProps';

const Google: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <circle cx="17" cy="17" r="17" fill="#1977F3"></circle>
      <path
        fill="#fff"
        d="M21.444 10.649H24V6.197C23.559 6.137 22.043 6 20.276 6c-8.086 0-5.886 9.158-6.208 10.5H10v4.976h4.067v12.522h4.986V21.477h3.902l.62-4.977H19.05c.22-3.294-.887-5.851 2.393-5.851z"
      ></path>
    </svg>
  );
};

export default Google;
