import styled from 'styled-components';
import { breakpoint } from 'helpers';
import IntegrationPageHeader from 'components/IntegrationPageHeader';
import IntegrationErrorContainer from 'components/IntegrationErrorContainer';
import IntegrationInfo from 'components/IntegrationInfo';
import { OutlinedButton } from 'uikit';

export const Container = styled.div`
  ${breakpoint('md')`
    padding: 25px 35px 50px;
  `}

  ${breakpoint('sm', 'md')`
    padding: 15px 25px 25px;
  `}

  ${breakpoint('xs', 'sm')`
     padding: 15px 15px 25px;
  `}
`;

export const StyledIntegrationInfo = styled(IntegrationInfo)`
  margin-bottom: 15px;
`;

export const StyledIntegrationErrorContainer = styled(
  IntegrationErrorContainer
)`
  margin-bottom: 15px;
`;

export const StyledIntegrationPageHeader = styled(IntegrationPageHeader)`
  ${breakpoint('lg')`
    margin-bottom: 35px;
  `}

  ${breakpoint('xs', 'lg')`
    margin-bottom: 20px;
  `}
`;

export const PageFooter = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors.borderColor};

  ${breakpoint('md')`
    padding: 30px 50px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px;
  `}
`;

export const BackButton = styled(OutlinedButton)`
  ${breakpoint('lg')`
    width: 120px;
  `}

  ${breakpoint('xs', 'lg')`
    width: 100%;
  `}
`;
