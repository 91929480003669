import styled from 'styled-components';
import { OutlinedButton } from 'uikit';
import { breakpoint } from '../../../../helpers';

export const Container = styled(OutlinedButton)`

  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  color: #17AACF;
  border-color: #17AACF;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  :hover,
  :active,
  :focus {
    color: #4cc2e0;
    border-color: #4cc2e0;
  }

  svg {
    margin-right: 8px;
  }

  ${breakpoint('xs', 'lg')`
    width: 178px;
  `}
`;
