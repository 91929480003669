import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { ITheme } from 'types';
import { WidgetContainer } from 'uikit';

const widgetColor = '#F95373';

interface ContainerProps extends ITheme {
  isHidden: boolean;
}

export const Container = styled(WidgetContainer)<ContainerProps>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  padding: 16px 20px 16px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CustomerIcon = styled.img`
  margin-right: 24px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black};

  ${breakpoint('md')`
    font-size: ${({ theme }) => theme.size.subtitle};
    `}

  ${breakpoint('xs', 'sm')`
    margin-right: 12px;
    font-size: ${({ theme }) => theme.size.body1};
  `}
`;

export const HideButton = styled.button`
  padding: 0;
  border: none;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.main};
  background-color: transparent;
`;

export const InviteDescription = styled.div`
  ${breakpoint('md')`
    display: flex;
    justify-content: space-between;
  `}
`;

export const InviteDetails = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: #959595;

  ${breakpoint('md')`
    margin-right: 20px;
    font-size: ${({ theme }) => theme.size.body1};
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const LearnMoreButton = styled.button`
  height: 33px;
  padding: 0 45px;
  border-radius: 17px;
  border: solid 2px currentColor;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${widgetColor};

  ${breakpoint('xs', 'md')`
    display: block;
    margin-left: auto;
  `}

  ${breakpoint('md')`
    flex-shrink: 0;
  `}
`;
