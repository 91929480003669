import React, { FC } from 'react';
import IconProps from './IconProps';

const Eye: FC<IconProps> = ({ className }) => (
  <svg
    width="79"
    height="79"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="42" cy="42" r="32" fill="#1FBFE8" />
    </g>
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="10"
      y="10"
      width="64"
      height="64"
    >
      <circle cx="42" cy="42" r="32" fill="#0099FF" />
    </mask>
    <g mask="url(#mask0)">
      {/* <path
        d="M55.5 75.5L29 47V35.5L36.5 33L55.5 38L74.5 59L55.5 75.5Z"
        fill="#13A8CD"
        style={{transform: "scale(0.94)"}}
      /> */}
    </g>
    <path
      d="M42 38C42 40.208 40.208 42 38 42C38 44.208 39.792 46 42 46C44.208 46 46 44.208 46 42C46 39.792 44.208 38 42 38ZM42 30C33.796 30 26 39.296 26 42C26 44.704 33.796 54 42 54C50.204 54 58 44.704 58 42C58 39.296 50.204 30 42 30ZM42 50C37.582 50 34 46.418 34 42C34 37.582 37.582 34 42 34C46.418 34 50 37.582 50 42C50 46.418 46.418 50 42 50Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="84"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Eye;
