import styled from 'styled-components';
import { CSSProperties } from 'react';
import { breakpoint } from '../../../../helpers';
import { InputBase, PrimaryButton } from 'uikit';

export const Container = styled.div`
  ${breakpoint('md')`
    padding: 25px 25px 35px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 45px 18px 60px;
  `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const LinkPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('xs', 'md')`
    margin-bottom: 40px;
  `}

  ${breakpoint('md')`
    margin-bottom: 20px;
  `}
`;

export const ShareLink = styled.div`
  padding: 15px 30px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.defaultRadius};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

  ${breakpoint('xs', 'md')`
    margin-bottom: 30px;
  `}

  ${breakpoint('md')`
    margin-bottom: 45px;
  `}
`;

export const StyledInput = styled(InputBase)`
  ${breakpoint('md')`
    margin-bottom: 30px;
  `}

  ${breakpoint('xs', 'md')`
    margin-bottom: 20px;
  `}
`;

export const CopyLinkButton = styled(PrimaryButton)`
  display: block;
  padding-right: 25px;
  padding-left: 25px;
  margin: 0 auto;
`;

export const overlayStyle: CSSProperties = { zIndex: 100000 };
