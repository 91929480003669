import React, { FC } from 'react';
import moment from 'moment';
import HistoryCollapseItem from '../HistoryCollapseItem/HistoryCollapseItem';
import DATE_FORMAT from 'constants/dateFormat';
import { getName } from '../../helpers';
import {
  Container,
  TableContainer,
  MobileCardsContainer,
  Row,
  HeaderRow,
  Col,
  HeaderCol,
  StyledLink,
  NoItemsBlock,
  NoItemsText
} from './styles';
import {formatCurrency} from 'helpers';
import { Meta } from '../../../../graphql'

export interface HistoryItem {
  id: number | string;
  name: string;
  startDate: string;
  endDate: string;
  rewards: number;
  fees: number;
  charges: number;
  totalCharge: number;
  pending: boolean;
  emphasized?: boolean;
}

interface Props {
  data?: any[];
  meta?: Meta | null,
  isShopify?: boolean
}

const BillingHistoryDetailedTable: FC<Props> = ({ data = [], meta, isShopify = true}) => {
  //const _data = mockData; //Remove later

  return (
    <Container>
      <TableContainer>
        <HeaderRow className={isShopify ? 'isShopify' : ''}>
          <HeaderCol className={'date'}>Date</HeaderCol>
          <HeaderCol className={'referrer'}>Referrer</HeaderCol>
          <HeaderCol className={'opportunity'}>Opportunity</HeaderCol>
          {isShopify && (
            <HeaderCol className={'transaction'}>Shopify<br />order ID</HeaderCol>
          )}
          <HeaderCol className={'fees'}>Platform Fees</HeaderCol>
          <HeaderCol className={'rewards'}>Reward Charges</HeaderCol>
          <HeaderCol className={'total'}>Total Charge</HeaderCol>
        </HeaderRow>
        {data.length ? 
          (data.map((item) => (
            <Row key={item.id} className={isShopify ? 'isShopify' : ''}>
              <Col className={'date'}>
                <span>{moment(item.createdAt).format(DATE_FORMAT)}</span>
                <span>{moment(item.createdAt).format('LT')}</span>
              </Col>
              <Col className={'referrer'}>
                <span>{getName(item.drummer)}</span>
              </Col>
              <Col className={'opportunity'}>
                {item.offer?.title || ''}
              </Col>
              {isShopify && (
                <Col className={'transaction'}>
                  <StyledLink target="_blank" href={`https://${item.shopDomain}/admin/orders/${item.shopifyOrderId}`}>{item.shopifyOrderId}</StyledLink>
                </Col>
              )}
              <Col className={'fees'}>
                {formatCurrency(item.drumFee)}
              </Col>
              <Col className={'rewards'}>
                {formatCurrency(item.drummerReward)}
              </Col>
              <Col className={'total'}>
                {formatCurrency(item.amount)}
              </Col>
            </Row>
          )))
          :
          (<NoItemsBlock>
            <NoItemsText>No Activity Yet</NoItemsText>
          </NoItemsBlock>)
        }
      </TableContainer>
      <MobileCardsContainer>
        {data.length ? 
          (data.map((item) => (
            <HistoryCollapseItem isShopify={isShopify} data={item}/>
          )))
          :
          (<NoItemsBlock>
            <NoItemsText>No Activity Yet</NoItemsText>
          </NoItemsBlock>)
        }
      </MobileCardsContainer>
      
    </Container>
  );
};

export default BillingHistoryDetailedTable;
