import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Body1 } from 'uikit';

export const Container = styled(Link)`
  display: block;
  margin-bottom: 50px;
  padding: 15px 10px;
  border: solid 1px ${({ theme }) => theme.colors.borderColor};
  text-align: center;
  border-radius: 30px;
`;

export const MainText = styled(Body1)`
  display: inline;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;

export const AccentedText = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.accentMainColor};
`;
