import React, { FC } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Container } from './styles';
import { chartOptions } from './constants';
import CustomTooltip from './components/Tooltip';
import './styles.scss';

export interface ChartItem {
  value: number;
  label: string;
  tooltipLabel?: string;
  tooltipDate?: string;
  tooltipActionType?: string;
  tooltipValue?: string;
}

interface Props {
  data: ChartItem[];
}

const OpportunityChart: FC<Props> = ({ data }) => {
  const ticks = data?.length
    ? [
        data[0].label,
        data[Math.floor(data.length / 2)].label,
        data[data.length - 1].label
      ]
    : [];
  return (
    <Container className={"rechart-bar-chart"}>
      <ResponsiveContainer height={chartOptions.height} width={'99%'}>
        <BarChart data={data}>
          <XAxis
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            tick={{ style: { 'background-color': 'red' } }}
            interval={0}
            ticks={ticks}
            dataKey="label"
          />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'none' }} />
          <Bar
            barSize={chartOptions.barSize}
            background={false}
            dataKey="value"
            fill={chartOptions.barColor}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default OpportunityChart;
