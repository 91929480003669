import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  ${breakpoint('lg')`
    padding: 40px 0 200px 0;
  `}

  ${breakpoint('xs', 'lg')`
    padding: 25px 0;
  `}
`;

export const HeadingContainer = styled.div`
  line-height: 19px;
  padding: 20px 5px 0 5px;
`;
export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.accentMainColor};
`;
