import React, { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { CONTACT_SUPPORT_LINK } from 'constants/outerLinks';
import { FieldFooter, InputBase, Tooltip } from 'uikit';
import validationSchema, { ShopifyFormType } from './validationSchema';
import tooltips from './constants';
import {
  ConnectButton,
  ContactSupportDescription,
  ContactSupportLink,
  ContactSupportSection,
  FormFooter,
  FormHeader,
  InputGroup,
  ShopifyForm,
  StyledLabel,
  StyledShareIcon
} from './styles';

interface Props {
  className?: string;
  connectionStatus: null | { isIntegrationActive: boolean };
  onConnectStore: (shopUrl: string) => void;
  shopUrl: string | undefined | null;
}

const initialValues: ShopifyFormType = { shopUrl: '' };

const ShopifyIntegrationForm: FC<Props> = ({
  className,
  connectionStatus,
  onConnectStore,
  shopUrl
}) => {
  const [prefilledValues, setPrefilledValues] = useState(initialValues);

  useEffect(() => {
    if (shopUrl) {
      setPrefilledValues({ shopUrl });
    }
  }, [shopUrl]);

  const handleSubmit = (values: ShopifyFormType) =>
    onConnectStore(values.shopUrl);

  return (
    <div className={className}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        initialValues={prefilledValues}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, handleSubmit, values }) => (
          <ShopifyForm onSubmit={handleSubmit}>
            <FormHeader>Install tracking</FormHeader>

            <InputGroup>
              <StyledLabel
                id="shopify-shop-url"
                title="Shop URL"
                tooltip={tooltips.LABEL}
              />
              <InputBase
                disabled={connectionStatus?.isIntegrationActive}
                id="shopify-shop-url"
                name="shopUrl"
                onChange={handleChange}
                placeholder="storename.myshopify.com"
                value={values.shopUrl}
              />
              <FieldFooter error={errors.shopUrl} />
            </InputGroup>

            <FormFooter>
              <ConnectButton
                disabled={connectionStatus?.isIntegrationActive}
                type="submit"
              >
                Connect to Shopify
                <StyledShareIcon />
              </ConnectButton>
              <Tooltip text={tooltips.SUBMIT_BUTTON} />
            </FormFooter>

            {connectionStatus && !connectionStatus.isIntegrationActive && (
              <ContactSupportSection>
                <ContactSupportDescription>
                  Please contact Drum support if you need ANY help reconnecting
                  your customer tracking.
                </ContactSupportDescription>
                <ContactSupportLink
                  href={CONTACT_SUPPORT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  as="a"
                >
                  Contact Support
                </ContactSupportLink>
              </ContactSupportSection>
            )}
          </ShopifyForm>
        )}
      </Formik>
    </div>
  );
};

export default ShopifyIntegrationForm;
