import React from 'react';
import ReactDOM from 'react-dom';
import { getApolloClient } from './apollo';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as tabsMessanger from 'services/tabsMessanger';
import { getCurrentBusinessId } from 'services/auth';

const root = document.getElementById('root');

const otherTabsLogInListener = async (data: any) => {
  const userId = await getCurrentBusinessId();
  if (userId !== data?.userId) {
    /**
     * DF: Doing apolloClient.resetStore() caused random error: https://github.com/apollographql/apollo-client/issues/3766#issuecomment-619829099
     * I decided to go with reloading.
     */
    window.location.reload(false);
  }
};

try {
  const apolloClient = getApolloClient();
  tabsMessanger.subscribeToLogIns(otherTabsLogInListener);
  ReactDOM[root && root.hasChildNodes() ? 'hydrate' : 'render'](
    <React.StrictMode>
      <App apolloClient={apolloClient} />
    </React.StrictMode>,
    root
  );
} catch (err) {
  console.log(err);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
