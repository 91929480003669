import styled from 'styled-components';
import Footer from 'components/Footer';
import { breakpoint } from 'helpers';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const PageBody = styled.div`
  flex: 1 0 auto;
  box-sizing: border-box;

  ${breakpoint('xl')`
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    box-sizing: border-box;
  `}

  ${breakpoint('lg')`
    padding: 40px 80px;
  `}

  ${breakpoint('md')`
    padding: 40px 40px 80px;
  `}

  ${breakpoint('xs', 'md')`
    padding: 25px 12px 50px;
  `}
`;

export const StyledFooter = styled(Footer)`
  text-align: center;

  ${breakpoint('md')`
    padding-top: 280px;
  `}

  ${breakpoint('xs', 'md')`
    padding-top: 180px;
  `}
`;
