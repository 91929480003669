import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import { FieldFooter } from 'uikit';
import { CreateOpportunityType } from '../../validationSchema';
import {
  DateFields,
  DateInputLabel,
  InputGroup,
  SectionDescription,
  SectionTitle
} from './styles';

const OpportunityDates: FC = () => {
  const { values, errors, setFieldValue } = useFormikContext<
    CreateOpportunityType
  >();
  const { startDate, endDate } = values.opportunityDates;

  const handleDateChange = (fieldName: string) => (date: Date | null) => {
    const formattedValue = date
      ? moment(date)
          .utc()
          .format()
      : '';

    setFieldValue(fieldName, formattedValue);
  };

  const formatFieldValueToDate = (date: string | null) => {
    if (!date) return null;

    return moment(date).toDate();
  };

  return (
    <div>
      <SectionTitle title="Would you like to set a start and expire on a specific date?" />
      <SectionDescription>
        You may set a start and end date for your opportunity. This will be
        displayed on the opportunity. If a referrer has saved your opportunity,
        post expiration it will appear as expired and they will be unable to
        share it.
      </SectionDescription>
      <DateFields>
        <InputGroup>
          <DateInputLabel>From</DateInputLabel>
          <DatePicker
            minDate={new Date()}
            onChange={handleDateChange('opportunityDates.startDate')}
            value={formatFieldValueToDate(startDate)}
          />
          <FieldFooter error={errors.opportunityDates?.startDate} />
        </InputGroup>
        <InputGroup>
          <DateInputLabel>To</DateInputLabel>
          <DatePicker
            minDate={formatFieldValueToDate(startDate)}
            onChange={handleDateChange('opportunityDates.endDate')}
            value={formatFieldValueToDate(endDate)}
          />
          <FieldFooter error={errors.opportunityDates?.endDate} />
        </InputGroup>
      </DateFields>
    </div>
  );
};

export default OpportunityDates;
