import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { ChevronLeft } from 'assets/svg';
import { PrimaryButton, OutlinedButton } from 'uikit';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  box-shadow: 0 -2px 5px rgb(120, 120, 120, 0.2);

  ${breakpoint('xs', 'md')`
    padding: 0 25px;
  `}

  ${breakpoint('md')`
    padding: 0 30px 0px 55px;
  `}
`;

export const MobileReturnButton = styled.button`
  width: 42px;
  height: 42px;
  border: solid 2px ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  line-height: 42px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};

  ${breakpoint('sm')`
    display: none;
  `}
`;

export const DesktopReturnButton = styled(OutlinedButton)`
  width: 120px;

  ${breakpoint('xs', 'sm')`
    display: none;
  `}
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 160px;
`;

export const StyledChevron = styled(ChevronLeft)`
  width: 15px;
  height: 15px;
  fill: currentColor;
`;
