import styled from 'styled-components';
import { PrimaryButton } from 'uikit';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	* {
		font-family: inherit !important;
	}
`;
export const Content = styled.div`
	padding-top: 10px;
`;
export const MainButton = styled(PrimaryButton)`
	width: 100%;
`;
export const ItemsList = styled.div`
	position: relative;
	left: -18px;
	width: calc(100% + 40px);
	margin-top: 20px;
`;
