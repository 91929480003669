import styled from 'styled-components';
import { H2, H3, Body3, Logo } from 'uikit';
import { breakpoint } from 'helpers';
import { ITheme } from 'types';

export const Container = styled.div`
  display: flex;
  min-height: 100%;

  ${breakpoint('xs', 'lg')`
    flex-direction: column-reverse;
    justify-content: flex-end;
    overflow-y: auto;
  `}
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${breakpoint('lg')`
    flex-shrink: 0;
    width: 35%;
    height: 100%;
    min-width: 470px;
    padding: 70px 45px 35px;
    overflow-y: auto;
  `}

  ${breakpoint('xs', 'lg')`
    position: relative;
    padding: 60px 25px 25px;
  `}
`;

export const StyledLogo = styled(Logo)`
  ${breakpoint('xs', 'lg')`
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
  `}
`;

export const PageHead = styled.div`
  margin-bottom: 25px;
  text-align: center;
`;

export const Title = styled(H2)`
  margin-bottom: 5px;
`;

export const Subtitle = styled(H3)`
  color: ${({ theme }) => theme.colors.inputColor};
`;

export const PageContent = styled.section`
  ${breakpoint('lg')`
    flex: 1;
  `}
`;

export const PageFooter = styled.footer`
  padding-top: 35px;
  text-align: center;
`;

export const ReturnLinkWrapper = styled.div`
  padding-top: 35px;
`;

export const Watermark = styled.img`
  margin-bottom: 16px;
`;

export const Copyright = styled(Body3)`
  color: ${({ theme }) => theme.colors.inputColor};
`;

export const ImageContainer = styled.div`
  position: relative;

  ${breakpoint('xs', 'lg')`
    height: 300px;

  `}

  ${breakpoint('lg')`
    flex: 1;
  `}
`;

export const BackgroundImage = styled.div<ITheme & { url: string }>`
  width: 100%;
  height: 100%;
  background: url(${({ url }) => url}) no-repeat center center;
  background-size: cover;
`;

export const BackgroundImageOverlay = styled.div<ITheme>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0) 100%);
`;

export const ImageCaption = styled.p`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  color: ${({ theme }) => theme.colors.white};
  z-index: 5;
  ${breakpoint('xs', 'lg')`
    bottom: 45px;
    padding: 0 25px;
    font-size: 1.8rem;
    text-align: center;
  `}

  ${breakpoint('lg')`
    bottom: 15%;
    left: 50%;
    max-width: 920px;
    transform: translate(-50%, 0);
    padding: 0 85px;
    font-size: 3rem;
  `}

  ${breakpoint('lg', 'xl')`
    font-size: 2rem;
  `}
`;
