import React, { FC, useState, ChangeEvent } from 'react';
import { InputBase } from 'uikit';
import { Container, PickerContainer, ButtonsContainer, ApplyButton, CancelButton, InputContainer } from './styles';

export interface Props {
  initialValue: number;
  onChange?: (value: number) => void;
  onApply?: (value: number) => void;
  onCancel?: () => void;
}

const CornersPicker: FC<Props> = ({initialValue , onChange, onApply, onCancel}) => {

  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    
    const { value, min, max } = e.target;
    if(!Number.isInteger(Number(value))) return;
    const result = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setValue(result);
    onChange && onChange(result)
  }
  const handleApply = () => {
    onApply && onApply(value)
  }
  const handleCancel = () => {
    onCancel && onCancel();
  }

  return (
    <Container>
      <PickerContainer>
        <InputContainer>
          <InputBase
            name={'sendTest'}
            onChange={handleChange}
            value={value}
            pattern="[0-9]*"
            min={0}
            max={30}
          />
        </InputContainer>  
      </PickerContainer>
      <ButtonsContainer>
        <ApplyButton onClick={handleApply}>Apply</ApplyButton>
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

export default CornersPicker;
