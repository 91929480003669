import React, { FC, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, Heading, Paragraph, Subtitle } from '../../styles';
import {
  Container,
  CopyLinkButton,
  LinkPreview,
  LoaderContainer,
  StyledInput,
  overlayStyle
} from './styles';
import { Loader } from 'uikit';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import trackedElementsIds from 'constants/trackedElementsIds';

interface Props {
  code: undefined | string | null;
  onOpenPreview: () => void;
  isLoading: boolean;
}

const { REACT_APP_DYNAMIC_LANDING_PAGE_DOMAIN } = process.env;

const TOOLTIP_TIMEOUT = 500;

const ShareLinkStep: FC<Props> = ({ code, isLoading, onOpenPreview }) => {
  const [isTooltipShown, setTooltip] = useState(false);

  const getLandingURL = (code: string) =>
    `${REACT_APP_DYNAMIC_LANDING_PAGE_DOMAIN}/business/invite/drummer?code=${code}`;

  const landingURL = code ? getLandingURL(code) : null;

  const handleCopyURL = () => {
    if (landingURL) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), TOOLTIP_TIMEOUT);
      copy(landingURL);
    }
  };

  return (
    <Container>
      <Heading id={trackedElementsIds.inviteCustomers.modal.title}>
        Invite your customers to Drum up business
      </Heading>
      <Paragraph>
        Copy the custom invitation link and send it out to your customers via
        any of your normal channels of communication. Your customers can then
        download the Drum app and begin driving customers to your business.
      </Paragraph>
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {landingURL && (
        <>
          <LinkPreview>
            <Subtitle
              id={
                trackedElementsIds.inviteCustomers.modal
                  .customInvitationLinkTitle
              }
            >
              Custom Invitation Link
            </Subtitle>
            <Button
              type="button"
              onClick={onOpenPreview}
              id={trackedElementsIds.inviteCustomers.modal.previewButton}
            >
              Preview
            </Button>
          </LinkPreview>

          <StyledInput disabled readOnly defaultValue={landingURL} />
          <Tooltip
            overlayStyle={overlayStyle}
            placement="top"
            overlay="Copied"
            visible={isTooltipShown}
          >
            <CopyLinkButton
              type="button"
              onClick={handleCopyURL}
              id={
                trackedElementsIds.inviteCustomers.modal.copyToClipboardButton
              }
            >
              Copy link to clipboard
            </CopyLinkButton>
          </Tooltip>
        </>
      )}
    </Container>
  );
};

export default ShareLinkStep;
