import React, { FC } from 'react';
import {
  Container,
  Contacts,
  Email,
  EmailLink
} from '../styled';

const FormattingFailed: FC = () => {
  return (
    <Container>
      <p>
        Drum supports CSV imports up to 25MB. Please try again. If you continue
        to have problems, please contact support.
      </p>
      <Contacts>
        <Email>
          <EmailLink href={'mailto:help@drum.io'}>help@drum.io</EmailLink>
        </Email>
      </Contacts>
    </Container>
  );
};

export default FormattingFailed;
