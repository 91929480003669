import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import { useApolloClient } from '@apollo/react-hooks';
import { signOut } from 'services/auth';
import { Container, LoginButton, Description } from './styles';
import PasswordPageHeading from 'components/PasswordPageHeading';

interface Props {
  backButtonLink?: string;
  logOutOnRedirect: boolean;
}

const PasswordUpdateSuccess: FC<Props> = ({ backButtonLink, logOutOnRedirect = false }) => {
  const history = useHistory();
  const client = useApolloClient();

  const returnRedirect = async  () => {
    if(logOutOnRedirect) {
      client.stop();
      await client.clearStore();
      await signOut({doNotReload : true});
    }
    backButtonLink ? window.location.href = backButtonLink : history.push(routes.LOGIN);
  }

  return (
    <Container>
      <PasswordPageHeading title="Success!"><Description>Your password has been reset successfully!</Description></PasswordPageHeading>
      <LoginButton onClick={returnRedirect} type="button">
        Return to log in
      </LoginButton>
    </Container>
  );
};

export default PasswordUpdateSuccess;
