import styled from 'styled-components';
import { Body2, H3 } from 'uikit';

export const Container = styled.div`
  
`;
export const PageDescription = styled(Body2)`

`;
export const InputGroup = styled.div`
  margin-bottom: 30px;
`;
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 2px;
    width: 105px;
    margin-top: 15px;

    &:last-of-type {
      margin-right: 0;
      width: 147px;
    }
  }
  
`;
export const PickerContainer = styled.div`
  position: relative;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const PickerTitle = styled(H3)`
  position: relative;
  top: -40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;