export const INITIAL_PAGE = 1;

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortKey {
  COMMISSION = 'commission',
  CONVERSION = 'conversion',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'createdAt',
  PUBLISHED_AT_UNFORMATTED = 'publishedAtUnformatted',
  CREATED_AT_UNFORMATTED = 'createdAtUnformatted'
}
