import React, { ChangeEvent, FC, useState } from 'react';
import StrengthPasswordMeter from '../../uikit/TextField/StrangePasswordMeter';
import { InputContainer, StyledInput, ToggleInputTypeButton } from './styles';

interface Props {
  className?: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  showPasswordStrength: boolean;
  value: string;
}

enum FieldTypes {
  PASSWORD = 'password',
  TEXT = 'text'
}

const PasswordField: FC<Props> = ({
  className,
  name,
  onChange,
  placeholder,
  showPasswordStrength,
  value
}) => {
  const [inputType, setInputType] = useState<FieldTypes>(FieldTypes.PASSWORD);
  const toggleInputType = () => {
    const newInputType =
      inputType === FieldTypes.PASSWORD ? FieldTypes.TEXT : FieldTypes.PASSWORD;
    setInputType(newInputType);
  };

  return (
    <div className={className}>
      <InputContainer>
        <StyledInput
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={inputType}
          value={value}
        />
        <ToggleInputTypeButton onClick={toggleInputType} type="button">
          {inputType === FieldTypes.PASSWORD ? 'Show' : 'Hide'}
        </ToggleInputTypeButton>
      </InputContainer>
      {showPasswordStrength && <StrengthPasswordMeter password={value} />}
    </div>
  );
};

export default PasswordField;
