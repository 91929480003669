import React, { FC } from 'react';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { PresetConfig } from 'apollo-boost';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { AUTH_TYPE, createAppSyncLink } from 'aws-appsync';
import { Auth } from 'aws-amplify';
import nodeFetch from 'node-fetch';

let apolloCilent: ApolloClient<NormalizedCacheObject> | null = null;

export const getApolloClient = () => {
  if (apolloCilent === null) {
    apolloCilent = createApolloClient();
    return apolloCilent;
  }
  return apolloCilent;
};

export const createApolloClient = (isServer: boolean = false) => {
  let config: PresetConfig;
  if (isServer) {
    config = {
      uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
      //ISSUE: https://github.com/apollographql/apollo-link/issues/513
      fetch: nodeFetch as any
    };
  } else {
    config = {
      uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT
    };
  }

  const httpLink = createHttpLink(config);

  const awsLink = createAppSyncLink({
    url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT as string,
    region: process.env.REACT_APP_AWS_APPSYNC_REGION as string,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken()
    },
    complexObjectsCredentials: () => Auth.currentCredentials()
  });

  return new ApolloClient({
    link: awsLink.concat(httpLink),
    cache: new InMemoryCache().restore((window as any).__APOLLO_STATE__ || {})
  });
};

interface IProvider {
  client: any;
  isServer: boolean;
}

const Rehydrated: React.FC = ({ children }) => <>{children}</>;

export const Provider: FC<IProvider> = (props) => (
  <ApolloProvider client={props.client}>
    <ApolloHooksProvider client={props.client}>
      {props.isServer ? (
        props.children
      ) : (
        <Rehydrated>{props.children}</Rehydrated>
      )}
    </ApolloHooksProvider>
  </ApolloProvider>
);
