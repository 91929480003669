import styled from 'styled-components';
import { breakpoint } from 'helpers';
import PageHeading from 'components/PageHeading';
import { PrimaryButton } from 'uikit';

export const PageHeader = styled.div`
  margin: 24px 0;

  ${breakpoint('md')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const StyledPageHeading = styled(PageHeading)`
  ${breakpoint('xs', 'md')`
    margin-bottom: 30px;
  `};
`;

export const CreateOpportunityButton = styled(PrimaryButton)`
  ${breakpoint('md')`
    width: 260px;
  `}

  ${breakpoint('xs', 'md')`
    width: 100%;
  `};
`;
