import styled from 'styled-components';
import { H2, PrimaryButton } from 'uikit';

export const Container = styled.div`
  text-align: center;
`;

export const PageHeading = styled(H2)`
  margin-bottom: 35px;
`;
export const Description = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
`;

export const LoginButton = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
`;
