import React, { ReactNode, useState } from 'react';
import useCollapse from 'react-collapsed';
import { Container, Button, Content } from './styled';

type Props = {
  title: string;
  text?: string;
  children?: ReactNode;
  expanded?: boolean;
};

const Collapse: React.FC<Props> = ({ title, children, text, expanded = false }: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({isExpanded});
  
  return (
    <Container>
      <Button {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}>{title}</Button>
      {text && <Content {...getCollapseProps()}>
        {text}
      </Content>}
      {children && <Content {...getCollapseProps()}>
        {children}
      </Content>}
    </Container>
  );
};

export default Collapse;
