import React, { FC } from 'react';
import { Body1 } from 'uikit';
import routes from 'constants/routes';
import OpportunityStatus from 'components/OpportunityStatus';
import { OfferStatus } from '../../../../graphql';
import {
  HeadingContainer,
  OpportunityStatusContainer,
  ReturnLink,
  StatusCaption,
  StyledHeading,
  ViewExamplesLink
} from './styles';

interface Props {
  className?: string;
  title: string;
  status: OfferStatus | undefined | null;
}

const OPPORTUNITY_SETUP_GUIDE_URL =
  ' https://help.drum.io/hc/en-us/articles/360050181093-What-kind-of-offer-should-I-create-for-my-e-commerce-business-';

const FormHeader: FC<Props> = ({ className, status, title }) => (
  <div className={className}>
    <HeadingContainer>
      <StyledHeading>{title}</StyledHeading>
      {!!status ? (
        <OpportunityStatusContainer>
          <StatusCaption>Status:</StatusCaption>
          <OpportunityStatus status={status} />
        </OpportunityStatusContainer>
      ) : (
        <ViewExamplesLink
          href={OPPORTUNITY_SETUP_GUIDE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          View example opportunities
        </ViewExamplesLink>
      )}
      <ReturnLink to={routes.DASHBOARD}>&lt; Back to Dashboard</ReturnLink>
    </HeadingContainer>
    <Body1>
      An opportunity is a landing page that you create below that describes your
      product, service, or special discount. Referrers share your landing page
      with their network and drive customers to your website to complete a
      transaction.
    </Body1>
  </div>
);

export default FormHeader;
