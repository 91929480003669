import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LinkTextMixin } from 'uikit';

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
`;

export const StyledLink = styled(Link)`
  ${LinkTextMixin};
`;

export const StyledCustomLink = styled.a`
  ${LinkTextMixin};
  cursor: pointer;
`;
