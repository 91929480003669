export const WEB_INTEGRATION_NO_URL_FILLED = 'Please verify base code and completion event installation';
export const WEB_INTEGRATION_NO_BASE_VERIFICATION = 'Please verify your base code installation';
export const WEB_INTEGRATION_NO_COMPLETION_VERIFICATION = 'Please verify your completion event installation';

export const WEB_INTEGRATION_SAVE_FAILED = 'Could not save integrations';
export const WEB_INTEGRATION_SAVE_SUCCESS = 'Integrations have been saved successfully!';

export const WEB_INTEGRATION_VERIFICATION_PROCESSING = 'Verification processing...';
export const WEB_INTEGRATION_VERIFICATION_SUCCESS_BASE = 'Base code verified';
export const WEB_INTEGRATION_VERIFICATION_SUCCESS_COMPLETION = 'Completion event verified';
export const WEB_INTEGRATION_VERIFICATION_FAILED_BASE = 'Base code verification failed.';
export const WEB_INTEGRATION_VERIFICATION_FAILED_COMPLETION = 'Completion event verification failed.';

export const WEB_INTEGRATION_VERIFICATION_FAILED_BUTTON = 'Please try again';
