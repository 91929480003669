import React, { FC, Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NetworkStatus } from 'apollo-client';
import {
  isShopifyWithCreditCard,
  isShopifyWithDefaultCreditCard
} from 'helpers/billing';
import SetupProfileLayout from '../../layouts/GeneralLayout';
import routes from '../../constants/routes';
import { CONTACT_SUPPORT_LINK } from 'constants/outerLinks';
import FAQ from '../../components/FAQ';
import DataPolicyDisclaimer from '../../components/DataPolicyDisclaimer';
import FormFooter from '../../components/FormFooter';
import {
  AccountButton,
  BillingInstructions,
  BillingOptions,
  Caption,
  Container,
  Content,
  HeadingContainer,
  Image,
  ImageContainer,
  PaymentMethodPlaceholder,
  StyledHeading,
  StyledLink,
  StyledTable
} from './styles';
import {
  useGetCurrentBusinessPaymentsLazyQuery,
  useSetPaymentMethodDefaultMutation,
  PaymentProvider,
  Payment
} from '../../graphql';
import { FullScreenLoader } from 'uikit';
import { DropInPaymentMethods } from './components/DropIn';
import ConnectPaymentMethodModal from './components/ConnectPaymentMethodModal';
import CompleteAccountSetUp from 'components/CompleteAccountSetUp';
import CreditCardDeletionModal from '../../components/CreditCardDeletionModal';
import BillingHistory from '../../components/BillingHistory';
import './styles.scss';

interface RenderButtonContentInput {
  handleClick: () => void;
}
type CurrentTab = 'list' | 'add' | null;

interface ConnectPaymentMethodProps {
  renderButtonContent: (input: RenderButtonContentInput) => JSX.Element;
  paymentMethod: DropInPaymentMethods;
  onPaymentMethodConnected: () => void;
}

const ConnectPaymentMethod: FC<ConnectPaymentMethodProps> = ({
  renderButtonContent,
  paymentMethod,
  onPaymentMethodConnected
}) => {
  const [showDropIn, setShowDropIn] = useState(false);
  const handleClick = () => setShowDropIn(true);
  const handleClose = () => setShowDropIn(false);

  const handlePaymentMethodConnected = () => {
    setShowDropIn(false);
    onPaymentMethodConnected();
  };

  return (
    <>
      <ConnectPaymentMethodModal
        isOpen={showDropIn}
        handleClose={handleClose}
        handlePaymentMethodConnected={handlePaymentMethodConnected}
        paymentMethod={paymentMethod}
      ></ConnectPaymentMethodModal>
      {renderButtonContent({ handleClick })}
    </>
  );
};

const BillingPage: FC = () => {
  const [checkSetupModal, setCheckSetupModal] = useState<boolean>(false);
  const [checkCardDeletionModal, setCheckCardDeletionModal] = useState<boolean>(
    false
  );
  const [currentTab, setCurrentTab] = useState<CurrentTab>(null);

  const history = useHistory();
  const [
    getCurrentBusinessPayments,
    {
      data: currentBusinessPaymentsData,
      loading: currentBusinessPaymentsLoading,
      refetch: refetchCurrentBusinessPaymentsData,
      networkStatus: currentBusinessPaymentsDataNetworkStatus
    }
  ] = useGetCurrentBusinessPaymentsLazyQuery({
    notifyOnNetworkStatusChange: true
  });

  const onPaymentMethodConnected = () => {
    getCurrentBusinessPayments({
      variables: {
        invalidator: new Date().toISOString()
      }
    });
    refetchCurrentBusinessPaymentsData();
    setCheckSetupModal(true);
  };
  const [
    updateDefault,
    { loading: isUpdateDefaultLoading, data: updateData }
  ] = useSetPaymentMethodDefaultMutation();

  const onAddAccountClick = () => {
    setCurrentTab('add');
  };
  const backToListClick = () => {
    setCurrentTab('list');
  };
  const onDone = () => {
    history.push(routes.DASHBOARD);
  };

  const onDefaultCheck = (provider: PaymentProvider | null) => {
    if (!provider) return;
    if (
      provider === PaymentProvider.Credit &&
      isShopifyWithCreditCard(
        currentBusinessPaymentsData?.getCurrentBusiness?.integrationType,
        (currentBusinessPaymentsData?.getCurrentBusiness
          ?.payments as Payment[]) || []
      )
    ) {
      setCheckCardDeletionModal(true);
      return;
    }

    updateDefault({
      variables: {
        input: {
          provider
        }
      }
    });
  };

  useEffect(() => {
    if (!updateData) return;
    refetchCurrentBusinessPaymentsData();
    // eslint-disable-next-line
  }, [updateData]);

  useEffect(() => {
    getCurrentBusinessPayments({
      variables: {
        invalidator: new Date().toISOString()
      }
    });
  }, [getCurrentBusinessPayments]);

  useEffect(() => {
    if(currentBusinessPaymentsDataNetworkStatus === NetworkStatus.refetch || currentBusinessPaymentsLoading) return;
    const payments = currentBusinessPaymentsData?.getCurrentBusiness?.payments;
    // DF TODO for 166: go to /billing-accounts page
    setCurrentTab(payments && payments.length ? 'list' : 'add');
    if (
      currentBusinessPaymentsData &&
      isShopifyWithDefaultCreditCard(
        currentBusinessPaymentsData?.getCurrentBusiness?.integrationType,
        (currentBusinessPaymentsData?.getCurrentBusiness
          ?.payments as Payment[]) || []
      )
    ) {
      setCheckCardDeletionModal(true);
    }
    // eslint-disable-next-line
  }, [currentBusinessPaymentsData]);

  return (
    <SetupProfileLayout sidebar={<FAQ preset="billing" />}>
      {(currentBusinessPaymentsLoading ||
        isUpdateDefaultLoading ||
        currentBusinessPaymentsDataNetworkStatus === NetworkStatus.refetch) && (
        <FullScreenLoader />
      )}

      <Container>
        <HeadingContainer>
          <StyledHeading title="Billing" />
          <StyledLink target="_blank" href={CONTACT_SUPPORT_LINK}>
            Need help?
          </StyledLink>
        </HeadingContainer>
        <Content>
          <BillingInstructions>
            Please link a payment account. This is necessary for paying any
            future commissions that occur when promoters perform a successful
            sale.
          </BillingInstructions>
          {currentTab === 'add' && (
            <Fragment>
              <BillingOptions>
                {!(
                  currentBusinessPaymentsData?.getCurrentBusiness
                    ?.integrationType === 'shopify'
                ) && (
                  <ConnectPaymentMethod
                    onPaymentMethodConnected={onPaymentMethodConnected}
                    paymentMethod={DropInPaymentMethods.CARD}
                    renderButtonContent={({ handleClick }) => {
                      return (
                        <PaymentMethodPlaceholder onClick={handleClick}>
                          <ImageContainer>
                            <Image src="/assets/credit-card.png" alt="" />
                          </ImageContainer>
                          <Caption>Credit Card</Caption>
                        </PaymentMethodPlaceholder>
                      );
                    }}
                  />
                )}
                <ConnectPaymentMethod
                  onPaymentMethodConnected={onPaymentMethodConnected}
                  paymentMethod={DropInPaymentMethods.PAYPAL}
                  renderButtonContent={({ handleClick }) => {
                    return (
                      <PaymentMethodPlaceholder onClick={handleClick}>
                        <img src="/assets/paypal-logo.png" alt="" />
                      </PaymentMethodPlaceholder>
                    );
                  }}
                />
              </BillingOptions>
              {!!currentBusinessPaymentsData?.getCurrentBusiness?.payments
                ?.length && (
                <AccountButton onClick={backToListClick}>
                  Back to List
                </AccountButton>
              )}
            </Fragment>
          )}
          {currentTab === 'list' && (
            <Fragment>
              <StyledTable
                paymentAccounts={
                  currentBusinessPaymentsData?.getCurrentBusiness?.payments ||
                  []
                }
                onCheck={onDefaultCheck}
              />
              <AccountButton onClick={onAddAccountClick}>
                Add Account
              </AccountButton>
            </Fragment>
          )}

          <DataPolicyDisclaimer />
        </Content>

        {currentTab === 'list' && <BillingHistory />}
      </Container>

      <FormFooter canSubmit={true} onClick={onDone} />

      <CompleteAccountSetUp
        onModalClose={() => setCheckSetupModal(false)}
        check={checkSetupModal}
      />
      <CreditCardDeletionModal
        onClose={() => setCheckCardDeletionModal(false)}
        check={checkCardDeletionModal}
        onSuccess={() => {
          setCheckCardDeletionModal(false);
          refetchCurrentBusinessPaymentsData();
        }}
        performIntegrationCheck={false}
      />
    </SetupProfileLayout>
  );
};

export default BillingPage;
