import styled from 'styled-components';
import { breakpoint } from '../../../../helpers';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  margin-top: 40px;
`;
export const TableContainer = styled.div`
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;
export const MobileCardsContainer = styled.div`
  ${breakpoint('lg')`
    display: none;
  `}
`;

export const Row = styled.div`
  display: flex;
  height: 48px;
  border-bottom: 1px solid #ccc;

  & > div {
    &.date {
      flex: 0.21;
  
      span:nth-of-type(1) {
        margin-right: 8px;
      }
    }
    &.referrer {
      flex: 0.12;
      overflow: hidden;
  
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &.opportunity {
      flex: 0.27;
      font-size: ${({ theme }) => theme.size.body2};
      overflow: hidden;
    }
    &.transaction {
      flex: 0.12;
    }
    &.fees {
      flex: 0.11;
      
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    &.rewards {
      flex: 0.13;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    &.total {
      flex: 0.16;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.colors.danger};
    }
  }

  &.isShopify > div {
    &.fees {
      flex: 0.07;
    }
    &.rewards {
      flex: 0.09;
    }
    &.total {
      flex: 0.12;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }
`;

export const StyledLink = styled.a`
  ${LinkTextMixin};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.main};
`;

export const Col = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.black};
  padding-right: 7px;
`;

export const Name = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};
`;

export const DateBlock = styled.p`
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body1};
`;
export const Emphasized = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.danger};

  &.margined {
    margin-right: 5px;
  }
`;
export const ArrowContainer = styled.span`
  display: inline-block;
  transform: rotate(-90deg);
  cursor: pointer;
`;

export const HeaderRow = styled(Row)`
  & > div {
    &.transaction {
      word-spacing: initial;
    }
  }
`;
export const HeaderCol = styled(Col)`
  font-size: ${({ theme }) => theme.size.body1} !important;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold} !important;
  color: ${({ theme }) => theme.colors.black} !important;
  word-spacing: 9999999px;
`;
export const NoItemsBlock = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 400px;
`;
export const NoItemsText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #C2C2C2;
`;

