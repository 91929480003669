import React, { FC, useState } from 'react';
import { ApolloError } from 'apollo-boost';
import { RouteComponentProps } from 'react-router-dom';
import {
  OfferStatus,
  useUpdateDraftOpportunityMutation,
  useUpdateOpportunityInfoMutation,
  useGetUserIntegrationQuery,
  useGetOfferQuery,
  useGetBusinessDetailsQuery,
  GetFirstOpportunityStatusDocument,
  GetFirstOpportunityStatusQuery
} from '../../graphql';
import CreateOpportunityForm from 'components/CreateOpportunity';
import CreateOpportunitySuccessModal from 'components/CreateOpportunitySuccessModal';
import { FullScreenLoader } from 'uikit';
import { CreateOpportunityType } from 'components/CreateOpportunity/validationSchema';
import {
  normalizeOpportunityFormValues,
  getIsProfileSetupCompleted,
  getProfileStatus
} from 'helpers';
import getInitialValues from 'components/CreateOpportunity/utils';
import routes from 'constants/routes';
import { AcceptButton } from './styles';

const DRAFT_OPPORTUNITY_DEFAULT_TITLE = 'Draft';

const EditOpportunityPage: FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history
}) => {
  const [isSuccessPublishModalOpen, setSuccessPublishModal] = useState(false);
  const [isFormSubmissionSucceeded, setFormSubmissionSucceeded] = useState<
    boolean | null
  >(null);
  const [error, setError] = useState<null | ApolloError>(null);

  const { id } = match.params;
  const { data: opportunityData, loading: getOfferLoading } = useGetOfferQuery({
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  const { data: businessDetails } = useGetBusinessDetailsQuery({
    fetchPolicy: 'cache-first'
  });
  const isProfileSetupCompleted = getIsProfileSetupCompleted(businessDetails);

  const profileSetupStatus = getProfileStatus(businessDetails);

  const {
    completeProfile,
    setupBilling,
    setupTracking
  } = profileSetupStatus;

  const {
    data: integrationInfo,
    loading: isUserIntegrationLoading
  } = useGetUserIntegrationQuery();

  const [
    updateDraftOpportunity,
    { loading: isUpdateDraftOpportunityLoading }
  ] = useUpdateDraftOpportunityMutation({
    onError: (error) => {
      setError(error);
      setFormSubmissionSucceeded(false);
    },
    onCompleted: () => {
      setFormSubmissionSucceeded(true);
      redirectUser();
    }
  });

  const [
    publishOpportunity,
    { data: publishedOpportunityData, loading: isUpdateOpportunityLoading }
  ] = useUpdateOpportunityInfoMutation({
    update: (cache) => {
      const businessIdQuery = cache.readQuery<GetFirstOpportunityStatusQuery>({
        query: GetFirstOpportunityStatusDocument
      });

      if (
        businessIdQuery?.getCurrentBusiness?.id &&
        !businessIdQuery?.getCurrentBusiness?.didPublishOffers &&
        businessIdQuery?.getCurrentBusiness?.firstDraftOfferId
      ) {
        cache.writeQuery({
          query: GetFirstOpportunityStatusDocument,
          data: {
            getCurrentBusiness: {
              __typename: 'Business',
              id: businessIdQuery.getCurrentBusiness.id,
              firstDraftOfferId: null,
              didPublishOffers: true
            }
          }
        });
      }
    },
    onError: (error) => {
      setError(error);
      setFormSubmissionSucceeded(false);
    },
    onCompleted: () => {
      setFormSubmissionSucceeded(true);
      redirectUser();
    }
  });

  const isLoading =
    isUserIntegrationLoading ||
    isUpdateOpportunityLoading ||
    isUpdateDraftOpportunityLoading ||
    getOfferLoading;

  const redirectUser = () => {
    if (isProfileSetupCompleted) {
      history.push(routes.MY_OPPORTUNITIES);
    } else {
      history.push(routes.DASHBOARD);
    }
  };

  const handlePublishOpportunity = (values: CreateOpportunityType) => {
    const formattedValues = normalizeOpportunityFormValues(
      values,
      integrationInfo
    );

    const input = {
      ...formattedValues,
      id,
      status: OfferStatus.Active
    };

    if(!completeProfile.isCompleted || !setupBilling.isCompleted || !setupTracking.isCompleted) {
      input.status = OfferStatus.InQueue;
    }

    publishOpportunity({ variables: { input } });
  };

  const handleSaveDraftOpportunity = async (values: CreateOpportunityType) => {
    const formattedValues = normalizeOpportunityFormValues(
      values,
      integrationInfo
    );

    const input = {
      ...formattedValues,
      id,
      title: formattedValues.title || DRAFT_OPPORTUNITY_DEFAULT_TITLE
    };

    await updateDraftOpportunity({ variables: { input } });
  };

  // TODO:
  // 1. Handle 403 error (when user tries to access opportunity of other user)
  // 2. Handle situation when user tries to fetch opportunity that doesn't exist

  const closeSuccessModal = () => {
    setSuccessPublishModal(false);
    redirectUser();
  };

  return (
    <>
      <CreateOpportunityForm
        error={error}
        onCloseErrorModal={() => setError(null)}
        values={opportunityData}
        integrationInfo={integrationInfo}
        onPublish={handlePublishOpportunity}
        onSaveDraft={handleSaveDraftOpportunity}
        title="Edit Opportunity"
        isSubmissionSucceeded={isFormSubmissionSucceeded}
        isEdit={true}
      />

      <CreateOpportunitySuccessModal
        title="Opportunity Published!"
        // TODO: remove this ugly adaptation
        values={getInitialValues(
          publishedOpportunityData
            ? { getOffer: publishedOpportunityData.updateOfferInfo }
            : publishedOpportunityData,
          businessDetails
        )}
        isOpen={isSuccessPublishModalOpen}
        onClose={() => setSuccessPublishModal(false)}
      >
        <AcceptButton onClick={closeSuccessModal} type="button">
          Done
        </AcceptButton>
      </CreateOpportunitySuccessModal>

      {isLoading && <FullScreenLoader />}
    </>
  );
};

export default EditOpportunityPage;
