import { RewardActionType, CommissionType } from '../graphql';

export const OPPORTUNITY_TEMPLATE_PARAM = "templateIndex";
export const OPPORTUNITY_SETUP_GUIDE_URL =
  ' https://help.drum.io/hc/en-us/articles/360050181093-What-kind-of-offer-should-I-create-for-my-e-commerce-business-';

export interface OpportunityTemplateData {
  opportunityDetails: {
    title: string;
    description: string;
  },
  referralReward: {
    commissionAmount: number | null;
    commissionType: CommissionType;
    minimumPurchaseAmount: number;
    isMinimumPurchaseAmountRequired: boolean;
    rewardActionType: RewardActionType;
  },
  blockDescription: string;
  selectButtonText: string;
}
export const getTemplates = (businessName: string ) => {
  return [
    {
      opportunityDetails: {
        title: "Get 20% off your order.",
        description: `Get 20% off your order at ${businessName || 'Business Name'} TODAY!`,
      },
      referralReward: {
        commissionAmount: 10,
        commissionType: CommissionType.Percentage,
        minimumPurchaseAmount: 0,
        isMinimumPurchaseAmountRequired: false,
        rewardActionType: RewardActionType.CompleteAPurchase
      },
      blockDescription: "Standard 10% reward for referring a customer.",
      selectButtonText: "Select template",
    },
    {
      opportunityDetails: {
        title: "Get 10% off your order.",
        description: `Get 10% off your order at ${businessName || 'Business Name'} TODAY!`,
      },
      referralReward: {
        commissionAmount: 25,
        commissionType: CommissionType.Fixed,
        minimumPurchaseAmount: 0,
        isMinimumPurchaseAmountRequired: false,
        rewardActionType: RewardActionType.CompleteAPurchase
      },
      blockDescription: "Standard $25 reward for referring a customer.",
      selectButtonText: "Select template",
    },
    {
      opportunityDetails: {
        title: "",
        description: "",
      },
      referralReward: {
        commissionAmount: null,
        commissionType: CommissionType.Fixed,
        minimumPurchaseAmount: 0,
        isMinimumPurchaseAmountRequired: false,
        rewardActionType: RewardActionType.CompleteAPurchase
      },
      blockDescription: "You customize the reward to any $ or % amount.",
      selectButtonText: "Create your own",
    },
  ]
};

export const TEMPLATES: OpportunityTemplateData[] = [
  {
    opportunityDetails: {
      title: "Get 20% off your order.",
      description: "Get 20% off your order at Myers Furniture TODAY!",
    },
    referralReward: {
      commissionAmount: 10,
      commissionType: CommissionType.Percentage,
      minimumPurchaseAmount: 0,
      isMinimumPurchaseAmountRequired: false,
      rewardActionType: RewardActionType.CompleteAPurchase
    },
    blockDescription: "Standard 10% reward for referring a customer.",
    selectButtonText: "Select template",
  },
  {
    opportunityDetails: {
      title: "Get 10% off your order.",
      description: "Get 10% off your order at Myers Furniture TODAY!",
    },
    referralReward: {
      commissionAmount: 25,
      commissionType: CommissionType.Fixed,
      minimumPurchaseAmount: 0,
      isMinimumPurchaseAmountRequired: false,
      rewardActionType: RewardActionType.CompleteAPurchase
    },
    blockDescription: "Standard $25 reward for referring a customer.",
    selectButtonText: "Select template",
  },
  {
    opportunityDetails: {
      title: "",
      description: "",
    },
    referralReward: {
      commissionAmount: null,
      commissionType: CommissionType.Fixed,
      minimumPurchaseAmount: 0,
      isMinimumPurchaseAmountRequired: false,
      rewardActionType: RewardActionType.CompleteAPurchase
    },
    blockDescription: "You customize the reward to any $ or % amount.",
    selectButtonText: "Create your own",
  },
];

export const STEPS = [
  {
    step: 1,
    caption: 'Choose a template'
  },
  {
    step: 2,
    caption: 'Review and Publish'
  },
]