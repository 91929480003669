import React, { FC } from 'react';
import { Container, LogoContainer } from './styles';

interface Props {
  className?: string;
}

const Footer: FC<Props> = ({ className }) => (
  <Container className={className}>
    <LogoContainer>
      <img src="/assets/drum-grey.png" alt="logo" />
    </LogoContainer>
    <small>&copy; Drum Technologies Inc. All rights reserved.</small>
  </Container>
);

export default Footer;
