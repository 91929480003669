import React, { FC } from 'react';
import IconProps from './IconProps';

const AcceptGreenMedium: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <g fill="#379923">
      <path d="M26.316 10.536c-.392 0-.76.16-1.036.46l-9.96 11.087-4.647-4.623a1.378 1.378 0 00-.966-.415c-.368 0-.713.139-.989.415-.529.552-.529 1.426 0 1.955l5.682 5.659c.253.253.598.414.966.414h.046c.368 0 .736-.184.99-.46L27.35 12.836c.506-.575.46-1.45-.115-1.955a1.346 1.346 0 00-.92-.345z"></path>
      <path d="M18.012 0C8.074 0 0 8.074 0 18.012c0 9.937 8.074 18.011 18.012 18.011 9.937 0 18.011-8.074 18.011-18.012C36 8.075 27.926 0 18.011 0zm0 33.24c-8.397 0-15.229-6.832-15.229-15.229-.023-8.419 6.81-15.25 15.229-15.25 8.396 0 15.228 6.831 15.228 15.227 0 8.42-6.832 15.252-15.229 15.252z"></path>
    </g>
  </svg>
);

export default AcceptGreenMedium;
